import { Icon } from "@ds_universal/Icon";
import React, { forwardRef } from "react";
import styled from "styled-components";

export const StyledInfoButton = styled.button<{
  size: number;
  disabled?: boolean;
}>`
  all: unset;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  border: ${({ theme }) => `2px solid ${theme.primaryColor[800]}`};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

type InfoButtonProps = {
  size?: number;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const InfoButton = forwardRef<HTMLButtonElement, InfoButtonProps>(
  ({ size = 8, disabled = false, ...props }, ref) => {
    return (
      <StyledInfoButton
        ref={ref}
        size={size + 8}
        disabled={disabled}
        {...props}
      >
        <Icon initialViewbox icon="infoI" width={size} height={size} />
      </StyledInfoButton>
    );
  }
);

InfoButton.displayName = "InfoButton";

export default InfoButton;
