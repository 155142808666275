import { SpaceUserRoleEnum } from "src/js/types/enums";
import { AvatarModel } from "src/js/types/models/User";
import { ObjectValues } from "./Common";

export type SpaceUserMember = {
  id: number;
  avatar?: AvatarModel;
  name: string;
  surname: string;
  email: string;
};

export type StaffMember = SpaceUserMember & {
  role: SpaceUserRoleEnum;
  groupsNumber: number;
  createdAt: string;
};

export const SpaceUsersQueryParam = {
  Staff: "staff",
  NonStaff: "non-staff"
} as const;

export type SpaceUsersQueryParamType = ObjectValues<
  typeof SpaceUsersQueryParam
>;
