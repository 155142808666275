import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Container } from "react-awesome-styled-grid";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { LocalStorageKey, readLocalData } from "src/js/modules/storageFunction";
import { fetchSpacesGroups } from "src/js/repository/dashboardRepository";
import { DashboardEvent, EventDomain, SpacesGroupType } from "src/js/types";
import styled from "styled-components";
import AnalyticsDashboardBlocked from "../SpacesAnalytics/AnalyticsDashboardBlocked";
import { Objectives } from "./Objectives";
import { AnalyticsInfoModal } from "./components/AnalyticsInfoModal";
import { FilterSelectors } from "./components/FilterSelectors";

export const AnalyticsContainer = styled(Container)`
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding: 0 0 120px;
  }
`;

const AnalyticsContent = observer(() => {
  const {
    SpaceStore: { activeSpaceId },
    ModalStore: { openModal }
  } = useStores();

  const [spaceGroups, setSpaceGroups] = useState<SpacesGroupType[]>();

  useEffect(() => {
    if (!activeSpaceId) return;
    // TODO: improve handling of fetch groups
    fetchSpacesGroups({
      spaceId: activeSpaceId,
      limit: 9999,
      offset: 0
    }).then(({ results }) => {
      setSpaceGroups(results);
    });
  }, [activeSpaceId]);

  useEffect(() => {
    _trackEvent(EventDomain.SpaceDashboardUsage, DashboardEvent.ViewDashboard);
    if (!readLocalData(LocalStorageKey.NewAnalyticsInfoModal)) {
      openModal(() => <AnalyticsInfoModal />);
    }
  }, []);

  return (
    <>
      <FilterSelectors spaceGroups={spaceGroups} />
      <AnalyticsContainer>
        <Objectives spaceGroups={spaceGroups} />
      </AnalyticsContainer>
    </>
  );
});

const Analytics = () => {
  const {
    SpacesSettingsStore: { generalSettings },
    SpaceStore: { activeSpaceId }
  } = useStores();

  const isDisabled = generalSettings?.planSettings?.enableAnalytics === false;
  if (isDisabled) {
    return <AnalyticsDashboardBlocked spaceId={activeSpaceId} />;
  }

  return <AnalyticsContent />;
};

export default observer(Analytics);
