import { useEffect, useState } from "react";

// TODO this hook is pretty useless, but i needed to split the big one
const useDirectMessage = ({
  disableChat = false
}: {
  disableChat: boolean;
}) => {
  const [disableChatState, setDisableChat] = useState(disableChat);

  useEffect(() => {
    setDisableChat(disableChat);
  }, [disableChat]);

  return {
    disableChatState,
    setDisableChat
  };
};

export default useDirectMessage;
