import { AvatarWithInitials, Heading, colors } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import {
  SpaceSchoolBadge,
  SpaceSchoolBadgeVariant
} from "src/js/components/SpaceSchoolBadge";
import { useStores } from "src/js/hooks";
import { __HERO_GROUPS_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { ImageModel } from "src/js/types";
import { useTheme } from "styled-components";
import { SpacesDotMenuWrapper } from "../../SpacesDotMenuWrapper";
import { LeaveSpaceModal } from "../../modal/LeaveSpaceModal";
import * as S from "./HeaderWhiteHome.styles";

const HeaderWhiteHome = () => {
  const {
    SpaceStore: { activeSpace, isClaimed, isAdmin, activeSpaceId },
    ModalStore: { closeModal, openModal }
  } = useStores();
  const { whiteTheme } = useTheme();

  const showLeaveSpaceModal = () => {
    openModal(() => <LeaveSpaceModal closeModal={closeModal} />);
  };

  return (
    <>
      <S.HeroBannerWrapper>
        <S.HeroBanner
          src={
            activeSpace?.space?.spaceCover?.large || __HERO_GROUPS_PLACEHOLDER__
          }
        />
        <S.SpaceBadge>
          <AvatarWithInitials
            theme={whiteTheme}
            url={(activeSpace?.space?.badge as ImageModel | undefined)?.large}
            alt={activeSpace?.space?.name}
            name={activeSpace?.space?.name || ""}
            avatarColor={activeSpace?.space?.defaultBadgeColor}
            size="medium"
          />
        </S.SpaceBadge>
      </S.HeroBannerWrapper>
      <S.TitleContainer>
        <Heading level="1">
          {activeSpace?.space?.name}
          {isClaimed ? (
            <SpaceSchoolBadge variant={SpaceSchoolBadgeVariant.Violet} />
          ) : null}
        </Heading>
        <SpacesDotMenuWrapper
          isAdmin={isAdmin}
          leaveSpace={showLeaveSpaceModal}
          activeSpace={activeSpace}
          activeSpaceId={activeSpaceId}
          color={colors.grey[50]}
          alignRight
        />
      </S.TitleContainer>
    </>
  );
};

export default observer(HeaderWhiteHome);
