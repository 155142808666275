/* eslint-disable react/no-unknown-property */
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { Mixin } from "@ds_themes/mixin";

import { BoxProps, SRootProps } from "./types";

const Root = styled.div<SRootProps>`
  ${({ $renderOn, $cssProps }) => {
    if ($renderOn) {
      const mediaQuery = Mixin[$renderOn];

      return css`
        display: none;

        ${mediaQuery`
          display: block;
          ${{ ...$cssProps }}
        `}
      `;
    }

    return $cssProps;
  }}
`;

/**
 * A simple component wrapper which can be used for minor styling purposes.
 * @param {string} id - HTML id attribute
 * @param {string} className - HTML class attribute
 * @param {string} title - HTML title attribute
 * @param {keyof typeof Mixin} renderOn - The screen size at which the box should be rendered
 * @param {CSSProperties} ...style - CSS style object
 * @returns {HTMLDivElement} Box component (div HTML element)
 *
 * @example
 * // Adding styled wrappers can be redundant and bothersome, the purpose of this component is to avoid that.
 * // So, for instance, instead of wrapping a `span` with a `div` element and styling the `div`, you can simply use the `Box` component.
 * // Instead of:
 * const StyledDiv = styled.div`
 *  margin-bottom: 8px;
 * `;
 *
 * <StyledDiv>
 *    <span>Some text</span>
 * </StyledDiv>
 *
 * // You can use this:
 * <Box marginBottom="8px">
 *    <span>Some text</span>
 * </Box>
 */
const Box = React.forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  (
    {
      children,
      onCopy,
      onCopyCapture,
      onCut,
      onCutCapture,
      onPaste,
      onPasteCapture,
      onCompositionEnd,
      onCompositionEndCapture,
      onCompositionStart,
      onCompositionStartCapture,
      onCompositionUpdate,
      onCompositionUpdateCapture,
      onFocus,
      onFocusCapture,
      onBlur,
      onBlurCapture,
      onChange,
      onChangeCapture,
      onBeforeInput,
      onBeforeInputCapture,
      onInput,
      onInputCapture,
      onReset,
      onResetCapture,
      onSubmit,
      onSubmitCapture,
      onInvalid,
      onInvalidCapture,
      onLoad,
      onLoadCapture,
      onError,
      onErrorCapture,
      onKeyDown,
      onKeyDownCapture,
      onKeyPress,
      onKeyPressCapture,
      onKeyUp,
      onKeyUpCapture,
      onAbort,
      onAbortCapture,
      onCanPlay,
      onCanPlayCapture,
      onCanPlayThrough,
      onCanPlayThroughCapture,
      onDurationChange,
      onDurationChangeCapture,
      onEmptied,
      onEmptiedCapture,
      onEncrypted,
      onEncryptedCapture,
      onEnded,
      onEndedCapture,
      onLoadedData,
      onLoadedDataCapture,
      onLoadedMetadata,
      onLoadedMetadataCapture,
      onLoadStart,
      onLoadStartCapture,
      onPause,
      onPauseCapture,
      onPlay,
      onPlayCapture,
      onPlaying,
      onPlayingCapture,
      onProgress,
      onProgressCapture,
      onRateChange,
      onRateChangeCapture,
      onSeeked,
      onSeekedCapture,
      onSeeking,
      onSeekingCapture,
      onStalled,
      onStalledCapture,
      onSuspend,
      onSuspendCapture,
      onTimeUpdate,
      onTimeUpdateCapture,
      onVolumeChange,
      onVolumeChangeCapture,
      onWaiting,
      onWaitingCapture,
      onAuxClick,
      onAuxClickCapture,
      onClick,
      onClickCapture,
      onContextMenu,
      onContextMenuCapture,
      onDoubleClick,
      onDoubleClickCapture,
      onDrag,
      onDragCapture,
      onDragEnd,
      onDragEndCapture,
      onDragEnter,
      onDragEnterCapture,
      onDragExit,
      onDragExitCapture,
      onDragLeave,
      onDragLeaveCapture,
      onDragOver,
      onDragOverCapture,
      onDragStart,
      onDragStartCapture,
      onDrop,
      onDropCapture,
      onMouseDown,
      onMouseDownCapture,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseMoveCapture,
      onMouseOut,
      onMouseOutCapture,
      onMouseOver,
      onMouseOverCapture,
      onMouseUp,
      onMouseUpCapture,
      onSelect,
      onSelectCapture,
      onTouchCancel,
      onTouchCancelCapture,
      onTouchEnd,
      onTouchEndCapture,
      onTouchMove,
      onTouchMoveCapture,
      onTouchStart,
      onTouchStartCapture,
      onPointerDown,
      onPointerDownCapture,
      onPointerMove,
      onPointerMoveCapture,
      onPointerUp,
      onPointerUpCapture,
      onPointerCancel,
      onPointerCancelCapture,
      onPointerEnter,
      onPointerLeave,
      onPointerOver,
      onPointerOverCapture,
      onPointerOut,
      onPointerOutCapture,
      onGotPointerCapture,
      onGotPointerCaptureCapture,
      onLostPointerCapture,
      onLostPointerCaptureCapture,
      onScroll,
      onScrollCapture,
      onWheel,
      onWheelCapture,
      onAnimationStart,
      onAnimationStartCapture,
      onAnimationEnd,
      onAnimationEndCapture,
      onAnimationIteration,
      onAnimationIterationCapture,
      onTransitionEnd,
      onTransitionEndCapture,
      defaultChecked,
      defaultValue,
      suppressContentEditableWarning,
      suppressHydrationWarning,
      accessKey,
      className,
      contentEditable,
      contextMenu,
      dir,
      draggable,
      hidden,
      id,
      lang,
      nonce,
      placeholder,
      slot,
      spellCheck,
      tabIndex,
      title,
      translate,
      radioGroup,
      role,
      about,
      datatype,
      inlist,
      prefix,
      property,
      resource,
      typeof: divTypeOf,
      vocab,
      autoCapitalize,
      autoCorrect,
      autoSave,
      itemProp,
      itemScope,
      itemType,
      itemID,
      itemRef,
      results,
      security,
      unselectable,
      inputMode,
      "aria-activedescendant": ariaActivedescendant,
      "aria-atomic": ariaAtomic,
      "aria-autocomplete": ariaAutocomplete,
      "aria-busy": ariaBusy,
      "aria-checked": ariaChecked,
      "aria-colcount": ariaColcount,
      "aria-colindex": ariaColindex,
      "aria-colspan": ariaColspan,
      "aria-controls": ariaControls,
      "aria-current": ariaCurrent,
      "aria-describedby": ariaDescribedby,
      "aria-details": ariaDetails,
      "aria-disabled": ariaDisabled,
      "aria-dropeffect": ariaDropeffect,
      "aria-errormessage": ariaErrormessage,
      "aria-expanded": ariaExpanded,
      "aria-flowto": ariaFlowto,
      "aria-grabbed": ariaGrabbed,
      "aria-haspopup": ariaHaspopup,
      "aria-hidden": ariaHidden,
      "aria-invalid": ariaInvalid,
      "aria-keyshortcuts": ariaKeyshortcuts,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledby,
      "aria-level": ariaLevel,
      "aria-live": ariaLive,
      "aria-modal": ariaModal,
      "aria-multiline": ariaMultiline,
      "aria-multiselectable": ariaMultiselectable,
      "aria-orientation": ariaOrientation,
      "aria-owns": ariaOwns,
      "aria-placeholder": ariaPlaceholder,
      "aria-posinset": ariaPosinset,
      "aria-pressed": ariaPressed,
      "aria-readonly": ariaReadonly,
      "aria-relevant": ariaRelevant,
      "aria-required": ariaRequired,
      "aria-roledescription": ariaRoledescription,
      "aria-rowcount": ariaRowcount,
      "aria-rowindex": ariaRowindex,
      "aria-rowspan": ariaRowspan,
      "aria-selected": ariaSelected,
      "aria-setsize": ariaSetsize,
      "aria-sort": ariaSort,
      "aria-valuemax": ariaValuemax,
      "aria-valuemin": ariaValuemin,
      "aria-valuenow": ariaValuenow,
      "aria-valuetext": ariaValuetext,
      "data-testid": testId,
      renderOn,
      ...style
    },
    ref
  ) => {
    return (
      <Root
        /*
          DOM HTML attributes 
        */
        onCopy={onCopy}
        onCopyCapture={onCopyCapture}
        onCut={onCut}
        onCutCapture={onCutCapture}
        onPaste={onPaste}
        onPasteCapture={onPasteCapture}
        onCompositionEnd={onCompositionEnd}
        onCompositionEndCapture={onCompositionEndCapture}
        onCompositionStart={onCompositionStart}
        onCompositionStartCapture={onCompositionStartCapture}
        onCompositionUpdate={onCompositionUpdate}
        onCompositionUpdateCapture={onCompositionUpdateCapture}
        onFocus={onFocus}
        onFocusCapture={onFocusCapture}
        onBlur={onBlur}
        onBlurCapture={onBlurCapture}
        onChange={onChange}
        onChangeCapture={onChangeCapture}
        onBeforeInput={onBeforeInput}
        onBeforeInputCapture={onBeforeInputCapture}
        onInput={onInput}
        onInputCapture={onInputCapture}
        onReset={onReset}
        onResetCapture={onResetCapture}
        onSubmit={onSubmit}
        onSubmitCapture={onSubmitCapture}
        onInvalid={onInvalid}
        onInvalidCapture={onInvalidCapture}
        onLoad={onLoad}
        onLoadCapture={onLoadCapture}
        onError={onError}
        onErrorCapture={onErrorCapture}
        onKeyDown={onKeyDown}
        onKeyDownCapture={onKeyDownCapture}
        onKeyPress={onKeyPress}
        onKeyPressCapture={onKeyPressCapture}
        onKeyUp={onKeyUp}
        onKeyUpCapture={onKeyUpCapture}
        onAbort={onAbort}
        onAbortCapture={onAbortCapture}
        onCanPlay={onCanPlay}
        onCanPlayCapture={onCanPlayCapture}
        onCanPlayThrough={onCanPlayThrough}
        onCanPlayThroughCapture={onCanPlayThroughCapture}
        onDurationChange={onDurationChange}
        onDurationChangeCapture={onDurationChangeCapture}
        onEmptied={onEmptied}
        onEmptiedCapture={onEmptiedCapture}
        onEncrypted={onEncrypted}
        onEncryptedCapture={onEncryptedCapture}
        onEnded={onEnded}
        onEndedCapture={onEndedCapture}
        onLoadedData={onLoadedData}
        onLoadedDataCapture={onLoadedDataCapture}
        onLoadedMetadata={onLoadedMetadata}
        onLoadedMetadataCapture={onLoadedMetadataCapture}
        onLoadStart={onLoadStart}
        onLoadStartCapture={onLoadStartCapture}
        onPause={onPause}
        onPauseCapture={onPauseCapture}
        onPlay={onPlay}
        onPlayCapture={onPlayCapture}
        onPlaying={onPlaying}
        onPlayingCapture={onPlayingCapture}
        onProgress={onProgress}
        onProgressCapture={onProgressCapture}
        onRateChange={onRateChange}
        onRateChangeCapture={onRateChangeCapture}
        onSeeked={onSeeked}
        onSeekedCapture={onSeekedCapture}
        onSeeking={onSeeking}
        onSeekingCapture={onSeekingCapture}
        onStalled={onStalled}
        onStalledCapture={onStalledCapture}
        onSuspend={onSuspend}
        onSuspendCapture={onSuspendCapture}
        onTimeUpdate={onTimeUpdate}
        onTimeUpdateCapture={onTimeUpdateCapture}
        onVolumeChange={onVolumeChange}
        onVolumeChangeCapture={onVolumeChangeCapture}
        onWaiting={onWaiting}
        onWaitingCapture={onWaitingCapture}
        onAuxClick={onAuxClick}
        onAuxClickCapture={onAuxClickCapture}
        onClick={onClick}
        onClickCapture={onClickCapture}
        onContextMenu={onContextMenu}
        onContextMenuCapture={onContextMenuCapture}
        onDoubleClick={onDoubleClick}
        onDoubleClickCapture={onDoubleClickCapture}
        onDrag={onDrag}
        onDragCapture={onDragCapture}
        onDragEnd={onDragEnd}
        onDragEndCapture={onDragEndCapture}
        onDragEnter={onDragEnter}
        onDragEnterCapture={onDragEnterCapture}
        onDragExit={onDragExit}
        onDragExitCapture={onDragExitCapture}
        onDragLeave={onDragLeave}
        onDragLeaveCapture={onDragLeaveCapture}
        onDragOver={onDragOver}
        onDragOverCapture={onDragOverCapture}
        onDragStart={onDragStart}
        onDragStartCapture={onDragStartCapture}
        onDrop={onDrop}
        onDropCapture={onDropCapture}
        onMouseDown={onMouseDown}
        onMouseDownCapture={onMouseDownCapture}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseMoveCapture={onMouseMoveCapture}
        onMouseOut={onMouseOut}
        onMouseOutCapture={onMouseOutCapture}
        onMouseOver={onMouseOver}
        onMouseOverCapture={onMouseOverCapture}
        onMouseUp={onMouseUp}
        onMouseUpCapture={onMouseUpCapture}
        onSelect={onSelect}
        onSelectCapture={onSelectCapture}
        onTouchCancel={onTouchCancel}
        onTouchCancelCapture={onTouchCancelCapture}
        onTouchEnd={onTouchEnd}
        onTouchEndCapture={onTouchEndCapture}
        onTouchMove={onTouchMove}
        onTouchMoveCapture={onTouchMoveCapture}
        onTouchStart={onTouchStart}
        onTouchStartCapture={onTouchStartCapture}
        onPointerDown={onPointerDown}
        onPointerDownCapture={onPointerDownCapture}
        onPointerMove={onPointerMove}
        onPointerMoveCapture={onPointerMoveCapture}
        onPointerUp={onPointerUp}
        onPointerUpCapture={onPointerUpCapture}
        onPointerCancel={onPointerCancel}
        onPointerCancelCapture={onPointerCancelCapture}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onPointerOver={onPointerOver}
        onPointerOverCapture={onPointerOverCapture}
        onPointerOut={onPointerOut}
        onPointerOutCapture={onPointerOutCapture}
        onGotPointerCapture={onGotPointerCapture}
        onGotPointerCaptureCapture={onGotPointerCaptureCapture}
        onLostPointerCapture={onLostPointerCapture}
        onLostPointerCaptureCapture={onLostPointerCaptureCapture}
        onScroll={onScroll}
        onScrollCapture={onScrollCapture}
        onWheel={onWheel}
        onWheelCapture={onWheelCapture}
        onAnimationStart={onAnimationStart}
        onAnimationStartCapture={onAnimationStartCapture}
        onAnimationEnd={onAnimationEnd}
        onAnimationEndCapture={onAnimationEndCapture}
        onAnimationIteration={onAnimationIteration}
        onAnimationIterationCapture={onAnimationIterationCapture}
        onTransitionEnd={onTransitionEnd}
        onTransitionEndCapture={onTransitionEndCapture}
        /*
          Standard HTML Attributes 
        */
        defaultChecked={defaultChecked}
        defaultValue={defaultValue}
        suppressContentEditableWarning={suppressContentEditableWarning}
        suppressHydrationWarning={suppressHydrationWarning}
        accessKey={accessKey}
        className={className}
        contentEditable={contentEditable}
        contextMenu={contextMenu}
        dir={dir}
        draggable={draggable}
        hidden={hidden}
        id={id}
        lang={lang}
        nonce={nonce}
        placeholder={placeholder}
        slot={slot}
        spellCheck={spellCheck}
        tabIndex={tabIndex}
        title={title}
        translate={translate}
        radioGroup={radioGroup}
        role={role}
        about={about}
        datatype={datatype}
        inlist={inlist}
        prefix={prefix}
        property={property}
        resource={resource}
        typeof={divTypeOf}
        vocab={vocab}
        autoCapitalize={autoCapitalize}
        autoCorrect={autoCorrect}
        autoSave={autoSave}
        itemProp={itemProp}
        itemScope={itemScope}
        itemType={itemType}
        itemID={itemID}
        itemRef={itemRef}
        results={results}
        security={security}
        unselectable={unselectable}
        inputMode={inputMode}
        /*
          Accessibility HTML attributes
        */
        aria-activedescendant={ariaActivedescendant}
        aria-atomic={ariaAtomic}
        aria-autocomplete={ariaAutocomplete}
        aria-busy={ariaBusy}
        aria-checked={ariaChecked}
        aria-colcount={ariaColcount}
        aria-colindex={ariaColindex}
        aria-colspan={ariaColspan}
        aria-controls={ariaControls}
        aria-current={ariaCurrent}
        aria-describedby={ariaDescribedby}
        aria-details={ariaDetails}
        aria-disabled={ariaDisabled}
        aria-dropeffect={ariaDropeffect}
        aria-errormessage={ariaErrormessage}
        aria-expanded={ariaExpanded}
        aria-flowto={ariaFlowto}
        aria-grabbed={ariaGrabbed}
        aria-haspopup={ariaHaspopup}
        aria-hidden={ariaHidden}
        aria-invalid={ariaInvalid}
        aria-keyshortcuts={ariaKeyshortcuts}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        aria-level={ariaLevel}
        aria-live={ariaLive}
        aria-modal={ariaModal}
        aria-multiline={ariaMultiline}
        aria-multiselectable={ariaMultiselectable}
        aria-orientation={ariaOrientation}
        aria-owns={ariaOwns}
        aria-placeholder={ariaPlaceholder}
        aria-posinset={ariaPosinset}
        aria-pressed={ariaPressed}
        aria-readonly={ariaReadonly}
        aria-relevant={ariaRelevant}
        aria-required={ariaRequired}
        aria-roledescription={ariaRoledescription}
        aria-rowcount={ariaRowcount}
        aria-rowindex={ariaRowindex}
        aria-rowspan={ariaRowspan}
        aria-selected={ariaSelected}
        aria-setsize={ariaSetsize}
        aria-sort={ariaSort}
        aria-valuemax={ariaValuemax}
        aria-valuemin={ariaValuemin}
        aria-valuenow={ariaValuenow}
        aria-valuetext={ariaValuetext}
        /*
          Forwarded ref
        */
        ref={ref}
        /*
          data-* related props
        */
        data-testid={testId}
        /*
          Style HTML attribute
        */
        $cssProps={style}
        $renderOn={renderOn}
      >
        {children}
      </Root>
    );
  }
);

Box.displayName = "Box";

export default Box;
