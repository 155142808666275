import { Avatar, Icon, ToolbarMenuItem } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";
import useStickyBannerProps from "src/js/components/layout/TopHeader/hooks/useStickyBannerContext";
import { useStores } from "src/js/hooks";
import { useMeQueryCached, useSpaceByIdCached } from "src/js/query";
import useUnreadThreadMessagesCounter from "src/js/hooks/useUnreadThreadMessagesCounter";
import { createUrl } from "src/js/modules/routing";
import { getAppRoutingContext } from "src/js/routing/appRoutingContext";
import { useTheme } from "styled-components";
import { StickyInfoBannerMobile } from "../StickyInfoBanner";
import { AcceptedPath, toolbarItems } from "./utils";
import { ChatButtonItem } from "../Toolbar/ChatButtonItem";

import * as S from "./ToolbarMobile.styles";

const ToolbarMobile = () => {
  const location = useLocation();
  const settingsURL = createUrl("settings_mobile");
  const isOverquota = location.pathname.includes("overquotablocked");
  const isActiveSettings = location.pathname.includes("settings");
  const stickyBannerProps = useStickyBannerProps();
  const context = getAppRoutingContext(location);
  const { whiteTheme } = useTheme();

  const {
    SpaceStore: {
      activeSpaceSlug,
      getLastActiveSpace,
      spacesListWithNoDemo,
      activeSpace,
      isDemoSpace
    }
  } = useStores();
  const { hasInSpaceUnreadMessages } = useUnreadThreadMessagesCounter();
  const { data: activeUser } = useMeQueryCached();

  const getHasEvent = (id: string) => {
    switch (id) {
      case "chat": {
        return (activeSpace ? hasInSpaceUnreadMessages() : false) as boolean;
      }
      case "calendar":
        return false; // add calendar as placeholder of the functionality
      default:
        return false;
    }
  };

  const handleItemClick = (path: AcceptedPath) => {
    if (path === "groups") {
      const lastActiveSpace = getLastActiveSpace();
      const spaceSlug =
        activeSpaceSlug ||
        lastActiveSpace?.space?.slug ||
        spacesListWithNoDemo[0]?.space.slug;
      // if user have 0 spaces
      if (!spaceSlug) {
        navigateTo(createUrl("home"));
        return;
      }

      navigateTo(
        createUrl("space_groups", {
          space_slug: spaceSlug
        })
      );
      return;
    }
    navigateTo(createUrl(path.replace("/", "") as never));
  };

  const showStickyBanner =
    (context === "groups" || context === "space") && !isDemoSpace;

  const { data } = useSpaceByIdCached(activeSpaceSlug, {
    enabled: !!activeSpaceSlug
  });

  if (!stickyBannerProps) return null;
  return (
    <S.MobileBottomNavigationBar isOverquota={isOverquota}>
      {showStickyBanner && <StickyInfoBannerMobile {...stickyBannerProps} />}
      {toolbarItems.map(({ id, path, icon }) => {
        const hasEvent = getHasEvent(id);
        return id === "chat" ? (
          !data?.spaceSettings.disableChat && <ChatButtonItem />
        ) : (
          <ToolbarMenuItem
            key={id}
            icon={<Icon icon={icon} height={20} width={20} initialViewbox />}
            onClick={() => {
              handleItemClick(path);
            }}
            theme={whiteTheme}
            id="toolbarHomeLink"
            isDisabled={context === "home"}
            isSelected={context === path}
            hasEvent={hasEvent}
          />
        );
      })}
      <S.AvatarContainer
        isActiveItem={isActiveSettings}
        onClick={() => navigateTo(settingsURL)}
      >
        {activeUser ? (
          <Avatar
            theme={whiteTheme}
            url={activeUser.avatar?.small}
            alt={activeUser.name}
            size="s"
            rounded
          />
        ) : null}
      </S.AvatarContainer>
    </S.MobileBottomNavigationBar>
  );
};

export default observer(ToolbarMobile);
