import { useState } from "react";
import { useHistory } from "react-router";
import { useGroupFeatures, useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { inviteMembersBulkToGroups } from "src/js/repository/inviteRepository";
import { useTranslation } from "src/js/translation";
import {
  GroupUserRoleEnum,
  InviteMembersBulkParams,
  SpaceUserRoleEnum
} from "src/js/types";

const useSendInviteMembers = () => {
  const { translate } = useTranslation();
  const { hideMembers } = useGroupFeatures();
  const history = useHistory();

  const {
    SpaceStore: { activeSpaceId },
    GroupStore: { activeGroup },
    ModalStore: { closeModal }
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);

  const handleInviteMembers = async ({
    groupIds,
    inviteRole,
    userEmails,
    inviteSpaceRole,
    skipCatch
  }: {
    groupIds: number[];
    inviteRole: GroupUserRoleEnum;
    userEmails: string[];
    inviteSpaceRole?: SpaceUserRoleEnum;
    skipCatch?: boolean;
  }) => {
    setIsLoading(true);
    try {
      const data: InviteMembersBulkParams = {
        groupIds,
        groupInviteUser: {
          inviteRole,
          inviteSpaceRole,
          userEmails,
          freeInvitation: false
        }
      };
      const response = await inviteMembersBulkToGroups(data);
      closeModal();
      if (!hideMembers) {
        const state = { pendingInvites: true };
        const newPath = createUrl(
          activeGroup?.id ? "group_settings" : "dashboard_users",
          {
            space_id: activeSpaceId,
            group_id: activeGroup?.id,
            option: "members"
          }
        );
        if (history.location.pathname === newPath) {
          history.replace({ pathname: newPath, state });
        } else {
          history.push({ pathname: newPath, state });
        }
      }
      showToastSuccess({
        str: translate("toast_invites_async_flow_start")
      });
      return response;
    } catch (error) {
      if (!skipCatch) {
        showToastError({
          str: translate("invite_modal_email_invite_toast_error")
        });
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleInviteMembers,
    isLoadingInviteMembers: isLoading
  };
};

export default useSendInviteMembers;
