import { useEffect } from "react";
import { useParams } from "react-router";
import { useSpaceByIdCached } from "src/js/query/";
import { navigateTo } from "src/legacy/modules/history";
import { createURL } from "src/legacy/modules/utility";

export const useRedirectIfChatDisabled = () => {
  const { space_slug } = useParams<{ space_slug: string | null }>();

  const { data } = useSpaceByIdCached(space_slug, { enabled: !!space_slug });

  useEffect(() => {
    if (!data) return;
    if (data.spaceSettings.disableChat) {
      navigateTo(createURL("home"));
    }
  }, [data?.spaceSettings.disableChat]);
};
