import { useQuery, UseQueryOptions } from "src/js/query/hooks";
import { countryQueryKey } from "src/js/query/location/country.query";
import {
  CountryDTO,
  fetchCountries
} from "src/js/repository/locationRepository";

export const useCountries = (
  options?: UseQueryOptions<CountryDTO[], Error, CountryDTO[]>
) => {
  const { data: countries } = useQuery<CountryDTO[]>(
    countryQueryKey.all,
    () => fetchCountries(),
    options
  );

  return {
    countries
  };
};
