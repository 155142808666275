import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { Button, Text } from "@arcadia/design-system";
import { navigateTo } from "src/legacy/modules/history";
import {
  translateString,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { GroupJoinWithCodeModal } from "src/js/components/GroupJoinWithCodeModal";
import { createUrl } from "src/js/modules/routing";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { LogoutModal } from "src/js/components/modal/LogoutModal";
import { SettingTabs } from "./SettingsPage.types";
import { SettingOptions } from "./SettingsPage.utils";

import * as S from "./SettingsPage.styles";

const SettingsPage = () => {
  const { headerHeight } = useAppLayout();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal, closeModal }
  } = useStores();

  useEffect(() => {
    if (!isLayoutModeMobile) {
      navigateTo(createUrl("user_profile_settings"));
    }
  }, [isLayoutModeMobile]);

  const onClickTile = useCallback(
    (type: SettingTabs) => () => {
      switch (type) {
        case "help":
          if (typeof zE !== "undefined") {
            zE.activate({ hideOnClose: true });
          }
          break;
        case "notifications":
          navigateTo(createUrl("user_notification_settings"));
          break;
        case "account":
          navigateTo(createUrl("user_manage_account_settings"));
          break;
        case "profile":
          navigateTo(createUrl("user_profile_settings"));
          break;
        case "joinGroupWithCode":
          openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
          break;
        default:
          break;
      }
    },
    []
  );

  const modalBody = () => <LogoutModal />;

  return (
    <S.SettingsWrapper headerHeight={headerHeight}>
      {SettingOptions.map(({ icon, type, text }) => (
        <S.Tile key={type} onClick={onClickTile(type)}>
          <S.LeftWrapper>
            <S.LeftIcon icon={icon} initialViewbox width={16} height={16} />
            <S.Text>{translate(text)}</S.Text>
          </S.LeftWrapper>
          <S.ChevronIcon />
        </S.Tile>
      ))}
      <S.ButtonWrapper>
        <Button
          variant="primary"
          fullWidth
          theme={whiteTheme}
          onClick={() => openModal(modalBody)}
        >
          <Text type="headerTitleLg">
            {translateString({ text: "logout" })}
          </Text>
        </Button>
      </S.ButtonWrapper>
    </S.SettingsWrapper>
  );
};

export default observer(SettingsPage);
