import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Column = styled.div<{ width?: string; greyColor?: boolean }>`
  color: ${({ theme, greyColor }) =>
    greyColor ? theme.colors.blueGrey[500] : theme.colors.grey[50]};
  width: ${({ width }) => width || "20%"};
  padding-right: 20px;
  overflow-wrap: break-word;
`;

export const TriggerRowArea = styled.span`
  width: calc(100% - 44px);
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TopMobile = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};
  padding: 16px;
  display: flex;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const TopMobileTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
`;

export const EllipsedText = styled(Text)`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameAndMailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
`;

export const AvatarNameWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  min-width: 0;
`;

export const BottomMobile = styled.div`
  width: 100%;
  padding: 16px 16px 24px;
  font-size: 14px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 6px;
`;

export const BottomMobileTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 8px;
`;

export const TabletAndUp = styled.span`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

export const PermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Permission = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

export const CheckIconContainer = styled.span`
  color: ${({ theme }) => theme.colors.success[500]};
  margin-right: 8px;
  display: flex;
`;

export const CrossIconContainer = styled.span`
  color: ${({ theme }) => theme.colors.error[500]};
  margin-right: 8px;
  display: flex;
`;

export const PermissionName = styled.span`
  font-size: 16px;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`;

export const HideOnMobile = styled.span`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
`;
