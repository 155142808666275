import { useEffect, useState } from "react";
import { TranslationKeys } from "src/js/translation";
import { CustomFieldDTO, TargetAudienceIdDTO } from "src/js/types";
import {
  CustomFieldsEnum,
  CustomFieldsState,
  CustomFieldsStateId,
  DTOCustomFieldMapped
} from "../components/sections/CustomFields/types";

const fromResponseToCustomFields = (
  customFields: CustomFieldDTO[]
): CustomFieldsState => {
  const result: CustomFieldsState = {} as CustomFieldsState;

  Object.values(CustomFieldsEnum).forEach(key => {
    const ids = DTOCustomFieldMapped[key];
    const isChecked = !ids
      ? false
      : customFields.some(field => ids.includes(Number(field.id)));
    result[key] = {
      name: `space_settings_custom_field_checkbox_${key}_label` as TranslationKeys,
      checked: isChecked
    };
  });

  return result;
};

const useCustomFields = ({
  customFieldsInit = []
}: {
  customFieldsInit: CustomFieldDTO[];
}) => {
  const [customFields, setCustomFieldsState] = useState<CustomFieldsState>(
    fromResponseToCustomFields(customFieldsInit)
  );

  const [targetAudienceState, setTargetAudience] = useState(
    customFieldsInit.length === 0 ? null : customFieldsInit[0].targetAudience
  );
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setCustomFieldsState(fromResponseToCustomFields(customFieldsInit));
    setTargetAudience(
      customFieldsInit.length === 0 ? null : customFieldsInit[0].targetAudience
    );
  }, [JSON.stringify(customFieldsInit)]);

  const setCustomFields = (id: CustomFieldsStateId, newValue: boolean) => {
    setIsDirty(true);

    setCustomFieldsState(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        checked: newValue
      }
    }));
  };

  return {
    customFields,
    setCustomFields,
    targetAudience: targetAudienceState,
    setTargetAudience: (audience: TargetAudienceIdDTO) => {
      setIsDirty(true);
      setTargetAudience(audience);
    },
    isCustomFieldDirty: isDirty,
    onSubmit: () => {
      setIsDirty(false);
    }
  };
};

export default useCustomFields;
