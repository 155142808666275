import colors from "@ds_themes/colors";
import { Icon } from "@ds_universal/Icon";
import { Loader } from "@ds_universal/Loader";
import { Text } from "@ds_universal/Text";
import React, { FC, forwardRef } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./CardLesson.styles";
import { CardLessonProps } from "./types";

const CardLesson: FC<CardLessonProps> = forwardRef<
  HTMLDivElement,
  CardLessonProps
>(
  (
    {
      theme,
      title,
      usersLabel,
      boardElementsLabel,
      imgUrl = "",
      altText = "",
      onOptionsClick,
      onClick,
      hideOptions,
      isLoading,
      creator,
      boardsCount,
      completedBoardElementsCount,
      boardElementsCount,
      nextBoardElement,
      onNextBoardElementClick,
      completedText,
      emptyText,
      onCreateModuleClick,
      // recordNavigationText,
      noModulesText,
      ...rest
    },
    ref
  ) => {
    const ariaLabelSubscribers = `with ${usersLabel} users`;
    const ariaLabel = `${title} ${
      isLoading ? "loading" : ariaLabelSubscribers
    }`;

    const progressPercentage =
      boardElementsCount && completedBoardElementsCount
        ? (completedBoardElementsCount / boardElementsCount) * 100
        : null;

    let progressColor = colors.success[500];

    if (progressPercentage && progressPercentage < 66)
      progressColor = colors.orange[500];

    if (progressPercentage && progressPercentage < 33)
      progressColor = colors.error[500];

    // PickertestOpenanswer

    return (
      <ThemeProvider theme={theme}>
        <S.CardLesson
          onClick={onClick}
          aria-label={ariaLabel}
          isLoading={isLoading}
          {...rest}
          ref={ref}
        >
          {!hideOptions ? (
            <S.Option
              onClick={e => {
                e.stopPropagation();
                onOptionsClick?.(e);
              }}
              tabIndex={0}
              aria-label="option"
            >
              <Icon icon="kebab" width="16" height="16" />
            </S.Option>
          ) : null}

          <S.Cover>
            <img src={imgUrl} alt={altText} />
          </S.Cover>

          <S.WrapperText>
            {creator ? (
              <S.CreatorSection>
                <S.StyledAvatar
                  url={creator.avatar?.small}
                  alt={`${creator.name} ${creator.surname} avatar`}
                  size="image"
                  theme={theme}
                  rounded
                ></S.StyledAvatar>
                <S.CreatorSectionText>
                  {creator.name} {creator.surname}
                </S.CreatorSectionText>
              </S.CreatorSection>
            ) : null}
            <S.WrapperTitle>
              {isLoading ? (
                <Loader
                  loaderType="CircularLoader"
                  theme={theme}
                  loaderWidth="24px"
                  loaderHeight="24px"
                  width="24px"
                  height="24px"
                  mainColor={theme.card.cardlesson.loading.color}
                  borderColor={theme.card.cardlesson.loading.color}
                />
              ) : null}
              <Text type="subHeaderTitle">{title}</Text>
            </S.WrapperTitle>
            {progressPercentage ? (
              <S.ProgressBarContainer>
                <S.ProgressBar
                  percentage={progressPercentage}
                  color={progressColor}
                />
              </S.ProgressBarContainer>
            ) : null}
            {progressPercentage && progressPercentage === 100 ? (
              <S.CompletedProgressContainer>
                <S.CompletedProgressIconContainer color={progressColor}>
                  <Icon icon="check2" height={10} width={10} />
                </S.CompletedProgressIconContainer>
                {completedText}
              </S.CompletedProgressContainer>
            ) : null}
            {nextBoardElement ? (
              <S.ColoredContainer
                onClick={e => {
                  e.stopPropagation();
                  onNextBoardElementClick?.(e);
                }}
              >
                <S.NextIconContainer>
                  <Icon icon="play" height={6} width={6} />
                </S.NextIconContainer>
                <S.EllipsedText>{nextBoardElement.name}</S.EllipsedText>
              </S.ColoredContainer>
            ) : null}
            {boardElementsCount === 0 ? (
              <S.ColoredContainer>
                <S.NoModulesIconContainer>
                  <Icon icon="dangerCircle" height={16} width={16} />
                </S.NoModulesIconContainer>
                <S.EllipsedText>{noModulesText}</S.EllipsedText>
              </S.ColoredContainer>
            ) : null}
            {!hideOptions && boardsCount === 0 ? (
              <S.ColoredContainer
                onClick={e => {
                  e.stopPropagation();
                  onCreateModuleClick?.(e);
                }}
              >
                {/* <S.NoModulesIconContainer> */}
                <Icon icon="pencil" height={20} width={20} />
                {/* </S.NoModulesIconContainer> */}
                <S.EllipsedText>{emptyText}</S.EllipsedText>
              </S.ColoredContainer>
            ) : null}
            <S.WrapperCounters>
              {usersLabel ? (
                <Text type="formSubtitle">{usersLabel}</Text>
              ) : null}
              {boardElementsLabel ? (
                <Text type="formSubtitle">{boardElementsLabel}</Text>
              ) : null}
            </S.WrapperCounters>
          </S.WrapperText>
        </S.CardLesson>
      </ThemeProvider>
    );
  }
);

export default CardLesson;
