import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./ConfirmationAlert.styles";
import { ConfirmationAlertProps } from "./types";

const ConfirmationAlert: FC<ConfirmationAlertProps> = ({
  theme,
  text,
  onDeclineFunction,
  declineText,
  onAcceptFunction,
  acceptText,
  children,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ConfirmationAlertContainer {...props}>
        <S.ConfirmationAlertIcon>
          <Icon icon="dangerCircle" width={16} height={16} />
        </S.ConfirmationAlertIcon>
        <S.ConfirmationAlertInfoContainer>
          <S.ConfirmationAlertMessage>
            <Text type="cta">{children ?? text}</Text>
          </S.ConfirmationAlertMessage>

          <S.ConfirmationAlertAction>
            <S.ConfirmationAlertAccept onClick={onAcceptFunction}>
              <Text type="cta">{acceptText}</Text>
            </S.ConfirmationAlertAccept>
            {declineText && onDeclineFunction ? (
              <S.ConfirmationAlertDecline onClick={onDeclineFunction}>
                <Text type="cta">{declineText}</Text>
              </S.ConfirmationAlertDecline>
            ) : null}
          </S.ConfirmationAlertAction>
        </S.ConfirmationAlertInfoContainer>
      </S.ConfirmationAlertContainer>
    </ThemeProvider>
  );
};

export default ConfirmationAlert;
