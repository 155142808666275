import { __JITSI_PATH__ } from "src/js/settings/settingsUrl";
import { AppUrlKeys, AppUrlRouteParams } from "./types";

let spaceSlug = null;
const baseGroupUrl = "/{space_slug}/group/";
const baseChatUrl = "/{space_slug}/chat/";

export const updateSpaceSlug = value => {
  spaceSlug = value;
};

const appUrl: Record<AppUrlKeys, string> = {
  activate_account: "/activateaccount/{option}",
  activity_inclass: `${baseGroupUrl}{group_id}/activity/inclass`,
  activity_examination: `${baseGroupUrl}{group_id}/activity/examination`,
  agenda: `${baseGroupUrl}{group_id}/calendar/agenda/{date}`,
  appLocker: "/applocker",
  auth: "/authFlow/{auth_token}",
  externalAuth: "auth",
  activity: `${baseGroupUrl}{group_id}/activity`,
  calendar: `${baseGroupUrl}{group_id}/calendar`,
  exercises: `${baseGroupUrl}{group_id}/exercises`,
  exercises_autostart: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/run/autostart`,
  exercises_drafts: `${baseGroupUrl}{group_id}/exercises/drafts`,
  exercise_detail: `${baseGroupUrl}{group_id}/exercises/{exercise_id}`,
  exercises_edit: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/edit`,
  exercises_fake: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/run/fake`,
  exercises_run: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/run`,
  exercises_reports_sessions: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/reports/sessions/{session_id}`,
  exercises_reports_users: `${baseGroupUrl}{group_id}/exercises/{exercise_id}/reports/users`,
  event_activity: `${baseGroupUrl}{group_id}/calendar/activity/{event_id}`,
  event_deadline: `${baseGroupUrl}{group_id}/calendar/deadline/{event_id}`,
  event_live: `${baseGroupUrl}{group_id}/calendar/live/{event_id}`,
  home: "/home",
  group_blocked: `${baseGroupUrl}{group_id}/blocked`,
  group_overquota_blocked: `${baseGroupUrl}{group_id}/overquotablocked`,
  group_settings: `${baseGroupUrl}{group_id}/settings/{option}`,
  join: "/join/{alias_id}",
  login: "/login",
  login_sso: "/sso",
  login_university: "/loginuniversity",
  logout: "/logout",
  moderation: `${baseGroupUrl}{group_id}/moderation`,
  notifications: "/{space_slug}/notifications",
  dashboard_groups: "/dashboard/{space_id}/groups",
  dashboard_analytics: "/dashboard/{space_id}/analytics",
  dashboard_users: "/dashboard/{space_id}/users",
  dashboard_staff: "/dashboard/{space_id}/staff",
  dashboard_settings: "/dashboard/{space_id}/settings/{option}",
  dashboard_badges: "/dashboard/{space_id}/badges",
  module_detail: `${baseGroupUrl}{group_id}/modules/{module_id}`,
  module_detail_drafts: `${baseGroupUrl}{group_id}/modules/{module_id}/drafts`,
  module_edit: `${baseGroupUrl}{group_id}/modules/{module_id}`,
  module_element: `${baseGroupUrl}{group_id}/modules/{module_id}/elements/{element_id}`,
  module_element_edit: `${baseGroupUrl}{group_id}/modules/{module_id}/elements/{board_element_id}/edit`,
  module_element_edit_draft: `${baseGroupUrl}{group_id}/modules/{module_id}/elements/{board_element_id}/draft/edit`,
  module_new: `${baseGroupUrl}{group_id}/modules/new`,
  modules: `${baseGroupUrl}{group_id}/modules`,
  onboarding_init: "/onboarding/init",
  onboarding_welcome: "/onboarding/welcome",
  onboarding_school_space_confirmation: "/onboarding/school-space-confirmation",
  parent_activation_url: "/underageapprovation/{user_code}",
  post_edit: `${baseGroupUrl}{group_id}/post/edit/{post_id}`,
  post_detail: `${baseGroupUrl}{group_id}/post/{post_id}`,
  post_resource: `${baseGroupUrl}{group_id}/post/{post_id}/resource/{resource_id}`,
  post_resource_edit: `${baseGroupUrl}{group_id}/post/{post_id}/resource/{resource_id}`,
  post_resource_comments: `${baseGroupUrl}{group_id}/post/{post_id}/resource/{resource_id}/comments`,
  profile_public: "/users/{user_uuid}/profile",
  ranking: `${baseGroupUrl}{group_id}/ranking/{page_number}`,
  report_abuse_post: `${baseGroupUrl}{group_id}/post/{post_id}/report`,
  requestPassword: "/password/request",
  requestRegistration: "/requestregistration",
  register: "/register",
  registerToken: "/register/{token}",
  report_issue: `${baseGroupUrl}{group_id}/issue/report`,
  search: "/search",
  space_groups: "/{space_slug}/groups",
  space_groups_overquota_blocked: "/{space_slug}/groups/overquotablocked",
  space_notification: "/{space_slug}{redirectUrl}",
  space_chats: "/{space_slug}/chats",
  chat_new: `${baseChatUrl}new`,
  chat_thread: `${baseChatUrl}thread/{thread_id}`,
  underage_lock: "/underagelock",
  settings_mobile: "/settings",
  user_profile_settings: "/settings/profile",
  user_profile_settings_edit: "/settings/profile/edit",
  user_manage_account_settings: "/settings/account",
  user_notification_settings: "/settings/notice",
  user_tips_settings: "/settings/tips",
  virtual_class: `${__JITSI_PATH__}{classroom_id}`,
  wall: `${baseGroupUrl}{group_id}/wall`,
  whiteboard: `${baseGroupUrl}{group_id}/whiteboard`
};

export function createUrl<TName extends keyof AppUrlRouteParams = never>(
  ...args: AppUrlRouteParams[TName] extends undefined
    ? [TName]
    : [TName, AppUrlRouteParams[TName]]
) {
  const [type, obj] = args;
  let result = "";
  const objectWithSpaceSlug = { space_slug: spaceSlug, ...obj };
  const appUrlType = appUrl[type];
  if (typeof appUrlType !== "undefined") {
    const url = appUrlType;
    result = url.replace(/\{(\w+)\}/g, (_, k) => {
      return objectWithSpaceSlug[k];
    });
  }
  return result;
}

export function createStaticUrl<TName extends keyof AppUrlRouteParams = never>(
  ...args: AppUrlRouteParams[TName] extends undefined
    ? [TName]
    : [TName, AppUrlRouteParams[TName]]
) {
  // @ts-expect-error args can not be inferred because of conditional type logic
  const originalUrl = createUrl(...args);
  return originalUrl.substring(1);
}

export { createUrl as default };
