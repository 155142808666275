/* eslint-disable no-shadow */
import {
  Dispatch,
  ReducerAction,
  ReducerState,
  useEffect,
  useReducer
} from "react";
import {
  InfiniteFetcherCallback,
  InfiniteFetcherState,
  InfiniteFetchReducerAction
} from "./types";

type Reducer<TData> = (
  state: InfiniteFetcherState<TData>,
  { type, payload }: InfiniteFetchReducerAction<TData>
) => InfiniteFetcherState<TData>;

const initialState: InfiniteFetcherState = {
  page: 0,
  data: undefined,
  isLoading: true,
  hasMoreResults: true,
  error: undefined
};

const reducer: Reducer<unknown> = (
  state,
  { type, payload }
): InfiniteFetcherState<unknown> => {
  switch (type) {
    case "fetch": {
      const isLoading = !(payload.page > 0);

      return {
        ...state,
        isLoading
      };
    }

    case "success": {
      const { data, limit, page } = payload || {};

      let newData = data;

      if (page > 0) {
        newData = [
          ...((state.data || []) as Array<unknown>),
          ...((data || []) as Array<unknown>)
        ];
      }

      let hasMoreResults = false;

      if (Array.isArray(data) && data.length >= limit) {
        hasMoreResults = true;
      }

      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: newData,
        page: state.page + 1,
        hasMoreResults
      };
    }

    case "error": {
      return {
        ...state,
        isLoading: false,
        error: payload?.error
      };
    }

    case "reset": {
      return {
        ...initialState,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

/**
 * @deprecated use useInfiniteQuery from [src/js/query/hooks](../../query/hooks/useInfiniteQuery.ts) instead
 */
const useInfiniteScrollFetcher = <
  TData extends Array<unknown>,
  RArgs extends Record<string, unknown> = Record<string, unknown>
>(
  cb: InfiniteFetcherCallback<TData, RArgs>,
  options?: Partial<{
    limit: number;
    lazy: boolean;
    onSuccess: (data: TData, args?: RArgs) => void;
  }>
) => {
  const [{ page, data, isLoading, hasMoreResults }, dispatch] = useReducer(
    reducer,
    initialState
  ) as [ReducerState<Reducer<TData>>, Dispatch<ReducerAction<Reducer<TData>>>];

  const { limit = 10, lazy, onSuccess } = options || {};

  const callFetchCb = async (_page = 0, args: RArgs = {} as never) => {
    try {
      dispatch({ type: "fetch", payload: { page: _page } });

      const res = await cb({
        limit,
        page: _page,
        ...args
      } as never);

      dispatch({
        type: "success",
        payload: { data: res, limit, page: _page }
      });

      onSuccess?.(res, args);
    } catch (error) {
      dispatch({ type: "error", payload: { error } });
    }
  };

  useEffect(() => {
    if (!lazy) {
      callFetchCb();
    }
  }, []);

  const fetchNextPage = (args?: RArgs) => {
    callFetchCb(page, args);
  };

  return {
    data,
    isLoading,
    hasMoreResults,
    page,
    reset: () => {
      dispatch({ type: "reset" });
    },
    fetchNextPage,
    fetch: callFetchCb
  };
};

export default useInfiniteScrollFetcher;
