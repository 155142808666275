import {
  Box,
  ContentSwitcher,
  ModalSizeType,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupUserRoleEnum, InviteMode, InviteModeType } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import styled, { useTheme } from "styled-components";
import SectionInviteEmail from "./SectionInviteEmail";
import SectionInviteImport from "./SectionInviteImport";
import SectionInviteLink from "./SectionInviteLink";

const StyledContentSwitcher = styled(ContentSwitcher)`
  margin: 24px auto;
`;

const StyledSectionWrapper = styled(Box)`
  min-height: 500px;
  max-height: 620px;
  padding: 0px 16px;
  @media (max-width: 767px) {
    min-height: unset;
    max-height: unset;
    padding: 8px 16px 32px;
  }
`;

const GroupInviteMemberModal = ({
  initialInviteMode = InviteMode.Email,
  initialGroupIds,
  initialInviteMemberRole,
  onClose
}: {
  initialInviteMode?: InviteModeType;
  initialGroupIds?: number[];
  initialInviteMemberRole?: GroupUserRoleEnum;
  onClose?: () => void;
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal },
    GroupStore: { groupName }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const currentTheme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const [inviteMode, setInviteMode] = useState(initialInviteMode);

  const renderInviteSection = () => {
    const propsSection = { theme: currentTheme };
    switch (inviteMode) {
      case InviteMode.Email:
        return (
          <SectionInviteEmail
            initialGroupIds={initialGroupIds}
            {...propsSection}
          />
        );
      case InviteMode.Link:
        return (
          <SectionInviteLink
            isMultiGroupMode={initialGroupIds?.length > 0}
            {...propsSection}
          />
        );
      case InviteMode.Import:
        return (
          <SectionInviteImport
            initialInviteMemberRole={initialInviteMemberRole}
            initialGroupIds={initialGroupIds}
            {...propsSection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalLayout
      desktopProps={{
        size: ModalSizeType.Big
      }}
      mobileProps={{
        withDrag: false
      }}
      labels={{
        title: translate("invite_modal_title", {
          groupName:
            groupName?.length > 40 ? `${groupName?.slice(0, 37)}...` : groupName
        }),
        mobileClose: translate("close")
      }}
      closeModal={onClose || closeModal}
    >
      <StyledContentSwitcher
        theme={greyTheme}
        items={[
          {
            id: UsetifulId.InvitationModalEmailSection,
            value: InviteMode.Email,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "invite_modal_email_invite"
                })}
              </Text>
            )
          },
          {
            id: UsetifulId.InvitationModalLinklSection,
            value: InviteMode.Link,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "invite_modal_link_invite"
                })}
              </Text>
            )
          },
          {
            id: UsetifulId.InvitationModalImportSection,
            value: InviteMode.Import,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "invite_modal_import"
                })}
              </Text>
            )
          }
        ]}
        activeOption={inviteMode}
        onContentItemChange={val => {
          setInviteMode(val as InviteModeType);
        }}
      />
      <StyledSectionWrapper>{renderInviteSection()}</StyledSectionWrapper>
    </ModalLayout>
  );
};

export default observer(GroupInviteMemberModal);
