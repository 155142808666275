import { Box, IconName } from "@arcadia/design-system";
import React, { forwardRef } from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores } from "src/js/hooks";
import * as S from "./TileButton.styles";

type TileButtonProps = {
  id: string;
  loading?: boolean;
  icon: IconName;
  title: string;
  description: string;
  disabledStyle?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const TileButton = forwardRef<HTMLButtonElement, TileButtonProps>(
  (
    {
      loading,
      disabledStyle,
      disabled,
      onClick,
      icon,
      title,
      description,
      ...props
    },
    ref
  ) => {
    const {
      UIStore: { isLayoutModeMobile }
    } = useStores();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled && onClick) {
        onClick(event);
      }
    };

    return (
      <Skeleton loading={loading} borderRadius={8}>
        <S.ButtonWrapper
          {...props}
          ref={ref}
          onClick={handleClick}
          disabledStyle={disabledStyle || disabled}
          disabled={disabled}
          aria-disabled={disabled}
        >
          <S.Column>
            <S.TitleRow>
              <S.StyledIcon icon={icon} initialViewbox width={24} height={24} />
              <Box display="flex" alignItems="center">
                <S.StyledText type="formField">{title}</S.StyledText>
              </Box>
            </S.TitleRow>
            {!isLayoutModeMobile ? (
              <S.CardDescription type="body">{description}</S.CardDescription>
            ) : null}
          </S.Column>
          <S.StyledIcon icon="plusThin" width={16} height={16} />
        </S.ButtonWrapper>
      </Skeleton>
    );
  }
);

TileButton.displayName = "TileButton";

export default TileButton;
