import {
  Box,
  Button,
  DoodleLoader,
  Heading,
  ModalSizeType,
  SearchBar,
  Text
} from "@arcadia/design-system";
import { useQueryClient } from "@tanstack/react-query";
import spacesNoUsersImage from "Images/spaces-no-user.png";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSelector } from "src/js/components/global/ListSelector";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useDebounce, useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import { showToastSuccess } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { StaffUserCard } from "src/js/pages/spaces/dashboard/Staff/components/StaffUserCard";
import { ToggleBlock } from "src/js/pages/spaces/dashboard/Staff/components/ToggleBlock";
import { useMutation } from "src/js/query/hooks";
import { staffQueryKey } from "src/js/query/staff";
import {
  fetchSpaceUsers,
  updateSpaceUserRole
} from "src/js/repository/dashboardRepository";
import { Trans } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  SpaceUserRoleEnum,
  StandardPaginationResponse,
  User
} from "src/js/types";
import {
  SpaceUserMember,
  SpaceUsersQueryParam,
  SpaceUsersQueryParamType,
  StaffMember
} from "src/js/types/models/Staff";
import { useTheme } from "styled-components";
import * as S from "./AddStaffModal.styles";

const AddStaffModal = ({
  listQueryParams
}: {
  listQueryParams: {
    spaceId: string;
    offset: number;
    limit: number;
    name?: string;
  };
}) => {
  const {
    SpaceStore: { activeSpaceName, activeSpaceId },
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser }
  } = useStores();
  const queryClient = useQueryClient();
  const [queryString, setQueryString] = useState("");
  const [userSelected, setUserSelected] = useState<Partial<User>>();
  const queryStringDebounced = useDebounce(queryString, 300);
  const [listStep, setListStep] = useState(true);
  const [accessPermission, setAccessPermission] = useState(false);
  const { greyTheme, whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const theme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const { name, surname, email, avatar, id } = userSelected || {};
  const disabled = id === activeUser?.id;

  const {
    data: usersList = [],
    reset,
    isLoading,
    hasMoreResults,
    fetch,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    SpaceUserMember[],
    {
      name?: string;
      spaceId: string;
      role?: SpaceUsersQueryParamType;
    }
  >(
    async ({ limit, page: _page }) => {
      const data = await fetchSpaceUsers({
        spaceId: activeSpaceId,
        name: queryStringDebounced,
        limit,
        offset: _page * limit,
        role: SpaceUsersQueryParam.NonStaff
      });
      return data?.results;
    },
    { lazy: true, limit: 30 }
  );

  useEffect(() => {
    if (activeSpaceId) {
      fetch(0, {
        spaceId: activeSpaceId,
        name: queryStringDebounced
      });
    }
    return () => reset();
  }, [activeSpaceId, queryStringDebounced]);

  const selectUserById = userId => {
    const userItem = usersList.find(user => user.id === userId);
    setUserSelected(userItem);
  };

  const { mutate: addRole } = useMutation(
    (params: { spaceId: string; userId: number; role: SpaceUserRoleEnum }) =>
      updateSpaceUserRole({ ...params }),
    {
      updateQueryKey: () => staffQueryKey.members(listQueryParams),
      onSuccess(
        data: { user: StaffMember },
        variables,
        context: StandardPaginationResponse<StaffMember> & {
          unfilteredTotal: number;
        }
      ) {
        if (context.results.some(user => user?.id === data.user?.id)) return;
        queryClient.setQueryData(staffQueryKey.members(listQueryParams), {
          ...context,
          results: [...context.results, data.user],
          total: context.total + 1,
          unfilteredTotal: context.unfilteredTotal + 1
        });
        showToastSuccess({
          str: (
            <Trans
              i18nKey={
                variables.role === SpaceUserRoleEnum.Admin
                  ? "staff_global_toast_permission_updated_full"
                  : "staff_global_toast_permission_updated_partial"
              }
              values={{ spaceName: activeSpaceName }}
            />
          )
        });
      },
      onStartMutating() {
        closeModal();
      },
      onErrorMessage: "staff_global_toast_permission_update_error"
    }
  );

  const submitPermission = ({ accessPermission, userId }) => {
    const role = accessPermission
      ? SpaceUserRoleEnum.Admin
      : SpaceUserRoleEnum.PowerUser;

    addRole({ spaceId: activeSpaceId, userId, role });
  };

  const UserListWithFormattedName = useMemo(() => {
    return usersList.map(user => {
      return {
        id: user?.id,
        name: (
          <S.ListElementWrapper>
            <Text type="formDescription">
              <S.Ellipsis>
                {user?.name}&nbsp;<strong>{user?.surname}</strong>
              </S.Ellipsis>
            </Text>
            <S.ListElementMail>
              <Text type="formSmallCaption">{user?.email}</Text>
            </S.ListElementMail>
          </S.ListElementWrapper>
        )
      };
    });
  }, [usersList]);

  const modalBody = listStep ? (
    <S.BodyContainer>
      <S.SearchContainer>
        <SearchBar
          id="search"
          theme={theme}
          onChange={event => {
            setQueryString(event.target.value);
          }}
          value={queryString}
          placeholder={translate("space_settings_user_search_label")}
        />
      </S.SearchContainer>
      {isLoading ||
      (UserListWithFormattedName && UserListWithFormattedName.length > 0) ? (
        <>
          <S.DescriptionLabel>
            <Text type="label">{translate("name")}</Text>
          </S.DescriptionLabel>
          <ScrollableDiv maxHeight="158px" id="user-permission-list">
            <InfiniteScroll
              scrollableTarget="user-permission-list"
              dataLength={UserListWithFormattedName.length}
              next={() => {
                fetchNextPage();
              }}
              hasMore={hasMoreResults}
              loader={<DoodleLoader theme={theme} isMini />}
            >
              <ListSelector
                itemList={UserListWithFormattedName}
                selectedItem={userSelected?.id}
                toggleItem={item => {
                  selectUserById(item.id);
                }}
              />
            </InfiniteScroll>
          </ScrollableDiv>
        </>
      ) : (
        <S.NoResult>
          <S.NoUsersImage src={spacesNoUsersImage} />
          <S.NoUserTitle>
            <Heading level="1">
              {translate("space_not_found_user_title")}
            </Heading>
          </S.NoUserTitle>
          <Text type="formSubtitle">
            {translate("space_not_found_user_subtitle")}
          </Text>
        </S.NoResult>
      )}
    </S.BodyContainer>
  ) : (
    <S.BodyContainer>
      <Box margin="0 0 8px">
        <Heading level="5">
          {translate("space_settings_modal_user_label")}
        </Heading>
      </Box>
      <StaffUserCard
        firstname={name}
        lastname={surname}
        email={email}
        avatar={avatar?.small}
      />
      <Box margin="24px 0 4px">
        <Heading level="5">
          {translate({
            text: "staff_modify_permissions_update",
            stringVariables: { userName: name }
          })}
        </Heading>
      </Box>
      <Box margin="0 0 24px">
        <Text type="body">
          {translate("staff_modify_permissions_description")}
        </Text>
      </Box>
      <ToggleBlock
        marginBottom={12}
        isSelected
        disabled
        icon="plusCircle"
        tooltipTitle={translate(
          "staff_modify_permissions_tooltip_create_groups"
        )}
        tooltipDescription={translate(
          "staff_modify_permissions_tooltip_create_groups_description"
        )}
      >
        <Text type="formField">
          {translate({
            text: "spaces_group_creation_permission_bold_label",
            stringVariables: { spaceName: activeSpaceName }
          })}
        </Text>
      </ToggleBlock>
      <ToggleBlock
        isSelected={accessPermission}
        disabled={disabled}
        onClick={() => setAccessPermission(prevState => !prevState)}
        icon="compassEmpty"
        tooltipTitle={translate(
          "staff_modify_permissions_tooltip_access_dashboard"
        )}
        tooltipDescription={translate(
          "staff_modify_permissions_tooltip_access_dashboard_description"
        )}
      >
        <Text type="formField">
          {translate("spaces_access_permission_bold_label")}
        </Text>
      </ToggleBlock>
    </S.BodyContainer>
  );

  const userListButtons =
    usersList.length > 0 ? (
      <>
        <Button
          onClick={closeModal}
          variant="secondary"
          theme={theme}
          fullWidth={isLayoutModeMobile}
        >
          {translate("undo_button_tooltip")}
        </Button>
        <Button
          disabled={!userSelected}
          onClick={() => setListStep(false)}
          variant="primary"
          theme={theme}
          fullWidth={isLayoutModeMobile}
        >
          {translate("confirm_button_label")}
        </Button>
      </>
    ) : (
      <Button
        disabled={!userSelected}
        onClick={() => {
          closeModal();
          navigateTo(createUrl("home"));
        }}
        variant="primary"
        theme={theme}
        fullWidth={isLayoutModeMobile}
      >
        {translate("spaces_settings_navigate_home")}
      </Button>
    );

  const modalButtons = listStep ? (
    userListButtons
  ) : (
    <>
      <Button
        disabled={!userSelected}
        onClick={() => setListStep(true)}
        variant="secondary"
        theme={theme}
        fullWidth={isLayoutModeMobile}
      >
        {translate("staff_modify_permissions_cancel_button")}
      </Button>
      <Button
        onClick={() =>
          submitPermission({
            accessPermission,
            userId: userSelected?.id
          })
        }
        disabled={disabled}
        variant="primary"
        theme={theme}
        fullWidth={isLayoutModeMobile}
      >
        {translate("staff_modify_permissions_update_button")}
      </Button>
    </>
  );

  return (
    <ModalLayout
      labels={{
        title: translate("add_permissions_title"),
        mobileClose: translate("close")
      }}
      closeModal={closeModal}
      desktopProps={{
        size: ModalSizeType.Big
      }}
    >
      {modalBody}
      <S.ModalFooterContainer>
        <S.ButtonContainer>{modalButtons}</S.ButtonContainer>
      </S.ModalFooterContainer>
    </ModalLayout>
  );
};

export default observer(AddStaffModal);
