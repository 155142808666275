import * as PopoverRadix from "@radix-ui/react-popover";
import * as TooltipRadix from "@radix-ui/react-tooltip";
import React, { forwardRef } from "react";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import * as S from "./TooltipContent.styled";

type TooltipContentProps = {
  showArrow?: boolean;
  children: React.ReactNode;
  className?: string;
  withPortal?: boolean;
  style?: React.CSSProperties | null;
  side?: "top" | "right" | "bottom" | "left";
};

const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  (
    { style, showArrow = true, children, className, withPortal = true, side },
    ref
  ) => {
    const {
      UIStore: { isLayoutModeMobile }
    } = useStores();

    const { primaryColor, colors } = useTheme();

    const tooltipStyle = style || {
      backgroundColor: primaryColor[100],
      color: colors.grey[1000]
    };

    if (isLayoutModeMobile) {
      return (
        <S.StyledPopoverContent
          className={className}
          ref={ref}
          style={tooltipStyle}
          withPortal={withPortal}
          side={side}
        >
          {children}
          {showArrow ? (
            <PopoverRadix.Arrow fill={tooltipStyle.backgroundColor} />
          ) : null}
        </S.StyledPopoverContent>
      );
    }

    return (
      <S.StyledTooltipContent
        className={className}
        ref={ref}
        style={tooltipStyle}
        withPortal={withPortal}
        side={side}
      >
        {children}
        {showArrow ? (
          <TooltipRadix.Arrow fill={tooltipStyle.backgroundColor} />
        ) : null}
      </S.StyledTooltipContent>
    );
  }
);

TooltipContent.displayName = "TooltipContent";

export default TooltipContent;
