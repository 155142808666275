import { Box, Button, Heading } from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupInSpaceModel } from "src/js/types";
import { InternalDispatcher } from "src/legacy/modules/dispatcher";
import { useTheme } from "styled-components";
import { GroupInvitationCard } from "./components/GroupInvitationCard";
import * as S from "./GroupsInvitations.styles";
import { GroupInvitation } from "./types";
import { useGroupInvitation } from "./useGroupInvitation";
import { ModalGroupsInvitations } from "./components/ModalGroupsInvitations";

const GroupsInvitations = ({
  appendToGroupList
}: {
  appendToGroupList?: (groups: GroupInSpaceModel[]) => void;
}) => {
  const [groupInvites, setGroupInvites] = useState<GroupInvitation[]>([]);
  const {
    ModalStore: { openModal }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const { showConfirmation, rejectInvite, getInvites, acceptAllInvites } =
    useGroupInvitation(setGroupInvites, groupInvites, appendToGroupList);

  useEffect(() => {
    InternalDispatcher.on("new_group_invite", getInvites);
    getInvites().then(response => {
      setGroupInvites(response);
    });

    return () => InternalDispatcher.off("new_group_invite", getInvites);
  }, []);

  const showInvitationsModal = () => {
    openModal(() => (
      <ModalGroupsInvitations
        groupInvites={groupInvites}
        showConfirmation={showConfirmation}
        rejectInvite={rejectInvite}
        acceptAllInvites={acceptAllInvites}
      />
    ));
  };

  return groupInvites.length ? (
    <Box position="relative">
      <S.HeaderContainer id="tileContainer">
        <Box flex={1}>
          <Heading level="5">
            {translate({ text: "group_invitation_title" })} (
            {groupInvites.length})
          </Heading>
          <S.Subtitle type="formSubtitle">
            {translate({ text: "group_invitation_subtitle_1" })}{" "}
            {translate({ text: "group_invitation_subtitle_2" })}
          </S.Subtitle>
        </Box>
        <S.ButtonsWrapper>
          <Button
            onClick={showInvitationsModal}
            theme={greyTheme}
            variant="secondary"
            size="small"
          >
            {translate("group_invitation_cta")}
          </Button>

          <Button
            onClick={() => acceptAllInvites()}
            theme={greyTheme}
            variant="secondary"
            size="small"
          >
            {translate("accept_multiple_invitations")}
          </Button>
        </S.ButtonsWrapper>
      </S.HeaderContainer>
      <S.GroupsCollapsableInvitations>
        {groupInvites.map(invitation => {
          return (
            <GroupInvitationCard
              key={invitation?.inviteCode}
              invitation={invitation}
              showConfirmation={showConfirmation}
              rejectInvite={rejectInvite}
            />
          );
        })}
      </S.GroupsCollapsableInvitations>
      <S.BlurRow shouldShow={groupInvites.length > 2} />
    </Box>
  ) : null;
};

export default GroupsInvitations;
