import React from "react";
import { Button, Icon } from "@arcadia/design-system";
import { trackLiveConference } from "src/js/modules/analyticsFunction";
import { useTheme } from "styled-components";
import { openExternalLink } from "src/js/modules/commonFunction";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { TimerView } from "src/js/components/TimerView";
import { EventStatus } from "src/js/pages/calendar/Calendar.types";
import { useParams } from "react-router";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { useMeQueryCached } from "src/js/query";
import { EventLiveStatusBannerParams } from "./EventLiveStatusBanner.types";

import * as S from "./EventLiveStatusBanner.styles";

const EventLiveStatusBanner = ({
  status = EventStatus.Default,
  userIsCreator = false,
  timeFromStart,
  showLive,
  timeToStart,
  liveRoomUrl
}: EventLiveStatusBannerParams) => {
  const {
    GroupStore: { groupId }
  } = useStores();
  const { event_id } = useParams<{ event_id?: string }>();

  const { data: activeUser } = useMeQueryCached();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  if (status === EventStatus.Finished) return null;

  const ongoingButton = (
    <Button
      onClick={() => {
        trackLiveConference({
          userId: activeUser?.id,
          groupId,
          liveRoomId: event_id
        });
        openExternalLink(liveRoomUrl);
      }}
      variant="primary"
      theme={whiteTheme}
      fullWidth={getBreakpoint() === "smartphone"}
    >
      {translate("event_detail_live_ongoing_action")}
    </Button>
  );

  return (
    <S.BannerContainer>
      {!userIsCreator &&
        (showLive ? (
          <S.ActionContainer>
            {ongoingButton}
            <S.TimerContainer>
              {status === EventStatus.Ongoing ? (
                <>
                  {`${translate("event_live_started")} `}
                  <TimerView time={timeFromStart} />
                </>
              ) : (
                <>
                  {`${translate("event_live_not_started_user_view")} `}
                  <TimerView time={timeToStart} />
                </>
              )}
            </S.TimerContainer>
          </S.ActionContainer>
        ) : (
          <>
            <S.LockedEventIcon>
              <Icon icon="locked" height={16} width={16} />
            </S.LockedEventIcon>
            {translate("event_live_not_started")}
          </>
        ))}
      {userIsCreator && (
        <S.ActionContainer>
          {ongoingButton}
          {status === EventStatus.Default && (
            <S.TimerContainer>
              {translate("event_live_not_started_creator_view")}
            </S.TimerContainer>
          )}
          {status === EventStatus.Ongoing && (
            <S.TimerContainer>
              {`${translate("event_live_started")} `}
              <TimerView time={timeFromStart} />
            </S.TimerContainer>
          )}
        </S.ActionContainer>
      )}
    </S.BannerContainer>
  );
};

export default observer(EventLiveStatusBanner);
