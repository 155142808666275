import React, { useEffect } from "react";
import { Box, ContextualAlert, Input } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useForm, useMutation, useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { FormInputs } from "src/js/types";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { queryClient, useMeQueryCached, userQueryKey } from "src/js/query";
import {
  checkEmailAvailability,
  saveEmail
} from "src/js/repository/userRepository";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { TranslationKeys } from "src/js/translation";
import { ModalBody } from "../Modal";
import { EmailEditSettingsFields } from "./UserAccountChangeEmailModal.types";
import { __FORM_EMAIL_EDIT_SETTINGS__ } from "./UserAccountChangeEmailModal.utils";

const UserAccountChangeEmailModal: React.FC = () => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    UserStore: { fetchUser },
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { register, handleSubmit, setValue, errors } =
    useForm<FormInputs<EmailEditSettingsFields>>();

  const { data: activeUser } = useMeQueryCached();

  const { mutate: confirm } = useMutation(
    async (vars: { email: string; confirm_password: string }) => {
      try {
        try {
          await checkEmailAvailability(vars.email);
        } catch {
          throw new Error("email_already_used");
        }
        try {
          await saveEmail(vars.email, vars.confirm_password);
        } catch {
          throw new Error("confirm_password_error");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        showToastSuccess({
          str: translate("change_email_confirmation_sent")
        });
        queryClient.invalidateQueries(userQueryKey.me);
        fetchUser();
        closeModal();
      },
      onError(error) {
        showToastError({
          str: translate(error.message as TranslationKeys)
        });
      }
    }
  );

  useEffect(() => {
    setValue([{ email: activeUser?.email }]);
  }, []);

  const handleSubmission = handleSubmit(vars => {
    return confirm(vars);
  });

  return (
    <ModalLayout
      closeModal={closeModal}
      mobileProps={{ closeLabel: translate("close") }}
      labels={{ title: translate({ text: "change_email_title" }) }}
    >
      <ModalBody onSubmit={handleSubmission} formId="change-email-form">
        <Box marginTop={20}>
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="email"
            label={translate({ text: "new_email" })}
            ref={register(__FORM_EMAIL_EDIT_SETTINGS__.email)}
            error={errors.email}
            rounded
          />
        </Box>

        <Box marginTop={20}>
          <span>{translate({ text: "password_check" })}</span>
        </Box>

        <Box marginTop={10}>
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="confirm_password"
            label={translate({ text: "confirm_password" })}
            isPassword
            ref={register(__FORM_EMAIL_EDIT_SETTINGS__.confirm_password)}
            error={errors.confirm_password}
            rounded
          />
        </Box>

        <Box margin="auto" marginTop={20} width="max-content">
          <ContextualAlert
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            text={translate({ text: "change_email_alert_message" })}
          />
        </Box>
      </ModalBody>
    </ModalLayout>
  );
};

export default UserAccountChangeEmailModal;
