import { ObjectValues } from "./Common";
import { ReactionCounter } from "./Reaction";
import { Resource } from "./Resource";
import { AvatarModel } from "./User";

export type ThreadUser = {
  uuid: string;
  name: string;
  surname: string;
  avatar: AvatarModel;
  hasLeft: boolean;
  isRemoved: boolean;
};

export type BackendThreadMessage = {
  id: string;
  userId: string;
  message: string;
  isRead: boolean;
  resources: Resource[];
  createdAt: string;
  updatedAt?: string;
  reactions?: ReactionCounter;
};

export const ThreadMessageError = {
  TimeoutSending: "timeoutSending"
};

export type ThreadMessageErrorType = ObjectValues<typeof ThreadMessageError>;

export type ThreadMessage = BackendThreadMessage & {
  isSent: boolean;
  error?: ThreadMessageErrorType;
};

export type Thread = {
  id: string;
  users: ThreadUser[];
  muted: boolean;
  unreadMessages: number;
  lastMessage: ThreadMessage;
};
