import { ConfirmationAlert } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { Trans } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { ResetContextType, ResetDataAlertProps } from "./types";
import { useResetMutation } from "./hooks";
import { translations } from "./utils";

export const StyledLink = styled.a`
  all: unset;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.grey[50]};
  cursor: pointer;
  &:active {
    text-decoration: underline;
  }
`;

const ResetDataAlert = <T extends ResetContextType>({
  username,
  userUuid,
  scormData,
  context
}: ResetDataAlertProps<T>) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    GroupStore: { activeGroup },
    ConfirmDialogStore: { closeConfirmDialog }
  } = useStores();

  const { onResetScormData } = useResetMutation<ResetContextType>();

  const handleAccept = () => {
    onResetScormData(context, activeGroup.id, {
      username,
      userUuid,
      scormData
    });
  };

  return (
    <ConfirmationAlert
      theme={whiteTheme}
      acceptText={translate("reset_all_user_scorm_record_data_alert_accept")}
      declineText={translate("reset_all_user_scorm_record_data_alert_refuse")}
      onAcceptFunction={handleAccept}
      onDeclineFunction={closeConfirmDialog}
      text={
        <>
          {translate(translations[context], {
            scormName: scormData?.scormName,
            username
          })}
          <br />
          <Trans
            i18nKey="reset_scorm_record_data_alert_guide_link"
            components={{
              CustomLink: <StyledLink target="_blank" />
            }}
          />
        </>
      }
    />
  );
};

export default ResetDataAlert;
