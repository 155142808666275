import { Icon, LinkButton, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce, useStores } from "src/js/hooks";
import { dismissToast, showToastError } from "src/js/modules/messageManager";
import {
  HeaderWrapper,
  PageContainer
} from "src/js/pages/whiteboard/Whiteboard.styles";
import { useTranslation } from "src/js/translation";
import { WebSocketConnectionState } from "src/js/types/models/WebSocket";
import styled, { createGlobalStyle } from "styled-components";
import { ResourceViewerWrap } from "src/legacy/view/resource/ResourceViewer.styles";
import { TopBannerDemoSpace } from "../TopBannerDemoSpace/TopBannerDemoSpace.styles";

export const GlobalStyleTopBannerErrorConnection = createGlobalStyle<{
  headerHeight: number;
}>`
  ${TopBannerDemoSpace} {
    display: none;
  }

  // Whiteboard, ExerciseEngine, ResourceViewer, ModuleResourceViewer
  ${HeaderWrapper} .header__clean, 
  #exercise-engine-container > .header {
    margin-top: ${({ headerHeight }) => headerHeight}px;
  }
  ${PageContainer} {
    height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  }
  .resource-iframe-container {
    margin-top: ${({ headerHeight }) => headerHeight - 1}px;
    height: calc(100% - ${({ headerHeight }) => headerHeight - 1}px) !important;
  }
  ${ResourceViewerWrap} {
    margin-top: ${({ headerHeight }) => headerHeight - 1}px;
  }
  .viewer-resource__body,
  .resource-iframe-container.with-footer {
    height: calc(100% - ${({ headerHeight }) => headerHeight - 1}px - 48px) !important;
  }
`;

export const StyledTopBannerErrorConnection = styled.div`
  width: 100%;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.error[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  z-index: ${({ theme }) => theme.zIndex.topBanner};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;

export const HideTopBannerErrorConnection = createGlobalStyle`
  ${StyledTopBannerErrorConnection} {
    display: none;
  }
`;

export const StyledIcon = styled(Icon)`
  flex: none;
`;

export const StyledTextWrap = styled.div`
  display: flex;
  gap: 8px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.grey[1000]};
  white-space: pre-wrap;
  font-weight: 500;
  line-height: 17px;
  padding: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.grey[900]};
  }
`;

const TopBannerErrorConnection = () => {
  const { translate } = useTranslation();
  const {
    WebSocketStore: { connectionState },
    ModalStore: { show: modalIsOpen }
  } = useStores();

  const [showBanner, setShowBanner] = useState(false);
  const showBannerDebounced = useDebounce(showBanner, 250);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!headerRef.current) return () => {};
    const resizeObserver = new ResizeObserver(() => {
      setHeaderHeight(headerRef.current?.offsetHeight || 0);
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect();
  }, [showBanner]);

  useEffect(() => {
    const shouldShowBanner =
      connectionState === WebSocketConnectionState.DISCONNECTED ||
      connectionState === WebSocketConnectionState.ERROR;
    setShowBanner(shouldShowBanner);
  }, [connectionState]);

  useEffect(() => {
    if (showBanner && modalIsOpen) {
      showToastError({
        str: translate("top_banner_error_connection_content"),
        options: {
          toastId: "banner_error_connection"
        }
      });
    } else {
      dismissToast("banner_error_connection");
    }
  }, [showBanner, modalIsOpen]);

  if (!showBannerDebounced || modalIsOpen) {
    return null;
  }

  return (
    <>
      <GlobalStyleTopBannerErrorConnection headerHeight={headerHeight} />
      <StyledTopBannerErrorConnection ref={headerRef}>
        <StyledIcon icon="dangerTriangle" height={16} width={16} />
        <StyledTextWrap>
          <StyledText>
            {translate("top_banner_error_connection_content")}
          </StyledText>
          <StyledLinkButton
            type="button"
            onClick={() => window.location.reload()}
          >
            {translate("top_banner_error_connection_refresh_cta")}
          </StyledLinkButton>
        </StyledTextWrap>
      </StyledTopBannerErrorConnection>
    </>
  );
};

export default observer(TopBannerErrorConnection);
