import React from "react";
import { Trans as TransI18n, TransProps } from "react-i18next";
import { TranslationKeys } from "./translated.types";
import { HTMLParserComponents } from "../settings/settingsHTMLParser";

const Trans = (props: TransProps<TranslationKeys>) => {
  const { components } = props;
  return (
    <TransI18n
      {...props}
      components={{ ...HTMLParserComponents, ...components }}
    />
  );
};

export default Trans;
