import getUserClient from "../modules/connection";
import {
  ScormDataDetailOrderBy,
  ScormDataOrderBy,
  SortOrder
} from "../query/scorm";
import {
  api_group_scorms,
  api_user_scorms,
  api_user_scorms_csv_export,
  api_group_scorms_csv_export,
  getVersionedApiUrl,
  api_group_scorms_reset
} from "./apiUrls";

type ScormRecordParams = {
  groupId: number;
  orderBy: ScormDataOrderBy | ScormDataDetailOrderBy;
  sortOrder: SortOrder;
  textSearch: string;
  offset: number;
  limit: number;
};

// TODO update return type
export const fetchScormRecords = async ({
  groupId,
  limit,
  offset,
  orderBy,
  sortOrder,
  textSearch
}: ScormRecordParams) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_scorms({ groupId }), "v3"), {
      params: {
        orderBy,
        limit,
        offset,
        sortOrder,
        textSearch
      }
    })
    .then(response => response.data);
};
// TODO update return type
export const fetchUserScormRecords = async ({
  groupId,
  limit,
  offset,
  userUuid,
  orderBy,
  sortOrder,
  textSearch
}: ScormRecordParams & { userUuid: string }) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_scorms({ groupId, userUuid }), "v3"), {
      params: {
        userUuid,
        orderBy,
        limit,
        offset,
        sortOrder,
        textSearch
      }
    })
    .then(response => response.data);
};

export const exportUserDataCsv = async ({
  userUuid,
  groupId,
  email
}: {
  userUuid: string;
  groupId: number;
  email: string;
}) => {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_user_scorms_csv_export({ groupId, userUuid }),
        "v3"
      ),
      { email }
    )
    .then(response => response.data);
};

export const exportGroupDataCsv = async ({
  groupId,
  email,
  includeNoProgress
}: {
  groupId: number;
  email: string;
  includeNoProgress?: boolean;
}) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_group_scorms_csv_export({ groupId }), "v3"), {
      email,
      includeUsersWithNoExecutions: includeNoProgress
    })
    .then(response => response.data);
};

const resetSCORMData = async (
  groupId: number,
  data?: { userUuid?: string; boardElementId?: number }
) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_group_scorms_reset({ groupId }), "v3"), data)
    .then(response => response.data);
};

// Delete all SCORM data for all users
export const resetAllSCORMData = async ({ groupId }: { groupId: number }) => {
  return resetSCORMData(groupId, {});
};

// Delete all SCORM data for a specific user
export const resetUserSCORMData = async ({
  groupId,
  userUuid
}: {
  groupId: number;
  userUuid: string;
}) => {
  return resetSCORMData(groupId, { userUuid });
};

// Delete specific SCORM data for a specific user and board element
export const resetSpecificSCORMData = async ({
  groupId,
  userUuid,
  boardElementId
}: {
  groupId: number;
  userUuid: string;
  boardElementId: number;
}) => {
  return resetSCORMData(groupId, { userUuid, boardElementId });
};
