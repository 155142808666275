import { Button, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;

  @media (max-width: 767px) {
    min-height: unset;
    gap: 8px;
    flex-direction: column;
    align-items: center;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  max-width: 756px;

  & > *:not(:first-child) {
    margin-top: 16px;
  }

  @media (max-width: 767px) {
    margin: 20px auto;
  }
`;

export const SectionWrapper = styled.div<{ zIndex?: number }>`
  position: relative;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};

  label {
    margin-bottom: 0px;
  }
`;
export const SectionPanel = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: relative;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  > span {
    margin-bottom: 1px;
  }
`;

const TitleWrapperBase = css`
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export const TitleWrapperMobile = styled.div`
  ${TitleWrapperBase}
  @media (min-width: 767px) {
    display: none;
  }
`;
export const TitleWrapperDesktop = styled.div`
  ${TitleWrapperBase}
  @media (max-width: 767px) {
    display: none;
  }
`;

export const SaveLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.violet[500]};
  margin-right: 8px;

  &:disabled {
    color: ${({ theme }) => theme.colors.violet[700]};
    cursor: not-allowed;
  }
`;

export const BackButton = styled.div`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

export const BadgeSubtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[600]};
`;

export const UsernameWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 0px;

  @media (max-width: 767px) {
    position: relative;
  }
`;
