import { useMutation } from "@tanstack/react-query";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { ResetContextType, ResetDataAlertProps } from "../types";
import { callResetFunction } from "../utils";

const useResetMutation = <T extends ResetContextType>() => {
  const { translate } = useTranslation();
  const {
    ConfirmDialogStore: { closeConfirmDialog }
  } = useStores();

  const { mutate } = useMutation(
    (params: {
      context: T;
      groupId: number;
      data: Omit<ResetDataAlertProps<T>, "context">;
    }) => {
      const { context, groupId, data } = params;
      return callResetFunction(context, groupId, data);
    }
  );

  const onResetScormData = (
    context: T,
    groupId: number,
    data: Omit<ResetDataAlertProps<T>, "context">
  ) => {
    mutate(
      {
        context,
        groupId,
        data
      },
      {
        onSuccess: async () => {
          closeConfirmDialog();
          showToastSuccess({
            str: translate("export_scorm_data_warning_time")
          });
        },
        onError: () => {
          showToastError({ str: "general_error_message" });
        }
      }
    );
  };

  return { onResetScormData };
};

export default useResetMutation;
