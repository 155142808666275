import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useState } from "react";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { editGroupInfo } from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import { DashboardEvent, EventDomain, SpacesGroupType } from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ModalSetupSelfJoinable } from "../components";

const useGroupSelfJoinableStatus = (groupObject: SpacesGroupType) => {
  const {
    ModalStore: { openModal, closeModal },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const [selfJoinable, setIsSelfJoinable] = useState(
    groupObject?.group?.selfJoinable
  );

  const setSelfJoinable = (id: number) => {
    openModal(() => (
      <ModalSetupSelfJoinable
        group={groupObject?.group}
        onConfirm={() => {
          closeModal();
          editGroupInfo({
            id,
            selfJoinable: true
          })
            .then(() => {
              setIsSelfJoinable(true);
              showToastSuccess({
                str: translate("dashboard_set_self_joinable_toaster_success")
              });
              _trackEvent(
                EventDomain.SpaceDashboardUsage,
                DashboardEvent.ConfirmSetSelfJoinableDashboard
              );
            })
            .catch(() => {
              showToastError({
                str: translate("dashboard_set_self_joinable_toaster_error")
              });
            });
        }}
      />
    ));
  };

  const unsetSelfJoinable = (id: number) => {
    const onAccept = () => {
      editGroupInfo({
        id,
        selfJoinable: false
      })
        .then(() => {
          setIsSelfJoinable(false);
          showToastSuccess({
            str: translate("dashboard_cancel_self_joinable_toaster_success")
          });
          _trackEvent(
            EventDomain.SpaceDashboardUsage,
            DashboardEvent.ConfirmUnsetSelfJoinableDashboard
          );
        })
        .catch(error => {
          showToastError({ str: error.message });
        });
    };
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("dashboard_set_self_joinable_toaster_confirmation")}
        declineText={translate("toast_ask_delete_template_cta_cancel")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("toast_ask_delete_template_cta_confirm")}
        onAcceptFunction={() => {
          closeConfirmDialog();
          onAccept();
        }}
      />
    );
  };

  return { selfJoinable, setSelfJoinable, unsetSelfJoinable };
};

export default useGroupSelfJoinableStatus;
