export const __METATITLE_PREFIX__ = "WeSchool";

export const __CONTENT_CLASS__ = "standard__container";

export const __UPLOAD_SIZE_LIMIT__ = 52428800; // 50mb
export const __IMAGE_SIZE_LIMIT__ = 10485760; // 10mb

export const __INSTANT_AUTOMATIC_STOP_TIMEOUT__ = 90; // minuti

export const __OFFICE_VIEWER__ =
  "https://view.officeapps.live.com/op/embed.aspx";

export const __EXERCISE_MAX_RATE__ = 10;
export const __QUIZ_ANSWER_TITLE_MAX_LENGTH__ = 2500;

export const __RANKING_TIME_RANGE__ = 7;
export const __RESOURCE_GMS_ULR__ = "api_crm";

export const __EXERCISES_CLONE_LIMIT__ = 10;

export const __EXERCISE_LIST_LIMIT__ = {
  desktop: 5,
  tablet: 5,
  smartphone: 5
};

export const __ACTIVITY_LIST_LIMIT__ = 300;
