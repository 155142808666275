import React from "react";
import {
  Box,
  Button,
  ContextualAlert,
  ContextualError
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useModal } from "src/js/context/ModalProvider";
import {
  ResourcePickerErrorProvider,
  useResourcePickerErrors
} from "src/js/components/ResourcePicker/providers/ResourcePickerErrorProvider";
import { RESOURCE_PICKER_ITEMS } from "src/js/components/ResourcePicker/ResourcePicker.const";
import { __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ } from "src/js/settings/settingFileUploader";
import { ResourcePickerModalProps } from "./ResourcePickerModal.types";
import * as S from "./ResourcePickerModal.styles";

const Errors = () => {
  const { errors } = useResourcePickerErrors();
  const { whiteTheme } = useTheme();

  return errors !== undefined && errors.length > 0 ? (
    <S.ErrorWrapper>
      {errors.map(err => (
        <ContextualError key={err} theme={whiteTheme} text={err} />
      ))}
    </S.ErrorWrapper>
  ) : null;
};

const ResourcePickerModal = ({
  body,
  subtitle,
  title,
  footer,
  confirmText,
  cancelText,
  hasCancel = false,
  hasConfirm = false,
  withWarningMessage = false,
  type,
  modalConfig = {},
  onCancel = () => {},
  onConfirm = () => {}
}: ResourcePickerModalProps) => {
  const { ModalStore } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const { title: customTitle, modalConfig: customModalConfig } = useModal();

  const renderFooter = () => {
    if (footer) {
      return <S.FooterWrapper>{footer}</S.FooterWrapper>;
    }
    if (hasCancel || hasConfirm) {
      return (
        <S.FooterWrapper>
          <S.DefaultFooterContainer>
            {hasCancel ? (
              <S.ButtonWrapper>
                <Button
                  fullWidth
                  theme={whiteTheme}
                  variant="secondary"
                  onClick={onCancel}
                >
                  {cancelText || translate("file_picker_modal_cancel")}
                </Button>
              </S.ButtonWrapper>
            ) : undefined}
            {hasConfirm ? (
              <S.ButtonWrapper>
                <Button
                  fullWidth
                  theme={whiteTheme}
                  variant="primary"
                  onClick={onConfirm}
                >
                  {confirmText || translate("file_picker_modal_confirm")}
                </Button>
              </S.ButtonWrapper>
            ) : undefined}
          </S.DefaultFooterContainer>
        </S.FooterWrapper>
      );
    }
    return null;
  };

  const isMicrosoft =
    type === RESOURCE_PICKER_ITEMS.WORD ||
    type === RESOURCE_PICKER_ITEMS.EXCEL ||
    type === RESOURCE_PICKER_ITEMS.POWERPOINT;
  const config = customModalConfig || modalConfig;

  return (
    <ResourcePickerErrorProvider>
      <ModalLayout
        closeModal={() => ModalStore.closeModal()}
        labels={{
          title: customTitle || title,
          subtitle,
          mobileTitle: title,
          mobileSubtitle: subtitle,
          mobileClose: translate("close")
        }}
        {...config}
      >
        <Box padding={16}>{body}</Box>
        <Errors />
        {withWarningMessage && (
          <S.WarningWrapper>
            <ContextualAlert
              theme={whiteTheme}
              text={translate(
                isMicrosoft
                  ? "file_upload_input_message_msdocs"
                  : "file_uploader_warning_size_limit",
                {
                  maximumFileSize:
                    type === RESOURCE_PICKER_ITEMS.SCORM
                      ? 500
                      : __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ / 1000000
                }
              )}
            />
          </S.WarningWrapper>
        )}
        {renderFooter()}
      </ModalLayout>
    </ResourcePickerErrorProvider>
  );
};

export default observer(ResourcePickerModal);
