import getUserClient from "../modules/connection";
import { GroupInSpaceModel } from "../types";
import {
  api_group_alias_active,
  api_groups_accept_user_invites,
  api_groups_invites_delete,
  api_self_joinable_groups_users,
  getVersionedApiUrl
} from "./apiUrls";

export interface AliasActiveDTO {
  email: string;
  id: number;
  group_invite: {
    group: {
      name: string;
    };
    alias: string;
    is_public: boolean;
  };
  invite_role: string;
  free_invitation: boolean;
  created: number;
}

export const checkAliasActive = (code: string) => {
  return getUserClient().get<AliasActiveDTO>(
    getVersionedApiUrl(api_group_alias_active({ alias: code }), "v1")
  );
};

export const joinGroup = (code: string) => {
  return getUserClient().get<{
    id: number;
    is_joining: boolean;
    responseJSON: string;
  }>(getVersionedApiUrl(api_groups_invites_delete({ inviteId: code }), "v1"));
};

export const joinAllSpaceInvites = () => {
  return getUserClient().patch<GroupInSpaceModel[]>(
    getVersionedApiUrl(api_groups_accept_user_invites(), "v3")
  );
};

export function selfJoinableGroupUser({
  groupId,
  userUuid
}: {
  groupId: number;
  userUuid: string;
}) {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_self_joinable_groups_users({ groupId, userUuid }),
        "v3"
      )
    )
    .then(({ data }) => {
      return data;
    });
}
