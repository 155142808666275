import { Avatar, Box, Button, Icon } from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { useGroupFeatures, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { useSpaceByIdCached } from "src/js/query";
import { useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  ChatEvent,
  EventDomain,
  SearchbarEvent
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { GroupInviteMemberModal } from "../GroupInviteMemberModal";
import GroupSettingsSearchBar from "./components/GroupSettingsSearchBar";
import GroupSettingsTablePagination from "./components/GroupSettingsTablePagination";
import * as S from "./GroupSettingsMembers.styles";
import { Role } from "./GroupSettingsMembers.types";
import GroupSettingsMembersInvitations from "./GroupSettingsMembersInvitations";
import {
  demoNoInvitationWarningBanner,
  temporaryNoInvitationWarningBanner
} from "./translations";
import { useGroupMembers } from "./useGroupMembers";

const GroupSettingsMembers = () => {
  const {
    GroupStore: { activeGroup: group, usersCount },
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal },
    SpaceStore: { canCreateChats, isDemoSpace, activeSpaceSlug, activeSpace },
    UserInviteStore: { reset },
    UserStore: { activeUser }
  } = useStores();
  const { navigateToThread } = useCreateQuickThread();
  const {
    isCreator,
    isTeacher,
    members,
    getMembers,
    getDropdownOptions,
    hasRole,
    totalMembers,
    isStudent,
    nameOrEmailDebounced,
    nameOrEmail,
    setNameOrEmail
  } = useGroupMembers();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { hideMembers } = useGroupFeatures();

  const [page, setPage] = useState(0);

  const hasMembers = useMemo(() => members && members.length, [members]);

  useEffect(() => {
    if (hideMembers) {
      navigateTo(
        createUrl("group_settings", {
          option: "information",
          space_slug: activeSpaceSlug,
          group_id: group?.id
        })
      );
    }
  }, [hideMembers, activeSpaceSlug, group?.id]);

  useEffect(() => {
    if (!group) return;
    getMembers(0);
    if (nameOrEmailDebounced) {
      _trackEvent(EventDomain.Searchbar, SearchbarEvent.SearchMemberInGroup);
    }
  }, [group, nameOrEmailDebounced]);

  const { data } = useSpaceByIdCached(activeSpaceSlug, {
    enabled: !!activeSpaceSlug
  });

  const showInviteModal = () => {
    reset();
    _trackEvent(EventDomain.AddMember, AddMemberEvent.MembersPageUserInvite);
    openModal(() => <GroupInviteMemberModal />);
  };

  const goToProfile = (uuid: string) => {
    navigateTo(
      createUrl("profile_public", {
        user_uuid: uuid
      })
    );
  };

  const tableMemberProfile = member => (
    <S.TableBodyItem>
      <S.TableBodyItemProfile onClick={() => goToProfile(member.uuid)}>
        <Avatar
          size="s"
          theme={whiteTheme}
          withTeacherRibbon={isTeacher || isCreator}
          url={member.avatar?.small}
        />
        <Box marginLeft={8} marginRight="auto">
          <S.TableText type="table">{`${member.name} ${member.surname}`}</S.TableText>
        </Box>
      </S.TableBodyItemProfile>
    </S.TableBodyItem>
  );

  const inviteButton = (
    <Button
      id="groupOptionsMembersInviteButton"
      theme={whiteTheme}
      variant="primary"
      onClick={showInviteModal}
      withIcon={{
        positionIcon: "left",
        icon: "plus",
        widthIcon: 12,
        heightIcon: 12
      }}
    >
      {translate("group_settings_page_members_invite")}
    </Button>
  );

  if (group && !isStudent) {
    return (
      <>
        {isDemoSpace ? (
          <WarningBanner
            text={demoNoInvitationWarningBanner.text}
            cta={demoNoInvitationWarningBanner.cta}
            margin="0 0 30px 0"
            maxWidth="100%"
          />
        ) : null}
        {activeSpace?.isTemporary ? (
          <WarningBanner
            text={temporaryNoInvitationWarningBanner.text}
            cta={temporaryNoInvitationWarningBanner.cta}
            margin="0 0 30px 0"
            maxWidth="100%"
          />
        ) : null}
        <Box marginBottom={24}>
          <S.HeaderContainer>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={isLayoutModeMobile ? "100%" : "unset"}
            >
              <S.StyledHeading level="5">
                {usersCount !== 0
                  ? `${translate("group_settings_page_members")} (${usersCount})`
                  : `${translate("group_settings_page_members")}`}
              </S.StyledHeading>
              {isLayoutModeMobile ? inviteButton : null}
            </Box>
            <GroupSettingsSearchBar
              nameOrEmail={nameOrEmail}
              setNameOrEmail={setNameOrEmail}
            />
            {!isLayoutModeMobile ? inviteButton : null}
          </S.HeaderContainer>
          {hasMembers ? (
            <>
              <S.TableWrapper>
                <S.Table>
                  <S.TableHead>
                    <S.TableRow>
                      <S.TableHeadItem>
                        {translate("group_settings_page_members")}
                      </S.TableHeadItem>
                      {!isLayoutModeMobile ? (
                        <>
                          <S.TableHeadItem>
                            {translate("email")}
                          </S.TableHeadItem>
                          <S.TableHeadItem>
                            {translate("group_settings_page_table_signup")}
                          </S.TableHeadItem>
                          <S.TableHeadItem />
                        </>
                      ) : undefined}
                      <S.TableHeadItem />
                    </S.TableRow>
                  </S.TableHead>
                  <S.TableBody>
                    {members.map(member => (
                      <S.TableRow key={member.uuid}>
                        {tableMemberProfile(member)}
                        {!isLayoutModeMobile ? (
                          <>
                            <S.TableBodyItem>
                              <S.TableText type="table">
                                {member.email}
                              </S.TableText>
                            </S.TableBodyItem>
                            <S.TableBodyItem>
                              <S.TableText type="table">
                                {format(member?.joined_at * 1000, "dd/MM/yyyy")}
                              </S.TableText>
                            </S.TableBodyItem>
                          </>
                        ) : undefined}
                        <S.TableBodyItem>
                          <S.AdminText type="table">
                            {hasRole(Role.Creator, member.roles)
                              ? translate("group_settings_page_creator")
                              : undefined}
                            {hasRole(Role.Teacher, member.roles) &&
                            !hasRole(Role.Creator, member.roles)
                              ? translate("teacher")
                              : undefined}
                          </S.AdminText>
                        </S.TableBodyItem>
                        <S.TableBodyItem width={32}>
                          {activeUser.uuid === member.uuid ? (
                            <S.IconButton
                              onClick={() => goToProfile(member.uuid)}
                            >
                              <Icon
                                icon="chevronRight"
                                width={16}
                                height={16}
                              />
                            </S.IconButton>
                          ) : (
                            <DotsMenu
                              id="settingMembersOptionsDotsMenu"
                              customIcon="kebabVertical"
                              items={getDropdownOptions(member, page)}
                              alignRight
                            />
                          )}
                        </S.TableBodyItem>
                      </S.TableRow>
                    ))}
                  </S.TableBody>
                </S.Table>
              </S.TableWrapper>
              <GroupSettingsTablePagination
                totalRecords={totalMembers}
                getRecords={getMembers}
                setPage={setPage}
              />
            </>
          ) : null}
        </Box>
        <GroupSettingsMembersInvitations />
      </>
    );
  }

  return (
    <>
      <S.HeaderContainer>
        <S.StyledHeading level="5">
          {usersCount !== 0
            ? `${translate("group_settings_members")} (${usersCount})`
            : `${translate("group_settings_members")}`}
        </S.StyledHeading>
        <GroupSettingsSearchBar
          nameOrEmail={nameOrEmail}
          setNameOrEmail={setNameOrEmail}
        />
      </S.HeaderContainer>
      <S.TableWrapper>
        <S.Table>
          <S.TableBody>
            {members.map(member => (
              <S.TableRow key={member.uuid}>
                {tableMemberProfile(member)}
                {canCreateChats &&
                activeUser.uuid !== member.uuid &&
                !data?.spaceSettings.disableChat ? (
                  <S.TableBodyItem width={32}>
                    <S.IconButton
                      onClick={() => {
                        _trackEvent(
                          EventDomain.Chat,
                          ChatEvent.ChatStartGroupMembersClick
                        );
                        navigateToThread(member.uuid);
                      }}
                    >
                      <Icon initialViewbox icon="chat" width={16} height={16} />
                    </S.IconButton>
                  </S.TableBodyItem>
                ) : null}
              </S.TableRow>
            ))}
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
      <GroupSettingsTablePagination
        totalRecords={totalMembers}
        getRecords={getMembers}
        setPage={setPage}
      />
    </>
  );
};

export default observer(GroupSettingsMembers);
