import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation";
import {
  Button,
  ConfirmationAlert,
  Heading,
  Text
} from "@arcadia/design-system";
import { __NO_RESULTS__ } from "src/js/settings/settingsImage";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { updateBoard } from "src/js/repository/boardRepository";
import { OldBoard } from "src/js/types";
import { useBoardContext } from "src/js/context/BoardProvider";
import { removeBlanksFromBoardElements } from "src/legacy/view/board/ModuleDetail";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";

import * as S from "./UploadError.styles";

const UploadError = ({
  title,
  elementId,
  text,
  onClose
}: {
  title: TranslationKeys;
  text: TranslationKeys;
  elementId: number;
  onClose: () => void;
}) => {
  const {
    GroupStore: { userIsTeacher },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    activeBoard,
    setBoard: saveBoard
  }: {
    activeBoard: OldBoard;
    setBoard: (id: string, reset?: boolean) => void;
  } = useBoardContext();

  const onDelete = () => {
    const filteredElements = activeBoard.board_elements.filter(
      el => el.id !== elementId
    );

    let reorderedBoardElements = filteredElements;
    if (activeBoard.has_mandatory_order) {
      reorderedBoardElements = removeBlanksFromBoardElements(filteredElements);
    }
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("scorm_broken_alert_deletion_message")}
        declineText={translate("scorm_broken_alert_deletion_cancel_cta")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("scorm_broken_alert_deletion_confirm_cta")}
        onAcceptFunction={() => {
          updateBoard(activeBoard.id, {
            ...activeBoard,
            board_elements: reorderedBoardElements
          })
            .then(res => {
              showToastSuccess({
                str: translate("scorm_broken_alert_deletion_success_message")
              });
              saveBoard(res.id, true);
            })
            .catch(e => {
              const error = extractErrorMessage(
                e || {},
                translate(
                  "Non hai i permessi per aggiornare questa board" as TranslationKeys
                )
              );
              showToastError({ str: error });
            });
          closeConfirmDialog();
          onClose();
        }}
      />
    );
  };

  return (
    <S.Wrapper>
      <S.BoxWrapper>
        <img src={__NO_RESULTS__} width="100px" alt="scorm error" />
        <S.TextWrapper>
          <Heading level="6">{translate(title)}</Heading>
          <Text type="formSubtitle">{translate(text)}</Text>
        </S.TextWrapper>
        {userIsTeacher ? (
          <Button onClick={onDelete} variant="primary" theme={whiteTheme}>
            {translate("scorm_broken_teacher_delete_cta")}
          </Button>
        ) : (
          <Text type="formSubtitle">
            {translate("scorm_broken_student_message")}
          </Text>
        )}
      </S.BoxWrapper>
    </S.Wrapper>
  );
};

export default UploadError;
