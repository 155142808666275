import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import React, { useState, useEffect, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ExerciseExecutionModel from "../models/exercise_executionModel";
import { getActiveGroupId } from "../modules/activeGroup";
import { navigateTo } from "../modules/history";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";
import ExerciseReportSessions from "../view/exercise/report/exerciseReportSessions";

const SingleExecutionController = ({ match, exercise }) => {
  const [execution, setExecution] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetchExecution = () => {
    if (!isUndefined(match.params.session_id)) {
      const sessionId = parseInt(match.params.session_id);
      if (isNumber(sessionId) && !isNaN(sessionId)) {
        const executionModel = new ExerciseExecutionModel({
          exercise_id: exercise.get("id"),
          id: sessionId
        });
        executionModel.fetchWithStats({
          success() {
            setExecution(executionModel);
            setLoaded(true);
          },
          error(params) {
            showToastError({
              str: extractErrorMessage(params.responseJSON, __("e_404"))
            });
            navigateTo(
              createURL("exercises", { group_id: getActiveGroupId() })
            );
          }
        });
      }
    } else {
      navigateTo(createURL("exercises", { group_id: getActiveGroupId() }));
    }
  };

  useEffect(() => {
    if (!loaded) {
      fetchExecution();
    }
  }, [execution, loaded]);

  if (execution === null) {
    return <div />;
  }

  return (
    <Switch>
      <Route
        exact
        path={
          WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsSession
        }
        render={props => (
          <ExerciseReportSessions
            exercise={exercise}
            execution={execution}
            {...props}
          />
        )}
      />
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  );
};

export default SingleExecutionController;
