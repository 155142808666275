import { GroupUserRoleEnum, SpaceUserRoleEnum } from "../enums";
import { ObjectValues } from "./Common";
import { WebSocketMessage } from "./WebSocket";

export const InviteMemberErrorMessage = {
  UserAlreadyInGroup: "u_user_already_in_group",
  InviteAlreadySent: "u_invite_already_sent"
} as const;

export type InviteMemberErrorMessageType = ObjectValues<
  typeof InviteMemberErrorMessage
>;

export const InviteMode = {
  Email: "email",
  Link: "link",
  Import: "import"
} as const;

export type InviteModeType = ObjectValues<typeof InviteMode>;

export type InviteMembersBulkParams = {
  groupIds: number[];
  groupInviteUser: {
    inviteRole: GroupUserRoleEnum;
    inviteSpaceRole?: SpaceUserRoleEnum;
    userEmails: string[];
    freeInvitation: boolean;
  };
};

export type InviteMembersBulkResponseError = {
  email: string;
  error: InviteMemberErrorMessageType;
};

export type InviteMemberPending = {
  id: number;
  email: string;
  role: GroupUserRoleEnum;
  inviteCode?: string;
  freeInvitation: boolean;
  isAccepted?: boolean;
  isRejected?: boolean;
  createdAt: number;
  emailSentAt?: number;
};

export type InviteMembersBulkResponse =
  | {
      groupId: number;
      groupInviteUser: {
        success: InviteMemberPending[];
        errors: InviteMembersBulkResponseError[];
      };
    }[]
  | "init async flow";

export type GroupInviteUserAsync = WebSocketMessage<{
  results: "completed";
}>;

export type InviteMembersAsyncStatus = {
  id: string;
  userUuid: string;
  createdAt: number;
} | null;
