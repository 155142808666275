import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Translate,
  translateString,
  useTranslation
} from "src/js/translation/TranslationProvider";
import {
  __PRIVACY_LINK__,
  __TERMS_LINK__
} from "src/js/settings/settingsGeneral";
import { useTheme } from "styled-components";
import { Input, INPUT_VARIANT } from "src/js/components/forms/input";
import { Box, Button, Input as WSInput } from "@arcadia/design-system";
import { InputCheckboxValidatedPrimary } from "src/js/components/forms/InputCheckboxValidatedPrimary";
import { useCloseOnClickOutside } from "src/js/hooks";
import { Calendar } from "src/js/components/Calendar";
import { format, subYears } from "date-fns";
import { subtractYears } from "src/js/pages/registration/Tutor/Tutor";
import { Trans } from "src/js/translation";
import { __FORM_TUTOR_APPROVE__ } from "./utils";

import * as S from "./UnderageApprovationForm.styles";

const yearsToShow = Array.from(
  { length: 100 },
  (_, i) => new Date(subtractYears(18)).getFullYear() - i
);

const TermsGroup = ({ register, watchTerms, errors, validationObject }) => {
  const privacy_link = __PRIVACY_LINK__;
  const terms_link = translateString({ text: __TERMS_LINK__ });

  const termsLabel = (
    <Trans i18nKey="tutor_terms_label" values={{ terms_link, privacy_link }} />
  );

  const weschoolContactLabel = (
    <Trans i18nKey="tutor_terms_commercial_label" values={{ privacy_link }} />
  );

  const thirdPartContactLabel = (
    <Trans
      i18nKey="tutor_terms_commercial_third_label"
      values={{ privacy_link }}
    />
  );

  return (
    <div className="register-form__terms margin-top-20">
      <InputCheckboxValidatedPrimary
        name="terms"
        label={termsLabel}
        register={register}
        validation={validationObject.terms}
        errorMessage={errors?.terms?.message}
      />
      {watchTerms && (
        <>
          <InputCheckboxValidatedPrimary
            name="weschool_contact"
            register={register}
            label={weschoolContactLabel}
          />
          <InputCheckboxValidatedPrimary
            name="third_part_contact"
            register={register}
            label={thirdPartContactLabel}
          />
        </>
      )}
      <p className="margin-top-10">
        <Translate text="terms_explanation_1" />
      </p>
      {watchTerms && (
        <p>
          <Trans i18nKey="terms_explanation_2" values={{ privacy_link }} />
        </p>
      )}
    </div>
  );
};

const UnderageApprovationForm = ({ submitFunction }) => {
  const { register, handleSubmit, setValue, errors, watch, control } =
    useForm();
  const { whiteTheme } = useTheme();
  const {
    isOpen: isCalendarOpen,
    ref: calendarRef,
    setIsOpen: setCalendarOpen
  } = useCloseOnClickOutside(false);
  const [date, setDate] = React.useState(new Date(subYears(new Date(), 18)));

  const watchTerms = watch("terms");
  const { translate } = useTranslation();

  const handleChangeDate = selectedDate => {
    setValue("birthday", format(new Date(selectedDate), "dd-MM-yyyy"), true);
    setDate(selectedDate);
    setCalendarOpen(false);
  };

  return (
    <form
      id="form-parent-approbation"
      className="form-registration margin-top-20"
      onSubmit={handleSubmit(formData => submitFunction(formData))}
    >
      <div className="flex">
        <Input
          id="name"
          name="name"
          placeholder="nome"
          marginRight={5}
          ref={register(__FORM_TUTOR_APPROVE__.name)}
          hasError={errors.name}
          errorMessage={errors.name?.message}
          variant={INPUT_VARIANT.DARK}
        />
        <Input
          id="surname"
          name="surname"
          placeholder="cognome"
          marginLeft={5}
          ref={register(__FORM_TUTOR_APPROVE__.surname)}
          hasError={errors.surname}
          errorMessage={errors.surname?.message}
          variant={INPUT_VARIANT.DARK}
        />
      </div>

      <Box margin="16px 0px">
        <Controller
          as={
            <S.CalendarInputWrapper ref={calendarRef}>
              <WSInput
                label="birthday"
                hideLabel
                name="birthday"
                theme={whiteTheme}
                autoComplete="off"
                readOnly
                rounded
                onKeyPress={e => e.preventDefault()}
                error={{
                  message: translate(errors.birthday?.message)
                }}
                placeholder={translate("Data di nascita")}
                onClick={() => setCalendarOpen(val => !val)}
                ref={register(__FORM_TUTOR_APPROVE__.birthday)}
              />
              <Box zIndex={1} position="absolute" left={0} top="40px">
                <Calendar
                  visible={isCalendarOpen}
                  selectedDate={date}
                  onChange={handleChangeDate}
                  years={yearsToShow}
                  maxDate={subtractYears(18)}
                  minDate={subtractYears(117)}
                />
              </Box>
            </S.CalendarInputWrapper>
          }
          control={control}
          rules={__FORM_TUTOR_APPROVE__.birthday}
          name="birthday"
          defaultValue={date}
        />
      </Box>

      <Input
        id="email"
        name="email"
        placeholder="registration_email_label"
        ref={register(__FORM_TUTOR_APPROVE__.email)}
        hasError={errors.email}
        errorMessage={errors.email?.message}
        marginTop={10}
        variant={INPUT_VARIANT.DARK}
      />
      <TermsGroup
        errors={errors}
        watchTerms={watchTerms}
        register={register}
        validationObject={__FORM_TUTOR_APPROVE__}
      />
      <div className="margin-top-10">
        <Button type="submit" variant="primary" theme={whiteTheme} fullWidth>
          <Translate text="continue_label" />
        </Button>
      </div>
    </form>
  );
};

export default UnderageApprovationForm;
