import { SisOnboarding } from "src/js/types/models/MarketingData";
import styled, { css } from "styled-components";
import { SWithMobileProps } from "./BusinessGroupCreationForm.types";

export const StyledForm = styled.form<SWithMobileProps & SisOnboarding>`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  ${({ isOnboarding }) =>
    isOnboarding &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      border-radius: 12px;
      width: 100%;
      /** just for extra safety in case of 14inch or less monitor or iPad */
      @media screen and (max-height: 735px) {
        & :first-child {
          & > div:first-of-type {
            & > div {
              height: 120px !important;
            }
          }
        }
      }
    `}
`;

export const SubmitWrapper = styled.div`
  position: sticky;
  bottom: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: auto;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const StyledLabel = styled.label`
  all: unset;
  display: block;
  margin: 16px 0 8px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Spacer = styled.div`
  margin-top: 16px;
`;
