import { EditorContent } from "@tiptap/react";
import {
  ActionButton,
  Loader,
  Text,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const editableContentMixin = () => css`
  * {
    font-size: 16px;
    line-height: 24px;
  }

  & > * + * {
    margin: 4px 0 0;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    padding: 0 18px;
  }

  code {
    font-family: ${({ theme }) => theme.fontFamily.default};
    font-size: 16px;
    background-color: ${({ theme }) => theme.primaryColor[800]};
    border: 1px solid ${({ theme }) => theme.primaryColor[700]};
    border-radius: 2px;
    color: ${({ theme }) => theme.primaryColor[300]};
    line-height: 26px;
  }

  pre {
    font-family: ${({ theme }) => theme.fontFamily.default};
    background-color: ${({ theme }) => theme.colors.grey[200]};
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.grey[800]};
    width: fit-content;

    code {
      color: inherit;
      padding: 0;
      background: none;
      border: none;
    }
  }

  blockquote {
    padding: 0 10px;
    margin: 0 0 4px;
    border: none;
    position: relative;
    font-size: 16px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 2px;
      background-color: ${({ theme }) => theme.primaryColor[500]};
    }
  }

  // placeholder
  p.is-editor-empty:first-child::before {
    color: ${({ theme }) => theme.colors.grey[700]};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    max-width: 100%;
    pointer-events: none;
    overflow-x: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    // IOS rules
    @supports (-webkit-touch-callout: none) {
      float: unset;
      height: unset;
      overflow-x: hidden;
      display: inline-block;
    }
  }
`;

export const StyledEditableContent = styled(EditorContent)<{
  hasResources?: boolean;
}>`
  .ProseMirror {
    ${scrollbarMixin(VariantType.White)}
    ${editableContentMixin()}
    overflow-y: auto;
    min-height: ${({ hasResources }) => (hasResources ? "32px" : "92px")};
    max-height: 30vh;
    padding: 8px 64px 8px 12px;
    @media screen and (max-width: 767px) {
      min-height: 20px;
      padding: 20px 12px 4px;
    }
    @supports (-webkit-touch-callout: none) {
      min-height: ${({ hasResources }) => (hasResources ? "20px" : "80px")};
    }
  }
`;

export const DropActiveText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const EditorContainer = styled.div<{
  dropActive: boolean;
  isFocused: boolean;
}>`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.grey[800] : theme.colors.grey[900]};
  @media screen and (max-width: 767px) {
    border-radius: 8px 8px 0 0;
  }
  ${({ dropActive }) =>
    dropActive &&
    css`
      border: 1px dashed ${({ theme }) => theme.colors.blueGrey[500]};
      background: ${({ theme }) => theme.primaryColor[800]};
      *:not(${DropActiveText}) {
        visibility: hidden;
      }
    `}
`;

export const SubmitLoader = styled(Loader)`
  position: absolute;
  top: -4px;
  left: -4px;
`;

export const SubmitWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

export const CancelButton = styled(ActionButton)`
  position: absolute;
  bottom: 12px;
  right: 64px;
`;

export const TextEditorResources = styled.div`
  display: flex;
  gap: 12px;
  padding: 8px 12px 8px 12px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-right: 64px;
  ::-webkit-scrollbar {
    height: 8px;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
    border-radius: 8px;
    &:hover {
      background: ${({ theme }) => theme.colors.blueGrey[900]};
      background-clip: padding-box;
    }
  }
  @media screen and (max-width: 767px) {
    margin-right: initial;
  }
`;
