import getUserClient from "src/js/modules/connection";
import {
  api_dashboard_analytics_indexes,
  api_dashboard_analytics_trends,
  api_dashboard_groups,
  api_dashboard_groups_indexes,
  api_dashboard_member,
  api_dashboard_members,
  api_dashboard_settings,
  api_dashboard_user,
  api_dashboard_user_indexes,
  api_dashboard_users,
  api_dashboard_users_roles,
  api_spaces_member,
  api_spaces_users,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __SPACE_PLANS_JSON_URL__ } from "src/js/settings/settingsUrl";
import {
  DashboardSettings,
  DashboardSettingsV3,
  FetchSpacesUsersParams
} from "../types/models/Dashboard";
import {
  SpaceUserMember,
  SpaceUsersQueryParam,
  SpaceUsersQueryParamType,
  StaffMember
} from "../types/models/Staff";
import { BasePaginationParams, StandardPaginationResponse } from "../types";
import { prepareCustomFieldsFormData } from "../pages/spaces/dashboard/SpacesSettings/SpacesSettingsCustomization/components/sections/CustomFields/utils";

export function fetchSpaceSettings({
  spaceId,
  checkSubscription = false
}): Promise<DashboardSettingsV3> {
  return getUserClient()
    .get(getVersionedApiUrl(api_dashboard_settings({ spaceId }), "v3"), {
      params: {
        checkSubscription
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export async function updateSpaceSettings({
  spaceId,
  badge,
  layoutColor,
  ctaFillColor,
  spaceCover,
  removeBadge,
  removeSpaceCover,
  name,
  description,
  disableChat,
  assessmentsGradingMethod,
  assessmentsCompletionThreshold,
  targetAudience,
  customFields
}: DashboardSettings) {
  const formData = new FormData();
  if (layoutColor || layoutColor === "")
    formData.append("layoutColor", layoutColor);
  if (ctaFillColor || ctaFillColor === "")
    formData.append("ctaFillColor", ctaFillColor);
  if (badge) formData.append("badge", badge);
  if (spaceCover) formData.append("spaceCover", spaceCover);
  if (removeBadge)
    formData.append("removeBadge", removeBadge as unknown as string);
  if (removeSpaceCover)
    formData.append("removeSpaceCover", removeSpaceCover as unknown as string);
  if (name) formData.append("name", name);
  if (disableChat !== undefined)
    formData.append("disableChat", (disableChat ? 1 : 0) as unknown as string);
  if (description) formData.append("description", description);
  const headers = {
    "Content-Type": "multipart/form-data"
  };
  if (assessmentsGradingMethod)
    formData.append("assessmentsGradingMethod", assessmentsGradingMethod);
  if (assessmentsCompletionThreshold !== undefined)
    formData.append(
      "assessmentsCompletionThreshold",
      `${assessmentsCompletionThreshold}`
    );

  // Prepare custom fields for update

  if (customFields) {
    const customFieldsFormData = prepareCustomFieldsFormData({
      customFields,
      targetAudience
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of (customFieldsFormData as any).entries()) {
      formData.append(entry[0], entry[1]);
    }
  }
  return getUserClient()
    .post(
      getVersionedApiUrl(api_dashboard_settings({ spaceId }), "v3"),
      formData,
      {
        headers
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpacesGroups({
  spaceId,
  limit,
  offset,
  name_filter,
  status_filter,
  ordination_by_filter,
  ordination_direction_filter
}: {
  spaceId: string;
  limit: number;
  offset: number;
  name_filter?: string;
  status_filter?: string;
  ordination_by_filter?: string;
  ordination_direction_filter?: string;
}) {
  return getUserClient()
    .get(getVersionedApiUrl(api_dashboard_groups({ spaceId }), "v3"), {
      params: {
        limit,
        offset,
        nameFilter: name_filter,
        statusFilter: status_filter,
        ordinationByFilter: ordination_by_filter,
        ordinationDirectionFilter: ordination_direction_filter
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceAnalyticsIndexes({
  spaceId,
  user = null,
  groups = null
}) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_dashboard_analytics_indexes({ spaceId }), "v3"),
      {
        params: {
          user,
          groups
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceUserAnalyticsIndexes({ spaceId, userId }) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_dashboard_user_indexes({ spaceId, userId }), "v3")
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceGroupsAnalyticsIndexes({ spaceId, groupIds }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_dashboard_groups_indexes({ spaceId }), "v3"), {
      params: {
        groupIds
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceTrendGraphData({
  spaceId,
  userId = null,
  groupIds,
  type,
  range,
  from_date,
  to_date
}) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_dashboard_analytics_trends({ spaceId }), "v3"),
      {
        params: {
          userId,
          groupIds,
          type,
          range,
          fromDate: from_date,
          toDate: to_date
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpacesUsers({
  spaceId,
  limit,
  offset,
  name,
  groups,
  roles,
  order_by,
  sort_order,
  include_disabled
}: FetchSpacesUsersParams) {
  return getUserClient()
    .get(getVersionedApiUrl(api_dashboard_users({ spaceId }), "v3"), {
      params: {
        limit,
        offset,
        name,
        groups,
        roles,
        orderBy: order_by,
        sortOrder: sort_order,
        includeDisabled: include_disabled ? 1 : 0
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceUserRoles({ spaceId, userId, limit, offset }) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_dashboard_users_roles({ spaceId, userId }), "v3"),
      {
        params: {
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function deleteSpaceUserGroups({ spaceId, userId }) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_dashboard_user({ spaceId, userId }), "v3"))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceMembers({
  spaceId,
  limit,
  offset,
  name
}: BasePaginationParams & {
  name?: string;
  spaceId: string;
}) {
  const params = Object.fromEntries(
    Object.entries({
      limit,
      offset,
      name
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, v]) => v !== "")
  );
  return getUserClient()
    .get<StandardPaginationResponse<StaffMember>>(
      getVersionedApiUrl(api_dashboard_members({ spaceId }), "v3"),
      { params }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpaceUsers({
  spaceId,
  limit,
  offset,
  name,
  role = SpaceUsersQueryParam.NonStaff
}: BasePaginationParams & {
  name?: string;
  spaceId: string;
  role: SpaceUsersQueryParamType;
}) {
  const params = Object.fromEntries(
    Object.entries({
      limit,
      offset,
      name,
      role
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, v]) => v !== "")
  );
  return getUserClient()
    .get<StandardPaginationResponse<SpaceUserMember>>(
      getVersionedApiUrl(api_spaces_users({ spaceId }), "v3"),
      { params }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function updateSpaceUserRole({ spaceId, userId, role }) {
  const body = {
    userId,
    role
  };
  return (
    getUserClient()
      // TODO: remove this api and use api_dashboard_members when endpoint will be accepting POST method
      .post(getVersionedApiUrl(api_spaces_member({ spaceId }), "v3"), body)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        throw error?.response?.data;
      })
  );
}

export function revokeSpaceUserRole({ spaceId, userId }) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_dashboard_member({ spaceId, userId }), "v3"))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function fetchSpacePlansOptions() {
  return fetch(__SPACE_PLANS_JSON_URL__).then(response => response.json());
}
