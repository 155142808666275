import { Button, Icon } from "@arcadia/design-system";
import styled from "styled-components";

const MaxWidth = "688px";

export const InfoWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  overflow: hidden;
  width: 100%;
  margin-top: 28px;
  display: block;
  max-width: ${MaxWidth};
  margin-bottom: 16px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;

export const InfoIcon = styled(Icon)`
  margin-right: 12px;
`;

export const ButtonWrapper = styled.div`
  max-width: ${MaxWidth};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  align-items: center;
  min-height: calc(100vh - 130px);

  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
`;

export const SecondaryHeaderContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const UsernameWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 16px;
  max-width: ${MaxWidth};
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 0px;

  @media (max-width: 767px) {
    position: relative;
  }
`;
