import React, { useMemo, useRef } from "react";
import Url from "url-parse";
import { observer } from "mobx-react";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { OldResource, Resource } from "src/js/types";
import { useTranslation } from "src/js/translation";
import { __RESOURCE_GMS_ULR__ } from "src/legacy/settings/settings";
import { createURL } from "src/legacy/modules/utility";
import { returnAccessToken } from "src/legacy/modules/ajax";
import { FallbackPlaceholder } from "src/js/components/resources/FallbackPlaceholder";
import { FallbackResource } from "src/js/components/resources/FallbackPlaceholder/FallbackPlaceholder";
import { useEmbeddable } from "src/js/query/embeddable";
import { useMeQueryCached } from "src/js/query";

const Link = ({
  boardElementId,
  boardElementOwnerUuid,
  resource
}: {
  boardElementId: number;
  boardElementOwnerUuid?: string;
  resource: (OldResource & { url?: string }) | Resource;
}) => {
  const { data: activeUser } = useMeQueryCached();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const iframe = useRef(null);

  // This LINK file is called in modules/chat/wall/calendar
  // but only in the modules i have the boardElement wrapper
  const resourceOwnerId = useMemo(() => {
    if ("user" in resource) {
      return Number(resource.user.id);
    }
  }, [resource]);

  const isLinkOwner = useMemo(
    () =>
      activeUser.id === resourceOwnerId ||
      activeUser.uuid === boardElementOwnerUuid,
    [resourceOwnerId, boardElementOwnerUuid]
  );

  // TODO this is caused by the uncertainty of where the resource came from (calendar, chat and wall are still using oldResource)
  // when we define all the entrypoints and be sure, we can get rid of the old ones
  const publicUrl = useMemo(() => {
    if ("public_url" in resource) {
      return resource.public_url;
    }
    if ("url" in resource) {
      return resource.url as string;
    }
    if ("publicUrls" in resource) {
      return resource.publicUrls[0];
    }
    return null;
  }, [resource]);

  const {
    data: embeddableRes,
    isLoading,
    isError
  } = useEmbeddable({ publicUrl }, { enabled: !!publicUrl, staleTime: 0 });

  if (isLoading) {
    return <DoodleLoader theme={greyTheme} />;
  }

  if (!publicUrl || isError) {
    return (
      <div className="text-center">{translate("general_error_message")}</div>
    );
  }

  // TODO check WTF is this SHIT
  if (
    publicUrl.indexOf(createURL(__RESOURCE_GMS_ULR__)) > -1 || // __RESOURCE_GMS_URL__ = "api_crm"
    publicUrl.indexOf("/v2/crm") > -1
  ) {
    const customUrl = new Url(publicUrl, null, true);
    if (!Object.prototype.hasOwnProperty.call(customUrl.query, "beid"))
      customUrl.query.beid = String(boardElementId);
    customUrl.query.access_token = returnAccessToken();
    return (
      <div className="full-height" key={`res${resource.id}`}>
        <iframe
          title={String(resource.id)}
          allow="autoplay"
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          src={customUrl.toString()}
        />
      </div>
    );
  }

  if (embeddableRes.embeddable) {
    return (
      <div className="full-height">
        <iframe
          title={String(resource.id)}
          id="link-player"
          allow="autoplay"
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          ref={iframe}
          src={publicUrl}
        />
      </div>
    );
  }

  return (
    <FallbackPlaceholder
      url={publicUrl}
      type={isLinkOwner ? FallbackResource.linkOwner : FallbackResource.link}
    />
  );
};

export default observer(Link);
