import { Dropdown, ModalSizeType } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { DashboardEvent, EventDomain } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { useTheme } from "styled-components";
import {
  headers,
  permissionsList,
  PermissionsTableRoles,
  PermissionsTableRolesType,
  rolesList
} from "./PermissionsTableModal.const";
import * as S from "./PermissionsTableModal.styles";

const PermissionsTableModal = () => {
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const [viewRole, setViewRole] = useState<PermissionsTableRolesType>(
    PermissionsTableRoles.Admin
  );

  useEffect(() => {
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.PermissionRolesTable
    );
  }, []);

  let filterRole = 0;
  switch (viewRole) {
    case PermissionsTableRoles.Staff:
      filterRole = 1;
      break;
    case PermissionsTableRoles.Instructor:
      filterRole = 2;
      break;
    case PermissionsTableRoles.Student:
      filterRole = 3;
      break;
    case PermissionsTableRoles.Admin:
    default:
      filterRole = 0;
  }

  const mobileTable = (
    <S.MobileContainer id={UsetifulId.PermissionRolesTable}>
      <S.DropdownWrapper>
        <Dropdown
          id="role"
          theme={greyTheme}
          optionsList={rolesList.map(option => ({
            id: option.id,
            label: translate(option.name)
          }))}
          selectedOptionId={viewRole}
          setSelectedOptionId={newRole => setViewRole(newRole)}
          placeholder={translate("spaces_users_filter_role")}
        />
      </S.DropdownWrapper>
      {permissionsList.flatMap((row, rowIndex) => (
        <S.MobileRow key={`row-title-${row.name}`} isOdd={rowIndex % 2 !== 0}>
          {translate(row.name)}
          {row.permissions[filterRole] ? (
            <S.StyledIcon icon="check2" width={16} height={16} />
          ) : null}
        </S.MobileRow>
      ))}
    </S.MobileContainer>
  );

  const desktopTable = (
    <S.TableContainer id={UsetifulId.PermissionRolesTable}>
      {headers.map(header => (
        <S.TableCell key={`header-${header}`} header isOdd>
          {header ? translate(header) : null}
        </S.TableCell>
      ))}
      {permissionsList.flatMap((row, rowIndex) => (
        <>
          <S.TableCell
            key={`row-title-${row.name}`}
            isOdd={rowIndex % 2 !== 0}
            leftAlignment
          >
            {translate(row.name)}
          </S.TableCell>

          {row.permissions.map((permission, colIndex) => (
            <S.TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${row.name}-${colIndex}`}
              isOdd={rowIndex % 2 !== 0}
            >
              {permission ? (
                <S.StyledIcon icon="check2" width={16} height={16} />
              ) : null}
            </S.TableCell>
          ))}
        </>
      ))}
    </S.TableContainer>
  );

  return (
    <ModalLayout
      labels={{
        title: translate("staff_roles_permissions_title"),
        mobileClose: translate("close")
      }}
      closeModal={closeModal}
      desktopProps={{
        size: ModalSizeType.Big
      }}
    >
      {isLayoutModeMobile ? mobileTable : desktopTable}
    </ModalLayout>
  );
};

export default observer(PermissionsTableModal);
