import React, { createContext, useContext, useState } from "react";
import { ModalLayoutProps } from "../components/modal/ModalLayout/ModalLayout.types";

interface ModalContextProps {
  title?: string;
  modalConfig?: Partial<ModalLayoutProps>;
  changeTitle: (newTitle: string) => void;
  changeModalConfig: (newConfig: Partial<ModalLayoutProps> | undefined) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [modalConfig, setModalConfig] = useState<
    Partial<ModalLayoutProps> | undefined
  >();

  const changeTitle = (newTitle: string) => {
    setTitle(newTitle);
  };

  const changeModalConfig = (newConfig: Partial<ModalLayoutProps>) => {
    setModalConfig(newConfig);
  };

  return (
    <ModalContext.Provider
      value={{ title, changeTitle, modalConfig, changeModalConfig }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
