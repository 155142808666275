import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  ContextualAlert,
  Dropdown,
  Heading,
  Text
} from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { S3Video } from "src/js/settings/settingsVideo";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { useTheme } from "styled-components";
import { TargetAudienceIdDTO } from "src/js/types";
import { Trans, useTranslation } from "src/js/translation";
import { CustomField, CustomFieldsState, CustomFieldsStateId } from "./types";
import * as S from "./CustomFields.styles";

const CustomFields = ({
  customFields,
  setCustomFields,
  targetAudience,
  setTargetAudience,
  editMode
}: {
  customFields: CustomFieldsState;
  setCustomFields: (id: CustomFieldsStateId, newValue: boolean) => void;
  targetAudience: TargetAudienceIdDTO;
  setTargetAudience: (audience: TargetAudienceIdDTO | null) => void;
  editMode: boolean;
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpaceId },
    SpacesSettingsStore: {
      generalSettings: { planSettings }
    }
  } = useStores();

  useEffect(() => {
    // Update the target audience to null if user deselect all the checkboxes
    if (
      Object.values(customFields)
        .filter(field => "checked" in field)
        .every(field => !field.checked) &&
      targetAudience !== null
    ) {
      setTargetAudience(null);
    }
  }, [customFields]);

  return (
    <S.Wrapper>
      <S.IntroText>
        <Heading level="5">
          {translate("space_settings_custom_fields_section_title")}
        </Heading>
        {planSettings?.planType !== "LITE" ? (
          <Text type="formSubtitle">
            <Trans
              i18nKey="space_settings_custom_fields_section_subtitle"
              components={{
                CustomLink: <S.StyledLink target="_blank" />
              }}
            />
          </Text>
        ) : null}
      </S.IntroText>
      {planSettings?.planType === "LITE" ? (
        <Box display="flex" flexDirection="column" gap="12px">
          <VideoPlayer video={S3Video.DashboardCustomizations} />

          <Text type="formSubtitle">
            <Trans
              i18nKey="space_settings_custom_fields_switch_plan_description"
              components={{
                CustomLink: <S.StyledLink target="_blank" />
              }}
            />
          </Text>
          <Box width="max-content">
            <Button
              onClick={() => {
                navigateTo(
                  createUrl("dashboard_settings", {
                    space_id: activeSpaceId,
                    option: "plan"
                  })
                );
              }}
              theme={whiteTheme}
              variant="primary"
            >
              {translate({
                text: "space_settings_custom_fields_switch_plan_cta"
              })}
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <S.AudienceBox>
            <Text type="body">
              {translate("space_settings_custom_fields_section_target_label")}
            </Text>
            <Dropdown
              id="target-audience"
              theme={whiteTheme}
              width="260px"
              disabled={!editMode}
              optionsList={[
                {
                  id: "1",
                  label: translate(
                    "space_settings_custom_fields_section_target_dropdown_option_all"
                  )
                },
                {
                  id: "2",
                  label: translate(
                    "space_settings_custom_fields_section_target_dropdown_option_instructors"
                  )
                },
                {
                  id: "3",
                  label: translate(
                    "space_settings_custom_fields_section_target_dropdown_option_learners"
                  )
                }
              ]}
              selectedOptionId={`${targetAudience}`}
              setSelectedOptionId={id => {
                setTargetAudience(Number(id) as TargetAudienceIdDTO);
              }}
              placeholder={translate(
                "space_settings_custom_fields_section_target_dropdown_placeholder"
              )}
            />
          </S.AudienceBox>
          <S.CustomFieldsWrapper>
            <Text type="subHeaderSubtitle">
              {translate(
                "space_settings_custom_fields_section_custom_checkboxes_label"
              )}
            </Text>
            <S.CheckboxWrapper disabled={!editMode}>
              {(
                Object.entries(customFields) as [
                  CustomFieldsStateId,
                  CustomField
                ][]
              ).map(([id, field]) => (
                <Checkbox
                  key={id}
                  id={`${id}`}
                  disabled={!editMode}
                  checked={field.checked}
                  value={translate(field.name)}
                  onChange={checked => {
                    setCustomFields(id, checked);
                  }}
                  theme={greyTheme}
                  label={translate(field.name)}
                />
              ))}
            </S.CheckboxWrapper>
          </S.CustomFieldsWrapper>
          {editMode && (
            <S.ContextualAlertWrapper>
              <ContextualAlert
                theme={whiteTheme}
                text={translate("space_settings_custom_fields_section_warning")}
              />
            </S.ContextualAlertWrapper>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default CustomFields;
