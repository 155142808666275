/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  fetchSpacePlansOptions,
  fetchSpaceSettings
} from "src/js/repository/dashboardRepository";

/**
 * @name SpacesSettingsStore
 *
 * @description
 * Manages settings page of spaces
 */
class SpacesSettingsStore {
  $generalSettings = {};
  $spacePlansOptions = {
    plans: [],
    seatTiers: [],
    mostPopular: null
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getSpaceSettings = ({ spaceId, checkSubscription }) => {
    if (!spaceId) return Promise.resolve();

    return fetchSpaceSettings({
      spaceId,
      checkSubscription
    })
      .then(settings => {
        this.setGeneralSettings(settings);
      })
      .catch(error => {
        this.setGeneralSettings({});
        throw error;
      });
  };

  getSpacePlansOptions = () => {
    return fetchSpacePlansOptions()
      .then(data => {
        this.setSpacePlansOptions(data);
      })
      .catch(error => {
        this.setSpacePlansOptions({
          plans: [],
          seatTiers: [],
          mostPopular: null
        });
        throw error;
      });
  };

  setGeneralSettings = value => {
    this.$generalSettings = value;
  };

  setSpacePlansOptions = value => {
    this.$spacePlansOptions = value;
  };

  reset = () => {
    this.$generalSettings = {};
  };

  get generalSettings() {
    return this.$generalSettings;
  }

  get spacePlansOptions() {
    return this.$spacePlansOptions;
  }
}

export default SpacesSettingsStore;
