import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";
import { AVATAR_TYPES } from "@ds_universal/Avatar/Avatar.const";

import { AvatarProps } from "./types";
import * as S from "./Avatar.styles";

const getAvatarImg = ({
  disabled,
  url
}: {
  disabled: boolean;
  url: string;
}): string => {
  if (disabled) {
    return AVATAR_TYPES.DISABLED;
  }
  return url || AVATAR_TYPES.DEFAULT;
};

const Avatar: FC<AvatarProps> = ({
  theme,
  rounded = true,
  alt = "descrizione immagine",
  size = "m",
  badge = 0,
  position = "top",
  withIcon = {},
  withTeacherRibbon = false,
  withActiveBorder = false,
  onClick,
  url = "",
  disabled = false,
  ...rest
}) => {
  const width = size === "image" ? undefined : theme.avatar[size].img;
  const _url = getAvatarImg({ url, disabled });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <S.Circle
          rounded={rounded}
          size={size}
          withActiveBorder={
            rounded && withActiveBorder && !badge && !withIcon.icon
          }
          onClick={onClick}
          {...rest}
        >
          <img
            src={_url}
            width={width}
            alt={alt}
            height="100%"
            style={{ height: "100%" }}
          />
        </S.Circle>

        {/* We have to make sure this condition (&& size === "m") doesn't break anything: if we remove it, there will be a TS error. Because if the size is equal to "m", only then will theme.avatar["m"].badge property exist */}
        {(badge || withIcon.icon) && size === "m" ? (
          <S.Badge
            background={theme.avatar[size].badge.background}
            text={theme.avatar[size].badge.text}
            position={position}
            dimension={withIcon?.dimension}
          >
            {withIcon.icon ? (
              <Icon
                icon={withIcon.icon}
                height={
                  withIcon.dimension ? `${withIcon.dimension - 7}px` : "9px"
                }
                width={
                  withIcon.dimension ? `${withIcon.dimension - 7}px` : "9px"
                }
                viewbox="0 0 24 24"
              />
            ) : (
              <Text type="label">{badge < 100 ? badge : "+99"}</Text>
            )}
          </S.Badge>
        ) : null}
        <S.TeacherRibbon>
          {withTeacherRibbon ? (
            <Icon
              icon="teacherRibbon"
              height="16px"
              width="16px"
              viewbox="0 0 24 24"
            />
          ) : null}
        </S.TeacherRibbon>
      </div>
    </ThemeProvider>
  );
};

export default Avatar;
