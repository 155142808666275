import {
  Box,
  Button,
  Checkbox,
  ContextualFeedback,
  Input
} from "@arcadia/design-system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { ExportDataFields } from "./const";
import { useExportData } from "./hooks";

import * as S from "./ExportDataModal.styles";

const ExportDataModal = ({ userUuid }: { userUuid?: string }) => {
  const [includeNoProgress, setIncludeNoProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    ModalStore: { closeModal }
  } = useStores();

  const { onSubmit } = useExportData({ userUuid });

  const { register, handleSubmit, errors, watch } = useForm<{
    email: string;
  }>();

  const emailValue = watch("email");

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{ title: translate("export_scorm_data_modal_title") }}
      desktopProps={{ hasAdaptiveWidth: true }}
    >
      <S.ModalBodyForm
        onSubmit={handleSubmit(({ email }) =>
          onSubmit({ email, includeNoProgress }).then(() => {
            setShowSuccess(true);
          })
        )}
      >
        <S.TextWrapper type="body">
          {translate("export_scorm_data_modal_body_text")}
        </S.TextWrapper>
        <S.InputWrapper>
          <Input
            rounded
            theme={whiteTheme}
            ref={register(ExportDataFields.email)}
            name="email"
            type="field"
            onChange={() => setShowSuccess(false)}
            label={translate("export_scorm_data_modal_email_input_label")}
            placeholder={translate(
              "export_scorm_data_modal_email_input_placeholder"
            )}
            error={
              errors.email && {
                message: translate(
                  errors.email.message as unknown as TranslationKeys
                )
              }
            }
          />
        </S.InputWrapper>
        {!userUuid && (
          <S.CheckboxContainer>
            <Checkbox
              theme={greyTheme}
              checked={includeNoProgress}
              onChange={val => {
                setShowSuccess(false);
                setIncludeNoProgress(val);
              }}
              label={translate(
                "export_scorm_data_modal_no_progress_checkbox_label"
              )}
            />
          </S.CheckboxContainer>
        )}
        {showSuccess && (
          <ContextualFeedback theme={whiteTheme}>
            {translate("export_scorm_data_modal_success_banner_text")}
          </ContextualFeedback>
        )}
        <Box marginTop="8px" alignSelf="center" display="flex">
          <Button
            variant="primary"
            theme={whiteTheme}
            type="submit"
            disabled={!!errors.email || !emailValue}
          >
            {translate("export_scorm_data_modal_submit_button")}
          </Button>
        </Box>
      </S.ModalBodyForm>
    </ModalLayout>
  );
};

export default ExportDataModal;
