import React from "react";
import { Box } from "@ds_universal/Box";
import { PageNav } from "@ds_universal/data_display";
import { __GROUP_MEMBERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

const GroupSettingsTablePagination = ({
  totalRecords,
  getRecords,
  setPage
}: {
  totalRecords: number;
  getRecords: (p: number) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <Box display="block" marginLeft="auto" width="max-content">
      <PageNav
        theme={greyTheme}
        onPageChanged={p => {
          setPage(
            p * __GROUP_MEMBERS_LIST_LIMIT__ - __GROUP_MEMBERS_LIST_LIMIT__
          );
          getRecords(
            p * __GROUP_MEMBERS_LIST_LIMIT__ - __GROUP_MEMBERS_LIST_LIMIT__
          );
        }}
        totalRecords={totalRecords}
        pageLimit={__GROUP_MEMBERS_LIST_LIMIT__}
        startingOffset={0}
        label={{
          previous: translate("prev"),
          next: translate("next")
        }}
      />
    </Box>
  );
};

export default GroupSettingsTablePagination;
