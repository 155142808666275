import { Link } from "@ds_universal/data_entry";
import { Heading } from "@ds_universal/Heading";
import { Icon } from "@ds_universal/Icon";
import styled from "styled-components";

export const ModalContainer = styled.div`
  padding: 24px;
`;

export const VideoContainer = styled.div`
  margin-top: 24px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 8px;
`;

export const FileItemCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  height: 84px;
`;

export const FileIcon = styled(Icon)`
  display: flex;
  flex: 0 0 48px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
`;

export const FileDataContent = styled.div`
  overflow: hidden;
`;

export const FileTitle = styled(Heading)`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: 6px;
`;

export const FileSizeLabel = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const StyledLinkButton = styled(Link)`
  border: none !important;
  outline: none !important;
  display: block;
  padding: 0;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  gap: 12px;
`;
