import React, { useEffect, useRef, useState } from "react";
import { EventAttachmentItem } from "src/js/components/EventAttachmentItem";
import { QuickActionButton } from "src/js/components/exercises/QuickActionButton";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { DurationCounterBadge } from "src/js/components/DurationCounterBadge";
import { RESOURCE_ICON_TYPE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import { SaveRecordingModal } from "src/js/components/SaveRecordingModal";
import { DoodleLoader, Icon, Heading, Text, Box } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  EventStatus,
  EventCategory,
  EventAttachmentMode
} from "src/js/pages/calendar/Calendar.types";
import { EventDownload } from "src/js/pages/calendar/EventDownload";
import {
  getEventStatus,
  getEventUrl,
  getDatesUtils
} from "src/js/pages/calendar/CalendarUtils";
import { EventRecipientsList } from "src/js/pages/calendar/EventRecipientsList";

import { CalendarTag } from "src/js/components/Calendar/CalendarTag";
import { format, intervalToDuration } from "date-fns";
import * as S from "./CalendarEventDetail.styles";
import CalendarEventDetailLiveBanner from "./CalendarEventDetailLiveBanner";

const CalendarEventDetail = ({
  eventDetail = {},
  groupId,
  userIsTeacher = false,
  accordionMode = false,
  editFunction = () => {},
  deleteFunction = () => {},
  copyFunction = () => {},
  openRecipientsModal = () => {},
  openResource = () => {}
}) => {
  const { whiteTheme } = useTheme();
  const {
    id,
    title,
    type,
    all_day,
    start,
    end,
    description,
    group_recipients = [],
    user_recipients = [],
    live_room,
    userIsCreator,
    has_recording,
    resource_attachments = [],
    board_attachments = [],
    exercise_attachments = []
  } = eventDetail || {};

  const titleRef = useRef();
  const [bodyMaxHeight, setBodyMaxHeight] = useState(260);

  useEffect(() => {
    const newHeight = titleRef?.current?.clientHeight;
    setBodyMaxHeight(284 - newHeight);
  }, [title]);

  const { ModalStore } = useStores();

  const { startDate, endDate, dateNow, duration, hasSameDay, hasSameTime } =
    getDatesUtils({
      start,
      end
    });

  const [status, setStatus] = useState(
    getEventStatus({
      dateNow,
      endDate,
      startDate,
      eventType: type
    })
  );
  const [timeFromStart, setTimeFromStart] = useState(
    startDate ? intervalToDuration({ start: new Date(), end: startDate }) : null
  );

  const [timeToStart, setTimeToStart] = useState(
    startDate ? intervalToDuration({ start: startDate, end: new Date() }) : null
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setStatus(
        getEventStatus({
          dateNow,
          endDate,
          startDate,
          eventType: type
        })
      );
      setTimeFromStart(
        startDate
          ? intervalToDuration({ start: new Date(), end: startDate })
          : null
      );
      setTimeToStart(
        startDate
          ? intervalToDuration({ start: startDate, end: new Date() })
          : null
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [startDate, endDate]);

  const openSaveRecordingModal = (name, resourceId) => {
    ModalStore.openModal(() => (
      <SaveRecordingModal
        resourceTitle={name}
        resourceId={resourceId}
        closeFunction={() => ModalStore.closeModal()}
      />
    ));
  };

  if (isEmptyObject(eventDetail)) {
    return <DoodleLoader theme={whiteTheme} isMini />;
  }

  const titleUrl = getEventUrl({ eventId: id, eventType: type, groupId });

  const userCanEdit =
    (type === EventCategory.Live && userIsCreator) ||
    (type === EventCategory.Activity && userIsTeacher) ||
    (type === EventCategory.Deadline && userIsTeacher);

  return (
    <S.CalendarDetailContainer>
      {!accordionMode && (
        <>
          <S.EventHeader>
            <CalendarTag type={type} status={status} />
            {userCanEdit && (
              <S.QuickActionContainer>
                <QuickActionButton onClick={() => editFunction(eventDetail)}>
                  <Icon icon="edit" height="16px" width="16px" />
                </QuickActionButton>
                <QuickActionButton onClick={() => copyFunction(eventDetail)}>
                  <Icon icon="copy" width={16} height={16} initialViewbox />
                </QuickActionButton>
                <QuickActionButton onClick={() => deleteFunction(id)}>
                  <Icon icon="trash" height="16px" width="16px" />
                </QuickActionButton>
              </S.QuickActionContainer>
            )}
          </S.EventHeader>
          <S.EventTitle href={titleUrl} ref={titleRef}>
            <Heading level="4">{title}</Heading>
          </S.EventTitle>
        </>
      )}
      <S.EventBody accordionMode={accordionMode}>
        <ScrollableDiv maxHeight={accordionMode ? null : `${bodyMaxHeight}px`}>
          {accordionMode && (
            <S.SectionTitle>
              <Text type="label">
                <Translate text="event_detail_duration" />
              </Text>
            </S.SectionTitle>
          )}
          <S.CompleteDateRange accordionMode={accordionMode}>
            {format(startDate, "iii dd MMM")}
            {!hasSameDay && ` - ${format(endDate, "iii dd MMM")}`}
          </S.CompleteDateRange>
          {!accordionMode && !all_day && (
            <S.VerticalCenteredRow>
              <Icon icon="clock" height="10px" width="10px" />
              <S.HourRange>
                {format(startDate, "HH:mm")}
                {!hasSameTime && ` - ${format(endDate, "HH:mm")}`}
              </S.HourRange>
              {!hasSameTime && (
                <DurationCounterBadge duration={duration} theme={whiteTheme} />
              )}
            </S.VerticalCenteredRow>
          )}
          <Box marginTop={16}>
            <EventDownload eventId={id} />
          </Box>
          <S.EventRecipientsContainer>
            <S.SectionTitle>
              <Text type="label">
                <Translate text="event_detail_recipients" />
              </Text>
            </S.SectionTitle>
            <EventRecipientsList
              group_recipients={group_recipients}
              user_recipients={user_recipients}
              onClickItem={() => {
                openRecipientsModal({
                  group_recipients,
                  user_recipients
                });
              }}
            />
          </S.EventRecipientsContainer>
          {description && (
            <>
              <S.SectionTitle>
                <Text type="label">
                  <Translate text="event_detail_description" />
                </Text>
              </S.SectionTitle>
              <S.EventDescription>
                <Text type="formDescription">{description}</Text>
              </S.EventDescription>
            </>
          )}

          <S.EventAttachments>
            {resource_attachments.map(
              ({ id: resourceId, type: resourceType, name }) => {
                return (
                  <EventAttachmentItem
                    key={resourceId}
                    mode={EventAttachmentMode.View}
                    id={resourceId}
                    type={resourceType}
                    name={name}
                    marginBottom={10}
                    openResource={resource =>
                      openResource({
                        ...resource
                      })
                    }
                  />
                );
              }
            )}
            {board_attachments.map(({ id: resourceId, name }) => {
              return (
                <EventAttachmentItem
                  key={resourceId}
                  mode={EventAttachmentMode.View}
                  id={resourceId}
                  type={RESOURCE_ICON_TYPE.BOARD}
                  name={name}
                  marginBottom={10}
                  openResource={resource =>
                    openResource({
                      ...resource,
                      eventType: type,
                      eventId: id
                    })
                  }
                />
              );
            })}
            {exercise_attachments.map(({ id: resourceId, name }) => {
              return (
                <EventAttachmentItem
                  key={resourceId}
                  mode={EventAttachmentMode.View}
                  id={resourceId}
                  type={RESOURCE_ICON_TYPE.EXERCISE}
                  name={name}
                  marginBottom={10}
                  openResource={resource =>
                    openResource({
                      ...resource,
                      eventType: type,
                      eventId: id
                    })
                  }
                />
              );
            })}
          </S.EventAttachments>
          {status === EventStatus.Finished &&
            has_recording &&
            userIsCreator &&
            live_room?.recordings &&
            live_room.recordings.length > 0 && (
              <>
                <S.SectionTitle>
                  <Text type="formTitle">
                    <Translate text="event_detail_download_lives_label" />
                  </Text>
                </S.SectionTitle>
                {live_room?.recordings.map(({ name, resource_id }) => {
                  return (
                    <S.RecordingResourceWrapper key={resource_id}>
                      <S.RecordingResource
                        onClick={() => openResource({ id: resource_id })}
                      >
                        <Text type="formDescription">{name}</Text>
                      </S.RecordingResource>
                      <S.SaveRecordingButton
                        type="button"
                        onClick={() => {
                          openSaveRecordingModal(name, resource_id);
                        }}
                      >
                        <Icon icon="download" height="16px" width="16px" />
                      </S.SaveRecordingButton>
                    </S.RecordingResourceWrapper>
                  );
                })}
              </>
            )}
        </ScrollableDiv>
      </S.EventBody>
      {type === EventCategory.Live ? (
        <CalendarEventDetailLiveBanner
          accordionMode={accordionMode}
          status={status}
          event_id={id}
          liveUrl={live_room?.url}
          dateNow={dateNow}
          startDate={startDate}
          endDate={endDate}
          userIsCreator={userIsCreator}
          timeFromStart={timeFromStart}
          timeToStart={timeToStart}
        />
      ) : null}
      {accordionMode && userCanEdit && (
        <S.EventFooter>
          <S.QuickActionContainer accordionMode={accordionMode}>
            <QuickActionButton onClick={() => editFunction(eventDetail)}>
              <Icon icon="edit" height="16px" width="16px" />
            </QuickActionButton>
            <QuickActionButton onClick={() => copyFunction(eventDetail)}>
              <Icon icon="copy" width={20} height={20} initialViewbox />
            </QuickActionButton>
            <QuickActionButton onClick={() => deleteFunction(id)}>
              <Icon icon="trash" height="16px" width="16px" />
            </QuickActionButton>
          </S.QuickActionContainer>
        </S.EventFooter>
      )}
    </S.CalendarDetailContainer>
  );
};

export default CalendarEventDetail;
