import { makeAutoObservable } from "mobx";

class UserInviteStore {
  $usersToInvite = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateUserList = email => {
    this.$usersToInvite = [...this.$usersToInvite, email];
  };

  removeUserFromList = email => {
    const updatedList = this.$usersToInvite.filter(
      userEmail => userEmail !== email
    );
    this.$usersToInvite = updatedList;
  };

  reset = () => {
    this.$usersToInvite = [];
  };

  get usersToInvite() {
    return [...new Set(this.$usersToInvite)];
  }
}

export default UserInviteStore;
