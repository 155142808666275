import { Icon, ProgressBar, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { func, oneOf } from "prop-types";
import React from "react";
import { SingleTaskCard } from "src/js/components/task/SingleTaskCard";
import { useStores } from "src/js/hooks";
import { createStaticUrl } from "src/js/modules/routing";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { TASKS_STATUS_WIDGET_MODE } from "./TasksStatusWidget.const";

import * as S from "./TasksStatusWidget.styles";

const TasksStatusWidget = ({ mode, mobileNavigationToggle }) => {
  const { whiteTheme, colors } = useTheme();
  const { UserTasksStore } = useStores();
  const {
    incompletedTasksList,
    tasksAreaIsExpanded,
    tasksAreaIsVisible,
    tasksAreaIsTemporarilyHidden,
    tasksCompletedNumber,
    tasksTotalNumber,
    setTasksAreaVisible,
    setTasksAreaExpanded
  } = UserTasksStore || {};

  const whiteBk = colors.grey[1000];

  if (
    incompletedTasksList?.length === 0 ||
    (!tasksAreaIsVisible && mode === TASKS_STATUS_WIDGET_MODE.DESKTOP)
  ) {
    return null;
  }

  const progressPercentage = tasksTotalNumber
    ? (tasksCompletedNumber / tasksTotalNumber) * 100
    : 0;

  const expandArea = () => {
    if (mode === TASKS_STATUS_WIDGET_MODE.DESKTOP && !tasksAreaIsExpanded) {
      setTasksAreaExpanded(true);
    }
  };

  const isExpanded =
    mode === TASKS_STATUS_WIDGET_MODE.DESKTOP && tasksAreaIsExpanded;

  const opacity =
    mode === TASKS_STATUS_WIDGET_MODE.DESKTOP && tasksAreaIsTemporarilyHidden
      ? 0
      : 1;

  return (
    <S.WidgetWrapper
      isExpanded={isExpanded}
      onClick={expandArea}
      positionFixed={mode === TASKS_STATUS_WIDGET_MODE.DESKTOP}
      opacity={opacity}
    >
      {mode === TASKS_STATUS_WIDGET_MODE.MOBILE && (
        <S.LinkLayer
          href={`#${createStaticUrl("user_tips_settings")}`}
          onClick={mobileNavigationToggle}
        />
      )}
      <S.WidgetHeader>
        <Text type="formTitle">
          {translateString({ text: "tasks_status_widget_title" })}
        </Text>
        {mode === TASKS_STATUS_WIDGET_MODE.MOBILE ? (
          <S.CustomLink
            href={`#${createStaticUrl("user_tips_settings")}`}
            theme={whiteTheme}
          >
            {translateString({ text: "tasks_status_widget_link" })}
          </S.CustomLink>
        ) : (
          <S.WidgetActionsContainer isExpanded={isExpanded}>
            <S.WidgetButton onClick={() => setTasksAreaExpanded(false)}>
              <Icon icon="chevronDown" width="16px" height="16px" />
            </S.WidgetButton>
            <S.Divider />
            <S.WidgetButton
              onClick={() => {
                setTasksAreaVisible(false);
                setTasksAreaExpanded(false);
              }}
            >
              <Icon icon="close" width="16px" height="16px" />
            </S.WidgetButton>
          </S.WidgetActionsContainer>
        )}
      </S.WidgetHeader>
      <ProgressBar
        theme={whiteTheme}
        percentage={progressPercentage}
        variant="thick"
      />
      <S.ListArea isExpanded={isExpanded}>
        <S.CustomScrollableDiv maxHeight="340px">
          {incompletedTasksList?.map(task => (
            <SingleTaskCard
              key={task.id}
              {...task}
              url={task.targetUrl}
              backgroundColor={whiteBk}
            />
          ))}
        </S.CustomScrollableDiv>
        <S.DesktopLinkArea>
          <S.CustomLink
            href={`#${createStaticUrl("user_tips_settings")}`}
            theme={whiteTheme}
          >
            {translateString({ text: "tasks_status_widget_link" })}
          </S.CustomLink>
        </S.DesktopLinkArea>
      </S.ListArea>
      {mode === TASKS_STATUS_WIDGET_MODE.MOBILE && (
        <S.TextCounterContainer type="captionInfoDetails">
          {translateString({
            text: "tasks_status_widget_counter",
            stringVariables: { tasksCompletedNumber, tasksTotalNumber }
          })}
        </S.TextCounterContainer>
      )}
    </S.WidgetWrapper>
  );
};

TasksStatusWidget.propTypes = {
  mode: oneOf(Object.values(TASKS_STATUS_WIDGET_MODE)).isRequired,
  mobileNavigationToggle: func
};

TasksStatusWidget.defaultProps = {
  mobileNavigationToggle: () => {}
};

export default observer(TasksStatusWidget);
