/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import { fetchSpaceGroups } from "src/js/repository/spacesRepository";
import { AvatarModel, GroupInSpaceModel } from "../types";
import SpaceStore from "./SpaceStore";

/**
 * @name SpaceGroupListStore
 *
 * @description
 * List of the groups of a specific space
 *
 */
class SpaceGroupListStore {
  $activeSpaceGroups: GroupInSpaceModel[] = [];
  $offset = 0;
  $limit = 30;
  $activeSpaceHasMoreGroups = false;
  private spaceStore: SpaceStore;
  private $hasMoreGroups: boolean;

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.dispose();
  }

  setSpaceGroups = groups => {
    this.$activeSpaceGroups = groups;
  };

  setOffset = offset => {
    this.$offset = offset;
  };

  setLimit = limit => {
    this.$limit = limit;
  };

  setHasMoreGroups = hasMore => {
    this.$activeSpaceHasMoreGroups = hasMore;
  };

  addGroupsAtStart = groups => {
    this.setSpaceGroups([...groups, ...this.$activeSpaceGroups]);
  };

  fetchActiveSpaceGroups = async (
    spaceId: string,
    next?: boolean,
    filterQueryString?: string
  ) => {
    if (!spaceId) return;
    try {
      const groups = await fetchSpaceGroups({
        spaceId,
        limit: this.$limit,
        offset: this.$offset,
        filterQueryString
      });
      if (next) {
        this.setSpaceGroups([...this.$activeSpaceGroups, ...groups?.results]);
      } else {
        this.setSpaceGroups(groups?.results);
      }
      this.setHasMoreGroups(groups?.hasNext);
    } catch {
      this.setSpaceGroups([]);
    }
  };

  fetchActiveSpaceGroupsNextPage = async () => {
    this.setOffset(this.$offset + this.$limit);
    this.fetchActiveSpaceGroups(this.spaceStore.activeSpaceSlug, true);
  };

  fetchActiveSpaceGroupsAll = async () => {
    // TODO: Skip fetch compare hasMoreGroups, limit, length
    this.setOffset(this.$offset + this.$limit);
    this.setLimit(9999);
    this.fetchActiveSpaceGroups(this.spaceStore.activeSpaceSlug, true);
  };

  updateGroupInfo = ({
    groupId,
    groupInfo
  }: {
    groupId: number;
    groupInfo: {
      name?: string;
      subject?: string;
      description?: string;
      cover?: AvatarModel;
    };
  }) => {
    const groupToUpdate = this.$activeSpaceGroups.find(
      ({ group }) => group.id === groupId
    );
    if (!groupToUpdate) return;
    const newGroup = { ...groupToUpdate.group, ...groupInfo };
    groupToUpdate.group = newGroup;
  };

  removeGroupFromList = ({ groupId }: { groupId: number }) => {
    const newList = this.$activeSpaceGroups.filter(
      group => group.group.id !== groupId
    );
    this.$activeSpaceGroups = newList;

    return newList;
  };

  private adjustCounter = ({
    groupId,
    increment
  }: {
    groupId: number;
    increment: boolean;
  }) => {
    const groupToUpdate = this.$activeSpaceGroups.find(
      ({ group }) => group.id === groupId
    );
    if (!groupToUpdate) return;
    if (increment) {
      groupToUpdate.unreadNotificationsCount += 1;
    } else if (groupToUpdate.unreadNotificationsCount > 0) {
      groupToUpdate.unreadNotificationsCount -= 1;
    }
  };

  increaseNotificationCounterOnGroup = ({ groupId }: { groupId: number }) => {
    this.adjustCounter({ groupId, increment: true });
  };

  decreaseNotificationCounterOnGroup = ({ groupId }: { groupId: number }) => {
    this.adjustCounter({ groupId, increment: false });
  };

  setNotificationCounterOnGroup = ({
    groupId,
    count
  }: {
    groupId: number;
    count: number;
  }) => {
    const groupToUpdate = this.$activeSpaceGroups.find(
      ({ group }) => group.id === groupId
    );
    if (!groupToUpdate) return;
    groupToUpdate.unreadNotificationsCount = count >= 0 ? count : 0;
  };

  get activeSpaceGroups() {
    return this.$activeSpaceGroups;
  }

  get hasMoreGroups() {
    return this.$hasMoreGroups;
  }

  get activeSpaceHasMoreGroups() {
    return this.$activeSpaceHasMoreGroups;
  }

  dispose = () => {
    reaction(
      // reacts on change of active space
      () => this.spaceStore.activeSpaceSlug,
      activeSlug => {
        this.reset();
        this.fetchActiveSpaceGroups(activeSlug);
      }
    );

    reaction(
      () => this.spaceStore.activeSpaceId,
      spaceId => {
        this.reset();
        this.fetchActiveSpaceGroups(spaceId);
      }
    );
  };

  reset = () => {
    this.$activeSpaceGroups = [];
    this.$offset = 0;
    this.$limit = 30;
    this.$hasMoreGroups = false;
  };
}

export default SpaceGroupListStore;
