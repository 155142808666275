import React, { ReactNode, useState } from "react";
import {
  PersistQueryClientProvider,
  PersistQueryClientProviderProps
} from "@tanstack/react-query-persist-client";
import { createIdbPersister } from "src/js/database";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { fetchLoggedUser } from "src/js/repository/userRepository";
import { User } from "src/js/types";
import { isAccessDeniedError } from "src/js/error";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { userQueryKey } from "./user";
import { queryClient } from "./client";

const idbPersister = createIdbPersister();

const persistOptions: PersistQueryClientProviderProps["persistOptions"] = {
  persister: idbPersister,
  buster: process.env.__APP_VER__
};

const PersistedQueryProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const { greyTheme } = useTheme();
  const { UserStore } = useStores();
  const { translate } = useTranslation();

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={persistOptions}
      onSuccess={async () => {
        let user: User | null = null;

        try {
          user = await queryClient.fetchQuery({
            queryKey: userQueryKey.me,
            queryFn: fetchLoggedUser,
            cacheTime: 0,
            staleTime: 0
          });
        } catch (err) {
          if (isAccessDeniedError(err)) {
            user = null;
          } else {
            showToastError({
              str: translate("general_error")
            });
            user = queryClient.getQueryData<User>(userQueryKey.me);
          }
        } finally {
          UserStore.setActiveUser(user);
          setInitialized(true);
        }
      }}
    >
      {initialized ? children : <DoodleLoader theme={greyTheme} />}
    </PersistQueryClientProvider>
  );
};

export default observer(PersistedQueryProvider);
