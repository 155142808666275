import { Dispatch, SetStateAction } from "react";
import { ObjectValues, Resource, ThreadMessage } from "src/js/types";

export type TextEditorSubmitFunction = {
  message: string;
  resources: Resource[];
};

export type TextEditorInputProps = {
  submitFunction: ({ message, resources }: TextEditorSubmitFunction) => void;
  onClick?: () => void;
  onKeyDown?: () => void;
  submitDisabled?: boolean;
  showSubmitLoader?: boolean;
  placeholder?: string;
  editableMessage?: ThreadMessage;
  setEditableMessage?: Dispatch<SetStateAction<ThreadMessage>>;
  editFunction?: ({ message }: { message: string }) => void;
};

export const ResourceActions = {
  SetTempsResources: "SET_TEMPS_RESOURCES",
  RemoveTempResourceById: "REMOVE_TEMP_RESOURCE_BY_ID",
  ReplaceTempResource: "REPLACE_TEMP_RESOURCE",
  RemoveResourceById: "REMOVE_RESOURCE_BY_ID",
  Reset: "RESET"
} as const;

export type ResourceActions = ObjectValues<typeof ResourceActions>;
