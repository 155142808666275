import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useAssessmentScores } from "src/js/query/analytics";
import { useTranslation } from "src/js/translation";
import { DashboardEvent, EventDomain, SpacesGroupType } from "src/js/types";
import { useTheme } from "styled-components";
import { CustomTooltip } from "src/js/components/CustomTooltip";
import { handleMetricCard } from "../../../Analytics.utils";
import { LegendItem } from "../../../components/CustomLegend/CustomLegend";
import { EmptyMetric } from "../../../components/EmptyMetric";
import { MetricCard } from "../../../components/MetricCard";
import { VerticalBarChart } from "../../../components/VerticalBarChart";
import { VerticalBarChartData } from "../../../components/VerticalBarChart/VerticalBarChart";
import { useAnalyticsFilter } from "../../../hooks";
import { AssessmentDetailModal } from "../AssessmentDetailModal";

const AssessmentScore = ({
  noGroupsButton,
  spaceGroups
}: {
  noGroupsButton?: ReactElement;
  spaceGroups: SpacesGroupType[];
}) => {
  const {
    SpaceStore: { activeSpaceId, activeSpaceCustomization, hasDecimalGrading },
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { colors } = useTheme();
  const { translate } = useTranslation();
  const { fromDate, toDate, groupIds, dateLabel } = useAnalyticsFilter();

  const { data: response, isFetching: loading } = useAssessmentScores({
    spaceId: activeSpaceId,
    withChartData: !isLayoutModeMobile,
    fromDate,
    toDate,
    groupIds
  });

  const hasSomeResults = response?.results.some(element => element.value !== 0);
  const isEmpty =
    !response?.results ||
    response?.results.length === 0 ||
    !!noGroupsButton ||
    !hasSomeResults;

  const { assessmentsCompletionThreshold } = activeSpaceCustomization || {};
  const chartThreshold = assessmentsCompletionThreshold / 10;

  const {
    resultLabel = null,
    trendLabel = null,
    trendSeverity = null
  } = !isEmpty
    ? handleMetricCard({
        baseChartResponse: response,
        measurementUnit: hasDecimalGrading ? "/10" : "%",
        trendMeasurementUnit: hasDecimalGrading ? "" : "%",
        resultMultiplier: hasDecimalGrading ? 1 : 10
      })
    : {};

  const data: VerticalBarChartData[] = response?.results.map(
    (element, index) => {
      const value = element.value / 100;
      const total = response.totalAttemptsNumber / 100;
      const percentageValue = total
        ? parseFloat(((value / total) * 100).toFixed(1))
        : 0;
      return {
        label: `${index}`,
        value: percentageValue,
        description: translate({
          text: "assessment_chart_tooltip_description",
          stringVariables: {
            percentageValue,
            value,
            total,
            score: `${index}`
          }
        })
      };
    }
  );

  const onClickDetailView = () => {
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.AssessmentsClickGroupDetail
    );
    openModal(() => <AssessmentDetailModal spaceGroups={spaceGroups} />);
  };

  const AssessmentScoreLegend: LegendItem[] = [
    {
      name: translate("assessment_chart_label_not_pass"),
      color: colors.error[700]
    },
    {
      name: translate("assessment_chart_label_pass"),
      color: colors.success[700]
    }
  ];

  return (
    <MetricCard
      loading={loading}
      titleLabel={translate("metric_card_objectives_assessments_score_title")}
      resultLabel={resultLabel}
      descriptionLabel={translate(
        "metric_card_objectives_assessments_score_description"
      )}
      trendLabel={trendLabel}
      trendSeverity={trendSeverity}
      dateLabel={dateLabel}
      titleChipLabel={`${translate(
        "metric_card_objectives_assessments_score_threshold"
      )} ${
        hasDecimalGrading
          ? assessmentsCompletionThreshold / 10
          : `${assessmentsCompletionThreshold}%`
      }`}
      detailViewLabel={translate("metric_card_detail_view_cta")}
      onClickDetailView={onClickDetailView}
      infoTooltip={
        <CustomTooltip
          maxWidth="224px"
          title={translate(
            "metric_card_objectives_assessments_score_info_tooltip_title"
          )}
          description={translate(
            "metric_card_objectives_assessments_score_info_tooltip_description"
          )}
        />
      }
      trackingEvents={{
        tooltip: {
          domain: EventDomain.SpaceDashboardUsage,
          action: DashboardEvent.AssessmentsViewInfo
        }
      }}
    >
      {isEmpty ? (
        <EmptyMetric
          title={translate("empty_metric_no_data_available")}
          description={translate(
            !noGroupsButton
              ? "empty_metric_change_time_period"
              : "empty_metric_no_groups"
          )}
        >
          {noGroupsButton}
        </EmptyMetric>
      ) : null}
      {!isEmpty && !isLayoutModeMobile ? (
        <VerticalBarChart
          data={data}
          legend={AssessmentScoreLegend}
          tooltipTitle={translate("assessment_chart_tooltip_title")}
          threshold={chartThreshold}
          hasDecimalGrading={hasDecimalGrading}
        />
      ) : null}
    </MetricCard>
  );
};

export default observer(AssessmentScore);
