import React from "react";
import { Box } from "@arcadia/design-system";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  REACTABLE_ENTITY,
  ReactableEntity
} from "src/js/types/models/Reaction";
import {
  BoardElementComment,
  PostComment,
  isPostComment
} from "src/js/types/models/Comment";
import { useGroupFeatures } from "src/js/hooks";
import { CommentProps } from "../CommentList.types";
import CommentBox from "./CommentBox";
import useReactions from "../../reactions/hooks/useReactions";

const getReactableEntity = ({
  comment,
  isNested
}: {
  comment: PostComment | BoardElementComment;
  isNested?: boolean;
}): ReactableEntity => {
  if (isPostComment(comment)) {
    if (isNested) return REACTABLE_ENTITY.POST_NESTED_COMMENT;
    return REACTABLE_ENTITY.POST_COMMENT;
  }
  if (isNested) return REACTABLE_ENTITY.BOARDELEMENT_NESTED_COMMENT;
  return REACTABLE_ENTITY.BOARDELEMENT_COMMENT;
};

const Comment = ({
  comment,
  commentIndex,
  onDelete,
  onDeleteNestedComment,
  onUpdate,
  updateCommentReactionList,
  onUpdateNestedComment,
  onCreateNestedComment,
  hideNestedComment,
  customReplyAction
}: CommentProps) => {
  const mobile = getBreakpoint() === "smartphone";
  const { addReaction, removeReaction } = useReactions();
  const { freezeComments } = useGroupFeatures();

  return (
    <Box marginBottom={24}>
      <CommentBox
        commentIndex={commentIndex}
        entity={getReactableEntity({ comment })}
        comment={comment}
        hideActions={freezeComments}
        onUpdate={newText => {
          onUpdate(comment, newText);
        }}
        onRemoveReaction={reactionId => {
          removeReaction(reactionId).then(() => {
            updateCommentReactionList(comment.id);
          });
        }}
        onAddReaction={reactionType => {
          addReaction(
            getReactableEntity({ comment }),
            comment.id as unknown as string,
            reactionType,
            comment.reactions?.reactionLoggedUser
          ).then(reaction => updateCommentReactionList(comment.id, reaction));
        }}
        onDelete={() => {
          onDelete(comment);
        }}
        onSubmitNewNestedComment={text => {
          onCreateNestedComment(comment.id, text);
        }}
        customReplyAction={customReplyAction}
      />
      {hideNestedComment || !comment.nestedComments.length ? null : (
        <Box marginLeft={mobile ? "20px" : "40px"} paddingTop="8px">
          {comment.nestedComments.map((nestedComment, index) => {
            return (
              <CommentBox
                commentIndex={index}
                entity={getReactableEntity({ comment, isNested: true })}
                key={nestedComment.id}
                comment={nestedComment}
                hideActions={freezeComments}
                onRemoveReaction={reactionId => {
                  removeReaction(reactionId).then(() => {
                    updateCommentReactionList(
                      comment.id,
                      null,
                      nestedComment.id
                    );
                  });
                }}
                onAddReaction={reactionType => {
                  addReaction(
                    getReactableEntity({ comment, isNested: true }),
                    nestedComment.id as unknown as string,
                    reactionType,
                    nestedComment.reactions?.reactionLoggedUser
                  ).then(reaction => {
                    updateCommentReactionList(
                      comment.id,
                      reaction,
                      nestedComment.id
                    );
                  });
                }}
                onUpdate={newText => {
                  onUpdateNestedComment(nestedComment, newText);
                }}
                onDelete={() => {
                  onDeleteNestedComment(nestedComment);
                }}
                hideReply
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Comment;
