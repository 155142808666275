/* eslint-disable lines-between-class-members */
import * as Sentry from "@sentry/browser";
import { makeAutoObservable } from "mobx";
import { fetchLoggedUser } from "src/js/repository/userRepository";
import { User } from "src/js/types";
import {
  MarketingDataFormFields,
  UserType
} from "../types/models/MarketingData";

/**
 * @name UserStore
 *
 * @description
 * ...
 */

type UserOnboarding = {
  userType: UserType | null;
  isCompleted: boolean;
  step: string;
  marketingData?: MarketingDataFormFields;
};

class UserStore {
  $activeUser: User | null = null;

  $userWalletDetails = {
    address: "",
    chainId: "",
    walletId: ""
  };

  $userOnboarding: UserOnboarding = {
    userType: null,
    isCompleted: false,
    step: ""
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  initUser = () => {
    return this.fetchUser();
  };

  /**
   * @name fetchUser
   *
   * @description
   * Get user info
   *
   *
   * @deprecated
   * Stores should not be used anymore, this is just to ensure backward compatibility. Use Tanstack Query instead.
   */
  fetchUser = async (): Promise<User | undefined> => {
    try {
      const user = await fetchLoggedUser();
      if (user) {
        this.$activeUser = user;
        Sentry.setUser({ id: user.id, username: user.email });
      }
      return user;
    } catch {
      // we don't want to send to sentry unhandled errors about user fetch
    }

    return undefined;
  };

  enableUser = () => {
    const newUser: User = {
      ...this.$activeUser,
      status: "enabled"
    };
    this.$activeUser = newUser;
  };

  setActiveUser = user => {
    this.$activeUser = user;
  };

  changeActiveUserLanguage = language => {
    if (!this.$activeUser) return;
    this.$activeUser.language = language;
  };

  updateUserWallet = walletDetails => {
    this.$userWalletDetails = walletDetails;
  };

  setUserOnboarding = (userOnboarding: UserOnboarding) => {
    this.$userOnboarding = userOnboarding;
  };

  /**
   *
   * @deprecated
   * You should use Tanstack Query useMeQueryCached to get the active user
   */
  get activeUser() {
    return this.$activeUser;
  }

  /**
   *
   * @deprecated
   * You should use Tanstack Query useMeQueryCached to get the active users full name
   * like so: ${activeUser.name} ${activeUser.surname}
   */
  get activeUserFullname() {
    if (!this.$activeUser) return "";
    return `${this.activeUser.name} ${this.activeUser.surname}`;
  }

  get isTraceable() {
    if (this.$activeUser) {
      return this.$activeUser?.shouldUseTracking;
    }
    return true;
  }

  get userWalletDetails() {
    return this.$userWalletDetails;
  }

  get userOnboarding() {
    return this.$userOnboarding;
  }
}

export default UserStore;
