import { AnimatePresence } from "framer-motion";
import React, { FC, PropsWithChildren } from "react";
import { Text } from "@ds_universal/Text";
import { VariantType } from "@ds_themes/types";
import { whiteTheme } from "@ds_themes/white";

import * as S from "./BottomModalSheet.styles";
import { BottomModalSheetProps } from "./BottomModalSheet.types";

const BottomModalSheet: FC<PropsWithChildren<BottomModalSheetProps>> = ({
  title,
  subtitle,
  onClose = () => {},
  onConfirm = () => {},
  isOpen,
  closeLabel,
  confirmLabel,
  progressPercentage = 0,
  children,
  withAdaptiveHeight = false,
  withHeaderLabels = true,
  withDrag = true,
  variant,
  isConfirmDisabled = false
}) => {
  const onDragEnd = (
    _: MouseEvent,
    info: { velocity: { y: number }; point: { y: number } }
  ) => {
    const shouldClose = info.point.y > 120;
    if (shouldClose) {
      onClose?.();
    }
  };

  const variantModalSheet: VariantType =
    variant || (withAdaptiveHeight ? VariantType.White : VariantType.Grey);
  const withProgressBar = !!progressPercentage;

  return (
    <AnimatePresence>
      {isOpen && (
        <S.TrasparentLayer
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => onClose?.()}
        >
          <S.ModalSheet
            drag={withDrag ? "y" : false}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{
              type: "spring",
              damping: 50,
              stiffness: 400
            }}
            dragSnapToOrigin
            dragMomentum={false}
            dragConstraints={{ top: 0, bottom: 100 }}
            dragElastic={{ top: 0, bottom: 0.2 }}
            onDragEnd={onDragEnd}
            withAdaptiveHeight={withAdaptiveHeight}
            onClick={e => e.stopPropagation()}
            variant={variantModalSheet}
          >
            {withHeaderLabels ? (
              <S.Header withProgressBar={withProgressBar}>
                {closeLabel && (
                  <S.DismissButton type="button" onClick={() => onClose?.()}>
                    {closeLabel}
                  </S.DismissButton>
                )}
                <S.HeaderContent>
                  {title && (
                    <S.HeaderTitle type="subHeaderTitle">{title}</S.HeaderTitle>
                  )}
                  {subtitle && (
                    <S.HeaderSubtitle type="subHeaderTitle">
                      {subtitle}
                    </S.HeaderSubtitle>
                  )}
                </S.HeaderContent>

                {confirmLabel && (
                  <S.ConfirmButton
                    type="button"
                    onClick={onConfirm}
                    disabled={isConfirmDisabled}
                  >
                    <Text type="headerTitleLg">{confirmLabel}</Text>
                  </S.ConfirmButton>
                )}
              </S.Header>
            ) : (
              <S.HeaderIndicator>
                <S.Indicator />
              </S.HeaderIndicator>
            )}
            {withProgressBar && (
              <S.ProgressBarWrapper>
                <S.StyledProgressBar
                  theme={whiteTheme}
                  percentage={progressPercentage}
                />
              </S.ProgressBarWrapper>
            )}
            <S.ModalScrollableArea variant={variantModalSheet}>
              {children}
            </S.ModalScrollableArea>
          </S.ModalSheet>
        </S.TrasparentLayer>
      )}
    </AnimatePresence>
  );
};

export default BottomModalSheet;
