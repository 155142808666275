import { Icon, Toast } from "@arcadia/design-system";
import { observer } from "mobx-react";
import Papa from "papaparse";
import React, { useRef, useState } from "react";
import { useSpacePlanType, useStores } from "src/js/hooks";
import { formatBytes } from "src/js/modules/commonFunction";
import {
  __CSV_UPLOAD_LIMITS__,
  __CSV_UPLOAD_MIME__
} from "src/js/settings/settingFileUploader";
import { __EMAIL_REGEX__ } from "src/js/settings/settingsInput";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./CsvMailUploader.styles";

type CsvMailUploaderFeedbackIds =
  | "import_csv_toast_limit_warning"
  | "import_csv_toast_mail_error";

export type CsvMailUploaderResult = {
  emailArray: string[];
  fileName: string;
  fileSize: number;
  hasMailFormatError?: boolean;
};

const CsvMailUploader = ({
  groupIds,
  onChangeHandler = () => {}
}: {
  groupIds: number[];
  onChangeHandler: (result: CsvMailUploaderResult) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const currentTheme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const [dropActive, setDropActive] = useState<boolean>(false);
  const [error, setError] = useState<CsvMailUploaderFeedbackIds | null>(null);

  const { totalMemberInviteLimit, memberInviteLimitPerGroup } =
    useSpacePlanType({ groupCount: groupIds.length });

  const checkMimeType = (file: File) => {
    if (__CSV_UPLOAD_MIME__.every(type => file.type !== type)) {
      setError("import_csv_toast_limit_warning");
      return false;
    }
    return true;
  };

  const checkFileSize = (file: File) => {
    if (file.size > __CSV_UPLOAD_LIMITS__) {
      setError("import_csv_toast_limit_warning");
      return false;
    }
    return true;
  };

  const onSaveFile = (file: File | undefined) => {
    if (!file) return;
    if (checkMimeType(file) && checkFileSize(file)) {
      Papa.parse<{ Email: string }>(file, {
        skipEmptyLines: true,
        header: true,
        delimiter: ",",
        complete(results) {
          if (results.data.length > memberInviteLimitPerGroup) {
            setError("import_csv_toast_limit_warning");
            return;
          }
          const hasMailFormatError = results.data.some(
            item => !item.Email?.match(__EMAIL_REGEX__)
          );
          const emailArray = results.data
            .filter(item => item.Email?.match(__EMAIL_REGEX__) !== null)
            .map(item => item.Email);
          if (emailArray.length === 0) {
            setError("import_csv_toast_mail_error");
            return;
          }
          onChangeHandler({
            emailArray,
            fileName: file.name,
            fileSize: file.size,
            hasMailFormatError
          });
          setError(null);
        }
      });
    }
  };

  const fileDrop: React.DragEventHandler = e => {
    e.preventDefault();
    setDropActive(false);
    const { files } = e.dataTransfer;
    onSaveFile(files[0]);
  };

  const dragLeave: React.DragEventHandler = e => {
    setDropActive(false);
    e.preventDefault();
  };

  const dragEnter: React.DragEventHandler = e => {
    setDropActive(true);
    e.preventDefault();
  };

  const dragOver: React.DragEventHandler = e => {
    setDropActive(true);
    e.preventDefault();
  };

  return (
    <>
      <S.FileUploadWrapper
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDragOver={dragOver}
        onDrop={fileDrop}
        onClick={() => inputRef.current?.click()}
        dropActive={dropActive}
      >
        <S.TextWrapper>
          <Icon icon="arrowUp" width={32} height={32} />
          <S.UploadMessage type="formTitle">
            {translate("import_csv_upload_instruction")}
          </S.UploadMessage>
        </S.TextWrapper>
        <S.HiddenInput
          ref={inputRef}
          type="file"
          hidden
          onChange={e => {
            onSaveFile(e.target.files?.[0]);
          }}
        />
      </S.FileUploadWrapper>
      {error ? (
        <S.ErrorWrapper>
          <Toast
            theme={currentTheme}
            type="warning"
            hasIcon
            visible
            onClose={() => setError(null)}
          >
            {translate({
              text: error,
              stringVariables: { membersLimit: totalMemberInviteLimit }
            })}
          </Toast>
        </S.ErrorWrapper>
      ) : (
        <S.FullWidthContextualAlert
          theme={currentTheme}
          text={translate({
            text: "import_csv_toast_supported_files",
            stringVariables: {
              maxFileSize: formatBytes(__CSV_UPLOAD_LIMITS__),
              membersLimit: totalMemberInviteLimit
            }
          })}
        />
      )}
    </>
  );
};

export default observer(CsvMailUploader);
