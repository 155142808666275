import { Label } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import styled, { useTheme } from "styled-components";

export const StyledText = styled.small<{ outline?: boolean }>`
  all: unset;
  color: ${({ theme, outline }) =>
    outline ? theme.colors.grey[50] : theme.colors.grey[1000]};
`;

const UserStatusBadge = ({
  isActive = true,
  outline = true
}: {
  isActive: boolean;
  outline?: boolean;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <Label
      outline={outline}
      theme={whiteTheme}
      color={isActive ? "green" : "bluegrey"}
    >
      <StyledText outline={outline}>
        {translate(isActive ? "active" : "not_active")}
      </StyledText>
    </Label>
  );
};

export default UserStatusBadge;
