/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Box,
  Button,
  Dropdown,
  Input,
  PictureUploader,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpacesDropdown } from "src/js/components/SpacesDropdown";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { formatBytes } from "src/js/modules/commonFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import {
  cloneGroup,
  createGroup,
  fetchItalianSchoolOptions
} from "src/js/repository/groupRepository";
import {
  __GROUP_COVER_MIME__,
  __GROUP_COVER_SIZE__
} from "src/js/settings/settingFileUploader";
import { __FORM_CREATE_SCHOOL_GROUP__ } from "src/js/settings/settingsFormValidation";
import { __GROUP_DESCRIPTION_LIMIT__ } from "src/js/settings/settingsGroups";
import { __GROUP_COVER_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { __SCHOOL_SUBJECTS__ } from "src/js/settings/settingsSelect";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation/translated.types";
import {
  EventDomain,
  GroupDetailsModel,
  GroupEvent,
  TemplatingEvent
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { ToggleSelfJoinable } from "../ToggleSelfJoinable";
import { GroupCreationFormProps } from "./SchoolGroupCreationForm.types";

import * as S from "./SchoolGroupCreationForm.styles";

const SchoolGroupCreationForm = ({
  step,
  prevStep,
  nextStep,
  theme,
  closeForm,
  clonedGroup,
  onGoBack,
  isWeschoolTemplate = false,
  isCustomTemplate = false
}: GroupCreationFormProps) => {
  const { translate } = useTranslation();
  const { trackView } = useViewTracking();
  const {
    SpaceStore,
    SpaceGroupListStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { spacesList, activeSpaceId, activeSpace } = SpaceStore;
  const { trackVirtualView } = useViewTracking();

  const { register, handleSubmit, setValue, clearError, errors } = useForm<{
    [key in keyof typeof __FORM_CREATE_SCHOOL_GROUP__]: string;
  }>({
    defaultValues: {
      spaceId: activeSpace?.space.id,
      name: ""
    }
  });

  const spaceDropdownDisabled = !!activeSpaceId;
  const isCloning = !!clonedGroup;

  const [stepOneData, setStepOneData] = useState({
    name: clonedGroup?.name
  });
  const [subjectState, setSubject] = useState(null);
  const [descriptionState, setDescriptionState] = useState(
    clonedGroup?.description
  );
  const [loading, setLoading] = useState(false);
  const [selectedSpaceId, setSelectedSpaceId] = useState<string>(activeSpaceId);
  const [subjectsList, setSubjectsList] = useState(__SCHOOL_SUBJECTS__);
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [selectedSchoolType, setSelectedSchoolType] = useState(null);
  const [schoolSpecializations, setSchoolSpecializations] = useState(null);
  const [selectedSchoolSpecialization, setSelectedSchoolSpecialization] =
    useState(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [isSelfJoinable, setIsSelfJoinable] = useState<boolean>(false);

  useEffect(() => {
    if (step === 1) {
      setValue("name", stepOneData?.name ?? clonedGroup?.name);
      setSubject(null);
      setSelectedSchoolType(null);
      setSelectedSchoolSpecialization(null);
    }
  }, [step]);

  useEffect(() => {
    setSubjectsList(__SCHOOL_SUBJECTS__);
    setSubject(null);
    setValue("spaceId", selectedSpaceId);
    setValue("name", clonedGroup?.name);
    setValue("description", clonedGroup?.description);
  }, []);

  useEffect(() => {
    if (!isCloning) {
      _trackEvent(EventDomain.Group, GroupEvent.StandardGroupSelected);
      trackVirtualView(
        WeSchoolVirtualViewDescriptions.Groups.GroupSchoolCreate
      );
    }
  }, []);

  useEffect(() => {
    fetchItalianSchoolOptions()
      .then(({ schools }) => {
        setSchoolTypes(schools);
      })
      .catch(() => {
        setSchoolTypes([]);
      });
  }, []);

  useEffect(() => {
    const selectedSchoolSpecializations = schoolTypes.find(
      ({ label }) => label === selectedSchoolType
    )?.specializations;
    setSchoolSpecializations(selectedSchoolSpecializations);
  }, [selectedSchoolType]);

  const stepOneSubmission = handleSubmit(formData => {
    const data = {
      name: formData.name,
      spaceId: formData.spaceId,
      subject: formData.specifySubject || formData.subject,
      schoolType: formData.schoolSpecialization || formData.schoolType
    };
    setStepOneData(data);
    nextStep();
  });

  const showCreationGroupToastSuccess = (group: GroupDetailsModel) => {
    const messageKey = group?.selfJoinable
      ? "toast_new_group_self_joinable"
      : "toast_ask_new_group_redirect";

    showToastSuccess({
      str: translate(messageKey, { group: group?.name })
    });
  };

  const finalSubmission = handleSubmit(formData => {
    if (loading) return;
    const submitData = {
      ...stepOneData,
      cover: coverImage,
      ...formData,
      sourceGroup: clonedGroup?.id,
      selfJoinable: isSelfJoinable
    };
    setLoading(true);
    if (isCloning) {
      if (isCustomTemplate || isWeschoolTemplate) {
        _trackEvent(
          EventDomain.Templating,
          isCustomTemplate
            ? TemplatingEvent.CustomTemplateCreation
            : TemplatingEvent.WeSchoolTemplateCreation
        );
      } else {
        _trackEvent(EventDomain.Group, GroupEvent.CloneGroup);
      }
      const payload = {
        name: submitData.name,
        subject: submitData.subject,
        schoolType: submitData.schoolType,
        description: submitData.description,
        cover: submitData.cover,
        selfJoinable: submitData.selfJoinable,
        ...(isWeschoolTemplate && { targetSpaceId: activeSpaceId })
      };
      cloneGroup(payload, clonedGroup.id)
        .then(data => {
          SpaceGroupListStore.addGroupsAtStart([data]);
          closeForm();
          if (data?.group?.cloningCompleted) {
            showCreationGroupToastSuccess(data?.group);
          }
          navigateTo(createUrl("wall", { group_id: data.group.id }));
        })
        .catch(error => {
          closeForm();
          trackView();
          showToastError({ str: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      _trackEvent(EventDomain.Group, GroupEvent.GroupCreation);
      createGroup(submitData)
        .then(data => {
          SpaceGroupListStore.addGroupsAtStart([data]);
          closeForm();
          showCreationGroupToastSuccess(data?.group);
          navigateTo(createUrl("wall", { group_id: data.group.id }));
        })
        .catch(error => {
          closeForm();
          trackView();
          showToastError({ str: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const handleSelfJoinableToggle = () => {
    setIsSelfJoinable(!isSelfJoinable);
    _trackEvent(
      EventDomain.Group,
      isSelfJoinable
        ? GroupEvent.UnSetSelfJoinableGroupCreation
        : GroupEvent.SetSelfJoinableGroupCreation
    );
  };

  const pictureUploadedPlaceHolderCover =
    activeSpace?.space?.spaceCover?.large || __GROUP_COVER_PLACEHOLDER__;

  if (step === 1) {
    return (
      <S.StyledForm
        id="form-create-group-first-step"
        onSubmit={stepOneSubmission}
        isMobile={isLayoutModeMobile}
      >
        <S.Spacer />
        <PictureUploader
          key="cover"
          label={translate("group_creation_form_cover")}
          theme={theme}
          imageSrc={coverImage}
          onChangeHandler={setCoverImage}
          defaultCover={
            clonedGroup?.cover?.originalUrl || pictureUploadedPlaceHolderCover
          }
          feedbacks={[
            {
              id: "upload_image_limit",
              type: "warning",
              message: `${translate("upload_image_limit")} ${formatBytes(
                __GROUP_COVER_SIZE__
              )}`
            },
            {
              id: "file_extension_unsupported_error",
              type: "error",
              message: translate("file_extension_unsupported_error")
            },
            {
              id: "file_size_error",
              type: "error",
              message: translate("file_size_error")
            }
          ]}
          maxSize={__GROUP_COVER_SIZE__}
          mimeArray={__GROUP_COVER_MIME__}
        />
        <S.Spacer />
        <Input
          key="name"
          theme={theme}
          type="field"
          rounded
          label={translate("group_creation_form_name")}
          name="name"
          placeholder={translate("group_creation_form_name_placeholder")}
          ref={register(__FORM_CREATE_SCHOOL_GROUP__.name)}
          feedback={
            errors.name
              ? {
                  message: translate(errors.name.message as TranslationKeys),
                  type: "error"
                }
              : null
          }
        />
        <S.Spacer />
        <Input
          type="textarea"
          key="description"
          name="description"
          rows={4}
          maxHeight="120px"
          maxLength={__GROUP_DESCRIPTION_LIMIT__}
          theme={theme}
          label={translate("group_creation_form_description")}
          placeholder={translate("group_creation_form_description_placeholder")}
          onChange={e => {
            setDescriptionState(e.target.value);
          }}
          value={descriptionState}
          ref={register(__FORM_CREATE_SCHOOL_GROUP__.description)}
          showCounter
          feedback={
            errors.description
              ? {
                  message: translate(
                    errors.description.message as TranslationKeys
                  ),
                  type: "error"
                }
              : null
          }
        />
        <S.Spacer />
        <Box display="none">
          <SpacesDropdown
            label={translate("group_creation_form_space")}
            selectedSpaceId={selectedSpaceId}
            setSelectedSpaceId={id => {
              setSelectedSpaceId(id);
              setValue("spaceId", id);
              clearError("spaceId");
            }}
            spacesList={spacesList}
            placeholder={translate("group_creation_form_space_placeholder")}
            disabled={spaceDropdownDisabled}
            error={{
              message:
                errors.spaceId &&
                translate(errors.spaceId.message as TranslationKeys)
            }}
          />
          <select
            name="spaceId"
            id="spaceHidden"
            hidden
            ref={register(__FORM_CREATE_SCHOOL_GROUP__.spaceId)}
          >
            <option />
            {spacesList.map(({ space }) => (
              <option key={space.id} value={space.id} />
            ))}
          </select>
        </Box>
        `{" "}
        <ToggleSelfJoinable
          theme={theme}
          isSelfJoinable={isSelfJoinable}
          handleSelfJoinableToggle={handleSelfJoinableToggle}
        />
        <S.SubmitWrapper>
          {!isLayoutModeMobile && onGoBack ? (
            <Button
              variant="secondary"
              type="button"
              theme={theme}
              fullWidth
              onClick={onGoBack}
            >
              {translate("back")}
            </Button>
          ) : undefined}
          <Button
            disabled={loading}
            type="submit"
            theme={theme}
            variant="primary"
            fullWidth
          >
            {translate("group_creation_form_submit_cta_first_step")}
          </Button>
        </S.SubmitWrapper>
      </S.StyledForm>
    );
  }

  return (
    <S.StyledForm
      id="form-create-group-final-step"
      onSubmit={finalSubmission}
      isMobile={isLayoutModeMobile}
    >
      <Text type="formSubtitle">
        <S.StyledLabel htmlFor="subject" id="subjectLabel">
          {translate("group_creation_form_subject")}
        </S.StyledLabel>
      </Text>
      <Dropdown
        id="subject"
        theme={theme}
        optionsList={subjectsList.map(option => ({
          id: option.id,
          label: translate(option.name as TranslationKeys)
        }))}
        selectedOptionId={subjectState}
        setSelectedOptionId={id => {
          setSubject(id);
          setValue("subject", id);
          clearError("subject");
        }}
        placeholder={translate("group_creation_form_subject_placeholder")}
        error={{
          message:
            errors.subject &&
            translate(errors.subject.message as TranslationKeys)
        }}
      />
      <select
        name="subject"
        id="subjectHidden"
        hidden
        ref={register(__FORM_CREATE_SCHOOL_GROUP__.subject)}
      >
        <option />
        {subjectsList.map(option => (
          <option key={option.id} value={option.id} />
        ))}
      </select>
      {subjectState === "other" ? (
        <>
          <S.Spacer />
          <Input
            key="specifySubject"
            theme={theme}
            type="field"
            rounded
            label={translate("group_creation_form_specify_subject")}
            name="specifySubject"
            placeholder={translate(
              "group_creation_form_specify_subject_placeholder"
            )}
            ref={register(__FORM_CREATE_SCHOOL_GROUP__.specifySubject)}
            feedback={
              errors.specifySubject
                ? {
                    message: translate(
                      errors?.specifySubject?.message as TranslationKeys
                    ),
                    type: "error"
                  }
                : null
            }
          />
        </>
      ) : null}
      <Text type="formSubtitle">
        <S.StyledLabel htmlFor="dropdown-schoolType" id="schoolTypeLabel">
          {translate("group_creation_form_school_type")}
        </S.StyledLabel>
      </Text>
      <Dropdown
        id="schoolType"
        theme={theme}
        optionsList={schoolTypes.map(option => ({
          id: option.label,
          label: option.label
        }))}
        selectedOptionId={selectedSchoolType}
        setSelectedOptionId={id => {
          setSelectedSchoolType(id);
          setValue("schoolType", id);

          clearError("schoolType");
        }}
        placeholder={translate("group_creation_form_school_type_placeholder")}
        error={{
          message:
            errors.schoolType &&
            translate(errors.schoolType.message as TranslationKeys)
        }}
      />
      <select
        name="schoolType"
        id="schoolTypeHidden"
        hidden
        ref={register(__FORM_CREATE_SCHOOL_GROUP__.schoolType)}
      >
        <option />
        {schoolTypes.map(option => (
          <option key={option.id} value={option.label} />
        ))}
      </select>
      {schoolSpecializations && schoolSpecializations.length > 0 ? (
        <>
          <Text type="formSubtitle">
            <S.StyledLabel
              htmlFor="dropdown-schoolSpecialization"
              id="schoolSpecializationLabel"
            >
              {translate("group_creation_form_school_specialization")}
            </S.StyledLabel>
          </Text>
          <Dropdown
            id="schoolSpecialization"
            theme={theme}
            optionsList={schoolSpecializations.map(option => ({
              id: option,
              label: option
            }))}
            selectedOptionId={selectedSchoolSpecialization}
            setSelectedOptionId={id => {
              setSelectedSchoolSpecialization(id);
              setValue("schoolSpecialization", id);
              clearError("schoolSpecialization");
            }}
            placeholder={translate(
              "group_creation_form_school_specialization_placeholder"
            )}
            fromTop
            error={{
              message:
                errors.schoolSpecialization &&
                translate(
                  errors.schoolSpecialization.message as TranslationKeys
                )
            }}
          />
          <select
            name="schoolSpecialization"
            id="schoolSpecializationHidden"
            hidden
            ref={register(__FORM_CREATE_SCHOOL_GROUP__.schoolSpecialization)}
          >
            <option />
            {schoolSpecializations.map(option => (
              <option key={option} value={option} />
            ))}
          </select>
        </>
      ) : null}
      <S.SubmitWrapper>
        {!isLayoutModeMobile && prevStep ? (
          <Button
            variant="secondary"
            type="button"
            theme={theme}
            fullWidth
            onClick={prevStep}
          >
            {translate("back")}
          </Button>
        ) : undefined}
        <Button
          type="submit"
          theme={theme}
          disabled={loading}
          variant="primary"
          fullWidth
        >
          {translate("group_creation_form_submit_cta")}
        </Button>
      </S.SubmitWrapper>
    </S.StyledForm>
  );
};

export default observer(SchoolGroupCreationForm);
