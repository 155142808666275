import { Box } from "@ds_universal/Box";
import { Button } from "@ds_universal/data_entry";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
  useEmailInvite,
  useOnboarding,
  useStores,
  useToastMessage
} from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import {
  createMarketingData,
  updateMarketingData
} from "src/js/repository/marketingDataRepository";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_MEMBERS_INVITE_VIDEO__
} from "src/js/settings/settingsImage";
import { TRIAL_MEMBERS_LIMIT } from "src/js/settings/settingsInvite";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  GroupUserRoleEnum,
  OnboardingEvent,
  ToastVariant
} from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { EmailInvite } from "../../../components/EmailInvite";
import { useSendInviteMembers } from "../../group/settings/GroupInviteMemberModal/hooks";
import { WeSchoolRoutes } from "../../Routes.const";
import Example from "../components/Example";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";

const FormContainer = styled.div`
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 24px 20px 24px 20px;
  }
`;

const StyledEmailInvite = styled(EmailInvite)`
  @media (max-width: 767px) {
    height: 180px;
    overflow: auto;
  }
`;

const MembersInvite = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { routeGuard } = useOnboarding();
  const {
    UserStore: { setUserOnboarding },
    UIStore: { isLayoutModeMobile },
    UserInviteStore: { usersToInvite },
    SpaceStore: { activeSpace },
    GroupStore: { groupId }
  } = useStores();

  useEffect(() => {
    routeGuard();
  }, []);

  const { handleInviteMembers } = useSendInviteMembers();
  const { toastMessageNodes, addToastMessage, removeToastMessage } =
    useToastMessage();

  const { onChange, onKeyDown, onPaste, setIsFocused, inputValue } =
    useEmailInvite({
      inviteLimit: TRIAL_MEMBERS_LIMIT,
      handleLimitReached: () => {
        addToastMessage({
          id: "invite-limit-pro-reached",
          variant: ToastVariant.Confirmation,
          children: translate("invite_modal_email_invite_toast_max_reached", {
            membersLimit: TRIAL_MEMBERS_LIMIT
          }),
          props: {
            onAcceptFunction: () => {
              removeToastMessage("invite-limit-pro-reached");
            },
            acceptText: translate("invite_modal_confirm_toast_accept")
          }
        });
      }
    });

  const setOnboardingAndRedirect = () => {
    _trackEvent(EventDomain.Onboarding, OnboardingEvent.CompleteFlow);
    setUserOnboarding({
      userType: USER_TYPE.BUSINESS,
      isCompleted: true,
      step: WeSchoolRoutes.Onboarding.membersInvite
    });
    navigateTo(
      createUrl("wall", {
        space_slug: activeSpace.space.slug,
        group_id: groupId
      })
    );
  };

  const goToActiveGroup = () => {
    updateMarketingData({
      userType: USER_TYPE.BUSINESS,
      onboardingCompleted: true
    })
      .then(() => {
        setOnboardingAndRedirect();
      })
      .catch(error => {
        if (error.response?.status === 404) {
          createMarketingData({
            userType: USER_TYPE.BUSINESS,
            onboardingCompleted: true
          })
            .then(() => {
              setOnboardingAndRedirect();
            })
            .catch(debugError => {
              // ideally this should never happen
              console.log({ debugError });
            });
        }
      });
  };

  const onConfirmInviteMembers = async () => {
    try {
      await handleInviteMembers({
        groupIds: [groupId],
        inviteRole: GroupUserRoleEnum.Teacher,
        userEmails: usersToInvite,
        skipCatch: true
      });
    } catch (error) {
      addToastMessage({
        id: "invite-error",
        duration: 5000,
        variant: ToastVariant.Error,
        children: translate("invite_modal_email_invite_toast_error")
      });
    }
  };

  const skipOnboardingStep = () => {
    _trackEvent(EventDomain.Onboarding, OnboardingEvent.SkipInvite);
    goToActiveGroup();
  };

  const membersInvite = (
    <FormContainer>
      <TitleBlock
        title={translate("onboarding_business_invite_members_page_title")}
        subtitle={translate("onboarding_business_invite_members_page_subtitle")}
      />
      <Box marginBottom={12}>{toastMessageNodes()}</Box>
      <StyledEmailInvite
        selectedMemberType={GroupUserRoleEnum.Teacher}
        inputValue={inputValue}
        onChange={onChange}
        setIsFocused={setIsFocused}
        handleInviteMembers={onConfirmInviteMembers}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
      />
      <Box
        marginTop={12}
        display="flex"
        gap={8}
        flexDirection={isLayoutModeMobile ? "column-reverse" : "row"}
      >
        <Button
          theme={whiteTheme}
          variant="secondary"
          onClick={skipOnboardingStep}
          fullWidth
        >
          {translate("onboarding_skip_invite_members_step")}
        </Button>
        <Button
          theme={whiteTheme}
          variant="primary"
          onClick={onConfirmInviteMembers}
          fullWidth
        >
          {translate("invite")}
        </Button>
      </Box>
    </FormContainer>
  );

  return (
    <OnboardingLayout
      mainContent={membersInvite}
      sideContent={
        <Example
          background={__EXAMPLE_BACKGROUND_PATTERN__}
          video={__EXAMPLE_MEMBERS_INVITE_VIDEO__}
          imagePosition="right: 0"
          description={translate(
            "onboarding_example_members_invite_description"
          )}
        />
      }
      stepper={<StepperBlock currentStep={4} totalSteps={4} />}
      mobileCentered
    />
  );
};

export default observer(MembersInvite);
