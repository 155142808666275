const __GROUP_KEY_ALL__ = "group";

export const groupQueryKey = {
  all: [__GROUP_KEY_ALL__],
  v1: [__GROUP_KEY_ALL__, "v1"],
  v3: [__GROUP_KEY_ALL__, "v3"],
  groupByIdV3: ({ groupId }: { groupId: number }) => [
    __GROUP_KEY_ALL__,
    "v3",
    { groupId }
  ],
  groupStats: ({ groupId }: { groupId: number }) => [
    __GROUP_KEY_ALL__,
    "groupStats",
    { groupId }
  ]
} as const;
