import {
  fetchScormRecords,
  fetchUserScormRecords
} from "src/js/repository/scormRepository";
import { ObjectValues, UserDetailsModel } from "src/js/types";
import { useInfiniteQuery, UseInfiniteQueryOptions } from "../hooks";
import { scormQueryKey } from "./scorm.query";

export type ScormData = {
  user: Omit<UserDetailsModel, "id">;
  learnersCount: number;
  lastUpdatedAt: string;
  timeSpent: number;
  completion: number;
};

export type ScormDataDetail = {
  boardName: string;
  scormsCount: number;
  boardElementName: string;
  boardId: number;
  boardElementId: number;
  updatedAt?: string;
  timeOfExecution: number;
  completed: boolean;
  score: number;
};

export type ScormDataOrderBy = keyof ScormData;
export type ScormDataDetailOrderBy = keyof Omit<
  ScormDataDetail,
  "boardId" | "boardElementId"
>;

export const Sorting = {
  Ascending: "asc",
  Descending: "desc"
} as const;

export type SortOrder = ObjectValues<typeof Sorting>;

export const useScormGroup = ({
  groupId,
  options,
  orderBy,
  sortOrder,
  textSearch
}: {
  groupId: number;
  options?: UseInfiniteQueryOptions<ScormData, Error, ScormData>;
  orderBy?: ScormDataOrderBy;
  sortOrder?: SortOrder;
  textSearch?: string;
}) => {
  return useInfiniteQuery(
    scormQueryKey.groupId({ groupId, orderBy, sortOrder, textSearch }),
    ({ limit, offset }) =>
      fetchScormRecords({
        groupId,
        limit,
        offset,
        orderBy,
        sortOrder,
        textSearch
      }),
    options
  );
};

export const useScormUser = ({
  userUuid,
  groupId,
  options,
  orderBy,
  sortOrder,
  textSearch
}: {
  userUuid: string;
  groupId: number;
  options?: UseInfiniteQueryOptions<ScormDataDetail, Error, ScormDataDetail>;
  orderBy?: ScormDataDetailOrderBy;
  sortOrder?: SortOrder;
  textSearch?: string;
}) => {
  return useInfiniteQuery<ScormDataDetail, Error, ScormDataDetail>(
    scormQueryKey.userUuid({
      userUuid,
      groupId,
      orderBy,
      sortOrder,
      textSearch
    }),
    ({ limit, offset }) =>
      fetchUserScormRecords({
        userUuid,
        groupId,
        limit,
        offset,
        orderBy,
        sortOrder,
        textSearch
      }),
    options
  );
};
