import { LinkButton, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const CardWrapper = styled.div<{ hasBorder?: boolean }>`
  border-radius: 12px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.colors.grey[800]}` : "none"};

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
      margin-bottom: 8px;
      align-items: stretch;
    `}

  @media screen and (max-width: 767px) {
    align-items: stretch;
  }
`;

export const GroupCoverWrapper = styled.div`
  position: relative;
`;

export const NFTChainIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const NFTLabelContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: 12px;
  border-radius: 48px;
  padding: 4px;
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  border: 1px solid white;
  > div {
    height: 30px;
    width: 30px;
    div {
      height: 100%;
      width: 100%;
    }
  }
  div {
    border: unset;
    margin: 0px;
    min-height: unset;
  }

  @media screen and (max-width: 767px) {
    width: 24px;
    height: 24px;
    > div {
      height: 22px;
      width: 22px;
    }
  }
`;

export const BodySectionWrapper = styled.div`
  margin-left: 16px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 0;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextSectionWrapper = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      align-self: stretch;
    `}
`;

export const StyledLinkButton = styled(LinkButton)<{ isReject?: boolean }>`
  color: ${({ theme, isReject }) =>
    isReject ? theme.primaryColor[50] : theme.primaryColor[500]};
  padding: 0;
  &:hover {
    color: ${({ theme, isReject }) =>
      isReject ? theme.primaryColor[50] : theme.primaryColor[500]};
  }

  &:not(:hover) > span {
    text-decoration: none;
  }
`;

export const GreyText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;
