import {
  ActionButton,
  AvatarWithInitials,
  Box,
  Button,
  colors,
  ContextualAlert,
  ContextualError,
  Divider,
  Heading,
  Icon,
  Input,
  PictureUploader,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ColorPickerInput,
  InputVariant,
  LabelPositions
} from "src/js/components/forms/ColorPickerInput";
import { PreventNavigation } from "src/js/components/global/PreventNavigation";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { PlanLabel } from "src/js/components/PlanLabel";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useStores } from "src/js/hooks";
import { useDashboardLayout } from "src/js/layout/DashboardLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { formatBytes } from "src/js/modules/commonFunction";
import {
  isAllowedFileSize,
  isAllowedMimeType
} from "src/js/modules/fileFunction";
import createUrl from "src/js/modules/routing";
import {
  __GROUP_COVER_MIME__,
  __GROUP_COVER_SIZE__
} from "src/js/settings/settingFileUploader";
import { __FORM_SPACES_GENERAL_SETTINGS__ } from "src/js/settings/settingsFormValidation";
import { __HERO_GROUPS_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { S3Video } from "src/js/settings/settingsVideo";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  DashboardSettings,
  SpaceModel,
  SpacePlanSettingsModel
} from "src/js/types";
import { CustomizationSettings } from "src/js/types/models/Customization";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import * as S from "./SpacesSettingsGeneral.styles";

export type SpacesSettingsGeneralProps = {
  tabButtons: JSX.Element;
  defaultSettings: {
    planSettings: SpacePlanSettingsModel;
    customizationSettings: CustomizationSettings;
  };
  space: SpaceModel;
  submitInformation: (
    dashboardSettings: Omit<DashboardSettings, "spaceId">
  ) => void;
};

// TODO clean and split this component

const SpacesSettingsGeneral = ({
  tabButtons,
  defaultSettings,
  space,
  submitInformation = () => {}
}: SpacesSettingsGeneralProps) => {
  const { whiteTheme } = useTheme();
  const {
    SpaceStore: { updateSpaceList, activeSpace },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { setHeaderComponent, resetHeaderComponent } = useDashboardLayout();
  const { customizationSettings, planSettings } = defaultSettings || {};

  const { spaceCover, badge, ctaFillColor, layoutColor } =
    customizationSettings || {};
  const { dashboardCustomGroupsCoverEnabled, dashboardCustomPaletteEnabled } =
    planSettings || {};

  const [coverImage, setCoverImage] = useState<File | string>(
    spaceCover?.large
  );
  const [badgeImage, setBadgeImage] = useState<File | string>(badge?.large);
  const [layoutColorPreview, setLayoutColorPreview] = useState(layoutColor);
  const [primaryButtonColor, setPrimaryButtonColor] = useState(ctaFillColor);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // TODO setting the hasUnsavedChanges like this (in each onChange) is not the correct way to do it
  // future improvement, just check if the values are different from the default, check the dirtyness
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [descriptionState, setDescriptionState] = useState(
    (space?.description as string) || ""
  );
  const inputRef = useRef<HTMLInputElement>();

  const { register, handleSubmit, setValue, errors } = useForm<{
    [key in keyof typeof __FORM_SPACES_GENERAL_SETTINGS__]: string;
  }>({
    defaultValues: {
      name: space?.name,
      description: space?.description as string
    }
  });

  useEffect(() => {
    setCoverImage(defaultSettings?.customizationSettings?.spaceCover?.large);
    setBadgeImage(defaultSettings?.customizationSettings?.badge?.large);
    setPrimaryButtonColor(defaultSettings?.customizationSettings?.ctaFillColor);
    setLayoutColorPreview(defaultSettings?.customizationSettings?.layoutColor);
    // TODO: this could be redundant. Remove it if unnecessary
    updateSpaceList(space);
  }, [defaultSettings]);

  useEffect(() => {
    setValue("description", space?.description as string);
  }, [space, editMode]);

  useEffect(() => {
    _trackEvent("SpaceSettingsUsage", "CustomizeUserAccess");
  }, []);

  let badgeImagePreview;
  if (badgeImage) {
    badgeImagePreview =
      typeof badgeImage === "string"
        ? badgeImage
        : URL.createObjectURL(badgeImage);
  }

  const onChangeBadgeHandler = useCallback(e => {
    const fileList = e.target.files;
    if (!fileList) return;

    if (!isAllowedMimeType(fileList[0], __GROUP_COVER_MIME__)) {
      setErrorMessage("file_extension_unsupported_error");
      return;
    }
    if (!isAllowedFileSize(fileList[0], __GROUP_COVER_SIZE__)) {
      setErrorMessage("file_size_error");
      return;
    }
    setBadgeImage(fileList[0]);
  }, []);

  const prepareSubmitData = (formData: {
    name: string;
    description: string;
    layoutColor: string;
  }) => {
    const fileCover = typeof coverImage !== "string" ? coverImage : null;
    const fileBadge = typeof badgeImage !== "string" ? badgeImage : null;

    const data = {
      badge: fileBadge,
      layoutColor: formData.layoutColor,
      ctaFillColor: primaryButtonColor,
      spaceCover: fileCover,
      removeBadge: !badgeImage,
      removeSpaceCover: !coverImage,
      name: formData.name,
      description: formData.description
    } as DashboardSettings;
    submitInformation(data);
    setEditMode(false);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    setHeaderComponent(
      <HeaderBack
        onGoBack={() =>
          navigateTo(
            createUrl(
              activeSpace?.enableAnalytics
                ? "dashboard_analytics"
                : "dashboard_staff",
              {
                space_id: space?.id
              }
            )
          )
        }
        stringTitle={space?.name}
      />
    );
    return () => {
      resetHeaderComponent();
    };
  }, [
    editMode,
    space?.id,
    space?.name,
    isLayoutModeMobile,
    coverImage,
    primaryButtonColor,
    badgeImage
  ]);

  const EditSaveButton = (
    <Box>
      <Button
        theme={whiteTheme}
        variant="secondary"
        type="button"
        onClick={
          editMode
            ? handleSubmit(formData => {
                prepareSubmitData(formData);
              })
            : () => setEditMode(true)
        }
      >
        {translate({
          text: editMode
            ? "space_settings_general_action_save"
            : "space_settings_general_action_edit"
        })}
      </Button>
    </Box>
  );

  return (
    <>
      <PreventNavigation
        shouldBlock={hasUnsavedChanges}
        promptText="space_settings_unsaved_changes_warning_text"
        acceptText="space_settings_unsaved_changes_accept_cta"
        declineText="space_settings_unsaved_changes_decline_cta"
        onNavigateCb={path => {
          setHasUnsavedChanges(false);
          navigateTo(path);
        }}
      />

      <S.FormStyled>
        <MainContainer paddingTop="10px" paddingTopMobile="72px">
          <S.AvatarWrapper>
            <S.AvatarWithInitialsWrapper>
              <AvatarWithInitials
                theme={whiteTheme}
                alt={space?.name}
                url={badgeImagePreview}
                name={space?.name || ""}
                avatarColor={space?.defaultBadgeColor}
                size="big"
              />
            </S.AvatarWithInitialsWrapper>
          </S.AvatarWrapper>
          {tabButtons}
          <S.IntroWrapper>
            <Box
              width={isLayoutModeMobile ? "100%" : "376px"}
              display="flex"
              flexDirection="column"
              gap="8px"
            >
              <Heading level="2">
                {translate("space_settings_general_customize_intro_title")}
              </Heading>
              <Text type="formSubtitle">
                {translate(
                  "space_settings_general_customize_intro_description"
                )}
              </Text>
            </Box>
            {EditSaveButton}
          </S.IntroWrapper>
          <S.ContentBox>
            <Heading level="5">
              {translate("space_settings_general_workspace_general_name")}
            </Heading>

            <Box marginTop="12px">
              <Input
                key="name"
                name="name"
                theme={whiteTheme}
                label={translate({
                  text: "space_settings_general_name_subtitle"
                })}
                ref={register(__FORM_SPACES_GENERAL_SETTINGS__.name)}
                feedback={
                  errors.name
                    ? {
                        message: translate(
                          errors.name.message as TranslationKeys
                        ),
                        type: "error"
                      }
                    : null
                }
                rounded
                disabled={!editMode}
                onChange={() => setHasUnsavedChanges(true)}
              />
            </Box>
            <Box marginTop={24} marginBottom={24}>
              <Divider theme={whiteTheme} color="grey" width="100%" />
            </Box>
            <Heading level="5">
              {translate(
                "space_settings_general_workspace_general_description"
              )}
            </Heading>
            <Box marginTop="12px" marginBottom="24px">
              <Input
                type="textarea"
                key="description"
                name="description"
                rows={4}
                maxHeight="92px"
                maxLength={300}
                theme={whiteTheme}
                label={translate({
                  text: "space_settings_general_description_subtitle"
                })}
                onChange={e => {
                  setHasUnsavedChanges(true);
                  setDescriptionState(e.target.value);
                }}
                value={descriptionState}
                ref={register(__FORM_SPACES_GENERAL_SETTINGS__.description)}
                disabled={!editMode}
                showCounter
              />
            </Box>
          </S.ContentBox>
          <S.ContentBox>
            <Heading level="5">
              {translate("space_settings_general_badge")}
            </Heading>
            <Box marginTop="12px">
              <Text type="formSubtitle">
                {translate("space_settings_general_badge_subtitle")}
              </Text>
              <Box position="relative" width="max-content">
                <S.EditableAvatarWrapper>
                  <AvatarWithInitials
                    theme={whiteTheme}
                    alt={space?.name}
                    url={badgeImagePreview}
                    name={space?.name || ""}
                    avatarColor={space?.defaultBadgeColor}
                    size="auto"
                  />
                </S.EditableAvatarWrapper>
                {editMode ? (
                  <>
                    <S.EditLayer onClick={() => inputRef?.current.click()}>
                      <Icon icon="pencil" width={20} height={20} />
                      <input
                        ref={inputRef}
                        type="file"
                        name="file"
                        onChange={e => {
                          setHasUnsavedChanges(true);
                          onChangeBadgeHandler(e);
                        }}
                      />
                    </S.EditLayer>
                    <Box position="absolute" top="0px" right="-16px">
                      <ActionButton
                        theme={whiteTheme}
                        variant="secondary"
                        icon="trash"
                        onClick={() => {
                          setBadgeImage(null);
                          setHasUnsavedChanges(true);
                        }}
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
              {editMode ? (
                <>
                  <ContextualAlert
                    theme={whiteTheme}
                    text={`${translate({
                      text: "space_settings_general_badge_edit_alert"
                    })} ${formatBytes(__GROUP_COVER_SIZE__)}`}
                  />
                  {errorMessage ? (
                    <Box marginTop="12px">
                      <ContextualError
                        theme={whiteTheme}
                        text={translate({ text: errorMessage })}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </Box>
            {dashboardCustomGroupsCoverEnabled && (
              <>
                <Box marginTop={24} marginBottom={24}>
                  <Divider theme={whiteTheme} color="grey" width="100%" />
                </Box>
                <Heading level="5">
                  {translate("space_settings_general_cover_image")}
                </Heading>
                <Box marginTop="12px">
                  <Text type="formSubtitle">
                    {translate("space_settings_general_cover_image_subtitle")}
                  </Text>
                  <S.UploaderContainer>
                    <PictureUploader
                      mode={editMode ? "edit" : "view"}
                      label={translate({
                        text: "space_settings_general_cover_image_subtitle"
                      })}
                      hideLabel
                      theme={whiteTheme}
                      imageSrc={coverImage}
                      onChangeHandler={image => setCoverImage(image)}
                      removeFile={() => setCoverImage(null)}
                      defaultCover={__HERO_GROUPS_PLACEHOLDER__}
                      feedbacks={[
                        {
                          id: "upload_image_limit",
                          type: "warning",
                          message: `${translate({
                            text: "upload_image_limit"
                          })} ${formatBytes(__GROUP_COVER_SIZE__)}`
                        },
                        {
                          id: "file_extension_unsupported_error",
                          type: "error",
                          message: translate({
                            text: "file_extension_unsupported_error"
                          })
                        },
                        {
                          id: "file_size_error",
                          type: "error",
                          message: translate({ text: "file_size_error" })
                        }
                      ]}
                      maxSize={__GROUP_COVER_SIZE__}
                      mimeArray={__GROUP_COVER_MIME__}
                    />
                  </S.UploaderContainer>
                </Box>
              </>
            )}
            {dashboardCustomPaletteEnabled && (
              <>
                <Box marginTop={24} marginBottom={24}>
                  <Divider theme={whiteTheme} color="grey" width="100%" />
                </Box>
                <Heading level="5">
                  {translate("space_settings_general_colors")}
                </Heading>
                <Box marginTop="12px">
                  <Text type="formSubtitle">
                    {translate("space_settings_general_colors_subtitle")}
                  </Text>
                </Box>
                <Box marginTop="20px">
                  <S.ColorPickerContainer>
                    <S.ColorPickerWrapper>
                      <ColorPickerInput
                        id="layoutColor"
                        name="layoutColor"
                        label={
                          <S.Label htmlFor="layoutColor">
                            <Text type="formSubtitle">
                              {translate("spaces_background_color")}
                            </Text>
                          </S.Label>
                        }
                        labelDirection={LabelPositions.FromTop}
                        ref={register(
                          __FORM_SPACES_GENERAL_SETTINGS__.layoutColor
                        )}
                        onChange={hexColor => {
                          setLayoutColorPreview(hexColor);
                          setHasUnsavedChanges(true);
                        }}
                        hasError={Boolean(errors.layoutColor)}
                        errorMessage={errors.layoutColor?.message}
                        variant={InputVariant.Dark}
                        defaultValue={layoutColorPreview || colors.violet[500]}
                        resetColor=""
                        disabled={!editMode}
                      />
                    </S.ColorPickerWrapper>
                    <S.FakeButtonContainer
                      fillColor={layoutColorPreview || colors.violet[500]}
                    >
                      <Button
                        type="button"
                        onClick={() => {}}
                        variant="primary"
                        theme={whiteTheme}
                      >
                        {translate(
                          "spaces_unlogged_primary_button_preview_text"
                        )}
                      </Button>
                    </S.FakeButtonContainer>
                  </S.ColorPickerContainer>
                </Box>
                <Box marginTop="16px">
                  <S.ColorPickerContainer>
                    <S.ColorPickerWrapper>
                      <ColorPickerInput
                        id="primary_button_color"
                        name="primary_button_color"
                        label={
                          <S.Label htmlFor="primary_button_color">
                            <Text type="formSubtitle">
                              {translate(
                                "spaces_unlogged_primary_button_color"
                              )}
                            </Text>
                          </S.Label>
                        }
                        labelDirection={LabelPositions.FromTop}
                        onChange={hexColor => {
                          setPrimaryButtonColor(hexColor);
                          setHasUnsavedChanges(true);
                        }}
                        variant={InputVariant.Dark}
                        defaultValue={primaryButtonColor || colors.violet[500]}
                        resetColor=""
                        disabled={!editMode}
                      />
                    </S.ColorPickerWrapper>
                    <S.FakeButtonContainer
                      fillColor={primaryButtonColor || colors.violet[500]}
                    >
                      <Button
                        type="button"
                        onClick={() => {}}
                        variant="primary"
                        theme={whiteTheme}
                      >
                        {translate(
                          "spaces_unlogged_primary_button_preview_text"
                        )}
                      </Button>
                    </S.FakeButtonContainer>
                  </S.ColorPickerContainer>
                </Box>
              </>
            )}
            {[
              dashboardCustomGroupsCoverEnabled,
              dashboardCustomPaletteEnabled
            ].some(element => {
              return element === false;
            }) && (
              <Box marginTop="32px">
                <PlanLabel
                  text={
                    <Heading level="4">
                      {" "}
                      {translate({
                        text: "space_settings_general_workspace_general_customization"
                      })}
                    </Heading>
                  }
                  label="Pro"
                />
                <Box marginTop="16px">
                  <VideoPlayer video={S3Video.DashboardCustomizations} />
                </Box>
                <Box marginTop="16px">
                  <Text type="formSubtitle">
                    {" "}
                    {translate({
                      text: "space_settings_general_workspace_general_customization_description"
                    })}
                  </Text>
                </Box>
                <Box marginTop="16px">
                  <Button
                    onClick={() => {
                      navigateTo(
                        createUrl("dashboard_settings", {
                          space_id: space?.id,
                          option: "plan"
                        })
                      );
                    }}
                    theme={whiteTheme}
                    variant="primary"
                  >
                    {translate({
                      text: "space_settings_general_workspace_general_customization_switch"
                    })}
                  </Button>
                </Box>
              </Box>
            )}
          </S.ContentBox>
          <S.FooterButtonWrapper>{EditSaveButton}</S.FooterButtonWrapper>
        </MainContainer>
      </S.FormStyled>
    </>
  );
};

export default observer(SpacesSettingsGeneral);
