import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ModalBodyForm = styled.form`
  padding: 24px 20px 20px;

  display: flex;

  flex-direction: column;
  gap: 16px;
`;

export const TextWrapper = styled(Text)`
  text-align: center;
  max-width: 410px;
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  label {
    color: ${({ theme }) => theme.colors.grey[300]};
    margin-bottom: 0px;
  }
  input {
    height: 44px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-bottom: -2px;
    > span {
      font-weight: 400;
      line-height: 18px;
    }
  }
`;
