/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import { Thread, ThreadUser } from "src/js/types";
import { arrayPartition } from "../modules/commonFunction";
import { fetchThreads } from "../repository/threadsRepository";
import {
  ThreadDeleteMessage,
  ThreadNewMessageHydrated,
  ThreadReadMessage
} from "../types/models/WebSocket";
import SpaceStore from "./SpaceStore";
import UserStore from "./UserStore";

type TabNotification = {
  count: number;
  lastUser: ThreadUser;
};

/**
 * @name ChatStore
 *
 * @description
 * Sets user list of chat threads (if she/he has any)
 */
class ChatStore {
  $threadsList: Thread[] = [];
  $numUnreadMessages = 0;
  $tabNotification: TabNotification = {
    count: 0,
    lastUser: undefined
  };
  spaceStore: SpaceStore;
  userStore: UserStore;

  private $visibilityChangeListener: (event: Event) => void;

  constructor({ spaceStore, userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.userStore = userStore;
    this.dispose();
    document.addEventListener("visibilitychange", () => {
      if (document?.visibilityState === "visible") {
        this.resetTabNotification();
      }
    });
  }

  fetchThreadsList = async (activeSpaceId: string, noReset?: boolean) => {
    if (!noReset) this.reset();
    if (!activeSpaceId) {
      return null;
    }

    try {
      const { results } = await fetchThreads({
        spaceId: activeSpaceId
      });
      const reorderedList = this.reorderThreadList(results);
      this.setThreadsList(reorderedList);
      const numUnreadMessages = results.reduce(
        (acc, thread) => acc + thread.unreadMessages,
        0
      );
      this.setNumUnreadMessages(numUnreadMessages);
      return results;
    } catch {
      this.reset();
      return null;
    }
  };

  fetchNewThread = async (message: ThreadNewMessageHydrated) => {
    const threadCreatedByOtherUser =
      message?.data?.userId !== this.userStore?.activeUser.uuid;
    try {
      const { results } = await fetchThreads({
        spaceId: this.spaceStore?.activeSpaceId
      });
      const newThread = results.find(t => message.data.threadId === t.id);
      // The unreadMessages value is not updated when a new thread is created
      // https://weschool-srl.atlassian.net/browse/SOSB-913
      if (newThread?.unreadMessages === 0 && threadCreatedByOtherUser) {
        newThread.unreadMessages = 1;
      }
      const reorderedList = this.reorderThreadList(results);
      this.setThreadsList(reorderedList);
      const numUnreadMessages = results.reduce(
        (acc, thread) => acc + thread.unreadMessages,
        0
      );
      this.setNumUnreadMessages(numUnreadMessages);
      return results;
    } catch {
      return null;
    }
  };

  setThreadsList = (threads: Thread[]) => {
    this.$threadsList = threads;
  };

  addNewThreadToThreadList = (newThread: Thread) => {
    this.setThreadsList([newThread, ...this.$threadsList]);
  };

  setNumUnreadMessages = (numUnreadMessages: number) => {
    this.$numUnreadMessages = numUnreadMessages;
  };

  resetTabNotification = () => {
    this.$tabNotification = {
      count: 0,
      lastUser: undefined
    };
  };

  handleTabNotification = (
    thread: Thread,
    message: ThreadNewMessageHydrated
  ) => {
    if (
      !document?.hidden ||
      thread?.muted ||
      message.data.userId === this.userStore?.activeUser.uuid
    ) {
      return;
    }
    const lastUser = thread.users.find(
      user => user?.uuid === message?.data?.userId
    );
    this.$tabNotification = {
      count: this.$tabNotification.count + 1,
      lastUser
    };
  };

  setThreadMuted = ({
    threadId,
    muted
  }: {
    threadId: string;
    muted: boolean;
  }) => {
    const threadIndex = this.$threadsList.findIndex(
      thread => thread.id === threadId
    );
    if (threadIndex < 0) return;
    this.$threadsList[threadIndex].muted = muted;
  };

  reorderThreadList = (list: Thread[]) => {
    const [unreadThreads, readThreads] = arrayPartition(
      list,
      thread => thread.unreadMessages > 0
    );
    return [...unreadThreads, ...readThreads];
  };

  onNewMessage = (message: ThreadNewMessageHydrated) => {
    if (!message?.data) return;
    const filteredThread = this.$threadsList.find(
      thread => message.data.threadId === thread.id
    );
    if (filteredThread) {
      this.handleTabNotification(filteredThread, message);
      if (filteredThread.muted) return;
      this.spaceStore.increaseThreadMessagesCounterOnSpace({
        spaceId: message.data.spaceId
      });
      // clone the thread object that received the message
      const updatedThread = { ...filteredThread };
      // extract the thread receiving the current message from the list
      const listWithoutUpdatedThread = this.$threadsList.filter(
        thread => thread.id !== updatedThread.id
      );
      // change last message and unread message count in thread receiving the current message
      updatedThread.lastMessage = {
        ...message.data,
        isRead: message.data.userId === this.userStore?.activeUser.uuid,
        isSent: true
      };
      if (message.data.userId !== this.userStore?.activeUser.uuid) {
        updatedThread.unreadMessages += 1;
      }
      const reorderedList = this.reorderThreadList([
        updatedThread,
        ...listWithoutUpdatedThread
      ]);
      this.setThreadsList(reorderedList);
    } else {
      // if the thread was not found it means it is a new thread
      // and it is necessary to call the api
      const threadCreatedByOtherUser =
        message?.data?.userId !== this.userStore?.activeUser.uuid;
      this.fetchNewThread(message).then(threadsList => {
        if (threadsList && threadCreatedByOtherUser) {
          const thread = threadsList.find(
            t => message?.data?.threadId === t.id
          );
          this.handleTabNotification(thread, message);
        }
      });
      if (threadCreatedByOtherUser) {
        this.spaceStore.increaseThreadMessagesCounterOnSpace({
          spaceId: message?.data?.spaceId
        });
      }
    }
  };

  onReadMessage = (message: ThreadReadMessage) => {
    const {
      data: { threadId, userId }
    } = message;
    if (userId !== this.userStore?.activeUser.uuid) return;
    this.spaceStore.decreaseThreadMessagesCounterOnSpace({
      spaceId: this.spaceStore.activeSpaceId
    });
    const newThreadsList = this.$threadsList.map(thread => ({
      ...thread,
      unreadMessages:
        thread.id === threadId && thread.unreadMessages > 0
          ? thread.unreadMessages - 1
          : thread.unreadMessages
    }));
    const reorderedList = this.reorderThreadList(newThreadsList);
    this.setThreadsList(reorderedList);
  };

  onRemoveMessageFromList = (message: ThreadDeleteMessage) => {
    if (!message?.data) return;
    const threadWithLastMessageDeleted = this.$threadsList.findIndex(thread => {
      return (
        message.data.threadId === thread.id &&
        message?.data?.id === thread.lastMessage?.id
      );
    });
    if (threadWithLastMessageDeleted > -1) {
      this.fetchThreadsList(this.spaceStore?.activeSpaceId, true);
    }
  };

  removeDOMEventListeners = () => {
    document.removeEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
  };

  addDOMEventListeners = (activeSpaceId: string) => {
    this.removeDOMEventListeners();
    this.$visibilityChangeListener = () => {
      if (document?.visibilityState === "visible") {
        this.fetchThreadsList(activeSpaceId, true);
      }
    };
    document.addEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
  };

  get threadsList() {
    return this.$threadsList;
  }

  get numUnreadMessages() {
    return this.$numUnreadMessages;
  }

  get tabNotification() {
    return this.$tabNotification;
  }

  dispose = () => {
    reaction(
      // reacts on change of space id
      () => this.spaceStore?.activeSpaceId,
      activeSpaceId => {
        this.fetchThreadsList(activeSpaceId);
        if (activeSpaceId) {
          this.addDOMEventListeners(activeSpaceId);
        } else {
          this.removeDOMEventListeners();
        }
      }
    );
  };

  reset = () => {
    this.$threadsList = [];
    this.$numUnreadMessages = 0;
    this.resetTabNotification();
  };
}

export default ChatStore;
