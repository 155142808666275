import { Input } from "@ds_universal/data_entry";
import React from "react";
import { TranslationKeys, useTranslation } from "src/js/translation";
import styled, { useTheme } from "styled-components";
import slugify from "slugify";
import { Text } from "@ds_universal/Text";
import { useStores } from "src/js/hooks";

const SlugifyOptions = {
  strict: true,
  replacement: ""
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div > div {
    margin-top: 0px;
  }
`;

const SanitizedInput = ({
  label,
  value,
  setValue,
  placeholder
}: {
  label: TranslationKeys;
  value?: string;
  setValue: (val: string) => void;
  placeholder?: TranslationKeys;
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  return (
    <StyledWrapper>
      {label ? <Text type="formSubtitle">{translate(label)}</Text> : null}
      <Input
        name={label}
        label={label}
        rounded
        hideLabel
        placeholder={placeholder ? translate(placeholder) : ""}
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        type="field"
        value={slugify(value || "", SlugifyOptions).toUpperCase()}
        onChange={e =>
          setValue(slugify(e.target.value, SlugifyOptions).toUpperCase())
        }
      />
    </StyledWrapper>
  );
};

export default SanitizedInput;
