import React from "react";
import { Route, Switch } from "react-router";
import MatchDashboardRoute from "src/js/context/utils/MatchDashboardRoute";
import { DashboardLayout } from "src/js/layout/DashboardLayout";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { SpacesBadges } from "src/js/pages/spaces/dashboard/SpacesBadges";
import { SpacesGroups } from "src/js/pages/spaces/dashboard/SpacesGroups";
import { SpacesSettings } from "src/js/pages/spaces/dashboard/SpacesSettings";
import { SpacesUsers } from "src/js/pages/spaces/dashboard/SpacesUsers";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { Analytics } from "src/js/pages/spaces/dashboard/Analytics";
import { Staff } from "../../spaces/dashboard/Staff";

export const DashboardRouter = () => {
  return (
    <DashboardLayout>
      <Route
        path={WeSchoolRoutes.App.Dashboard.space}
        component={MatchDashboardRoute}
      />
      <Switch>
        <Route
          path={WeSchoolRoutes.App.Dashboard.groups}
          exact
          component={SpacesGroups}
        />
        <Route
          path={WeSchoolRoutes.App.Dashboard.analytics}
          exact
          component={Analytics}
        />
        <Route
          path={WeSchoolRoutes.App.Dashboard.users}
          exact
          component={SpacesUsers}
        />
        <Route
          path={WeSchoolRoutes.App.Dashboard.staff}
          exact
          component={Staff}
        />
        <Route
          path={WeSchoolRoutes.App.Dashboard.settings}
          exact
          component={SpacesSettings}
        />
        <Route
          path={WeSchoolRoutes.App.Dashboard.badges}
          exact
          component={SpacesBadges}
        />
        <Route
          render={props => (
            <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
          )}
        />
      </Switch>
    </DashboardLayout>
  );
};
