import { ApiSearchGroupsVars } from "./types";

export function getVersionedApiUrl(
  apiUrl: string,
  version?: "v1" | "v2" | "v3"
) {
  if (typeof version === "undefined") {
    return `v1/${apiUrl}`;
  }
  return `${version}/${apiUrl}`;
}

export const api_activity = vars => `groups/${vars.group_id}/activity`;
export const api_ai_assistant_content = () => "ai-assistant/content";
export const api_ai_assistant_token = vars =>
  `groups/${vars.groupId}/ai-api/token`;
export const api_ai_assistant_transaction_id = vars =>
  `groups/${vars.groupId}/ai-api/ai-transaction-id`;
export const api_custom_activities_create = vars =>
  `groups/${vars.group_id}/customactivities`;

export const api_badge_delete = (vars: { badgeId: string }) =>
  `badges/${vars.badgeId}`;

export const api_live_opening = () => "live-opening";

export const api_board = vars => `boards/${vars.board_id}`;
export const api_boards = vars => `group/${vars.groupId}/boards`;
export const api_boards_list = vars => `groups/${vars.groupId}/boards/list`;
export const api_group_scorms = vars => `groups/${vars.groupId}/scorm-records`;
export const api_group_scorms_reset = vars =>
  `groups/${vars.groupId}/scorm-records/reset`;
export const api_group_scorms_csv_export = vars =>
  `groups/${vars.groupId}/scorm-records/csv-export`;
export const api_user_scorms_csv_export = vars =>
  `groups/${vars.groupId}/users/${vars.userUuid}/scorm-records/csv-export`;
export const api_user_scorms = vars =>
  `groups/${vars.groupId}/users/${vars.userUuid}/scorm-records`;
export const api_boards_order = () => "boards/reorder";
export const api_board_path = vars => `boards/${vars.board_id}/path`;
export const api_new_board = () => "boards";
export const api_publish_board = vars => `boards/${vars.board_id}/publish`;
export const api_reorder_board = vars => `boards/${vars.board_id}/position`;
export const api_unpublish_board = vars => `boards/${vars.board_id}/unpublish`;
export const api_edit_board = vars => `boards/${vars.board_id}`;
export const api_constraints_board = vars =>
  `boards/${vars.board_id}/constraints`;
export const api_edit_constraints_board = vars =>
  `boards/${vars.board_id}/constraints/${vars.constraint_id}`;

export const api_board_element_details = vars =>
  `board-elements/${vars.boardElementId}/details`;
export const api_board_element_comments = vars =>
  `board-elements/${vars.boardElementId}/comments`;
export const api_board_element_comment = vars =>
  `board-elements/comments/${vars.commentId}`;
export const api_board_element_nested_comments = vars =>
  `board-elements/comments/${vars.commentId}/comments`;
export const api_board_element_nested_comment = vars =>
  `board-elements/comments/comments/${vars.nestedCommentId}`;

export const api_board_element_create = vars => `board/${vars.boardId}/element`;
export const api_board_element_track = vars =>
  `boards/${vars.boardId}/boardelements/${vars.elementId}/track`; // old track for opening a resource
export const api_module_resource_suggest = vars =>
  `boards/${vars.moduleId}/elements`;
export const api_board_element_state = vars =>
  `board-element/${vars.board_element_id}/state`;
export const api_board_element_resource = vars =>
  `boardelement/${vars.board_element_id}/resource`;
export const api_board_elements_score = () => "score/boards";
export const api_board_search = () => "board/search";

export const api_calendar_event_id = vars =>
  `calendar/event/${vars.calendarEventId}`;
export const api_calendar_event = () => "calendar/event";

export const api_dashboard_analytics_indexes = vars =>
  `dashboard/${vars.spaceId}/indexes`;

export const api_dashboard_user_indexes = ({ spaceId, userId }) =>
  `dashboard/${spaceId}/indexes/users/${userId}`;

export const api_dashboard_groups_indexes = ({ spaceId }) =>
  `dashboard/${spaceId}/indexes/groups`;

export const api_dashboard_analytics_trends = vars =>
  `dashboard/${vars.spaceId}/trends`;
export const api_dashboard_groups = vars => `dashboard/${vars.spaceId}/groups`;
export const api_dashboard_member = vars =>
  `dashboard/${vars.spaceId}/members/${vars.userId}`;
export const api_dashboard_members = vars =>
  `dashboard/${vars.spaceId}/members`;
export const api_dashboard_settings = vars =>
  `dashboard/${vars.spaceId}/settings`;
export const api_dashboard_user = vars =>
  `dashboard/${vars.spaceId}/users/${vars.userId}`;
export const api_dashboard_users = vars => `dashboard/${vars.spaceId}/users`;
export const api_dashboard_users_roles = vars =>
  `dashboard/${vars.spaceId}/users/${vars.userId}/roles`;

export const api_dea_group_metadata = vars =>
  `/v1/import/dea/group/${vars.projectId}/metadata`;
export const api_dea_import_group = vars =>
  `/v1/import/dea/group/${vars.projectId}`;
export const api_dea_import_lesson = vars =>
  `/v1/import/dea/board/${vars.projectId}/${vars.lessonId}`;
export const api_dea_lesson_metadata = vars =>
  `/v1/import/dea/board/${vars.projectId}/${vars.lessonId}/metadata`;

// LTI POC

// deep-link (login)
export const api_deep_link = vars =>
  `boards/${vars.boardId}/${vars.registrationId}/deep-link`;
// lti_resource (get the resource in view)
export const api_lti_resource = vars =>
  `boards/elements/${vars.elementId}/lti-resource`;
// registration list for weschool
export const api_lti_registration_list = () => "lti-registrations";

// END LTI POC

export const api_exercises = () => "exercises";
export const api_exercises_instant_abort = vars =>
  `exercises/${vars.exerciseId}/executions/${vars.executionId}/abort`;
export const api_execution_comments = vars =>
  `exercises/${vars.executionId}/comments`;
export const api_execution_redo = vars =>
  `exercises/${vars.exerciseId}/executions/${vars.executionId}/redo`;

export const api_exercise = vars =>
  `groups/${vars.groupId}/exercises/${vars.exerciseId}`;
export const api_exercises_entities = vars => `exercises/${vars.exerciseId}`;
export const api_exercises_publish = vars =>
  `exercises/${vars.exerciseId}/publish`;
export const api_exercises_unpublish = vars =>
  `exercises/${vars.exerciseId}/unpublish`;

export const api_exercise_settings = vars =>
  `exercises/${vars.exerciseId}/settings`;

export const api_exercise_show_report_to_user = vars =>
  `exercises/${vars.exerciseId}/reports/users/${vars.userId}`;

export const api_exercise_show_report_to_all = vars =>
  `exercises/${vars.exerciseId}/reports/users`;

export const api_groups = () => "groups";
export const api_groups_clone = vars => `groups/${vars.groupId}/clone`;
export const api_groups_stats = vars => `groups/${vars.groupId}/stats`;

export const api_countries = () => "countries";
export const api_cities = vars => `countries/${vars.countryId}/cities`;

export const api_user_custom_fields = vars =>
  `spaces/${vars.spaceId}/users/${vars.userId}/custom-fields`;
export const api_user_required_custom_fields = vars =>
  `spaces/${vars.spaceId}/users/${vars.userId}/required-custom-fields`;

export const api_education_levels = () => "education-levels";

export const api_group_active = vars => `groups/${vars.groupId}`;
export const api_group_activate = vars => `group/${vars.groupId}/activate`;
export const api_group_alias_active = vars => `alias/${vars.alias}/active`;
export const api_group_badges = (vars: { groupId: number }) =>
  `groups/${vars.groupId}/badges`;
export const api_group_calendar = vars => `group/${vars.groupId}/calendar`;
export const api_group_calendar_event = vars =>
  `groups/${vars.groupId}/calendar/event/${vars.calendarEventId}`;
export const api_group_calendar_eventFile = ({
  groupId,
  calendarEventId
}: {
  groupId: number;
  calendarEventId: string;
}) => `groups/${groupId}/calendar/event/${calendarEventId}/export`;
export const api_group_emails_by_role = vars => `groups/${vars.groupId}/emails`;
export const api_group_progress = vars => `groups/${vars.groupId}/progress`;
export const api_group_whiteboard_join = vars =>
  `whiteboard/${vars.groupId}/join`;
export const api_group_whiteboard_activate = vars =>
  `whiteboard/${vars.groupId}/activate`;

export const api_group_whiteboard_deactivate = vars =>
  `whiteboard/${vars.groupId}/deactivate`;

export const api_groups_count_students = vars =>
  `groups/${vars.groupId}/count/students`;
export const api_groups_admin_members = vars =>
  `groups/${vars.groupId}/admin/members`;
export const api_groups_edit = vars => `groups/${vars.groupId}/edit`;
export const api_groups_invites = vars => `groups/${vars.groupId}/invites`;
export const api_groups_invites_alias = vars =>
  `groups/${vars.groupId}/invites/alias`;
export const api_groups_invites_alias_check = vars =>
  `groups/${vars.groupId}/invite/alias/${vars.alias}/check`;
export const api_groups_invites_alias_edit = vars =>
  `groups/${vars.groupId}/invite`;
export const api_groups_invites_delete = vars =>
  `groups/invites/${vars.inviteId}`;
export const api_groups_invites_publish = vars =>
  `groups/${vars.groupId}/invites/alias/publish`;
export const api_groups_invites_reject = vars =>
  `groups/invites/${vars.inviteId}/users/reject`;
export const api_groups_accept_user_invites = () =>
  "groups/accept-user-invites";
export const api_groups_user_invites_bulk = () => "groups/user-invites";
export const api_groups_user_invites_paginated = (vars: { groupId: number }) =>
  `groups/${vars.groupId}/user-invites/paginated`;
export const api_groups_user_invites_async = () => "group-invite-user/async";
export const api_groups_new = () => "groups";
export const api_groups_notification_settings = vars =>
  `groups/${vars.groupId}/notification-settings`;
export const api_groups_requests = () => "groups/request/all";
export const api_group_search = () => "groups/search";
export const api_groups_users = vars => `groups/${vars.groupId}/users`;
export const api_groups_settings = vars => `groups/${vars.groupId}/settings`;
export const api_group_users_unlogged = vars => `users/group/${vars.groupId}`;
export const api_groups_users_edit = vars =>
  `groups/${vars.groupId}/users/${vars.userId}`;
export const api_groups_users_most_mentioned = vars =>
  `groups/${vars.groupId}/users/most-mentioned`;
export const api_self_joinable_groups_users = vars =>
  `self-joinable-groups/${vars.groupId}/users/${vars.userUuid}`;
export const api_groups_users_restore = vars =>
  `groups/${vars.groupId}/users/${vars.userId}/restore`;
export const api_groups_migrate = () => "groups/migrate";
export const api_group_info_from_invitation = vars =>
  `groups/invitation/${vars.invitationId}`;

export const api_invite_info = vars => `invite/${vars.inviteCode}`;

export const api_notifications_marketing_settings_update = () =>
  "users/me/settings/notifications/marketing/preferences";

export const api_module_approve_resource = vars =>
  `boards/${vars.moduleId}/elements/${vars.elementId}/approve`;
export const api_module_reject_resource = vars =>
  `boards/${vars.moduleId}/elements/${vars.elementId}/reject`;

export const api_notification_read = vars =>
  `notifications/${vars.notificationId}/read`;

export const api_notification_unread = vars =>
  `notifications/${vars.notificationId}/unread`;

export const api_notification_group_readall = vars =>
  `notifications/groups/${vars.groupId}/read`;

export const api_notification_space_readall = vars =>
  `notifications/spaces/${vars.spaceId}/read`;

export const api_notifications = () => "notifications";
export const api_notifications_read = () => "notifications/read";

export const api_oauth_logout = () => "auth/v1/logout";
export const api_oauth_registration_info = () => "registration/token";
export const api_onboarding_create_marketing_data = () => "marketing-data";
export const api_onboarding_update_marketing_data = () => "marketing-data";
export const api_onboarding_read_marketing_data = () => "marketing-data";

export const api_spaces_customizations = vars =>
  `spaces/${vars.spaceId}/customizations`;
export const api_spaces_checkout = vars => `spaces/${vars.spaceId}/checkout`;
export const api_spaces_portal_session = vars =>
  `spaces/${vars.spaceId}/portal-session`;
export const api_spaces_list = () => "spaces";
export const api_spaces_member = vars => `spaces/${vars.spaceId}/member`;
export const api_spaces_can_create_group = () => "spaces/can-create-group";
export const api_spaces_groups_by_space_id = vars =>
  `spaces/${vars.spaceId}/groups`;
export const api_spaces_groups_paginated = vars =>
  `spaces/${vars.spaceId}/groups/paginated`;
export const api_spaces_self_joinable_group = vars =>
  `spaces/${vars.spaceId}/self-joinable-groups`;
export const api_spaces_groups_can_migrate = vars =>
  `spaces/${vars.spaceId}/groups/can-migrate`;
export const api_spaces_details = vars => `spaces/${vars.spaceId}`;
export const api_spaces_threads = vars => `spaces/${vars.spaceId}/threads`;
export const api_spaces_threads_exists = vars =>
  `spaces/${vars.spaceId}/threads/exists`;
export const api_threads_settings = vars => `threads/${vars.threadId}/settings`;
export const api_threads_unread_oldest = vars =>
  `threads/${vars.threadId}/unread/oldest`;
export const api_spaces_threads_messages = vars =>
  `spaces/${vars.spaceId}/threads/${vars.threadId}/messages`;
export const api_spaces_members = vars => `spaces/${vars.spaceId}/members`;
export const api_spaces_templates = vars => `spaces/${vars.spaceId}/templates`;
export const api_spaces_users = vars => `spaces/${vars.spaceId}/users`;
export const api_spaces_resources = vars =>
  `spaces/${vars.spaceSlug}/resources`;
export const api_spaces_upload_file_authorize = vars =>
  `spaces/${vars.spaceSlug}/upload-file/authorize`;
export const api_websocket_auth_token = () => "websocket/token";

export const api_report_threads = vars =>
  `exercises/${vars.exerciseId}/executions/${vars.executionId}/threads`;
export const api_report_threads_delete_correction = vars =>
  `exercises/${vars.exerciseId}/executions/${vars.executionId}/threads/delete`;
export const api_report_threads_edit_correction = vars =>
  `exercises/${vars.exerciseId}/executions/${vars.executionId}/threads/edit`;

export const api_request_reset_password = () => "users/password/reset";

export const api_resource = vars => `resources/${vars.resource_id}`;
export const api_resource_embeddable = () => "embeddable";
export const api_group_resource = (vars: {
  groupId: number;
  resourceId: number;
}) => `${vars.groupId}/resources/${vars.resourceId}`;
export const api_resource_create = () => "resources";
export const api_resource_edit_title = vars =>
  `post/${vars.post_id}/resource/${vars.resource_id}/title`;

export const api_reset_password = () => "users/password/reset/confirm";

export const api_platform_invite = () => "platforminvites";
export const api_posts = () => "posts";
export const api_post = vars => `posts/${vars.post_id}`;
export const api_all_posts = vars => `groups/${vars.groupId}/posts`;
export const api_pin_posts = vars => `posts/${vars.postId}/pin`;
export const api_post_comments = vars => `posts/${vars.postId}/comments`;
export const api_post_comment = vars =>
  `posts/${vars.postId}/comments/${vars.commentId}`;
export const api_post_resource = vars => `post/${vars.post_id}/resource`;
export const api_posts_resources = vars => `posts/${vars.post_id}/resources`;

export const api_post_nested_comments = vars =>
  `posts/comments/${vars.commentId}/comments`;
export const api_post_nested_comment = vars =>
  `posts/comments/${vars.commentId}/comments/${vars.nestedCommentId}`;
export const api_nested_comment = vars =>
  `comments/${vars.parentId}/comments/${vars.nestedCommentId}`;

export const api_reactions = () => "reactions";
export const api_reaction = vars => `reactions/${vars.reactionId}`;
export const api_user_reactions = () => "reactions/users";

export const api_registration = () => "users/registration";
export const api_registration_token = () => "registration/token";
export const api_registration_code_info = vars =>
  `groups/${vars.code}/invites/token`;
export const api_registration_token_info = vars =>
  `platforminvites/${vars.token}`;
export const api_registration_confirm = () => "users/me/registration/confirm";

export const api_users_verify = () => "users/me/verify";
export const api_users_verify_resend = () => "users/me/verify/resend";

export const api_school_new = () => "schools";
export const api_search = () => "search";
export const api_search_groups = (vars: ApiSearchGroupsVars) =>
  `users/${vars.userId}/groups`;
export const api_search_users = () => "users/classmates";

export const api_space_badges_list = (vars: { spaceId: string }) =>
  `spaces/${vars.spaceId}/badges`;

export const api_space_notification_settings = vars =>
  `spaces/${vars.spaceId}/notification-settings`;

export const api_sso = () => "auth/sso/saml/initialize";

export const api_oauth_sso = () => "auth/sso/oauth/initialize";

export const api_tutor_email_request = () => "users/me/ask-mail-confirmation";
export const api_tutor_check_token = vars => `confirm-user/${vars.token}`;
export const api_tutor_registration = vars => `confirm-user/${vars.token}`;

export const api_url_info = () => "urlinfo";

export const api_user_score = vars => `score/users/${vars.user_id}`;

export const api_users_avatar = () => "users/avatar";

export const api_user_activation_code = () => "users/me/ask-confirmation";
export const api_user_can_vote_app = () => "rate-app";
export const api_users_change_mail_confirm = vars =>
  `users/me/email-change/confirm?token=${vars.token}`;
export const api_users_check_mail = email =>
  `users/me/email/availability?email=${email}`;

export const api_users_groups = () => "groups";
export const api_user_group_invites = () => "users/me/groups/invites";
export const api_users_me = () => "users/me";
export const api_users_delete = () => "users/me/delete-account";
export const api_users_name = () => "users/me/name";
export const api_users_email = () => "users/me/email";
export const api_users_email_resend = () => "users/me/email-change/send";
export const api_users_email_reject = () => "users/me/email-change/reject";

export const api_users_platform_tasks = () => "users/me/platform-tasks";
export const api_users_password = () => "users/me/password";
export const api_user_onesignal_token = () => "api/v1/user/onesignal/token";
export const api_user_public_profile = vars =>
  `public-profile/${vars.userUuid}`;
export const api_user_public_profile_badge_earned = (vars: {
  userUuid: string;
}) => `users/${vars.userUuid}/badges`;
export const api_users_public_profile_rank_list = vars =>
  `score/users/${vars.user_id}/charts`;
export const api_user_public_profile_score = vars =>
  `score/users/${vars.user_id}`;
export const api_user_public_profile_settings = () => "public-profile/settings";
export const api_user_public_profile_shared_group = vars =>
  `users/${vars.userUuid}/shared-groups`;
export const api_users_search_groups = vars => `users/${vars.user_id}/groups`;
export const api_space_user_stats = vars => `users/stats/${vars.spaceId}`;

export const api_vendor_accounts = () => "live/vendor/accounts";

export const api_resources_search = () => "resources/search";
export const api_youtube_search =
  "https://www.googleapis.com/youtube/v3/search";
export const api_slug_space_availability = vars =>
  `space-slug/${vars.slug}/check`;
export const api_space_creation = () => "spaces";
export const api_join_unclaimed_space = vars =>
  `spaces/${vars.spaceId}/join-unclaimed`;
export const api_join_demo_space = () => "spaces/demo-generate";
export const api_space_leave = vars => `spaces/${vars.spaceId}/leave`;
export const api_space_school_search_list = () => "spaces/search-school";
export const api_user_sso_provider = () => "users/sso-provider";

export const api_module_resource_edit_title = vars =>
  `boards/${vars.moduleId}/elements/${vars.resourceId}`;
export const api_module_resource_link_preview = () => "resources/link-preview";
export const api_module_resource_thumbnail = vars =>
  `boards/${vars.moduleId}/elements/${vars.elementId}/preview`;
export const api_user_wallet_details = (uuid: string) => `users/${uuid}/wallet`;
export const api_user_wallet_update = ({
  userId,
  walletId
}: {
  userId: string;
  walletId: string;
}) => `users/${userId}/wallet/${walletId}`;
export const api_report_abuse = () => "content-report";
export const api_space_badges_related_groups = ({
  badgeId
}: {
  badgeId: string;
}) => `badges/${badgeId}/groups`;
export const api_space_badges_create_badge = () => "badges";
export const api_space_badges_edit_badge = ({ badgeId }: { badgeId: string }) =>
  `badges/${badgeId}`;
export const api_space_badges_related_users = ({
  badgeId
}: {
  badgeId: string;
}) => `badges/${badgeId}/users`;
export const api_user_awarded_badges = ({ userId }: { userId: string }) =>
  `users/${userId}/badges`;
export const api_user_awarded_badge = ({
  userId,
  badgeId
}: {
  userId: string;
  badgeId: string;
}) => `users/${userId}/badges/${badgeId}`;
export const api_space_badges_transactions = ({
  badgeId
}: {
  badgeId: string;
}) => `badges/${badgeId}/transactions`;
export const api_space_badges_transaction = ({
  badgeId,
  transactionId
}: {
  badgeId: string;
  transactionId: string;
}) => `badges/${badgeId}/transactions/${transactionId}`;

// dashboard analytics
export const api_spaces_analytics_groups_progress = ({
  spaceId
}: {
  spaceId: string;
}) => `spaces/${spaceId}/analytics/groups-progress`;
export const api_spaces_analytics_assessment_scores = ({
  spaceId
}: {
  spaceId: string;
}) => `spaces/${spaceId}/analytics/assessment-scores`;
export const api_spaces_analytics_student_training_hours = ({
  spaceId
}: {
  spaceId: string;
}) => `spaces/${spaceId}/analytics/student-training-hours`;
export const api_spaces_analytics_student_learning_time = ({
  spaceId
}: {
  spaceId: string;
}) => `spaces/${spaceId}/analytics/student-learning-time`;
export const api_spaces_analytics_student_sessions_count = ({
  spaceId
}: {
  spaceId: string;
}) => `spaces/${spaceId}/analytics/student-sessions-count`;
export const api_space_badge_generate_pdf = ({
  badgeId,
  userId
}: {
  badgeId: string;
  userId: string;
}) => `badges/${badgeId}/user/${userId}/generate-pdf`;
