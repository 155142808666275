import {
  ConfirmationAlert,
  ContextualAlert,
  ContextualError,
  ContextualFeedback
} from "@arcadia/design-system";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { ObjectValues } from "./Common";

export const ToastVariant = {
  Success: "success",
  Error: "error",
  Alert: "alert",
  Warning: "warning",
  Confirmation: "confirmation"
} as const;

export type ToastVariantType = ObjectValues<typeof ToastVariant>;

export type BaseToastMessage = {
  id?: string;
  duration?: number;
  children?: React.ReactNode;
  zIndex?: number;
};

export type SuccessToastMessage = BaseToastMessage & {
  variant: typeof ToastVariant.Success;
  props?: Omit<
    React.ComponentProps<typeof ContextualFeedback>,
    "children" | "theme"
  >;
};

export type ErrorToastMessage = BaseToastMessage & {
  variant: typeof ToastVariant.Error;
  props?: Omit<React.ComponentProps<typeof ContextualError>, "text" | "theme">;
};

export type AlertToastMessage = BaseToastMessage & {
  variant: typeof ToastVariant.Alert;
  props?: Omit<React.ComponentProps<typeof ContextualAlert>, "text" | "theme">;
};

export type WarningToastMessage = BaseToastMessage & {
  variant: typeof ToastVariant.Warning;
  props?: React.ComponentProps<typeof WarningBanner>;
};

export type ConfirmationToastMessage = BaseToastMessage & {
  variant: typeof ToastVariant.Confirmation;
  props?: Omit<
    React.ComponentProps<typeof ConfirmationAlert>,
    "text" | "theme"
  >;
};

export type ToastMessage =
  | SuccessToastMessage
  | ErrorToastMessage
  | AlertToastMessage
  | WarningToastMessage
  | ConfirmationToastMessage;
