import { Box, Text, Toggle } from "@arcadia/design-system";
import React from "react";
import { useSpacePlanType, useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { DisabledSectionWrapper } from "../../DisabledSectionWrapper";
import { InfoTooltip } from "../../InfoTooltip";
import * as S from "./ToggleSelfJoinable.styles";

type ToggleSelfJoinableProps = {
  theme: any;
  isSelfJoinable: boolean;
  handleSelfJoinableToggle: () => void;
};

const ToggleSelfJoinable = ({
  theme,
  isSelfJoinable,
  handleSelfJoinableToggle
}: ToggleSelfJoinableProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { isClaimable, isClaimed }
  } = useStores();
  const { isPlanProOrEnterprise } = useSpacePlanType({
    groupCount: 1
  });

  return (
    <DisabledSectionWrapper
      disabled={(!isPlanProOrEnterprise || isClaimable) && !isClaimed}
      tooltipContent={
        <Text type="formDescription">
          {translate("tooltip_group_self_joinable_require_pro_plan")}
        </Text>
      }
    >
      <S.SectionCard>
        <S.SectionCardContent>
          <S.SectionCardContentText>
            <Box display="flex" gap="4px" alignItems="center">
              <S.StyledSectionTitle type="subHeaderSubtitle">
                {translate("group_creation_form_self_joinable_title")}
              </S.StyledSectionTitle>
              <InfoTooltip
                side={isLayoutModeMobile ? undefined : "right"}
                customTooltipProps={{
                  title: translate(
                    "group_creation_form_self_joinable_tooltip_title"
                  ),
                  description: translate(
                    "group_creation_form_self_joinable_tooltip_description"
                  )
                }}
              />
            </Box>
            <S.StyledSectionDescription>
              <Text
                type={
                  isLayoutModeMobile ? "formSmallCaption" : "formDescription"
                }
              >
                {translate("group_creation_form_self_joinable_description")}
              </Text>
            </S.StyledSectionDescription>
          </S.SectionCardContentText>
        </S.SectionCardContent>
        <S.ToggleWrapper>
          <Toggle
            theme={theme}
            title="Set group self joinable"
            onChange={handleSelfJoinableToggle}
            checked={isSelfJoinable}
            size="small"
          />
        </S.ToggleWrapper>
      </S.SectionCard>
    </DisabledSectionWrapper>
  );
};

export default ToggleSelfJoinable;
