import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import MatchReportRoute from "src/js/context/utils/MatchReportRoute";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ExerciseModel from "../models/exerciseModel";
import { getActiveGroupId } from "../modules/activeGroup";
import { navigateTo } from "../modules/history";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";
import ExerciseEngine from "../view/exercise/engine/exerciseEngine";
import ExerciseBuilder from "../view/exercise/exerciseBuilder";
import ExerciseDetailView from "../view/exercise/exercisesDetailView";
import EserciziReportsUsers from "../view/exercise/report/exerciseReportUsers";
import SingleExecutionController from "./SingleExecutionController";
import SingleInstantController from "./SingleInstantController";

const SingleExerciseController = props => {
  const [exercise, setExercise] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { exercise_id } = useParams<{ exercise_id: string }>();

  const fetchExercise = () => {
    if (typeof exercise_id !== "undefined") {
      const exerciseId = parseInt(exercise_id, 10);
      if (typeof exerciseId === "number") {
        const exerciseModel = new ExerciseModel();
        exerciseModel.url = createURL("api_exercises_entities", {
          exercise_id: exerciseId
        });
        exerciseModel.on("change", newExercise => {
          setExercise(newExercise);
        });
        exerciseModel.fetch({
          success(model) {
            setExercise(model);
            setLoaded(true);
          },
          error(params) {
            showToastError({
              str: extractErrorMessage(params.responseJSON, __("e_404"))
            });
            navigateTo(
              createUrl("exercises", { group_id: getActiveGroupId() })
            );
          }
        });
      }
    } else {
      navigateTo(createUrl("exercises", { group_id: getActiveGroupId() }));
    }
  };

  useEffect(() => {
    if (!loaded) {
      fetchExercise();
    }
  }, [exercise_id, loaded]);

  if (exercise === null) {
    return <div />;
  }

  return (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseEdit}
        render={props => <ExerciseBuilder exercise={exercise} {...props} />}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRun}
        render={props => <ExerciseEngine exercise={exercise} {...props} />}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRunOption}
        render={props => <ExerciseEngine exercise={exercise} {...props} />}
      />
      <Route
        exact
        path={
          WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsUsers
        }
        render={props => (
          <EserciziReportsUsers exercise={exercise} {...props} />
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstantRun}
        render={props => (
          <ExerciseEngine exercise={exercise} instant {...props} />
        )}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstant}
        render={props => (
          <SingleInstantController exercise={exercise} {...props} />
        )}
      />
      <Route
        path={
          WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsSession
        }
        render={props => {
          return (
            <>
              <MatchReportRoute />
              <SingleExecutionController exercise={exercise} {...props} />
            </>
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercise}
        component={props => (
          <ExerciseDetailView exercise={exercise} {...props} />
        )}
      />
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  );
};

export default SingleExerciseController;
