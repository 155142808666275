import { Button, Dropdown } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledDropdown = styled(Dropdown)`
  width: fit-content;
  min-width: 160px;
  @media (max-width: 767px) {
    margin-left: auto;
  }
`;

export const StyledConfirmButton = styled(Button)`
  margin-left: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledFooter = styled.div`
  padding-bottom: 20px;
  @media (max-width: 767px) {
    position: sticky;
    bottom: 20px;
    width: 100%;
  }
`;
