import { Box, DoodleLoader, colors } from "@arcadia/design-system";
import { doRouteBack, navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import { useStores } from "src/js/hooks";
import { UnloggedRegisterLayout } from "src/js/layout/unloggedLayout/UnloggedRegisterLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import UnderageApprovationForm from "src/js/pages/App/UnderageApprovationPage/components/UnderageApprovationForm";
import {
  checkTutorApprobationToken,
  sendTutorApprobation
} from "src/js/repository/tutorRepository";
import {
  __PRIVACY_LINK__,
  __TERMS_LINK__
} from "src/js/settings/settingsGeneral";
import { Trans } from "src/js/translation";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { EventDomain, UserEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { RegistrationInviteOptionsContainer } from "../../registration/RegistrationOptions/RegistrationOptions.styles";

export const UnderageApprovationPage = observer(props => {
  const { match } = props;
  const {
    AuthStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [codeVerified, setCodeVerified] = useState(false);
  const [userToApprove, setUserToApprove] = useState(false);
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const privacy_link = __PRIVACY_LINK__;
  const terms_link = translate({ text: __TERMS_LINK__ });

  useEffect(() => {
    if (match.params.code) {
      checkTutorApprobationToken(match.params.code)
        .then(result => {
          setCodeVerified(true);
          setUserToApprove(result);
        })
        .catch(() => {
          showToastError({
            str: <Translate text="tutor_approbation_invalid_code" />
          });
          navigateTo(createUrl("login"));
        });
    } else {
      showToastError({
        str: <Translate text="tutor_approbation_invalid_code" />
      });
      navigateTo(createUrl("login"));
    }
  }, []);

  const goBack = () => {
    if (AuthStore.userIsLogged) {
      doRouteBack();
    } else {
      navigateTo(createUrl("login"));
    }
  };

  const submitFunction = data => {
    const token = match.params.code;
    sendTutorApprobation(token, data)
      .then(() => {
        _trackEvent(EventDomain.User, UserEvent.UnderageApprovation, "Success");
        showToastSuccess({
          str: (
            <Translate
              text="underage_approvation_success"
              stringVariables={{
                user: `${userToApprove.name} ${userToApprove.surname}`
              }}
            />
          )
        });
        goBack();
      })
      .catch(error => {
        showToastError({ str: <Translate text={error.message} /> });
      });
  };

  if (codeVerified === false) {
    return <DoodleLoader theme={greyTheme} />;
  }

  return (
    <UnloggedRegisterLayout pageTitle="tutor_form_title">
      <RegistrationInviteOptionsContainer marginBottom={24} marginTop={24}>
        {!isLayoutModeMobile ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={24}
          >
            <WeschoolLogo
              width="146"
              height="23"
              viewBox="0 0 207 32"
              color={colors.violet[501]}
            />
          </Box>
        ) : null}
        <div className="medium-title">
          <Translate text="tutor_form_title" />
        </div>
        <div className="margin-top-20 font-12">
          <Translate text="company_header" />
        </div>
        <div className="margin-top-10">
          <Trans
            i18nKey="tutor_form_introduction"
            values={{
              user: `${userToApprove.name} ${userToApprove.surname}`,
              birthday: moment(userToApprove.birthday).format("DD-MM-YYYY")
            }}
          />
        </div>
        <div className="margin-vertical-20 font-12 register-form__terms">
          <Trans
            i18nKey="terms_link"
            values={{
              terms_link,
              privacy_link
            }}
          />
        </div>
        <UnderageApprovationForm submitFunction={submitFunction} />
        <div className="flex-centered-column">
          <div className="unlogged-card-external-text margin-top-20">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={goBack}>
              <Translate text="back-weschool" />
            </a>
          </div>
        </div>
        <div className="flex-centered-column margin-top-10">
          <UnloggedSupportLink />
        </div>
      </RegistrationInviteOptionsContainer>
    </UnloggedRegisterLayout>
  );
});
