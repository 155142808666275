import { TranslationKeys } from "src/js/translation";
import { Resource } from "src/js/types";
import { ObjectValues } from "./Common";
import { RealTimeNotificationModel } from "./Notification";
import { ReactableEntity, Reaction, ReactionType } from "./Reaction";

export type WSAuthToken = { value: string; expiresAt: string };
export type WSAuthTokenResponse = {
  data: {
    jwt: string;
    expiresAt: string;
  };
};

export const WebSocketConnectionState = {
  INIT: "INIT",
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  DISCONNECTED: "DISCONNECTED",
  ERROR: "ERROR"
} as const;

export type WebSocketConnectionStateType = ObjectValues<
  typeof WebSocketConnectionState
>;

export const ScormEvent = {
  UploadFailed: "uploadFailed",
  UploadSuccess: "uploadSuccess"
} as const;

export const GroupInviteUserEvent = {
  GroupInviteUserAsync: "GROUP_INVITE_USER_ASYNC"
} as const;

export const EVENT = {
  NEW_MESSAGE_EVENT: "newMessage",
  DELETE_MESSAGE_EVENT: "deleteMessage",
  EDIT_MESSAGE_EVENT: "editMessage",
  READ_MESSAGE_EVENT: "readMessage",
  IS_TYPING_MESSAGE_EVENT: "isTypingMessage",
  AI_COURSE_GENERATION_SUCCESS: "courseGenerationSuccess",
  AI_COURSE_GENERATION_FAIL: "courseGenerationFail",
  CREATE_REACTION_EVENT: "createReaction",
  DELETE_REACTION_EVENT: "deleteReaction",
  // Notifications
  DO_REACTION_POST: "DO_REACTION_POST",
  ACTIVITY_START_INCOMING: "ACTIVITY_START_INCOMING",
  All_DAY_ACTIVITY_START_INCOMING: "All_DAY_ACTIVITY_START_INCOMING",
  DO_BOARD_EDIT: "DO_BOARD_EDIT",
  DO_BOARD_ELEMENT_DRAFT_APPROVED: "DO_BOARD_ELEMENT_DRAFT_APPROVED",
  DO_BOARD_ELEMENT_DRAFT_NEW: "DO_BOARD_ELEMENT_DRAFT_NEW",
  DO_BOARD_ELEMENT_DRAFT_REJECTED: "DO_BOARD_ELEMENT_DRAFT_REJECTED",
  DO_BOARD_LOCK_MODE: "DO_BOARD_LOCK_MODE",
  DO_BOARD_PUBLISH: "DO_BOARD_PUBLISH",
  DO_BOARD_UNLOCK_MODE: "DO_BOARD_UNLOCK_MODE",
  DO_EXAMINATION_EXECUTION_FINISH: "DO_EXAMINATION_EXECUTION_FINISH",
  DO_EXERCISE_EXECUTION_FINISH: "DO_EXERCISE_EXECUTION_FINISH",
  DO_REACTION_NESTED_BOARD_COMMENT: "DO_REACTION_NESTED_BOARD_COMMENT",
  DO_EXAMINATION_PUBLISH: "DO_EXAMINATION_PUBLISH",
  DO_REACTION_NESTED_POST_COMMENT: "DO_REACTION_NESTED_POST_COMMENT",
  DO_REACTION_BOARD_ELEMENT_COMMENT: "DO_REACTION_BOARD_ELEMENT_COMMENT",
  DO_REACTION_POST_COMMENT: "DO_REACTION_POST_COMMENT",
  DO_EXERCISE_EXECUTION_COMMENT_NEW: "DO_EXERCISE_EXECUTION_COMMENT_NEW",
  DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_CORRECTION_MODIFIED:
    "DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_CORRECTION_MODIFIED",
  DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_CLOSED:
    "DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_CLOSED",
  DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_REQUEST:
    "DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_REQUEST",
  DO_EXERCISE_PUBLISH: "DO_EXERCISE_PUBLISH",
  DO_GROUP_INVITE_USER_ACCEPT: "DO_GROUP_INVITE_USER_ACCEPT",
  DO_MENTION_NESTED_POST_COMMENT: "DO_MENTION_NESTED_POST_COMMENT",
  DO_MENTION_NESTED_BOARD_ELEMENT_COMMENT:
    "DO_MENTION_NESTED_BOARD_ELEMENT_COMMENT",
  DO_POST: "DO_POST",
  DO_POST_COMMENT: "DO_POST_COMMENT",
  DO_MENTION_POST: "DO_MENTION_POST",
  DO_MENTION_POST_COMMENT: "DO_MENTION_POST_COMMENT",
  DO_MENTION_BOARD_ELEMENT_COMMENT: "DO_MENTION_BOARD_ELEMENT_COMMENT",
  LIVE_START_INCOMING: "LIVE_START_INCOMING",
  DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_OPEN:
    "DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_OPEN",
  DO_EXERCISE_EXECUTION_CORRECTION_FINISHED:
    "DO_EXERCISE_EXECUTION_CORRECTION_FINISHED",
  DO_NESTED_BOARD_COMMENT: "DO_NESTED_BOARD_COMMENT",
  DO_NESTED_POST_COMMENT: "DO_NESTED_POST_COMMENT",
  DO_BOARD_ELEMENT_COMMENT: "DO_BOARD_ELEMENT_COMMENT",
  DEADLINE_END_INCOMING: "DEADLINE_END_INCOMING",
  GROUP_CLONING_COMPLETED: "GROUP_CLONING_COMPLETED",
  SCORM_RESET_REQUEST: "scorm_reset_request",
  ...GroupInviteUserEvent,
  ...ScormEvent
  // SCORM
} as const;

export const DOMAIN = {
  CHAT_DOMAIN: "chat",
  AI_DOMAIN: "ai",
  NOTIFICATIONS_DOMAIN: "notifications",
  TEMPLATING_DOMAIN: "templating",
  SCORM: "scorm",
  GROUP_INVITE_USER: "group_invite_user"
} as const;

export type WebSocketDomain = ObjectValues<typeof DOMAIN>;
export type WebSocketEvent = ObjectValues<typeof EVENT>;

type WebSocketNewMessageData = {
  threadId: string;
  spaceId: string;
  resources: Resource[];
  message: string;
};

type WebSocketNewMessageDataHydrated = {
  id: string;
  threadId: string;
  spaceId: string;
  resources: Resource[];
  message: string;
  userId: string;
  createdAt: string;
};

type WebSocketScormResetRequestData = {
  userId: string;
  status: string;
  spaceId: string;
  groupId: number;
  boardId: string;
  boardElementId: string;
};

type WebSocketIsTypingMessageData = {
  isTyping: boolean;
  userId: string;
  threadId: string;
};

type WebSocketDeleteMessageData = {
  id: string;
  userId: string;
  threadId: string;
  spaceId: string;
};

type WebSocketEditMessageData = {
  id: string;
  userId: string;
  threadId: string;
  spaceId: string;
  message: string;
  updatedAt?: string;
};

type WebSocketReadMessageData = {
  id: string;
  userId: string;
  threadId: string;
  spaceId: string;
  createdAt?: string;
};

type WebSocketMessageCreateReactionData = {
  userId: string;
  userUuid: string;
  threadId: string;
  spaceId: string;
  reactedId: string; // message id
  reactableEntity: ReactableEntity;
  reactionType: ReactionType;
};

type WebSocketMessageCreateReactionDataHydrated = {
  id: string;
  userId: string;
  threadId: string;
  spaceId: string;
  reaction: Reaction;
};

type WebSocketMessageDeleteReactionData = {
  id: string;
  userId: string;
  threadId: string;
  spaceId: string;
  reactionId: string;
  reactionType: ReactionType;
};

type WebSocketScormUploadData = {
  scormId: string;
  error?: TranslationKeys;
};

type WebSocketAICourseGenSuccess = {
  aiTransactionId: string;
  boardId: string;
};

type WebSocketAICourseGenFail = {
  aiTransactionId: string;
  errorMessage: string;
};

type WebSocketTemplatingGroupCloningCompleted = {
  groupId: number;
};

type WebSocketDefaultResponse = any;

type EventDataMap = {
  [EVENT.DELETE_MESSAGE_EVENT]: WebSocketDeleteMessageData;
  [EVENT.EDIT_MESSAGE_EVENT]: WebSocketEditMessageData;
  [EVENT.IS_TYPING_MESSAGE_EVENT]: WebSocketIsTypingMessageData;
  [EVENT.NEW_MESSAGE_EVENT]:
    | WebSocketNewMessageDataHydrated
    | WebSocketNewMessageData;
  [EVENT.READ_MESSAGE_EVENT]: WebSocketReadMessageData;
  [EVENT.AI_COURSE_GENERATION_SUCCESS]: WebSocketAICourseGenSuccess;
  [EVENT.AI_COURSE_GENERATION_FAIL]: WebSocketAICourseGenFail;
  [EVENT.CREATE_REACTION_EVENT]:
    | WebSocketMessageCreateReactionDataHydrated
    | WebSocketMessageCreateReactionData;
  [EVENT.DELETE_REACTION_EVENT]: WebSocketMessageDeleteReactionData;
  [EVENT.UploadFailed]: WebSocketScormUploadData;
  [EVENT.UploadSuccess]: WebSocketScormUploadData;
  [EVENT.SCORM_RESET_REQUEST]: WebSocketScormResetRequestData;
};

export type WebSocketMessageData<T extends WebSocketEvent> =
  T extends keyof EventDataMap ? EventDataMap[T] : WebSocketDefaultResponse;

export type WebSocketMessage<T> = {
  event: WebSocketEvent;
  domain: WebSocketDomain;
  data: T;
};

export const WebSocketSendError = {
  SocketNotReady: "ERROR_SOCKET_NOT_READY",
  MessageNotValid: "ERROR_MESSAGE_NOT_VALID"
} as const;

export type WebSocketSendStatus = ObjectValues<typeof WebSocketSendError>;

export type ThreadSendNewMessage = WebSocketMessage<WebSocketNewMessageData>;

export type ThreadNewMessageHydrated =
  WebSocketMessage<WebSocketNewMessageDataHydrated>;

export type ThreadIsTypingMessage =
  WebSocketMessage<WebSocketIsTypingMessageData>;

export type ThreadDeleteMessage = WebSocketMessage<WebSocketDeleteMessageData>;
export type ThreadEditMessage = WebSocketMessage<WebSocketEditMessageData>;

export type ThreadReadMessage = WebSocketMessage<WebSocketReadMessageData>;
export type ScormResetMessage =
  WebSocketMessage<WebSocketScormResetRequestData>;
export type AICourseGenSuccess = WebSocketMessage<WebSocketAICourseGenSuccess>;
export type AICourseGenFail = WebSocketMessage<WebSocketAICourseGenFail>;
export type NotificationMessage = WebSocketMessage<RealTimeNotificationModel>;

export type ThreadCreateReaction =
  WebSocketMessage<WebSocketMessageCreateReactionData>;
export type ThreadCreateReactionHydrated =
  WebSocketMessage<WebSocketMessageCreateReactionDataHydrated>;
export type ThreadDeleteReaction =
  WebSocketMessage<WebSocketMessageDeleteReactionData>;

export type TemplatingGroupCloningCompleted =
  WebSocketMessage<WebSocketTemplatingGroupCloningCompleted>;

export type ScormUpload = WebSocketMessage<WebSocketScormUploadData>;
