import React from "react";
import { FallbackPlaceholder } from "src/js/components/resources/FallbackPlaceholder";
import { FallbackResource } from "src/js/components/resources/FallbackPlaceholder/FallbackPlaceholder";
import {
  __RESOURCE_DOCUMENT_MIME__,
  __RESOURCE_SHEET_MIME__,
  __RESOURCE_SLIDE_MIME__
} from "src/js/settings/settingFileUploader";
import {
  OldExercise,
  OldResource,
  RESOURCE_DEPRECATED_TYPE,
  RESOURCE_POC_TYPE,
  RESOURCE_TYPE,
  Resource
} from "src/js/types";
import {
  Exercise,
  Image,
  ImageGallery,
  Link,
  Lti,
  Maps,
  Pdf,
  Scorm,
  TextEditor,
  VideoPlayer,
  Vimeo,
  Youtube
} from "src/legacy/models/resources";
import { __OFFICE_VIEWER__ } from "src/legacy/settings/settings";

const getContentFromResourceMimeType = async (
  resource: OldResource | Resource
) => {
  const publicUrl =
    "publicUrls" in resource
      ? resource.publicUrls[0]
      : "public_url" in resource
        ? resource.public_url
        : "";

  // Getting the mimeType from the url headers directly from S3
  const mimeType = await fetch(publicUrl, {
    method: "GET",
    mode: "cors"
  }).then(response => {
    return response.headers.get("Content-Type");
  });

  if (
    [
      ...__RESOURCE_DOCUMENT_MIME__,
      ...__RESOURCE_SLIDE_MIME__,
      ...__RESOURCE_SHEET_MIME__
    ].includes(mimeType)
  ) {
    return (
      <iframe
        className="resource__iframe"
        width="100%"
        src={`${__OFFICE_VIEWER__}?src=${encodeURIComponent(publicUrl)}`}
      />
    );
  }

  switch (mimeType) {
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
    case "image/gif":
    case "image/tiff":
      return <Image resource={resource} />;
    case "video/mp4":
    case "video/quicktime":
      return <VideoPlayer publicUrl={publicUrl} videoId={resource.id} />;
    case "application/pdf":
      return <Pdf id={resource.id} publicUrl={publicUrl} />;
    default:
      return (
        <FallbackPlaceholder url={publicUrl} type={FallbackResource.file} />
      );
  }
};

// TODO improve typings and fix the way we are instanciating the resources
export const getContentFromResource = ({
  resource,
  exercise,
  boardElementId,
  boardElementOwnerUuid,
  onClose
}: {
  resource?: OldResource | Resource;
  exercise?: OldExercise;
  boardElementId?: number;
  boardElementOwnerUuid?: string;
  onClose?: () => void;
}) => {
  switch (resource?.type || exercise.type) {
    // Deprecated?
    case RESOURCE_TYPE.VIMEO:
      return (
        <Vimeo resource={resource as OldResource & { vimeo_id: string }} />
      );
    // Deprecated?
    case RESOURCE_TYPE.YOUTUBE:
      return (
        <Youtube resource={resource as OldResource & { youtube_id: string }} />
      );
    case RESOURCE_POC_TYPE.LTI:
      return <Lti elementId={boardElementId} />;
    case RESOURCE_TYPE.LINK:
      return (
        <Link
          resource={resource}
          boardElementId={boardElementId}
          boardElementOwnerUuid={boardElementOwnerUuid}
        />
      );
    case RESOURCE_TYPE.SCORM:
      return (
        <Scorm
          // @ts-expect-error - TODO fix the error
          resource={resource}
          boardElementId={boardElementId}
          onClose={onClose}
        />
      );
    // Retro compatibility with depcrecated getReact()
    case RESOURCE_TYPE.IMAGE_GALLERY:
    case RESOURCE_DEPRECATED_TYPE.IMAGEGALLERY:
      return (
        <ImageGallery
          resource={
            resource as (OldResource | Resource) & { images: OldResource[] }
          }
        />
      );
    case RESOURCE_TYPE.FILE:
    case RESOURCE_TYPE.DROPBOX:
    case RESOURCE_TYPE.GOOGLE_DRIVE:
    case RESOURCE_TYPE.DOCUMENT:
    case RESOURCE_TYPE.SLIDESHOW:
    case RESOURCE_TYPE.SPREADSHEET:
    case RESOURCE_DEPRECATED_TYPE.ONEDRIVE:
      return getContentFromResourceMimeType(resource);
    case RESOURCE_DEPRECATED_TYPE.MAPS:
      return (
        <Maps
          resource={
            resource as OldResource & {
              maps_longitude: string;
              maps_latitude: string;
              maps_zoom: string;
            }
          }
        />
      );
    case RESOURCE_TYPE.EXERCISE:
    case RESOURCE_TYPE.EXAMINATION:
    case RESOURCE_DEPRECATED_TYPE.ASSIGNMENT:
      return <Exercise exercise={exercise} />;
    case RESOURCE_TYPE.TEXT:
      return <TextEditor resource={resource} />;
    default:
      if (exercise) {
        return <Exercise exercise={exercise} />;
      }
      let url;
      if ("public_url" in resource) {
        url = resource.public_url;
      } else {
        url = resource.publicUrls[0];
      }
      return (
        <iframe
          title={resource.name}
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          src={url}
        />
      );
  }
};
