export const UsetifulId = {
  Toolbar: "toolbar",
  Sidebar: "sidebar",
  SidebarDashboardLink: "sidebarDashboardLink",
  SidebarGroupList: "sidebarGroupList",
  SidebarChatList: "sidebarChatList",
  TopHeaderContainer: "topheader-container",
  ToolbarHomeLink: "toolbarHomeLink",
  GroupsEmptyStateCreateNewGroupCta: "groupsEmptyStateCreateNewGroupCta",
  SpaceHomeCreateGroupCta: "spaceHomeCreateGroupCta",
  DashboardSecondaryHeader: "dashboardSecondaryHeader",
  GroupSecondaryHeader: "groupSecondaryHeader",
  GroupSecondaryHeaderMembersBlock: "groupSecondaryHeaderMembersBlock",
  GroupSecondaryHeaderInviteCta: "groupSecondaryHeaderInviteCta",
  GroupOptionsDotsMenu: "groupOptionsDotsMenu",
  PermissionRolesTable: "PermissionRolesTable",
  GroupMembersSearchbar: "GroupMembersSearchbar",
  ToolbarActiveSpaceDropdownButton: "toolbarActiveSpaceDropdownButton",
  ToolbarDemoSpaceElement: "toolbarDemoSpaceElement",
  ToolbarActiveSpaceAndActions: "toolbarActiveSpaceAndActions",
  InvitationModalEmailSection: "InvitationModalEmailSection",
  InvitationModalLinklSection: "InvitationModalLinklSection",
  InvitationModalImportSection: "InvitationModalImportSection",
  ImportFromGroupsSelection: "ImportFromGroupsSelection",
  ImportFromCsvSelection: "ImportFromCsvSelection",
  InviteMembersDashboard: "InviteMembersDashboard",
  StaffPermissionUpdate: "StaffPermissionUpdate"
} as const;
