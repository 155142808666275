import getUserClient from "../modules/connection";
import { api_cities, api_countries, getVersionedApiUrl } from "./apiUrls";

// v3/countries
export type CountryDTO = {
  id: string;
  name: string;
  code: string;
};

// v3/countries/{countryId}/cities
export type CityDTO = {
  id: string;
  name: string;
  country: CountryDTO;
};

export const fetchCountries = async () => {
  return getUserClient()
    .get<CountryDTO[]>(getVersionedApiUrl(api_countries(), "v3"))
    .then(({ data }) => {
      return data;
    });
};
export const fetchCities = async ({ countryId }: { countryId: string }) => {
  return getUserClient()
    .get<CityDTO[]>(getVersionedApiUrl(api_cities({ countryId }), "v3"))
    .then(({ data }) => {
      return data;
    });
};
