import { fetchLoggedUser } from "src/js/repository/userRepository";
import { User } from "src/js/types";
import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { userQueryKey } from "./user.query";

export const useMeQuery = (options?: UseQueryOptions<User, Error, User>) => {
  const { data: user, ...otherData } = useQuery(
    userQueryKey.me,
    fetchLoggedUser,
    options
  );

  return {
    data: user,
    ...otherData
  };
};

export const useMeQueryCached = (
  options?: UseQueryOptions<User, Error, User>
) => {
  return useMeQuery({
    ...options,
    staleTime: Infinity
  });
};
