/* eslint-disable react/no-danger */
import { Heading, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { Trans, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { __SUPPORT_MAIL__ } from "src/js/settings/settingsGeneral";
import * as S from "../StickyInfoBanner.styles";
import { BannerProps } from "../types";

/**
 * @name StickyInfoBannerDesktop
 *
 * @description StickyInfoBannerDesktop component.
 */

const StickyInfoBannerDesktop = ({
  translations,
  withDescriptionButton,
  withCtaButton,
  withFooterLink,
  footerLink,
  translationCtaButtonProps,
  onClickCtaButton
}: BannerProps) => {
  const { whiteTheme, colors } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isStickyBannerOpen, toggleStickyBanner, isStickyBannerVisible }
  } = useStores();

  const handleIsOpen = () => {
    toggleStickyBanner();
  };

  const transition = {
    type: "easeOut",
    damping: 100,
    stiffness: 100
  };

  return (
    <AnimatePresence>
      {isStickyBannerVisible ? (
        <S.InfoBanner>
          <S.StickyInfoBannerContainer>
            <S.StickyInfoBannerOpened
              initial="closed"
              animate={isStickyBannerOpen ? "open" : "closed"}
              variants={{
                open: { height: 224, visibility: "visible", opacity: 1 },
                closed: { height: 8, visibility: "hidden", opacity: 0 }
              }}
              transition={transition}
            >
              <Heading level="5">{translate(translations.titleOpened)}</Heading>
              <div>
                <Trans
                  i18nKey={translations.textOpened}
                  values={{
                    support_mail: __SUPPORT_MAIL__
                  }}
                />
              </div>
              {withDescriptionButton && (
                <S.StyledLink
                  // whiteTheme itself doesn't contain the colors property,
                  // Link from DS is wrapped on ThemeProvider and override the internal one
                  theme={{ ...whiteTheme, colors: { ...colors } }}
                  target="_blank"
                  href={translate(translations.ctaLink)}
                >
                  {translate(translations.ctaOpened)}
                </S.StyledLink>
              )}
              {withCtaButton && (
                <S.SubmitButton
                  variant="primary"
                  theme={whiteTheme}
                  onClick={onClickCtaButton}
                >
                  {translate(translations.ctaButton, {
                    ...translationCtaButtonProps
                  })}
                </S.SubmitButton>
              )}
              {withFooterLink && (
                <S.InfoContainer
                  onClick={() => window.open(footerLink, "_blank")}
                >
                  <Text type="ctaTertiary">
                    {translate(translations.footerLink)}
                  </Text>
                  <Icon icon="arrowRight" />
                </S.InfoContainer>
              )}
            </S.StickyInfoBannerOpened>
          </S.StickyInfoBannerContainer>
          <S.StickyInfoBannerClosedContainer onClick={handleIsOpen}>
            <S.StickyInfoBannerClosed
              initial={{ display: "flex" }}
              animate={isStickyBannerOpen ? "open" : "closed"}
              transition={transition}
              variants={{
                open: { display: "none" },
                closed: { display: "flex" }
              }}
            >
              <S.ContentInfoBannerClosed marginTop="-4px">
                <Heading level="6">
                  {translate(translations.textClosed)}
                </Heading>
                <S.IconBannerClosed icon="help" />
              </S.ContentInfoBannerClosed>
            </S.StickyInfoBannerClosed>
          </S.StickyInfoBannerClosedContainer>
          <S.CloseInfoBanner
            animate={isStickyBannerOpen ? "open" : "closed"}
            transition={transition}
            variants={{
              open: { display: "flex" },
              closed: { display: "none" }
            }}
            onClick={handleIsOpen}
          >
            <Icon icon="close" />
          </S.CloseInfoBanner>
        </S.InfoBanner>
      ) : null}
    </AnimatePresence>
  );
};

export default observer(StickyInfoBannerDesktop);
