import {
  resetAllSCORMData,
  resetSpecificSCORMData,
  resetUserSCORMData
} from "src/js/repository/scormRepository";
import { TranslationKeys } from "src/js/translation";
import { ResetContext, ResetContextType, ResetDataAlertProps } from "./types";

export const callResetFunction = async <T extends ResetContextType>(
  context: T,
  groupId: number,
  data: Omit<ResetDataAlertProps<T>, "context">
) => {
  switch (context) {
    case ResetContext.AllUserAllScorm:
      return resetAllSCORMData({ groupId });
    case ResetContext.SingleUserAllScormGeneralPage:
    case ResetContext.SingleUserAllScorm:
      return resetUserSCORMData({ groupId, userUuid: data.userUuid });
    case ResetContext.SingleUserSpecificScorm:
      return resetSpecificSCORMData({
        groupId,
        userUuid: data.userUuid,
        boardElementId: data.scormData.boardElementId
      });
    default:
      return null;
  }
};

export const translations: Record<ResetContextType, TranslationKeys> = {
  [ResetContext.AllUserAllScorm]: "reset_all_user_scorm_record_data_alert_text",
  [ResetContext.SingleUserAllScormGeneralPage]:
    "reset_single_user_scorm_record_general_page_data_alert_text",
  [ResetContext.SingleUserAllScorm]:
    "reset_single_user_scorm_record_data_alert_text",
  [ResetContext.SingleUserSpecificScorm]:
    "reset_single_user_specific_scorm_record_data_alert_text"
};
