import { Text } from "@ds_universal/Text";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 24px;
`;

export const Subtitle = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const MetricCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`;

export const RightAligner = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
