import { useEffect } from "react";
import { useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { checkAlias, saveAlias } from "src/js/repository/groupRepository";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { ObjectValues } from "src/js/types";

const GroupAliasError = {
  AlreadyTaken: "already-taken",
  TooShort: "too-short"
};

export type GroupAliasErrorType = ObjectValues<typeof GroupAliasError>;

const groupAliasErrorMessages: Record<GroupAliasErrorType, TranslationKeys> = {
  [GroupAliasError.AlreadyTaken]: "group_alias_edit_error_already_taken",
  [GroupAliasError.TooShort]: "group_alias_edit_error_too_short"
};

const useLinkInvite = () => {
  const { translate } = useTranslation();
  const {
    GroupStore: {
      activeGroup: { id },
      updatePublicInvitation,
      fetchPublicInvitation,
      updateGroupAlias
    }
  } = useStores();

  useEffect(() => {
    fetchPublicInvitation(id);
  }, []);

  const updateGroupAliasHandler = async ({
    groupAlias
  }: {
    groupAlias: string;
  }) => {
    try {
      if (groupAlias.length <= 1) {
        throw new Error(GroupAliasError.TooShort);
      }
      const { availability } = await checkAlias(id, groupAlias);
      if (!availability) {
        throw new Error(GroupAliasError.AlreadyTaken);
      }
      const response: { alias?: string } = await saveAlias(id, groupAlias);
      updateGroupAlias(response?.alias);
      showToastSuccess({
        str: translate("group_alias_edit_success")
      });
    } catch (error) {
      if (Object.values(GroupAliasError).includes(error?.message)) {
        const errorMessage = error.message as GroupAliasErrorType;
        showToastError({
          str: translate(groupAliasErrorMessages[errorMessage])
        });
      } else {
        showToastError({
          str: extractErrorMessage(error, translate("general_error_retry"))
        });
      }
      throw error;
    }
  };

  const togglePublicInvite = async () => {
    try {
      await updatePublicInvitation(id);
    } catch (error) {
      showToastError({ str: error.message });
    }
  };

  return { updateGroupAliasHandler, togglePublicInvite };
};

export default useLinkInvite;
