import {
  fetchSpaceGroupsPaginated,
  fetchSpaceSelfJoinableGroups
} from "src/js/repository/spacesRepository";
import {
  GROUPS_HOME_LIST_LIMIT,
  SELF_JOINABLE_GROUP_LIST_LIMIT
} from "src/js/settings/settingsPagination";
import { useQuery } from "../hooks";

// Query keys

const GROUPS_KEY_ALL = "groups";

const selfJoinableGroups = ({
  spaceId,
  offset = 0,
  limit = SELF_JOINABLE_GROUP_LIST_LIMIT,
  name = ""
}: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => [GROUPS_KEY_ALL, "selfJoinable", spaceId, offset, limit, name];

const groupsPaginated = ({
  spaceId,
  offset = 0,
  limit = GROUPS_HOME_LIST_LIMIT,
  name = ""
}: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => [GROUPS_KEY_ALL, "groupsPaginated", spaceId, offset, limit, name];

export const groupsQueryKey = {
  all: [GROUPS_KEY_ALL] as const,
  selfJoinableGroups,
  groupsPaginated
} as const;

// Hooks

export const useSelfJoinableGroups = (params: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => {
  return useQuery(
    groupsQueryKey.selfJoinableGroups(params),
    () => fetchSpaceSelfJoinableGroups(params),
    { refetchOnWindowFocus: false }
  );
};

export const useGroupsPaginated = (params: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => {
  return useQuery(
    groupsQueryKey.groupsPaginated(params),
    () => fetchSpaceGroupsPaginated(params),
    { refetchOnWindowFocus: false }
  );
};
