import { Box, Text } from "@arcadia/design-system";
import React from "react";
import {
  __FORM_CONTRACT__,
  __FORM_REGISTRATION__
} from "src/js/settings/settingsFormValidation";
import {
  __PRIVACY_LINK__,
  __TERMS_LINK__
} from "src/js/settings/settingsGeneral";
import { Trans, useTranslation } from "src/js/translation";
import { InputCheckboxValidatedPrimary } from "src/js/components/forms/InputCheckboxValidatedPrimary";
import * as S from "./Terms.styles";

const Terms = ({
  register,
  watchTerms,
  watchUnderage,
  errors,
  contract,
  isTutor = false
}) => {
  const { translate } = useTranslation();
  const privacy_link = __PRIVACY_LINK__;
  const terms_link = translate({ text: __TERMS_LINK__ });

  if (watchUnderage) {
    return (
      <div className="margin-top-10">
        <Trans i18nKey="underage_check" values={{ terms_link, privacy_link }} />
      </div>
    );
  }

  const termsLabel = contract ? (
    <Trans i18nKey="terms4_label" values={{ terms_link }} />
  ) : (
    <Trans i18nKey="terms_label" values={{ terms_link, privacy_link }} />
  );

  const weschoolContactLabel = (
    <Trans
      i18nKey={
        isTutor ? "parent_terms_commercial_label" : "terms_commercial_label"
      }
      values={{ privacy_link }}
    />
  );

  const thirdPartContactLabel = (
    <Trans
      i18nKey={
        isTutor
          ? "parent_terms_commercial_third_label"
          : "terms_commercial_third_labe"
      }
      values={{ privacy_link }}
    />
  );

  return (
    <>
      <Box margin="16px 0px">
        {contract ? (
          <Text type="formSmallCaption">
            {translate("treatment_responsability")}
          </Text>
        ) : (
          !isTutor && (
            <Text type="formSmallCaption">
              {translate({ text: "company_header" })}
            </Text>
          )
        )}
      </Box>
      <S.CheckboxContainer>
        {contract ? (
          <InputCheckboxValidatedPrimary
            name="terms4"
            label={termsLabel}
            register={register}
            validation={__FORM_CONTRACT__.terms4}
            errorMessage={errors?.terms4?.message}
            id="terms4"
          />
        ) : (
          <InputCheckboxValidatedPrimary
            name="terms"
            label={termsLabel}
            register={register}
            validation={__FORM_REGISTRATION__.terms}
            errorMessage={errors?.terms?.message}
            id="terms"
          />
        )}
      </S.CheckboxContainer>
      {contract && watchTerms && <div className="separator-horizontal" />}
      {contract && watchTerms && (
        <Text type="formSmallCaption">
          <Box margin="16px 0px">
            <Trans i18nKey="label_treatment" values={{ privacy_link }} />
          </Box>
        </Text>
      )}
      {watchTerms && (
        <S.CheckboxContainer>
          <InputCheckboxValidatedPrimary
            name="weschool_contact"
            register={register}
            label={weschoolContactLabel}
            id="weschool_contact"
            validation={null}
          />
          <InputCheckboxValidatedPrimary
            name="third_part_contact"
            register={register}
            label={thirdPartContactLabel}
            id="third_part_contact"
            validation={null}
          />
        </S.CheckboxContainer>
      )}
      <Box className="margin-top-8 margin-bottom-8">
        <Text type="formSmallCaption">{translate("terms_explanation_1")}</Text>
      </Box>
      {watchTerms && (
        <Text type="formSmallCaption">
          <Trans i18nKey="terms_explanation_2" values={{ privacy_link }} />
        </Text>
      )}
    </>
  );
};

export default Terms;
