import { ObjectValues } from "src/js/types";

export const ResetContext = {
  AllUserAllScorm: "AllUserAllScorm",
  SingleUserAllScormGeneralPage: "SingleUserAllScormGeneralPage",
  SingleUserAllScorm: "SingleUserAllScorm",
  SingleUserSpecificScorm: "SingleUserSpecificScorm"
} as const;

export type ResetContextType = ObjectValues<typeof ResetContext>;

type ContextProps = {
  [ResetContext.AllUserAllScorm]: {
    username?: never;
    userUuid?: never;
    scormData?: never;
    context: "AllUserAllScorm";
  };
  [ResetContext.SingleUserAllScormGeneralPage]: {
    username: string;
    userUuid: string;
    scormData?: never;
    context: "SingleUserAllScormGeneralPage";
  };
  [ResetContext.SingleUserAllScorm]: {
    username?: never;
    userUuid: string;
    scormData?: never;
    context: "SingleUserAllScorm";
  };
  [ResetContext.SingleUserSpecificScorm]: {
    username?: never;
    userUuid: string;
    scormData: {
      boardElementId: number;
      scormName: string;
    };
    context: "SingleUserSpecificScorm";
  };
};

export type ResetDataAlertProps<T extends ResetContextType> =
  T extends keyof ContextProps ? ContextProps[T] : never;
