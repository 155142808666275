import { PublicProfileSettings } from "src/js/types";
import { fetchPublicProfileSettings } from "src/js/repository/publicProfileRepository";
import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { publicProfileQueryKey } from "./publicProfile.query";

export const usePublicProfileSettings = (
  options?: UseQueryOptions<PublicProfileSettings, Error, PublicProfileSettings>
) => {
  const { data: publicProfileSettings, ...otherData } = useQuery(
    publicProfileQueryKey.settings,
    fetchPublicProfileSettings,
    options
  );

  return {
    data: publicProfileSettings,
    ...otherData
  };
};

export const usePublicProfileSettingsCached = (
  options?: UseQueryOptions<PublicProfileSettings, Error, PublicProfileSettings>
) => {
  return usePublicProfileSettings({
    ...options,
    staleTime: Infinity
  });
};
