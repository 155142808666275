import {
  Dropdown,
  DropdownMultiple,
  SearchBar,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { FilterBar } from "src/js/components/FilterBar";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import {
  ROLE_FILTER_OPTIONS,
  SORT_FILTER_OPTIONS
} from "./SpacesUsersFilters.const";
import * as S from "./SpacesUsersFilters.styles";

// TODO fix missing infinite scroll in group dropdown

const SpacesUsersFilters = ({
  roleFilterSelected = [],
  groupFilterSelected = [],
  sortFilterSelected = [],
  searchDefaultValue,
  setFilter,
  groupList = [],
  removeStatusFilter,
  removeAllStatusFilters
}) => {
  const [queryString, setQueryString] = useState(searchDefaultValue);
  const [filterGroupString, setFilterGroupString] = useState("");
  const { greyTheme } = useTheme();

  useEffect(() => {
    setQueryString(searchDefaultValue);
  }, [searchDefaultValue]);

  const onSubmitSearch = useCallback(event => {
    event.preventDefault();
    setFilter(queryString, "searchQuery");
  });

  const onChangeSearch = useCallback(value => {
    setQueryString(value);
  });

  return (
    <S.TopFiltersContainer>
      <Row justify={{ md: "space-between" }}>
        <Col xs={12} sm={6}>
          <Translator>
            {({ translate }) => {
              return (
                <S.SearchbarWrapper>
                  <SearchBar
                    theme={greyTheme}
                    label="searchQuery"
                    value={queryString}
                    onChange={event => {
                      onChangeSearch(event.target.value);
                    }}
                    placeholder={translate("user_or_mail_search_placeholder")}
                    onClick={onSubmitSearch}
                  />
                </S.SearchbarWrapper>
              );
            }}
          </Translator>
        </Col>
      </Row>
      <S.RowWithMargin />
      <Row>
        <Col sm={8}>
          <Row align={{ xs: "center" }} style={{ flexWrap: "nowrap" }}>
            <S.Label>
              <Text type="formSubtitle">
                <Translate text="filter_by_colon" />
              </Text>
            </S.Label>
            <S.FilterWrapper>
              <S.FixedWrapper>
                <Translator>
                  {({ translate }) => {
                    return (
                      <S.DropdownWrapper>
                        <DropdownMultiple
                          theme={greyTheme}
                          hideButtonTags
                          optionsList={groupList
                            .filter(({ value }) =>
                              value
                                .toLowerCase()
                                .includes(filterGroupString.toLowerCase())
                            )
                            .map(({ key, value }) => ({
                              id: key,
                              label: value
                            }))}
                          selectedOptionIds={
                            groupList.length === 0 ? [] : groupFilterSelected
                          }
                          setSelectedOptionIds={value =>
                            setFilter(value, "selectedGroups")
                          }
                          placeholder={translate("groups")}
                          searchPlaceholder={translate("search_in_n_groups", {
                            nGroups: groupList.length
                          })}
                          scrollableListId="space-group-filter-dropdown-multiple"
                          searchable
                          searchQuery={filterGroupString}
                          onSearchFunction={e => {
                            setFilterGroupString(e.target.value);
                          }}
                        />
                      </S.DropdownWrapper>
                    );
                  }}
                </Translator>
              </S.FixedWrapper>
              <S.FixedWrapper>
                <Translator>
                  {({ translate }) => (
                    <S.DropdownWrapper>
                      <DropdownMultiple
                        theme={greyTheme}
                        hideButtonTags
                        optionsList={ROLE_FILTER_OPTIONS.map(
                          ({ key, value }) => ({
                            id: key,
                            label: translate(value)
                          })
                        )}
                        selectedOptionIds={roleFilterSelected}
                        setSelectedOptionIds={value =>
                          setFilter(value, "selectedRoles")
                        }
                        placeholder={translate("spaces_users_filter_role")}
                        scrollableListId="space-role-filter-dropdown-multiple"
                      />
                    </S.DropdownWrapper>
                  )}
                </Translator>
              </S.FixedWrapper>
            </S.FilterWrapper>
          </Row>
        </Col>
        <Col xs={12} sm={4}>
          <S.RightAlignWrapper>
            <Translator>
              {({ translate }) => (
                <Dropdown
                  id="selectOrderFilterId"
                  theme={greyTheme}
                  optionsList={SORT_FILTER_OPTIONS.map(({ key, value }) => ({
                    id: key,
                    label: translate(value)
                  }))}
                  selectedOptionId={sortFilterSelected[0]}
                  setSelectedOptionId={value =>
                    setFilter([value], "selectedOrder")
                  }
                  placeholder={translate("sort_by")}
                />
              )}
            </Translator>
          </S.RightAlignWrapper>
        </Col>
      </Row>
      <Translator>
        {({ translate }) => (
          <FilterBar
            selectedFiltersKeys={[
              ...roleFilterSelected,
              ...groupFilterSelected
            ]}
            options={[...ROLE_FILTER_OPTIONS, ...groupList].map(
              ({ key, value }) => ({
                id: key,
                label: translate(value)
              })
            )}
            removeFilter={removeStatusFilter}
            removeAllFilters={removeAllStatusFilters}
          />
        )}
      </Translator>
    </S.TopFiltersContainer>
  );
};

export default observer(SpacesUsersFilters);
