export const IMAGE_MIME = ["image/jpeg", "image/png", "image/gif"];

export const __GROUP_COVER_SIZE__ = 52428800;
export const __GROUP_COVER_MIME__ = IMAGE_MIME;

export const __RESOURCE_DOCUMENT_LIMITS__ = 10485760;
export const __RESOURCE_DOCUMENT_MIME__ = [
  ".doc",
  ".dot",
  ".docx",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const __RESOURCE_GALLERY_LIMITS__ = 52428800;
export const __RESOURCE_GALLERY_MIME__ = IMAGE_MIME;
export const __RESOURCE_PDF_LIMITS__ = 52428800;
export const __RESOURCE_PDF_MIME__ = [".pdf", "application/pdf"];
export const __RESOURCE_SHEET_LIMITS__ = 5242880;
export const __RESOURCE_SHEET_MIME__ = [
  ".xls",
  ".xlt",
  ".xla",
  ".xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];
export const __RESOURCE_SLIDE_LIMITS__ = 10485760;
export const __RESOURCE_SLIDE_MIME__ = [
  ".pps",
  ".ppt",
  ".pptx",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];
export const __RESOURCE_UPLOAD_LIMITS__ = 52428800;
export const __RESOURCE_UPLOAD_MIME__ = "*";

export const __SCORM_UPLOAD_STANDARD_LIMITS__ = 524288000;
export const __SCORM_UPLOAD_MIME__ = [
  ".zip",
  "application/zip",
  "application/x-zip-compressed",
  "application/x-zip",
  "application/x-winzip"
];

export const __CSV_UPLOAD_LIMITS__ = 52428800;
export const __CSV_UPLOAD_MIME__ = [".csv", "text/csv"];

export const __VIDEO_UPLOAD_MIME__ = ["video/mp4", "video/quicktime"];

export const __USER_AVATAR_SIZE__ = 10485760;
export const __USER_AVATAR_MIME__ = IMAGE_MIME;

export const __SHARE_PREVIEW_SIZE__ = 52428800;
export const __SHARE_PREVIEW_MIME__ = IMAGE_MIME;

export const __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ =
  Number(process.env.__TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__) || 100000000;
