import { PageNav, Text } from "@arcadia/design-system";
import React, { useState } from "react";
import { GroupPanel } from "src/js/components/GroupPanel";
import { __SPACE_GROUPS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { SpacesGroupType } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./SpacesGroupsList.styles";

type SpacesGroupsListProps = {
  showTooltipFirstTemplate: boolean;
  filteredGroups: SpacesGroupType[];
  totalGroups: number;
  currentOffset: number;
  toggleActivation: (selectedGroup) => void;
  cloneGroup: (groupId) => void;
  showTeachers: (teachers, groupName) => void;
  changePage: (page) => void;
};

const SpacesGroupsList = ({
  showTooltipFirstTemplate,
  filteredGroups,
  totalGroups,
  currentOffset,
  toggleActivation = () => {},
  cloneGroup = () => {},
  showTeachers = () => {},
  changePage = () => {}
}: SpacesGroupsListProps) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const [hideTooltipFirstTemplate, setHideTooltipFirstTemplate] =
    useState(false);
  return (
    <>
      <S.GroupListHeader>
        <S.HeaderContent>
          <S.HeaderColumn large>
            <Text type="label">
              {translate("dashboard_groups_header_name")}
            </Text>
          </S.HeaderColumn>
          <S.HeaderColumn>
            <Text type="label">{translate("teachers")}</Text>
          </S.HeaderColumn>
          <S.HeaderColumn small>
            <Text type="label">{translate("status")}</Text>
          </S.HeaderColumn>
          <S.HeaderColumn small>
            <Text type="label">{translate("users")}</Text>
          </S.HeaderColumn>
          <S.HeaderColumn>
            <Text type="label">
              {translate("dashboard_groups_header_properties")}
            </Text>
          </S.HeaderColumn>
        </S.HeaderContent>
        <S.ActionColumn>
          <Text type="label">{translate("actions")}</Text>
        </S.ActionColumn>
      </S.GroupListHeader>
      {filteredGroups.map((groupObject, index) => (
        <GroupPanel
          showTooltipFirstTemplate={
            showTooltipFirstTemplate && index === 0 && !hideTooltipFirstTemplate
          }
          onClickDotsMenu={() => {
            setHideTooltipFirstTemplate(true);
          }}
          key={groupObject?.group?.id}
          groupObject={groupObject}
          toggleActivation={toggleActivation}
          cloneGroup={cloneGroup}
          showTeachers={showTeachers}
        />
      ))}
      <S.PageNavWrapper>
        <PageNav
          theme={greyTheme}
          totalRecords={totalGroups}
          pageLimit={__SPACE_GROUPS_LIST_LIMIT__}
          startingOffset={currentOffset}
          onPageChanged={changePage}
          label={{
            previous: translate({ text: "prev" }),
            next: translate({ text: "next" })
          }}
        />
      </S.PageNavWrapper>
    </>
  );
};

export default SpacesGroupsList;
