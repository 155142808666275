import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import { useStores } from "src/js/hooks";
import * as S from "./Modal.styles";
import {
  __BACKDROP_PORTAL_DEFAULT_NAME__,
  __MODAL_PORTAL_DEFAULT_NAME__,
  backdropStates,
  modalContentStates
} from "./Modal.utils";

const Modal = () => {
  const {
    ModalStore: { body, show, options, customClose, setAnimating }
  } = useStores();
  const modalPortalName = options?.portalName || __MODAL_PORTAL_DEFAULT_NAME__;

  let modalPortal = document.getElementById(modalPortalName);
  let backdropPortal = document.getElementById(
    __BACKDROP_PORTAL_DEFAULT_NAME__
  );

  if (!modalPortal) {
    modalPortal = document.createElement("div");
    modalPortal.setAttribute("id", modalPortalName);
    document.body.appendChild(modalPortal);
  }

  if (!backdropPortal) {
    backdropPortal = document.createElement("div");
    backdropPortal.setAttribute("id", __BACKDROP_PORTAL_DEFAULT_NAME__);
    document.body.appendChild(backdropPortal);
  }

  if (options?.customStyle) {
    modalPortal.setAttribute("style", options.customStyle);
  } else {
    modalPortal.removeAttribute("style");
  }

  const handleEscape = useCallback(
    event => {
      if (event.keyCode === 27) customClose();
    },
    [customClose]
  );

  useEffect(() => {
    if (!show) return () => {};
    document.addEventListener("keydown", handleEscape, false);
    document.body.classList.add("no-scroll");
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
      document.body.classList.remove("no-scroll");
    };
  }, [show, handleEscape]);

  const onAnimationStart = useCallback(() => {
    setAnimating(true);
  }, []);

  const onAnimationComplete = useCallback(() => {
    setAnimating(false);
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {show ? (
            <S.Backdrop
              key="backdrop"
              onClick={customClose}
              initial={backdropStates.initial}
              animate={backdropStates.animate}
              exit={backdropStates.exit}
              transition={backdropStates.transition}
            />
          ) : null}
        </AnimatePresence>,
        backdropPortal
      )}
      {ReactDOM.createPortal(
        <AnimatePresence>
          {show ? (
            <S.ModalContent
              onAnimationStart={onAnimationStart}
              onAnimationComplete={onAnimationComplete}
              key="modal-content"
              initial={modalContentStates.initial}
              animate={modalContentStates.animate}
              exit={modalContentStates.exit}
              transition={modalContentStates.transition}
            >
              {body()}
            </S.ModalContent>
          ) : null}
        </AnimatePresence>,
        modalPortal
      )}
    </>
  );
};

export default observer(Modal);
