import { FormValidatorMap } from "src/js/types";
import { S3Video } from "src/js/settings/settingsVideo";
import { TranslationKeys } from "src/js/translation";
import { AddLinkFields, LinkType } from "./AddLinkResource.types";
import { RESOURCE_PICKER_ITEMS } from "../../ResourcePicker.const";

export const linkRegex =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const getFaviconFromUrl = ({ url }: { url: string }) => {
  const youtubeRegex =
    /(https?:\/\/)?:\/\/(?:www\.)?(youtube\.com|youtu\.be)\/?(.*?)/;
  const gDocsRegex = /https:\/\/docs\.google\.com\/document\/d\/(.*?)\/.*?/g;
  const gSheetRegex =
    /https:\/\/docs\.google\.com\/spreadsheets\/d\/(.*?)\/.*?/g;
  const gSlidesRegex =
    /https:\/\/docs\.google\.com\/presentation\/d\/(.*?)\/.*?/g;
  const gMapsRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:maps\.)?google\.(?:com?\/?\w*\?.*?|\w{2,3}\/?\w*)(?:[\w-]+\/){1,2}(?:place|search|dir)\/[^/!]+|https?:\/\/goo\.gl\/maps\/\w+/g;

  const vimeoRegex =
    /https?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/g;

  if (youtubeRegex.test(url)) return "picker-youtube";
  if (gDocsRegex.test(url)) return "picker-googledoc";
  if (gMapsRegex.test(url)) return "picker-googlemaps";
  if (gSheetRegex.test(url)) return "picker-googlesheet";
  if (gSlidesRegex.test(url)) return "picker-googleslides";
  if (vimeoRegex.test(url)) return "picker-vimeo-single-char";

  return null;
};

export const sanitizeIfVimeoUrl = ({ url }: { url: string }) => {
  const urlToSanitize: string = url;
  const vimeoRegex =
    /https?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/g;
  if (vimeoRegex.test(urlToSanitize)) {
    const parsed = urlToSanitize.match(
      /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
    );
    return `https://player.vimeo.com/video/${parsed[1]}`;
  }
  return urlToSanitize;
};

export const getTranslationFromLinkType = (
  linkType: LinkType
): TranslationKeys => {
  switch (linkType) {
    case RESOURCE_PICKER_ITEMS.LINK:
      return "add_link_modal_video_caption";
    case RESOURCE_PICKER_ITEMS.GDOCS:
      return "add_link_modal_video_caption_gsuite";
    case RESOURCE_PICKER_ITEMS.GSHEET:
      return "add_link_modal_video_caption_gsuite";
    case RESOURCE_PICKER_ITEMS.GSLIDES:
      return "add_link_modal_video_caption_gsuite";
    case RESOURCE_PICKER_ITEMS.VIMEO:
      return "add_link_modal_video_caption_vimeo";
    case RESOURCE_PICKER_ITEMS.YOUTUBE:
      return "add_link_modal_video_caption_youtube";
    default:
      return "add_link_modal_video_caption";
  }
};

export const __ADD_LINK_RESOURCE_SETTINGS__: FormValidatorMap<AddLinkFields> = {
  linkInput: {
    required: "add_link_resource_required_error",
    pattern: {
      value: linkRegex,
      message: "add_link_resource_type_error"
    }
  }
};

export const linkTypeToS3Video = (linkType: LinkType) => {
  switch (linkType) {
    case RESOURCE_PICKER_ITEMS.LINK:
      return S3Video.EmbedPrivacyGeneral;
    case RESOURCE_PICKER_ITEMS.YOUTUBE:
      return S3Video.EmbedPrivacyYoutube;
    case RESOURCE_PICKER_ITEMS.GDOCS:
      return S3Video.EmbedPrivacyGDocs;
    case RESOURCE_PICKER_ITEMS.GSHEET:
      return S3Video.EmbedPrivacyGDocs;
    case RESOURCE_PICKER_ITEMS.GSLIDES:
      return S3Video.EmbedPrivacyGDocs;
    case RESOURCE_PICKER_ITEMS.VIMEO:
      return S3Video.EmbedPrivacyVimeo;
    default:
      return S3Video.EmbedPrivacyGeneral;
  }
};
