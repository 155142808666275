// Query keys
const __SPACES_KEY_ALL__ = "spaces";

export const spacesQueryKey = {
  all: [__SPACES_KEY_ALL__],
  id: ({ spaceId }: { spaceId: string }) => [
    ...spacesQueryKey.all,
    { spaceId }
  ],
  templates: ({ spaceId }: { spaceId: string }) => [
    ...spacesQueryKey.id({ spaceId }),
    "templates",
    spaceId
  ]
} as const;
