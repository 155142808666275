import React, { useMemo } from "react";
import { Box } from "@arcadia/design-system";
import { useForm } from "react-hook-form";
import { TranslationKeys } from "src/js/translation/translated.types";
import { translateString } from "src/js/translation/TranslationProvider";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { changePassword } from "src/js/repository/userRepository";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useMutation, useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { PasswordStrengthIndicator } from "src/js/components/forms/PasswordStrenghtIndicator";
import { checkPasswordStrength } from "src/js/modules/formFunction";
import { PasswordInput } from "src/js/components/PasswordInput";
import { __CHANGE_PASSWORD_SCHEMA__ } from "./utils";
import { ModalBody } from "../Modal";

type ChangePasswordFormProps = {
  newPassword: string;
  confirmNewPassword: string;
  oldPassword: string;
};

const UserAccountChangePasswordModal = () => {
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const { register, errors, handleSubmit, formState, watch } =
    useForm<ChangePasswordFormProps>({
      mode: "onChange"
    });

  const newPass = watch("newPassword");

  const strength = useMemo(() => {
    return checkPasswordStrength(newPass || "");
  }, [newPass]);

  const { mutate: changePasswordMutation } = useMutation(
    async (data: ChangePasswordFormProps) => {
      if (data.newPassword !== data.confirmNewPassword) {
        throw new Error("u_password_not_match");
      }

      try {
        await changePassword(data.newPassword, data.oldPassword);
      } catch (error) {
        throw new Error("error_u_old_password_not_match");
      }
    },
    {
      onSuccess: () => {
        showToastSuccess({
          str: translateString({ text: "password_update_success" })
        });
        closeModal();
      },
      onError(error) {
        showToastError({
          str: translate(error.message as TranslationKeys)
        });
      }
    }
  );

  const onSubmit = (formData: ChangePasswordFormProps) => {
    changePasswordMutation(formData);
  };
  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{ title: translate("change_password") }}
      mobileProps={{ closeLabel: translate("close") }}
    >
      <ModalBody
        onSubmit={handleSubmit(onSubmit)}
        formId="change-password-form"
        isDisabled={!formState.isValid}
      >
        <Box marginTop={20}>
          <PasswordInput
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="oldPassword"
            label={translateString({ text: "old_password_label" })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.oldPassword)}
            error={{
              message: translateString({
                text: errors?.oldPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
        <Box marginTop={20}>
          <PasswordInput
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="newPassword"
            label={translateString({ text: "new_password_label" })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.newPassword)}
            error={{
              message: translateString({
                text: errors?.newPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
        {!!strength && (
          <Box marginBottom={20}>
            <PasswordStrengthIndicator strength={strength} />
          </Box>
        )}
        <Box marginTop={20}>
          <PasswordInput
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="confirmNewPassword"
            label={translateString({
              text: "profile_password_confirmation_label"
            })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.confirmNewPassword)}
            error={{
              message: translateString({
                text: errors?.confirmNewPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
      </ModalBody>
    </ModalLayout>
  );
};

export default observer(UserAccountChangePasswordModal);
