import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { useStores } from "src/js/hooks";
import { translateString } from "src/js/translation/TranslationProvider";
import {
  GroupDetailsModel,
  SpacePurpose,
  TemplateSelection,
  TemplateSelectionType
} from "src/js/types";
import styled, { useTheme } from "styled-components";
import { BusinessGroupCreationForm } from "./BusinessGroupCreationForm";
import { SchoolGroupCreationForm } from "./SchoolGroupCreationForm";

export const GroupCreationFormWrap = styled.div`
  padding: 20px;
  height: 100%;
  @media (max-width: 767px) {
    padding: 16px 20px;
  }
  @media (min-width: 768px) {
    // TODO: Improve fixed list options
    & #dropdown-multiple-list {
      max-height: 180px;
    }
  }
`;

type GroupCreationFormProps = {
  onGoBack?: () => void;
  clonedGroup?: GroupDetailsModel;
  prevSelection?: TemplateSelectionType;
};

const GroupCreationForm = ({
  onGoBack,
  clonedGroup,
  prevSelection
}: GroupCreationFormProps) => {
  const {
    SpaceStore: { activeSpace },
    UIStore: { isLayoutModeMobile },
    ModalStore: { setCustomCloseCb, closeModal }
  } = useStores();
  const { whiteTheme, greyTheme } = useTheme();
  const { trackView } = useViewTracking();

  const [step, setStep] = useState(1);

  useEffect(() => {
    // Set a custom close function to track the user left this virtual view
    setCustomCloseCb(() => {
      trackView();
      closeModal();
    });
    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  const isSchool = activeSpace.space.purpose === SpacePurpose.School;
  const progressPercentage = isSchool ? (100 * step) / 2 : 0;

  const title = translateString({ text: "group_create_modal_title" });

  const prevStep = () => setStep(1);
  const back = step === 1 ? onGoBack : prevStep;

  return (
    <ModalLayout
      labels={{
        title,
        mobileClose: translateString({ text: "close" })
      }}
      progressPercentage={progressPercentage}
      closeModal={closeModal}
      mobileProps={{
        withDrag: false,
        ...(!back
          ? {}
          : {
              closeLabel: translateString({ text: "back" }),
              onClose: back
            })
      }}
    >
      <GroupCreationFormWrap>
        {isSchool ? (
          <SchoolGroupCreationForm
            step={step}
            prevStep={prevStep}
            nextStep={() => setStep(2)}
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            closeForm={closeModal}
            clonedGroup={clonedGroup}
            onGoBack={onGoBack}
            isWeschoolTemplate={prevSelection === TemplateSelection.Weschool}
            isCustomTemplate={prevSelection === TemplateSelection.Custom}
          />
        ) : (
          <BusinessGroupCreationForm
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            closeForm={closeModal}
            clonedGroup={clonedGroup}
            onGoBack={onGoBack}
            isWeschoolTemplate={prevSelection === TemplateSelection.Weschool}
            isCustomTemplate={prevSelection === TemplateSelection.Custom}
          />
        )}
      </GroupCreationFormWrap>
    </ModalLayout>
  );
};

export default observer(GroupCreationForm);
