import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const LinkBtn = styled.button`
  margin-left: 4px;
  background: none;
  border: none;
  text-decoration-line: underline;
  :hover {
    color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const AccountContainer = styled.div`
  margin-top: 24px;
`;

export const AccountInputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const LanguageLabel = styled.label`
  margin-bottom: 13px;
  font-weight: 400;
`;

export const AccountButtonWrapper = styled.div`
  margin-bottom: 8px;
  width: max-content;
  margin-left: auto;
`;

export const FullWidthContextualAlertWrapper = styled.div`
  margin-bottom: 12px;
  > div {
    max-width: 100%;
  }
`;

export const PendingEmailButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

export const PrivacyPolicyText = styled(Text)`
  margin-bottom: 32px;
  display: block;
  color: ${({ theme }) => theme.colors.violet[500]};
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const SaveButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const LogoutButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const DeleteAccountButtonWrapper = styled.div`
  margin-top: 16px;
`;

export const AccountDataWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
`;

export const AccountDataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;

export const DataText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const EditButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 4px;
  transition: 300ms ease-in-out;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primaryColor[900]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[900]};
  }

  @media (max-width: 767px) {
    padding: 4px;
  }
`;
