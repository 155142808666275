import React from "react";

export const HTMLParserComponents = {
  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
  a: <a />,
  b: <strong />,
  u: <u />,
  em: <em />,
  pre: <pre />,
  span: <span />,
  sub: <sub />,
  sup: <sup />
};
