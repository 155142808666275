import { Box } from "@arcadia/design-system";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useTheme } from "styled-components";
import { BaseChartItem, DashboardXAxisFrequencyType } from "src/js/types";
import { format } from "date-fns";
import { CustomTooltip } from "src/js/components/CustomTooltip";
import { CustomLegend } from "../CustomLegend";
import { LegendItem } from "../CustomLegend/CustomLegend";

export type SingleLineChartProps = {
  data: BaseChartItem[];
  legend?: LegendItem[];
  tooltipTitle: string;
  tooltipDescription?: string;
  measurementUnit: string;
  xAxisFrequency?: DashboardXAxisFrequencyType;
};

const SingleLineChart = ({
  data,
  legend = [],
  tooltipTitle,
  tooltipDescription,
  measurementUnit,
  xAxisFrequency
}: SingleLineChartProps) => {
  const { colors, primaryColor } = useTheme();

  return (
    <Box width="100%" height="240px">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, bottom: -12, left: -28 }}
        >
          <Legend
            verticalAlign="top"
            iconType="rect"
            content={<CustomLegend legendItems={legend} />}
          />
          <CartesianGrid vertical={false} stroke={colors.grey[900]} />
          <XAxis
            dataKey="label"
            stroke={colors.grey[900]}
            fontSize={10}
            fontWeight={500}
            tick={{ fill: colors.grey[100] }}
            tickLine
            axisLine={false}
            tickFormatter={label =>
              format(new Date(label), "MMM dd").toUpperCase()
            }
            interval={xAxisFrequency - 1}
            padding={{ left: 16, right: 16 }}
          />
          <YAxis
            stroke={colors.grey[100]}
            fontSize={10}
            fontWeight={500}
            tickLine={false}
            axisLine={false}
            tickFormatter={value =>
              `${Math.round(value * 100) / 100}${measurementUnit}`
            }
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            animationDuration={0}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return CustomTooltip({
                  title: tooltipTitle,
                  subtitle: format(new Date(label), "EEE MMM dd, yyyy"),
                  description: tooltipDescription,
                  highlightedValue: `${payload[0].value}${measurementUnit}`,
                  color: primaryColor[500]
                });
              }
              return null;
            }}
          />
          <Line
            type="linear"
            dataKey="value"
            stroke={primaryColor[500]}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SingleLineChart;
