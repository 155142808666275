import { Avatar, Button, Text } from "@arcadia/design-system";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useGroupFeatures, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { createUrl } from "src/js/modules/routing";
import { GroupInviteMemberModal } from "src/js/pages/group/settings/GroupInviteMemberModal";
import { Animations } from "src/js/settings/animations";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { AddMemberEvent, EventDomain } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { DotsMenu } from "../../DotsMenu";
import { LiveButton } from "./components/LiveButton";
import { SpaceSecondaryHeaderTabs } from "./components/SpaceSecondaryHeaderTabs";
import { useOptions } from "./hooks/useOptions";

import * as S from "./SpaceSecondaryHeader.styles";

const SpaceSecondaryHeader = () => {
  const {
    UserInviteStore: { reset },
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile },
    GroupStore: {
      activeGroup,
      usersPreview,
      usersCount,
      groupCover,
      userIsTeacher
    }
  } = useStores();
  const { colors, whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { options } = useOptions();
  const { hideMembers } = useGroupFeatures();

  const showInviteModal = () => {
    _trackEvent(EventDomain.AddMember, AddMemberEvent.GroupPageUserInvite);
    reset();
    openModal(() => <GroupInviteMemberModal />);
  };

  if (!activeGroup || !Object.keys(activeGroup).length) return null;

  return (
    <S.SpaceSecondaryHeaderWrapper
      isLayoutModeMobile={isLayoutModeMobile}
      id={UsetifulId.GroupSecondaryHeader}
    >
      {isLayoutModeMobile ? (
        <SpaceSecondaryHeaderTabs />
      ) : (
        <S.ContentWrapper>
          <S.GroupRow {...Animations.Fade} transition={{ delay: 0.2 }}>
            <Avatar
              theme={whiteTheme}
              url={groupCover?.small ?? __GROUP_COVER_DEFAULT__}
              size="m"
              rounded={false}
            />
            <S.Column>
              <S.GroupNameRow>
                <S.GroupName>{activeGroup?.name}</S.GroupName>
                <S.DotsMenuWrapper>
                  {options ? (
                    <DotsMenu
                      id={UsetifulId.GroupOptionsDotsMenu}
                      items={options}
                    />
                  ) : null}
                </S.DotsMenuWrapper>
              </S.GroupNameRow>
              <SpaceSecondaryHeaderTabs />
            </S.Column>
          </S.GroupRow>
          <S.RightSection
            {...Animations.Fade}
            transition={{ delay: 0.4 }}
            id={UsetifulId.GroupSecondaryHeaderMembersBlock}
          >
            <LiveButton groupId={activeGroup?.id} />
            {!isEmpty(usersPreview) && !hideMembers && (
              <S.GroupMembersColumn
                onClick={() => {
                  navigateTo(
                    createUrl("group_settings", {
                      group_id: activeGroup?.id,
                      option: "members"
                    })
                  );
                }}
              >
                <S.GroupMembersAvatarWrapper>
                  {usersPreview.map(user => {
                    return (
                      <Avatar
                        url={user.avatar ? user.avatar?.small : ""}
                        size="s"
                        theme={whiteTheme}
                        rounded
                        key={user.id}
                      />
                    );
                  })}
                </S.GroupMembersAvatarWrapper>
                <Text type="formSmallCaption">
                  {translate({
                    text: "group_header_members_count",
                    stringVariables: { suggestionCount: usersCount }
                  })}
                </Text>
              </S.GroupMembersColumn>
            )}
            {userIsTeacher && (
              <Button
                id={UsetifulId.GroupSecondaryHeaderInviteCta}
                withIcon={{
                  icon: "userEmpty",
                  height: 20,
                  width: 20,
                  positionIcon: "left",
                  color: colors.grey[50],
                  hover: {
                    color: colors.grey[50]
                  }
                }}
                theme={whiteTheme}
                variant="secondary"
                size="small"
                onClick={showInviteModal}
              >
                {translate({ text: "group_header_cta_invite" })}
              </Button>
            )}
          </S.RightSection>
        </S.ContentWrapper>
      )}
    </S.SpaceSecondaryHeaderWrapper>
  );
};

export default observer(SpaceSecondaryHeader);
