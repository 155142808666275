import React, { useCallback } from "react";
import { useTheme } from "styled-components";
import { Box, Button, Heading, Input, Text } from "@arcadia/design-system";
import { Trans, useTranslation } from "src/js/translation";
import { useForm, useStores } from "src/js/hooks";
import { __FORM_REQUEST_RESET_PASSWORD__ } from "src/js/settings/settingsFormValidation";
import PasswordRecoverySVG from "src/img/icons/svg/password-recovery.svg";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import StarsLeftSVG from "src/img/icons/svg/stars-recover-left.svg";
import StarsRightSVG from "src/img/icons/svg/stars-recover-right.svg";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { requestResetPassword } from "src/js/repository/unloggedRepository";

import * as S from "./RequestResetPasswordPage.styles";
import { Background } from "../components/Background";

type RequestResetPasswordForm = {
  email: string;
};

const RequestResetPasswordPage = () => {
  const { UIStore } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { register, handleSubmit, errors, formState } =
    useForm<RequestResetPasswordForm>({
      mode: "onChange",
      reValidateMode: "onChange"
    });

  const onSubmit = useCallback((formData: RequestResetPasswordForm) => {
    requestResetPassword(formData)
      .then(() => {
        showToastSuccess({
          str: translate("reset_password_request_success")
        });
        navigateTo(createUrl("login"));
      })
      .catch(error => {
        showToastError({ str: translate(error.message) });
      });
  }, []);

  return (
    <Background>
      {!UIStore.isLayoutModeMobile ? (
        <S.Card
          key="reset-card"
          initial={{
            opacity: 0,
            scale: 1.15
          }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ duration: 0.35, ease: "easeInOut" }}
        >
          <Heading level="3">
            {translate("request_resetpassword_page_title")}
          </Heading>
          <S.SVGWrapper>
            <PasswordRecoverySVG />
          </S.SVGWrapper>
          <Box textAlign="center" marginTop={20}>
            <Text type="body">
              <div>
                <Trans i18nKey="request_resetpassword_page_body" />
              </div>
            </Text>
          </Box>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="email"
              label=""
              type="field"
              rounded
              placeholder={translate(
                "request_reset_password_email_placeholder"
              )}
              ref={register(__FORM_REQUEST_RESET_PASSWORD__.email)}
              error={errors.email}
              theme={whiteTheme}
            />
            <Box marginTop={60}>
              <Button
                theme={whiteTheme}
                variant="primary"
                fullWidth
                type="submit"
                disabled={!formState.isValid}
              >
                {translate("request_resetpassword_page_btn")}
              </Button>
            </Box>
          </S.Form>
          <UnloggedSupportLink />
        </S.Card>
      ) : (
        <S.MobileWrapper>
          <S.LogoWrapper>
            <WeSchoolLogoSVG />
          </S.LogoWrapper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={80}
          >
            <S.MobileTitleWrapper>
              <S.MobileTitle level="3">
                {translate("request_resetpassword_page_title")}
              </S.MobileTitle>
              <Box position="absolute" left={-40} bottom={2}>
                <StarsLeftSVG />
              </Box>
              <Box position="absolute" right={-40} top={2}>
                <StarsRightSVG />
              </Box>
            </S.MobileTitleWrapper>
            <Box width={146} height={146} marginTop={40}>
              <PasswordRecoverySVG />
            </Box>
            <S.MobileTextWrapper>
              <Text type="body">
                <div>
                  <Trans i18nKey="request_resetpassword_page_body" />
                </div>
              </Text>
            </S.MobileTextWrapper>
          </Box>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="email"
              label=""
              type="field"
              rounded
              placeholder={translate(
                "request_reset_password_email_placeholder"
              )}
              ref={register(__FORM_REQUEST_RESET_PASSWORD__.email)}
              error={errors.email}
              theme={whiteTheme}
            />
            <Box marginTop={40}>
              <Button
                theme={whiteTheme}
                variant="primary"
                fullWidth
                type="submit"
                disabled={!formState.isValid}
              >
                {translate("request_resetpassword_page_btn")}
              </Button>
            </Box>
          </S.Form>
        </S.MobileWrapper>
      )}
    </Background>
  );
};

export default RequestResetPasswordPage;
