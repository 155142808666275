import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AudienceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};
`;
export const CheckboxWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.blueGrey[500] : theme.colors.grey[50]};
  }
  input[type="checkbox"] {
    background-color: ${({ disabled }) => disabled && "initial"};
  }
`;

export const CustomFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContextualAlertWrapper = styled.div`
  > div {
    width: fit-content;
    max-width: unset;
  }
`;

export const StyledLink = styled.a`
  all: unset;
  text-decoration: underline;
  color: ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
  &:active {
    text-decoration: underline;
  }
`;
