import { ConfirmationAlert, Text } from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { useDebounce, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { GroupResendInviteModal } from "src/js/pages/group/settings/GroupResendInviteModal";
import { useSpaceByIdCached } from "src/js/query";
import {
  changeUserRole,
  deleteInvite,
  getAllGroupMembers,
  removeUser
} from "src/js/repository/groupRepository";
import { fetchGroupPendingInvites } from "src/js/repository/inviteRepository";
import { __GROUP_MEMBERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import {
  ChatEvent,
  EventDomain,
  GroupUserRoleEnum,
  InviteMemberPending
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useSendInviteMembers } from "../GroupInviteMemberModal/hooks";
import { Member, PaginatedMembers, Role } from "./GroupSettingsMembers.types";

export const useGroupMembers = () => {
  const {
    GroupStore: { activeGroup: group, roles },
    SpaceStore: { canCreateChats, activeSpaceSlug },
    ModalStore: { openModal, closeModal },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    UserStore: { activeUser }
  } = useStores();
  const { navigateToThread } = useCreateQuickThread();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const { handleInviteMembers } = useSendInviteMembers();

  const [members, setMembers] = useState<Member[]>([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [nameOrEmail, setNameOrEmail] = useState<string>("");
  const [invitations, setInvitations] = useState<InviteMemberPending[]>([]);
  const [totalInvitations, setTotalInvitations] = useState(0);
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [invitationNameOrEmail, setInvitationNameOrEmail] =
    useState<string>("");

  const nameOrEmailDebounced = useDebounce(nameOrEmail, 300);
  const invitationNameOrEmailDebounced = useDebounce(
    invitationNameOrEmail,
    300
  );

  const isCreator = roles?.includes(Role.Creator);
  const isTeacher = roles?.includes(Role.Teacher);
  const isStudent = roles?.includes(Role.Student);

  const getMembers = (offset: number) => {
    if (!group?.id) return;
    setLoadingMembers(true);
    getAllGroupMembers(
      group.id,
      __GROUP_MEMBERS_LIST_LIMIT__,
      offset,
      true,
      Date.now(),
      nameOrEmailDebounced
    )
      .then((data: PaginatedMembers) => {
        setMembers(data.results);
        setTotalMembers(data.total);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingMembers(false);
      });
  };

  const getInvitations = (offset: number) => {
    setLoadingInvitations(true);
    return fetchGroupPendingInvites({
      groupId: group.id,
      limit: __GROUP_MEMBERS_LIST_LIMIT__,
      offset,
      userNameOrEmail: invitationNameOrEmailDebounced
    })
      .then(data => {
        setInvitations(data.results);
        setTotalInvitations(data.total);
      })
      .catch(() => {
        showToastError({ str: translate("group_not_available") });
      })
      .finally(() => setLoadingInvitations(false));
  };

  const sendInvite = async (newMail: string, inviteRole: GroupUserRoleEnum) => {
    handleInviteMembers({
      groupIds: [group?.id],
      inviteRole,
      userEmails: [newMail]
    }).then(() => {
      _trackEvent("Group", "GroupInvite", "EmailLink", 1);
    });
  };

  const reSendInvite = (
    id: number,
    newMail: string,
    inviteRole: GroupUserRoleEnum
  ) => {
    deleteInvite(id)
      .then(() => {
        sendInvite(newMail, inviteRole);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const cancelInvite = (inviteId: number, page: number) => {
    deleteInvite(inviteId)
      .then(() => {
        showToastSuccess({ str: translate("invite_deleted_message") });
        closeModal();
        getInvitations(page);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showResendInviteModal = (
    id: number,
    email: string,
    inviteRole: GroupUserRoleEnum
  ) => {
    openModal(() => (
      <GroupResendInviteModal
        closeModal={() => closeModal()}
        defaultMail={email}
        reSendFunction={(newMail: string) =>
          reSendInvite(id, newMail, inviteRole)
        }
      />
    ));
  };

  const showCancelInviteModal = (inviteId: number, page: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("cancel_invite_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          cancelInvite(inviteId, page);
          closeConfirmDialog();
        }}
      />
    );
  };

  const hasRole = useCallback((desired: Role, memberRoles: Role[]) => {
    return memberRoles.includes(desired);
  }, []);

  const removeMember = (user: Member, page: number) => {
    removeUser(group.id, user.id)
      .then(() => {
        showToastSuccess({
          str: translate("group_user_removed_message")
        });
        closeModal();
        getMembers(page);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showRemoveMemberModal = (user: Member, page: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={
          user.roles.includes(Role.Teacher) ? (
            translate("remove_user_title")
          ) : (
            <Text type="captionAlert">
              <Trans i18nKey="alert_remove_user_warning" />
            </Text>
          )
        }
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          removeMember(user, page);
          closeConfirmDialog();
        }}
      />
    );
  };

  const changeMemberRole = (user: Member, role: string, page: number) => {
    const toasterMessage =
      role === "ROLE_TEACHER"
        ? "user_promoted_to_teacher_message"
        : "user_demoted_to_student_message";
    changeUserRole(group.id, user.id, role)
      .then(() => {
        showToastSuccess({ str: translate(toasterMessage) });
        closeModal();
        getMembers(page);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showChangeMemberRoleModal = (
    user: Member,
    role: string,
    page: number
  ) => {
    const bodyMessage =
      role === "ROLE_TEACHER"
        ? "promote_to_teacher_confirmation_message"
        : "demote_to_student_confirmation_message";
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate(bodyMessage)}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          changeMemberRole(user, role, page);
          closeConfirmDialog();
        }}
      />
    );
  };

  const { data } = useSpaceByIdCached(activeSpaceSlug, {
    enabled: !!activeSpaceSlug
  });

  const getDropdownOptions = (user: Member, page: number): ListItemProps[] => {
    const viewProfile: ListItemProps = {
      id: "go-to-profile",
      icon: "user",
      label: translate("go_to_profile"),
      onClick: () => {
        navigateTo(
          createUrl("profile_public", {
            user_uuid: user.uuid
          })
        );
      }
    };

    const startConversation: ListItemProps = {
      id: "go-to-chat",
      icon: "chat",
      label: translate("group_members_start_conversation"),
      onClick: () => {
        _trackEvent(EventDomain.Chat, ChatEvent.ChatStartGroupMembersClick);
        navigateToThread(user.uuid);
      }
    };

    const promoteToTeacher: ListItemProps = {
      id: "promote-teacher",
      icon: "edit",
      label: translate("promote_teacher"),
      onClick: () => {
        showChangeMemberRoleModal(user, "ROLE_TEACHER", page);
      }
    };

    const demoteToStudent: ListItemProps = {
      id: "demote-student",
      icon: "edit",
      label: translate("demote_student"),
      onClick: () => {
        showChangeMemberRoleModal(user, "ROLE_STUDENT", page);
      }
    };

    const removeFromGroup: ListItemProps = {
      id: "remove-from-group",
      icon: "trash",
      label: translate("remove_from_group"),
      redShade: true,
      onClick: () => {
        showRemoveMemberModal(user, page);
      }
    };
    const creator = user.roles.includes(Role.Creator);
    const teacher = user.roles.includes(Role.Teacher);
    const student = user.roles.includes(Role.Student);

    let dropdownOptions =
      canCreateChats &&
      activeUser.uuid !== user.uuid &&
      !data?.spaceSettings.disableChat
        ? [startConversation, viewProfile]
        : [viewProfile];

    if (isCreator) {
      if (!creator && !teacher) {
        dropdownOptions = [
          promoteToTeacher,
          ...dropdownOptions,
          removeFromGroup
        ];
      } else if (!creator && teacher) {
        dropdownOptions = [
          demoteToStudent,
          ...dropdownOptions,
          removeFromGroup
        ];
      }
    } else if (isTeacher && student) {
      dropdownOptions = [promoteToTeacher, ...dropdownOptions, removeFromGroup];
    }

    return dropdownOptions;
  };

  return {
    isCreator,
    isStudent,
    isTeacher,
    invitations,
    totalInvitations,
    getInvitations,
    loadingInvitations,
    members,
    totalMembers,
    getMembers,
    loadingMembers,
    getDropdownOptions,
    showRemoveMemberModal,
    showResendInviteModal,
    hasRole,
    showCancelInviteModal,
    nameOrEmailDebounced,
    nameOrEmail,
    setNameOrEmail,
    invitationNameOrEmailDebounced,
    invitationNameOrEmail,
    setInvitationNameOrEmail
  };
};
