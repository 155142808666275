import { mobileMixin } from "src/js/theme/breakpointsMixin";
import styled from "styled-components";

export const NoBoardsLayout = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  align-items: center;

  ${mobileMixin`
    padding-top: 0;
    min-height: calc(100vh - 120px);
    flex-direction: column;
  `}
`;

export const NoBoardsImageContainer = styled.div`
  margin: 20px;
  max-width: 400px;

  img {
    max-width: 100%;
    min-width: 300px;
  }

  ${mobileMixin`
    margin: 10px;
    text-align: center;
  `}
`;

export const NoBoardsInfo = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.title};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  max-width: 400px;

  ${mobileMixin`
    margin: 10px;
    text-align: center;
  `}
`;

export const NoBoardsInfoTitle = styled.span`
  margin-bottom: 8px;
`;

export const NoBoardsInfoDescription = styled.span`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const NoBoardsButtonsContainer = styled.div`
  display: flex;

  button + button {
    margin-left: 16px;
  }

  ${mobileMixin`
    flex-direction: column;
    button + button {
      margin-left: 0;
      margin-top: 10px;
    }
  `}
`;

export const NewModuleCard = styled.div`
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  max-width: 820px;
`;

export const VideoContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  flex: 1;
  max-width: 384px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > div {
    border-radius: 0px;
    height: 100%;
  }
  video {
    object-fit: cover;
  }
`;

export const ActionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 44px;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const MobileMessageWrapper = styled(MobileWrapper)`
  gap: 8px;
`;
