import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import styled from "styled-components";

const Wrapper = styled.div<{ withBorderTop?: boolean }>`
  border-top: ${({ theme, withBorderTop }) =>
    withBorderTop && `1px solid ${theme.colors.grey[900]}`};
  display: flex;
  padding: ${({ withBorderTop }) =>
    withBorderTop ? "16px 0px" : "0px 0px 16px 0px"};
`;
const FakeInput = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border-radius: 100px;
  padding: 0px 20px;
  height: 44px;
  cursor: not-allowed;
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  gap: 8px;
`;

const DisabledCommentInput = ({
  withBorderTop
}: {
  withBorderTop?: boolean;
}) => {
  const { translate } = useTranslation();
  return (
    <Wrapper withBorderTop={withBorderTop}>
      <FakeInput>
        <Icon icon="bellCrossed" width={16} height={16} initialViewbox />
        <Text type="formFieldEmpty">
          {translate("hiding_social_feature_disabled_input_placeholder")}
        </Text>
      </FakeInput>
    </Wrapper>
  );
};

export default DisabledCommentInput;
