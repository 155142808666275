import React, { useState } from "react";
import { Box, Text } from "@arcadia/design-system";
import {
  ReactableEntity,
  ReactionCounter,
  ReactionType
} from "src/js/types/models/Reaction";
import { useTranslation } from "src/js/translation";
import { useGroupFeatures } from "src/js/hooks";
import { getShortcodeFromType } from "../Reactions.utils";
import { ReactionListModal } from "../ReactionListModal";
import TranslatedTooltip from "../../TranslatedTooltip";
import { useUserReactionList } from "../hooks";
import { REACTION_MODAL_TAB } from "../Reactions.types";

import * as S from "./ReactionList.styles";

type ReactionListProps = {
  reactionsCounter: ReactionCounter;
  onClick: (type: ReactionType) => void;
  light?: boolean;
  entity: ReactableEntity;
  entityId: string;
  hasInlineViewAll?: boolean;
};

const ReactionList = ({
  reactionsCounter,
  onClick,
  light = false,
  entity,
  entityId,
  hasInlineViewAll
}: ReactionListProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const { freezeComments } = useGroupFeatures();

  const { translate } = useTranslation();

  const { initUserReaction, getUserReactionList } = useUserReactionList({
    limit: 10,
    reactableEntity: entity,
    reactedId: entityId
  });
  if (!reactionsCounter || reactionsCounter.total === 0) return null;

  const allReactions = getUserReactionList(REACTION_MODAL_TAB.ALL)?.results;
  const { total, reactions, reactionLoggedUser } = reactionsCounter;

  const viewAllButton = (
    <TranslatedTooltip
      placement={hasInlineViewAll ? "topRight" : "bottomRight"}
      onVisibleChange={visible => {
        if (!visible || allReactions.length) return;
        initUserReaction();
      }}
      customTooltip={
        <Box padding=" 8px 12px">
          {allReactions &&
            allReactions.map(({ user }) => {
              return <p key={user.uuid}>{`${user.name} ${user.surname}`}</p>;
            })}
        </Box>
      }
    >
      <S.ViewAllReactionButton
        hasInlineViewAll={hasInlineViewAll}
        type="button"
        onClick={() => {
          setShowDetails(true);
        }}
      >
        <S.StyledText type="ctaTertiary" hasInlineViewAll={hasInlineViewAll}>
          {translate("reaction_list_people_have_reacted_cta", { total })}
        </S.StyledText>
      </S.ViewAllReactionButton>
    </TranslatedTooltip>
  );

  return (
    <>
      <Box>
        {hasInlineViewAll ? null : viewAllButton}
        <S.ReactionListWrapper>
          {reactions?.map(({ reactionType, count }) => (
            <TranslatedTooltip
              placement="top"
              key={reactionType}
              customTooltip={
                <S.CustomTooltipWrapper>
                  <em-emoji
                    shortcodes={getShortcodeFromType(reactionType)}
                    set="native"
                    size="24px"
                  />
                  <Text type="captionInfoDetails">
                    {translate(
                      reactionLoggedUser?.reactionType === reactionType
                        ? "tooltip_on_single_reaction_selected"
                        : "tooltip_on_single_reaction_not_selected"
                    )}
                  </Text>
                </S.CustomTooltipWrapper>
              }
            >
              <S.ReactionElement
                isActive={reactionLoggedUser?.reactionType === reactionType}
                onClick={
                  freezeComments
                    ? () => {}
                    : () => {
                        onClick(reactionType);
                      }
                }
                light={light}
              >
                <em-emoji
                  shortcodes={getShortcodeFromType(reactionType)}
                  set="native"
                  size="14px"
                />
                <Text type="formSmallCaption">{count}</Text>
              </S.ReactionElement>
            </TranslatedTooltip>
          ))}
          {hasInlineViewAll ? viewAllButton : null}
        </S.ReactionListWrapper>
      </Box>
      {showDetails ? (
        <ReactionListModal
          reactions={reactions}
          entityId={entityId}
          entity={entity}
          onClose={() => {
            setShowDetails(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ReactionList;
