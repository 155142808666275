import React, { useCallback, useRef, useState } from "react";
import {
  api_users_avatar,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  __USER_AVATAR_MIME__,
  __USER_AVATAR_SIZE__
} from "src/js/settings/settingFileUploader";
import { showToastError } from "src/js/modules/messageManager";
import getUserClient from "src/js/modules/connection";
import UserPoints from "src/js/components/UserPoints";
import {
  ActionButton,
  Avatar,
  Box,
  Heading,
  Icon,
  ProgressBar
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useMeQueryCached } from "src/js/query";
import {
  isAllowedFileSize,
  isAllowedMimeType
} from "src/js/modules/fileFunction";
import { UploadInfo } from "./components";

import * as S from "./EditAvatar.styles";

const EditAvatar = () => {
  const [loaded, setLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { whiteTheme, greyTheme } = useTheme();
  const inputRef = useRef<HTMLInputElement>();

  const { data: activeUser, refetch } = useMeQueryCached();

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      setErrorMessage(null);
      if (!fileList) return;

      if (!isAllowedMimeType(fileList[0], __USER_AVATAR_MIME__)) {
        setErrorMessage("file_extension_unsupported_error");
        return;
      }
      if (!isAllowedFileSize(fileList[0], __USER_AVATAR_SIZE__)) {
        setErrorMessage("file_size_error");
        return;
      }

      const data = new FormData();
      data.append("avatar", fileList[0]);
      setIsLoading(true);
      getUserClient()
        .post(getVersionedApiUrl(api_users_avatar(), "v3"), data, {
          onUploadProgress: ProgressEvent => {
            setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
          }
        })
        .then(() => {
          refetch();
          setIsLoading(false);
          setLoaded(0);
        })
        .catch(() => {
          showToastError({ str: "Attenzione, si è verificato un errore" });
        });
    },
    []
  );

  return (
    <>
      <S.AvatarWrapper>
        <Avatar
          theme={whiteTheme}
          url={activeUser.avatar?.small}
          alt={`${activeUser.name} ${activeUser.surname}`}
          size="xl"
        />
        <UserPoints userId={activeUser.id} />
        <S.EditLayer
          isDefaultAvatar={!activeUser.avatar}
          onClick={() => inputRef.current.click()}
        >
          <Icon icon="pencil" width={20} height={20} />
          <input
            ref={inputRef}
            type="file"
            name="file"
            onChange={onChangeHandler}
          />
        </S.EditLayer>
        <Box position="absolute" top="0px" right="-16px">
          <ActionButton
            theme={whiteTheme}
            variant="secondary"
            icon="trash"
            onClick={() =>
              getUserClient()
                .delete(getVersionedApiUrl(api_users_avatar(), "v3"))
                .then(() => {
                  refetch();
                })
            }
          />
        </Box>
      </S.AvatarWrapper>
      <S.DesktopUploadInfo>
        <UploadInfo errorMessage={errorMessage} />
      </S.DesktopUploadInfo>
      {isLoading ? (
        <Box marginTop="8px">
          <ProgressBar theme={greyTheme} percentage={loaded} variant="thin" />
        </Box>
      ) : null}
      <S.MobileUploadInfo>
        <Box marginBottom={10}>
          <Heading level="3">
            {`${activeUser?.name} ${activeUser?.surname}`}
          </Heading>
        </Box>

        <UploadInfo errorMessage={errorMessage} />
      </S.MobileUploadInfo>
    </>
  );
};

export default EditAvatar;
