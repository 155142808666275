import { Button, Heading, Text } from "@arcadia/design-system";
import { ObjectValues } from "src/js/types";
import React from "react";
import { openExternalLink } from "src/js/modules/commonFunction";
import { Trans, TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { __NO_RESULTS__ } from "src/js/settings/settingsImage";

import * as S from "./FallbackPlaceholder.styles";

export const FallbackResource = {
  link: "link",
  linkOwner: "linkOwner",
  file: "file"
} as const;

type FallbackResourceType = ObjectValues<typeof FallbackResource>;

type FallbackCopies = {
  title: TranslationKeys;
  subtitle: TranslationKeys;
  cta: TranslationKeys;
};

const copyFromType: Record<FallbackResourceType, FallbackCopies> = {
  [FallbackResource.linkOwner]: {
    title: "resource_embed_link_not_available_owner_title",
    subtitle: "resource_embed_link_not_available_owner_subtitle",
    cta: "resource_embed_link_not_available_owner_cta"
  },
  [FallbackResource.link]: {
    title: "resource_embed_link_not_available_title",
    subtitle: "resource_embed_link_not_available_subtitle",
    cta: "resource_embed_link_not_available_cta"
  },
  [FallbackResource.file]: {
    title: "resource_embed_file_not_available_title",
    subtitle: "resource_embed_file_not_available_subtitle",
    cta: "resource_embed_file_not_available_cta"
  }
};

const FallbackPlaceholder = ({
  type,
  url
}: {
  url: string;
  type: FallbackResourceType;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.Wrapper>
      <S.BoxWrapper>
        <img width={100} src={__NO_RESULTS__} alt="not embeddable" />
        <S.TextWrapper>
          <Heading level="6">{translate(copyFromType[type].title)}</Heading>
          {/* TODO this need to have a redirect link, use Trans instead of translate */}
          <Text type="formSubtitle">
            <Trans
              i18nKey={copyFromType[type].subtitle}
              components={{
                CustomLink: <S.StyledLink target="_blank" />
              }}
            />
          </Text>
        </S.TextWrapper>
        <Button
          onClick={() => {
            openExternalLink(url);
          }}
          variant="primary"
          theme={whiteTheme}
        >
          {translate(copyFromType[type].cta)}
        </Button>
      </S.BoxWrapper>
    </S.Wrapper>
  );
};

export default FallbackPlaceholder;
