import { Box, Button, ContextualAlert, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import BadgeGroupWithIcon from "src/js/components/GroupPanel/components/BadgeGroupWithIcon";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { Skeleton } from "src/js/components/Skeleton";
import { useSpacePlanType, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import {
  fetchGroupEmailByRoles,
  getGroup
} from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  EventDomain,
  GroupInSpaceModel,
  GroupUserRoleEnum
} from "src/js/types";
import styled, { useTheme } from "styled-components";
import { useSendInviteMembers } from "../hooks";
import SelectionGroupImportModal from "./SelectionGroupImportModal";

const StyledTextSubtitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;
const StyledTextDescription = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

export const StyledLinkBtn = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
`;

const ConfirmGroupImportModal = ({
  sourceGroupId,
  groupIds,
  role
}: {
  sourceGroupId: number;
  groupIds: number[];
  role: GroupUserRoleEnum;
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme, primaryColor } = useTheme();
  const [groupDetails, setGroupDetails] = useState<GroupInSpaceModel>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userEmails, setUserEmails] = useState<string[]>([]);

  const { memberInviteLimitPerGroup } = useSpacePlanType({
    groupCount: groupIds.length
  });

  const { handleInviteMembers, isLoadingInviteMembers } =
    useSendInviteMembers();

  const setupMembers = async () => {
    setIsLoading(true);
    try {
      const group = await getGroup(sourceGroupId);
      setGroupDetails(group);
      const userList: string[] = await fetchGroupEmailByRoles({
        groupId: sourceGroupId,
        limit: memberInviteLimitPerGroup,
        offset: 0,
        role
      })?.then(data => data?.results);
      setUserEmails(userList);
      setIsLoading(false);
    } catch (error) {
      closeModal();
      showToastError({
        str: translate("error_fetch_group_import_email_list")
      });
    }
  };

  useEffect(() => {
    setupMembers();
  }, []);

  const onClose = () => {
    closeModal();
    openModal(() => (
      <SelectionGroupImportModal
        groupIds={groupIds}
        initialGroupId={sourceGroupId}
        role={role}
      />
    ));
  };

  const onConfirmInviteMembers = async () => {
    _trackEvent(EventDomain.AddMember, AddMemberEvent.ConfirmImportFromGroups);
    await handleInviteMembers({
      groupIds,
      inviteRole: role,
      userEmails
    });
  };

  return (
    <ModalLayout
      closeModal={onClose}
      labels={{
        title: translate({ text: "import_members_confirm_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        padding="16px 20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          margin="auto"
          {...(!isLayoutModeMobile && {
            padding: "24px 0px 32px"
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Skeleton loading={!groupDetails}>
              <BadgeGroupWithIcon
                group={groupDetails?.group}
                icon="profile"
                iconSize={32}
                iconWrapperStyle={
                  {
                    color: primaryColor[500],
                    "--secondary-color": primaryColor[900]
                  } as React.CSSProperties
                }
              />
            </Skeleton>
          </Box>
          <StyledTextSubtitle>
            {groupDetails
              ? translate("import_members_confirm_group_name", {
                  groupName: groupDetails?.group?.name
                })
              : "-"}
          </StyledTextSubtitle>
          <StyledTextDescription>
            {translate("import_members_confirm_description")}
          </StyledTextDescription>
          <ContextualAlert style={{ maxWidth: "100%" }} theme={whiteTheme}>
            {translate(
              userEmails.length === 0
                ? "import_members_confirm_no_users"
                : "import_members_confirm_pending_notice",
              { type: role }
            )}
          </ContextualAlert>
        </Box>
        <Button
          disabled={
            isLoading || isLoadingInviteMembers || userEmails.length === 0
          }
          variant="primary"
          theme={whiteTheme}
          fullWidth
          onClick={onConfirmInviteMembers}
        >
          {translate("import_members_confirm_button", { type: role })}
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default observer(ConfirmGroupImportModal);
