import {
  Box,
  Button,
  ContextualAlert,
  CtaTertiary,
  Dropdown,
  Heading,
  Icon
} from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { LogoutModal } from "src/js/components/modal/LogoutModal";
import { useMutation, useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  changeLocale,
  rejectEmailChange,
  resendEmailChange
} from "src/js/repository/userRepository";
import { __LOCALE_LANGUAGE__ } from "src/js/settings/settingsSelect";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  queryClient,
  useMeQueryCached,
  usePublicProfileSettingsCached,
  userQueryKey
} from "src/js/query";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useTheme } from "styled-components";
import { HubSpotUpdateUser } from "src/js/repository/hubspotRepository";
import { UserAccountChangePasswordModal } from "./components/UserAccountChangePasswordModal";
import { UserAccountDeleteModal } from "./components/UserAccountDeleteModal";
import { UserAccountChangeEmailModal } from "./components/UserAccountChangeEmailModal";
import { UserAccountChangeNameModal } from "./components/UserAccountChangeNameModal";
import * as S from "./AccountPage.styles";
import { MetaMaskWalletConnection } from "./components/MetaMaskWalletConnection";
import { UserAccountChangePhoneModal } from "./components/UserAccountChangePhoneModal";

const AccountPage = () => {
  const { greyTheme } = useTheme();
  const {
    UserStore: { changeActiveUserLanguage, isTraceable },
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate, setLocale } = useTranslation();
  const { data: activeUser } = useMeQueryCached();
  const { data: publicProfileSettings } = usePublicProfileSettingsCached();
  const [langOptions] = useState(
    __LOCALE_LANGUAGE__.map(({ id, name }) => ({
      id,
      label: translate({ text: name })
    }))
  );

  const { mutate: updateLanguage, isLoading: isUpdateLanguageLoading } =
    useMutation(
      async (language: string) => {
        await setLocale(language);
        await changeLocale(language);
        changeActiveUserLanguage(language);
      },
      {
        onSuccess(language: string) {
          queryClient.invalidateQueries(userQueryKey.me);
          showToastSuccess({
            str: translate("profile_update_success")
          });
          HubSpotUpdateUser({ ...activeUser, language });
        },
        onError(error) {
          showToastError({
            str: translate(extractErrorMessage(error, "error_change_locale"))
          });
        }
      }
    );

  const { mutate: resendEmailConfirmation } = useMutation(resendEmailChange, {
    onSuccess() {
      queryClient.invalidateQueries(userQueryKey.me);
      showToastSuccess({
        str: translate("change_email_confirmation_sent")
      });
    },
    onError(error) {
      showToastError({
        str: translate(extractErrorMessage(error, "error_resend_email_change"))
      });
    }
  });

  const { mutate: rejectEmailConfirmation } = useMutation(rejectEmailChange, {
    onSuccess() {
      queryClient.invalidateQueries(userQueryKey.me);
      showToastSuccess({
        str: translate("change_email_rejected")
      });
    },
    onError(error) {
      showToastError({
        str: translate(extractErrorMessage(error, "error_resend_email_change"))
      });
    }
  });

  const openChangeNameModal = useCallback(() => {
    openModal(() => <UserAccountChangeNameModal />);
  }, []);

  const openEditPhoneModal = useCallback(() => {
    openModal(() => <UserAccountChangePhoneModal />);
  }, []);

  const openChangeEmailModal = useCallback(() => {
    openModal(() => <UserAccountChangeEmailModal />);
  }, []);

  const openChangePasswordModal = useCallback(() => {
    openModal(() => <UserAccountChangePasswordModal />);
  }, []);

  const openLogoutModal = useCallback(() => {
    openModal(() => <LogoutModal />);
  }, []);

  const openDeleteAccountModal = useCallback(() => {
    openModal(() => <UserAccountDeleteModal />);
  }, []);

  return (
    <>
      <Box minHeight={50} display="flex" alignItems="center">
        <Heading level="4">{translate("settings_account_header")}</Heading>
      </Box>
      <S.AccountContainer>
        <S.AccountDataWrapper>
          <S.AccountDataRow>
            <S.DataWrapper>
              <Icon icon="user" width={16} height={16} initialViewbox />
              <S.DataText type="body">{`${activeUser?.name} ${activeUser?.surname}`}</S.DataText>
            </S.DataWrapper>
            <S.EditButton onClick={openChangeNameModal}>
              {isLayoutModeMobile ? (
                <Icon icon="pencil" width={16} height={16} initialViewbox />
              ) : (
                translate("edit")
              )}
            </S.EditButton>
          </S.AccountDataRow>
          <S.AccountDataRow>
            <S.DataWrapper>
              <Icon icon="phone" width={16} height={16} initialViewbox />
              <S.DataText type="body">
                {publicProfileSettings?.phone}
              </S.DataText>
            </S.DataWrapper>
            <S.EditButton onClick={openEditPhoneModal}>
              {isLayoutModeMobile ? (
                <Icon icon="pencil" width={16} height={16} initialViewbox />
              ) : (
                translate("edit")
              )}
            </S.EditButton>
          </S.AccountDataRow>
          <S.AccountDataRow>
            <S.DataWrapper>
              <Icon icon="mailOutline" width={16} height={16} initialViewbox />
              <S.DataText type="body">{activeUser?.email}</S.DataText>
            </S.DataWrapper>
            <TranslatedTooltip
              placement="right"
              customTooltip={
                <Box padding={12} maxWidth={280} textAlign="center">
                  {translate("sso_email_alert_message")}
                </Box>
              }
              forceHide={!activeUser?.isSingleSignOnAccount}
            >
              <S.EditButton
                disabled={activeUser?.isSingleSignOnAccount}
                onClick={openChangeEmailModal}
              >
                {isLayoutModeMobile ? (
                  <Icon icon="pencil" width={16} height={16} initialViewbox />
                ) : (
                  translate("edit")
                )}
              </S.EditButton>
            </TranslatedTooltip>
          </S.AccountDataRow>
          <S.AccountDataRow>
            <S.DataWrapper>
              <Icon icon="lockClosed" width={16} height={16} initialViewbox />
              <S.DataText type="body">*********</S.DataText>
            </S.DataWrapper>
            <S.EditButton onClick={openChangePasswordModal}>
              {isLayoutModeMobile ? (
                <Icon icon="pencil" width={16} height={16} initialViewbox />
              ) : (
                translate("edit")
              )}
            </S.EditButton>
          </S.AccountDataRow>
        </S.AccountDataWrapper>
        {activeUser?.pendingMail && (
          <S.FullWidthContextualAlertWrapper>
            <ContextualAlert
              theme={greyTheme}
              text={translate({
                text: "edit_profile_confirm_mail_alert",
                stringVariables: { email: activeUser?.pendingMail }
              })}
            />
            <S.PendingEmailButtonWrapper>
              <CtaTertiary theme={greyTheme} onClick={resendEmailConfirmation}>
                {translate("mail_not_received_send_again")}
              </CtaTertiary>

              <CtaTertiary theme={greyTheme} onClick={rejectEmailConfirmation}>
                {translate("edit_mail_cancel")}
              </CtaTertiary>
            </S.PendingEmailButtonWrapper>
          </S.FullWidthContextualAlertWrapper>
        )}
        <S.AccountInputWrapper>
          <S.LanguageLabel>{translate("language_label")}</S.LanguageLabel>
          <Dropdown
            id="language"
            theme={greyTheme}
            optionsList={langOptions}
            setSelectedOptionId={updateLanguage}
            selectedOptionId={activeUser?.language}
            disabled={isUpdateLanguageLoading}
          />
        </S.AccountInputWrapper>
        <S.PrivacyPolicyText type="cta">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={translate("user_profile_privacy_policy_link")}
          >
            {translate("show_privacy_link")}
          </a>
          {isTraceable && (
            <S.LinkBtn
              onClick={() => {
                window?.Cookiebot?.renew();
              }}
            >
              {translate("show_cookie_link")}
            </S.LinkBtn>
          )}
        </S.PrivacyPolicyText>
        <MetaMaskWalletConnection />
        <Heading level="6">{translate("sign_out")}</Heading>
        <S.LogoutButtonWrapper>
          <Button
            onClick={openLogoutModal}
            theme={greyTheme}
            withIcon={{ icon: "logout", positionIcon: "left" }}
            variant="secondary"
          >
            {translate("disconnect")}
          </Button>
        </S.LogoutButtonWrapper>
        <Heading level="6">{translate("delete_account_section_title")}</Heading>
        <S.DeleteAccountButtonWrapper>
          <Button
            onClick={openDeleteAccountModal}
            theme={greyTheme}
            variant="danger"
          >
            {translate("delete_account_button")}
          </Button>
        </S.DeleteAccountButtonWrapper>
      </S.AccountContainer>
    </>
  );
};

export default AccountPage;
