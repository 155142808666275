import styled from "styled-components";

export const ModalContentContainer = styled.div`
  padding: 24px 24px 0;

  @media screen and (max-width: 767px) {
    padding: 24px 16px 0;
  }
`;

export const ModalFooterContainer = styled.div`
  padding: 24px 20px 16px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
`;
