import { TranslationKeys } from "src/js/translation";
import { ObjectValues } from "src/js/types";

export const PermissionsTableRoles = {
  Admin: "Admin",
  Staff: "Staff",
  Instructor: "Instructor",
  Student: "Student"
};

export type PermissionsTableRolesType = ObjectValues<
  typeof PermissionsTableRoles
>;

export const rolesList: {
  id: PermissionsTableRolesType;
  name: TranslationKeys;
}[] = [
  {
    id: PermissionsTableRoles.Admin,
    name: "staff_roles_permissions_admin"
  },
  {
    id: PermissionsTableRoles.Staff,
    name: "staff_roles_permissions_staff_member"
  },
  {
    id: PermissionsTableRoles.Instructor,
    name: "staff_roles_permissions_instructor"
  },
  {
    id: PermissionsTableRoles.Student,
    name: "staff_roles_permissions_student"
  }
];

export const headers = [null, ...rolesList.map(role => role.name)];

export const permissionsList: {
  name: TranslationKeys;
  permissions: boolean[];
}[] = [
  {
    name: "staff_roles_permissions_space_billing",
    permissions: [true, false, false, false]
  },
  {
    name: "staff_roles_permissions_dashboard_access",
    permissions: [true, true, false, false]
  },
  {
    name: "staff_roles_permissions_edit_space_learners",
    permissions: [true, true, true, false]
  },
  {
    name: "staff_roles_permissions_edit_space_groups",
    permissions: [true, true, true, false]
  },
  {
    name: "staff_roles_permissions_view_all_groups",
    permissions: [true, false, false, false]
  },
  {
    name: "staff_roles_permissions_manage_space_staff",
    permissions: [true, false, false, false]
  },
  {
    name: "staff_roles_permissions_create_new_groups",
    permissions: [true, true, false, false]
  },
  {
    name: "staff_roles_permissions_delete_groups",
    permissions: [true, true, false, false]
  },
  {
    name: "staff_roles_permissions_edit_group_settings",
    permissions: [true, true, true, false]
  },
  {
    name: "staff_roles_permissions_invite_group_members",
    permissions: [true, false, false, false]
  },
  {
    name: "staff_roles_permissions_edit_group_modules",
    permissions: [true, true, true, false]
  },
  {
    name: "staff_roles_permissions_edit_group_test",
    permissions: [true, true, true, false]
  }
];
