import { Icon, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import { DotsMenu } from "src/js/components/DotsMenu";
import { ReactionPicker } from "src/js/components/reactions/ReactionPicker";
import { ResourceItemPreview } from "src/js/components/resources";
import styled, { css } from "styled-components";

export const StyledResourceItemPreview = styled(ResourceItemPreview)`
  border: none;
  background: ${({ theme }) => theme.colors.grey[1000]};
  @media screen and (max-width: 767px) {
    min-width: 100px;
    width: calc(50% - 24px);
  }
`;

export const MessageResources = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const MessageContainer = styled.div<{
  active?: boolean;
  hasNoUser: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  position: relative;
  padding: 4px 64px 4px 24px;
  margin-top: 8px;

  @media (max-width: 767px) {
    padding: 4px 12px;
    user-select: none;
  }

  ${({ hasNoUser }) =>
    hasNoUser &&
    css`
      padding-left: 64px;
      margin-top: 0;
      @media (max-width: 767px) {
        padding-left: 52px;
      }
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      * {
        color: ${theme.colors.grey[300]};
      }
    `}

  &:hover {
    @media (min-width: 768px) {
      background: ${({ theme }) => theme.primaryColor[800]};
    }
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.primaryColor[800]};
    `}
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
`;

export const AuthorName = styled.span<{ color: string }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: ${({ color }) => color};
`;

export const StatusIcon = styled(Icon)<{ isRead?: boolean }>`
  margin-left: 4px;
  width: 16px;
  height: 16px;
  color: ${({ theme, isRead }) =>
    isRead ? theme.primaryColor[500] : theme.colors.blueGrey[300]};
`;

export const ErrorIcon = styled(Icon)`
  margin-left: 4px;
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.colors.error[500]};
`;

export const TimeOfMessage = styled(Text)`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.blueGrey[300]};
`;

export const AvatarWrapper = styled.div`
  margin-top: 4px;
  width: 32px;
  flex: 0 0 32px;
`;

export const OptionsMessageContainer = styled.div`
  position: absolute;
  right: 16px;
  top: calc(50% - 16px);
  z-index: 1101;
  display: flex;
  gap: 4px;
`;

export const AnimatedWrapper = styled(motion.div)`
  position: absolute;
  top: -56px;
  left: -346px;
`;

export const StyledReactionButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.primaryColor[900]};
  color: ${({ theme }) => theme.primaryColor[500]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const StyledDotsMenu = styled(DotsMenu)`
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.primaryColor[900]};
  color: ${({ theme }) => theme.primaryColor[500]};

  &:focus {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextEditorRenderWrapper = styled.div<{ editedContent?: string }>`
  flex: "1 0 0";
  ${({ editedContent }) =>
    editedContent &&
    css`
      .ProseMirror > *:last-child::after {
        content: " ${editedContent}";
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.grey[500]};
      }
    `}
`;

export const OptionsMessageContainerMobile = styled.ul`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 100%;
  min-width: 160px;
  margin: 0;
  list-style-type: none;
  text-align: left;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledReactionPicker = styled(ReactionPicker)`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  box-shadow: none;
  border-radius: 0;
  height: 60px;
  padding-bottom: 12px;
`;
