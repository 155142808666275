import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { CustomListElementProps } from "src/js/components/global/ListSelector";
import {
  SelectableList,
  SelectableListEmptyState,
  SelectableListEmptyStatusType
} from "src/js/components/layout/SelectableList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { fetchGroupsList } from "src/js/repository/groupsListRepository";
import { useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  BasePaginationParams,
  BasePaginationResponse,
  EventDomain,
  GroupInSpaceModel,
  GroupUserRoleEnum,
  InviteMode
} from "src/js/types";
import GroupInviteMemberModal from "../GroupInviteMemberModal";
import ConfirmGroupImportModal from "./ConfirmGroupImportModal";
import { ItemCardGroupImportModal } from "./ItemCardGroupImportModal";

const SelectionGroupImportModal = ({
  groupIds,
  initialGroupId,
  role
}: {
  groupIds: number[];
  initialGroupId?: number;
  role: GroupUserRoleEnum;
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal }
  } = useStores();

  const [selectedGroups, setSelectedGroups] = useState<string[] | number[]>(
    initialGroupId ? [initialGroupId] : []
  );

  useEffect(() => {
    _trackEvent(
      EventDomain.AddMember,
      AddMemberEvent.ImportFromGroupsSelection
    );
  }, []);

  const fetchItems = useCallback(
    (
      params: BasePaginationParams & {
        name: string;
        [key: string]: any;
      }
    ): Promise<BasePaginationResponse<GroupInSpaceModel>> => {
      return fetchGroupsList(params).then(data => {
        const results = data.results.map(result => ({
          id: result.group.id,
          ...result
        }));
        return {
          hasNext: data.hasNext,
          results
        };
      });
    },
    []
  );

  const getCustomItemCard = (
    props: CustomListElementProps<GroupInSpaceModel>
  ) => <ItemCardGroupImportModal {...props} />;

  const getEmptyStateFromStatus = (status: SelectableListEmptyStatusType) => (
    <SelectableListEmptyState
      status={status}
      emptySearchLabel="import_members_empty_search"
      emptyListLabel="import_members_empty_list"
    />
  );

  const onConfirm = () => {
    closeModal();
    openModal(() => (
      <ConfirmGroupImportModal
        groupIds={groupIds}
        sourceGroupId={selectedGroups[0] as number}
        role={role}
      />
    ));
  };

  const onCancel = () => {
    closeModal();
    openModal(() => (
      <GroupInviteMemberModal
        initialInviteMode={InviteMode.Import}
        initialInviteMemberRole={role}
      />
    ));
  };

  return (
    <ModalLayout
      labels={{
        title: translate("import_members_title"),
        mobileClose: translate("close")
      }}
      mobileProps={{
        withDrag: false
      }}
      closeModal={closeModal}
      desktopProps={{ hasAdaptiveHeight: false }}
    >
      <SelectableList
        withCancelButton
        onCancel={onCancel}
        onConfirm={onConfirm}
        fetchItems={fetchItems}
        selectedItemsIds={selectedGroups}
        onChangeSelectedItemsIds={setSelectedGroups}
        customComponents={{
          getCustomItemCard,
          getEmptyStateFromStatus
        }}
        labels={{
          translatedConfirm: translate("import_members_proceed"),
          translatedCancel: translate("import_members_cancel"),
          translatedTitle: translate("import_members_instruction"),
          searchPlaceholder: translate("import_members_search_placeholder")
        }}
      />
    </ModalLayout>
  );
};

export default observer(SelectionGroupImportModal);
