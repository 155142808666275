import React from "react";
import { useTheme } from "styled-components";
import { UserStatusBadge } from "src/js/components/UserStatusBadge";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { Modal, Button, Text } from "@arcadia/design-system";

import * as S from "./GroupPanelActivationModal.styles";

const GroupPanelActivationModal = ({
  closeModal = () => {},
  callbackOk = () => {},
  groupName,
  isActive
}) => {
  const handleSubmit = () => {
    closeModal();
    callbackOk();
  };

  const { whiteTheme } = useTheme();

  return (
    <Translator>
      {({ translate }) => {
        return (
          <Modal
            onClose={closeModal}
            scrollable
            title={translate(
              isActive ? "group_deactivation" : "group_activation"
            )}
          >
            <S.ModalContentContainer>
              <S.ModalContentTitle>
                <Text type="formTitle">
                  <Translate text="choice_confirmation_question" />
                </Text>
              </S.ModalContentTitle>
              <S.ModalContentSubtitle>
                <Text type="formSubtitle">
                  <Translate
                    text={
                      isActive
                        ? "group_deactivation_confirmation"
                        : "group_activation_confirmation"
                    }
                  />
                </Text>
              </S.ModalContentSubtitle>
              <S.ModalContentGroup>
                <S.ModalContentGroupTitle>{groupName}</S.ModalContentGroupTitle>
                <UserStatusBadge isActive={isActive} outline={false} />
              </S.ModalContentGroup>
            </S.ModalContentContainer>
            <S.ModalFooterContainer>
              <S.ButtonContainer>
                <Button
                  theme={whiteTheme}
                  onClick={closeModal}
                  variant="secondary"
                  fullOnMobile
                >
                  <Translate text="undo_button_tooltip" />
                </Button>
                <Button
                  theme={whiteTheme}
                  onClick={handleSubmit}
                  variant="primary"
                  fullOnMobile
                >
                  <Translate
                    text={isActive ? "deactivate_group" : "activate_group"}
                  />
                </Button>
              </S.ButtonContainer>
            </S.ModalFooterContainer>
          </Modal>
        );
      }}
    </Translator>
  );
};

export default GroupPanelActivationModal;
