import styled from "styled-components";

const IconButton = styled.button`
  all: unset;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor[500]};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.primaryColor[800]};
    }
  }
  @media (hover: none) {
    &:active {
      background-color: ${({ theme }) => theme.primaryColor[800]};
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export default IconButton;
