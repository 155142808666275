import { Icon } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupUserRoleEnum } from "src/js/types";
import * as S from "./EmailInvite.styles";

type EmailInviteProps = {
  selectedMemberType: GroupUserRoleEnum;
  inputValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsFocused: (isFocused: boolean) => void;
  onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInviteMembers: () => void;
  disabled?: boolean;
  className?: string;
};

const EmailInvite = ({
  selectedMemberType,
  inputValue,
  onChange,
  setIsFocused,
  handleInviteMembers,
  onPaste,
  onKeyDown,
  disabled,
  className
}: EmailInviteProps) => {
  const {
    UserInviteStore: { usersToInvite, removeUserFromList }
  } = useStores();

  const { translate } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !disabled) {
      handleInviteMembers();
    }
    onKeyDown(event);
  };

  return (
    <S.EmailInviteWrapper
      onClick={() => inputRef.current?.focus()}
      disabled={disabled}
      className={className}
    >
      <S.EmailInviteContent onKeyDown={handleKeyDown} onPaste={onPaste}>
        {usersToInvite?.map(email => (
          <S.ChipItem key={email}>
            <S.ChipItemIcon
              icon={
                selectedMemberType === GroupUserRoleEnum.Teacher
                  ? "teacherRibbon"
                  : "profile"
              }
              initialViewbox
              width={20}
              height={20}
            />
            <S.ChipItemText type="formSubtitle">{email}</S.ChipItemText>
            <S.ChipItemDeleteBtn onClick={() => removeUserFromList(email)}>
              <Icon icon="close" width={12} height={12} />
            </S.ChipItemDeleteBtn>
          </S.ChipItem>
        ))}
        <S.InputQuery
          disabled={disabled}
          autoFocus
          ref={inputRef}
          value={inputValue}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={translate("input_email_invite_placeholder")}
        />
      </S.EmailInviteContent>
    </S.EmailInviteWrapper>
  );
};

export default observer(EmailInvite);
