import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import isiOS from "src/js/modules/userAgentFunction";
import { translateString } from "src/js/translation/TranslationProvider";
import { isAndroidDevice } from "./deviceFunction";

/**
 * Don't use this function for complex object or Proxy
 * Utils function for deep copy object in new and older browser
 * @param obj: T
 * @returns a deep copy of obj passed
 */
export const structuredClone = <T>(obj: T): T => {
  try {
    if ("structuredClone" in window) {
      return window.structuredClone(obj);
    }
  } catch (e) {
    // in this case the obj you're trying to clone is not clonable, so we do nothing and use the parse/stringify default method
  }
  return JSON.parse(JSON.stringify(obj));
};

function iosCopyToClipboard(el) {
  const oldContentEditable = el.contentEditable;
  const oldReadOnly = el.readOnly;
  const range = document.createRange();

  // eslint-disable-next-line no-param-reassign
  el.contentEditable = true;
  // eslint-disable-next-line no-param-reassign
  el.readOnly = false;
  range.selectNodeContents(el);

  const s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  // eslint-disable-next-line no-param-reassign
  el.contentEditable = oldContentEditable;
  // eslint-disable-next-line no-param-reassign
  el.readOnly = oldReadOnly;

  document.execCommand("copy");
}

export function copyToClipboard(data, showSuccess = true) {
  const aux = document.createElement("input");
  aux.setAttribute("value", data);
  document.body.appendChild(aux);
  if (isiOS()) {
    iosCopyToClipboard(aux);
  } else {
    aux.select();
    document.execCommand("copy");
  }
  document.body.removeChild(aux);
  if (showSuccess)
    showToastSuccess({ str: translateString({ text: "copiato!" }) });
}

export const copyToClip = data => {
  function listener(e) {
    e.clipboardData.setData("text/html", data);
    e.clipboardData.setData("text/plain", data);
    e.preventDefault();
  }

  const aux = document.createElement("input");
  aux.setAttribute("value", data);
  document.body.appendChild(aux);

  document.addEventListener("copy", listener);
  if (isiOS()) {
    iosCopyToClipboard(aux);
  } else {
    aux.select();

    document.execCommand("copy");
  }
  document.removeEventListener("copy", listener);

  document.body.removeChild(aux);
  return true;
};

export function debounce(fn, time) {
  let timeout;
  // eslint-disable-next-line func-names
  return function () {
    // eslint-disable-next-line prefer-rest-params
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// TODO remove after rebranding home
export function formatNumber(num, digits) {
  let digitsNew = digits;
  if (typeof digitsNew === "undefined") {
    digitsNew = 1;
  }
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" }
    /* { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" }, happy problem */
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    (num / si[i].value).toFixed(digitsNew).replace(rx, "$1") + si[i].symbol
  );
}

function getUrlParams(search) {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}

export function getUrlParameterValue(locationSearch, name) {
  const params = getUrlParams(decodeURI(locationSearch));
  return params[name];
}

export function openExternalLink(url: string) {
  const open = window.open(url, "_blank");
  if (open === null || typeof open === "undefined") {
    showToastError({
      str: translateString({ text: "enable_popup" })
    });
  }
}

export function isEmptyObject(object) {
  return Object.keys(object).length === 0 && object.constructor === Object;
}

export function escapeRegExp(string) {
  return string.replace("(?<=/)[^/]+(?=/)", "$1"); // $& means the whole matched string
}

export function trimValue(event) {
  // eslint-disable-next-line no-param-reassign
  event.target.value = event.target.value.trim();
}

export function shuffleArray(array) {
  const resultArray = [...array];
  for (let i = resultArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = resultArray[i];
    resultArray[i] = resultArray[j];
    resultArray[j] = temp;
  }
  return resultArray;
}

export function isElementInViewport(element, allowPartial, topPixelOffset = 0) {
  const rect = element.getBoundingClientRect();
  const innerHeight =
    (window.innerHeight || document.documentElement.clientHeight) -
    topPixelOffset;
  const innerWidth = window.innerWidth || document.documentElement.clientWidth;
  const top = rect.top - topPixelOffset;
  const bottom = rect.bottom - topPixelOffset;
  const { left, right } = rect;

  if (allowPartial === true) {
    return (
      (top >= 0 && top <= innerHeight && left >= 0 && left <= innerWidth) || // top left in view
      (top >= 0 && top <= innerHeight && right >= 0 && right <= innerWidth) || // top right in view
      (bottom >= 0 &&
        bottom <= innerHeight &&
        left >= 0 &&
        left <= innerWidth) || // bottom left in view
      (bottom >= 0 &&
        bottom <= innerHeight &&
        right >= 0 &&
        right <= innerWidth) // top right in view
    );
  }
  // element must be completely visible
  return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
}

export function isEllipsisActive(element: HTMLElement) {
  return element ? element.offsetWidth < element.scrollWidth : null;
}

export function hasCollapsedHeight(element: HTMLElement) {
  return element ? element.offsetHeight < element.scrollHeight : null;
}

export function isCollapsedElement(element: HTMLElement) {
  return isEllipsisActive(element) || hasCollapsedHeight(element);
}

export const equalsArrayIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export const getExternalRedirectUrl = () => {
  const completeUrl = process.env.__AUTH_REDIRECT_URL__;
  return completeUrl;
};

export function hasVerticalScrollbar(element) {
  if (element) {
    return element.scrollHeight > element.clientHeight;
  }
  return null;
}

function camelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const timeFormatter = (value, unit, suffix, translate) => {
  if (unit === "second") {
    return translate("justNow");
  }
  return `${value} ${translate(unit + (value > 1 ? "s" : ""))} ${translate(
    camelCase(suffix)
  )}`;
};

export const arrayPartition = <T>(
  array: T[],
  isValid: (elem: T) => boolean
) => {
  return array.reduce<[T[], T[]]>(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
};

export const preventAndroidKeyboardClose = {
  onTouchEnd: event => {
    if (isAndroidDevice()) {
      event.preventDefault();
      if (event?.currentTarget?.click) {
        event?.currentTarget?.click();
      } else {
        const fakeClickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        });
        event.target.dispatchEvent(fakeClickEvent);
      }
    }
  }
};

export function capitalizeFirstLetter(string: string): string {
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const handleDownload = ({
  fileName,
  url
}: {
  fileName: string;
  url: string;
}) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
