import styled, { DefaultTheme } from "styled-components";

import { SToggleLabelProps, SSliderProps } from "./types";

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.behind};
`;

export const Label = styled.label<SToggleLabelProps>`
  position: relative;
  display: block;
  font-size: ${({ size }) => {
    if (size === "small") return "8px";
    if (size === "big") return "11px";
    return "10px";
  }};
  width: 7.5em;
  height: 3.5em;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin: 0;

  ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const handleSliderColor = ({
  disabled,
  checked,
  theme
}: { theme: DefaultTheme } & Pick<SSliderProps, "checked" | "disabled">) => {
  switch (true) {
    case checked === true && disabled === true:
      return theme.toggle.disabled.sliderOn;
    case checked === true && disabled === false:
      return theme.toggle.sliderOn;
    case checked === false && disabled === true:
      return theme.toggle.disabled.sliderOff;
    case checked === false && disabled === false:
      return theme.toggle.sliderOff;
    default:
      return theme.toggle.sliderOn;
  }
};

const handleSliderBackground = ({
  disabled,
  checked,
  theme
}: { theme: DefaultTheme } & Pick<SSliderProps, "checked" | "disabled">) => {
  switch (true) {
    case checked === true && disabled === true:
      return theme.toggle.disabled.background;
    case checked === true && disabled === false:
      return theme.toggle.backgroundOn;
    case checked === false:
      return theme.toggle.backgroundOff;
    default:
      return theme.toggle.backgroundOn;
  }
};

export const Slider = styled.span<SSliderProps>`
  position: absolute;
  cursor: pointer;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: ${({ theme, disabled, checked }) =>
    handleSliderBackground({ theme, disabled, checked })};
  border-radius: 3.4em;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.toggle.disabled.sliderOff : theme.toggle.backgroundOn};
  &::before {
    position: absolute;
    content: "";
    height: 2.7em;
    width: 2.7em;
    left: calc(0.4em - 2px);
    bottom: calc(0.4em - 2px);
    background-color: ${({ theme, disabled, checked }) =>
      handleSliderColor({ theme, disabled, checked })};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  & + svg {
    position: absolute;
    display: ${({ withIcon }) => (withIcon ? "block" : "none")};
    z-index: ${({ theme }) => theme.zIndex.regular};
    top: ${({ size }) =>
      size === "big" ? "calc(50% - 12px)" : "calc(50% - 9px)"};
    left: 4.2em;
    color: ${({ theme, disabled, checked }) =>
      handleSliderColor({ theme, disabled, checked })};
  }

  ${Input}:checked + & {
    & + svg {
      left: 1em;
    }
  }

  ${Input}:checked + &::before {
    -webkit-transform: translateX(4em);
    -ms-transform: translateX(4em);
    transform: translateX(4em);
  }

  &:hover {
    background-color: ${({ theme, checked }) =>
      checked && theme.toggle.hover.background};
    border: 1px solid ${({ theme }) => theme.toggle.hover.background};
    &::before {
      background-color: ${({ theme, checked }) =>
        checked ? theme.toggle.hover.slider : theme.toggle.hover.background};
    }
  }

  ${Input}:focus-visible + & {
    outline: ${({ theme }) => theme.toggle.focus.border};
  }

  ${Input}:disabled + & {
    pointer-events: none;
  }
`;
