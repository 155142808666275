import {
  Dropdown,
  DropdownMultiple,
  SearchBar,
  Text
} from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { FilterBar } from "src/js/components/FilterBar";
import {
  SORT_FILTER_OPTIONS,
  STATUS_FILTER_OPTIONS
} from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsFilters/SpacesGroupsFilters.const";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import * as S from "./SpacesGroupsFilters.styles";

const SpacesGroupsFilters = ({
  statusFilterSelected,
  sortFilterSelected,
  searchDefaultValue,
  setFilter,
  removeStatusFilter,
  removeAllStatusFilters
}) => {
  const [queryString, setQueryString] = useState(searchDefaultValue);
  const { greyTheme } = useTheme();

  useEffect(() => {
    setQueryString(searchDefaultValue);
  }, [searchDefaultValue]);

  const onSubmitSearch = event => {
    event.preventDefault();
    setFilter(queryString, "searchQuery");
  };

  const onChangeSearch = value => {
    setQueryString(value);
  };

  return (
    <S.TopFiltersContainer>
      <Row justify={{ sm: "space-between" }}>
        <Col xs={12} sm={6}>
          <Translator>
            {({ translate }) => {
              return (
                <S.SearchbarWrapper>
                  <SearchBar
                    theme={greyTheme}
                    label="searchQuery"
                    value={queryString}
                    onChange={event => {
                      onChangeSearch(event.target.value);
                    }}
                    placeholder={translate("group_search_placeholder")}
                    onClick={onSubmitSearch}
                  />
                </S.SearchbarWrapper>
              );
            }}
          </Translator>
        </Col>
      </Row>
      <S.RowWithMargin />
      <Row align="center">
        <Col xs={12} sm={5} md={4}>
          <Row align="center">
            <S.Label>
              <Text type="formSubtitle">
                <Translate text="filter_by_colon" />
              </Text>
            </S.Label>
            <S.FlexWrapper>
              <Translator>
                {({ translate }) => (
                  <DropdownMultiple
                    theme={greyTheme}
                    hideButtonTags
                    optionsList={STATUS_FILTER_OPTIONS.map(
                      ({ key, value }) => ({
                        id: key,
                        label: translate(value)
                      })
                    )}
                    selectedOptionIds={statusFilterSelected}
                    setSelectedOptionIds={values => {
                      setFilter(values, "selectedStatus");
                    }}
                    placeholder={translate("status")}
                  />
                )}
              </Translator>
            </S.FlexWrapper>
          </Row>
        </Col>
        <Col xs={12} sm={5} md={4} offset={{ xs: 0, sm: 2, md: 4 }}>
          <S.RightAlignWrapper>
            <Translator>
              {({ translate }) => (
                <Dropdown
                  id="selectOrderFilterId"
                  theme={greyTheme}
                  optionsList={SORT_FILTER_OPTIONS.map(({ key, value }) => ({
                    id: key,
                    label: translate(value)
                  }))}
                  selectedOptionId={sortFilterSelected[0]}
                  setSelectedOptionId={value =>
                    setFilter([value], "selectedOrder")
                  }
                  placeholder={translate("sort_by")}
                />
              )}
            </Translator>
          </S.RightAlignWrapper>
        </Col>
      </Row>
      <Translator>
        {({ translate }) => (
          <FilterBar
            selectedFiltersKeys={statusFilterSelected}
            options={STATUS_FILTER_OPTIONS.map(({ key, value }) => ({
              id: key,
              label: translate(value)
            }))}
            removeFilter={removeStatusFilter}
            removeAllFilters={removeAllStatusFilters}
          />
        )}
      </Translator>
    </S.TopFiltersContainer>
  );
};

export default SpacesGroupsFilters;
