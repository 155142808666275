import { SearchBar } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import MainContainer from "src/js/components/layout/MainContainer/MainContainer";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import useWalletConnection from "src/js/pages/App/Settings/AccountPage/components/MetaMaskWalletConnection/useWalletConnection";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { SpacesNoGroups } from "../SpacesNoGroups";
import { SpacesBadgesList } from "./SpaceBadgesList";
import * as S from "./SpacesBadges.styles";
import { SpacesBadgesListPlaceholder } from "./SpacesBadgesListPlaceholder";
import ConnectYourWallet from "./components/ConnectYourWallet";
import CreateEditBadgeModal from "./components/CreateEditBadgeModal";

const SpacesBadges = () => {
  const {
    ModalStore: { openModal },
    SpaceStore: { activeSpaceId, activeSpaceName },
    SpaceBadgesListStore: {
      filteredBadges,
      queryString,
      areFetched,
      totalSpaceGroups,
      fetchFilteredBadges,
      filterResult,
      spaceFetchGroups,
      reset
    },
    SpaceBadgesStore: { setEmitStudentsDrawerOpen, setGroupsDrawerOpen }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const { fetchUserWalletDetails } = useWalletConnection();

  const [isLoading, setLoading] = useState(true);
  const [filterBadgeString, setFilterBadgeString] = useState(queryString);

  const closeAnyDrawerLeftOpen = () => {
    setEmitStudentsDrawerOpen(false);
    setGroupsDrawerOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    spaceFetchGroups(activeSpaceId);
    fetchUserWalletDetails();
    closeAnyDrawerLeftOpen();
    fetchFilteredBadges(activeSpaceId)
      .catch(() => {
        showToastError({
          str: translate("error_fetch_space_badge")
        });
      })
      .finally(() => setLoading(false));

    return reset;
  }, [activeSpaceId]);

  const triggerCreateEditModal = (isEditing: boolean) => {
    openModal(() => <CreateEditBadgeModal isEditing={isEditing} />);
  };

  const onSubmitSearch = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    filterResult(filterBadgeString);
  };

  const onChangeBadgeSearch = (value: string) => {
    setFilterBadgeString(value);
  };

  let pageContent = <S.StyledDoodleLoader theme={greyTheme} />;

  const createNewGroup = () => {
    _trackEvent(EventDomain.Group, GroupEvent.DashboardGroupsEmptyState);
    openModal(() => <GroupCreationModal />);
  };

  if (!isLoading && totalSpaceGroups === 0) {
    pageContent = (
      <SpacesNoGroups spaceName={activeSpaceName} newGroup={createNewGroup} />
    );
  }

  if (!isLoading && totalSpaceGroups) {
    pageContent = (
      <MainContainer>
        {filteredBadges.length > 0 || areFetched ? (
          <S.PageContainer>
            <ConnectYourWallet />
            <S.ActionContainer>
              <S.SearchBarWrapper>
                <SearchBar
                  theme={greyTheme}
                  label="searchQuery"
                  value={filterBadgeString}
                  onChange={event => {
                    onChangeBadgeSearch(event.target.value);
                  }}
                  placeholder={translate("space_badges_search_placeholder")}
                  onClick={onSubmitSearch}
                />
              </S.SearchBarWrapper>
              <S.CreateButton
                withIcon={{ icon: "plus" }}
                theme={greyTheme}
                variant="primary"
                onClick={() => triggerCreateEditModal(false)}
              >
                {translate("space_badges_create_badge_cta")}
              </S.CreateButton>
            </S.ActionContainer>
            <SpacesBadgesList editBadge={() => triggerCreateEditModal(true)} />
          </S.PageContainer>
        ) : (
          <SpacesBadgesListPlaceholder
            onClick={() => triggerCreateEditModal(false)}
          />
        )}
      </MainContainer>
    );
  }

  return (
    <>
      {pageContent}
      {totalSpaceGroups > 0 && (filteredBadges.length > 0 || areFetched) && (
        <S.StyledFabButton
          theme={whiteTheme}
          variant="primary"
          icon="plusThin"
          onClick={() => triggerCreateEditModal(false)}
        />
      )}
    </>
  );
};

export default observer(SpacesBadges);
