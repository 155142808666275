import { DotsMenu } from "src/js/components/DotsMenu";
import styled, { createGlobalStyle, css } from "styled-components";

export const FormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px;

  border-radius: 8px;
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 20px 20px 0px;
  }
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  div {
    margin-top: 0px;
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-top: 1px solid ${({ theme }) => theme.primaryColor[800]};
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  padding: 8px 16px;

  @media screen and (max-width: 767px) {
    background-color: transparent;
    position: fixed;
    bottom: 92px;
    border: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const CustomDotsMenu = styled(DotsMenu)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  padding: 0;
  height: 38px;
  width: 38px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey[50]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    color: ${({ theme }) => theme.colors.grey[1000]};
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

const BaseGlobalStyle = css`
  max-width: fit-content;
  border-radius: 4px;
  top: 48px;
  overflow: hidden;
`;
export const GlobalStyleAttachmentPostQuick = createGlobalStyle`
  #new-post-quick-attachments-list {
   ${BaseGlobalStyle};
    left: 0;

  }
`;

export const GlobalStyleAttachmentPost = createGlobalStyle`
  #new-post-attachments-list {
    ${BaseGlobalStyle};
    right: 0;
  }
`;
