import { Text } from "@arcadia/design-system";
import React from "react";
import { BaseChartResponse } from "src/js/types";
import styled from "styled-components";
import { handleMetricCard } from "../../../dashboard/Analytics/Analytics.utils";
import { ChipMetricTrend } from "../../../dashboard/Analytics/components/ChipMetricTrend";

const StyledMetricCard = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[500]};
  border-radius: 8px;
  padding: 16px 20px;
`;

const StyledMetricCardData = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.grey[100]};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const AnalyticsPreviewMetricCard = <T,>({
  title,
  baseChartResponse,
  measurementUnit,
  trendMeasurementUnit,
  numberOfDecimalDigits,
  resultMultiplier
}: {
  title: string;
  baseChartResponse: BaseChartResponse<T>;
  measurementUnit: string;
  trendMeasurementUnit: string;
  numberOfDecimalDigits?: number;
  resultMultiplier?: number;
}) => {
  const {
    resultLabel = null,
    trendLabel = null,
    trendSeverity = null
  } = handleMetricCard({
    baseChartResponse,
    measurementUnit,
    trendMeasurementUnit,
    numberOfDecimalDigits,
    resultMultiplier
  });

  return (
    <StyledMetricCard>
      <Text type="body">{title}</Text>
      <StyledMetricCardData>
        <Text type="formField">{resultLabel || "0"}</Text>
        <ChipMetricTrend
          trendSeverity={trendSeverity}
          label={trendLabel || "0"}
        />
      </StyledMetricCardData>
    </StyledMetricCard>
  );
};

export default AnalyticsPreviewMetricCard;
