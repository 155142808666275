import React from "react";
import { useTheme } from "styled-components";
import { Avatar, Text } from "@arcadia/design-system";
import * as S from "./StaffUserCard.styles";

const StaffUserCard = ({ firstname, lastname, avatar, email }) => {
  const { whiteTheme } = useTheme();
  return (
    <S.Card>
      <Avatar
        theme={whiteTheme}
        url={avatar}
        size="s"
        alt={`${firstname}-${lastname}`}
        rounded
      />
      <S.UserData>
        <S.NameWrapper>
          <Text type="formField">
            {firstname} {lastname}
          </Text>
        </S.NameWrapper>
        <S.Mail>
          <Text type="formDescription">{email}</Text>
        </S.Mail>
      </S.UserData>
    </S.Card>
  );
};

export default StaffUserCard;
