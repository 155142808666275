import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const Subtitle = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const AwardedBadgesContainer = styled.div`
  margin-bottom: 32px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  height: auto;
  max-height: 232px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const BlurRow = styled.div<{ shouldShow?: boolean }>`
  position: absolute;
  transition: visibility 0.5s ease;
  visibility: ${({ shouldShow }) => (shouldShow ? "visible" : "hidden")};
  bottom: 0px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.grey[1000]}00 0%,
    ${theme.colors.grey[1000]}80 44.27%,
    ${theme.colors.grey[1000]}FF 100%
  )`};
`;
