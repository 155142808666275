import { observer } from "mobx-react";
import { useInitWebSocketGroupCloning, useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import { DOMAIN, EVENT, ScormResetMessage } from "../types/models/WebSocket";
import { queryClient } from "../query";
import { scormQueryKey } from "../query/scorm";

const InitWebSocketListeners = () => {
  const {
    ChatStore: { onRemoveMessageFromList, onNewMessage, onReadMessage },
    WebSocketStore: { socket },
    AIStore: { onAICourseGenFail, onAICourseGenSuccess }
  } = useStores();
  useInitWebSocketGroupCloning();

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.DELETE_MESSAGE_EVENT,
    handler: onRemoveMessageFromList
  });

  useSocket({
    socket,
    domain: DOMAIN.SCORM,
    event: EVENT.SCORM_RESET_REQUEST,
    handler: (message: ScormResetMessage) => {
      const { data } = message;
      if (data.status === "success") {
        queryClient.invalidateQueries(scormQueryKey.all);
      }
    }
  });

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.NEW_MESSAGE_EVENT,
    handler: onNewMessage
  });

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.READ_MESSAGE_EVENT,
    handler: onReadMessage
  });

  useSocket({
    socket,
    domain: DOMAIN.AI_DOMAIN,
    event: EVENT.AI_COURSE_GENERATION_SUCCESS,
    handler: onAICourseGenSuccess
  });

  useSocket({
    socket,
    domain: DOMAIN.AI_DOMAIN,
    event: EVENT.AI_COURSE_GENERATION_FAIL,
    handler: onAICourseGenFail
  });

  return null;
};

export default observer(InitWebSocketListeners);
