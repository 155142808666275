import { Avatar, Icon, IconName, Text } from "@arcadia/design-system";
import React from "react";
import { useTheme } from "styled-components";
import { BadgeRelatedUserType, SpaceBadge } from "src/js/types";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useTranslation } from "src/js/translation";
import useDownloadBadgeCertificate from "src/js/hooks/badges/useDownloadBadgeCertificate";
import { CircularLoader } from "src/js/components/global/CircularLoader";
import * as S from "./SpaceBadgeRewardedUserModal.styles";
import SpaceAwardedBadgeImage from "../SpaceAwardedBadgeImage";

type BadgeRewardedUserProps = {
  user: BadgeRelatedUserType;
  badge: SpaceBadge;
};

type TooltipItem = {
  text: string;
  icon: IconName;
  disabled?: boolean;
  onClick: () => void;
};

const SpaceBadgeRewardedUser = ({ user, badge }: BadgeRewardedUserProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { generatePdf, isDownloading } = useDownloadBadgeCertificate();

  const {
    name,
    surname,
    avatar,
    nftReference,
    openBadgePublicUrl,
    transactionReference
  } = user;
  const userAwardedNFT = !!nftReference;
  const { image } = badge;

  const tooltipItems: TooltipItem[] = [
    {
      text: translate("space_badge_tooltip_item_badge_details"),
      onClick: () => window.open(openBadgePublicUrl),
      icon: "externalLink"
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_nft_details"),
      onClick: () => window.open(nftReference),
      icon: "externalLink"
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_blockchain_explorer"),
      onClick: () => window.open(transactionReference),
      icon: "externalLink"
    },
    {
      text: translate("badge_pdf_certificate_tooltip_item_title"),
      onClick: () => generatePdf({ badgeId: badge.id, userId: user.id }),
      icon: "download",
      disabled: isDownloading
    }
  ];

  return (
    <S.ModalUserContainer>
      <S.ModalUserAvatar>
        <Avatar
          size="s"
          theme={whiteTheme}
          alt={`${name} ${surname}`}
          url={avatar?.originalUrl ?? ""}
        />
      </S.ModalUserAvatar>
      <S.ModalUserName>
        <Text type="headerTitle">{`${name} ${surname}`}</Text>
      </S.ModalUserName>
      <TranslatedTooltip
        placement="topRight"
        customTooltip={
          <S.TooltipContainer>
            {tooltipItems
              .filter(item => item)
              .map(({ text, icon, disabled, onClick }) => (
                <S.TooltipItem
                  key={text}
                  onClick={onClick}
                  isDisable={disabled}
                >
                  <Text type="textLink">{text}</Text>
                  <Icon icon={icon} width={16} height={16} initialViewbox />
                  {isDownloading && disabled ? (
                    <S.StyledLoaderContainer>
                      <CircularLoader size={30} />
                    </S.StyledLoaderContainer>
                  ) : null}
                </S.TooltipItem>
              ))}
          </S.TooltipContainer>
        }
      >
        <S.BadgeImageContainer>
          <S.BadgeImageHoverLayer />
          <SpaceAwardedBadgeImage
            chainId={user.chainId}
            image={image}
            userAwardedNFTBadge={userAwardedNFT}
          />
        </S.BadgeImageContainer>
      </TranslatedTooltip>
    </S.ModalUserContainer>
  );
};

export default SpaceBadgeRewardedUser;
