import { Box, TabButtons } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router";

import useStores from "src/js/hooks/useStores";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { SpacesSettingsPlan } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsPlan";
import { updateSpaceSettings } from "src/js/repository/dashboardRepository";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { SpacesSettingsGeneral } from "./SpacesSettingsGeneral";
import { SpacesSettingsCustomization } from "./SpacesSettingsCustomization";

const SpacesSettings = () => {
  const { SpaceStore, SpacesSettingsStore } = useStores();
  const { option } = useParams();

  const {
    generalSettings,
    spacePlansOptions,
    getSpacePlansOptions,
    setGeneralSettings
  } = SpacesSettingsStore;
  const {
    activeSpace,
    activeSpaceId,
    activeSpaceName,
    setActiveSpaceName,
    activeSpaceDescription,
    setActiveSpaceDescription,
    setActiveSpaceBadge,
    setActiveSpaceCustomization
  } = SpaceStore;

  useEffect(() => {
    getSpacePlansOptions().catch(() => {
      showToastError({
        str: <Translate text="spaces_plan_options_error_message" />
      });
    });
  }, [activeSpace]);
  // TODO: remove this comment when endpoints v3 are ready
  // if (!activeSpace) return null;

  const customizationSelected = option === "customization";
  const planSelected = option === "plan";
  const generalSelected = !option || option === "general"; // general is selected as default if nothing else is selected

  const menuOptions = [
    {
      id: "general",
      icon: "manageContent",
      label: translateString({
        text: "spaces_settings_general_menu_label"
      })
    },
    {
      id: "customization",
      icon: "activityZone",
      label: translateString({
        text: "spaces_settings_customization_menu_label"
      })
    },
    {
      id: "plan",
      icon: "creditcard",
      label: translateString({
        text: "spaces_settings_plan_menu_label"
      })
    }
  ];

  const onNavigate = useCallback(
    id => {
      if (!id) return;

      navigateTo(
        createUrl("dashboard_settings", {
          space_id: activeSpaceId,
          option: id
        })
      );
    },
    [activeSpaceId]
  );

  const tabButtons = (
    <TabButtons
      options={menuOptions}
      selectedId={option}
      onSelected={id => onNavigate(id)}
    />
  );

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Box marginBottom="60px" width="100%">
        {generalSelected && (
          <SpacesSettingsGeneral
            tabButtons={tabButtons}
            defaultSettings={generalSettings}
            space={activeSpace?.space}
            activeSpaceName={activeSpaceName}
            activeSpaceDescription={activeSpaceDescription}
            submitInformation={data => {
              updateSpaceSettings({ ...data, spaceId: activeSpaceId })
                .then(settings => {
                  setGeneralSettings(settings);
                  setActiveSpaceBadge(settings?.customizationSettings?.badge);
                  setActiveSpaceName(data.name);
                  setActiveSpaceDescription(data.description);
                  setActiveSpaceCustomization(settings?.customizationSettings);
                  showToastSuccess({
                    str: translateString({
                      text: "space_save_settings_success_message"
                    })
                  });
                })
                .catch(() => {
                  showToastError({
                    str: <Translate text="spaces_save_settings_error_message" />
                  });
                });
            }}
          />
        )}
        {customizationSelected && (
          <SpacesSettingsCustomization tabButtons={tabButtons} />
        )}
        {planSelected && (
          <SpacesSettingsPlan
            space={activeSpace?.space}
            tabButtons={tabButtons}
            spacePlansOptions={spacePlansOptions}
            planSettings={generalSettings?.planSettings}
          />
        )}
      </Box>
    </AnimatePresence>
  );
};

export default observer(SpacesSettings);
