import {
  Avatar,
  BottomModalSheet,
  Box,
  ConfirmationAlert,
  Icon
} from "@arcadia/design-system";
import { format } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { ListItem, ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { TextEditorRender } from "src/js/components/TextEditorRender";
import { ReactionList } from "src/js/components/reactions/ReactionList";
import { ReactionPicker } from "src/js/components/reactions/ReactionPicker";
import { ResourceGridPreview } from "src/js/components/resources";
import ResourceView from "src/js/components/resources/ResourceView";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import useLongPress from "src/js/hooks/useLongPress";
import { copyToClip } from "src/js/modules/commonFunction";
import { isTouchDevice } from "src/js/modules/deviceFunction";
import { getIsMediaResource } from "src/js/modules/fileFunction";
import { showToastSuccess } from "src/js/modules/messageManager";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { REACTABLE_ENTITY, ReactionType } from "src/js/types/models/Reaction";
import { useTheme } from "styled-components";
import { getTitleUser, userIsDisabled } from "../../Thread.const";
import * as S from "./ThreadMessage.styles";
import { ThreadMessageProps } from "./ThreadMessage.types";
import { useIntersectionMarkAsRead } from "./hooks";

const ThreadMessage = ({
  messageData,
  isSender,
  isSent,
  isRead,
  error,
  removeMessageFromList,
  user,
  userNameColor,
  lastMessageDate,
  handleMarkAsRead,
  isEditMode,
  setEditableMessage,
  deleteUnsendMessage,
  retrySendMessage,
  createMessageReaction,
  deleteMessageReaction
}: ThreadMessageProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [resourceViewPosition, setResourceViewPosition] =
    useState<number>(null);
  const [viewOptions, setViewOptions] = useState<boolean>(false);
  const {
    isOpen: isOpenEmojiPicker,
    ref: emojiRef,
    setIsOpen: setIsOpenEmojiPicker
  } = useCloseOnClickOutside(false);
  const {
    isOpen: pinViewOptions,
    setIsOpen: setPinViewOptions,
    ref: pinViewRef
  } = useCloseOnClickOutside(false);
  const {
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    UserStore: { activeUser }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  useIntersectionMarkAsRead({
    ref: elementRef,
    isRead: messageData?.isRead,
    handleMarkAsRead
  });

  const showOptions = () => {
    setViewOptions(true);
  };

  const hideOptions = () => {
    setViewOptions(false);
  };

  const deleteModalConfirm = async e => {
    e.stopPropagation();
    hideOptions();

    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={<Translate text="chat_delete_message_confirm_title" />}
        declineText={<Translate text="undo_button_tooltip" />}
        onDeclineFunction={closeConfirmDialog}
        acceptText={<Translate text="confirm" />}
        onAcceptFunction={() => {
          removeMessageFromList(messageData.id);
          closeConfirmDialog();
          const overlay = document.getElementById("confirm-dialog-overlay");
          overlay.style.display = "none";
        }}
      />
    );
  };

  const activeUserHasReaction =
    messageData.reactions?.reactionLoggedUser?.userId === activeUser?.uuid ||
    messageData.reactions?.reactionLoggedUser?.userUuid === activeUser?.uuid;

  const handleReactionClick = (reactionType: ReactionType) => {
    // If user has no reaction add the reaction clicked by the user and return
    if (!activeUserHasReaction) {
      createMessageReaction({
        messageId: messageData.id,
        reactionType
      });
      return;
    }

    // remove reaction if current logged user already has one
    deleteMessageReaction({
      messageId: messageData.id,
      reactionId: messageData.reactions?.reactionLoggedUser?.id,
      reactionType: messageData.reactions?.reactionLoggedUser?.reactionType
    });
    if (
      reactionType !== messageData.reactions?.reactionLoggedUser?.reactionType
    ) {
      // user has clicked another reaction form the precious one, therefore we add it
      createMessageReaction({
        messageId: messageData.id,
        reactionType
      });
    }
  };

  const longPressEvent = useLongPress(showOptions, hideOptions, {
    shouldPreventDefault: false,
    delay: 500
  });

  const longPressEventsForMobile = isTouchDevice() && longPressEvent;

  const showDesktopOptionsContainer =
    !isTouchDevice() && (viewOptions || pinViewOptions || isEditMode);

  const allResourcesAreMedia = messageData.resources.every(resource =>
    getIsMediaResource(resource?.fileMimeType)
  );

  const isFailedMessage = messageData.error && !messageData.isSent;

  const menuItems: ListItemProps[] = isFailedMessage
    ? [
        {
          id: `${messageData?.id}-threadMessageActions-1`,
          label: translate("thread_message_action_delete"),
          icon: "trash",
          redShade: true,
          onClick: deleteUnsendMessage
        }
      ]
    : [
        {
          id: `${messageData?.id}-threadMessageActions-3`,
          label: translate("thread_message_action_edit"),
          icon: "editSecondary",
          onClick: () => setEditableMessage(messageData)
        },
        {
          id: `${messageData?.id}-threadMessageActions-1`,
          label: translate("thread_message_action_delete"),
          icon: "trash",
          redShade: true,
          onClick: deleteModalConfirm
        }
      ];

  const messageBody = (
    <S.MessageBox
      key={messageData.userId + messageData.createdAt + messageData?.id}
    >
      {messageData?.message ? (
        <S.TextEditorRenderWrapper
          editedContent={
            messageData?.updatedAt
              ? translate("thread_message_edited_label")
              : null
          }
        >
          <TextEditorRender content={messageData?.message} />
        </S.TextEditorRenderWrapper>
      ) : null}
      {messageData?.resources?.length > 0 ? (
        <S.MessageResources>
          <ResourceView.Portal>
            {typeof resourceViewPosition === "number" ? (
              <ResourceView.Content
                resources={messageData.resources}
                startingPosition={resourceViewPosition}
                onClose={() => setResourceViewPosition(null)}
              />
            ) : null}
          </ResourceView.Portal>
          {allResourcesAreMedia ? (
            <ResourceGridPreview
              resources={messageData.resources}
              onClickItem={i => setResourceViewPosition(i)}
            />
          ) : (
            <>
              {messageData.resources.map((resource, i) => (
                <S.StyledResourceItemPreview
                  key={`thread-resource-${resource?.id}`}
                  resource={resource}
                  onClick={() => setResourceViewPosition(i)}
                />
              ))}
            </>
          )}
        </S.MessageResources>
      ) : null}
      {messageData.reactions ? (
        <Box marginTop={8}>
          <ReactionList
            light
            hasInlineViewAll
            reactionsCounter={messageData.reactions}
            entity={REACTABLE_ENTITY.THREAD_MESSAGE}
            entityId={messageData.id}
            onClick={handleReactionClick}
          />
        </Box>
      ) : null}
      {showDesktopOptionsContainer || isFailedMessage ? (
        <S.OptionsMessageContainer ref={pinViewRef}>
          <div ref={emojiRef}>
            <AnimatePresence>
              {isOpenEmojiPicker && (
                <S.AnimatedWrapper
                  transition={{ type: "tween" }}
                  initial={{ top: 0, scale: 0, opacity: 0, left: -172 }}
                  animate={{ top: -56, scale: 1, opacity: 1, left: -346 }}
                  exit={{ top: 0, scale: 0, opacity: 0, left: -172 }}
                >
                  <ReactionPicker
                    onSelect={reactionType => {
                      handleReactionClick(reactionType);
                      setIsOpenEmojiPicker(false);
                    }}
                  />
                </S.AnimatedWrapper>
              )}
            </AnimatePresence>
            {messageData?.error ? (
              <S.StyledReactionButton onClick={retrySendMessage}>
                <Icon icon="refresh" initialViewbox height={20} width={20} />
              </S.StyledReactionButton>
            ) : (
              <S.StyledReactionButton
                onClick={() => {
                  setPinViewOptions(true);
                  setIsOpenEmojiPicker(val => !val);
                }}
              >
                <Icon
                  icon={isOpenEmojiPicker ? "emojiFull" : "emojiEmpty"}
                  height={20}
                  width={20}
                />
              </S.StyledReactionButton>
            )}
          </div>
          {isSender ? (
            <S.StyledDotsMenu
              id="threadMessageDotsMenu"
              items={menuItems}
              alignRight
              openFromTop
              customIcon="kebabVertical"
              onClickCustom={() => setPinViewOptions(true)}
            />
          ) : null}
        </S.OptionsMessageContainer>
      ) : null}
      <BottomModalSheet
        isOpen={isTouchDevice() && viewOptions}
        onClose={() => hideOptions()}
        onConfirm={() => hideOptions()}
        withAdaptiveHeight
        withHeaderLabels={false}
        title={null}
      >
        <S.StyledReactionPicker
          onSelect={reactionType => {
            handleReactionClick(reactionType);
            hideOptions();
          }}
          hasBubblesStyle
          selectedReaction={
            messageData.reactions?.reactionLoggedUser?.reactionType
          }
        />
        <S.OptionsMessageContainerMobile>
          {isSender ? (
            <ListItem
              id="threadMessageActions-3"
              icon="editSecondary"
              label={translate("thread_message_action_edit")}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setEditableMessage(messageData);
                hideOptions();
              }}
            />
          ) : null}
          <ListItem
            id="threadMessageActions-2"
            icon="copyImport"
            label={translate("thread_message_action_copy")}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              copyToClip(
                messageData.message
                  .replace(/&nbsp;/g, " ")
                  .replace(/&#39;/g, "'")
                  .replace(/\\r\\n/g, "<br />")
                  .trim()
              );
              showToastSuccess({
                str: translate("copied_to_clipboard")
              });
              hideOptions();
            }}
          />
          {isSender ? (
            <ListItem
              id="threadMessageActions-1"
              icon="trash"
              label={translate("thread_message_action_delete")}
              redShade
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                deleteModalConfirm(e);
                hideOptions();
              }}
            />
          ) : null}
        </S.OptionsMessageContainerMobile>
      </BottomModalSheet>
    </S.MessageBox>
  );

  const disabled = userIsDisabled(user);

  return (
    <S.MessageContainer
      active={
        viewOptions || pinViewOptions || isEditMode || !!messageData.error
      }
      disabled={disabled}
      ref={elementRef}
      hasNoUser={!user}
      onMouseEnter={!isTouchDevice() ? showOptions : undefined}
      onMouseLeave={!isTouchDevice() ? hideOptions : undefined}
      {...longPressEventsForMobile}
    >
      {user ? (
        <>
          <S.AvatarWrapper>
            <Avatar
              disabled={disabled}
              theme={whiteTheme}
              url={user?.avatar?.small}
              size="s"
              alt={user?.name + user?.surname}
            />
          </S.AvatarWrapper>
          <S.Messages>
            <Box display="flex" alignItems="center">
              <S.AuthorName color={userNameColor}>
                {getTitleUser(user, {
                  removed: translate("chat_thread_user_removed")
                })}
              </S.AuthorName>
              {isSender ? (
                <>
                  {isSent ? (
                    <S.StatusIcon
                      icon="check2"
                      initialViewbox
                      height={12}
                      width={12}
                      isRead={isRead}
                    />
                  ) : null}
                  {error ? <S.ErrorIcon icon="close" initialViewbox /> : null}
                </>
              ) : null}
              <S.TimeOfMessage type="captionInfoDetails">
                {format(lastMessageDate, "HH:mm")}
              </S.TimeOfMessage>
            </Box>
            {messageBody}
          </S.Messages>
        </>
      ) : (
        messageBody
      )}
    </S.MessageContainer>
  );
};

export default observer(ThreadMessage);
