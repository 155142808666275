import {
  CardLesson,
  Heading,
  PageNav,
  SearchBar
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { groupGetCover } from "src/js/GroupFunction";
import { useDebounce, useStores } from "src/js/hooks";
import handleThumbnails from "src/js/modules/imageFunction";
import { createUrl } from "src/js/modules/routing";
import { groupsQueryKey, useSelfJoinableGroups } from "src/js/query/groups";
import { SELF_JOINABLE_GROUP_LIST_LIMIT } from "src/js/settings/settingsPagination";
import { Trans, useTranslation } from "src/js/translation";
import { GroupDetailsModel } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import { showToastSuccess } from "src/js/modules/messageManager";
import { queryClient, useMeQueryCached } from "src/js/query";
import { selfJoinableGroupUser } from "src/js/repository/joinRepository";
import ModalEnterSelfJoinable from "./components/ModalEnterSelfJoinable";
import * as S from "./SelfJoinableGroupsList.styles";

const SelfJoinableGroupsList = () => {
  const {
    ModalStore: { openModal, closeModal },
    SpaceStore: { activeSpaceId, activeSpaceSlug, activeSpace },
    SpaceGroupListStore: { addGroupsAtStart }
  } = useStores();
  const { data: activeUser } = useMeQueryCached();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const [queryString, setQueryString] = useState("");
  const [offset, setOffset] = useState(0);
  const queryStringDebounced = useDebounce(queryString, 300);

  useEffect(() => {
    setOffset(0);
  }, [queryStringDebounced]);

  const joinGroup = (group: GroupDetailsModel) => {
    selfJoinableGroupUser({
      groupId: group.id,
      userUuid: activeUser.uuid
    }).then(() => {
      closeModal();
      navigateTo(
        createUrl("wall", {
          group_id: group.id,
          space_slug: activeSpaceSlug
        })
      );
      addGroupsAtStart([
        {
          isTeacher: false,
          group,
          space: activeSpace.space
        }
      ]);
      queryClient.invalidateQueries(groupsQueryKey.all);
      showToastSuccess({
        str: (
          <Trans
            i18nKey="enter_self_joinable_success_toaster"
            values={{
              groupName: group.name
            }}
          />
        )
      });
    });
  };

  const showJoinModal = (group: GroupDetailsModel) => {
    openModal(() => (
      <ModalEnterSelfJoinable group={group} onConfirm={joinGroup} />
    ));
  };

  const listQueryParams: {
    spaceId: string;
    offset: number;
    limit: number;
    name?: string;
  } = {
    spaceId: activeSpaceId,
    offset,
    limit: SELF_JOINABLE_GROUP_LIST_LIMIT,
    name: queryStringDebounced
  };

  const { data, isFetching } = useSelfJoinableGroups(listQueryParams);
  const { results = [], total, unfilteredTotal } = data || {};

  if (!queryStringDebounced && (!results || results.length === 0)) return null;

  return (
    <>
      <Heading level="5">
        {translate({ text: "space_self_joinable_groups_title" })} (
        {unfilteredTotal})
      </Heading>
      <S.Subtitle type="formSubtitle">
        {translate({ text: "space_self_joinable_groups_subtitle" })}
      </S.Subtitle>
      <SearchBar
        theme={greyTheme}
        label="searchQuery"
        value={queryString}
        onChange={event => {
          setQueryString(event.target.value);
        }}
        placeholder={translate("space_search_group_placeholder")}
      />
      <S.ListContainer>
        {results.map(item => {
          const { group, creator } = item || {};
          return (
            <Skeleton
              loading={isFetching}
              borderRadius={20}
              key={`${group.id}-${activeSpaceId}`}
            >
              <CardLesson
                isLoading={group?.cloningCompleted === false}
                theme={greyTheme}
                title={group.name}
                imgUrl={handleThumbnails(groupGetCover(group), "large")}
                hideOptions
                onClick={() => showJoinModal(group)}
                boardElementsLabel={`${item.boardElementsCount} ${translate("space_group_card_contents")}`}
                creator={creator}
              />
            </Skeleton>
          );
        })}
      </S.ListContainer>
      <S.RightAligner>
        <PageNav
          theme={greyTheme}
          totalRecords={total}
          pageLimit={SELF_JOINABLE_GROUP_LIST_LIMIT}
          startingOffset={offset}
          onPageChanged={p =>
            setOffset((p - 1) * SELF_JOINABLE_GROUP_LIST_LIMIT)
          }
          label={{
            previous: translate({ text: "prev" }),
            next: translate({ text: "next" })
          }}
        />
      </S.RightAligner>
    </>
  );
};

export default observer(SelfJoinableGroupsList);
