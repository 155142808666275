import { Box, Icon, IconName, ImageViewer } from "@arcadia/design-system";
import React from "react";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { GroupDetailsModel } from "src/js/types";
import styled from "styled-components";

const StyledImageViewerWrapper = styled.div`
  height: 100%;
  img {
    border-radius: 8px;
  }
`;

const StyledIconWrapper = styled.div<{ iconSize: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  bottom: -35%;
  right: -35%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

const BadgeGroupWithIcon = ({
  group,
  icon,
  iconSize,
  iconWrapperStyle,
  children
}: {
  group: GroupDetailsModel;
  icon?: IconName;
  iconSize?: number;
  iconWrapperStyle?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  return (
    <Box position="relative" width={64} height={64}>
      <StyledImageViewerWrapper>
        <ImageViewer
          alt="group badge"
          url={group?.cover?.small || group?.cover?.originalUrl}
          fallbackImage={__GROUP_COVER_DEFAULT__}
        />
      </StyledImageViewerWrapper>
      {children ?? (
        <StyledIconWrapper style={iconWrapperStyle} iconSize={iconSize}>
          <Icon initialViewbox icon={icon} height={iconSize} width={iconSize} />
        </StyledIconWrapper>
      )}
    </Box>
  );
};

export default BadgeGroupWithIcon;
