import { ReactI18NextChild } from "react-i18next";
import { SpaceType, SpaceUserRoleEnum } from "../enums";
import { ObjectValues } from "./Common";
import { CustomizationSettings } from "./Customization";
import { ImageModel } from "./Image";
import { WeschoolTemplateModel } from "./Template";

export const SpacePurpose = {
  School: "school",
  Company: "company",
  // the others purpose are deprecated from the new onboarding flow feature.
  // Since we will have old spaces with some deprecated value in the DB we
  // DO NOT delete the deprecated values for now
  University: "university",
  CorporateTraining: "corporateTraining",
  ProfessionalDevelopment: "professionalDevelopment",
  TestPreparation: "testPreparation",
  TeacherTraining: "teacherTraining",
  AcceleratorsPrograms: "acceleratorsPrograms",
  PersonalEnrichment: "personalEnrichment",
  PostgraduateEducation: "postgraduateEducation",
  Other: "other"
} as const;

export type SpacePurpose = ObjectValues<typeof SpacePurpose>;

export const BillingFrequencyType = {
  Monthly: "MONTHLY",
  Yearly: "YEARLY"
} as const;

export type BillingFrequencyType = ObjectValues<typeof BillingFrequencyType>;

export const PlanType = {
  NONE: "NONE",
  FREE: "FREE",
  LITE: "LITE",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE"
} as const;

export type PlanType = ObjectValues<typeof PlanType>;

export const SubscriptionStatusType = {
  InTrial: "in_trial",
  Active: "active",
  Cancelled: "cancelled"
} as const;

export type SubscriptionStatusType = ObjectValues<
  typeof SubscriptionStatusType
>;

export type SpaceModel = {
  description: ReactI18NextChild | Iterable<ReactI18NextChild>;
  id: string;
  name: string;
  badge: ImageModel | string;
  slug: string;
  defaultBadgeColor: number;
  purpose: string;
  spaceCover?: ImageModel;
  hasContract: boolean;
  spaceType: SpaceType;
  isOpen?: boolean;
  country?: string;
  address?: string;
  city?: string;
  province?: string;
  zip?: string;
};

export type GeneratingDemoSpaceModel = {
  badge?: Partial<ImageModel> | string;
  defaultBadgeColor?: number;
  name?: string;
};

export type SpaceSettingsMinimal = {
  blocked: boolean;
  disableChat: boolean;
  dashboardGroupsEnabled: boolean;
  planType: PlanType;
};

export type SpaceDetailsModel = {
  groupsCount: number;
  usersCount: number;
  space: SpaceModel;
  spaceSettings: SpaceSettingsMinimal;
  role: SpaceUserRoleEnum;
  isTemporary: boolean;
  isInTrial?: boolean;
  enableAnalytics?: boolean;
  trialDaysLeft?: number;
  spaceAdminUuid?: string;
  customizationSettings?: CustomizationSettings;
};

export type SingleSpaceModel = Omit<
  SpaceDetailsModel,
  "usersCount" | "groupsCount"
>;

export type SpaceListModel = {
  space: SpaceModel;
  role: SpaceUserRoleEnum;
  unreadNotificationCount: number;
  unreadThreadMessagesCount: number;
};

export type SpacePlanSettingsModel = {
  blocked: boolean;
  customerId: string;
  dashboardCustomGroupsCoverEnabled: boolean;
  dashboardCustomPaletteEnabled: boolean;
  dashboardGroupsEnabled: boolean;
  dashboardUsersEnabled: boolean;
  enableAnalytics: boolean;
  planBilling: BillingFrequencyType;
  planPriceAmount: number;
  planPriceCurrency: string;
  planSeats: number;
  planType: PlanType;
  subscriptionId: boolean;
  subscriptionStatus: SubscriptionStatusType;
};

export type SpaceDetailsMinimal = Pick<
  SpaceModel,
  "name" | "badge" | "defaultBadgeColor"
>;

export type SpaceTemplatesModel = {
  customTemplates: WeschoolTemplateModel[];
  weschoolTemplates: WeschoolTemplateModel[];
};
