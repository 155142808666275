import colors from "@ds_themes/colors";
import { Box } from "@ds_universal/Box";
import { Input } from "@ds_universal/data_entry";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  EventDomain,
  ToastMessage,
  ToastVariant
} from "src/js/types";
import styled, { DefaultTheme } from "styled-components";
import { useLinkInvite } from "../hooks";
import FakeInput from "./FakeInput";
import IconButton from "./IconButton";

export const ColoredText = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`;

const StyledInput = styled(Input)<{ errorLayout?: boolean }>`
  & > div {
    margin-top: 0px;
    & > input {
      min-height: 48px;
      border: ${({ errorLayout }) =>
        errorLayout ? `1px solid ${colors.error[400]}` : undefined};
    }
  }
`;

type InputGroupCodeProps = {
  theme: DefaultTheme;
  isDisabled: boolean;
  isVisualDisabled?: boolean;
  handleTextCopy: (text: string, toast?: ToastMessage) => void;
};

const InputEditGroupAlias = ({
  theme,
  isDisabled,
  isVisualDisabled,
  handleTextCopy
}: InputGroupCodeProps) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { alias }
  } = useStores();

  const [inputAlias, setInputAlias] = useState<string>(alias);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { updateGroupAliasHandler } = useLinkInvite();

  useEffect(() => {
    if (isDisabled) {
      setIsEditing(false);
    }
  }, [isDisabled]);

  const onSaveAlias = async () => {
    if (alias === inputAlias) {
      setIsEditing(false);
      return;
    }
    setIsLoading(true);
    try {
      await updateGroupAliasHandler({ groupAlias: inputAlias });
      setIsEditing(false);
    } catch (error) {
      setInputAlias(alias);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      await onSaveAlias();
    }
  };

  if (isEditing) {
    return (
      <StyledInput
        rounded
        autofocus
        theme={theme}
        value={inputAlias}
        onChange={event => {
          setInputAlias(event?.target?.value?.replace(/\s+/g, ""));
        }}
        onKeyPress={handleKeyPress}
        rightSection={
          <Box display="flex" gap="8px" marginLeft="auto">
            <IconButton
              style={{ padding: "0 4px" }}
              disabled={isLoading}
              onClick={onSaveAlias}
            >
              <Text>{translate("save")}</Text>
            </IconButton>
            <IconButton
              disabled={isLoading}
              onClick={() => setIsEditing(false)}
            >
              <Icon icon="close" />
            </IconButton>
          </Box>
        }
        disabled={isLoading}
        hideLabel
        label=""
        ref={inputRef}
      />
    );
  }

  return (
    <FakeInput
      disabled={isDisabled}
      onEdit={isDisabled ? null : () => setIsEditing(true)}
      onCopy={() => {
        handleTextCopy(inputAlias, {
          variant: ToastVariant.Success,
          id: "invite-group-code-toast",
          duration: 2000,
          children: translate("invite_modal_link_group_toast_copied")
        });
        _trackEvent(EventDomain.AddMember, AddMemberEvent.GroupCodeCopied);
      }}
    >
      {!isVisualDisabled
        ? inputAlias
        : translate("invite_modal_link_invite_group_code_disabled")}
    </FakeInput>
  );
};

export default InputEditGroupAlias;
