import { Box, PageNav } from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { ReactElement, useState } from "react";
import { useStores } from "src/js/hooks";
import { useGroupsProgress } from "src/js/query/analytics";
import { useTranslation } from "src/js/translation";
import { DashboardEvent, EventDomain } from "src/js/types";
import { useTheme } from "styled-components";
import { CustomTooltip } from "src/js/components/CustomTooltip";
import { handleMetricCard } from "../../../Analytics.utils";
import { LegendItem } from "../../../components/CustomLegend/CustomLegend";
import { CustomTick } from "../../../components/CustomTick";
import { EmptyMetric } from "../../../components/EmptyMetric";
import { HorizontalStackedBarChart } from "../../../components/HorizontalStackedBarChart";
import { HorizontalStackedBarChartData } from "../../../components/HorizontalStackedBarChart/HorizontalStackedBarChart";
import { MetricCard } from "../../../components/MetricCard";
import { useAnalyticsFilter } from "../../../hooks";
import { GroupTooltipProps } from "../GroupTooltip";

const CourseProgress = ({
  noGroupsButton
}: {
  noGroupsButton?: ReactElement;
}) => {
  const {
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { primaryColor, whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { fromDate, toDate, aggregation, groupIds } = useAnalyticsFilter();

  const [offset, setOffset] = useState<number>(0);

  const pageLimit = 6;

  const { data: response, isFetching: loading } = useGroupsProgress({
    spaceId: activeSpaceId,
    offset,
    limit: pageLimit,
    withChartData: !isLayoutModeMobile,
    fromDate,
    toDate,
    aggregation,
    groupIds
  });

  const isEmpty =
    !response?.results || response?.results.length === 0 || !!noGroupsButton;

  const {
    resultLabel = null,
    trendLabel = null,
    trendSeverity = null
  } = !isEmpty
    ? handleMetricCard({
        baseChartResponse: response,
        measurementUnit: "%",
        trendMeasurementUnit: translate(
          "analytics_percentage_point_abbreviation"
        )
      })
    : {};

  const data: HorizontalStackedBarChartData[] = response?.results.map(
    result => {
      const tierData = result.data.map(item => {
        const value = Math.round(item.value / 100);
        const total = Math.round(result.totalUsers / 100);
        // used floor to guarantee that the total length in the chart is under 100%
        const percentageValue = total
          ? Math.floor((value / total) * 1000) / 10
          : 0;
        return {
          percentageValue,
          description: translate({
            text: "course_progress_chart_tooltip_description",
            stringVariables: {
              percentageValue,
              value,
              total,
              range: item.label
            }
          })
        };
      });

      return {
        name: result.group.name,
        tier0: tierData[0]?.percentageValue,
        tier1: tierData[1]?.percentageValue,
        tier2: tierData[2]?.percentageValue,
        tier3: tierData[3]?.percentageValue,
        description: [
          tierData[0]?.description,
          tierData[1]?.description,
          tierData[2]?.description,
          tierData[3]?.description
        ]
      };
    }
  );

  const courseProgressLegend: LegendItem[] = [
    {
      name: translate({
        text: "course_progress_chart_label",
        stringVariables: {
          range: "100%"
        }
      }),
      color: primaryColor[300]
    },
    {
      name: translate({
        text: "course_progress_chart_label",
        stringVariables: {
          range: "50-99%"
        }
      }),
      color: primaryColor[500]
    },
    {
      name: translate({
        text: "course_progress_chart_label",
        stringVariables: {
          range: "1-49%"
        }
      }),
      color: primaryColor[700]
    },
    {
      name: translate({
        text: "course_progress_chart_label",
        stringVariables: {
          range: "0%"
        }
      }),
      color: primaryColor[900]
    }
  ];

  const groups: GroupTooltipProps[] = response?.results.map(result => {
    const { id, name, cover } = result.group || {};
    return {
      id,
      name,
      cover,
      totalUsers: Math.round(result.totalUsers / 100)
    };
  });

  const defineCustomTick = props => {
    return <CustomTick {...props} groups={groups} />;
  };

  return (
    <MetricCard
      loading={loading}
      titleLabel={translate(
        "metric_card_objectives_overall_course_progress_title"
      )}
      resultLabel={resultLabel}
      descriptionLabel={translate(
        "metric_card_objectives_overall_course_progress_description"
      )}
      trendLabel={trendLabel}
      trendSeverity={trendSeverity}
      dateLabel={translate({
        text: "metric_card_objectives_date_not_change_label",
        stringVariables: {
          date: format(new Date(toDate), "dd/MM/yyyy")
        }
      })}
      infoTooltip={
        <CustomTooltip
          maxWidth="224px"
          title={translate(
            "metric_card_objectives_overall_course_progress_info_tooltip_title"
          )}
          description={translate(
            "metric_card_objectives_overall_course_progress_info_tooltip_description"
          )}
        />
      }
      trackingEvents={{
        tooltip: {
          domain: EventDomain.SpaceDashboardUsage,
          action: DashboardEvent.CourseprogressViewInfo
        }
      }}
    >
      {isEmpty ? (
        <EmptyMetric
          title={translate("empty_metric_no_data_available")}
          description={translate(
            !noGroupsButton
              ? "empty_metric_change_time_period"
              : "empty_metric_no_groups"
          )}
        >
          {noGroupsButton}
        </EmptyMetric>
      ) : null}
      {!isEmpty && !isLayoutModeMobile ? (
        <>
          <HorizontalStackedBarChart
            data={data}
            legend={courseProgressLegend}
            customTick={props => defineCustomTick(props)}
          />
          <Box
            display="block"
            marginLeft="auto"
            marginBottom={response?.total > pageLimit ? -20 : 0}
            width="max-content"
          >
            <PageNav
              theme={whiteTheme}
              onPageChanged={p => {
                setOffset((p - 1) * pageLimit);
              }}
              totalRecords={response?.total}
              pageLimit={pageLimit}
              startingOffset={offset}
              label={{ previous: translate("prev"), next: translate("next") }}
            />
          </Box>
        </>
      ) : null}
    </MetricCard>
  );
};

export default observer(CourseProgress);
