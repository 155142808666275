import { fetchThreadMessages } from "src/js/repository/threadsRepository";
import { BasePaginationResponse } from "src/js/types";
import {
  BackendThreadMessage,
  ThreadMessage,
  ThreadUser
} from "src/js/types/models/Thread";

export const ID_NEW_THREAD_ITEM = "sidebar-thread-new";
export const FETCH_LIMIT_MESSAGES = 50;
export const ERROR_MESSAGE_TIMEOUT = 10000;

export function userIsDisabled(u: ThreadUser) {
  return u?.hasLeft || u?.isRemoved;
}

export function getTitleUser(
  user: ThreadUser,
  translations: { removed: string }
): string {
  if (!user) return "";
  if (user?.isRemoved) return translations?.removed;
  return `${user?.name} ${user?.surname}${user?.hasLeft ? " 🚫" : ""}`;
}

export function getTitleFromUsers(
  users: ThreadUser[],
  translations: { removed: string }
): string {
  const validUsers = users?.filter(user => !user.isRemoved);
  if (validUsers?.length === 0) {
    return translations?.removed;
  }
  const names = validUsers?.map(user => getTitleUser(user, translations));
  return names?.join(", ") || "";
}

export function getUsersWithoutMe(
  users: ThreadUser[],
  activeUserUuid: string
): ThreadUser[] {
  return users?.filter(user => user.uuid !== activeUserUuid);
}

export function getAvatarData(usersWithoutMe: ThreadUser[]): {
  disabled: boolean;
  url: string;
} {
  const firstActiveUser = usersWithoutMe?.find(u => !userIsDisabled(u));
  if (firstActiveUser) {
    return { disabled: false, url: firstActiveUser.avatar?.small };
  }
  return { disabled: true, url: null };
}

export function getPlaceholderUsersList(
  users: ThreadUser[],
  activeUserUuid: string
): string {
  if (!activeUserUuid || !users) return "";
  const usersWithoutMe = getUsersWithoutMe(users, activeUserUuid);
  const userList = usersWithoutMe.map(user => ` ${user.name} ${user.surname}`);
  return userList ? userList.toString() : "";
}

export const getThreadMessagesFromOldestUnread = async ({
  spaceId,
  threadId,
  oldestUnreadMessage,
  lastMessage
}: {
  spaceId: string;
  threadId: string;
  oldestUnreadMessage: ThreadMessage;
  lastMessage: ThreadMessage;
}) => {
  let threadMessagesData: BasePaginationResponse<BackendThreadMessage>;
  if (lastMessage?.id !== oldestUnreadMessage?.id) {
    threadMessagesData = await fetchThreadMessages({
      spaceId,
      threadId,
      toId: lastMessage?.id,
      fromId: oldestUnreadMessage?.id
    });
    threadMessagesData.results = [
      lastMessage,
      ...threadMessagesData.results,
      oldestUnreadMessage
    ];
    if (threadMessagesData?.results?.length >= FETCH_LIMIT_MESSAGES) {
      return threadMessagesData;
    }
  }

  const threadOldestMessagesData = await fetchThreadMessages({
    spaceId,
    threadId,
    toId: oldestUnreadMessage?.id,
    limit: FETCH_LIMIT_MESSAGES
  });
  threadOldestMessagesData.results = [
    ...threadMessagesData?.results,
    ...threadOldestMessagesData?.results
  ];
  return threadOldestMessagesData;
};
