import styled, { css } from "styled-components";

export const Column = styled.div<{ large?: boolean; small?: boolean }>`
  color: ${({ theme }) => theme.colors.grey[50]};
  width: 20%;
  padding-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 30%;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 15%;
    `}
`;

export const TriggerRowArea = styled.span`
  width: calc(100% - 44px);
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TopMobile = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent4};
  padding: 24px 24px 8px;
  display: flex;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const TopMobileTitle = styled.div`
  flex: 1;
`;

export const TopMobileActionContainer = styled.div`
  margin-left: 20px;
`;

export const GroupName = styled.button<{ isActive?: boolean }>`
  all: unset;
  font-size: 16px;
  font-weight: 500;
  display: block;
  @media screen and (min-width: 992px) {
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.primaryColor[500]}!important;
      }
    `}
`;

export const GroupNameWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 12px;
  @media screen and (min-width: 992px) {
    gap: 8px;
    margin-bottom: 0;
  }
`;

export const CheckIcon = styled.span`
  color: ${({ theme }) => theme.colors.success[500]};
  font-size: 16px;
`;

export const UsersCounter = styled.span`
  font-size: 16px;
  @media screen and (max-width: 991px) {
    text-align: right;
  }
`;

export const BottomMobile = styled.div`
  width: 100%;
  padding: 24px 32px;
  font-size: 14px;

  > div > div {
    padding-left: 0px;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const BottomMobileTitle = styled.div`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.accent2};
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const TabletAndUp = styled.span`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

export const HideOnXS = styled.span`
  @media screen and (min-width: 320px) {
    display: none;
  }

  @media screen and (min-width: 992px) {
    display: block;
  }
`;

export const ShowOnXS = styled.span`
  @media screen and (min-width: 320px) {
    display: block;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PropertyLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
`;

export const GroupNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 992px) {
    > span {
      padding-bottom: 1px;
    }
  }
`;
