import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useMutation, useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { Box } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { InputPhone } from "src/js/components/forms/InputPhone";
import {
  publicProfileQueryKey,
  queryClient,
  usePublicProfileSettingsCached
} from "src/js/query";
import countryCodeJSON from "src/js/components/forms/InputPhone/countryCodes.json";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { savePublicProfileSettings } from "src/js/repository/publicProfileRepository";
import { Country } from "src/js/components/forms/InputPhone/types";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { ModalBody } from "../Modal";
import { __EDIT_PHONE_SCHEMA__ } from "./UserAccountChangePhoneModal.utils";

const UserAccountChangePhoneModal = () => {
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme, whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { data: publicProfileSettings } = usePublicProfileSettingsCached();
  const [countryDirty, setCountryDirty] = useState(false);
  const { register, formState, watch, handleSubmit, setValue } = useForm<{
    phone: string;
  }>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      phone: publicProfileSettings?.phone?.split(" ")[1]
    }
  });
  const [country, setCountry] = useState<Country>({
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39"
  });
  const phone = watch("phone");

  useEffect(() => {
    if (!publicProfileSettings?.phone) return;
    setValue("phone", publicProfileSettings?.phone.split(" ")[1]);
    const defaultCountry = countryCodeJSON.find(
      ct => ct.dial_code === publicProfileSettings?.phone.split(" ")[0]
    );
    if (defaultCountry) setCountry(defaultCountry);
  }, [publicProfileSettings?.phone]);

  const isDirty = useMemo(() => {
    return formState.dirty || countryDirty;
  }, [formState.dirty]);

  const isValidPhone = useMemo(() => {
    const valid = __EDIT_PHONE_SCHEMA__.phone.pattern.value.test(phone);
    return valid && phone.length > 0;
  }, [phone]);

  const { mutate: updatePhone } = useMutation(
    async (phoneNumber: string) => {
      const formattedPhone = `${country.dial_code} ${phoneNumber}`;
      await savePublicProfileSettings({ phone: formattedPhone });
    },
    {
      onSuccess: () => {
        showToastSuccess({
          str: translate("account_change_phone_success")
        });
        queryClient.invalidateQueries(publicProfileQueryKey.settings);
        closeModal();
      },
      onError(error) {
        showToastError({
          str: error.message
        });
      }
    }
  );

  const handleSubmission = handleSubmit(vars => {
    return updatePhone(vars.phone);
  });

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{ title: translate("account_change_phone_title") }}
      mobileProps={{ closeLabel: "Close" }}
    >
      <ModalBody
        onSubmit={handleSubmission}
        formId="change-phone-form"
        isDisabled={!formState.isValid}
      >
        <Box minHeight="30dvh" marginTop={20}>
          <InputPhone
            label={translate("public_profile_phone_label")}
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            country={country}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onChangeCountry={country => {
              setCountryDirty(true);
              setCountry(country);
            }}
            name="phone"
            ref={register(__EDIT_PHONE_SCHEMA__.phone)}
            error={{
              message: translate({
                text:
                  !isValidPhone && isDirty
                    ? "phone_number_validation_error"
                    : undefined
              })
            }}
          />
        </Box>
      </ModalBody>
    </ModalLayout>
  );
};

export default observer(UserAccountChangePhoneModal);
