import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupCreationModal } from "../../GroupCreationModal";
import { SpaceCreationModal } from "../../SpaceCreation";
import { CtaType, WarningBannerProps } from "./types";

import * as S from "./WarningBanner.styles";
import { GroupJoinWithCodeModal } from "../../GroupJoinWithCodeModal";

/**
 * @name WarningBanner
 *
 * @description WarningBanner component
 */

const WarningBanner = ({ text, cta, margin, maxWidth }: WarningBannerProps) => {
  const { translate } = useTranslation();
  const { ModalStore } = useStores();

  const handleModal = () => {
    ModalStore.closeModal();
    switch (cta?.type) {
      case CtaType.GroupCreation:
        ModalStore.openModal(() => <GroupCreationModal />);
        break;
      case CtaType.GroupCreationByInvitation:
        ModalStore.openModal(() => (
          <GroupJoinWithCodeModal closeModal={ModalStore.closeModal} />
        ));
        break;
      case CtaType.SpaceCreation:
        ModalStore.openModal(() => <SpaceCreationModal />);
        break;
      default:
        break;
    }
  };

  const ctaComponent =
    cta?.type === CtaType.Link ? (
      cta?.key
    ) : (
      <S.StyledLinkButton onClick={handleModal}>
        {translate(cta?.key)}
      </S.StyledLinkButton>
    );
  return (
    <S.BannerContainer margin={margin} maxWidth={maxWidth}>
      <Icon icon="dangerCircle" width={18} height={18} />
      <Text>
        {translate(text)} {ctaComponent}
      </Text>
    </S.BannerContainer>
  );
};

export default WarningBanner;
