import React from "react";
import { Button, Icon, Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { createUrl } from "src/js/modules/routing";
import { useStores } from "src/js/hooks";
import { navigateTo } from "Legacy/modules/history";
import { useTranslation } from "src/js/translation";
import * as S from "./LogoutModal.styles";
import { ModalLayout } from "../ModalLayout";

const LogoutModal = () => {
  const { whiteTheme } = useTheme();
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  return (
    <ModalLayout
      mobileProps={{
        withAdaptiveHeight: true,
        withHeaderLabels: false,
        withDrag: true
      }}
      labels={{
        title: translate("logout_modal_title"),
        mobileClose: translate("logout_modal_title")
      }}
      closeModal={closeModal}
    >
      <S.ModalBody>
        <S.IllustrationContainer>
          <Icon
            icon="illustration-logout"
            height={145}
            width={145}
            viewbox="0 0 151 147"
          />
        </S.IllustrationContainer>
        <S.LogoutText level="4">{translate("logout_modal_text")}</S.LogoutText>
        <S.ModalButtonsContainer>
          <Button
            variant="primary"
            theme={whiteTheme}
            fullWidth
            onClick={() => {
              navigateTo(createUrl("logout"));
              closeModal();
            }}
          >
            {translate("logout_modal_action_button")}
          </Button>
          <Box
            onClick={e => {
              e.stopPropagation();
              closeModal();
            }}
          >
            <S.Cancel type="textLink">
              {translate("logout_modal_cancel_button")}
            </S.Cancel>
          </Box>
        </S.ModalButtonsContainer>
      </S.ModalBody>
    </ModalLayout>
  );
};

export default observer(LogoutModal);
