import {
  Box,
  ContextualAlert,
  Divider,
  Heading,
  RadioGroup,
  Text
} from "@arcadia/design-system";
import React from "react";
import { NumberSelector } from "src/js/components/forms/NumberSelector";
import { useTranslation } from "src/js/translation";
import { AssessmentsGradingMethod } from "src/js/types/models/Customization";
import { useTheme } from "styled-components";

import * as S from "../../../SpacesSettingsCustomization.styles";

const AssessmentGrading = ({
  editMode,
  setGradingMethod,
  gradingMethod,
  normalizedGradingMethod,
  setNormalizedThreshold,
  gradingThreshold
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <>
      <Heading level="5">
        {translate("space_settings_general_assessment_grading")}
      </Heading>
      <Box marginTop="12px">
        <Text type="formSubtitle">
          {translate("space_settings_general_assessment_grading_subtitle")}
        </Text>
      </Box>
      <Box marginTop="12px">
        <RadioGroup
          theme={whiteTheme}
          options={[
            {
              label: translate(
                "space_settings_general_assessment_grading_decimal"
              ),
              value: AssessmentsGradingMethod.Decimal,
              disabled: !editMode
            },
            {
              label: translate(
                "space_settings_general_assessment_grading_percentage"
              ),
              value: AssessmentsGradingMethod.Percentage,
              disabled: !editMode
            }
          ]}
          orientationOption="column"
          orientationLabel="right"
          onChangeFunction={value => setGradingMethod(value)}
          value={gradingMethod}
          textType="body"
        />
      </Box>
      <Box marginTop={24} marginBottom={24}>
        <Divider theme={whiteTheme} color="grey" width="100%" />
      </Box>
      <Heading level="5">
        {translate("space_settings_general_completion_threshold")}
      </Heading>
      <Box marginTop="12px" display="flex" alignItems="center" gap={12}>
        <NumberSelector
          {...normalizedGradingMethod}
          disabled={!editMode}
          onChange={setNormalizedThreshold}
        />
        <Text type="formSubtitle">
          {translate("space_settings_general_completion_threshold_label")}
        </Text>
      </Box>
      <S.FullWidthContextualAlertWrapper>
        <ContextualAlert
          theme={whiteTheme}
          text={translate(
            gradingThreshold === 0
              ? "space_settings_general_completion_threshold_zero_warning"
              : "space_settings_general_completion_threshold_default_warning"
          )}
        />
      </S.FullWidthContextualAlertWrapper>
    </>
  );
};

export default AssessmentGrading;
