import {
  Box,
  Button,
  ContextualAlert,
  Icon,
  ImageViewer,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { Trans, useTranslation } from "src/js/translation";
import { GroupDetailsModel } from "src/js/types";
import { Mixin } from "@ds_themes/mixin";
import styled, { useTheme } from "styled-components";
import { useGroupStats } from "src/js/query";

const StyledTextSubtitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;

const StyledTextDescription = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

const StyledImageViewerWrapper = styled.div`
  height: 100%;
  width: 64px;
  height: 64px;
  img {
    border-radius: 8px;
  }
`;

const StyledAlertContainer = styled.div`
  & > div:first-child {
    max-width: 100%;
  }
`;

const StyledDesktopButton = styled(Button)`
  ${Mixin.mobile`display: none;`}
`;

const StyledStatsContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  @media screen and (max-width: 767px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

const StyledStatsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${({ theme }) => theme.colors.grey[100]};
`;

type ModalEnterSelfJoinableProps = {
  group: GroupDetailsModel;
  onConfirm: (groupId: GroupDetailsModel) => void;
};

const ModalEnterSelfJoinable = ({
  group,
  onConfirm
}: ModalEnterSelfJoinableProps) => {
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const { data } = useGroupStats({ groupId: group.id });
  const { boardsCount, boardElementsCount, exercisesCount, membersCount } =
    data || {};

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate({ text: "enter_self_joinable_modal_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        padding="16px 20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          margin="auto"
          {...(!isLayoutModeMobile && {
            padding: "24px 0px 32px"
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <StyledImageViewerWrapper>
              <ImageViewer
                alt="group badge"
                url={group?.cover?.small || group?.cover?.originalUrl}
                fallbackImage={__GROUP_COVER_DEFAULT__}
              />
            </StyledImageViewerWrapper>
          </Box>
          <StyledTextSubtitle>{group.name}</StyledTextSubtitle>
          <StyledTextDescription>{group.description}</StyledTextDescription>
          <StyledStatsContainer>
            <StyledStatsBlock>
              <Icon icon="book" height={16} width={16} />
              {boardsCount}
            </StyledStatsBlock>
            <StyledStatsBlock>
              <Icon icon="document" height={16} width={16} />
              {boardElementsCount}
            </StyledStatsBlock>
            <StyledStatsBlock>
              <Icon icon="weight" height={16} width={16} />
              {exercisesCount}
            </StyledStatsBlock>
            <StyledStatsBlock>
              <Icon icon="userEmpty" height={14} width={14} />
              {membersCount}
            </StyledStatsBlock>
          </StyledStatsContainer>
          <StyledAlertContainer>
            <ContextualAlert
              theme={whiteTheme}
              text={<Trans i18nKey="enter_self_joinable_modal_warning" />}
            />
          </StyledAlertContainer>
        </Box>
        <Box display="flex" gap={8}>
          <StyledDesktopButton
            variant="secondary"
            theme={whiteTheme}
            fullWidth
            onClick={closeModal}
          >
            {translate("enter_self_joinable_modal_cta_cancel")}
          </StyledDesktopButton>
          <Button
            variant="primary"
            theme={whiteTheme}
            fullWidth
            onClick={() => onConfirm(group)}
          >
            {translate("enter_self_joinable_modal_cta_confirm")}
          </Button>
        </Box>
      </Box>
    </ModalLayout>
  );
};

export default observer(ModalEnterSelfJoinable);
