import { useCallback, useState } from "react";
import { useStores } from "src/js/hooks";
import { __EMAIL_REGEX__ } from "src/js/settings/settingsInput";

const useEmailInvite = ({
  inviteLimit,
  handleLimitReached
}: {
  inviteLimit: number;
  handleLimitReached: () => void;
}) => {
  const {
    UserInviteStore: { removeUserFromList, updateUserList, usersToInvite }
  } = useStores();
  const [usersInvited, setUsersInvited] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [invitationSent, setInvitationSent] = useState<boolean>(false);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const lastCharacter = val[val.length - 1] || "";
      const SPECIAL_CHARACTERS = [",", " "];
      const hasSpecial = SPECIAL_CHARACTERS.includes(lastCharacter);
      const hasEmail = val.match(__EMAIL_REGEX__) !== null;
      if (hasEmail && hasSpecial) {
        const validEmails = val.match(__EMAIL_REGEX__);
        const remainingSlots = inviteLimit - usersToInvite.length;
        if (remainingSlots > 0) {
          updateUserList(...validEmails);
        } else {
          handleLimitReached();
        }
        setInputValue("");
      } else {
        setInputValue(val.replace(/[,\s]/g, ""));
      }
    },
    [inputValue, usersToInvite, inviteLimit, handleLimitReached]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      if (key === "Backspace" && inputValue === "") {
        const copy = [...usersToInvite];
        const removedEmail = copy.pop();
        removeUserFromList(removedEmail);
      }
    },
    [inputValue, usersToInvite]
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedData = e.clipboardData.getData("Text");
      const hasEmail = pastedData.match(__EMAIL_REGEX__) !== null;

      if (hasEmail) {
        e.preventDefault();
        const validEmails = pastedData.match(__EMAIL_REGEX__);
        const remainingSlots = inviteLimit - usersToInvite.length;
        const emailsToAdd = validEmails.slice(0, remainingSlots);
        emailsToAdd.forEach(email => updateUserList(email));
        setInputValue("");
        if (remainingSlots <= 0 || validEmails.length > remainingSlots) {
          handleLimitReached();
        }
      }
    },
    [usersToInvite, inviteLimit, handleLimitReached]
  );

  return {
    onChange,
    onKeyDown,
    onPaste,
    setIsFocused,
    setInvitationSent,
    setUsersInvited,
    inputValue,
    isFocused,
    invitationSent,
    usersInvited
  };
};

export default useEmailInvite;
