/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useCallback, useEffect, useState } from "react";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";

import { useGroupFeatures, useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { queryClient } from "src/js/query";
import { groupsQueryKey } from "src/js/query/groups";
import {
  getBaseStudentOptions,
  getBaseTeacherOptions,
  getOnDeleteOrLeave
} from "../utils";

export const useOptions = () => {
  const { translate } = useTranslation();
  const { hideMembers } = useGroupFeatures();

  const {
    GroupStore: { groupId, roles, permissionToCustomization },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    SpaceGroupListStore: { removeGroupFromList },
    SpaceStore: {
      activeSpace,
      isAdmin,
      removeSpaceFromSpaceList,
      cleanLastActiveSpaceLocalStorage
    }
  } = useStores();
  const { whiteTheme } = useTheme();

  const isCreator = roles.includes("ROLE_CREATOR");
  const isTeacher = roles.includes("ROLE_TEACHER");

  const [options, setOptions] = useState<ListItemProps[]>(null);

  useEffect(() => {
    let fullOptions = addDeleteOrLeaveOptions({
      options: isTeacher
        ? getBaseTeacherOptions({ groupId, translate })
        : getBaseStudentOptions({ groupId, translate }),
      isCreator
    });

    fullOptions = addSectionsOption({
      options: fullOptions
    });

    fullOptions = fullOptions.filter(
      option => !hideMembers || option.id !== "view-members"
    );

    setOptions(fullOptions);
  }, [groupId, hideMembers]);

  const addSectionsOption = useCallback(
    ({ options }: { options: ListItemProps[] }) => {
      const fullOptions: ListItemProps[] = options.slice(0);

      if (permissionToCustomization) {
        fullOptions.splice(3, 0, {
          id: "sections",
          icon: "activityZone",
          label: translate("header_group_settings_customize_label"),
          onClick: () =>
            navigateTo(
              createUrl("group_settings", {
                group_id: groupId,
                option: "sections"
              })
            )
        });
      }

      return fullOptions;
    },
    [groupId, permissionToCustomization]
  );

  const addDeleteOrLeaveOptions = ({
    options,
    isCreator
  }: {
    options: ListItemProps[];
    isCreator: boolean;
  }) => {
    const optionsAux = options.slice(0);
    const { deleteGroup, removeMe } = getOnDeleteOrLeave({
      groupId,
      translate
    });

    const deleteOrLeaveOption: ListItemProps = {
      icon: "trash",
      label: translate(
        isCreator ? "group_delete_button" : "group_leave_button"
      ),
      id: isCreator ? "delete-group" : "leave-group",
      redShade: true,
      onClick: () =>
        openConfirmDialog(
          <ConfirmationAlert
            theme={whiteTheme}
            text={translate(
              isCreator ? "group_delete_message" : "group_leave_message"
            )}
            declineText={translate("Annulla")}
            onDeclineFunction={closeConfirmDialog}
            acceptText={translate("Conferma")}
            onAcceptFunction={() => {
              if (isCreator) {
                deleteGroup();
              } else {
                removeMe();
              }
              queryClient.invalidateQueries(groupsQueryKey.all);
              const newList = removeGroupFromList({ groupId });
              if (newList.length === 0 && !isAdmin && activeSpace) {
                removeSpaceFromSpaceList(activeSpace.space.id);
                cleanLastActiveSpaceLocalStorage();
                navigateTo(createUrl("home"));
              } else {
                navigateTo(
                  createUrl("space_groups", {
                    space_slug: activeSpace?.space?.slug
                  })
                );
              }

              closeConfirmDialog();
            }}
          />
        )
    };

    optionsAux.splice(optionsAux.length, 0, deleteOrLeaveOption);

    return optionsAux;
  };

  return { options };
};
