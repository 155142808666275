import { Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";

import { UserAwardedBadgeType } from "src/js/types";
import { AwardedBadgeCard } from "../AwardedBadgeCard";
import * as S from "./ModalAwardedBadges.styles";

type ModalAwardedBadgesProps = {
  userAwardedBadges: UserAwardedBadgeType[];
  hideAwardedBadge: (
    badgeId: string,
    requestForSpecificSpace?: boolean
  ) => void;
  requestForSpecificSpace?: boolean;
};

const ModalAwardedBadges = ({
  userAwardedBadges,
  hideAwardedBadge,
  requestForSpecificSpace
}: ModalAwardedBadgesProps) => {
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate({ text: "user_awarded_badge_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <S.ModalBody>
        <S.HeaderWrapper>
          <div>
            <Text type="formField">
              {translate("user_awarded_badge_subtitle")}
            </Text>
          </div>
        </S.HeaderWrapper>
        <S.ScrollableArea>
          {userAwardedBadges.map(badge => {
            return (
              <AwardedBadgeCard
                key={badge.id}
                badge={badge}
                hideAwardedBadge={() =>
                  hideAwardedBadge(badge.id, requestForSpecificSpace)
                }
                hasBorder
              />
            );
          })}
        </S.ScrollableArea>
      </S.ModalBody>
      <S.BlurRow />
    </ModalLayout>
  );
};

export default observer(ModalAwardedBadges);
