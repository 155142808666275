import { DOMAIN, EVENT } from "src/js/types/models/WebSocket";
import { TranslationKeys } from "src/js/translation";
import { extractWebSocketMessage } from "../websocket/useSocket";

type PromiseRes = {
  isValid: boolean;
  error?: TranslationKeys;
};

export const checkSCORMValidityAsync = ({
  socket,
  scormUuid
}: {
  socket: WebSocket;
  scormUuid: string;
}) => {
  const eventTypeFail = DOMAIN.SCORM + EVENT.UploadFailed;
  const eventTypeSuccess = DOMAIN.SCORM + EVENT.UploadSuccess;

  let onScormMessage: (msg: MessageEvent<string>) => void;

  const scormPromise = new Promise<PromiseRes>(resolve => {
    onScormMessage = msg => {
      const extractedData = extractWebSocketMessage<
        "uploadFailed" | "uploadSuccess"
      >(msg);
      if (!extractedData) {
        socket.removeEventListener("message", onScormMessage);
        return resolve({
          isValid: false,
          error: "SCORM_message_event_empty_error"
        });
      }
      const { socketEventType, newMessage } = extractedData;

      if (newMessage.data.scormId !== scormUuid) {
        // In case of multiple uploads, we may receive the wrong event, we just ignore it in that case
        return null;
      }
      if (eventTypeFail === socketEventType) {
        socket.removeEventListener("message", onScormMessage);
        return resolve({ isValid: false, error: newMessage.data.error });
      }
      if (eventTypeSuccess === socketEventType) {
        socket.removeEventListener("message", onScormMessage);
        return resolve({ isValid: true });
      }
      return resolve({
        isValid: false,
        error: "SCORM_message_unknown_event_type_error"
      });
    };

    socket.addEventListener("message", onScormMessage);
  });

  // Fallback in case of the lambda is not responding, or if we missed the event
  const timeoutPromise = new Promise<PromiseRes>(resolve => {
    setTimeout(() => {
      socket.removeEventListener("message", onScormMessage);
      resolve({ isValid: false, error: "timeout_upload_SCORM_error" });
    }, 30000); // 30 sec timeout
  });

  return Promise.race([scormPromise, timeoutPromise]);
};
