import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { boardResourceOpened } from "src/js/repository/boardRepository";
import { useMutation, useStores } from "src/js/hooks";
import { useBoardElementDetails } from "src/js/query/boardElements/useBoardElementDetails";
import { useExerciseByIdV1 } from "src/js/query/exercises";
import { BoardElementDetail, RESOURCE_TYPE } from "src/js/types";
import { getContentFromResource } from "src/legacy/view/resource/utils";
import { useBoardResources } from "src/legacy/view/board/hooks/useBoardResources";
import { useBoardContext } from "src/js/context/BoardProvider";
import {
  shouldUpdateLocalBoardElementCompletion,
  trackInteractions
} from "../utils";
import useBoardElementNavigation from "./useBoardElementNavigation";

const useModuleResourceViewer = ({
  indexDefault,
  onClose
}: {
  indexDefault: number;
  onClose: () => void;
}) => {
  const [resourceComponent, setResourceComponent] =
    useState<React.ReactNode>(null);
  const {
    BoardsStore: { updateBoardElementViewById },
    GroupStore: { settings, checkIfUserIsTeacher },
    UserStore: { activeUser }
  } = useStores();
  const { element_id: resourceId } = useParams<{
    element_id?: string;
  }>();
  const { activeBoard } = useBoardContext();

  const {
    data: boardElement,
    isLoading: isLoadingBoardElement,
    error: boardElementError
  } = useBoardElementDetails(resourceId, {
    enabled: !!resourceId,
    retry(_, error) {
      return error?.response?.status !== 403;
    },
    refetchOnWindowFocus: false
  });

  const canFetchExercise = useMemo(() => {
    if (!boardElement) return false;
    return (
      boardElement?.resource.type === RESOURCE_TYPE.EXERCISE &&
      "exerciseId" in boardElement.resource
    );
  }, [boardElement]);

  const { data: exercise, isLoading: isLoadingExercise } = useExerciseByIdV1(
    boardElement?.resource.exerciseId,
    "best",
    { enabled: canFetchExercise }
  );

  const { boardElements: allBoardElements } = useBoardResources({
    isDraft: false,
    board: activeBoard
  });

  const {
    currentIndex,
    onNextBoardElement,
    onPrevBoardElement,
    reportAbuse,
    showComments,
    toggleComments
  } = useBoardElementNavigation({
    boardElement,
    indexDefault,
    allBoardElements
  });

  const isResourceEditable = useMemo(
    () =>
      !!checkIfUserIsTeacher(activeUser.uuid) ||
      activeUser.uuid === boardElement?.user.uuid,
    [boardElement, activeUser]
  );

  const areCommentsDisabled = useMemo(
    () => settings.boardComments === "disabled" || !!boardElementError,
    [settings, boardElementError]
  );

  const shouldShowFooter = useMemo(
    () =>
      (allBoardElements.length > 1 || !!boardElement?.id) && !boardElementError,
    [allBoardElements, boardElement, boardElementError]
  );

  const { mutate: setBoardElementAsViewed } = useMutation(
    async (boardElementToUpdate: BoardElementDetail) => {
      boardResourceOpened(
        boardElementToUpdate.boardId,
        boardElementToUpdate.id
      );
    },
    {
      // TODO: Implement optimistic update
      onSuccess: boardElementToUpdate => {
        updateBoardElementViewById(activeBoard, boardElementToUpdate.id);
      }
    }
  );

  const prevBoardElementId = useRef(null);

  useEffect(() => {
    // TODO this useEffect runs twice in some cases, resulting a multiple "trackInteractions" calls
    // using ref just to make sure it's run once, but this definetly need to be fixed
    if (prevBoardElementId.current !== boardElement?.id) {
      prevBoardElementId.current = boardElement?.id;
    }

    if (!boardElement || (canFetchExercise && isLoadingExercise)) return;

    if (
      shouldUpdateLocalBoardElementCompletion(
        activeBoard.board_elements,
        boardElement
      )
    ) {
      setBoardElementAsViewed(boardElement);
    }

    const resourceComponentUnresolved = getContentFromResource({
      resource: exercise ? undefined : boardElement.resource,
      boardElementId: Number(boardElement.id),
      boardElementOwnerUuid: boardElement.user.uuid,
      exercise,
      onClose
    });
    if (resourceComponentUnresolved instanceof Promise) {
      resourceComponentUnresolved.then((resolved: React.ReactNode) => {
        setResourceComponent(resolved);
      });
    } else {
      setResourceComponent(resourceComponentUnresolved);
    }
    trackInteractions(boardElement.resource);
  }, [boardElement, canFetchExercise, exercise]);

  return {
    allBoardElements,
    areCommentsDisabled,
    boardElement,
    currentIndex,
    isLoadingBoardElement,
    isResourceEditable,
    onNextBoardElement,
    onPrevBoardElement,
    reportAbuse,
    resourceComponent,
    shouldShowFooter: shouldShowFooter && !isLoadingBoardElement,
    showComments,
    toggleComments,
    boardElementError
  };
};

export default useModuleResourceViewer;
