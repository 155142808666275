import { Dropdown } from "@ds_universal/data_entry";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { CityDTO, CountryDTO } from "src/js/repository/locationRepository";
import { Text } from "@ds_universal/Text";
import { useStores } from "src/js/hooks";
import { useCountries } from "../hooks/useCountries";
import { useCities } from "../hooks/useCities";

import * as S from "./CountryAndCityPicker.styles";

type Props = {
  country?: CountryDTO;
  city?: CityDTO;
  setCountry: (val: CountryDTO) => void;
  setCity: (val: CityDTO) => void;
  countryLabel?: TranslationKeys | null;
  cityLabel?: TranslationKeys | null;
  openFromTop?: boolean;
};
const CountryAndCityPicker = ({
  country = null,
  city = null,
  setCountry,
  setCity,
  countryLabel = null,
  cityLabel = null,
  openFromTop = false
}: Props) => {
  const { whiteTheme, greyTheme } = useTheme();
  const [countryFilterString, setCountryFilterString] = useState("");
  const [cityFilterString, setCityFilterString] = useState("");
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const { countries = [] } = useCountries();

  const { cities = [] } = useCities({
    options: { enabled: !!country?.id },
    countryId: country?.id
  });

  return (
    <S.Wrapper>
      <S.Field>
        {countryLabel ? (
          <Text type="formSubtitle">{translate(countryLabel)}</Text>
        ) : null}
        <Dropdown
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          placeholder={translate("country_dropdown_placeholder")}
          fromTop={openFromTop}
          optionsList={countries
            .map(({ id, name }) => {
              return { id, label: name };
            })
            .filter(({ label }) =>
              label.toLowerCase().includes(countryFilterString.toLowerCase())
            )}
          searchable
          setSelectedOptionId={id => {
            setCountry(countries.find(country => country.id === id));
            setCountryFilterString("");
          }}
          onSearchFunction={e => {
            setCountry(null);
            setCity(null);
            setCountryFilterString(e.target.value);
          }}
          searchPlaceholder={translate("country_dropdown_search_placeholder")}
          selectedOptionId={country?.id}
        />
      </S.Field>
      <S.Field>
        {cityLabel ? (
          <Text type="formSubtitle">{translate(cityLabel)}</Text>
        ) : null}
        <Dropdown
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          placeholder={translate("city_dropdown_placeholder")}
          fromTop={openFromTop}
          optionsList={cities
            .map(({ id, name }) => {
              return { id, label: name };
            })
            .filter(({ label }) =>
              label.toLowerCase().includes(cityFilterString.toLowerCase())
            )}
          searchable
          disabled={!country?.id}
          setSelectedOptionId={id => {
            setCity(cities.find(city => city.id === id));
            setCityFilterString("");
          }}
          onSearchFunction={e => {
            setCity(null);
            setCityFilterString(e.target.value);
          }}
          searchPlaceholder={translate("city_dropdown_search_placeholder")}
          selectedOptionId={city?.id}
        />
      </S.Field>
    </S.Wrapper>
  );
};

export default CountryAndCityPicker;
