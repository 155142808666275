import { Heading, Link, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { NoticeRadioElement } from "src/js/components/notice/NoticeRadioElement";
import { useStores } from "src/js/hooks";
import { createStaticUrl } from "src/js/modules/routing";
import { updateGroupNotificationSettings } from "src/js/repository/noticeSettingsRepository";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  AppPreference,
  EmailPreference,
  NotificationSettingsModel
} from "src/js/types/models/Notification";
import { useTheme } from "styled-components";
import { AppPreferenceRadioList, EmailPreferenceRadioList } from "./utils";

import * as S from "./GroupSettingsNotice.styles";

const GroupSettingsNotice = () => {
  const {
    GroupStore: {
      activeGroup: group,
      notificationSettingsPreference,
      setGroupNoticeSettings
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const settingsUrl = `#${createStaticUrl("user_notification_settings")}`;
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  const updateNotificationSettings = useCallback(
    ({ appPreference, emailPreference }: NotificationSettingsModel) => {
      updateGroupNotificationSettings({
        groupId: group.id,
        appPreference,
        emailPreference
      }).then(newSettings => {
        setGroupNoticeSettings(newSettings);
      });
    },
    [group.id]
  );

  const updateEmailSettings = useCallback(
    (emailPreference: EmailPreference) => {
      updateNotificationSettings({
        appPreference: notificationSettingsPreference?.appPreference,
        emailPreference
      });
    },
    [updateNotificationSettings, notificationSettingsPreference?.appPreference]
  );

  const updateAppSettings = useCallback(
    (appPreference: AppPreference) => {
      updateNotificationSettings({
        appPreference,
        emailPreference: notificationSettingsPreference?.emailPreference
      });
    },
    [
      updateNotificationSettings,
      notificationSettingsPreference?.emailPreference
    ]
  );

  return (
    <>
      <S.PageTitle>
        <Heading level="5">{translate("group_settings_page_title")}</Heading>
      </S.PageTitle>
      <S.SectionTitle>
        {isLayoutModeMobile ? (
          <Text type="formField">
            {translate("group_settings_paragraph_title_inapp")}
          </Text>
        ) : (
          <Heading level="6">
            {translate("group_settings_paragraph_title_inapp")}
          </Heading>
        )}
      </S.SectionTitle>
      <S.SectionRadio>
        {AppPreferenceRadioList.map(
          ({ icon, value, title, description }, i) => (
            <NoticeRadioElement
              icon={icon}
              key={icon + value}
              even={i % 2 === 0}
              isSelected={
                notificationSettingsPreference?.appPreference === value
              }
              onClick={updateAppSettings}
              value={value}
              title={translate(title)}
              description={translate(description)}
            />
          )
        )}
      </S.SectionRadio>
      <S.SectionTitle>
        {isLayoutModeMobile ? (
          <Text type="formField">
            {translate("group_settings_paragraph_title_email")}
          </Text>
        ) : (
          <Heading level="6">
            {translate("group_settings_paragraph_title_email")}
          </Heading>
        )}
      </S.SectionTitle>
      <S.SectionRadio>
        {EmailPreferenceRadioList.map(
          ({ icon, value, title, description }, i) => (
            <NoticeRadioElement
              icon={icon}
              key={icon + value}
              even={i % 2 === 0}
              isSelected={
                notificationSettingsPreference?.emailPreference === value
              }
              onClick={updateEmailSettings}
              value={value}
              title={translate(title)}
              description={translate(description)}
            />
          )
        )}
      </S.SectionRadio>
      <S.BottomLinkContainer>
        <Link theme={greyTheme} href={settingsUrl} rel="noopener noreferrer">
          {translate("group_settings_general_notification_link")}
        </Link>
      </S.BottomLinkContainer>
    </>
  );
};

export default observer(GroupSettingsNotice);
