import { ContextualAlert, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const FileUploadWrapper = styled.div<{ dropActive: boolean }>`
  width: 100%;
  height: 220px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: 1px dashed ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-style: ${({ dropActive }) => (dropActive ? "solid" : "dashed")};
  &:hover {
    border-style: solid;
  }

  @media (max-width: 767px) {
    height: 150px;
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const UploadMessage = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  text-align: center;
`;

export const HiddenInput = styled.input`
  display: none !important;
`;

export const TextWrapper = styled.div`
  max-width: 188px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const FullWidthContextualAlert = styled(ContextualAlert)`
  margin-top: 12px;
  max-width: 100%;
`;

export const ErrorWrapper = styled.div`
  margin-top: 12px;
`;
