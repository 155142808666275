export default {
  activateAccount: "/activateaccount/:option?",
  agenda: "/:space_slug?/group/:group_id/calendar/agenda/:date?",
  auth: "/authFlow/:auth_code",
  externalAuth: "/auth",
  home: "/home",
  calendar: "/:space_slug?/group/:group_id/calendar",
  confirmRegistration: "/users/me/registration/confirm/:token",
  confirmRegistrationWithAlias:
    "/users/me/registration/confirm/:token/alias/:alias",
  deaImportLesson: "/dea/import/:project_id/:node_id",
  deaImportProject: "/dea/import/:project_id",
  eventActivity: "/:space_slug?/group/:group_id/calendar/activity/:event_id",
  eventDeadline: "/:space_slug?/group/:group_id/calendar/deadline/:event_id",
  eventLive: "/:space_slug?/group/:group_id/calendar/live/:event_id",
  groupBlocked: "/:space_slug?/group/:group_id/blocked",
  groupCreate: "/group/new/:group_id?",
  groupOverquotaBlocked: "/:space_slug?/group/:group_id/overquotablocked",
  groupSettings: "/:space_slug?/group/:group_id/settings/:option",
  login: "/login/:token?/:client_id?",
  loginUniversity: "/loginuniversity",
  modules: "/:space_slug?/group/:group_id/modules",
  notifications: "/:space_slug?/notifications",
  loginSso: "/sso",
  dashboardGroups: "/dashboard/:space_id/groups",
  dashboardAnalytics: "/dashboard/:space_id/analytics",
  dashboardUsers: "/dashboard/:space_id/users",
  dashboardSettings: "/dashboard/:space_id/settings/:option?",
  parentApprobation: "/underageapprovation/:code",
  privacyPolicy: "/privacy-policy",
  publicProfile: "/users/:userUuid/profile",
  publicProfileEdit: "/settings/profile/edit",
  register: "/register/:token?",
  resetPassword: "/users/me/password/reset/confirm/:token",
  requestPassword: "/password/request",
  search: "/search/:keyword?/:filter?/:filter?",
  spaceGroups: "/:space_slug/groups", // space slug is mandatory for the main page of a space
  userConfirmMail: "/change-mail-confirm/:token",
  userAccountMobileSettings: "/settings/account/:option",
  share: "/share",
  whiteboard: "/:space_slug?/group/:group_id/whiteboard"
};
