import { ConfirmationAlert } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";

import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { handleGroupErrorMessage } from "../context/utils/errorHandler";
import { showToastSuccess } from "../modules/messageManager";
import createUrl from "../modules/routing";
import { useTranslation } from "../translation";
import { ActiveGroupFull } from "../types";
import {
  DOMAIN,
  EVENT,
  TemplatingGroupCloningCompleted
} from "../types/models/WebSocket";
import useSocket from "./websocket/useSocket";

const ConfirmationAlertWrap = styled.div`
  > div {
    background-color: ${({ theme }) => theme.colors.success[600]};
  }
`;

const useInitWebSocketGroupCloning = () => {
  const {
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    WebSocketStore: { socket },
    SpaceStore: { activeSpaceSlug },
    GroupStore: { activeGroup, fetchGroup, setActiveGroupFull },
    SpaceGroupListStore: { fetchActiveSpaceGroups, activeSpaceGroups }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const fetchActiveGroup = () => {
    const groupId = activeGroup?.id;
    fetchGroup(groupId)
      .then((response: ActiveGroupFull) => {
        setActiveGroupFull(response);
      })
      .catch(() => {
        handleGroupErrorMessage();
      });
  };

  const onGroupCloningCompleted = ({
    data
  }: TemplatingGroupCloningCompleted) => {
    if (activeSpaceSlug) {
      fetchActiveSpaceGroups(activeSpaceSlug);
    }
    if (activeGroup?.id === data?.groupId) {
      fetchActiveGroup();
      showToastSuccess({
        str: translate("toast_ask_new_group_redirect", {
          group: activeGroup?.name
        })
      });
      return;
    }
    const newSpaceGroup = activeSpaceGroups.find(
      el => el?.group?.id === data?.groupId
    );
    openConfirmDialog(
      <ConfirmationAlertWrap>
        <ConfirmationAlert
          theme={whiteTheme}
          text={translate("toast_ask_new_group_redirect", {
            group: newSpaceGroup?.group?.name
          })}
          declineText={translate("toast_ask_new_group_redirect_cta_cancel")}
          onDeclineFunction={closeConfirmDialog}
          acceptText={translate("toast_ask_new_group_redirect_cta_confirm")}
          onAcceptFunction={() => {
            closeConfirmDialog();
            navigateTo(createUrl("wall", { group_id: data?.groupId }));
          }}
        />
      </ConfirmationAlertWrap>
    );
  };

  useSocket({
    socket,
    domain: DOMAIN.TEMPLATING_DOMAIN,
    event: EVENT.GROUP_CLONING_COMPLETED,
    handler: onGroupCloningCompleted
  });
};

export default useInitWebSocketGroupCloning;
