import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { matchPath, useLocation } from "react-router";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { DashboardSecondaryHeader } from "src/js/components/layout/DashboardSecondaryHeader";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";

export const useDashboardLayoutHeader = () => {
  const location = useLocation();
  const {
    SpaceStore: { activeSpace }
  } = useStores();
  const {
    UIStore: { isLayoutModeMobile: isMobile }
  } = useStores();
  const [headerComponent, setHeaderComponentInner] = useState<ReactNode>(
    <DashboardSecondaryHeader />
  );
  const [headerComponentManual, setHeaderComponentManual] =
    useState<ReactNode>();

  const setHeaderComponent = useCallback((component: ReactNode) => {
    setHeaderComponentManual(component);
  }, []);

  const resetHeaderComponent = useCallback(() => {
    setHeaderComponentManual(undefined);
  }, []);

  useEffect(() => {
    if (headerComponentManual) {
      setHeaderComponentInner(headerComponentManual);
    } else if (
      matchPath(location.pathname, {
        path: WeSchoolRoutes.App.Dashboard.settings
      })
    ) {
      setHeaderComponentInner(
        <HeaderBack
          stringTitle={activeSpace?.space?.name}
          onGoBack={() =>
            navigateTo(
              createUrl(
                activeSpace?.enableAnalytics
                  ? "dashboard_analytics"
                  : "dashboard_staff",
                {
                  space_id: activeSpace?.space?.id
                }
              )
            )
          }
        />
      );
    } else {
      setHeaderComponentInner(<DashboardSecondaryHeader />);
    }
  }, [location.pathname, headerComponentManual, isMobile]);

  return {
    headerComponent,
    setHeaderComponent,
    resetHeaderComponent
  };
};
