import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./ProgressRowLabel.styles";

const ProgressRowLabel = ({ label, icon, value }) => {
  return (
    <S.ProgressRowLabelEl>
      <S.IconContainer>
        <Icon icon={icon} />
      </S.IconContainer>
      <S.ContentWrapper>
        <S.RowLabel>{label}</S.RowLabel>
        <S.RowValue>{value}</S.RowValue>
      </S.ContentWrapper>
    </S.ProgressRowLabelEl>
  );
};

export default ProgressRowLabel;
