import { AvatarWithInitials, Box, Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PreventNavigation } from "src/js/components/global/PreventNavigation";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { useDashboardLayout } from "src/js/layout/DashboardLayout";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import createUrl from "src/js/modules/routing";
import { useTheme } from "styled-components";
import { useFormLogic } from "./hooks";
import { EditSaveButton } from "./components/EditSaveButton";
import {
  DirectMessage,
  AssessmentGrading,
  CustomFields
} from "./components/sections";

import * as S from "./SpacesSettingsCustomization.styles";

export type SpaceSettingsCustomizationProps = {
  tabButtons: JSX.Element;
};

const SpaceSettingsCustomization = ({
  tabButtons
}: SpaceSettingsCustomizationProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpace },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const {
    editMode,
    hasChanges,
    onClickEditOrSave,
    gradingMethod,
    gradingThreshold,
    normalizedGradingMethod,
    setGradingMethod,
    setNormalizedThreshold,
    disableChatState,
    setDisableChat,
    customFields,
    setCustomFields,
    setTargetAudience,
    targetAudience
  } = useFormLogic();
  const { setHeaderComponent, resetHeaderComponent } = useDashboardLayout();

  useEffect(() => {
    setHeaderComponent(
      <HeaderBack
        onGoBack={() =>
          navigateTo(
            createUrl("dashboard_analytics", {
              space_id: activeSpace?.space?.id
            })
          )
        }
        stringTitle={activeSpace?.space?.name}
      />
    );
    return () => {
      resetHeaderComponent();
    };
  }, [
    editMode,
    activeSpace?.space?.id,
    activeSpace?.space?.name,
    isLayoutModeMobile
  ]);

  return (
    <>
      <PreventNavigation
        shouldBlock={hasChanges}
        promptText="space_settings_unsaved_changes_warning_text"
        acceptText="space_settings_unsaved_changes_accept_cta"
        declineText="space_settings_unsaved_changes_decline_cta"
        onNavigateCb={path => {
          navigateTo(path);
        }}
      />
      <S.FormStyled>
        <MainContainer paddingTop="10px" paddingTopMobile="72px">
          <S.AvatarWrapper>
            <S.AvatarWithInitialsWrapper>
              <AvatarWithInitials
                theme={whiteTheme}
                alt={activeSpace?.space?.name}
                url={
                  (activeSpace?.space?.badge?.small as unknown as
                    | string
                    | null) ?? ""
                }
                name={activeSpace?.space?.name ?? ""}
                avatarColor={activeSpace?.space?.defaultBadgeColor}
                size="big"
              />
            </S.AvatarWithInitialsWrapper>
          </S.AvatarWrapper>
          {tabButtons}
          <S.IntroWrapper>
            <Box
              width={isLayoutModeMobile ? "100%" : "376px"}
              display="flex"
              flexDirection="column"
              gap="8px"
            >
              <Heading level="2">
                {translate("spaces_settings_customization_heading")}
              </Heading>
              <Text type="formSubtitle">
                {translate("spaces_settings_customization_description")}
              </Text>
            </Box>

            <EditSaveButton editMode={editMode} onClick={onClickEditOrSave} />
          </S.IntroWrapper>
          <S.ContentBox>
            <AssessmentGrading
              editMode={editMode}
              gradingMethod={gradingMethod}
              gradingThreshold={gradingThreshold}
              normalizedGradingMethod={normalizedGradingMethod}
              setGradingMethod={setGradingMethod}
              setNormalizedThreshold={setNormalizedThreshold}
            />
          </S.ContentBox>
          <S.ContentBox>
            <DirectMessage
              editMode={editMode}
              disableChat={disableChatState}
              setDisableChat={setDisableChat}
            />
          </S.ContentBox>
          <S.ContentBox>
            <CustomFields
              customFields={customFields}
              setCustomFields={setCustomFields}
              targetAudience={targetAudience}
              setTargetAudience={setTargetAudience}
              editMode={editMode}
            />
          </S.ContentBox>
          <S.FooterButtonWrapper>
            <EditSaveButton editMode={editMode} onClick={onClickEditOrSave} />
          </S.FooterButtonWrapper>
        </MainContainer>
      </S.FormStyled>
    </>
  );
};

export default observer(SpaceSettingsCustomization);
