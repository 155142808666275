import {
  ActionButton,
  Box,
  Button,
  ConfirmationAlert,
  Icon,
  colors
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import last from "lodash/last";
import React from "react";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { handleAlert } from "src/js/modules/alertManager";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { getQueryParamFromFragment } from "src/js/modules/localization";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { withTheme } from "styled-components";
import swal from "sweetalert";
import withBackbone from "with-backbone";
import QuizCollection from "../../../collections/quizzesCollection";
import { modalPage } from "../../../models/modalPageModel";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { InternalDispatcher } from "../../../modules/dispatcher";
import { navigateTo } from "../../../modules/history";
import { instants } from "../../../modules/instant";
import { __ } from "../../../modules/localization";
import { createURL } from "../../../modules/utility";
import ExerciseEngineProgressBar from "./exerciseEngine_progressbar";
import ExerciseEngineQuiz from "./exerciseEngine_quiz_base";
import ExerciseEngineSplash from "./exerciseEngine_splash";
import ExerciseEngineSummary from "./exerciseEngine_summary";
import { HideTopBannerErrorConnection } from "src/js/components/layout/TopBannerErrorConnection/TopBannerErrorConnection";

const ExerciseEngine = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        autoStart: false
      };
    },
    getInitialState() {
      this.quizzes = new QuizCollection(this.props.exercise.getQuizzes());
      return {
        status: "beforeStart",
        btnStatus: "",
        quizStatus: "",
        current: 0,
        dateStart: 0,
        diff: 0,
        exercise: this.props.exercise
      };
    },
    componentWillMount() {
      const self = this;
      const { exercise } = this.props;
      modalPage.hide();
      if (exercise.get("shuffle")) {
        exercise.shuffleQuiz();
      }
      self.quizzes = new QuizCollection(exercise.getQuizzes());
    },
    componentDidMount() {
      const self = this;
      let confirmText = __("exercise_run_confirmation_text");

      if (this.props.exercise.get("type") == "EXAMINATION") {
        confirmText = __("exercise_examination_run_confirmation_text");
      }

      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", self.historyPushState);

      InternalDispatcher.on("route", this.setQuizFromUrl);

      InternalDispatcher.on("instant_manual_stop", obj => {
        if (self.state.status == "beforeStart") {
          navigateTo(self.getBackRoute(), { trigger: true });
        } else if (
          self.state.status != "review" &&
          self.state.status != "review_pending" &&
          instants.getActiveInstantId() > 0
        ) {
          self.stop();
        }
      });
      if (self.props.match.params.option == "autostart") {
        self.setState({ status: "running" });
        self.start();
      }
    },
    historyPushState() {
      history.pushState(null, null, document.URL);
    },
    componentDidUpdate() {
      if (
        (this.state.status == "review" ||
          this.state.status == "review_pending") &&
        instants.getActiveInstantId() > 0
      ) {
        instants.resetActiveInstantId();
      }
    },
    componentWillUnmount() {
      const self = this;
      window.removeEventListener("popstate", self.historyPushState);

      clearInterval(window.exerciseTimer);
      InternalDispatcher.off("route", self.setQuizFromUrl);
      InternalDispatcher.off("instant_manual_stop");
    },
    setQuizFromUrl(route) {
      if (route === "exercisesRun") {
        const hash = window.location.hash;
        const tokens = hash.split("/");
        const quiz_number = last(tokens);
        if (isNumber(parseInt(quiz_number))) {
          if (quiz_number < this.state.current) this.prevQuiz();
          if (quiz_number > this.state.current) this.nextQuiz();
        }
      }
    },
    getTime() {
      const self = this;
      const dateStart = this.state.dateStart;
      const dateNow = new Date().getTime();
      const diff = dateNow - dateStart;
      const timeThreshold = this.props.exercise.getTimeThreshold();

      if (timeThreshold > 0) {
        const timeLeft = timeThreshold - Math.floor(diff / 1000);
        if (timeLeft > 0) {
          return timeLeft;
        }
        // countdown a zero. chiamo fine.
        self.respondQuiz(true);
        this.stop();
        return 0;
      }
      return Math.floor(diff / 1000);
    },
    getCurrentQuiz() {
      return this.quizzes.at(this.state.current);
    },
    setCurrentInnerQuiz(quiz) {
      this.currentInnerquiz = quiz;
    },
    prevQuiz() {
      try {
        swal.close(); // chiudo tutti i messaggi di conferma eventualmente presenti.
      } catch (e) {
        console.log("error", e);
      }
      $("html, body").animate({ scrollTop: 0 }, 0);
      if (this.props.exercise.isVerification()) {
        this.stopQuiz(); // quiz in corso è finito
        const prev = this.state.current - 1;
        if (prev >= 0) {
          this.setState({ current: prev }, function () {
            this.startQuiz(); // inizializzo il nuovo quiz
          });
        } else {
          this.setState({ current: 0 }, function () {
            this.startQuiz(); // inizializzo il nuovo quiz
          });
        }
      } else {
        // do nothing, nell'esercizio normale non si può tornare indietro
        console.warn("You can't go back in a standard exercise");
      }
    },
    // skipAll è una forzatura chiamata solo nella callback del retry in caso di mancanza di connesione
    nextQuiz(skipAll) {
      $("html, body").animate({ scrollTop: 0 }, 0);
      const self = this;
      let next = 0;
      if (this.isLastQuiz() || skipAll) {
        this.stop();
      } else {
        // vado avanti...
        next = this.state.current + 1;
        if (next < self.quizzes.length) {
          this.setState({ current: next }, function () {
            this.startQuiz(); // inizializzo il nuovo quiz
          });
        } else {
          this.setState({ current: self.quizzes.length - 1 }, function () {
            this.startQuiz(); // inizializzo il nuovo quiz
          });
        }
      }
    },
    showFeedback(result) {
      // result = CORRECT || WRONG || REVIEW
      if (this.props.exercise.isExercise()) {
        // feedback solo su esercizi standard
        const $el = $("#feedback");
        if (result === "CORRECT") {
          // esercizio corretto
          $el.html("");
          $el.html(
            `<div class="exen__feedback exen__feedback-success"><span class="exen__feedback-text">${__("WELL DONE!")}</span></div>`
          );
        } else if (result === "REVIEW") {
          $el.html("");
          $el.html(
            `<div class="exen__feedback exen__feedback-review"><span class="exen__feedback-text">${__("risposta inviata")}</span></div>`
          );
        } else {
          // esercizio errato
          $el.html("");
          $el.html(
            `<div class="exen__feedback exen__feedback-error"><span class="exen__feedback-text">${__("OUCH!")}</span></div>`
          );
        }
        setTimeout(() => {
          $(".exen__feedback").fadeOut(200, () => {
            $el.html("");
          });
        }, 1000);
      }
    },
    checkQuiz(force) {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;
      const forceNext = !!(force !== undefined && force === true);
      try {
        const quiz = this.getCurrentQuiz();

        if (quiz.isFullyAnswered() || forceNext) {
          if (self.isLastQuiz()) {
            self.confirmLastCheck();
          } else {
            self.respondQuiz(force);
          }
        } else {
          // alert
          let copyAlert = __("answer_not_given_forward_alert");

          if (this.props.exercise.isVerification()) {
            copyAlert = __("answer_examination_not_given_forward_alert");
          }
          ConfirmDialogStore.openConfirmDialog(
            <ConfirmationAlert
              theme={whiteTheme}
              text={copyAlert}
              declineText={__("Annulla")}
              onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
              acceptText={__("Conferma")}
              onAcceptFunction={() => {
                if (self.isLastQuiz()) {
                  setTimeout(self.confirmLastCheck, 200);
                } else {
                  self.respondQuiz(force);
                }
                ConfirmDialogStore.closeConfirmDialog();
              }}
            />
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    // skipAll è una forzatura chiamata solo nella callback del retry in caso di mancanza di connessione
    respondQuiz(force, skipAll = false) {
      const self = this;
      const forceNext = !!(force !== undefined && force === true);
      const quiz = this.getCurrentQuiz();
      this.setState({ btnStatus: "loading" });

      const callback_ok = function () {
        // da eseguire dopo eventuale correzione
        this.setState({ status: "running" });
        this.setState({ btnStatus: "" });
        const quiz = this.getCurrentQuiz();
        if (quiz.needsReview()) {
          this.showFeedback("REVIEW");
        } else {
          this.showFeedback("CORRECT");
        }
        this.nextQuiz(skipAll);
      }.bind(this);

      const callback_error = function () {
        // da eseguire dopo eventuale correzione
        // se sbagliato visualizzo messaggio errore
        // e do la possibilità di continuare lo stesso di ripetere il quiz
        this.showFeedback("WRONG");
        this.setState({
          status: "running",
          btnStatus: ""
        });
        this.nextQuiz(skipAll);
      }.bind(this);

      // preparo chiamata al server e mi metto in ascolto sulla risposta
      // oppure faccio i miei controlli
      // IF step by step-->
      // controllo se esecuzione è lato server...
      if (this.props.exercise.isStepByStep() && !forceNext) {
        // correggo quiz
        if (this.props.exercise.isCorrectedInBackend()) {
          this.stopQuiz(callback_ok, callback_error); // aspetto risposta dal server
        } else if (
          quiz.checkUserAnswer(this.props.exercise.get("success_threshold"))
        ) {
          this.stopQuiz(callback_ok, callback_error); // quiz in corso è finito. Non aspetto risposta perchè correzione è in locale
        } else {
          this.stopQuiz(callback_error, callback_error);
        }
      } else {
        this.stopQuiz(callback_ok, callback_error); // quiz in corso è finito. Non aspetto risposta perchè correzione è a fine esercizio
      }
    },
    confirmLastCheck() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;
      let alertText = __("confirm_test_stop");
      if (self.props.exercise.isVerification()) {
        alertText = `${__("confirm_test_stop")} ${__("confirm_test_stop_warning")}`;
      }

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={alertText}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            self.respondQuiz();
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    isLastQuiz() {
      return this.state.current === this.quizzes.length - 1;
    },
    setSession(info) {
      this.props.exercise.setSession(info);
      this.session = info;
    },
    getSession() {
      return this.session || {};
    },
    start() {
      const group = getActiveGroup();
      // console.info('Exercise started!');
      const self = this;
      this.setState({
        dateStart: new Date().getTime()
      });

      window.exerciseTimer = setInterval(() => {
        self.setState({
          diff: self.getTime()
        });
      }, 1000);

      this.setState({ btnStatus: "loading" });

      let isFake = false;
      if (group.isTeacher()) isFake = true;

      const exerciseInfo = JSON.stringify({
        instant: this.isInstant(),
        execution_id: this.getInstantId(),
        fake: isFake
      });

      const callback_ok = function (data) {
        const that = this;
        this.setSession(data);

        let sessionType = this.props.exercise.isExercise()
          ? "BExercise"
          : "BTest";
        if (group.isTeacher()) {
          // teacher = fake exec
          sessionType = this.props.exercise.isExercise()
            ? "BFakeExercise"
            : "BFakeTest";
        }
        if (this.isInstant()) {
          sessionType = this.props.exercise.isExercise()
            ? "BInstantExercise"
            : "BInstantTest";
        }

        _trackEvent("Assignments", "AssignmentsBeginning", sessionType);

        /* tento di aprire le sessioni di tutti i quiz */
        var data = JSON.stringify({
          exercise_execution_id: this.getSession().id
        });

        that.startQuiz(); // inizializzo primo quiz
        that.setState({
          status: "running",
          btnStatus: "",
          dateStart: new Date().getTime()
        });
      }.bind(this);

      const callback_error = function (data) {
        const errorMsg = extractErrorMessage(
          data,
          __("non puoi fare questo esercizio")
        );
        showToastError({ str: errorMsg });
        this.setState({ btnStatus: "" });
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return;
        }
        navigateTo(createURL("exercises", { group_id: getActiveGroupId() }), {
          trigger: true
        });
      }.bind(this);

      this.props.exercise.startSession(
        exerciseInfo,
        callback_ok,
        callback_error
      );
    },
    stop(async, redirect) {
      const group = getActiveGroup();
      const self = this;
      if (isUndefined(async)) {
        async = true;
      }

      if (isUndefined(redirect)) {
        redirect = false;
      }

      self.setState({ btnStatus: "loading" });
      clearInterval(window.exerciseTimer);

      try {
        swal.close(); // chiudo tutti i messaggi di conferma eventualmente presenti.
      } catch (e) {
        console.log("error", e);
      }

      const exerciseInfo = this.getExecutionInfo();

      const callback_ok = function (data) {
        // console.log("ciao");
        let sessionType = this.props.exercise.isExercise()
          ? "EExercise"
          : "ETest";
        if (group.isTeacher()) {
          // teacher = fake exec
          sessionType = this.props.exercise.isExercise()
            ? "EFakeExercise"
            : "EFakeTest";
        }
        if (this.isInstant()) {
          sessionType = this.props.exercise.isExercise()
            ? "EInstantExercise"
            : "EInstantTest";
        }

        _trackEvent("Assignments", "AssignmentsEnd", sessionType);
        this.setSession(data);
        this.checkExercise();
        this.setState({
          status: "review",
          btnStatus: ""
        });
      }.bind(this);

      const callback_error = function (data) {
        this.checkExercise();
        this.setState({
          status: "review_pending",
          btnStatus: ""
        });
      }.bind(this);

      this.quizzes.each((quiz, index) => {
        let result = false;
        if (
          quiz.checkUserAnswer(self.props.exercise.get("success_threshold"))
        ) {
          result = true;
        }
        const data = JSON.stringify({
          result,
          answers: quiz.getFormattedUserAnswer()
        });
      });

      self.state.exercise.stopSession(
        exerciseInfo,
        callback_ok,
        callback_error,
        async
      );
    },
    getExecutionInfo() {
      const self = this;
      let quiz_ok = 0;
      let quiz_nok = 0;
      this.quizzes.each((quiz, i) => {
        if (
          quiz.checkUserAnswer(self.props.exercise.get("success_threshold"))
        ) {
          quiz_ok += 1;
        } else {
          quiz_nok += 1;
        }
      });

      const userMark = Math.round((quiz_ok / this.quizzes.length) * 100);
      const result = userMark >= this.props.exercise.get("success_threshold");

      const exerciseInfo = {
        quiz_ok,
        quiz_nok,
        result,
        vote: userMark
      };
      return exerciseInfo;
    },
    checkExercise() {
      // rispondo
      const self = this;
      this.quizzes.each((quiz, i) => {
        if (
          quiz.checkUserAnswer(self.props.exercise.get("success_threshold"))
        ) {
          // console.info('QUIZ', i, 'corretto');
        } else {
          // console.info('QUIZ', i, 'ERRATO');
        }
      });
    },
    startQuiz(callback_ok, callback_error) {
      const self = this;
      const quiz = this.getCurrentQuiz();
      this.setInitialQuizAnswer(quiz.getUserAnswer()); // salvo risposta data in precedenza dall'utente (anche se vuota)
      if (quiz.hasSession()) {
        // controllo se quiz è già stato inizializzato
        // bon, sono a posto
      } else {
        this.setState({ quizStatus: "loading" });
        const data = JSON.stringify({
          exercise_execution_id: this.getSession().id
        });
        // procedo a prescindere dalla risposta del server
        quiz.setSession({});
        self.setState({
          quizStatus: "",
          btnStatus: ""
        });
        if (typeof callback_ok === "function") callback_ok();
      }
    },
    stopQuiz(callback_ok, callback_error) {
      const self = this;
      const quiz = this.getCurrentQuiz();
      let result = false;

      if (quiz.hasSession()) {
        // controllo se quiz è già stato inizializzato (in teoria a questo punto dovrebbe esserlo sempre)
        if (
          quiz.checkUserAnswer(this.props.exercise.get("success_threshold"))
        ) {
          result = true;
        }
        const data = {
          id: quiz.id,
          result,
          answers: quiz.getFormattedUserAnswer()
        };

        self.state.exercise.saveExecution(data);

        // procedo a prescindere dalla risposta del server
        self.setState({
          quizStatus: "",
          btnStatus: ""
        });
        if (result) {
          // se quiz è corretto
          if (typeof callback_ok === "function") callback_ok();
        } else if (typeof callback_error === "function") callback_error();
      }
    },
    setInitialQuizAnswer(answer) {
      this.initialQuizAnswer = answer;
    },
    getInitialQuizAnswer() {
      return this.initialQuizAnswer;
    },
    isInstant() {
      return this.props.instant !== undefined && this.props.instant;
    },
    getInstantId() {
      return this.props.match.params.instant_id !== undefined
        ? this.props.match.params.instant_id
        : 0;
    },
    getBackRoute() {
      const moduleId = getQueryParamFromFragment("module");
      if (moduleId) {
        return createUrl("module_detail", {
          group_id: getActiveGroupId(),
          module_id: moduleId
        });
      }

      let route = "exercises";
      if (!this.props.exercise.isPublished()) route = "exercises_drafts";

      return createURL(route, {
        group_id: getActiveGroupId()
      });
    },
    closeInstant(skipConfirmation) {
      const self = this;
      const confirmText = __("instant_close_confirmation_message");
      const exerciseInfo = this.getExecutionInfo();
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      const callback_ok = function (data) {
        instants.resetActiveInstantId();
        navigateTo(this.getBackRoute(), { trigger: true });
      }.bind(this);

      const callback_error = function (data) {
        showToastError({
          str: __("non è stato possibile salvare i dati dell'esecuzione")
        });
        instants.resetActiveInstantId();
        navigateTo(this.getBackRoute(), { trigger: true });
      }.bind(this);

      if (!isUndefined(skipConfirmation) && skipConfirmation == true) {
        self.props.exercise.stopSession(
          exerciseInfo,
          callback_ok,
          callback_error
        );
      } else {
        ConfirmDialogStore.openConfirmDialog(
          <ConfirmationAlert
            theme={whiteTheme}
            text={confirmText}
            declineText={__("Annulla")}
            onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
            acceptText={__("Conferma")}
            onAcceptFunction={() => {
              self.props.exercise.stopSession(
                exerciseInfo,
                callback_ok,
                callback_error
              );
              ConfirmDialogStore.closeConfirmDialog();
            }}
          />
        );
      }
    },
    closeExercise() {
      const self = this;
      if (this.state.status === "running" || this.state.status === "loading") {
        const confirmText = __("exercise_close_confirmation_text");

        const afterConfirm = function () {
          const exerciseInfo = self.getExecutionInfo();

          let route = "exercises";
          if (!self.props.exercise.isPublished()) route = "exercises_drafts";

          const callback_ok = function (data) {
            navigateTo(this.getBackRoute(), { trigger: true });
          }.bind(self);

          const callback_error = function (data) {
            showToastError({
              str: __("non è stato possibile salvare i dati dell'esecuzione")
            });
            navigateTo(this.getBackRoute(), { trigger: true });
          }.bind(self);

          self.props.exercise.stopSession(
            exerciseInfo,
            callback_ok,
            callback_error
          );
        };

        const button = {
          cancel: __("Annulla"),
          confirm: __("Conferma")
        };

        if (self.props.exercise.isExercise()) {
          button.exitWithSafe = {
            text: __("exit_without_submit"),
            value: "exit"
          };
        }
        handleAlert({
          text: confirmText,
          buttons: button
        }).then(isConfirm => {
          if (isConfirm === true) {
            afterConfirm();
          }
          if (isConfirm === "exit") {
            setTimeout(() => {
              const moduleId = getQueryParamFromFragment("module");
              if (moduleId) {
                navigateTo(this.getBackRoute());
                return;
              }
              navigateTo(
                createUrl("exercises", {
                  group_id: getActiveGroupId()
                })
              );
            }, 0);
          }
        });
      }
    },
    render() {
      const self = this;
      const exercise = this.props.exercise;
      let mainContent = <div />;
      const { weschoolTheme } = this.props.theme;
      let footerButton = (
        <Button
          onClick={self.checkQuiz}
          theme={weschoolTheme.white}
          variant="primary"
        >
          {__("AVANTI")}
        </Button>
      );

      switch (this.state.status) {
        case "beforeStart":
          mainContent = (
            <ExerciseEngineSplash
              exercise={exercise}
              onClick={this.start}
              status={this.state.btnStatus}
            />
          );
          break;

        case "review":
        case "review_pending":
          mainContent = (
            <ExerciseEngineSummary
              retry={() => {
                self.respondQuiz(true, true);
                return 0;
              }}
              exercise={self.state.exercise}
              session={this.getSession()}
              exenStatus={this.state.status}
            />
          );
          break;

        case "running":
          // TODO controllare lunghezza quiz
          var quiz = this.getCurrentQuiz();

          if (quiz !== undefined) {
            mainContent = (
              <div>
                <ExerciseEngineQuiz
                  quiz={quiz}
                  quizStatus={this.state.quizStatus}
                  exercise={exercise}
                  key={`quiz${quiz.get("id")}`}
                  setCurrentInnerQuiz={this.setCurrentInnerQuiz}
                />
              </div>
            );
          } else {
            mainContent = (
              <div className="alert alert-danger">
                <p>
                  <strong>Uhm...</strong>
                </p>
                <p>{__("Sembra che non ci sia nulla qui")}...</p>
              </div>
            );
          }
          break;

        default:
          mainContent = <div />;
          break;
      }

      let header = "";
      let footer = "";
      let timebar = "";

      const diff = this.state.diff;
      let min = Math.floor(diff / 60);
      let sec = diff % 60;
      if (sec < 10) sec = `0${sec}`;
      if (min < 10) min = `0${min}`;

      if (self.isLastQuiz()) {
        footerButton = (
          <Button
            onClick={self.checkQuiz}
            theme={weschoolTheme.white}
            variant="primary"
          >
            {__("confirm")}
          </Button>
        );
      }

      if (this.state.status === "running") {
        if (exercise.isVerification()) {
          // verifica
          footer = (
            <Box className="app__fixed-footer" bottom="0px">
              {this.state.current > 0 ? (
                <Button
                  onClick={this.prevQuiz}
                  theme={weschoolTheme.white}
                  variant="secondary"
                >
                  {__("prev")}
                </Button>
              ) : null}
              {footerButton}
            </Box>
          );

          const maxTime = exercise.getTimeThreshold();
          if (maxTime > 0) {
            const timebarSize = Math.floor(
              ((maxTime - this.state.diff) * 100) / maxTime
            );
            timebar = (
              <div className="exen__timebar">
                <div
                  className="exen__timebar-past"
                  style={{ width: `${timebarSize}%` }}
                />
              </div>
            );
          }
        } else {
          // esercizio
          footer = (
            <Box className="app__fixed-footer" bottom="0px">
              {footerButton}
            </Box>
          );
        }

        header = (
          <div className="header">
            <header className="header__top-bar">
              <div className="header__title margin-left-15">
                {exercise.get("title")}
              </div>
              <div className="header__action-container">
                <ActionButton
                  theme={weschoolTheme.grey}
                  variant="secondary"
                  onClick={
                    this.isInstant() ? this.closeInstant : this.closeExercise
                  }
                  icon="close"
                />
              </div>
            </header>
            <Box
              display="flex"
              position="absolute"
              top="54px"
              width="100%"
              backgroundColor={colors.grey[1000]}
            >
              <ExerciseEngineProgressBar
                current={this.state.current}
                num={this.quizzes.length}
              />
              <div className="exen__exercise-timer">
                <Icon icon="clock" width={15} height={15}></Icon>{" "}
                <span className="margin-left-5">
                  {min}:{sec}
                </span>
              </div>
            </Box>
            {timebar}
          </div>
        );
      }

      return (
        <div id="exercise-engine-container">
          <HideTopBannerErrorConnection />
          {header}
          <div className="container-fluid">{mainContent}</div>
          {footer}
          <div className="exen__feedback-wrapper" id="feedback" />
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(ExerciseEngine));
