import { Box, Button, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupDetailsModel } from "src/js/types";
import styled, { useTheme } from "styled-components";
import BadgeGroupWithIcon from "./BadgeGroupWithIcon";

const StyledTextSubtitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;
const StyledTextDescription = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

type ModalSetupSelfJoinableProps = {
  group: GroupDetailsModel;
  onConfirm: () => void;
};

const ModalSetupSelfJoinable = ({
  group,
  onConfirm
}: ModalSetupSelfJoinableProps) => {
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate({ text: "dashboard_set_self_joinable_modal_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        padding="16px 20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          margin="auto"
          {...(!isLayoutModeMobile && {
            padding: "24px 0px 32px"
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <BadgeGroupWithIcon
              group={group}
              icon="stickyNote"
              iconSize={16}
              iconWrapperStyle={{
                width: 32,
                height: 32
              }}
            />
          </Box>
          <StyledTextSubtitle>
            {translate("dashboard_set_self_joinable_modal_subtitle")}
          </StyledTextSubtitle>
          <StyledTextDescription>
            {translate("dashboard_set_self_joinable_modal_description")}
          </StyledTextDescription>
        </Box>
        <Button
          variant="primary"
          theme={whiteTheme}
          fullWidth
          onClick={onConfirm}
        >
          {translate({
            text: "dashboard_set_self_joinable_modal_cta"
          })}
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default observer(ModalSetupSelfJoinable);
