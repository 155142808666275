import React, { useEffect, useState } from "react";
import { Box, Button, Icon } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import useWalletConnection from "src/js/pages/App/Settings/AccountPage/components/MetaMaskWalletConnection/useWalletConnection";
import { observer } from "mobx-react";
import * as S from "./styles";
import { WalletBannerIsHidden } from "./const";

const ConnectYourWallet = () => {
  const [isHidden, setIsHidden] = useState(true);
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    UserStore: {
      userWalletDetails: { address }
    }
  } = useStores();
  const { connectToWallet } = useWalletConnection();

  const hideWalletBanner = () => {
    setIsHidden(true);
    localStorage.setItem(WalletBannerIsHidden, "true");
  };
  const isWalletBannerHidden = !!(
    address || localStorage.getItem(WalletBannerIsHidden)
  );

  useEffect(() => {
    setIsHidden(isWalletBannerHidden);
  }, [isWalletBannerHidden]);

  return isHidden ? null : (
    <S.Container>
      <S.ConnectYourWallet>
        <S.InfoDetailsContainer>
          <S.MetaMaskIconContainer>
            <Icon icon="metamaskFox" width={44} height={44} initialViewbox />
          </S.MetaMaskIconContainer>
          <Box display="flex" flexDirection="column">
            <S.PrimaryDescription type="formTitle">
              {translate("spaces_badges_connect_wallet_primary_description")}
            </S.PrimaryDescription>
            <S.SecondaryDescription type="formSubtitle">
              {translate("spaces_badges_connect_wallet_secondary_description")}
            </S.SecondaryDescription>
          </Box>
        </S.InfoDetailsContainer>
        <S.ButtonsContainer>
          <Button
            theme={greyTheme}
            onClick={() => connectToWallet(true)}
            variant="primary"
          >
            {translate("spaces_badges_connect_wallet_action_button")}
          </Button>
          {isLayoutModeMobile ? (
            <Button
              theme={greyTheme}
              variant="secondary"
              onClick={hideWalletBanner}
            >
              {translate("close")}
            </Button>
          ) : (
            <S.CloseIcon onClick={hideWalletBanner}>
              <Icon icon="close" width={16} height={16} initialViewbox />
            </S.CloseIcon>
          )}
        </S.ButtonsContainer>
      </S.ConnectYourWallet>
    </S.Container>
  );
};

export default observer(ConnectYourWallet);
