import {
  Box,
  Button,
  Heading,
  ModalSizeType,
  Text,
  Toast
} from "@arcadia/design-system";
import { formatBytes } from "@ds_universal/data_entry/PictureUploader/utils";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useSpacePlanType, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { GroupInviteMemberModal } from "src/js/pages/group/settings/GroupInviteMemberModal";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  AddMemberEvent,
  EventDomain,
  GroupUserRoleEnum,
  InviteMode
} from "src/js/types";
import { useTheme } from "styled-components";
import { CsvMailUploader } from "../components/CsvMailUploader";
import { CsvMailUploaderResult } from "../components/CsvMailUploader/CsvMailUploader";
import { useSendInviteMembers } from "../hooks";
import * as S from "./FileImportModal.styles";

const FileImportModal = ({
  groupIds,
  selectedMemberType = GroupUserRoleEnum.Student
}: {
  groupIds: number[];
  selectedMemberType?: GroupUserRoleEnum;
}) => {
  const {
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme, whiteTheme } = useTheme();
  const currentTheme = isLayoutModeMobile ? greyTheme : whiteTheme;
  const { translate } = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<CsvMailUploaderResult>(null);
  const { emailArray, fileName, fileSize, hasMailFormatError } =
    uploadedFile || {};

  const { totalMemberInviteLimit } = useSpacePlanType({
    groupCount: groupIds.length
  });
  const { handleInviteMembers, isLoadingInviteMembers } =
    useSendInviteMembers();

  useEffect(() => {
    _trackEvent(EventDomain.AddMember, AddMemberEvent.ImportFromCSVSelection);
  }, []);

  const closeModal = () => {
    openModal(() => (
      <GroupInviteMemberModal initialInviteMode={InviteMode.Import} />
    ));
  };

  const stepBack = () => {
    return uploadedFile ? setUploadedFile(null) : closeModal();
  };

  const onConfirmInviteMembers = async () => {
    _trackEvent(EventDomain.AddMember, AddMemberEvent.ConfirmImportFromCSV);
    await handleInviteMembers({
      groupIds,
      inviteRole: selectedMemberType,
      userEmails: emailArray
    });
  };

  return (
    <ModalLayout
      desktopProps={{
        size: ModalSizeType.Normal
      }}
      labels={{
        title: translate("invite_modal_import_from_csv"),
        mobileClose: translate("close")
      }}
      closeModal={stepBack}
      progressPercentage={uploadedFile ? 100 : 50}
    >
      <S.ModalContainer>
        <Heading level="5">{translate("import_csv_title")}</Heading>
        {uploadedFile ? (
          <>
            <S.VideoContainer>
              <VideoPlayer video={S3Video.CsvUploadSuccess} />
            </S.VideoContainer>
            {hasMailFormatError ? (
              <Box marginTop="16px">
                <Toast
                  theme={currentTheme}
                  type="warning"
                  hasIcon
                  visible
                  onClose={() =>
                    setUploadedFile(prev => {
                      return { ...prev, hasMailFormatError: false };
                    })
                  }
                >
                  {translate("import_csv_toast_mail_error")}
                </Toast>
              </Box>
            ) : null}
            <S.FileItemCard>
              <S.FileIcon
                width="auto"
                height="auto"
                icon="csv"
                initialViewbox
              />
              <S.FileDataContent>
                <S.FileTitle level="6">{fileName}</S.FileTitle>
                <S.FileSizeLabel>
                  <Text type="label">{formatBytes(fileSize)}</Text>
                </S.FileSizeLabel>
              </S.FileDataContent>
            </S.FileItemCard>
          </>
        ) : (
          <>
            <Box marginTop="4px">
              <Text type="body">
                {translate({
                  text: "import_csv_description",
                  stringVariables: { membersLimit: totalMemberInviteLimit }
                })}
              </Text>
            </Box>
            <Box marginTop="24px">
              <Text type="body">{translate("import_csv_instructions")}</Text>
            </Box>
            <S.StyledLinkButton
              theme={currentTheme}
              href="/assets/download/sample-csv-file.csv"
              download="sample-csv-file.csv"
            >
              {translate("import_csv_download_example")}
            </S.StyledLinkButton>
            <CsvMailUploader
              groupIds={groupIds}
              onChangeHandler={result => {
                setUploadedFile(result);
              }}
            />
          </>
        )}
        <S.Footer>
          {!isLayoutModeMobile ? (
            <Button theme={currentTheme} variant="secondary" onClick={stepBack}>
              {translate("close")}
            </Button>
          ) : null}
          <Button
            variant="primary"
            disabled={!uploadedFile || isLoadingInviteMembers}
            onClick={onConfirmInviteMembers}
            theme={currentTheme}
            fullWidth={isLayoutModeMobile}
          >
            {translate({
              text: "import_csv_confirm_button",
              stringVariables: { type: selectedMemberType }
            })}
          </Button>
        </S.Footer>
      </S.ModalContainer>
    </ModalLayout>
  );
};

export default observer(FileImportModal);
