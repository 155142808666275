import { Button, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { GroupInvitation } from "../../types";
import { GroupInvitationCard } from "../GroupInvitationCard";

import * as S from "./ModalGroupsInvitations.styles";

type ModalEnterSelfJoinableProps = {
  groupInvites: GroupInvitation[];
  showConfirmation: ({ invitation }: { invitation: GroupInvitation }) => void;
  rejectInvite: ({ invitation }: { invitation: GroupInvitation }) => void;
  acceptAllInvites: () => void;
};

const ModalGroupsInvitations = ({
  groupInvites,
  showConfirmation,
  rejectInvite,
  acceptAllInvites
}: ModalEnterSelfJoinableProps) => {
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate({ text: "group_invitation_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <S.ModalBody>
        <S.HeaderWrapper>
          <div>
            <Text type="formField">
              {translate("group_invitation_subtitle_1")}
            </Text>
          </div>
          <Text type="subHeaderTitle">
            {translate("group_invitation_subtitle_2")}
          </Text>
        </S.HeaderWrapper>
        <S.ScrollableArea>
          {groupInvites.map(invitation => {
            return (
              <GroupInvitationCard
                key={invitation?.inviteCode}
                invitation={invitation}
                showConfirmation={showConfirmation}
                rejectInvite={rejectInvite}
                hasBorder
              />
            );
          })}
        </S.ScrollableArea>
      </S.ModalBody>
      <S.FooterWrapper>
        <S.DesktopButton
          variant="secondary"
          theme={whiteTheme}
          fullWidth
          onClick={closeModal}
        >
          {translate("close")}
        </S.DesktopButton>
        <Button
          variant="primary"
          theme={whiteTheme}
          fullWidth
          onClick={() => acceptAllInvites()}
        >
          {translate("accept_multiple_invitations")}
        </Button>
      </S.FooterWrapper>
    </ModalLayout>
  );
};

export default observer(ModalGroupsInvitations);
