import React, { useEffect } from "react";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { Avatar, Box, Button, Text } from "@arcadia/design-system";
import { useRouteMatch } from "react-router-dom";
import { createURL } from "src/legacy/modules/utility";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import styled, { useTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { checkAliasActive, joinGroup } from "src/js/repository/joinRepository";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { getGroup } from "src/js/repository/groupRepository";
import { GroupInSpaceModel } from "src/js/types";

const ProfileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoggedText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

const JoinPage = () => {
  const {
    params: { codice }
  } = useRouteMatch<{ codice: string }>();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal },
    UserStore,
    SpaceStore: { activeSpace, spacesList, addNewSpaceToSpaceList },
    SpaceGroupListStore: { addGroupsAtStart, activeSpaceGroups },
    AuthStore: { userIsLogged }
  } = useStores();

  useEffect(() => {
    if (!codice) {
      return;
    }
    if (!userIsLogged) {
      navigateTo(createUrl("registerToken", { token: codice }));
      return;
    }

    checkAliasActive(codice)
      .then(() => {
        joinGroup(codice)
          .then(async ({ data }) => {
            if (data.id) {
              const group = (await getGroup(data.id)) as GroupInSpaceModel;
              localStorage.setItem("groupId", `${data.id}`);

              if (
                data.is_joining &&
                !activeSpaceGroups.find(
                  ({ group: groupInList }) => groupInList.id === group.group.id
                )
              ) {
                _trackEvent("Group", "GroupJoin");

                if (activeSpace && group.space.id === activeSpace.space.id) {
                  addGroupsAtStart([group]);
                }
              }

              if (
                !spacesList.find(
                  ({ space: spaceInList }) => spaceInList.id === group.space.id
                )
              ) {
                addNewSpaceToSpaceList({
                  space: group.space,
                  role: null,
                  unreadNotificationCount: 0,
                  unreadThreadMessagesCount: 0
                });
              }

              navigateTo(
                createUrl("wall", {
                  group_id: data.id,
                  space_slug: group?.space?.slug
                })
              );
            } else {
              navigateTo(createUrl("home"));
            }
          })
          .catch(({ response: er }: AxiosError) => {
            navigateTo(createUrl("home"));
            const errorMsg = extractErrorMessage(
              er.statusText,
              translate("Attenzione, si è verificato un errore")
            );
            switch (er.status) {
              case 400:
                showToastError({ str: errorMsg });
                break;
              case 403:
              case 404:
                openModal(() => (
                  <ModalLayout
                    labels={{
                      title: translate("join_page_modal_title"),
                      mobileClose: translate("close")
                    }}
                    desktopProps={{ onClose: closeModal }}
                    closeModal={closeModal}
                  >
                    <Box padding={20} paddingTop={40}>
                      <Box textAlign="center" marginBottom={42}>
                        <Text type="headerTitle">
                          {translate("join_page_modal_message")}
                        </Text>
                      </Box>
                      <ProfileBox>
                        <Avatar
                          theme={greyTheme}
                          url={UserStore.activeUser?.avatar?.small}
                        />
                        <Box marginLeft={12} overflow="hidden" width="100%">
                          <Column>
                            <NameText type="formField">
                              {UserStore.activeUserFullname}
                            </NameText>
                            <EmailText type="body">
                              {UserStore.activeUser?.email ?? ""}
                            </EmailText>
                          </Column>
                        </Box>
                        <Box display="block" marginLeft={12}>
                          <LoggedText>
                            {translate("join_page_modal_logged")}
                          </LoggedText>
                        </Box>
                      </ProfileBox>
                      <Box
                        display="flex"
                        justifyContent="center"
                        marginTop={60}
                        overflow="hidden"
                      >
                        <Button
                          theme={greyTheme}
                          variant="primary"
                          onClick={() => {
                            navigateTo(createUrl("logout"));
                          }}
                        >
                          {translate("join_page_modal_btn_logout")}
                        </Button>
                      </Box>
                    </Box>
                  </ModalLayout>
                ));
                /** added here as a fallback because backend is giving 404 on a mismatched invitation */
                break;
              default:
                showToastError({ str: errorMsg });
                break;
            }
          });
      })
      .catch(er => {
        const errorMsg = extractErrorMessage(
          er.responseJSON,
          translate("group_disabled")
        );
        showToastError({ str: errorMsg });
        navigateTo(createURL("login"));
      });
  }, [codice, userIsLogged]);

  return undefined;
};

export default observer(JoinPage);
