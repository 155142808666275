import React from "react";
import { useTheme } from "styled-components";
import { useCloseOnClickOutside } from "src/js/hooks";
import { Avatar } from "@arcadia/design-system";
import { useLocation } from "react-router";
import { getAppRoutingContext } from "src/js/routing/appRoutingContext";
import { useMeQueryCached } from "src/js/query";
import { PopupUserMenu } from "./PopupUserMenu";

import * as S from "./ProfileMenu.styles";

const ProfileMenu = () => {
  const { whiteTheme } = useTheme();
  const location = useLocation();
  const routingContext = getAppRoutingContext(location);
  const { ref, isOpen, setIsOpen } = useCloseOnClickOutside(false);

  const { data: activeUser } = useMeQueryCached();

  const handleTogglePopover = () => {
    setIsOpen(visible => !visible);
  };

  return (
    <div id="toolbarProfileLink" ref={ref}>
      <S.AvatarWrapper
        role="button"
        aria-label="Profile popover"
        isSelected={routingContext === "profile"}
        onClick={handleTogglePopover}
      >
        <Avatar
          theme={whiteTheme}
          url={activeUser?.avatar?.small}
          alt={`${activeUser?.name} - ${activeUser?.surname}`}
          size="s"
          rounded
        />
      </S.AvatarWrapper>
      <PopupUserMenu isOpen={isOpen} closePopover={() => setIsOpen(false)} />
    </div>
  );
};

export default ProfileMenu;
