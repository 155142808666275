import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  DashboardEvent,
  EventDomain,
  StateMetricSeverity,
  StateMetricSeverityType,
  StateTrendSeverity,
  StateTrendSeverityType
} from "src/js/types";
import { CustomTooltip } from "src/js/components/CustomTooltip";
import { PrimaryMetricCard } from "../../../components/PrimaryMetricCard";
import { useAnalyticsFilter, useLearningMetrics } from "../../../hooks";
import { MetricObjectives } from "../MetricObjectives";

const PrimaryObjectives = ({ noGroups }: { noGroups: boolean }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { dateLabel } = useAnalyticsFilter();
  const { percentageValue, trendValue, loading } = useLearningMetrics();

  const trendLabel = `${Math.abs(trendValue)} ${translate(
    "analytics_percentage_point_abbreviation"
  )}`;

  let trendSeverity: StateTrendSeverityType =
    trendValue < 0 ? StateTrendSeverity.Critical : StateTrendSeverity.Normal;

  let metricSeverity: StateMetricSeverityType = StateMetricSeverity.Critical;
  if (percentageValue > 33 && percentageValue <= 66)
    metricSeverity = StateMetricSeverity.Warning;
  if (percentageValue > 66) metricSeverity = StateMetricSeverity.Normal;

  if (noGroups || percentageValue === 0) {
    metricSeverity = null;
    trendSeverity = null;
  }

  return (
    <PrimaryMetricCard
      loading={loading}
      margin="20px 0 16px"
      {...(isLayoutModeMobile && {
        margin: "0 0 16px"
      })}
      titleLabel={translate("primary_metric_card_objectives_title")}
      dateLabel={dateLabel}
      infoTooltip={
        <CustomTooltip
          maxWidth="224px"
          title={translate("primary_metric_card_objectives_info_tooltip_title")}
          description={translate(
            "primary_metric_card_objectives_info_tooltip_description"
          )}
        />
      }
      trackingEvents={{
        tooltip: {
          domain: EventDomain.SpaceDashboardUsage,
          action: DashboardEvent.ObjectivesViewInfo
        }
      }}
    >
      <MetricObjectives
        loading={loading}
        resultLabel={`${percentageValue}%`}
        metricSeverity={metricSeverity}
        descriptionLabel={translate("metric_objectives_description", {
          metricSeverity: metricSeverity || StateMetricSeverity.Normal
        })}
        trendLabel={trendLabel}
        trendSeverity={trendSeverity}
        progressPercentage={percentageValue}
      />
    </PrimaryMetricCard>
  );
};

export default observer(PrimaryObjectives);
