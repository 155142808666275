import { navigateTo } from "Legacy/modules/history";
import React from "react";
import { RESOURCE_ICON_TYPE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import handleThumbnails from "src/js/modules/imageFunction";
import { showToastError } from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { fetchResource } from "src/js/repository/resourceRepository";
import { __IMG_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";
import { TODO } from "src/js/types";
import { Translate } from "../translation";

export const openResource = ({ id, type, groupId, onClose }) => {
  if (!id) return null;
  if (type === RESOURCE_ICON_TYPE.BOARD) {
    navigateTo(
      createUrl("module_detail", {
        group_id: groupId,
        module_id: id
      })
    );
    return null;
  }
  if (type === RESOURCE_ICON_TYPE.EXERCISE) {
    navigateTo(
      createUrl("exercises_run", {
        group_id: groupId,
        exercise_id: id
      })
    );
    return null;
  }
  return fetchResource(id)
    .then(resourceFetched => {
      return (
        <ResourceViewer
          disableOption
          resources={[resourceFetched]}
          showComments={false}
          startingPosition={0}
          onClose={onClose}
        />
      );
    })
    .catch(() => {
      // TODO: i18n add label
      showToastError({
        str: <Translate text={"quiz_resource_fetch_error" as TODO} />
      });
      return null;
    });
};

export const getResourceImage = resource => {
  let respourceImage = null;
  const preview = handleThumbnails(resource.preview, "large");
  if (preview !== __IMG_PLACEHOLDER__) {
    respourceImage = preview;
  }
  return respourceImage;
};

export const parseResourceNameAsServer = (name: string) =>
  name.toLowerCase().replace(/ /g, "-");
