import styled from "styled-components";

export const HeroBannerWrapper = styled.div`
  position: relative;
  margin: 40px auto 16px;
  max-width: 620px;
  @media screen and (max-width: 767px) {
    max-width: unset;
    margin: 24px 16px 16px;
  }
`;

export const HeroBanner = styled.img`
  object-fit: cover;
  height: 220px;
  width: 100%;
  z-index: 0;
  border-radius: 12px;
`;

export const SpaceBadge = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  margin: 0 16px 32px;
  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
`;
