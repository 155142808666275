import React, { useCallback } from "react";
import { Avatar } from "@arcadia/design-system";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import { useMeQueryCached } from "src/js/query";
import { Animations } from "src/js/settings/animations";
import { useTheme } from "styled-components";

import * as S from "./HeaderProfileTab.styles";

const HeaderProfileTab = () => {
  const { whiteTheme } = useTheme();

  const { data: activeUser } = useMeQueryCached();

  const onNavigateProfile = useCallback(() => {
    navigateTo(createUrl("user_profile_settings"));
  }, []);

  return (
    <AnimatePresence>
      <S.HeaderProfileTabWrapper>
        <motion.div
          {...Animations.Fade}
          transition={{ delay: 0.2 }}
          onClick={onNavigateProfile}
        >
          <Avatar
            theme={whiteTheme}
            url={activeUser.avatar?.small}
            alt={activeUser.name}
            size="m"
          />
        </motion.div>
        <motion.div {...Animations.Fade} transition={{ delay: 0.4 }}>
          <S.Name level="4">{`${activeUser?.name} ${activeUser?.surname}`}</S.Name>
        </motion.div>
      </S.HeaderProfileTabWrapper>
    </AnimatePresence>
  );
};

export default observer(HeaderProfileTab);
