import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { fetchSpaceSettings } from "src/js/repository/dashboardRepository";
import { DashboardSettingsV3 } from "src/js/types";
import { dashboardQueryKey } from "./dashboard.query";

export const useDashboardSettingsV3 = (
  spaceId: string,
  checkSubscription: boolean,
  options?: UseQueryOptions<DashboardSettingsV3, Error, DashboardSettingsV3>
) => {
  const { data: dashboardSettings, ...otherData } = useQuery(
    dashboardQueryKey.settingsV3({ spaceId, checkSubscription }),
    () => fetchSpaceSettings({ spaceId, checkSubscription }),
    options
  );

  return {
    dashboardSettings,
    ...otherData
  };
};
