import React, { FC, useEffect, useRef } from "react";
import { HTMLParser } from "../../HTMLParser";
import * as S from "./SpacesWelcomeActionsLabel.styles";
import { SpacesWelcomeActionsLabelProps } from "./SpacesWelcomeActionsLabel.types";

const SpacesWelcomeActionsLabel: FC<SpacesWelcomeActionsLabelProps> = ({
  label,
  actions
}) => {
  const ref = useRef(null);
  const listeners = useRef([]);

  useEffect(() => {
    const links = Array.from(ref.current.querySelectorAll("a"));
    links.forEach((node: HTMLLinkElement) => {
      const action = actions.find(a => a.id === node.id)?.onClick;
      if (action) {
        node.addEventListener("click", action);
        listeners.current.push(node);
      }
    });
    return () => {
      listeners.current.forEach(node => {
        const action = actions.find(a => a.id === node.id).onClick;
        node.removeEventListener("click", action);
      });
      listeners.current = [];
    };
  }, [label, actions]);

  return (
    <S.StyledActionsText ref={ref}>
      <HTMLParser htmlString={label} />
    </S.StyledActionsText>
  );
};

export default SpacesWelcomeActionsLabel;
