import * as Tooltip from "@radix-ui/react-tooltip";
import { Avatar, BottomModalSheet } from "@arcadia/design-system";
import React, { FC, useCallback, useState } from "react";
import { useSpaceByIdCached } from "src/js/query";
import { AnimatePresence } from "framer-motion";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import useCreateQuickThread from "src/js/pages/App/Spaces/hooks/useCreateQuickThread";
import { useTranslation } from "src/js/translation";
import { ChatEvent, EventDomain } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import * as S from "./UserProfileTooltip.styles";
import {
  TooltipContentProps,
  UserProfileTooltipProps
} from "./UserProfileTooltip.types";

const TooltipContent: FC<TooltipContentProps> = ({ user, openInNewTab }) => {
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser },
    SpaceStore: { canCreateChats, activeSpaceSlug },
    ModalStore: { show, closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { navigateToThread } = useCreateQuickThread();

  const { uuid, name, surname, avatar } = user;
  const { data } = useSpaceByIdCached(activeSpaceSlug, {
    enabled: !!activeSpaceSlug
  });

  const hasChatButton =
    canCreateChats &&
    activeUser.uuid !== uuid &&
    !data?.spaceSettings.disableChat;

  const goToProfile = useCallback(() => {
    if (!uuid) return;
    if (show) closeModal();
    if (openInNewTab) {
      window.open(
        `${window.location.origin}/#${createUrl("profile_public", {
          user_uuid: uuid
        })}`,
        "_blank"
      );
      return;
    }
    const redirectToProfileUrl = createUrl("profile_public", {
      user_uuid: uuid
    });
    navigateTo(redirectToProfileUrl);
    window.scrollTo(0, 0);
  }, [uuid, openInNewTab]);

  return (
    <>
      <S.TooltipHeader>
        <Avatar
          theme={whiteTheme}
          url={avatar?.medium}
          alt={`${name} ${surname}`}
          size={isLayoutModeMobile ? "m" : "l"}
          rounded
        />
        <S.UserFullName>
          {name} {surname}
        </S.UserFullName>
      </S.TooltipHeader>
      <S.TooltipFooter>
        {hasChatButton ? (
          <S.TooltipFooterButton
            fullWidth
            onClick={() => {
              _trackEvent(EventDomain.Chat, ChatEvent.ChatStartWallClick);
              navigateToThread(uuid, openInNewTab);
            }}
            variant="primary"
            theme={whiteTheme}
          >
            {translate("user_profile_tooltip_message")}
          </S.TooltipFooterButton>
        ) : null}
        <S.TooltipFooterButton
          fullWidth
          onClick={goToProfile}
          variant="secondary"
          theme={whiteTheme}
        >
          {translate("user_profile_tooltip_view")}
        </S.TooltipFooterButton>
      </S.TooltipFooter>
    </>
  );
};

const UserProfileTooltip: FC<UserProfileTooltipProps> = ({
  children,
  user,
  openInNewTab
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const [isModalSheetOpen, setModalSheetOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (!user) return children;

  return isLayoutModeMobile ? (
    <>
      <S.MobileWrapper onClick={() => setModalSheetOpen(true)}>
        {children}
      </S.MobileWrapper>
      <BottomModalSheet
        isOpen={isModalSheetOpen}
        onClose={() => setModalSheetOpen(false)}
        title=""
        withHeaderLabels={false}
        withAdaptiveHeight
      >
        <S.TooltipLayout>
          <TooltipContent user={user} openInNewTab={openInNewTab} />
        </S.TooltipLayout>
      </BottomModalSheet>
    </>
  ) : (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal forceMount>
          <AnimatePresence>
            {isTooltipOpen ? (
              <S.StyledTooltipContent forceMount asChild>
                <S.TooltipLayout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, y: -10 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{
                    duration: 0.2
                  }}
                >
                  <TooltipContent user={user} openInNewTab={openInNewTab} />
                </S.TooltipLayout>
              </S.StyledTooltipContent>
            ) : null}
          </AnimatePresence>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default UserProfileTooltip;
