import { Button, CtaTertiary, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { Animations } from "src/js/settings/animations";
import { useTranslation } from "src/js/translation";
import {
  NotificationArea,
  OtherAreaValue
} from "src/js/types/models/Notification";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import {
  DateRow,
  NoNotifications,
  Notification,
  NotificationSkeleton,
  PillBar,
  TabBar
} from "./components";
import { useNotificationDrawer } from "./hooks/useNotificationDrawer";

import * as S from "./NotificationDrawer.styles";

const NotificationDrawer = () => {
  const {
    tabItems,
    diffIsMoreThanOneDay,
    onChangeTab,
    date,
    groupId,
    notifications,
    isLoadingNextPage,
    scrollableRef,
    formatDate,
    setDate,
    isLoading,
    onMarkRead,
    onMarkUnRead,
    markAllNotificationsAsRead
  } = useNotificationDrawer();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal }
  } = useStores();
  const { whiteTheme } = useTheme();

  const getRedirectParams = () => {
    if (!groupId) return "";
    return `?status=All&groupId=${groupId}&area=${NotificationArea.Wall},${
      NotificationArea.Lesson
    },${NotificationArea.Calendar},${
      NotificationArea.Exercise
    },${OtherAreaValue.join(",")}`;
  };

  const goToNotifications = () => {
    navigateTo(`${createUrl("notifications")}${getRedirectParams()}`);
  };

  const goToSettings = () => {
    navigateTo(createUrl("user_notification_settings"));
  };

  return (
    <S.NotificationDrawerWrapper
      isLayoutModeMobile={isLayoutModeMobile}
      {...Animations.Dropdown}
    >
      {isLayoutModeMobile ? (
        <S.NotificationHeaderMobile>
          <PillBar items={tabItems} onTabChange={onChangeTab} />
        </S.NotificationHeaderMobile>
      ) : (
        <S.NotificationHeader>
          <S.TitleRow>
            <S.Title level="3">
              {translate("notification_drawer_title")}
            </S.Title>
            <S.IconWrapper onClick={goToSettings}>
              <Icon icon="gear" width={20} height={20} />
            </S.IconWrapper>
          </S.TitleRow>
          <TabBar items={tabItems} onTabChange={onChangeTab} />
        </S.NotificationHeader>
      )}
      {notifications.length === 0 && !isLoading ? (
        <NoNotifications
          title={translate("notifications_drawer_no_notifications_title")}
          subtitle={translate("notifications_drawer_no_notifications_subtitle")}
        />
      ) : (
        <>
          <S.DateRow>
            <AnimatePresence exitBeforeEnter initial={false}>
              <Text type="formSmallCaption">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.45 }}
                  key={date}
                >
                  {date}
                </motion.div>
              </Text>
            </AnimatePresence>
            <S.MarkReadButton onClick={markAllNotificationsAsRead}>
              <Text type="captionAlert">
                {translate("notification_drawer_btn_mark_all_read")}
              </Text>
            </S.MarkReadButton>
          </S.DateRow>
          <S.NotificationContent
            isLayoutModeMobile={isLayoutModeMobile}
            ref={scrollableRef}
          >
            <AnimatePresence>
              {isLoading ? (
                <NotificationSkeleton />
              ) : (
                notifications.map((notification, index) => (
                  <React.Fragment key={notification.id}>
                    {diffIsMoreThanOneDay(
                      notifications[index - 1]?.updatedAt,
                      notification.updatedAt
                    ) &&
                      index !== 0 && (
                        <DateRow
                          scrollContainerRef={scrollableRef}
                          date={formatDate(notification.updatedAt)}
                          onDateHidden={() => {
                            setDate(formatDate(notification.updatedAt));
                          }}
                          onDateVisible={() => {
                            setDate(
                              formatDate(notifications[index - 1]?.updatedAt)
                            );
                          }}
                        />
                      )}
                    <Notification
                      notification={notification}
                      onMarkRead={onMarkRead}
                      onMarkUnread={onMarkUnRead}
                      onClick={isLayoutModeMobile ? closeModal : undefined}
                    />
                  </React.Fragment>
                ))
              )}
              {isLoadingNextPage && <NotificationSkeleton />}
            </AnimatePresence>
          </S.NotificationContent>
          {!isLoading && (
            <S.FooterWrapper isLayoutModeMobile={isLayoutModeMobile}>
              {isLayoutModeMobile ? (
                <Button
                  variant="primary"
                  fullWidth
                  theme={whiteTheme}
                  onClick={goToNotifications}
                >
                  {translate("notification_drawer_footer_link")}
                </Button>
              ) : (
                <CtaTertiary theme={whiteTheme} onClick={goToNotifications}>
                  {translate("notification_drawer_footer_link")}
                </CtaTertiary>
              )}
            </S.FooterWrapper>
          )}
        </>
      )}
    </S.NotificationDrawerWrapper>
  );
};

export default observer(NotificationDrawer);
