import { ActionButton, Heading, Icon, Text } from "@arcadia/design-system";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DurationCounterBadge } from "src/js/components/DurationCounterBadge";
import { trackLiveConference } from "src/js/modules/analyticsFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import { CalendarEventDetail } from "src/js/pages/calendar/CalendarEventDetail";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  getDatesUtils,
  getEventStatus,
  getEventUrl,
  hasLiveAccess
} from "src/js/pages/calendar/CalendarUtils";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";
import { useTheme } from "styled-components";
import { useMeQueryCached } from "src/js/query";
import { format } from "date-fns";
import * as S from "./EventCard.styles";
import { CalendarTag } from "../Calendar/CalendarTag";

const getMultiDayDate = ({ isMultiDay, node, currentDate }) => {
  if (isMultiDay && currentDate && currentDate instanceof Date) {
    return moment(currentDate);
  }
  if (isMultiDay && node) {
    const eventGroupElement =
      node && node.current && node.current.closest(".mbsc-event-group");
    const groupDateElement =
      eventGroupElement && eventGroupElement.querySelector(".mbsc-list-header");
    return groupDateElement && moment(groupDateElement.innerHTML, "DD/MM/YYYY");
  }
  return null;
};

const EventCard = ({
  id,
  type,
  title,
  allDay = false,
  isMultiDay = false,
  currentDate = null,
  start,
  end,
  liveUrl,
  groupId,
  userIsTeacher = false,
  accordionMode = false,
  openAccordionCallback = () => {},
  eventDetail = {},
  openRecipientsModal = () => {},
  openResource = () => {},
  deleteCalendarEvent = () => {},
  editCalendarEvent = () => {},
  copyCalendarEvent = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();
  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, [id]);

  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const { data: activeUser } = useMeQueryCached();

  const { startDate, endDate, dateNow, duration, hasSameDay, hasSameTime } =
    getDatesUtils({ start, end });

  const status = getEventStatus({
    dateNow,
    endDate,
    startDate,
    eventType: type
  });

  const handleCardClick = useCallback(() => {
    if (
      !accordionMode &&
      type === EventCategory.Live &&
      hasLiveAccess({
        dateNow,
        startDate,
        endDate
      })
    ) {
      trackLiveConference({
        userId: activeUser?.id,
        groupId,
        liveRoomId: id
      });
      openExternalLink(liveUrl);
    }
  });

  const handleChevronClick = useCallback(() => {
    setIsOpen(!isOpen);
    openAccordionCallback({ id, eventDetail });
  });

  const titleUrl = getEventUrl({
    eventId: id,
    eventType: type,
    groupId
  });

  const multiDayDate = getMultiDayDate({
    isMultiDay,
    node: containerRef,
    currentDate
  });

  return (
    <S.CardContainer
      ref={containerRef}
      status={type === EventCategory.Live && status}
      theme={greyTheme}
    >
      <S.CardHeader
        clickable={
          !accordionMode &&
          hasLiveAccess({
            dateNow,
            startDate,
            endDate
          })
        }
        onClick={() => handleCardClick()}
      >
        {multiDayDate ? (
          <S.EventDate>
            <Text type="formSubtitle">
              {multiDayDate?.format("ddd").charAt(0).toUpperCase() +
                multiDayDate?.format("ddd").slice(1)}
            </Text>
            <Heading level="3">{multiDayDate?.format("DD")}</Heading>
            <Text type="formSubtitle">{multiDayDate?.format("MMM")}</Text>
          </S.EventDate>
        ) : (
          <S.EventDate>
            <Text type="formSubtitle">
              {format(startDate, "eeeeee").charAt(0).toUpperCase() +
                format(startDate, "eeeeee").slice(1)}
            </Text>
            <Heading level="3">{format(startDate, "dd")}</Heading>
            <Text type="formSubtitle">
              {" "}
              {format(startDate, "MMM").charAt(0).toUpperCase() +
                format(startDate, "MMM").slice(1)}
            </Text>
          </S.EventDate>
        )}
        <S.EventData theme={whiteTheme}>
          <S.VerticalCenteredRow>
            <CalendarTag type={type} status={status} />
            {!hasSameDay && (
              <S.CompleteDateRange>
                <Text type="formSubtitle">
                  {format(startDate, "iii dd MMM")} -{" "}
                  {format(endDate, "iii dd MMM")}
                </Text>
              </S.CompleteDateRange>
            )}
          </S.VerticalCenteredRow>
          <S.EventTitle href={titleUrl} accordionMode={accordionMode}>
            <Text type="formField">{title}</Text>
          </S.EventTitle>
          <S.VerticalCenteredRow>
            <Icon icon="clock" height="12" width="12" />
            {allDay ? (
              <Text type="formDescription">
                {translate("event_card_all_day")}
              </Text>
            ) : (
              <>
                <Text type="formSubtitle">
                  {format(startDate, "HH:mm")}
                  {!hasSameTime && ` - ${format(endDate, "HH:mm")}`}
                </Text>
                <S.HourRange>
                  {!hasSameTime && (
                    <DurationCounterBadge
                      duration={duration}
                      theme={whiteTheme}
                    />
                  )}
                </S.HourRange>
              </>
            )}
          </S.VerticalCenteredRow>
        </S.EventData>
      </S.CardHeader>
      {accordionMode && (
        <S.ChevronContainer>
          {isOpen ? (
            <ActionButton
              icon="chevronUp"
              variant="tertiary"
              theme={whiteTheme}
              onClick={() => handleChevronClick()}
            />
          ) : (
            <ActionButton
              icon="chevronDown"
              variant="tertiary"
              theme={whiteTheme}
              onClick={() => handleChevronClick()}
            />
          )}
        </S.ChevronContainer>
      )}
      {isOpen && (
        <S.CardBody>
          <S.LineSeparator />
          <CalendarEventDetail
            eventDetail={eventDetail}
            accordionMode={accordionMode}
            userIsTeacher={userIsTeacher}
            openRecipientsModal={openRecipientsModal}
            openResource={openResource}
            deleteFunction={deleteCalendarEvent}
            editFunction={editCalendarEvent}
            copyFunction={copyCalendarEvent}
            groupId={groupId}
          />
        </S.CardBody>
      )}
    </S.CardContainer>
  );
};

export default EventCard;
