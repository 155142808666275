import { observer } from "mobx-react";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useStores } from "src/js/hooks";
import { matchPath, useLocation } from "react-router-dom";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { Avatar, TabButtons, TabButtonsOption } from "@arcadia/design-system";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { EditAvatar } from "src/js/components/EditAvatar";
import { useMeQueryCached } from "src/js/query";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { useAppLayout } from "../AppLayout";

import * as S from "./SettingsLayout.styles";

type SettingsPage = "account" | "notice" | "profile" | undefined;

const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const { headerHeight } = useAppLayout();
  const { data: activeUser } = useMeQueryCached();
  const location = useLocation();

  const isNotifications = !!matchPath(location.pathname, {
    path: WeSchoolRoutes.App.Settings.settingsNotifications,
    exact: true,
    strict: true
  })?.isExact;
  const isAccount = !!matchPath(location.pathname, {
    path: WeSchoolRoutes.App.Settings.settingsAccount,
    exact: true,
    strict: true
  })?.isExact;
  const isProfile = !!matchPath(location.pathname, {
    path: WeSchoolRoutes.App.Settings.settingsProfile,
    exact: true,
    strict: true
  })?.isExact;
  const isProfileEdit = !!matchPath(location.pathname, {
    path: WeSchoolRoutes.App.Settings.settingsProfileEdit,
    exact: true,
    strict: true
  })?.isExact;

  const tinted = useMemo(
    () => isNotifications || isAccount || isProfile || isProfileEdit,
    [isNotifications, isAccount, isProfile, isProfileEdit]
  );

  const option = useMemo(() => {
    if (isNotifications) return "notice";
    if (isAccount) return "account";
    if (isProfile) return "profile";
    if (isProfileEdit) return "profile";
    return undefined;
  }, [isNotifications, isAccount, isProfile, isProfileEdit]);

  const pageOptions: TabButtonsOption[] = [
    {
      id: "profile",
      icon: "user",
      label: translate("profile_page_personal_information")
    },
    {
      id: "account",
      icon: "gear",
      label: translate("settings_account_btn_account")
    },
    {
      id: "notice",
      icon: "bellEmpty",
      label: translate("settings_account_btn_notifications")
    }
  ];

  const onNavigate = useCallback((page: SettingsPage) => {
    switch (page) {
      case "account":
        navigateTo(createUrl("user_manage_account_settings"));
        break;
      case "notice":
        navigateTo(createUrl("user_notification_settings"));
        break;
      case "profile":
        navigateTo(createUrl("user_profile_settings"));
        break;
      case undefined:
        break;
      default:
        navigateTo(createUrl("user_profile_settings"));
        break;
    }
  }, []);

  return (
    <S.SettingsWrapper tinted={tinted} isMobile={isLayoutModeMobile}>
      {!isLayoutModeMobile ? (
        <S.UserNameWrapper headerHeight={headerHeight}>
          <HeaderBack
            stringTitle={`${activeUser?.name} ${activeUser?.surname}`}
          />
        </S.UserNameWrapper>
      ) : undefined}
      <S.ContentWrapper>
        {!isLayoutModeMobile ? (
          <>
            <S.AvatarWrapper>
              {isProfileEdit ? (
                <EditAvatar />
              ) : (
                <Avatar
                  theme={whiteTheme}
                  url={activeUser?.avatar?.small}
                  alt={`${activeUser?.name} ${activeUser?.surname}`}
                  size="xl"
                  rounded
                />
              )}
            </S.AvatarWrapper>
            <TabButtons
              options={pageOptions}
              selectedId={option}
              onSelected={onNavigate}
            />
          </>
        ) : undefined}
        {children}
      </S.ContentWrapper>
    </S.SettingsWrapper>
  );
};

export default observer(SettingsLayout);
