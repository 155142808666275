import { Avatar, Box, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { GroupNumberBadge } from "src/js/components/GroupNumberBadge";
import { Panel } from "src/js/components/Panel";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SpaceUserRoleEnum } from "src/js/types";
import { StaffMember } from "src/js/types/models/Staff";
import { useTheme } from "styled-components";
import * as S from "./StaffPanel.styles";

type StaffPanelProps = { member: StaffMember; onEditClick: () => void };

const StaffPanel = ({ member, onEditClick }: StaffPanelProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const {
    avatar,
    name,
    surname,
    email,
    role = SpaceUserRoleEnum.PowerUser,
    groupsNumber
  } = member;

  const menuItems: ListItemProps[] = [
    {
      id: "edit-permission",
      label: translate("staff_table_card_actions_edit"),
      icon: "pencil",
      onClick: onEditClick
    }
  ];

  const userPermissions = (
    <S.PermissionContainer>
      <S.Permission>
        <S.CheckIconContainer>
          <Icon icon="successCircle" height="16px" width="16px" />
        </S.CheckIconContainer>
        <Text
          type={isLayoutModeMobile ? "formDescription" : "formSmallCaption"}
        >
          {translate("staff_table_card_group_creation")}
        </Text>
      </S.Permission>
      <S.Permission>
        {role === SpaceUserRoleEnum.PowerUser ? (
          <S.CrossIconContainer>
            <Icon icon="errorCircle" height="16px" width="16px" />
          </S.CrossIconContainer>
        ) : (
          <S.CheckIconContainer>
            <Icon icon="successCircle" height="16px" width="16px" />
          </S.CheckIconContainer>
        )}
        <Text
          type={isLayoutModeMobile ? "formDescription" : "formSmallCaption"}
        >
          {translate("staff_table_card_dashboard_access")}
        </Text>
      </S.Permission>
    </S.PermissionContainer>
  );

  return (
    <Panel height={80} padding={20} isSelectable={false}>
      <S.TriggerRowArea>
        <S.InfoContainer>
          <S.TopMobile>
            <S.TopMobileTitle>
              <S.AvatarNameWrapper>
                <Avatar
                  theme={whiteTheme}
                  url={avatar?.small}
                  alt={`${name} - ${surname}`}
                  rounded
                  size="m"
                  withIcon={{ icon: "shieldPermissions", dimension: 24 }}
                  position="bottom"
                />
                <S.NameAndMailWrapper>
                  <S.EllipsedText type="formField">
                    {name} {surname}
                  </S.EllipsedText>
                  <S.EllipsedText type="body">{email}</S.EllipsedText>
                </S.NameAndMailWrapper>
              </S.AvatarNameWrapper>
            </S.TopMobileTitle>
            <DotsMenu
              id="userPanelMobileDotsMenu"
              items={menuItems}
              alignRight
            />
          </S.TopMobile>

          <S.BottomMobile>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <S.BottomMobileTitle>
                  <Text type="label">
                    {translate("space_settings_user_list_permissions_title")}
                  </Text>
                </S.BottomMobileTitle>
                {userPermissions}
              </Box>
              <Box display="flex" flexDirection="column">
                <S.BottomMobileTitle>
                  <Text type="label">
                    {translate("staff_table_num_groups")}
                  </Text>
                </S.BottomMobileTitle>
                <GroupNumberBadge number={groupsNumber} />
              </Box>
            </Box>
          </S.BottomMobile>

          <S.TabletAndUp>
            <S.Column width="30%">
              <S.AvatarNameWrapper>
                <Avatar
                  theme={whiteTheme}
                  url={avatar?.small}
                  alt={`${name} - ${surname}`}
                  rounded
                  size="m"
                  withIcon={{ icon: "shieldPermissions", dimension: 24 }}
                  position="bottom"
                />
                {name} {surname}
              </S.AvatarNameWrapper>
            </S.Column>
            <S.Column width="30%" greyColor>
              {email}
            </S.Column>
            <S.Column width="10%">
              <GroupNumberBadge number={groupsNumber} />
            </S.Column>
            <S.Column width="30%">{userPermissions}</S.Column>
          </S.TabletAndUp>
        </S.InfoContainer>
      </S.TriggerRowArea>
      <S.HideOnMobile>
        <DotsMenu id="staffPanelDesktopDotsMenu" items={menuItems} alignRight />
      </S.HideOnMobile>
    </Panel>
  );
};

export default observer(StaffPanel);
