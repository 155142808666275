import styled, { css } from "styled-components";
import colors from "@ds_themes/colors";

import { SCircleProps, SAvatarBadgeProps } from "./types";

export const Circle = styled.div<SCircleProps>`
  ${({ theme, size = "m" }) => {
    if (size === "image") {
      return undefined;
    }

    return {
      height: theme.avatar[size].mask,
      width: theme.avatar[size].mask
    };
  }}
  border: ${({ withActiveBorder }) =>
    withActiveBorder ? `1.6px solid ${colors.violet[501]}` : undefined};
  padding: ${({ withActiveBorder }) => (withActiveBorder ? "3px" : undefined)};
  border-radius: ${({ rounded }) => (rounded ? "100%" : "10%")};
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 10px;
  position: relative;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  & > img {
    object-fit: contain;
    border-radius: ${({ withActiveBorder }) =>
      withActiveBorder ? "50%" : undefined};
  }
`;

export const Badge = styled.div<SAvatarBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ dimension }) => (dimension ? `${dimension}px` : "16px")};
  width: ${({ dimension }) => (dimension ? `${dimension}px` : "16px")};
  position: absolute;
  margin-left: ${({ dimension }) =>
    dimension ? `${32 - dimension / 2}px` : "24px"};
  margin-top: ${({ position, dimension }) =>
    position === "top"
      ? "-44px"
      : dimension
        ? `${-4 - dimension / 2}px`
        : "-12px"};
  border-radius: 50%;
  background-color: ${({ background }) => background};
  color: ${({ text }) => text};
  span {
    padding-bottom: 1px;
  }
  svg {
    color: ${({ text }) => text};
  }
`;

export const TeacherRibbon = styled.div`
  position: absolute;
  top: 3px;
  right: -5px;
  color: ${({ theme }) => theme.avatar.ribbon};
`;
