import { extractErrorMessage } from "src/js/modules/messageManager";
import getUserClient, { getLoginUserClient } from "src/js/modules/connection";
import {
  api_user_activation_code,
  api_user_score,
  api_users_me,
  getVersionedApiUrl,
  api_user_can_vote_app,
  api_users_delete,
  api_users_name,
  api_users_check_mail,
  api_users_email,
  api_users_password,
  api_users_email_reject,
  api_users_email_resend,
  api_user_sso_provider,
  api_user_wallet_details,
  api_user_wallet_update,
  api_education_levels,
  api_user_required_custom_fields,
  api_user_custom_fields,
  api_live_opening
} from "src/js/repository/apiUrls";
import { WalletBannerIsHidden } from "src/js/pages/spaces/dashboard/SpacesBadges/components/ConnectYourWallet/const";
import { CustomFieldsDTO, SSOProvider, User } from "src/js/types";
import { WalletResponse } from "./types";

export async function fetchLoggedUser() {
  const response = await getLoginUserClient().get<User>(
    getVersionedApiUrl(api_users_me(), "v3")
  );
  return response.data;
}

export function changeLocale(language) {
  const body = {
    language
  };
  return getUserClient()
    .put(getVersionedApiUrl(api_users_me()), body)
    .then(({ data }) => data);
}

export type EducationLevelsDTO = {
  id: string;
  name: string;
};
export async function fetchEducationLevel() {
  return getUserClient()
    .get<EducationLevelsDTO[]>(getVersionedApiUrl(api_education_levels(), "v3"))
    .then(({ data }) => data);
}

export function changeName(name, surname, password) {
  const body = {
    name,
    surname,
    password
  };
  return getUserClient()
    .put(getVersionedApiUrl(api_users_name()), body)
    .then(({ data }) => data)
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_change_name"));
      throw Err;
    });
}

export function checkEmailAvailability(email) {
  return getUserClient()
    .get(getVersionedApiUrl(api_users_check_mail(email)))
    .then(({ data }) => data)
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_check_mail_availability")
      );
      throw Err;
    });
}

export function saveEmail(email, password) {
  const body = {
    email,
    password
  };
  return getUserClient()
    .put(getVersionedApiUrl(api_users_email()), body)
    .then(({ data }) => data)
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_save_mail"));
      throw Err;
    });
}

export function changePassword(password, old_password) {
  const body = {
    password,
    old_password
  };
  return getUserClient()
    .post(getVersionedApiUrl(api_users_password()), body)
    .then(({ data }) => data)
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_change_password")
      );
      throw Err;
    });
}

export const trackLiveOpenedEvent = async (userId, groupId, liveRoomId) => {
  const body = {
    userId,
    groupId,
    liveRoomId
  };
  return getUserClient()
    .post(getVersionedApiUrl(api_live_opening(), "v3"), body)
    .then(({ data }) => data)
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "general_error"));
      throw Err;
    });
};

export function fetchUserApproveCode() {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_activation_code()))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_logged_user")
      );
      throw Err;
    });
}

export function fetchUserScore(user_id) {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_score({ user_id })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_logged_user")
      );
      throw Err;
    });
}

export function fetchUserCanVoteApp() {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_can_vote_app()))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_logged_user")
      );
      throw Err;
    });
}

export function resendEmailChange() {
  return getUserClient()
    .post(getVersionedApiUrl(api_users_email_resend()))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_resend_email_change")
      );
      throw Err;
    });
}

export function rejectEmailChange() {
  return getUserClient()
    .patch(getVersionedApiUrl(api_users_email_reject()))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_reject_email_change")
      );
      throw Err;
    });
}

export function deleteUserAccount({ email, password }) {
  return getUserClient()
    .post(getVersionedApiUrl(api_users_delete()), { email, password })
    .then(({ data }) => {
      return data;
    });
}

export const fetchUserSSOProvider = (email: string) => {
  return getUserClient()
    .get<{
      providerCode: SSOProvider;
      tenantId: string;
    }>(getVersionedApiUrl(api_user_sso_provider(), "v3"), {
      params: {
        email
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(error);
      throw Err;
    });
};

export const fetchUserCustomFields = async ({
  userId,
  spaceId
}: {
  userId: number;
  spaceId: string;
}) => {
  return getUserClient()
    .get<CustomFieldsDTO>(
      getVersionedApiUrl(
        api_user_required_custom_fields({ userId, spaceId }),
        "v3"
      )
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(error);
      throw Err;
    });
};

type SaveCustomFieldsBody = {
  countryOfBirth: string;
  cityOfBirth: string;
  countryOfResidence: string;
  cityOfResidence: string;
  educationLevel: string;
  vat: string;
  identification: string;
};
export const saveUserCustomFields = async ({
  userId,
  spaceId,
  customFields
}: {
  userId: number;
  spaceId: string;
  customFields: Partial<CustomFieldsDTO>;
}) => {
  const body: Partial<SaveCustomFieldsBody> = {};
  if (customFields?.cityOfBirth) {
    body.cityOfBirth = customFields.cityOfBirth.id;
  }
  if (customFields?.countryOfBirth) {
    body.countryOfBirth = customFields.countryOfBirth.id;
  }
  if (customFields?.cityOfResidence) {
    body.cityOfResidence = customFields.cityOfResidence.id;
  }
  if (customFields?.countryOfResidence) {
    body.countryOfResidence = customFields.countryOfResidence.id;
  }
  if (customFields?.educationLevel) {
    body.educationLevel = customFields.educationLevel;
  }

  if (customFields?.identification) {
    if (typeof customFields.identification === "string") {
      body.identification = customFields.identification;
    } else {
      body.identification = customFields.identification.value;
    }
  }
  if (customFields?.vat) {
    if (typeof customFields.vat === "string") {
      body.vat = customFields.vat;
    } else {
      body.vat = customFields.vat.value;
    }
  }

  return getUserClient()
    .post<Partial<SaveCustomFieldsBody>>(
      getVersionedApiUrl(api_user_custom_fields({ userId, spaceId }), "v3"),
      body
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(error);
      throw Err;
    });
};

export const getUserWalletDetails = async (uuid: string) => {
  try {
    const response = await getUserClient().get<WalletResponse>(
      getVersionedApiUrl(api_user_wallet_details(uuid), "v3")
    );
    return response.data;
  } catch (error) {
    throw error?.response.data;
  }
};

export const createWallet = async (
  uuid: string,
  address: string,
  chain: string
) => {
  const walletData = { address, chain };

  try {
    const response = await getUserClient().post<WalletResponse>(
      getVersionedApiUrl(api_user_wallet_details(uuid), "v3"),
      walletData
    );

    return response.data;
  } catch (error) {
    throw error?.response.data;
  }
};

export const deleteUserWallet = async (userId: string, walletId: string) => {
  try {
    const response = await getUserClient().delete(
      getVersionedApiUrl(api_user_wallet_update({ userId, walletId }), "v3")
    );

    localStorage.removeItem(WalletBannerIsHidden);
    return response.data;
  } catch (error) {
    throw error?.response.data;
  }
};
