import styled from "styled-components";

export const UsersList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:not(:last-child) {
    margin-bottom: 2px;
  }
`;

export const WrapperCtaTertiary = styled.div`
  width: max-content;
  > button {
    padding: 0px;
  }
`;

export const Mobile = styled.span`
  display: inline-block;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const TabletAndUp = styled.span`
  display: none;

  @media screen and (min-width: 992px) {
    display: inline;
  }
`;

export const StyledList = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
