import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { useSpaceById } from "src/js/query";
import { navigateTo } from "src/legacy/modules/history";

const useSetupSpace = () => {
  const [blocked, setBlocked] = useState(false);
  const {
    SpaceStore: { setActiveSpaceById, activeSpaceId },
    SpacesSettingsStore: { getSpaceSettings }
  } = useStores();

  const { space_slug } = useParams<{ space_slug: string }>();

  const { pathname } = useLocation();

  const { data } = useSpaceById(space_slug, {
    enabled: !!space_slug
  });

  useEffect(() => {
    if (!data) return;
    if (data.space.id !== activeSpaceId) {
      getSpaceSettings({
        spaceId: data.space.id,
        checkSubscription: false
      });
      setActiveSpaceById(data.space.id);
    }
    setBlocked(data.spaceSettings.blocked);
  }, [data]);

  useEffect(() => {
    if (
      blocked &&
      !pathname.includes("overquotablocked") &&
      !pathname.includes("chat")
    ) {
      navigateTo(createUrl("space_groups_overquota_blocked", { space_slug }));
    }

    if (!blocked && pathname.includes("overquotablocked")) {
      navigateTo(createUrl("space_groups", { space_slug }));
    }
  }, [blocked, pathname]);

  return { spaceSlug: space_slug, spaceIsBlocked: blocked };
};

export default useSetupSpace;
