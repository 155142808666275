import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import {
  exportGroupDataCsv,
  exportUserDataCsv
} from "src/js/repository/scormRepository";

type BaseExportData = {
  groupId: number;
  email: string;
};

type ExportGroupDataProps = BaseExportData & {
  includeNoProgress?: boolean;
};

type OnSubmitProps = {
  email: string;
  includeNoProgress?: boolean;
};

const useExportData = ({ userUuid }: { userUuid?: string }) => {
  const {
    GroupStore: { activeGroup }
  } = useStores();

  const { mutateAsync: onExportGroupData } = useMutation(
    async ({ groupId, email, includeNoProgress }: ExportGroupDataProps) => {
      return exportGroupDataCsv({ groupId, email, includeNoProgress });
    }
  );

  const { mutateAsync: onExportUserData } = useMutation(
    async ({ groupId, email }: BaseExportData) => {
      return exportUserDataCsv({ userUuid, groupId, email });
    },
    {
      onError: () => {
        showToastError({ str: "general_error_message" });
      }
    }
  );

  const onSubmit = useCallback(
    ({ email, includeNoProgress }: OnSubmitProps) => {
      if (userUuid) {
        return onExportUserData({ groupId: activeGroup.id, email });
      }
      return onExportGroupData({
        email,
        groupId: activeGroup.id,
        includeNoProgress
      });
    },
    [onExportUserData, onExportGroupData, activeGroup, userUuid]
  );

  return { onSubmit };
};

export default useExportData;
