import React from "react";
import { navigateTo } from "Legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { convertMarkup, highlightText } from "src/js/modules/textFunction";
import styled, { useTheme } from "styled-components";
import { Avatar, Icon } from "@arcadia/design-system";
import { EventDomain, SearchResponseItem, SearchbarEvent } from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { HTMLParser } from "src/js/components/HTMLParser";
import SearchResultBreadcrumb from "./SearchResultBreadcrumb";

const StyledAuthorBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;

  > span {
    margin-left: 8px;
  }
`;

type Props = {
  item: SearchResponseItem;
  keyword: string;
};
const SearchResultItem = ({ item, keyword }: Props) => {
  const { whiteTheme } = useTheme();

  const getImage = () => {
    let imageBox = null;
    let imgStyle = {};

    if (item.image) {
      imgStyle = {
        backgroundImage: `url(${item?.image?.medium})`
      };
      imageBox = <div style={imgStyle} className="search-result__image" />;
    }

    return imageBox;
  };

  const getFormattedText = () => {
    const highlightedText = highlightText(item.text, keyword);
    const marckupText = convertMarkup(highlightedText);
    return marckupText;
  };

  const handleClick = () => {
    _trackEvent(EventDomain.Searchbar, SearchbarEvent.SearchResultsClick);
    switch (item.type) {
      case "post":
      case "post-comment":
      case "post-comment-reply":
      case "post-resource":
        navigateTo(
          createUrl("post_detail", {
            space_slug: item.spaceSlug,
            group_id: item.groupId,
            post_id: item.postId
          })
        );
        break;
      case "board":
        navigateTo(
          createUrl("module_detail", {
            space_slug: item.spaceSlug,
            group_id: item.groupId,
            module_id: item.boardId
          })
        );
        break;
      case "board-element":
      case "board-element-comment":
      case "board-element-comment-reply":
        navigateTo(
          createUrl("module_element", {
            space_slug: item.spaceSlug,
            group_id: item.groupId,
            module_id: item.boardId,
            element_id: item.boardElementId
          })
        );
        break;
      case "exercise":
        navigateTo(
          createUrl("exercise_detail", {
            space_slug: item.spaceSlug,
            group_id: item.groupId,
            exercise_id: item.exerciseId
          })
        );
        break;
      default:
        navigateTo(
          createUrl("wall", {
            space_slug: item.spaceSlug,
            group_id: item.groupId
          })
        );
    }
  };

  return (
    <div className="search-result__item margin-bottom-15" onClick={handleClick}>
      <div className="search-result__info">
        <SearchResultBreadcrumb item={item} />
        <div className="search-result__text">
          <HTMLParser htmlString={getFormattedText()} />
        </div>
        <StyledAuthorBlock>
          {item.authorAvatar ? (
            <Avatar
              theme={whiteTheme}
              url={item.authorAvatar.small}
              alt={`${item.authorName} - ${item.authorSurname}`}
              size="xs"
            />
          ) : (
            <Icon icon="user" />
          )}
          <span>{`${item.authorName} ${item.authorSurname}`}</span>
        </StyledAuthorBlock>
      </div>
      {getImage()}
    </div>
  );
};

export default SearchResultItem;
