/* eslint-disable no-lonely-if */
import { ActionButton, Box, Dropdown, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PillToggle } from "src/js/pages/App/Spaces/NotificationsPage/components/PillToggle";
import { useDebounce, useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  NotificationArea,
  NotificationAreaLabel,
  NotificationAreaModel,
  NotificationAreaValue,
  NotificationReadModel,
  OtherAreaValue
} from "src/js/types/models/Notification";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { READ_FILTER_OPTIONS } from "../../NotificationsPage.const";
import { useNotificationsParameters } from "../../hooks";

import * as S from "./Filters.styles";

const addIfMissingRemoveIfPresent = (
  array: NotificationAreaModel[],
  value: NotificationAreaModel | NotificationAreaModel[]
): NotificationAreaModel[] => {
  const newData = new Set(array);
  const values = Array.isArray(value) ? value : [value];

  newData.forEach(d => {
    if (!NotificationAreaValue.includes(d)) {
      newData.delete(d);
    }
  });

  values.forEach(v => {
    if (newData.has(v)) {
      newData.delete(v);
    } else {
      newData.add(v);
    }
  });

  return Array.from(newData);
};

const Filters = () => {
  const { area, groupId, read, setParams } = useNotificationsParameters();
  const {
    NotificationFilterStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const [filterString, setFilterString] = useState("");
  const filterDebounced = useDebounce(filterString, 500);

  const { groupList } = NotificationFilterStore;

  const { greyTheme } = useTheme();

  useEffect(() => {
    NotificationFilterStore.setGroupFilterString({
      filterString: filterDebounced
    });
  }, [filterDebounced]);

  useEffect(() => {
    NotificationFilterStore.fetchGroupList();
  }, []);

  const updateSelectedArea = ({ area: newArea }) => {
    setParams({
      groupId,
      read,
      area: addIfMissingRemoveIfPresent(area, newArea)
    });
  };

  const areaPills = ({ isMobile }) => {
    return (
      <>
        <PillToggle
          id={`Wall ${isMobile ? "mobile" : "desktop"} `}
          type={NotificationAreaLabel.Wall}
          active={area?.includes(NotificationArea.Wall)}
          onChange={() => updateSelectedArea({ area: NotificationArea.Wall })}
        />
        <PillToggle
          id={`Exercise ${isMobile ? "mobile" : "desktop"} `}
          type={NotificationAreaLabel.Exercise}
          active={area?.includes(NotificationArea.Exercise)}
          onChange={() =>
            updateSelectedArea({ area: NotificationArea.Exercise })
          }
        />
        <PillToggle
          id={`Lesson ${isMobile ? "mobile" : "desktop"} `}
          type={NotificationAreaLabel.Lesson}
          active={area?.includes(NotificationArea.Lesson)}
          onChange={() => updateSelectedArea({ area: NotificationArea.Lesson })}
        />
        <PillToggle
          id={`Calendar ${isMobile ? "mobile" : "desktop"} `}
          type={NotificationAreaLabel.Calendar}
          active={area?.includes(NotificationArea.Calendar)}
          onChange={() =>
            updateSelectedArea({ area: NotificationArea.Calendar })
          }
        />
        <PillToggle
          id={`Other ${isMobile ? "mobile" : "desktop"} `}
          type={NotificationAreaLabel.Other}
          active={area.some(val => {
            return OtherAreaValue.indexOf(val as any) !== -1;
          })}
          onChange={() => updateSelectedArea({ area: OtherAreaValue })}
        />
      </>
    );
  };

  const goToSettings = () => {
    navigateTo(createUrl("user_notification_settings"));
  };

  return (
    <S.FilterSection>
      <Box display="flex" flex={1}>
        <S.DropdownContainer>
          <S.DropdownLabelContainer>
            <Text type="formSubtitle">
              {translate(
                "notifications_section_all_notifications_group_filter_dropdown_label"
              )}
            </Text>
          </S.DropdownLabelContainer>
          <S.DropdownGroupFilterContainer>
            {/* TODO fix the fetch of the group if the selected id is not in the list */}
            <Dropdown
              theme={greyTheme}
              placeholder={translate("groups")}
              optionsList={groupList.map(({ key, value }) => ({
                id: key.toString(),
                label: value
              }))}
              selectedOptionId={groupId?.toString()}
              setSelectedOptionId={gId =>
                setParams({
                  area,
                  read,
                  groupId: parseInt(gId, 10)
                })
              }
              searchable
              onReset={() => {
                setParams({
                  area,
                  read,
                  groupId: null
                });
              }}
              resetPlaceholder={translate(
                "notifications_remove_groups_filters_dropdown_cta_label"
              )}
              onSearchFunction={event => {
                event.persist();
                setFilterString(event.target.value);
              }}
              searchPlaceholder={translate("search_in_n_groups", {
                nGroups: groupList.length
              })}
            />
          </S.DropdownGroupFilterContainer>
        </S.DropdownContainer>
      </Box>

      <Box display="flex" flex={1} justifyContent="flex-end" gap={16}>
        {!isLayoutModeMobile && (
          <ActionButton
            theme={greyTheme}
            icon="gear"
            variant="secondary"
            onClick={goToSettings}
          />
        )}
        <S.DropdownFilterContainer>
          <Dropdown
            theme={greyTheme}
            selectedOptionId={read}
            optionsList={READ_FILTER_OPTIONS.map(({ key, value }) => ({
              id: key,
              label: translate(value)
            }))}
            setSelectedOptionId={id => {
              setParams({
                area,
                groupId,
                read: id as NotificationReadModel
              });
            }}
          />
        </S.DropdownFilterContainer>
      </Box>
      <Box display="flex" width="100%">
        <S.TypeFilterSection>
          <S.TypeFilterLabelContainer aria-hidden>
            <Text type="formSubtitle">
              {translate("notifications_filter_area_label")}
            </Text>
          </S.TypeFilterLabelContainer>
          {areaPills({ isMobile: false })}
        </S.TypeFilterSection>
      </Box>
    </S.FilterSection>
  );
};

export default observer(Filters);
