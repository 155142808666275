import { Box, Button, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { Trans, useTranslation } from "src/js/translation";
import { GroupDetailsModel } from "src/js/types";
import styled, { useTheme } from "styled-components";
import BadgeGroupWithIcon from "./BadgeGroupWithIcon";
import BannerWarning from "./BannerWarning";
import ModalPermissionTemplate from "./ModalPermissionTemplate";

const StyledTextSubtitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;
const StyledTextDescription = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

export const StyledLinkBtn = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
`;

type ModalSetupTemplateProps = {
  group: GroupDetailsModel;
  onConfirm: () => void;
};

const ModalSetupTemplate = ({ group, onConfirm }: ModalSetupTemplateProps) => {
  const {
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const openSetupTemplateModal = () => {
    openModal(() => <ModalSetupTemplate group={group} onConfirm={onConfirm} />);
  };

  const openPermissionModal = () => {
    openModal(() => (
      <ModalPermissionTemplate
        onBack={() => {
          closeModal();
          openSetupTemplateModal();
        }}
      />
    ));
  };

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate({ text: "modal_setup_template_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        hasAdaptiveWidth: false
      }}
      mobileProps={{
        withAdaptiveHeight: false
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        padding="16px 20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          margin="auto"
          {...(!isLayoutModeMobile && {
            padding: "24px 0px 32px"
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <BadgeGroupWithIcon
              group={group}
              icon="bookmarkStar"
              iconSize={16}
              iconWrapperStyle={{
                width: 32,
                height: 32
              }}
            />
          </Box>
          <StyledTextSubtitle>
            {translate("modal_setup_template_subtitle")}
          </StyledTextSubtitle>
          <StyledTextDescription>
            {translate("modal_setup_template_description")}
          </StyledTextDescription>
          <BannerWarning icon="error">
            <Trans
              i18nKey="modal_setup_template_warning"
              components={{
                Permission: <StyledLinkBtn onClick={openPermissionModal} />
              }}
            />
          </BannerWarning>
        </Box>
        <Button
          variant="primary"
          theme={whiteTheme}
          fullWidth
          onClick={onConfirm}
        >
          {translate({
            text: "modal_setup_template_cta_confirm"
          })}
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default observer(ModalSetupTemplate);
