import {
  Box,
  Button,
  ContextualError,
  Input,
  Text
} from "@arcadia/design-system";
import React from "react";
import { useForm } from "react-hook-form";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { useMeQueryCached } from "src/js/query";
import { deleteUserAccount } from "src/js/repository/userRepository";
import { __FORM_DELETE_ACCOUNT__ } from "src/js/settings/settingsFormValidation";
import { __DELETE_ACCOUNT_ERASER__ } from "src/js/settings/settingsImage";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import * as S from "./UserAccountDeleteModal.styles";

const Warning = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <S.ModalBannerContainer>
      <ContextualError
        theme={whiteTheme}
        text={translate("delete_account_modal_warning_message")}
      />
    </S.ModalBannerContainer>
  );
};

type AccountDeleteFormData = {
  email: string;
  password: string;
};

const UserAccountDeleteModal = () => {
  const { register, handleSubmit, watch, errors } =
    useForm<AccountDeleteFormData>();
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal }
  } = useStores();
  const { data: user } = useMeQueryCached();
  const inputEmail = watch("email");

  const handleSubmission = handleSubmit(formData => {
    deleteUserAccount({
      email: formData.email.toLowerCase(),
      password: formData.password
    })
      .then(() => {
        showToastSuccess({
          str: translate("account_delete_success")
        });
        closeModal();
        navigateTo(createUrl("logout"));
      })
      .catch(() =>
        showToastError({
          str: translate("Error" as TranslationKeys)
        })
      );
  });

  return (
    <ModalLayout
      mobileProps={{
        closeLabel: translate("back"),
        onClose: closeModal
      }}
      labels={{
        title: translate("delete_account_modal_title")
      }}
      closeModal={closeModal}
    >
      <form onSubmit={handleSubmission}>
        <S.ModalBodyContainer>
          {!isLayoutModeMobile && (
            <Box marginBottom={40}>
              <Warning />
            </Box>
          )}
          <S.ModalImageContainer src={__DELETE_ACCOUNT_ERASER__} />
          <Box>
            <Text type="formField">
              {translate("delete_account_modal_password_check")}
            </Text>
            <S.InputWrapper>
              <Input
                theme={isLayoutModeMobile ? greyTheme : whiteTheme}
                label={translate("delete_account_modal_mail")}
                name="email"
                ref={register(__FORM_DELETE_ACCOUNT__.email)}
                rounded
                feedback={
                  errors.email
                    ? {
                        type: "error",
                        message: translate(
                          errors.email.message as TranslationKeys
                        )
                      }
                    : null
                }
              />
            </S.InputWrapper>
            {user?.isSingleSignOnAccount ? null : (
              <Input
                theme={isLayoutModeMobile ? greyTheme : whiteTheme}
                label={translate("delete_account_modal_password")}
                name="password"
                isPassword
                ref={register(__FORM_DELETE_ACCOUNT__.password)}
                rounded
                feedback={
                  errors.password
                    ? {
                        type: "error",
                        message: translate(
                          errors.password.message as TranslationKeys
                        )
                      }
                    : null
                }
              />
            )}
          </Box>
          {isLayoutModeMobile && (
            <Box marginTop={20}>
              <Warning />
            </Box>
          )}
          <S.ModalFooterContainer>
            <Button onClick={closeModal} theme={whiteTheme} variant="secondary">
              {translate("delete_account_modal_cancel")}
            </Button>

            <Button
              type="submit"
              theme={whiteTheme}
              variant="danger"
              disabled={
                user?.isSingleSignOnAccount ? inputEmail !== user?.email : false
              }
            >
              {translate("delete_account_modal_confirm")}
            </Button>
          </S.ModalFooterContainer>
        </S.ModalBodyContainer>
      </form>
    </ModalLayout>
  );
};

export default UserAccountDeleteModal;
