import { useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import {
  GroupFeatures,
  GroupFeaturesType,
  GroupSettingsEnum
} from "src/js/types";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { useGroupByIdV3Cached, useSpaceByIdCached } from "src/js/query";
import { useMutation, useStores } from "src/js/hooks";
import { editGroupSettings } from "src/js/repository/groupRepository";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";

const useGroupFeatures = () => {
  const {
    GroupStore: { setActiveGroupSettings }
  } = useStores();
  const { translate } = useTranslation();
  const [loading, setLoading] = useState<GroupFeaturesType>(undefined);
  const { params } = useRouteMatch<{ group_id?: string; space_slug?: string }>({
    path: WeSchoolRoutes.App.Spaces.SpaceGroup.groupId
  }) ?? {
    params: {
      space_slug: undefined,
      group_id: undefined
    }
  };

  const groupId = useMemo(
    () => (params?.group_id ? parseInt(params.group_id, 10) : undefined),
    [params?.group_id]
  );

  const spaceId = useMemo(
    () => (params?.space_slug ? params.space_slug : undefined),
    [params?.space_slug]
  );

  const { data: group, refetch: refetchGroup } = useGroupByIdV3Cached(
    { groupId },
    { enabled: !!groupId }
  );

  const { data: space } = useSpaceByIdCached(spaceId, { enabled: !!spaceId });

  const groupFeaturesVisibility = useMemo(
    () => ({
      [GroupFeatures.FreezeComments]:
        group?.settings?.freezeComments === GroupSettingsEnum.enabled,
      [GroupFeatures.DisallowSuggestions]:
        group?.settings?.disallowSuggestions === GroupSettingsEnum.enabled,
      [GroupFeatures.HideMembers]:
        group?.settings?.hideMembers === GroupSettingsEnum.enabled,
      [GroupFeatures.DisableChat]: space?.spaceSettings?.disableChat
    }),
    [
      group?.settings?.freezeComments,
      group?.settings?.disallowSuggestions,
      group?.settings?.hideMembers,
      space?.spaceSettings?.disableChat
    ]
  );

  const changeFeatureMutation = useMutation(
    async ({
      feature,
      value
    }: {
      feature: GroupFeaturesType;
      value: boolean;
    }) => {
      setLoading(feature);
      const enumValue = value
        ? GroupSettingsEnum.disabled
        : GroupSettingsEnum.enabled;
      await editGroupSettings({ groupId, settings: { [feature]: enumValue } });
    },
    {
      onSuccess: async () => {
        await refetchGroup();
        // TODO: Remove this, it's just for backward compatibility
        setActiveGroupSettings(group.settings);
        showToastSuccess({
          str: translate("group_settings_section_message_hidden_success")
        });
        setLoading(undefined);
      },
      onError: error => {
        showToastError({
          str: extractErrorMessage(error, translate("general_error_retry"))
        });
        setLoading(undefined);
      }
    }
  );

  const onChangeFeature = (feature: GroupFeaturesType, value: boolean) => {
    if (feature === GroupFeatures.DisableChat) return;
    changeFeatureMutation.mutate({ feature, value });
  };

  return { ...groupFeaturesVisibility, loading, onChangeFeature };
};

export default useGroupFeatures;
