import { Heading, PageNav, SearchBar, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { InfoTooltip } from "src/js/components/InfoTooltip";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useDebounce, useStores } from "src/js/hooks";
import { AddStaffModal } from "src/js/pages/spaces/dashboard/Staff/components/AddStaffModal";
import { EditStaffModal } from "src/js/pages/spaces/dashboard/Staff/components/EditStaffModal";
import { useStaffMembers } from "src/js/query/staff";
import { __SPACE_STAFF_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { Trans } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { PermissionsTableModal } from "./components/PermissionsTableModal";
import { StaffPanel } from "./components/StaffPanel";
import * as S from "./Staff.styles";

const Staff = () => {
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceId },
    ModalStore: { openModal }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const [showAllContent, setShowAllContent] = useState<boolean>(false);
  const [queryString, setQueryString] = useState("");
  const [offset, setOffset] = useState(0);
  const queryStringDebounced = useDebounce(queryString, 300);

  useEffect(() => {
    setOffset(0);
  }, [queryStringDebounced]);

  const listQueryParams: {
    spaceId: string;
    offset: number;
    limit: number;
    name?: string;
  } = {
    spaceId: activeSpaceId,
    offset,
    limit: __SPACE_STAFF_LIST_LIMIT__,
    name: queryStringDebounced
  };

  const { data } = useStaffMembers(listQueryParams);
  const { results: members = [], total, unfilteredTotal } = data || {};

  const openAddStaffModal = () => {
    openModal(() => <AddStaffModal listQueryParams={listQueryParams} />);
  };

  const openEditStaffModal = member => {
    openModal(() => (
      <EditStaffModal user={member} listQueryParams={listQueryParams} />
    ));
  };

  const openPermissionsTableModal = () => {
    openModal(() => <PermissionsTableModal />);
  };

  return (
    <MainContainer>
      <S.PageContainer>
        <S.StaffTitleContainer>
          <S.HeaderInfoWrap>
            <S.TitleWrapper>
              <Heading level="1">{translate("staff_title")}</Heading>
              {isLayoutModeMobile ? (
                <InfoTooltip
                  side="bottom"
                  customTooltipProps={{
                    title: translate("staff_title"),
                    description: (
                      <p>
                        {`${translate("staff_description_pre")} `}
                        <Trans i18nKey="staff_description" />
                      </p>
                    )
                  }}
                />
              ) : null}
              <S.Counter>{unfilteredTotal}</S.Counter>
              {isLayoutModeMobile ? (
                <S.StyledLinkButton onClick={openPermissionsTableModal}>
                  <Text type="label">
                    {translate("staff_permissions_and_roles")}
                  </Text>
                </S.StyledLinkButton>
              ) : null}
            </S.TitleWrapper>
            {!isLayoutModeMobile ? (
              <S.DescriptionContainer>
                <div>
                  <Text type="formDescription">
                    {translate("staff_description_pre")}
                  </Text>
                </div>
                {showAllContent ? <Trans i18nKey="staff_description" /> : null}
                <S.StyledLinkButton
                  onClick={() => {
                    setShowAllContent(prev => !prev);
                  }}
                >
                  <Text type="textLink">
                    {translate(
                      showAllContent
                        ? "staff_description_cta_close"
                        : "staff_description_cta_read_more"
                    )}
                  </Text>
                </S.StyledLinkButton>
              </S.DescriptionContainer>
            ) : null}
          </S.HeaderInfoWrap>
          {!isLayoutModeMobile && (
            <S.StyledButton
              onClick={openAddStaffModal}
              variant="primary"
              theme={greyTheme}
              withIcon={{
                icon: "plus",
                positionIcon: "left",
                heightIcon: 16,
                widthIcon: 16,
                viewbox: "0 0 24 24"
              }}
            >
              {translate("staff_table_card_add_member")}
            </S.StyledButton>
          )}
        </S.StaffTitleContainer>
        <S.SearchbarWrapper>
          <SearchBar
            theme={greyTheme}
            label="searchQuery"
            value={queryString}
            onChange={event => {
              setQueryString(event.target.value);
            }}
            placeholder={translate("user_or_mail_search_placeholder")}
          />
        </S.SearchbarWrapper>
        <S.StaffListHeader>
          <S.HeaderContent>
            <S.Column width="30%">
              <Text type="label">{translate("staff_table_name")}</Text>
            </S.Column>
            <S.Column width="30%">
              <Text type="label">{translate("staff_table_email")}</Text>
            </S.Column>
            <S.Column width="10%">
              <Text type="label">{translate("staff_table_num_groups")}</Text>
            </S.Column>
            <S.Column width="30%">
              <S.StyledLinkButton onClick={openPermissionsTableModal}>
                <Text type="label">{translate("staff_table_permissions")}</Text>
              </S.StyledLinkButton>
            </S.Column>
          </S.HeaderContent>
          <S.ActionColumn>
            <Text type="label">{translate("staff_table_actions")}</Text>
          </S.ActionColumn>
        </S.StaffListHeader>
        {members.map(member => (
          <StaffPanel
            key={member.id}
            member={member}
            onEditClick={() => openEditStaffModal(member)}
          />
        ))}
        <S.RightAligner>
          <PageNav
            theme={greyTheme}
            totalRecords={total}
            pageLimit={__SPACE_STAFF_LIST_LIMIT__}
            startingOffset={offset}
            onPageChanged={p => setOffset((p - 1) * __SPACE_STAFF_LIST_LIMIT__)}
            label={{
              previous: translate({ text: "prev" }),
              next: translate({ text: "next" })
            }}
          />
        </S.RightAligner>
      </S.PageContainer>
      {isLayoutModeMobile && (
        <S.StyledFabButton
          onClick={openAddStaffModal}
          theme={greyTheme}
          variant="primary"
          icon="plus"
        />
      )}
    </MainContainer>
  );
};

export default observer(Staff);
