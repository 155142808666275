import { Icon } from "@ds_universal/Icon";
import styled, { css } from "styled-components";

export const ToggleBlockContainer = styled.div<{
  isSelected: boolean;
  marginBottom: number;
}>`
  position: relative;
  width: 100%;
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.primaryColor[500]};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;

export const ToggleBlockBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.primaryColor[500]};
  flex: 0 0 28px;
`;

export const TooltipContainer = styled.div`
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
`;
