import { IconName } from "@ds_types/components";
import { Toggle } from "@ds_universal/data_entry";
import { observer } from "mobx-react";
import React from "react";
import { InfoTooltip } from "src/js/components/InfoTooltip";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import * as S from "./ToggleBlock.styles";

const ToggleBlock = ({
  onClick = () => {},
  children,
  isSelected = false,
  disabled = false,
  marginBottom,
  icon,
  tooltipTitle,
  tooltipDescription
}: {
  onClick?: () => void;
  children: React.ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  marginBottom?: number;
  icon: IconName;
  tooltipTitle: string;
  tooltipDescription: string;
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();

  return (
    <S.ToggleBlockContainer isSelected={isSelected} marginBottom={marginBottom}>
      <S.ToggleBlockBody>
        <S.StyledIcon icon={icon} width={28} height={28} initialViewbox />
        {children}
        <S.TooltipContainer>
          <InfoTooltip
            side={isLayoutModeMobile ? "bottom" : "right"}
            customTooltipProps={{
              title: tooltipTitle,
              description: tooltipDescription
            }}
          />
        </S.TooltipContainer>
      </S.ToggleBlockBody>
      <div>
        <Toggle
          theme={whiteTheme}
          title={`toggleBlock-${tooltipTitle}`}
          size="small"
          checked={isSelected}
          disabled={disabled}
          onChange={onClick}
        />
      </div>
    </S.ToggleBlockContainer>
  );
};

export default observer(ToggleBlock);
