import { Icon, ModalSizeType } from "@arcadia/design-system";
import React from "react";
import { FileUploader } from "src/js/components/FileUploader";
import { AddDropboxResource } from "src/js/components/ResourcePicker/components/AddDropboxResource";
import { useStores } from "src/js/hooks";
import { PrepareWSResource } from "src/js/hooks/resources/useResourcePayload";
import { useTranslation } from "src/js/translation";
import { Resource } from "src/js/types";
import { FileType } from "../FileUploader/FileUploader.types";
import {
  RESOURCE_PICKER_ITEMS,
  RESOURCE_PICKER_SECTION
} from "./ResourcePicker.const";
import {
  DropdownItem,
  ResourcePickerSectionType
} from "./ResourcePicker.types";
import {
  AddAITextEditorResource,
  AddExerciseResource,
  AddLinkResource,
  AddTextResource
} from "./components";
import AddGDriveResource from "./components/AddGDriveResource/AddGDriveResource";

// TODO: We will need to pass modalOptions as param in useDropdownItems, to handle special cases in posts for opening multiple modals. modalOptions will be an object of styles(object) and modalId(string).

const useDropdownItems = (
  section: ResourcePickerSectionType,
  prepareModuleResourcePayload?: (fileList: FileList) => void,
  setUploadingProgress?: (id: number, progress: number) => void,
  elementIndex?: number,
  onStartOfUpload?: (resource?: Resource) => void,
  prepareWSResource?: PrepareWSResource
) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { closeModal }
  } = useStores();
  const weSchoolResourcesTitle = translate(
    "resource_picker_dropdown_group_weschool_resources"
  );
  const AIAssistantTitle = translate(
    "resource_picker_dropdown_group_ai_assistant"
  );

  const uploadTitle = translate("resource_picker_dropdown_group_upload");
  const cloudTitle = translate("resource_picker_dropdown_group_cloud");
  const embedTitle = translate("resource_picker_dropdown_group_embed");
  const dropdownItems: { [key: string]: DropdownItem[] } = {
    [AIAssistantTitle]: [
      {
        id: "ai-chatGpt",
        label: translate("resource_type_AI_editor"),
        item: RESOURCE_PICKER_ITEMS.AIEDITOR,
        resourceIcon: (
          <Icon icon="sparkles" height="20px" width="20px" initialViewbox />
        ),
        isBeta: true,
        purpleBackground: true,
        sections: [RESOURCE_PICKER_SECTION.MODULES],
        title: translate("resource_modal_AI_editor_title"),
        modalConfig: {
          desktopProps: { hasAdaptiveWidth: true, size: ModalSizeType.Big },
          mobileProps: {
            withDrag: false
          }
        },
        ModalBody: ({
          onSuccessCallback
        }: {
          onSuccessCallback: () => void;
        }) => (
          <AddAITextEditorResource
            onClose={closeModal}
            onSubmit={onSuccessCallback}
            setUploadingProgress={setUploadingProgress}
            elementId={elementIndex}
          />
        )
      }
    ],
    [weSchoolResourcesTitle]: [
      {
        id: "item-editor",
        label: translate("resource_type_editor"),
        item: RESOURCE_PICKER_ITEMS.EDITOR,
        resourceIcon: (
          <Icon
            icon="picker-texteditor-white"
            width={16}
            height={22}
            viewBox="0 0 16 22"
          />
        ),
        modalConfig: {
          desktopProps: { hasAdaptiveWidth: true, size: ModalSizeType.Big },
          mobileProps: { withDrag: false }
        },
        purpleBackground: true,
        title: translate("resource_modal_text_title"),
        isBeta:
          section === RESOURCE_PICKER_SECTION.WALL ||
          section === RESOURCE_PICKER_SECTION.MODULES,
        ModalBody: ({
          onSuccessCallback
        }: {
          onSuccessCallback: () => void;
        }) => (
          <AddTextResource
            onClose={closeModal}
            onCancel={closeModal}
            onSuccess={onSuccessCallback}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementId={elementIndex}
          />
        )
      },
      // {
      //   id: "item-module",
      //   label: translate("resource_type_module"),
      //   item: RESOURCE_PICKER_ITEMS.MODULE,
      //   resourceIcon: <Icon icon="book" width={20} height={20} />,
      //   purpleBackground: true,
      //   sections:  [RESOURCE_PICKER_SECTION.WALL]
      // },
      // {
      //   id: "item-module-block",
      //   label: translate("resource_type_module_block"),
      //   item: RESOURCE_PICKER_ITEMS.MODULE_BLOCK,
      //   resourceIcon: (
      //     <Icon icon="copy" width={20} height={20} viewBox="0 0 20 20" />
      //   ),
      //   purpleBackground: true,
      //   sections: [RESOURCE_PICKER_SECTION.WALL]
      // },
      {
        id: "item-test",
        label: translate("resource_type_excercise"),
        item: RESOURCE_PICKER_ITEMS.EXERCISE,
        sections: [
          RESOURCE_PICKER_SECTION.MODULES,
          RESOURCE_PICKER_SECTION.WALL
        ],
        resourceIcon: <Icon icon="weight" width={20} height={20} />,
        purpleBackground: true,
        title: translate("resource_modal_exercise_title"),
        ModalBody: ({
          onSuccessCallback
        }: {
          onSuccessCallback: () => void;
        }) => (
          <AddExerciseResource
            onSubmit={onSuccessCallback}
            onClose={closeModal}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        )
      }
      // {
      //   id: "item-agenda",
      //   label: translate("resource_type_agenda"),
      //   item: RESOURCE_PICKER_ITEMS.AGENDA,
      //   resourceIcon: <Icon icon="calendar" width={20} height={20} />,
      //   purpleBackground: true,
      //   sections: [RESOURCE_PICKER_SECTION.WALL]
      // }
    ],
    [uploadTitle]: [
      {
        id: "item-file",
        label: translate("resource_type_file"),
        item: RESOURCE_PICKER_ITEMS.FILE,
        title: translate("file_picker_modal_title"),
        subtitle: "",
        hasConfirm: false,
        hasCancel: true,
        resourceIcon: <Icon icon="desktop" width={20} height={20} />,
        purpleBackground: true,
        description: translate("resource_type_file_description"),
        withWarningMessage: true,
        ModalBody: () => (
          <FileUploader
            type={FileType.FILE}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-link",
        label: translate("resource_type_link"),
        item: RESOURCE_PICKER_ITEMS.LINK,
        resourceIcon: (
          <Icon icon="chain" width={18} height={18} viewBox="0 0 20 20" />
        ),
        purpleBackground: true,
        description: translate("resource_type_link_description"),
        title: translate("resource_modal_link_title"),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            title="resource_modal_link_title"
            linkType={RESOURCE_PICKER_ITEMS.LINK}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            onClose={closeModal}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        )
      },
      {
        id: "item-image",
        label: translate("resource_type_image"),
        title: translate("image_picker_modal_title"),
        item: RESOURCE_PICKER_ITEMS.IMAGE,
        hasConfirm: false,
        hasCancel: true,
        resourceIcon: (
          <Icon icon="image2" width={26} height={21} viewBox="0 0 28 24" />
        ),
        purpleBackground: true,
        description: translate("resource_type_image_description"),
        withWarningMessage: true,
        ModalBody: () => (
          <FileUploader
            type={FileType.IMAGE}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-video",
        label: translate("resource_type_video"),
        title: translate("video_picker_modal_title"),
        item: RESOURCE_PICKER_ITEMS.VIDEO,
        hasConfirm: false,
        hasCancel: true,
        resourceIcon: <Icon icon="play" width={20} height={20} />,
        purpleBackground: true,
        description: translate("resource_type_video_description"),
        withWarningMessage: true,
        ModalBody: () => (
          <FileUploader
            type={FileType.VIDEO}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-scorm",
        label: translate("resource_type_scorm"),
        item: RESOURCE_PICKER_ITEMS.SCORM,
        title: translate("scorm_picker_modal_title"),
        hasConfirm: false,
        hasCancel: true,
        description: translate("resource_type_scorm_description"),
        resourceIcon: (
          <Icon
            icon="picker-scorm-white"
            width={18}
            height={20}
            viewBox="0 0 20 20"
          />
        ),
        purpleBackground: true,
        sections: [RESOURCE_PICKER_SECTION.MODULES],
        withWarningMessage: true,
        ModalBody: () => (
          <FileUploader
            type={FileType.SCORM}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      }
    ],
    [cloudTitle]: [
      {
        id: "item-gdrive",
        label: "Google Drive",
        item: RESOURCE_PICKER_ITEMS.GDRIVE,
        resourceIcon: (
          <Icon
            icon="picker-googledrive"
            width={22}
            height={22}
            initialViewbox
          />
        ),
        ModalBody: ({
          onSuccessCallback
        }: {
          onSuccessCallback: () => void;
        }) => (
          <AddGDriveResource
            onClose={closeModal}
            onSuccess={onSuccessCallback}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        )
      },
      {
        id: "item-dropbox",
        label: "Dropbox",
        item: RESOURCE_PICKER_ITEMS.DROPBOX,
        resourceIcon: (
          <Icon
            icon="picker-dropbox"
            width={20}
            height={17}
            viewBox="0 0 64 55"
          />
        ),
        ModalBody: ({
          onSuccessCallback
        }: {
          onSuccessCallback: () => void;
        }) => (
          <AddDropboxResource
            onClose={closeModal}
            onSuccess={onSuccessCallback}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        )
      }
    ],
    [embedTitle]: [
      {
        id: "item-pdf",
        label: "PDF",
        item: RESOURCE_PICKER_ITEMS.PDF,
        hasConfirm: false,
        hasCancel: true,
        title: translate("pdf_picker_modal_title"),
        resourceIcon: (
          <Icon icon="picker-pdf" width={17} height={22} viewBox="0 0 53 64" />
        ),
        withWarningMessage: true,
        ModalBody: () => (
          <FileUploader
            type={FileType.PDF}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-gdocs",
        label: "Google Docs",
        item: RESOURCE_PICKER_ITEMS.GDOCS,
        resourceIcon: (
          <Icon
            icon="picker-googledoc"
            width={14}
            height={19}
            viewBox="0 0 47 64"
          />
        ),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            title="resource_modal_link_title"
            linkType={RESOURCE_PICKER_ITEMS.GDOCS}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            onClose={closeModal}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        ),
        title: translate("resource_modal_link_title")
      },
      {
        id: "item-gsheet",
        label: "Google Sheet",
        item: RESOURCE_PICKER_ITEMS.GSHEET,
        resourceIcon: (
          <Icon
            icon="picker-googlesheet"
            width={14}
            height={20}
            initialViewbox
          />
        ),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            title="resource_modal_link_title"
            linkType={RESOURCE_PICKER_ITEMS.GSHEET}
            onSuccess={onSuccessCallback}
            onClose={closeModal}
            onError={onErrorCallback}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        ),
        title: translate("resource_modal_link_title")
      },
      {
        id: "item-gslides",
        label: "Google Slides",
        item: RESOURCE_PICKER_ITEMS.GSLIDES,
        resourceIcon: (
          <Icon
            icon="picker-googleslides"
            width={16}
            height={22}
            viewBox="0 0 47 64"
          />
        ),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            title="resource_modal_link_title"
            linkType={RESOURCE_PICKER_ITEMS.GSLIDES}
            onSuccess={onSuccessCallback}
            onClose={closeModal}
            onError={onErrorCallback}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        ),
        title: translate("resource_modal_link_title")
      },
      {
        id: "item-excel",
        label: "Excel",
        title: translate("excel_picker_modal_title"),
        hasConfirm: false,
        hasCancel: true,
        item: RESOURCE_PICKER_ITEMS.EXCEL,
        withWarningMessage: true,
        resourceIcon: (
          <Icon
            icon="picker-excel"
            width={20}
            height={18}
            viewBox="0 0 64 60"
          />
        ),
        ModalBody: () => (
          <FileUploader
            type={FileType.SPREADSHEET}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-powerpoint",
        label: "Powerpoint",
        item: RESOURCE_PICKER_ITEMS.POWERPOINT,
        hasConfirm: false,
        hasCancel: true,
        title: translate("powerpoint_picker_modal_title"),
        withWarningMessage: true,
        resourceIcon: (
          <Icon
            icon="picker-powerpoint"
            width={21}
            height={19}
            viewBox="0 0 64 60"
          />
        ),
        ModalBody: () => (
          <FileUploader
            type={FileType.SLIDE}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-word",
        label: "Word",
        item: RESOURCE_PICKER_ITEMS.WORD,
        hasConfirm: false,
        hasCancel: true,
        title: translate("word_picker_modal_title"),
        withWarningMessage: true,
        resourceIcon: (
          <Icon icon="picker-word" width={20} height={18} viewBox="0 0 64 60" />
        ),
        ModalBody: () => (
          <FileUploader
            type={FileType.DOCUMENT}
            section={section}
            prepareModuleResourcePayload={prepareModuleResourcePayload}
            onClose={closeModal}
          />
        )
      },
      {
        id: "item-vimeo",
        label: "Vimeo",
        item: RESOURCE_PICKER_ITEMS.VIMEO,
        resourceIcon: (
          <Icon icon="picker-vimeo" width={24} height={7} viewBox="0 0 64 19" />
        ),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            title="resource_modal_vimeo_title"
            linkType={RESOURCE_PICKER_ITEMS.VIMEO}
            onSuccess={onSuccessCallback}
            onClose={closeModal}
            onError={onErrorCallback}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            elementIndex={elementIndex}
          />
        ),
        description: translate("resource_type_vimeo_description"),
        title: translate("resource_modal_vimeo_title")
      },
      {
        id: "item-youtube",
        label: "Youtube",
        item: RESOURCE_PICKER_ITEMS.YOUTUBE,
        resourceIcon: (
          <Icon
            icon="picker-youtube"
            width={20}
            height={14}
            viewBox="0 0 69 48"
          />
        ),
        ModalBody: ({ onSuccessCallback, onErrorCallback }) => (
          <AddLinkResource
            linkType={RESOURCE_PICKER_ITEMS.YOUTUBE}
            onSuccess={onSuccessCallback}
            onClose={closeModal}
            onError={onErrorCallback}
            onSubmit={onStartOfUpload}
            prepareWSResource={prepareWSResource}
            setUploadingProgress={setUploadingProgress}
            title="resource_modal_youtube_title"
            elementIndex={elementIndex}
          />
        ),
        description: translate("resource_type_youtube_description"),
        title: translate("resource_modal_youtube_title")
      }
    ]
  };

  const mergedItems = [
    ...dropdownItems[AIAssistantTitle],
    ...dropdownItems[weSchoolResourcesTitle],
    ...dropdownItems[uploadTitle],
    ...dropdownItems[cloudTitle],
    ...dropdownItems[embedTitle]
  ];

  return { dropdownItems, mergedItems };
};

export default useDropdownItems;
