import { FormValidatorMap } from "src/js/types";
import { EmailEditSettingsFields } from "./UserAccountChangeEmailModal.types";

export const __FORM_EMAIL_EDIT_SETTINGS__: FormValidatorMap<EmailEditSettingsFields> =
  {
    confirm_password: {
      required: "registration_password_error"
    },
    email: {
      required: "email_required_validation_error",
      pattern: {
        value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
        message: "email_required_validation_error"
      }
    }
  };
