import { Box, Divider, DoodleLoader, Icon } from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { DotsMenu } from "src/js/components/DotsMenu";
import { Skeleton } from "src/js/components/Skeleton";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { inviteMembersAsyncStatus } from "src/js/repository/inviteRepository";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  GroupInviteUserAsync,
  GroupUserRoleEnum,
  SearchbarEvent
} from "src/js/types";
import { DOMAIN, EVENT } from "src/js/types/models/WebSocket";
import { useTheme } from "styled-components";
import GroupSettingsSearchBar from "./components/GroupSettingsSearchBar";
import GroupSettingsTablePagination from "./components/GroupSettingsTablePagination";
import * as S from "./GroupSettingsMembers.styles";
import { useGroupMembers } from "./useGroupMembers";

const GroupSettingsMembersInvitations = () => {
  const {
    GroupStore: { activeGroup: group },
    UIStore: { isLayoutModeMobile },
    WebSocketStore: { socket }
  } = useStores();
  const {
    invitations,
    totalInvitations,
    getInvitations,
    showResendInviteModal,
    isStudent,
    showCancelInviteModal,
    invitationNameOrEmailDebounced,
    invitationNameOrEmail,
    setInvitationNameOrEmail
  } = useGroupMembers();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { state }: { state: { pendingInvites: boolean } } = useLocation();

  const invitationsAllowed = useMemo(
    () => group?.id || isStudent,
    [group, isStudent]
  );

  const [invitationsPage, setInvitationsPage] = useState(0);
  const [invitationsLoading, setInvitationsLoading] = useState<boolean>(true);
  const [hasPendingInvites, setHasPendingInvites] = useState<boolean | null>(
    null
  );

  const hasInvitations = useMemo(
    () => invitations && invitations.length,
    [invitations]
  );

  useSocket({
    socket,
    domain: DOMAIN.GROUP_INVITE_USER,
    event: EVENT.GroupInviteUserAsync,
    handler: ({ data }: GroupInviteUserAsync) => {
      if (data?.results) {
        getInvitations(0).then(() => {
          setHasPendingInvites(false);
        });
      }
    }
  });

  useEffect(() => {
    if (state?.pendingInvites) {
      setInvitationsLoading(false);
      setHasPendingInvites(true);
    }
  }, [state]);

  useEffect(() => {
    if (!invitationsAllowed) return;
    if (hasPendingInvites === null) {
      setInvitationsLoading(true);
      inviteMembersAsyncStatus().then(data => {
        const hasPendingInvitations = !!data?.id;
        setHasPendingInvites(hasPendingInvitations);
        if (hasPendingInvitations) {
          setInvitationsLoading(false);
        } else {
          getInvitations(0).then(() => {
            setInvitationsLoading(false);
          });
        }
      });
      return;
    }
    if (invitationNameOrEmailDebounced) {
      _trackEvent(
        EventDomain.Searchbar,
        SearchbarEvent.SearchPendingInviteInGroup
      );
    }
    setInvitationsLoading(true);
    getInvitations(0).then(() => {
      setInvitationsLoading(false);
    });
  }, [invitationsAllowed, invitationNameOrEmailDebounced]);

  if (!invitationsAllowed) {
    return null;
  }

  if (invitationsLoading) {
    return (
      <Box>
        <Divider theme={whiteTheme} color="grey" />
        <Box marginBottom={24} />
        <S.HeaderContainer>
          <S.StyledHeading level="5">
            {translate("group_settings_page_pending")}
          </S.StyledHeading>
          <Skeleton loading={hasPendingInvites === null}>
            <GroupSettingsSearchBar
              nameOrEmail={invitationNameOrEmail}
              setNameOrEmail={setInvitationNameOrEmail}
            />
          </Skeleton>
        </S.HeaderContainer>
        <Box marginBottom={24}>
          <DoodleLoader theme={whiteTheme} isMini />
        </Box>
      </Box>
    );
  }

  if (hasPendingInvites) {
    return (
      <Box>
        <Divider theme={whiteTheme} color="grey" />
        <Box marginBottom={24} />
        <S.HeaderContainer>
          <S.StyledHeading level="5">
            {translate("group_settings_page_pending")}
          </S.StyledHeading>
        </S.HeaderContainer>
        <S.StyledLoadingCard
          title="invites_loading_card_title"
          subtitle="invites_loading_card_subtitle"
        />
      </Box>
    );
  }

  return (
    <Box>
      <Divider theme={whiteTheme} color="grey" />
      <Box marginBottom={24} />
      <S.HeaderContainer>
        <S.StyledHeading level="5">
          {translate("group_settings_page_pending")}
        </S.StyledHeading>
        <GroupSettingsSearchBar
          nameOrEmail={invitationNameOrEmail}
          setNameOrEmail={setInvitationNameOrEmail}
        />
      </S.HeaderContainer>
      {hasInvitations ? (
        <S.TableWrapper>
          <Box overflowX={isLayoutModeMobile ? "scroll" : "unset"}>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadItem>
                    {translate("group_settings_page_account_label")}
                  </S.TableHeadItem>
                  <S.TableHeadItem>{translate("email")}</S.TableHeadItem>
                  <S.TableHeadItem>
                    {translate("group_settings_page_date_label")}
                  </S.TableHeadItem>
                  <S.TableHeadItem>
                    {translate("group_settings_page_status_label")}
                  </S.TableHeadItem>
                  <S.TableHeadItem />
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {invitations.map(invitation => (
                  <S.TableRow key={invitation.id}>
                    <S.TableBodyItem>
                      <S.RoleLabelWrapper>
                        <S.RoleLabel type="label">
                          {invitation.role === GroupUserRoleEnum.Teacher
                            ? translate("teacher")
                            : translate("student")}
                        </S.RoleLabel>
                      </S.RoleLabelWrapper>
                    </S.TableBodyItem>
                    <S.TableBodyItem>
                      <Box display="flex" alignItems="center" gap={4}>
                        {!invitation.emailSentAt ? (
                          <TranslatedTooltip tooltipString="group_members_invite_error_tooltip">
                            <Icon icon="dangerCircle" width={16} height={16} />
                          </TranslatedTooltip>
                        ) : null}
                        <S.TableText type="table">
                          {invitation.email}
                        </S.TableText>
                      </Box>
                    </S.TableBodyItem>
                    <S.TableBodyItem>
                      <S.TableText type="table">
                        {format(invitation.createdAt * 1000, "dd/MM/yyyy")}
                      </S.TableText>
                    </S.TableBodyItem>
                    <S.TableBodyItem>
                      <S.TableText type="table">
                        {translate("group_settings_page_confirming_email")}
                      </S.TableText>
                    </S.TableBodyItem>
                    <S.TableBodyItem width={32}>
                      <DotsMenu
                        id="settingMembersInviteDotsMenu"
                        customIcon="paperPlane"
                        items={[
                          {
                            id: "cancel-invite",
                            icon: "trash",
                            label: translate("cancel_invite"),
                            onClick: () =>
                              showCancelInviteModal(
                                invitation.id,
                                invitationsPage
                              )
                          },
                          {
                            id: "resend-invite",
                            icon: "paperPlane",
                            label: translate("resend_invite"),
                            onClick: () =>
                              showResendInviteModal(
                                invitation.id,
                                invitation.email,
                                invitation.role
                              )
                          }
                        ]}
                        alignRight
                      />
                    </S.TableBodyItem>
                  </S.TableRow>
                ))}
              </S.TableBody>
            </S.Table>
          </Box>
        </S.TableWrapper>
      ) : null}
      <GroupSettingsTablePagination
        totalRecords={totalInvitations}
        getRecords={getInvitations}
        setPage={setInvitationsPage}
      />
    </Box>
  );
};

export default observer(GroupSettingsMembersInvitations);
