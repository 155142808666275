import { Box } from "@ds_universal/Box";
import { ContextualAlert, ContextualError } from "@ds_universal/data_display";
import React from "react";
import { formatBytes } from "src/js/modules/commonFunction";
import { __USER_AVATAR_SIZE__ } from "src/js/settings/settingFileUploader";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

const UploadInfo = ({ errorMessage }: { errorMessage?: TranslationKeys }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <>
      {errorMessage && (
        <Box marginBottom="10px">
          <ContextualError
            theme={whiteTheme}
            text={translate({ text: errorMessage })}
          />
        </Box>
      )}
      <ContextualAlert
        theme={whiteTheme}
        text={`${translate({
          text: "upload_image_limit"
        })} ${formatBytes(__USER_AVATAR_SIZE__)}`}
      />
    </>
  );
};

export default UploadInfo;
