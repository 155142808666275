/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import { WS_ENDPOINT } from "../settings/settingsWebSocket";
import {
  WebSocketConnectionState,
  WebSocketConnectionStateType,
  WebSocketSendError
} from "../types/models/WebSocket";
import UserStore from "./UserStore";
import AuthStore from "./AuthStore";

const __MAX_RECONNECTION_ATTEMPTS__ = 5;
const __RECONNECTION_DELAY__ = 2000;

/**
 * @name WebSocketStore
 *
 * @description WebSocket connection and logics
 */
class WebSocketStore {
  private $socketUrl: string;
  private $socket: WebSocket = null;
  userStore: UserStore;
  authStore: AuthStore;

  $connectionState: WebSocketConnectionStateType =
    WebSocketConnectionState.INIT;
  private $reconnectionAttempts = 0;
  private $reconnectionDelayMs = 1;

  private $socketOpenListener: (event: Event) => void;
  private $socketCloseListener: (event: CloseEvent) => void;
  private $socketErrorListener: (event: Event) => void;
  private $visibilityChangeListener: (event: Event) => void;
  private $onlineListener: (event: Event) => void;
  private $offlineListener: (event: Event) => void;

  constructor({ userStore, authStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.$socketUrl = WS_ENDPOINT;
    this.userStore = userStore;
    this.authStore = authStore;
    this.reactOnActiveUserChange();
  }

  startConnection = () => {
    if (!this.authStore.authToken) throw new Error("Auth token is not set");
    const ws = new WebSocket(
      `${this.$socketUrl}?auth=${this.authStore.authToken?.value}`
    );
    this.setSocket(ws);
    this.setConnectionState(WebSocketConnectionState.CONNECTING);
    this.addSocketEventListeners();
  };

  closeConnection = () => {
    this.removeSocketEventListeners();
    if (this.socket) {
      this.socket.close();
      this.setSocket(null);
    }
  };

  initConnection = async () => {
    this.closeConnection();
    await this.authStore.refreshWebSocketToken();
    this.startConnection();
  };

  isWebSocketConnectedAndOnline = () => {
    return (
      this.socket?.readyState === WebSocket.OPEN && window.navigator.onLine
    );
  };

  attemptConnection = async () => {
    if (
      !this.isWebSocketConnectedAndOnline() &&
      this.connectionState !== WebSocketConnectionState.CONNECTING
    ) {
      try {
        await this.initConnection();
      } catch (error) {
        this.handleReconnection();
      }
    }
  };

  resetReconnection = () => {
    this.$reconnectionAttempts = 0;
    this.$reconnectionDelayMs = 1;
  };

  handleReconnection = async () => {
    this.$reconnectionAttempts += 1;
    this.$reconnectionDelayMs =
      this.$reconnectionAttempts * __RECONNECTION_DELAY__;

    if (this.$reconnectionAttempts >= __MAX_RECONNECTION_ATTEMPTS__) {
      console.error("Maximum reconnection attempts reached. Giving up.");
      this.resetReconnection();
      this.setConnectionState(WebSocketConnectionState.DISCONNECTED);
      return;
    }

    await new Promise(resolve => {
      setTimeout(resolve, this.$reconnectionDelayMs);
    });
    try {
      await this.initConnection();
    } catch (error) {
      this.handleReconnection();
    }
  };

  addSocketEventListeners = () => {
    this.$socketOpenListener = () => {
      this.resetReconnection();
      this.setConnectionState(WebSocketConnectionState.CONNECTED);
    };
    this.$socketCloseListener = () => {
      this.removeSocketEventListeners();
      this.setConnectionState(WebSocketConnectionState.DISCONNECTED);
      if (document?.visibilityState === "visible") {
        this.attemptConnection();
      }
    };
    this.$socketErrorListener = () => {
      this.removeSocketEventListeners();
      this.setConnectionState(WebSocketConnectionState.ERROR);
      if (document?.visibilityState === "visible") {
        this.handleReconnection();
      }
    };

    this.socket.addEventListener("open", this.$socketOpenListener);
    this.socket.addEventListener("close", this.$socketCloseListener);
    this.socket.addEventListener("error", this.$socketErrorListener);
  };

  removeSocketEventListeners = () => {
    if (this.socket) {
      this.socket.removeEventListener("open", this.$socketOpenListener);
      this.socket.removeEventListener("close", this.$socketCloseListener);
      this.socket.removeEventListener("error", this.$socketErrorListener);
    }
  };

  addDOMEventListeners = () => {
    this.removeDOMEventListeners();
    this.$visibilityChangeListener = () => {
      if (document?.visibilityState === "visible") {
        this.attemptConnection();
      }
    };
    document.addEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
    this.$onlineListener = this.attemptConnection;
    window.addEventListener("online", this.$onlineListener);
    this.$offlineListener = () => {
      this.closeConnection();
      this.setConnectionState(WebSocketConnectionState.DISCONNECTED);
    };
    window.addEventListener("offline", this.$offlineListener);
  };

  removeDOMEventListeners = () => {
    document.removeEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
    window.removeEventListener("online", this.$onlineListener);
    window.removeEventListener("offline", this.$offlineListener);
  };

  sendMessage = async message => {
    await this.attemptConnection();
    if (!this.isWebSocketConnectedAndOnline()) {
      throw WebSocketSendError.SocketNotReady;
    } else if (message === undefined) {
      throw WebSocketSendError.MessageNotValid;
    }
    this.socket.send(JSON.stringify(message));
  };

  setSocket = (socket: WebSocket) => {
    this.$socket = socket;
  };

  setConnectionState = (state: WebSocketConnectionStateType) => {
    this.$connectionState = state;
  };

  get socketUrl() {
    return this.$socketUrl;
  }

  get socket() {
    return this.$socket;
  }

  get connectionState() {
    return this.$connectionState;
  }

  reactOnActiveUserChange = () => {
    reaction(
      () => this.userStore?.activeUser,
      activeUser => {
        const userId = activeUser?.id;
        if (!userId) {
          this.closeConnection();
          this.removeDOMEventListeners();
          return;
        }
        try {
          this.attemptConnection();
          this.addDOMEventListeners();
        } catch (error) {
          console.log({ webSocketStoreConnectionError: error });
        }
      }
    );
  };
}

export default WebSocketStore;
