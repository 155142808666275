import { Box, Divider, Heading, Text, Toggle } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { EmailInvite } from "src/js/components/EmailInvite";
import { InfoTooltip } from "src/js/components/InfoTooltip";
import {
  useEmailInvite,
  useSpacePlanType,
  useStores,
  useToastMessage
} from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  demoNoInvitationWarningBanner,
  temporaryNoInvitationWarningBanner
} from "src/js/pages/group/settings/GroupSettingsMembers/translations";
import { PRO_PLAN_MEMBERS_LIMIT } from "src/js/settings/settingsInvite";
import { Trans, useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  EventDomain,
  GroupUserRoleEnum,
  SpaceUserRoleEnum,
  ToastMessage,
  ToastVariant
} from "src/js/types";
import { DefaultTheme } from "styled-components";
import * as S from "./SectionInviteEmail.styles";
import { useInviteTransComponents, useSendInviteMembers } from "./hooks";

const SectionInviteEmail = ({
  theme,
  initialInviteMemberRole = GroupUserRoleEnum.Student,
  initialGroupIds
}: {
  theme: DefaultTheme;
  initialInviteMemberRole?: GroupUserRoleEnum;
  initialGroupIds?: number[];
}) => {
  const { translate } = useTranslation();
  const {
    UserInviteStore: { usersToInvite },
    SpaceStore: { isDemoSpace, isClaimable, isAdmin, activeSpace },
    SpacesSettingsStore: {
      generalSettings: { planSettings }
    },
    UIStore: { isLayoutModeMobile },
    GroupStore: { groupId }
  } = useStores();

  const groupIds = initialGroupIds ?? [groupId];
  const {
    totalMemberInviteLimit,
    memberInviteLimitPerGroup,
    isPlanProOrEnterprise
  } = useSpacePlanType({
    groupCount: groupIds.length
  });

  const { transComponents } = useInviteTransComponents();
  const { handleInviteMembers, isLoadingInviteMembers } =
    useSendInviteMembers();

  const isInvitationAllowed = !isDemoSpace && !activeSpace?.isTemporary;
  const isInvitationEmailDisabled =
    !isInvitationAllowed || !usersToInvite.length || isLoadingInviteMembers;

  const [selectedMemberType, setSelectedMemberType] =
    useState<GroupUserRoleEnum>(initialInviteMemberRole);
  const [isPowerUser, setIsPowerUser] = useState(false);

  useEffect(() => {
    _trackEvent(
      EventDomain.AddMember,
      AddMemberEvent.EmailInviteSectionDisplayed
    );
  }, []);

  const {
    toastMessageNodes,
    addToastMessage,
    removeToastMessage,
    setToastMessages
  } = useToastMessage();

  const { onChange, onKeyDown, onPaste, setIsFocused, inputValue } =
    useEmailInvite({
      inviteLimit: memberInviteLimitPerGroup,
      handleLimitReached: () => {
        if (isPlanProOrEnterprise) {
          addToastMessage({
            id: "invite-limit-pro-reached",
            variant: ToastVariant.Confirmation,
            children: translate("invite_modal_email_invite_toast_max_reached", {
              membersLimit: totalMemberInviteLimit
            }),
            props: {
              onAcceptFunction: () => {
                removeToastMessage("invite-limit-pro-reached");
              },
              acceptText: translate("invite_modal_confirm_toast_accept")
            }
          });
          return;
        }
        if (isClaimable) {
          addToastMessage({
            id: "invite-limit-claimable-reached",
            variant: ToastVariant.Confirmation,
            children: (
              <Trans
                i18nKey="invite_modal_email_invite_toast_claim_max_reached"
                values={{
                  newMembersLimit: PRO_PLAN_MEMBERS_LIMIT
                }}
                components={transComponents}
              />
            ),
            props: {
              onAcceptFunction: () => {
                removeToastMessage("invite-limit-claimable-reached");
              },
              acceptText: translate("invite_modal_confirm_toast_accept")
            }
          });
          return;
        }
        addToastMessage({
          id: "invite-limit-lite-reached",
          variant: ToastVariant.Confirmation,
          children: (
            <Trans
              i18nKey="invite_modal_email_invite_toast_upgrade_limit"
              values={{
                membersLimit: PRO_PLAN_MEMBERS_LIMIT
              }}
              components={transComponents}
            />
          ),
          props: {
            onAcceptFunction: () => {
              removeToastMessage("invite-limit-lite-reached");
            },
            acceptText: translate("invite_modal_confirm_toast_accept")
          }
        });
      }
    });

  const getInitialMessages = (): ToastMessage[] => {
    if (isDemoSpace) {
      return [
        {
          id: "demo-space-warning",
          variant: ToastVariant.Warning,
          props: demoNoInvitationWarningBanner
        }
      ];
    }
    if (activeSpace?.isTemporary) {
      return [
        {
          id: "temporary-space-warning",
          variant: ToastVariant.Warning,
          props: temporaryNoInvitationWarningBanner
        }
      ];
    }
    if (activeSpace?.isInTrial) {
      return [
        {
          id: "admin-no-dashboard-groups",
          variant: ToastVariant.Alert,
          children: (
            <Trans
              i18nKey={
                isAdmin
                  ? "invite_modal_email_invite_toast_upgrade_limit_pro"
                  : "invite_modal_email_invite_toast_upgrade_limit_pro_no_admin"
              }
              values={{
                membersLimit: totalMemberInviteLimit,
                newMembersLimit: PRO_PLAN_MEMBERS_LIMIT
              }}
              components={transComponents}
            />
          )
        }
      ];
    }
    if (isClaimable) {
      return [
        {
          id: "claimable-space",
          variant: ToastVariant.Alert,
          children: (
            <Trans
              i18nKey="invite_modal_email_invite_toast_upgrade_limit_claim"
              values={{
                membersLimit: totalMemberInviteLimit,
                newMembersLimit: PRO_PLAN_MEMBERS_LIMIT
              }}
              components={transComponents}
            />
          )
        }
      ];
    }
    if (isPlanProOrEnterprise) {
      return [
        {
          id: "default-members-limit",
          variant: ToastVariant.Alert,
          children: translate("invite_modal_email_invite_toast_limit", {
            membersLimit: PRO_PLAN_MEMBERS_LIMIT
          })
        }
      ];
    }
    return [
      {
        id: "admin-no-dashboard-groups",
        variant: ToastVariant.Alert,
        children: (
          <Trans
            i18nKey={
              isAdmin
                ? "invite_modal_email_invite_toast_upgrade_limit_pro"
                : "invite_modal_email_invite_toast_upgrade_limit_pro_no_admin"
            }
            values={{
              membersLimit: totalMemberInviteLimit,
              newMembersLimit: PRO_PLAN_MEMBERS_LIMIT
            }}
            components={transComponents}
          />
        )
      }
    ];
  };

  useEffect(() => {
    setToastMessages(getInitialMessages());
  }, [planSettings]);

  const onConfirmInviteMembers = async () => {
    try {
      await handleInviteMembers({
        groupIds,
        inviteRole: selectedMemberType,
        userEmails: usersToInvite,
        inviteSpaceRole: isPowerUser ? SpaceUserRoleEnum.PowerUser : undefined,
        skipCatch: true
      });
    } catch (error) {
      addToastMessage({
        id: "invite-error",
        duration: 5000,
        variant: ToastVariant.Error,
        children: translate("invite_modal_email_invite_toast_error")
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        gap="8px"
        flexDirection={isLayoutModeMobile ? "column" : "row"}
        marginBottom="12px"
      >
        <Box display="flex" flexDirection="column" gap="4px" flex={1}>
          <Heading level="5">
            {translate("invite_modal_email_invite_title")}
          </Heading>
          <Text type="formSubtitle">
            {translate("invite_modal_email_invite_instructions")}
          </Text>
        </Box>
        <S.StyledDropdown
          id="invite-members-type-dropdown"
          theme={theme}
          optionsList={[
            {
              id: GroupUserRoleEnum.Student,
              label: translate("select_member_type_students")
            },
            {
              id: GroupUserRoleEnum.Teacher,
              label: translate("select_member_type_instructors")
            }
          ]}
          selectedOptionId={selectedMemberType}
          setSelectedOptionId={id =>
            setSelectedMemberType(id as GroupUserRoleEnum)
          }
          disabled={!isInvitationAllowed}
        />
      </Box>
      {isAdmin ? (
        <>
          <Box marginBottom={12}>
            <Divider theme={theme} color="grey" width="100%" />
          </Box>
          <Box
            display="flex"
            gap="20px"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="12px"
          >
            <Box display="flex" flexDirection="column" gap="4px">
              <Box display="flex" gap="4px">
                <Text type="formField">
                  {translate("invite_modal_email_poweruser_title")}
                </Text>
                <InfoTooltip
                  side="bottom"
                  customTooltipProps={{
                    title: translate(
                      "invite_modal_email_poweruser_tooltip_title"
                    ),
                    description: (
                      <p>
                        <Trans
                          i18nKey="invite_modal_email_poweruser_tooltip_description"
                          components={transComponents}
                        />
                      </p>
                    )
                  }}
                />
              </Box>
              <Text type="body">
                {translate("invite_modal_email_poweruser_instructions")}
              </Text>
            </Box>
            <div>
              <Toggle
                disabled={!isInvitationAllowed}
                theme={theme}
                title="toggle-is-poweruser"
                size="small"
                checked={isPowerUser}
                onChange={event => setIsPowerUser(event.target.checked)}
              />
            </div>
          </Box>
        </>
      ) : null}
      <Box display="flex" flexDirection="column" gap="8px">
        {toastMessageNodes()}
      </Box>
      <Box padding="16px 0px">
        <EmailInvite
          selectedMemberType={selectedMemberType}
          inputValue={inputValue}
          onChange={onChange}
          setIsFocused={setIsFocused}
          handleInviteMembers={onConfirmInviteMembers}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          disabled={!isInvitationAllowed}
        />
      </Box>
      <S.StyledFooter>
        <S.StyledConfirmButton
          theme={theme}
          variant="primary"
          onClick={onConfirmInviteMembers}
          disabled={isInvitationEmailDisabled}
        >
          {translate("invite_modal_email_invite_as_type", {
            type:
              selectedMemberType === GroupUserRoleEnum.Student
                ? translate("select_member_type_students")
                : translate("select_member_type_instructors")
          })}
        </S.StyledConfirmButton>
      </S.StyledFooter>
    </>
  );
};

export default observer(SectionInviteEmail);
