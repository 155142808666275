import { shadow } from "@ds_themes/index";
import styled, { css } from "styled-components";
import { Avatar } from "@ds_universal/Avatar";
import colors from "@ds_themes/colors";

import { SCardLessonProps } from "./types";

export const Cover = styled.div`
  height: 96px;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(black, black);
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 12px 12px 0 0;
  img {
    height: auto;
    width: 100%;
  }
`;

export const WrapperCounters = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 4px;
  color: ${colors.grey[500]};
`;

export const WrapperTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const WrapperText = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  box-sizing: border-box;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.card.cardlesson.background};
  color: ${({ theme }) => theme.card.cardlesson.text};
  height: 128px;
`;

export const Option = styled.button`
  position: absolute;
  top: 12px;
  right: 16px;
  border: none;
  z-index: ${({ theme }) => theme.zIndex.regular};
  width: 32px;
  height: 20px;
  background-color: ${({ theme }) => theme.card.cardlesson.background};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0px;
  cursor: pointer;
  &:focus-within {
    outline: ${({ theme }) => theme.card.cardlesson.focus.border};
  }
  svg {
    opacity: 1;
    color: ${({ theme }) => theme.card.cardlesson.text};
  }
`;

export const CreatorSection = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 4px;
`;

export const CreatorSectionText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export const CardLesson = styled.div<SCardLessonProps>`
  height: 224px;
  min-width: 0;
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  border: 0px;
  padding: 0px;
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    css`
      height: auto;
      opacity: 0.6;
    `}

  transition: box-shadow 0.3s ease-in-out;
  img {
    transition: transform 0.3s ease-in-out;
    transform: scale3d(1, 1, 1);
    backface-visibility: hidden;
  }
  &:focus {
    outline: ${({ theme }) => theme.card.cardlesson.focus.border};
  }
  &:hover {
    box-shadow: ${shadow[500]};

    img {
      transform: scale3d(1.03, 1.03, 1);
    }
  }
`;

export const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.card.cardlesson.background};
  border-radius: 20px;
  z-index: 1;
  width: 100%;
  margin-top: 8px;
`;

export const ProgressBar = styled.div<{ percentage: number; color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 4px;
  height: 4px;
  width: ${({ percentage }) => `${percentage}%`};
  max-width: 100%;
`;

export const CompletedProgressContainer = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
`;

export const CompletedProgressIconContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.card.cardlesson.cart.icon};
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 10px;
  margin-right: 8px;
`;

export const ColoredContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.card.cardlesson.cart.background};
  width: 100%;
  margin: 8px 0;
`;

export const EllipsedText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
`;

export const NextIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.card.cardlesson.cart.background};
  color: ${({ theme }) => theme.card.cardlesson.cart.icon};
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 10px;
`;

export const NoModulesIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  color: ${({ theme }) => theme.card.cardlesson.cart.background};
`;
