import { useQuery, UseQueryOptions } from "src/js/query/hooks";
import { cityQueryKey } from "src/js/query/location/city.query";
import { CityDTO, fetchCities } from "src/js/repository/locationRepository";

export const useCities = ({
  options,
  countryId = null
}: {
  options?: UseQueryOptions<CityDTO[], Error, CityDTO[]>;
  countryId?: string;
}) => {
  const { data: cities } = useQuery<CityDTO[]>(
    cityQueryKey.countryId({ countryId }),
    () => fetchCities({ countryId }),
    options
  );

  return {
    cities
  };
};
