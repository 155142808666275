import React from "react";
import { __NO_RESULTS__ } from "src/js/settings/settingsImage";
import { Heading } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import * as S from "./EmptyModule.styles";

const EmptyModule = () => {
  const { translate } = useTranslation();
  return (
    <S.EmptyModuleWrapper>
      <img width={100} src={__NO_RESULTS__} alt="empty module" />
      <S.EmptyModuleWrapperMessage>
        <Heading level="5">{translate("empty_module_view_title")}</Heading>
        <S.StyledText type="formDescription">
          {translate("empty_module_view_description")}
        </S.StyledText>
      </S.EmptyModuleWrapperMessage>
    </S.EmptyModuleWrapper>
  );
};

export default EmptyModule;
