/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import { updateSpaceSlug } from "src/js/modules/routing";
import { fetchSpacesList } from "src/js/repository/spaceListRepository";
import {
  fetchSpaceDetails,
  fetchSpaceUserStats
} from "src/js/repository/spacesRepository";
import {
  ImageModelType,
  SpaceDetailsModel,
  SpaceListModel,
  SpaceModel,
  SpacePurpose,
  SpaceType,
  SpaceUserRoleEnum
} from "src/js/types";
import { updateSpaceSlugLegacy } from "src/legacy/modules/utility";
import { userIsUnderage } from "../UserFunction";
import {
  createLocalData,
  destroyLocalData,
  readLocalData
} from "../modules/storageFunction";
import { SpaceUserStats } from "../repository/types";
import UserStore from "./UserStore";
import { AssessmentsGradingMethod } from "../types/models/Customization";

export const getLastActiveSpaceLocalStoragekey = ({
  userUuid
}: {
  userUuid: string;
}) => {
  return `lastActiveSpace-${userUuid}`;
};

/**
 * @name SpaceStore
 *
 * @description
 * Sets user list of spaces (if she/he has any)
 */
class SpaceStore {
  $spacesList: SpaceListModel[] = [];
  $start = 0;
  $limit = 30;
  $hasMoreSpace = false;
  private $userSpacesListLoading = false;
  private $activeSpaceSlug: string | null = null;
  $activeSpace: SpaceDetailsModel | null = null;
  $spaceUserStats: SpaceUserStats | null = null;
  userStore: UserStore;

  constructor({ userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.userStore = userStore;
    this.dispose();
    this.reactToActiveSpaceSlug();
    this.reactToActiveSpaceId();
  }

  fetchUserSpacesList = activeUser => {
    if (!activeUser) {
      this.setSpacesList([]);
      return null;
    }

    this.$userSpacesListLoading = true;

    return fetchSpacesList({
      limit: this.$limit,
      offset: this.$start
    })
      .then(({ results, hasNext }) => {
        this.setSpacesList([...this.$spacesList, ...results]);
        this.setHasMoreSpaces(hasNext);
      })
      .catch(() => {
        this.setSpacesList([]);
      })
      .finally(() => {
        this.$userSpacesListLoading = false;
      });
  };

  setActiveSpace = (space: SpaceDetailsModel | null) => {
    if (space !== null) {
      this.setLastActiveSpace(space);
    }
    this.$activeSpace = space;
  };

  setLastActiveSpace = (space: SpaceDetailsModel | null) => {
    const user = this.userStore.activeUser;
    createLocalData(
      getLastActiveSpaceLocalStoragekey({ userUuid: user.uuid }),
      JSON.stringify(space)
    );
  };

  getLastActiveSpace(): SpaceDetailsModel | null {
    const user = this.userStore.activeUser;
    const lastActiveSpaceString = readLocalData(
      getLastActiveSpaceLocalStoragekey({ userUuid: user.uuid })
    );

    return lastActiveSpaceString !== null
      ? JSON.parse(lastActiveSpaceString)
      : null;
  }

  cleanLastActiveSpaceLocalStorage = () => {
    const user = this.userStore.activeUser;
    destroyLocalData(
      getLastActiveSpaceLocalStoragekey({ userUuid: user.uuid })
    );
    this.setActiveSpace(null);
  };

  setSpaceUserStats = (spaceUserStats: SpaceUserStats | null) => {
    this.$spaceUserStats = spaceUserStats;
  };

  setActiveSpaceById = async (idOrSlug: string) => {
    if (!idOrSlug) {
      this.setActiveSpace(null);
      return Promise.resolve();
    }
    if (this.$activeSpace?.space) {
      this.$activeSpace.space.spaceType = SpaceType.STANDARD;
    }
    try {
      const spaceDetails = await fetchSpaceDetails(idOrSlug);
      this.setActiveSpace(spaceDetails as SpaceDetailsModel);
      updateSpaceSlug(spaceDetails.space.slug);
      updateSpaceSlugLegacy(spaceDetails.space.slug);
      this.setActiveSpaceBySlug(spaceDetails.space.slug);
      return Promise.resolve();
    } catch {
      this.setActiveSpace(null);
      return Promise.resolve();
    }
  };

  fetchSpacesNextPage = () => {
    this.setStart(this.$start + this.$limit);
    this.fetchUserSpacesList(this.userStore.activeUser);
  };

  setSpacesList = (value: SpaceListModel[]) => {
    // Using a map to ensure that the list of spaces is unique
    const seen = new Map();
    this.$spacesList = value.filter(
      item => !seen.has(item.space.id) && seen.set(item.space.id, true)
    );
  };

  addNewSpaceToSpaceList = (newSpace: SpaceListModel) => {
    this.setSpacesList([newSpace, ...this.$spacesList]);
  };

  removeSpaceFromSpaceList = (leavedSpaceId: string) => {
    const spaceFiltered = this.$spacesList.filter(
      x => x.space.id !== leavedSpaceId
    );

    this.setSpacesList(spaceFiltered);
  };

  setActiveSpaceBySlug = (slug: string) => {
    this.$activeSpaceSlug = slug;
  };

  setStart = (start: number) => {
    this.$start = start;
  };

  setHasMoreSpaces = (hasMore: boolean) => {
    this.$hasMoreSpace = hasMore;
  };

  resetActiveSpace = () => {
    this.$activeSpace = null;
    this.$activeSpaceSlug = null;
  };

  setActiveSpaceName = (name: string) => {
    if (this.$activeSpace?.space) {
      this.$activeSpace.space.name = name;
    }
  };

  setActiveSpaceDescription = (description: string) => {
    if (this.$activeSpace?.space) {
      this.$activeSpace.space.description = description;
    }
  };

  updateSpaceList = (space: SpaceModel) => {
    const spaceFound = this.$spacesList.find(x => x.space?.id === space?.id);
    if (this.$activeSpace) {
      spaceFound.space = space;
      this.$activeSpace.space = space;
    }
  };

  setActiveSpaceBadge = badge => {
    if (this.$activeSpace?.space) {
      this.$activeSpace.space.badge = badge;
    }
  };

  setActiveSpaceTrialStatus = ({ isInTrial, trialDaysLeft }) => {
    if (this.$activeSpace) {
      this.$activeSpace.isInTrial = isInTrial;
      this.$activeSpace.trialDaysLeft = trialDaysLeft;
    }
  };

  private adjustCounter = ({
    spaceId,
    increment
  }: {
    spaceId: string;
    increment: boolean;
  }) => {
    const spaceToUpdate = this.$spacesList.find(
      ({ space }) => space.id === spaceId
    );
    if (!spaceToUpdate) return;
    if (increment) {
      spaceToUpdate.unreadNotificationCount += 1;
    } else if (spaceToUpdate.unreadNotificationCount > 0) {
      spaceToUpdate.unreadNotificationCount -= 1;
    }
  };

  increaseNotificationCounterOnSpace = ({ spaceId }: { spaceId: string }) => {
    this.adjustCounter({ spaceId, increment: true });
  };

  decreaseNotificationCounterOnSpace = ({ spaceId }: { spaceId: string }) => {
    this.adjustCounter({ spaceId, increment: false });
  };

  setNotificationCounterOnSpace = ({
    spaceId,
    count
  }: {
    spaceId: string;
    count: number;
  }) => {
    const spaceToUpdate = this.$spacesList.find(
      ({ space }) => space.id === spaceId
    );
    if (!spaceToUpdate) return;
    spaceToUpdate.unreadNotificationCount = count >= 0 ? count : 0;
  };

  increaseThreadMessagesCounterOnSpace = ({ spaceId }: { spaceId: string }) => {
    const spaceToUpdate = this.$spacesList.find(
      ({ space }) => space.id === spaceId
    );
    if (!spaceToUpdate) return;
    spaceToUpdate.unreadThreadMessagesCount += 1;
  };

  decreaseThreadMessagesCounterOnSpace = ({ spaceId }: { spaceId: string }) => {
    const spaceToUpdate = this.$spacesList.find(
      ({ space }) => space.id === spaceId
    );
    if (!spaceToUpdate || spaceToUpdate.unreadThreadMessagesCount <= 0) return;
    spaceToUpdate.unreadThreadMessagesCount -= 1;
  };

  getBadge(size?: ImageModelType): string | undefined {
    if (this.activeSpace?.space) {
      const { badge } = this.activeSpace.space;

      if (typeof badge === "object") {
        return badge?.[size || "medium"];
      }

      return badge;
    }

    return undefined;
  }

  setActiveSpaceCustomization = customizationSettings => {
    if (this.activeSpace) {
      this.$activeSpace.customizationSettings = customizationSettings;
    }
  };

  get spacesList() {
    return this.$spacesList;
  }

  get isDemoSpaceEnabled() {
    return this.isOwnerOrAdminInSomeSpace || this.$spacesList.length === 0;
  }

  get spacesListWithNoDemo(): SpaceListModel[] {
    return this.$spacesList.filter(
      space => space.space.spaceType !== SpaceType.DEMO
    );
  }

  get activeSpace(): SpaceDetailsModel {
    return this.$activeSpace;
  }

  get spaceUserStats(): SpaceUserStats | null {
    return this.$spaceUserStats;
  }

  get activeSpaceName() {
    return this.$activeSpace?.space?.name;
  }

  get activeSpaceDescription() {
    return this.$activeSpace?.space?.description;
  }

  get activeSpaceSlug() {
    return this.$activeSpaceSlug;
  }

  get activeSpaceId() {
    return this.$activeSpace?.space?.id;
  }

  get activeSpaceSettings() {
    return this.$activeSpace?.spaceSettings;
  }

  get isAdmin() {
    return (
      this.$activeSpace?.role === SpaceUserRoleEnum.Owner ||
      this.$activeSpace?.role === SpaceUserRoleEnum.Admin
    );
  }

  get isClaimable() {
    return (
      !userIsUnderage(this.userStore.activeUser) &&
      this.$activeSpace?.space.isOpen &&
      this.$activeSpace?.space.purpose === SpacePurpose.School
    );
  }

  get isClaimed() {
    return (
      !this.$activeSpace?.space.isOpen &&
      this.$activeSpace?.space.purpose === SpacePurpose.School
    );
  }

  get canCreateChats() {
    return !(
      this.isClaimable ||
      this.$activeSpace?.isTemporary ||
      this.isDemoSpace
    );
  }

  get canCreateGroups() {
    return this.$activeSpace?.space.isOpen
      ? true
      : this.$activeSpace?.role === SpaceUserRoleEnum.Owner ||
          this.$activeSpace?.role === SpaceUserRoleEnum.Admin ||
          this.$activeSpace?.role === SpaceUserRoleEnum.PowerUser;
  }

  get canCreateGroupsFromHome() {
    let isAdmin = false;

    this.$spacesList?.forEach(space => {
      if (
        space.role === SpaceUserRoleEnum.Owner ||
        space.role === SpaceUserRoleEnum.Admin ||
        space.role === SpaceUserRoleEnum.PowerUser
      ) {
        isAdmin = true;
      }
    });

    return isAdmin;
  }

  get demoSpace() {
    return this.$spacesList.filter(
      spaceDetails => spaceDetails.space.spaceType === SpaceType.DEMO
    )[0];
  }

  get hasMoreSpaces() {
    return this.$hasMoreSpace;
  }

  get hasDemoSpace() {
    return this.$spacesList.some((result: SpaceListModel) => {
      return result.space.spaceType === SpaceType.DEMO;
    });
  }

  get isDemoSpace() {
    return this.$activeSpace?.space.spaceType === SpaceType.DEMO;
  }

  get userSpacesListLoading() {
    return this.$userSpacesListLoading;
  }

  get isOwnerOrAdminInSomeSpace() {
    return this.$spacesList.some(space =>
      [SpaceUserRoleEnum.Admin, SpaceUserRoleEnum.Owner].includes(space.role)
    );
  }

  get activeSpaceCustomization() {
    return this.$activeSpace?.customizationSettings;
  }

  get hasDecimalGrading() {
    return (
      this.$activeSpace?.customizationSettings?.assessmentsGradingMethod !==
      AssessmentsGradingMethod.Percentage
    );
  }

  dispose = () => {
    reaction(
      // reacts on change of user id
      () => this.userStore.activeUser?.id,
      activeUser => {
        this.fetchUserSpacesList(activeUser);
      }
    );
  };

  reactToActiveSpaceSlug = () => {
    reaction(
      () => this.$activeSpaceSlug,
      () => {
        this.setActiveSpaceById(this.$activeSpaceSlug);
      }
    );
  };

  reactToActiveSpaceId = () => {
    reaction(
      () => this.$activeSpace,
      async () => {
        const spaceId = this.$activeSpace?.space?.id;
        if (!spaceId) return;
        try {
          const spaceUserStats = await fetchSpaceUserStats({ spaceId });
          this.setSpaceUserStats(spaceUserStats);
        } catch {
          this.setSpaceUserStats(null);
        }
      }
    );
  };

  reset = () => {
    this.$spacesList = [];
    this.$start = 0;
    this.$limit = 30;
    this.$hasMoreSpace = false;
    this.$activeSpace = null;
    this.$spaceUserStats = null;
    this.$activeSpaceSlug = null;
  };
}

export default SpaceStore;
