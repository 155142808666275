import { fetchSpaceMembers } from "src/js/repository/dashboardRepository";
import { __SPACE_STAFF_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useQuery } from "../hooks";

// Query keys

const __STAFF_KEY_ALL__ = "staff";

const members = ({
  spaceId,
  offset = 0,
  limit = __SPACE_STAFF_LIST_LIMIT__,
  name = ""
}: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => [__STAFF_KEY_ALL__, "members", spaceId, offset, limit, name];

export const staffQueryKey = {
  members
} as const;

// Hooks

export const useStaffMembers = (params: {
  spaceId: string;
  offset: number;
  limit: number;
  name?: string;
}) => {
  return useQuery(
    staffQueryKey.members(params),
    () => fetchSpaceMembers(params),
    { refetchOnWindowFocus: false }
  );
};
