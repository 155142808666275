import { useContext, useEffect, useState } from "react";
import { captureException } from "@sentry/browser";
import { InternalRealtimeDispatcher } from "./observer";
import { RealtimeContext } from "./RealTimeProvider";

const bindTo = (client, channel, eventName, callback) => {
  const topic = `${channel}/${eventName}`;

  // console.log("MQTT subscribe", topic);
  client.subscribe(topic, {}, (error, granted) => {
    if (error) {
      // console.log("MQTT", error, granted);
      captureException(error, { extra: granted });
    }
  });

  InternalRealtimeDispatcher.on(topic, callback);

  return {
    unsubscribe: () => {
      // console.log("MQTT unsubscribe", topic);

      client.unsubscribe(topic);
      InternalRealtimeDispatcher.off(topic, callback);
    },
    unbind: () => {
      // console.log("MQTT unsubscribe/unbind", topic);

      client.unsubscribe(topic);
      InternalRealtimeDispatcher.off(topic, callback);
    }
  };
};

const useChannel = (channelName, { version = "v1" } = {}) => {
  const { client, topicPrefix } = useContext(RealtimeContext);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (channelName && client) {
      setChannel({
        subscribe: (eventName, callback) =>
          bindTo(
            client,
            `${topicPrefix}/${version}/${channelName}`,
            eventName,
            callback
          )
      });
    }

    return () => {
      setChannel(null);
    };
  }, [channelName, client]);

  return channel;
};

export const useGroupChannel = activeGroup => {
  const channelName = activeGroup ? `group/${activeGroup.id}` : null;

  const channel = useChannel(channelName);
  if (!activeGroup) return null;

  return channel;
};

export const useUserChannel = user => {
  const channelName = user && user.id ? `user/${user.id}` : null;

  const channel = useChannel(channelName);
  if (!user) return null;

  return channel;
};
