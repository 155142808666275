import getUserClient from "../modules/connection";
import {
  BasePaginationParams,
  InviteMemberPending,
  InviteMembersAsyncStatus,
  InviteMembersBulkParams,
  InviteMembersBulkResponse,
  StandardPaginationResponse
} from "../types";
import {
  api_groups_user_invites_async,
  api_groups_user_invites_bulk,
  api_groups_user_invites_paginated,
  getVersionedApiUrl
} from "./apiUrls";

export async function inviteMembersBulkToGroups(body: InviteMembersBulkParams) {
  try {
    const { data } = await getUserClient().post<InviteMembersBulkResponse>(
      getVersionedApiUrl(api_groups_user_invites_bulk(), "v3"),
      body
    );
    return data;
  } catch (error) {
    const Err = error.response.data;
    throw Err;
  }
}

export async function fetchGroupPendingInvites({
  groupId,
  offset,
  limit,
  userNameOrEmail
}: BasePaginationParams & {
  userNameOrEmail?: string;
  groupId: number;
}) {
  const params = Object.fromEntries(
    Object.entries({
      limit,
      offset,
      userNameOrEmail
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, v]) => v !== "")
  );
  try {
    const { data } = await getUserClient().get<
      StandardPaginationResponse<InviteMemberPending>
    >(
      getVersionedApiUrl(api_groups_user_invites_paginated({ groupId }), "v3"),
      {
        params
      }
    );
    return data;
  } catch (error) {
    const Err = error.response.data;
    throw Err;
  }
}

export async function inviteMembersAsyncStatus() {
  try {
    const { data } = await getUserClient().get<InviteMembersAsyncStatus>(
      getVersionedApiUrl(api_groups_user_invites_async(), "v3")
    );
    return data;
  } catch (error) {
    const Err = error.response.data;
    throw Err;
  }
}
