import { SingleSpaceModel } from "src/js/types";
import { fetchSpaceDetails } from "src/js/repository/spacesRepository";
import { UseQueryOptions, useQuery } from "../hooks";
import { spacesQueryKey } from "./spaces.query";

export const useSpaceById = (
  spaceId: string,
  options?: UseQueryOptions<SingleSpaceModel, Error, SingleSpaceModel>
) => {
  const { data: space, ...otherData } = useQuery(
    spacesQueryKey.id({ spaceId }),
    () => fetchSpaceDetails(spaceId),
    options
  );

  return {
    data: space,
    ...otherData
  };
};

export const useSpaceByIdCached = (
  spaceId: string,
  options?: UseQueryOptions<SingleSpaceModel, Error, SingleSpaceModel>
) => {
  return useSpaceById(spaceId, {
    ...options,
    staleTime: Infinity
  });
};
