import { Text } from "@arcadia/design-system";
import React from "react";
import { __SUPPORT_MAIL__ } from "src/js/settings/settingsGeneral";
import { Trans } from "src/js/translation";
import styled from "styled-components";

const StyledLink = styled.div`
  text-align: center;
  max-width: 430px;
  a {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

const UnloggedSupportLink = () => {
  return (
    <Text type="formSubtitle">
      <StyledLink>
        <Trans
          i18nKey="support_label_new"
          values={{
            support_mail: __SUPPORT_MAIL__
          }}
        />
      </StyledLink>
    </Text>
  );
};

export default UnloggedSupportLink;
