import { useState } from "react";
import { useMutation } from "src/js/hooks";
import { handleDownload } from "src/js/modules/commonFunction";
import { generatePdfCertificate } from "src/js/repository/badgesRepository";

const useDownloadBadgeCertificate = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const { mutate: generatePdf } = useMutation(
    async (vars: { badgeId: string; userId: string }) => {
      setIsDownloading(true);
      const { publicFilename, url } = await generatePdfCertificate(
        vars.badgeId,
        vars.userId
      );
      setIsDownloading(false);
      handleDownload({ fileName: publicFilename, url });
    }
  );

  return { generatePdf, isDownloading };
};

export default useDownloadBadgeCertificate;
