import { Box, Button, Input } from "@arcadia/design-system";
import { format, subYears } from "date-fns";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { userIsUnderage } from "src/js/UserFunction";
import { Calendar } from "src/js/components/Calendar";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { trimValue } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __FORM_REGISTRATION__ } from "src/js/settings/settingsFormValidation";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SocialLoginOptions } from "src/js/components/SocialLoginOptions";
import { useTheme } from "styled-components";
import { InputPasswordStrengthValidation } from "src/js/components/forms/InputPasswordStrengthValidation";
import { Terms } from "../Terms";
import { FormTypes, RegistrationFormData } from "../types";

import * as S from "../Registration.styles";

const setupData = (
  data: RegistrationFormData,
  token: string,
  birthday: string,
  jwt: string,
  terms: boolean
) => {
  const dataResult = data;
  dataResult.language = getCurrentLanguage();
  dataResult.birthday = birthday;
  dataResult.terms = terms || false;
  dataResult.weschoolContact = data.weschool_contact || false;
  dataResult.thirdPartContact = data.third_part_contact || false;
  dataResult.inviteToken = token;
  dataResult.externalRegistrationToken = jwt || null;
  if (dataResult.terms4) {
    dataResult.customTerms = true;
  }
  return dataResult;
};

const subtractYears = (years: number) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - years));
};

const NormalUser: FC<FormTypes> = ({
  formData,
  handleRegister,
  contract,
  registerInSchool,
  inviteGroupId
}) => {
  const { register, handleSubmit, errors, watch, control, setValue } =
    useForm<RegistrationFormData>({ defaultValues: formData });
  const { translate } = useTranslation();
  const {
    isOpen: isCalendarOpen,
    ref: calendarRef,
    setIsOpen: setCalendarOpen
  } = useCloseOnClickOutside(false);
  const [date, setDate] = useState(subtractYears(20));
  const watchTerms = contract ? watch("terms4") : watch("terms");
  const token = formData.invite_token || "";
  const dateWatched = watch("birthday");
  const watchUnderage =
    dateWatched && userIsUnderage({ birthday: dateWatched });
  const onSubmit = (data: RegistrationFormData) => {
    handleRegister(
      setupData(data, token, dateWatched, formData.jwt, watchTerms)
    );
  };

  const {
    ThemeStore: { customUnloggedPrimaryButtonTheme },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const { whiteTheme } = useTheme();

  let passwordField = (
    <InputPasswordStrengthValidation
      name="password"
      placeholder="password"
      register={register}
      validation={__FORM_REGISTRATION__.password}
      hasError={!!errors.password}
      errorMessage={translate(errors.password?.message as TranslationKeys)}
      rounded
    />
  );

  const handleChangeDate = (dateChanged: Date) => {
    setValue("birthday", format(new Date(dateChanged), "dd-MM-yyyy"));
    setDate(dateChanged);
    setCalendarOpen(false);
  };

  if (formData.jwt) {
    passwordField = null;
  }

  const yearsToShow = Array.from(
    { length: 100 },
    (_, i) => new Date(subYears(new Date(), 18)).getFullYear() - i
  );

  const isColumnLayoutForm = !isLayoutModeMobile || inviteGroupId;

  return (
    <div>
      <form
        id="form-registration-user"
        className="form-registration"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection={isColumnLayoutForm ? null : "column"}
          gap={isColumnLayoutForm ? 12 : 8}
          marginBottom={16}
        >
          <Input
            name="name"
            placeholder={translate("nome")}
            ref={register(__FORM_REGISTRATION__.name)}
            feedback={
              errors.name
                ? {
                    message: translate(errors.name.message as TranslationKeys),
                    type: "error"
                  }
                : null
            }
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
          />
          <Input
            name="surname"
            placeholder={translate("cognome")}
            ref={register(__FORM_REGISTRATION__.surname)}
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
            feedback={
              errors.surname
                ? {
                    message: translate(
                      errors.surname.message as TranslationKeys
                    ),
                    type: "error"
                  }
                : null
            }
          />
        </Box>
        <Input
          name="email"
          disabled={formData.emailDisabled}
          placeholder={translate("registration_email_label")}
          ref={register(__FORM_REGISTRATION__.email)}
          theme={whiteTheme}
          label=""
          hideLabel
          rounded
          feedback={
            errors.email
              ? {
                  message: translate(errors.email.message as TranslationKeys),
                  type: "error"
                }
              : null
          }
          onChange={trimValue}
        />
        <Box marginTop={16} marginBottom={16}>
          <Controller
            as={
              <S.CalendarInputWrapper ref={calendarRef}>
                <Input
                  label="birthday"
                  hideLabel
                  name="birthday"
                  autoComplete="off"
                  theme={whiteTheme}
                  rounded
                  readOnly
                  onKeyPress={e => {
                    e.preventDefault();
                  }}
                  error={{
                    message: translate({
                      text: errors.birthday?.message as TranslationKeys
                    })
                  }}
                  placeholder={translate({
                    text: "Data di nascita"
                  })}
                  onClick={() => setCalendarOpen(val => !val)}
                  ref={register(__FORM_REGISTRATION__.birthday)}
                />
                <Box zIndex={1} position="absolute" left={0} top="40px">
                  <Calendar
                    visible={isCalendarOpen}
                    selectedDate={date}
                    onChange={handleChangeDate}
                    years={registerInSchool ? yearsToShow : undefined}
                    maxDate={registerInSchool ? subtractYears(18) : new Date()}
                    minDate={registerInSchool ? subtractYears(117) : undefined}
                  />
                </Box>
              </S.CalendarInputWrapper>
            }
            control={control}
            rules={__FORM_REGISTRATION__.birthday}
            name="birthday"
            defaultValue={formData.birthday}
          />
        </Box>
        {passwordField}
        <Terms
          errors={errors}
          watchTerms={watchTerms}
          watchUnderage={watchUnderage}
          register={register}
          contract={contract}
        />
        <div className="margin-top-10">
          <Button
            type="submit"
            variant="primary"
            theme={customUnloggedPrimaryButtonTheme || whiteTheme}
            fullWidth
          >
            {translate(
              token
                ? "register_form_main_title"
                : "registration_submit_button_label"
            )}
          </Button>
        </div>
        <SocialLoginOptions inviteToken={token} />
      </form>
    </div>
  );
};

export default observer(NormalUser);
