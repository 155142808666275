import React from "react";
import { Button, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { openExternalLink } from "src/js/modules/commonFunction";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { trackLiveConference } from "src/js/modules/analyticsFunction";
import { TimerView } from "src/js/components/TimerView";
import { EventStatus } from "src/js/pages/calendar/Calendar.types";
import { hasLiveAccess } from "src/js/pages/calendar/CalendarUtils";
import { useStores } from "src/js/hooks";
import { Duration } from "date-fns";
import { useMeQueryCached } from "src/js/query";
import * as S from "./CalendarEventDetail.styles";
import { CalendarEventDetailLiveBannerParams } from "./CalendarEventDetail.types";

const LiveButton = ({
  event_id,
  liveUrl
}: {
  event_id: number;
  liveUrl: string;
}) => {
  const {
    GroupStore: { groupId }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { data: activeUser } = useMeQueryCached();
  return (
    <Button
      onClick={() => {
        trackLiveConference({
          userId: activeUser?.id,
          groupId,
          liveRoomId: event_id
        });
        openExternalLink(liveUrl);
      }}
      variant="secondary"
      theme={whiteTheme}
      fullWidth
    >
      <Text type="formDescription">
        {translate("event_detail_live_ongoing_action")}
      </Text>
    </Button>
  );
};

const LiveTimer = ({ timeFromStart }: { timeFromStart: Duration }) => {
  const { translate } = useTranslation();
  return (
    <S.TimerContainer>
      {translate("event_live_started")} <TimerView time={timeFromStart} />
    </S.TimerContainer>
  );
};

const TimerViewComponent = ({
  timeToStart,
  isCreator,
  liveAccess
}: {
  timeToStart: Duration;
  isCreator: boolean;
  liveAccess: boolean;
}) => {
  const { translate } = useTranslation();
  if (liveAccess) {
    return (
      <S.TimerContainer>
        {`${translate("event_live_not_started_user_view")} `}
        <TimerView time={timeToStart} />
      </S.TimerContainer>
    );
  }
  return isCreator ? (
    <S.TimerContainer>
      {translate("event_live_not_started_creator_view")}
    </S.TimerContainer>
  ) : (
    <S.LockedEvent>
      <S.LockedEventIcon>
        <Icon icon="locked" height="16px" width="16px" />
      </S.LockedEventIcon>
      {translate("event_detail_live_not_started_label")}
    </S.LockedEvent>
  );
};

const CalendarEventDetailLiveBanner = ({
  status = EventStatus.Default,
  accordionMode,
  event_id,
  liveUrl,
  dateNow,
  endDate,
  startDate,
  userIsCreator,
  timeFromStart,
  timeToStart
}: CalendarEventDetailLiveBannerParams) => {
  const liveAccess = hasLiveAccess({
    dateNow,
    startDate,
    endDate
  });

  if (status === EventStatus.Finished) return null;

  return (
    <S.EventLive accordionMode={accordionMode}>
      {status === EventStatus.Default ? (
        <TimerViewComponent
          timeToStart={timeToStart}
          isCreator={userIsCreator}
          liveAccess={liveAccess}
        />
      ) : null}
      {status === EventStatus.Ongoing ? (
        <LiveTimer timeFromStart={timeFromStart} />
      ) : null}
      {userIsCreator || liveAccess ? (
        <LiveButton event_id={event_id} liveUrl={liveUrl} />
      ) : null}
    </S.EventLive>
  );
};

export default CalendarEventDetailLiveBanner;
