import React from "react";
import RealtimeGroupSubscribe from "./RealtimeGroupSubscribe";
import RealtimeUserDemoSpaceSubscribe from "./RealtimeUserDemoSpaceSubscribe";
import RealtimeUserSubscribe from "./RealtimeUserSubscribe";

const RealtimeSubscribeList = () => {
  return (
    <>
      <RealtimeGroupSubscribe />
      <RealtimeUserSubscribe />
      <RealtimeUserDemoSpaceSubscribe />
    </>
  );
};

export default RealtimeSubscribeList;
