import React, { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { Button, Dropdown } from "@ds_universal/data_entry";
import { useTheme } from "styled-components";
import {
  EducationLevelsDTO,
  fetchEducationLevel,
  fetchUserCustomFields,
  saveUserCustomFields
} from "src/js/repository/userRepository";
import { CustomFieldsDTO } from "src/js/types";
import { useTranslation } from "src/js/translation";
import { CustomFieldsEnum } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsCustomization/components/sections/CustomFields/types";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { ConfirmationAlert } from "@ds_universal/data_display";
import { Box } from "@ds_universal/Box";
import { Text } from "@ds_universal/Text";
import { CountryAndCityPicker, SanitizedInput } from "./components";
import { ModalLayout } from "../modal/ModalLayout";
import * as S from "./GroupCustomFieldsBlockedModal.styles";

const MappedEducationLevelsTranslationKeys = {
  1: "education_level_dropdown_option_elementary_school",
  2: "education_level_dropdown_option_lower_school",
  3: "education_level_dropdown_option_high_school",
  4: "education_level_dropdown_option_bachelor_degree",
  5: "education_level_dropdown_option_master_degree",
  6: "education_level_dropdown_option_phd",
  7: "education_level_dropdown_option_other"
};

const chunkFields = (fields: JSX.Element[], maxFieldsPerStep: number) => {
  const chunks: JSX.Element[][] = [];
  let currentStep: JSX.Element[] = [];
  let currentSize = 0;

  fields.forEach(field => {
    const fieldSize =
      field.key === CustomFieldsEnum.Residence ||
      field.key === CustomFieldsEnum.BirthLocation
        ? 2
        : 1;

    // Check if adding this field would exceed the maxFieldsPerStep
    if (currentSize + fieldSize > maxFieldsPerStep) {
      chunks.push(currentStep);
      currentStep = [];
      currentSize = 0;
    }

    currentStep.push(field);
    currentSize += fieldSize;
  });

  // Push the last step if there are remaining fields
  if (currentStep.length) {
    chunks.push(currentStep);
  }

  return chunks;
};

const GroupCustomFieldsBlockedModal = () => {
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    ModalStore: { setCustomCloseCb, closeModal },
    SpaceStore: { activeSpaceId },
    GroupStore: { setActiveGroupFull, activeGroupFull },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser }
  } = useStores();
  const [customFieldsInit, setCustomFieldsInit] =
    useState<CustomFieldsDTO>(null);
  const [customFields, setCustomFields] = useState<CustomFieldsDTO>(null);
  const [educationLevels, setEducationLevels] =
    useState<EducationLevelsDTO[]>(null);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setCustomCloseCb(() => {});
    fetchUserCustomFields({
      userId: activeUser.id,
      spaceId: activeSpaceId
    }).then(res => {
      setCustomFieldsInit(res);
      setCustomFields(res);
    });

    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  useEffect(() => {
    if (customFieldsInit?.educationLevel === null) {
      fetchEducationLevel().then(res => {
        setEducationLevels(res);
      });
    }
  }, [customFieldsInit]);

  const onSubmit = () => {
    saveUserCustomFields({
      customFields,
      userId: activeUser.id,
      spaceId: activeSpaceId
    }).then(() => {
      setActiveGroupFull({ ...activeGroupFull, missingCustomFields: false });
      closeModal();
    });
  };

  const missingFields: JSX.Element[] = [];

  if (
    customFieldsInit?.cityOfBirth === null ||
    customFieldsInit?.countryOfBirth === null
  ) {
    missingFields.push(
      <CountryAndCityPicker
        key={CustomFieldsEnum.BirthLocation}
        cityLabel="group_custom_fields_blocked_modal_birth_city_label"
        countryLabel="group_custom_fields_blocked_modal_birth_country_label"
        city={customFields?.cityOfBirth}
        country={customFields?.countryOfBirth}
        setCountry={country =>
          setCustomFields(oldData => ({
            ...oldData,
            countryOfBirth: country
          }))
        }
        setCity={city => {
          setCustomFields(oldData => ({
            ...oldData,
            cityOfBirth: city
          }));
        }}
      />
    );
  }

  if (
    customFieldsInit?.cityOfResidence === null ||
    customFieldsInit?.countryOfResidence === null
  ) {
    missingFields.push(
      <CountryAndCityPicker
        key={CustomFieldsEnum.Residence}
        cityLabel="group_custom_fields_blocked_modal_residence_city_label"
        countryLabel="group_custom_fields_blocked_modal_residence_country_label"
        city={customFields?.cityOfResidence}
        country={customFields?.countryOfResidence}
        openFromTop={missingFields.length !== 0}
        setCountry={country =>
          setCustomFields(oldData => ({
            ...oldData,
            countryOfResidence: country
          }))
        }
        setCity={city => {
          setCustomFields(oldData => ({
            ...oldData,
            cityOfResidence: city
          }));
        }}
      />
    );
  }

  if (customFieldsInit?.identification === null) {
    missingFields.push(
      <SanitizedInput
        key={CustomFieldsEnum.ID}
        label="group_custom_fields_blocked_modal_identification_label"
        placeholder="group_custom_fields_blocked_modal_identification_placeholder"
        value={customFields?.identification as string}
        setValue={val => {
          setCustomFields(oldData => ({ ...oldData, identification: val }));
        }}
      />
    );
  }

  if (customFieldsInit?.vat === null) {
    missingFields.push(
      <SanitizedInput
        key={CustomFieldsEnum.VAT}
        label="group_custom_fields_blocked_modal_vat_label"
        placeholder="group_custom_fields_blocked_modal_vat_placeholder"
        value={customFields?.vat as string}
        setValue={val => {
          setCustomFields(oldData => ({ ...oldData, vat: val }));
        }}
      />
    );
  }

  if (customFieldsInit?.educationLevel === null) {
    const openFromTop =
      missingFields.some(
        el =>
          el.key === CustomFieldsEnum.BirthLocation ||
          el.key === CustomFieldsEnum.Residence
      ) && missingFields.length === 2;

    missingFields.push(
      <S.DropdownWrapper key={CustomFieldsEnum.EducationLevel}>
        <Text type="formSubtitle">
          {translate("group_custom_fields_blocked_modal_education_level_label")}
        </Text>
        <Dropdown
          fromTop={openFromTop}
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          placeholder={translate(
            "group_custom_fields_blocked_modal_education_level_placeholder"
          )}
          optionsList={educationLevels?.map(({ id }) => ({
            id,
            label: translate(MappedEducationLevelsTranslationKeys[id])
          }))}
          setSelectedOptionId={id =>
            setCustomFields(oldData => ({ ...oldData, educationLevel: id }))
          }
          selectedOptionId={customFields?.educationLevel}
        />
      </S.DropdownWrapper>
    );
  }

  // Split the fields into steps with a maximum of 4 fields per step
  const steps = chunkFields(missingFields, 4);

  const isCurrentStepValid = () => {
    if (!steps || !steps[currentStep]) return false;
    return steps[currentStep].every(field => {
      const { key } = field;
      if (key === CustomFieldsEnum.BirthLocation) {
        return customFields?.cityOfBirth && customFields?.countryOfBirth;
      }
      if (key === CustomFieldsEnum.Residence) {
        return (
          customFields?.cityOfResidence && customFields?.countryOfResidence
        );
      }
      if (key === CustomFieldsEnum.ID) {
        return customFields?.identification;
      }
      if (key === CustomFieldsEnum.VAT) {
        return customFields?.vat;
      }
      if (key === CustomFieldsEnum.EducationLevel) {
        return customFields?.educationLevel;
      }
      return true;
    });
  };

  return (
    <ModalLayout
      labels={{
        title: translate("group_custom_fields_blocked_modal_title"),
        mobileTitle: translate("group_custom_fields_blocked_modal_title_mobile")
      }}
      mobileProps={{
        closeLabel: " ",
        withDrag: false,
        ...(currentStep < 1
          ? {}
          : {
              closeLabel: translate("back"),
              onClose: () => setCurrentStep(prev => prev - 1)
            })
      }}
      desktopProps={{
        hasAdaptiveHeight: true,
        onBack: currentStep > 0 ? () => setCurrentStep(prev => prev - 1) : null
      }}
      progressPercentage={((currentStep + 1) / steps.length) * 100}
    >
      <S.Wrapper>
        <Box textAlign="center">
          <Text type="body">
            {translate("group_custom_fields_blocked_modal_description")}
          </Text>
        </Box>
        {steps[currentStep]}

        <S.Buttons>
          <Button
            theme={whiteTheme}
            variant="secondary"
            fullWidth={isLayoutModeMobile}
            onClick={() => {
              openConfirmDialog(
                <ConfirmationAlert
                  acceptText={translate(
                    "group_custom_fields_blocked_modal_go_home_accept_cta"
                  )}
                  declineText={translate(
                    "group_custom_fields_blocked_modal_go_home_decline_cta"
                  )}
                  onAcceptFunction={() => {
                    closeModal();
                    closeConfirmDialog();
                    navigateTo(createUrl("home"));
                  }}
                  onDeclineFunction={closeConfirmDialog}
                  theme={whiteTheme}
                  text={translate(
                    "group_custom_fields_blocked_modal_go_home_text"
                  )}
                />
              );
            }}
          >
            {translate("group_custom_fields_blocked_modal_go_home_cta")}
          </Button>
          {currentStep < steps.length - 1 ? (
            <Button
              theme={whiteTheme}
              variant="primary"
              type="button"
              fullWidth={isLayoutModeMobile}
              disabled={!isCurrentStepValid()}
              onClick={() => setCurrentStep(prev => prev + 1)}
            >
              {translate("group_custom_fields_blocked_modal_continue_cta")}
            </Button>
          ) : (
            <Button
              theme={whiteTheme}
              variant="primary"
              fullWidth={isLayoutModeMobile}
              type="button"
              disabled={!isCurrentStepValid()}
              onClick={onSubmit}
            >
              {translate("group_custom_fields_blocked_modal_submit_cta")}
            </Button>
          )}
        </S.Buttons>
      </S.Wrapper>
    </ModalLayout>
  );
};

export default GroupCustomFieldsBlockedModal;
