import {
  DoodleLoader,
  FabButton,
  getHighContrastColor,
  Text
} from "@arcadia/design-system";
import styled from "styled-components";

export const StyledDoodleLoader = styled(DoodleLoader)`
  & > div {
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  @media (max-width: 768px) {
    padding: 16px 0 120px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextDescription = styled(Text)`
  display: block;
  margin-top: 4px;
`;

export const UsersCounter = styled.div`
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  padding: 0 10px;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => getHighContrastColor(theme.primaryColor[500])};
`;

export const StyledFabButton = styled(FabButton)`
  z-index: ${({ theme }) => theme.zIndex.average};
`;
