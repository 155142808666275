import {
  ConfirmationAlert,
  ContextualAlert,
  ContextualError,
  ContextualFeedback
} from "@arcadia/design-system";
import React, { useCallback, useEffect, useState } from "react";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { ToastMessage, ToastVariant } from "../types";

const useToastMessage = (initialMessages: ToastMessage[] = []) => {
  const [toastMessages, setToastMessages] =
    useState<ToastMessage[]>(initialMessages);
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const addToastMessage = useCallback((newMessage: ToastMessage) => {
    const message = { zIndex: 9, ...newMessage };
    setToastMessages(prevMessages => {
      const existingMessageIndex = prevMessages.findIndex(
        msg => msg.id === newMessage.id
      );
      if (existingMessageIndex !== -1) {
        const updatedMessages = [...prevMessages];
        updatedMessages[existingMessageIndex] = message;
        return updatedMessages;
      }
      return [...prevMessages, message];
    });
  }, []);

  const removeToastMessage = useCallback((id: string) => {
    setToastMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
  }, []);

  useEffect(() => {
    const timeouts = toastMessages.map(message => {
      if (message.duration !== undefined) {
        return setTimeout(() => {
          removeToastMessage(message.id);
        }, message.duration);
      }
      return null;
    });

    return () => {
      timeouts.forEach(timeout => timeout && clearTimeout(timeout));
    };
  }, [toastMessages, removeToastMessage]);

  const renderToastMessage = useCallback(
    (toastMessage: ToastMessage) => {
      switch (toastMessage.variant) {
        case ToastVariant.Success:
          return (
            <ContextualFeedback
              key={toastMessage.id}
              theme={whiteTheme}
              {...toastMessage.props}
            >
              {toastMessage.children}
            </ContextualFeedback>
          );
        case ToastVariant.Error:
          return (
            <ContextualError
              key={toastMessage.id}
              style={{ maxWidth: "100%" }}
              theme={whiteTheme}
              {...toastMessage.props}
            >
              {toastMessage.children}
            </ContextualError>
          );
        case ToastVariant.Alert:
          return (
            <ContextualAlert
              key={toastMessage.id}
              style={{ maxWidth: "100%" }}
              theme={whiteTheme}
              {...toastMessage.props}
            >
              {toastMessage.children}
            </ContextualAlert>
          );
        case ToastVariant.Warning:
          return (
            <WarningBanner
              key={toastMessage.id}
              maxWidth="100%"
              {...toastMessage.props}
            />
          );
        case ToastVariant.Confirmation:
          return (
            <ConfirmationAlert
              key={toastMessage.id}
              style={{ width: "100%" }}
              theme={whiteTheme}
              {...toastMessage.props}
            >
              {toastMessage.children}
            </ConfirmationAlert>
          );
        default:
          return null;
      }
    },
    [translate, whiteTheme, removeToastMessage]
  );

  const toastMessageNodes = useCallback(() => {
    const highestZIndexToast = toastMessages.reduce((highest, current) => {
      if (
        current.zIndex !== undefined &&
        (highest === null || current.zIndex > highest.zIndex)
      ) {
        return current;
      }
      return highest;
    }, null);

    if (highestZIndexToast) {
      return [renderToastMessage(highestZIndexToast)];
    }
    return toastMessages.map(renderToastMessage);
  }, [toastMessages, renderToastMessage]);

  return {
    toastMessages,
    toastMessageNodes,
    addToastMessage,
    removeToastMessage,
    setToastMessages
  };
};

export default useToastMessage;
