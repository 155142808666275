import { useCallback, useMemo, useState } from "react";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  queryClient,
  spacesQueryKey,
  useDashboardSettingsV3
} from "src/js/query";
import { updateSpaceSettings } from "src/js/repository/dashboardRepository";
import { useTranslation } from "src/js/translation";
import { AssessmentsGradingMethodType } from "src/js/types/models/Customization";
import { TargetAudienceIdDTO } from "src/js/types";
import { useMutation } from "src/js/query/hooks";
import useAssessmentGrading from "./useAssessmentGrading";
import useDirectMessage from "./useDirectMessage";
import useCustomFields from "./useCustomFields";
import { CustomFieldsState } from "../components/sections/CustomFields/types";

type UpdateSpaceSettingsCustomizationProps = {
  gradingThreshold: number;
  gradingMethod: AssessmentsGradingMethodType;
  disableChat: boolean;
  customFields: CustomFieldsState;
  targetAudience: TargetAudienceIdDTO;
};

const useFormLogic = () => {
  const {
    SpaceStore: { activeSpace }
  } = useStores();
  const { translate } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const { dashboardSettings, refetch: refetchDashboardSettings } =
    useDashboardSettingsV3(activeSpace?.space.id, false, {
      enabled: !!activeSpace?.space?.id,
      staleTime: 0,
      refetchOnWindowFocus: false
    });

  const {
    gradingMethod,
    setGradingMethod,
    gradingThreshold,
    setNormalizedThreshold,
    normalizedGradingMethod
  } = useAssessmentGrading({
    assessmentsCompletionThreshold:
      dashboardSettings?.customizationSettings?.assessmentsCompletionThreshold,
    assessmentsGradingMethod:
      dashboardSettings?.customizationSettings?.assessmentsGradingMethod
  });

  const { disableChatState, setDisableChat } = useDirectMessage({
    disableChat: dashboardSettings?.disableChat
  });

  const {
    customFields,
    setCustomFields,
    targetAudience,
    setTargetAudience,
    isCustomFieldDirty,
    onSubmit
  } = useCustomFields({
    customFieldsInit: dashboardSettings?.requiredCustomFields
  });

  const { mutate: updateDashboardSettings } = useMutation(
    ({
      gradingMethod,
      gradingThreshold,
      disableChat,
      customFields,
      targetAudience
    }: UpdateSpaceSettingsCustomizationProps) =>
      updateSpaceSettings({
        assessmentsCompletionThreshold: gradingThreshold,
        assessmentsGradingMethod: gradingMethod,
        spaceId: activeSpace?.space?.slug,
        customFields,
        targetAudience,
        disableChat
      }),
    {
      onSuccess: () => {
        onSubmit();
        showToastSuccess({
          str: translate({
            text: "space_save_settings_success_message"
          })
        });
        queryClient.invalidateQueries(
          spacesQueryKey.id({ spaceId: activeSpace?.space?.slug })
        );
      },
      onError: () => {
        showToastError({
          str: translate("spaces_save_settings_error_message")
        });
      },
      onSettled() {
        refetchDashboardSettings();
        setEditMode(false);
      }
    }
  );

  // TODO try to improve this
  const hasChanges = useMemo(() => {
    return (
      dashboardSettings?.customizationSettings?.assessmentsGradingMethod !==
        gradingMethod ||
      dashboardSettings?.customizationSettings
        ?.assessmentsCompletionThreshold !== gradingThreshold ||
      dashboardSettings?.disableChat !== disableChatState ||
      isCustomFieldDirty
    );
  }, [
    dashboardSettings?.disableChat,
    dashboardSettings?.customizationSettings?.assessmentsGradingMethod,
    dashboardSettings?.customizationSettings?.assessmentsCompletionThreshold,
    disableChatState,
    gradingMethod,
    gradingThreshold,
    isCustomFieldDirty
  ]);

  // TODO add customFields to save the values in the BE when API is ready
  const onClickEditOrSave = useCallback(() => {
    if (!editMode) {
      setEditMode(true);
      return;
    }

    if (hasChanges) {
      updateDashboardSettings({
        disableChat: disableChatState,
        gradingMethod,
        gradingThreshold,
        customFields,
        targetAudience
      });
    } else {
      setEditMode(false);
    }
  }, [
    hasChanges,
    updateDashboardSettings,
    gradingMethod,
    gradingThreshold,
    disableChatState,
    editMode,
    targetAudience,
    customFields
  ]);

  return {
    editMode,
    hasChanges,
    onClickEditOrSave,
    gradingMethod,
    gradingThreshold,
    normalizedGradingMethod,
    setGradingMethod,
    setNormalizedThreshold,
    disableChatState,
    setDisableChat,
    customFields,
    setCustomFields,
    targetAudience,
    setTargetAudience
  };
};

export default useFormLogic;
