import { ActionButton, Box, Heading, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { useGroupFeatures, useStores } from "src/js/hooks";
import { useSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import createUrl from "src/js/modules/routing";
import {
  __GROUP_COVER_MIME__,
  __GROUP_COVER_SIZE__
} from "src/js/settings/settingFileUploader";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

import * as S from "./GroupSettings.styles";

import { GroupSettingsParams } from "./GroupSettings.types";
import { GroupSettingsInformation } from "./GroupSettingsInformation";
import { GroupSettingsMembers } from "./GroupSettingsMembers";
import { GroupSettingsNotice } from "./GroupSettingsNotice";
import { GroupSettingsSections } from "./GroupSettingsSections";
import { useGroupSettingsNavigation } from "./useGroupNavigation";

const GroupSettings = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    GroupStore: {
      activeGroupFull,
      roles,
      usersCount,
      permissionToCustomization
    },
    UIStore
  } = useStores();
  const { option } = useParams<GroupSettingsParams>();
  const { group } = activeGroupFull;

  const [isEditMode, setEditMode] = React.useState(false);

  const [groupCoverUrl, setGroupCoverUrl] = useState(group?.cover?.originalUrl);
  const [groupCoverFile, setGroupCoverFile] = useState(null);
  const { hideMembers } = useGroupFeatures();

  useEffect(() => {
    setGroupCoverUrl(group?.cover?.originalUrl);
    setGroupCoverFile(null);
  }, [isEditMode, group?.cover?.originalUrl]);
  const { setHeaderComponent, resetHeaderComponent } = useSpaceGroupsLayout();

  const {
    goBack,
    navigateInformation,
    navigateMembers,
    navigateNotifications,
    navigateSections
  } = useGroupSettingsNavigation(group);

  // Need to check that the group id is populated before doing the redirect
  // TODO dig more into the #undefined space and group in the url
  if (group?.id && !permissionToCustomization && option === "sections") {
    return (
      <Redirect
        to={createUrl("group_settings", {
          group_id: group?.id,
          option: "information"
        })}
      />
    );
  }

  let pageToRender;

  if (!group) {
    pageToRender = undefined;
  } else {
    switch (option) {
      case "information":
        pageToRender = (
          <GroupSettingsInformation
            group={group}
            groupCoverFile={groupCoverFile}
            groupCoverUrl={groupCoverUrl}
            roles={roles}
            isEditMode={isEditMode}
            setEditMode={setEditMode}
          />
        );

        break;
      case "members":
        pageToRender = <GroupSettingsMembers />;
        break;
      case "notice":
        pageToRender = <GroupSettingsNotice />;
        break;
      case "sections":
        pageToRender = <GroupSettingsSections />;
        break;
      default:
        break;
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const fileSizeKiloBytes = event.target.files[0].size / 1024;

      if (fileSizeKiloBytes <= __GROUP_COVER_SIZE__) {
        setGroupCoverFile(event.target.files[0]);
        setGroupCoverUrl(URL.createObjectURL(event.target.files[0]));
      }
    }
  };
  useEffect(() => {
    setHeaderComponent(
      <SecondaryBar
        position="sticky"
        top={54}
        leftSection={
          <Box marginLeft={16}>
            <ActionButton
              variant="secondaryColored"
              icon="chevronLeft"
              theme={whiteTheme}
              onClick={goBack}
            />
          </Box>
        }
        middleSection={
          <S.CenterContainer>
            <Heading level="5">
              {translate("group_settings_page_header")}
            </Heading>
          </S.CenterContainer>
        }
      />
    );

    return () => {
      resetHeaderComponent();
    };
  }, [isEditMode, UIStore.isLayoutModeMobile, group]);

  return (
    <S.PageWrapper>
      <Box marginTop={64} width="100%" padding={20}>
        <S.ContentWrapper>
          <Box position="relative">
            <S.ImageWrapper src={groupCoverUrl || __GROUP_COVER_DEFAULT__} />
            {isEditMode ? (
              <S.UpdateImageOverlay type="button" onClick={() => {}}>
                <S.CustomImageInput
                  type="file"
                  accept={__GROUP_COVER_MIME__.join(",")}
                  name="cover"
                  onChange={handleFileChange}
                />
                <Icon icon="pencil" initialViewbox width={32} height={32} />
              </S.UpdateImageOverlay>
            ) : null}
          </Box>
          <Box marginTop={16} textAlign="center">
            <Heading level="3">{group?.name}</Heading>
          </Box>
          {!hideMembers && (
            <Box marginTop={4} textAlign="center">
              <Text type="formSmallCaption">{`${usersCount} ${translate(
                "group_settings_page_tab_members"
              )}`}</Text>
            </Box>
          )}
          <S.TabButtonContainer showTabSections={permissionToCustomization}>
            <S.TabButton
              active={option === "information"}
              onClick={navigateInformation}
            >
              <Icon icon="edit" width={20} height={20} />
              <S.TabButtonText>
                {translate("group_settings_page_tab_info")}
              </S.TabButtonText>
            </S.TabButton>
            {!hideMembers && (
              <S.TabButton
                active={option === "members"}
                onClick={navigateMembers}
              >
                <Icon icon="user" width={20} height={20} />
                <S.TabButtonText>
                  {translate("group_settings_page_tab_members")}
                </S.TabButtonText>
              </S.TabButton>
            )}
            <S.TabButton
              active={option === "notice"}
              onClick={navigateNotifications}
            >
              <Icon icon="bellCrossed" width={20} height={20} />
              <S.TabButtonText>
                {translate("group_settings_page_tab_notifications")}
              </S.TabButtonText>
            </S.TabButton>
            {permissionToCustomization ? (
              <S.TabButton
                active={option === "sections"}
                onClick={navigateSections}
              >
                <Icon
                  initialViewbox
                  icon="activityZone"
                  width={20}
                  height={20}
                />
                <S.TabButtonText>
                  {translate("header_group_settings_customize_label")}
                </S.TabButtonText>
              </S.TabButton>
            ) : null}
          </S.TabButtonContainer>
          {pageToRender}
        </S.ContentWrapper>
      </Box>
    </S.PageWrapper>
  );
};

export default observer(GroupSettings);
