import styled from "styled-components";
import { SWithMobileProps } from "./SchoolGroupCreationForm.types";

export const StyledForm = styled.form<SWithMobileProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 420px;
`;

export const SubmitWrapper = styled.div`
  position: sticky;
  bottom: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: auto;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const StyledLabel = styled.label`
  all: unset;
  display: block;
  margin: 16px 0 8px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Spacer = styled.div`
  margin-top: 16px;
`;
