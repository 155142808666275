import React from "react";
import { Icon, IconName, Text, colors } from "@arcadia/design-system";
import { isBefore } from "date-fns";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { __MAINNET_CHAIN_ICONS__ } from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import useDownloadBadgeCertificate from "src/js/hooks/badges/useDownloadBadgeCertificate";
import { CircularLoader } from "src/js/components/global/CircularLoader";
import * as S from "./BadgeEarnedList.styles";
import { BadgeEarnedProps } from "./BadgeEarned.types";

type TooltipItem = {
  text: string;
  icon: IconName;
  disabled?: boolean;
  onClick: () => void;
};

const BadgeEarned = ({
  name,
  image,
  expiredAt,
  openBadgePublicUrl,
  nftReference,
  transactionReference,
  chainId,
  loggedInUserId,
  id
}: BadgeEarnedProps) => {
  const { translate } = useTranslation();
  const {
    UserStore: {
      activeUser: { uuid }
    }
  } = useStores();
  const { generatePdf, isDownloading } = useDownloadBadgeCertificate();

  const checkIfBadgeIsExpired = () => {
    if (!expiredAt) {
      return false;
    }

    const now = new Date();
    const badgeExpiration = new Date(expiredAt);
    return isBefore(badgeExpiration, now);
  };

  const hasLink = !!openBadgePublicUrl;
  const userAwardedNFT = !!nftReference;

  const tooltipItems: TooltipItem[] = [
    {
      text: translate("space_badge_tooltip_item_badge_details"),
      onClick: () => window.open(openBadgePublicUrl),
      icon: "externalLink"
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_nft_details"),
      onClick: () => window.open(nftReference),
      icon: "externalLink"
    },
    transactionReference && {
      text: translate("space_badge_tooltip_item_blockchain_explorer"),
      onClick: () => window.open(transactionReference),
      icon: "externalLink"
    },
    loggedInUserId === uuid && {
      text: translate("badge_pdf_certificate_tooltip_item_title"),
      onClick: () => generatePdf({ badgeId: id, userId: loggedInUserId }),
      icon: "download",
      disabled: isDownloading
    }
  ];

  const handleChainIconColumn = () => {
    if (userAwardedNFT) {
      if (__MAINNET_CHAIN_ICONS__[chainId]) {
        return (
          <S.AwardedNFTIndicatorContainer>
            <Icon
              icon={__MAINNET_CHAIN_ICONS__[chainId]}
              width={12}
              height={12}
              initialViewbox
              color={colors.grey[1000]}
            />
          </S.AwardedNFTIndicatorContainer>
        );
      }
      return (
        <S.NFTLabelContainer>
          <Text type="label">NFT</Text>
        </S.NFTLabelContainer>
      );
    }
    return null;
  };

  return (
    <TranslatedTooltip
      placement="top"
      customTooltip={
        <S.TooltipContainer>
          {tooltipItems
            .filter(item => item)
            .map(({ text, icon, disabled, onClick }) => (
              <S.TooltipItem key={text} onClick={onClick} isDisable={disabled}>
                <Icon icon={icon} width={16} height={16} initialViewbox />
                <S.TooltipText type="table">{text}</S.TooltipText>
                {isDownloading && disabled ? (
                  <S.StyledLoaderContainer>
                    <CircularLoader size={30} />
                  </S.StyledLoaderContainer>
                ) : null}
              </S.TooltipItem>
            ))}
        </S.TooltipContainer>
      }
    >
      <S.EarnedBadgeContainer
        data-testid="earned-badge-container"
        hasLink={hasLink}
      >
        <S.BadgeImageContainer>
          {handleChainIconColumn()}
          <S.BadgeEarnedImage
            isExpired={checkIfBadgeIsExpired()}
            src={image}
            alt="Badge-logo"
          />
        </S.BadgeImageContainer>
        <S.BadgeEarnedName>
          <Text type="body">{name}</Text>
        </S.BadgeEarnedName>
      </S.EarnedBadgeContainer>
    </TranslatedTooltip>
  );
};
export default BadgeEarned;
