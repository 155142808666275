import React from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { SpaceBaseCardSizeType } from "@arcadia/design-system";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { DashboardEvent, EventDomain } from "src/js/types";
import { MigrateGroupsModal } from "../MigrateGroupsModal";
import { SpaceInfoCard } from "../SpaceCards/SpaceInfoCard";
import * as S from "./SpacesDotMenuWrapper.styles";
import { ListItemProps } from "../DotsMenu/ListItem";
import { ClaimableSpaceCard } from "../ClaimableSpaceCard";

const SpacesDotMenuWrapper = ({
  isAdmin,
  leaveSpace,
  activeSpace,
  activeSpaceId,
  color,
  alignRight = true,
  onForceClose = () => {}
}) => {
  const { ModalStore } = useStores();
  const { translate } = useTranslation();

  const menuItems: ListItemProps[] = isAdmin
    ? [
        {
          id: "Dashboard",
          label: translate("spaces_settings_general_dashboard"),
          icon: "compassEmpty",
          onClick: () => {
            navigateTo(
              createUrl(
                activeSpace?.enableAnalytics
                  ? "dashboard_analytics"
                  : "dashboard_staff",
                {
                  space_id: activeSpaceId
                }
              )
            );
            onForceClose();
            _trackEvent(
              EventDomain.SpaceDashboardUsage,
              DashboardEvent.DashboardUserAccessSpMenu
            );
          }
        },
        {
          id: "Customize",
          label: translate("spaces_settings_plan_customize"),
          icon: "edit",
          onClick: () =>
            navigateTo(
              createUrl("dashboard_settings", {
                space_id: activeSpaceId,
                option: "general"
              })
            )
        },
        {
          id: "Plan",
          label: translate("spaces_settings_permissions_plan"),
          icon: "creditcard",
          onClick: () =>
            navigateTo(
              createUrl("dashboard_settings", {
                space_id: activeSpaceId,
                option: "plan"
              })
            )
        },
        {
          id: "moveGroups",
          label: translate("spaces_settings_move_groups"),
          icon: "fourWayArrow",
          onClick: () => {
            ModalStore.openModal(() => (
              <MigrateGroupsModal
                skipIntro
                moveFromActiveSpace
                onClose={() => ModalStore.closeModal()}
              />
            ));
          }
        },
        {
          id: "leaveSpace",
          label: translate("spaces_settings_leave_space"),
          icon: "logout",
          onClick: leaveSpace,
          redShade: true
        }
      ]
    : [
        {
          id: "leaveSpace",
          label: translate("spaces_settings_leave_space"),
          icon: "logout",
          onClick: leaveSpace,
          redShade: true
        }
      ];

  return (
    <S.DotsMenu
      id="spaceOptionsDotsMenu"
      listContainerId="spaceOptionsMenuList"
      items={menuItems}
      color={color}
      alignRight={alignRight}
      header={
        <>
          <S.HeaderWrapper>
            <SpaceInfoCard
              spaceName={activeSpace?.space?.name}
              sizeVariant={SpaceBaseCardSizeType.Small}
              color={activeSpace?.space?.defaultBadgeColor}
              url={activeSpace?.space?.badge?.small}
              hasBorder={false}
            />
          </S.HeaderWrapper>
          <ClaimableSpaceCard />
        </>
      }
    />
  );
};

export default SpacesDotMenuWrapper;
