import { UserInformation } from "./ProfileEditPage.types";

export const getPlaceholder = (key: keyof UserInformation) => {
  switch (key) {
    case "status":
      return "insert_status";
    case "birthday":
      return "insert_birthday";
    case "phone":
      return "insert_phone";
    case "facebook":
      return "insert_profile_facebook";
    case "instagram":
      return "insert_profile_instagram";
    default:
      return "insert_field";
  }
};

export const __EDIT_PROFILE_SCHEMA__ = {
  phone: {
    pattern: {
      value: /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      message: "phone_number_validation_error"
    }
  },
  facebook: {
    pattern: {
      value: /^(https?:\/\/)?((w{3}.|m\.)?)facebook.com\/.*/i,
      message: "facebook_profile_validation_error"
    }
  },
  instagram: {
    pattern: {
      value: /^@/,
      message: "instagrma_profile_validation_error"
    }
  },
  // We have no real rules for the status, pattern accept everything and doesn't exist
  status: {
    pattern: {
      value: /[\s\S]*/,
      message: "status_validation_error"
    }
  },
  birthday: {
    pattern: {
      value: /[\s\S]*/,
      message: "birthday_validation_error"
    }
  }
};
