import { Box, Button, Heading } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import {
  useAssessmentScores,
  useGroupsProgress,
  useUsersSessionsCount
} from "src/js/query/analytics";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import {
  useAnalyticsFilter,
  useLearningMetrics
} from "../../dashboard/Analytics/hooks";
import * as S from "./AnalyticsPreview.styles";
import AnalyticsPreviewMetricCard from "./components/AnalyticsPreviewMetricCard";

const AnalyticsPreview = () => {
  const {
    SpaceStore: { activeSpaceId, hasDecimalGrading }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const { fromDate, toDate, groupIds, aggregation } = useAnalyticsFilter();

  const { percentageValue, trendValue } = useLearningMetrics();

  const { data: responseGroupsProgress } = useGroupsProgress({
    spaceId: activeSpaceId,
    offset: 0,
    limit: 6,
    withChartData: false,
    fromDate,
    toDate,
    aggregation,
    groupIds
  });

  const { data: responseAssessmentScores } = useAssessmentScores({
    spaceId: activeSpaceId,
    withChartData: false,
    fromDate,
    toDate,
    groupIds
  });

  const { data: responseUsersSessionsCount } = useUsersSessionsCount({
    spaceId: activeSpaceId,
    withChartData: false,
    fromDate,
    toDate,
    groupIds,
    aggregation
  });

  return (
    <>
      <S.HeaderContainer>
        <Box flex={1}>
          <Heading level="5">
            {translate({ text: "space_analytics_title" })}
          </Heading>
          <S.Subtitle type="formSubtitle">
            {translate({ text: "space_analytics_subtitle" })}
          </S.Subtitle>
        </Box>
        <S.ButtonWrapper>
          <Button
            onClick={() =>
              navigateTo(
                createUrl("dashboard_analytics", { space_id: activeSpaceId })
              )
            }
            theme={greyTheme}
            variant="secondary"
            size="small"
          >
            {translate("space_analytics_cta")}
          </Button>
        </S.ButtonWrapper>
      </S.HeaderContainer>
      <S.MetricCardsContainer>
        <AnalyticsPreviewMetricCard
          title={translate("primary_metric_card_objectives_title")}
          baseChartResponse={{
            averageNumber: percentageValue,
            averageNumberTrend: trendValue,
            results: []
          }}
          measurementUnit="%"
          trendMeasurementUnit={translate(
            "analytics_percentage_point_abbreviation"
          )}
        />
        <AnalyticsPreviewMetricCard
          title={translate(
            "metric_card_objectives_overall_course_progress_title"
          )}
          baseChartResponse={responseGroupsProgress}
          measurementUnit="%"
          trendMeasurementUnit={translate(
            "analytics_percentage_point_abbreviation"
          )}
        />
        <AnalyticsPreviewMetricCard
          title={translate("metric_card_objectives_assessments_score_title")}
          baseChartResponse={responseAssessmentScores}
          measurementUnit={hasDecimalGrading ? "/10" : "%"}
          trendMeasurementUnit={hasDecimalGrading ? "" : "%"}
          resultMultiplier={hasDecimalGrading ? 1 : 10}
        />
        <AnalyticsPreviewMetricCard
          title={translate("metric_card_objectives_number_of_sessions_title")}
          baseChartResponse={responseUsersSessionsCount}
          measurementUnit=""
          trendMeasurementUnit=""
          numberOfDecimalDigits={0}
        />
      </S.MetricCardsContainer>
    </>
  );
};

export default observer(AnalyticsPreview);
