import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  @media (max-width: 767px) {
    margin: 0 16px;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 380px;
`;
