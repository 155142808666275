import { greyTheme } from "@ds_themes/grey";
import { whiteTheme } from "@ds_themes/white";
import { DropdownCustomOption, DropdownOption } from "@ds_types/components";
import { DoodleLoader } from "@ds_universal/DoodleLoader";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import { SpecificError } from "@ds_universal/data_display";
import { SearchBar } from "@ds_universal/data_entry/SearchBar";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DefaultTheme, ThemeProvider } from "styled-components";

import * as S from "./Dropdown.styles";
import { DropdownProps, DropdownSubComponents, OptionProps } from "./types";

// TODO Could be improved using option.id instead of indexes
// TODO: Consider refactoring this and the other 2 dropdown components and maybe combine them into a single component
const Dropdown: FC<DropdownProps> & DropdownSubComponents = ({
  id = "dropdown-single",
  className,
  theme,
  optionsList,
  placeholder,
  width = "100%",
  setSelectedOptionId,
  selectedOptionId,
  disabled,
  onReset,
  resetPlaceholder,
  searchable,
  searchPlaceholder,
  onSearchFunction,
  searchQuery,
  hasNext = false,
  showNext = () => {},
  scrollableListId = "dropdown-multiple-list",
  fromTop = false,
  error,
  children,
  onOptionsOpen = () => {}
}) => {
  const subComponents = React.Children.map(
    children as React.ReactElement[],
    (child: React.ReactElement) =>
      child?.type === Dropdown.Option ? child : null
  )?.filter(c => c);

  const customOptionsList = useMemo<DropdownCustomOption[]>(
    () =>
      subComponents?.map(element => ({
        ...(element.props as OptionProps),
        element
      })) || [],
    [subComponents]
  );

  const isCustomized = optionsList === undefined;
  const valueOptionsList = !isCustomized ? optionsList : customOptionsList;

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [indexOnFocus, setIndexOnFocus] = useState<number>(-1);
  const optionsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  // theme.dropdown is the correct pick, greyTheme/whiteTheme are keep for retro-compatibility
  const searchbarTheme = theme.dropdown.searchbar
    ? theme.dropdown
    : // @ts-ignore
      theme === whiteTheme
      ? greyTheme
      : whiteTheme;

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    if (!isOptionsOpen) {
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
    optionsRef.current = optionsRef.current.slice(0, valueOptionsList.length);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOptionsOpen, valueOptionsList]);

  useEffect(() => {
    if (indexOnFocus !== -1 && isOptionsOpen) {
      optionsRef.current[indexOnFocus]?.focus();
    }
  }, [indexOnFocus, isOptionsOpen]);

  useEffect(() => {
    onOptionsOpen(isOptionsOpen);
  }, [isOptionsOpen, onOptionsOpen]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (option: DropdownOption) => {
    setSelectedOptionId(option.id);
    setIsOptionsOpen(false);
  };
  const handleListKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = e => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setIndexOnFocus(
          indexOnFocus - 1 >= 0 ? indexOnFocus - 1 : valueOptionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setIndexOnFocus(
          indexOnFocus === valueOptionsList.length - 1 || indexOnFocus === -1
            ? 0
            : indexOnFocus + 1
        );
        break;
      default:
        break;
    }
  };

  const onHoverOption = (index: number) => {
    setIndexOnFocus(index);
  };

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper
        ref={ref}
        width={width}
        isCustomized={isCustomized}
        className={className}
      >
        <S.Button
          type="button"
          disabled={disabled}
          id={id}
          aria-expanded={isOptionsOpen}
          aria-haspopup="listbox"
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
          role="button"
        >
          {isCustomized ? (
            <>
              {selectedOptionId ? (
                <span>
                  {(valueOptionsList as DropdownCustomOption[])[
                    valueOptionsList.findIndex(
                      option => option.id === selectedOptionId
                    )
                  ]?.element || placeholder}
                </span>
              ) : (
                <S.EmptyOption>
                  <Text type="cta"> {placeholder} </Text>
                </S.EmptyOption>
              )}
            </>
          ) : (
            <>
              {selectedOptionId ? (
                <span>
                  {valueOptionsList[
                    valueOptionsList.findIndex(
                      option => option.id === selectedOptionId
                    )
                  ]?.label || placeholder}
                </span>
              ) : (
                <Text type="cta"> {placeholder} </Text>
              )}
            </>
          )}
          <div id="arrow">
            {isOptionsOpen ? (
              <Icon icon="chevronSmallUp" height="16px" width="16px" />
            ) : (
              <Icon icon="chevronSmallDown" height="16px" width="16px" />
            )}
          </div>
        </S.Button>
        {error?.message ? (
          <SpecificError
            // TODO: fix typings
            theme={theme.input as unknown as DefaultTheme}
            text={error.message}
          />
        ) : null}
        {isOptionsOpen ? (
          <div>
            <S.OptionContainer
              role="listbox"
              aria-label="lista di opzioni"
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
              aria-activedescendant={valueOptionsList[indexOnFocus]?.id}
              fromTop={fromTop}
            >
              {searchable ? (
                <S.SearchInputContainer>
                  <SearchBar
                    value={searchQuery}
                    theme={searchbarTheme as unknown as DefaultTheme}
                    onChange={onSearchFunction}
                    placeholder={searchPlaceholder}
                  />
                </S.SearchInputContainer>
              ) : null}
              <S.OptionsListContainer>
                <S.ScrollableWrapper
                  id={scrollableListId}
                  role="listbox"
                  aria-label="lista di opzioni"
                  tabIndex={-1}
                  onKeyDown={handleListKeyDown}
                  withScrollbar={valueOptionsList.length > 6}
                  aria-activedescendant={valueOptionsList[indexOnFocus]?.id}
                  aria-multiselectable="true"
                >
                  <InfiniteScroll
                    scrollableTarget={scrollableListId}
                    dataLength={valueOptionsList.length}
                    next={showNext}
                    hasMore={hasNext}
                    loader={<DoodleLoader theme={theme} isMini />}
                  >
                    {valueOptionsList.map((option, index) => (
                      <S.OptionButton
                        type="button"
                        id={`button ${option.id}`}
                        aria-label={option.label}
                        key={option.id}
                        role="option"
                        aria-selected={selectedOptionId === option.id}
                        aria-checked={selectedOptionId === option.id}
                        disabled={option.isDisabled}
                        tabIndex={0}
                        ref={el => (optionsRef.current[index] = el)}
                        onClick={() => setSelectedThenCloseDropdown(option)}
                        onMouseEnter={() => onHoverOption(index)}
                        value={option.label}
                      >
                        {isCustomized ? (
                          (option as DropdownCustomOption).element
                        ) : (
                          <Text type="cta"> {option.label} </Text>
                        )}
                      </S.OptionButton>
                    ))}
                  </InfiniteScroll>
                </S.ScrollableWrapper>
              </S.OptionsListContainer>
              {onReset ? (
                <S.ResetWrapper>
                  <S.ResetButton role="button" onClick={onReset}>
                    <Icon icon="trash" height="18" width="18" />
                    <Text type="formDescription">{resetPlaceholder}</Text>
                  </S.ResetButton>
                </S.ResetWrapper>
              ) : null}
            </S.OptionContainer>
          </div>
        ) : null}
      </S.Wrapper>
    </ThemeProvider>
  );
};

const Option: FC<OptionProps> = props => (
  <S.OptionCustom>{props.children}</S.OptionCustom>
);
Dropdown.Option = Option;

export default Dropdown;
