import { getGroup } from "src/js/repository/groupRepository";
import { ActiveGroupFull } from "src/js/types";
import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { groupQueryKey } from "./group.query";

export const useGroupByIdV3 = (
  { groupId }: { groupId: number },
  options?: UseQueryOptions<ActiveGroupFull, Error, ActiveGroupFull>
) => {
  const { data: group, ...otherData } = useQuery(
    groupQueryKey.groupByIdV3({ groupId }),
    () => getGroup(groupId),
    options
  );

  return {
    data: group,
    ...otherData
  };
};

export const useGroupByIdV3Cached = (
  { groupId }: { groupId: number },
  options?: UseQueryOptions<ActiveGroupFull, Error, ActiveGroupFull>
) => {
  return useGroupByIdV3(
    { groupId },
    {
      ...options,
      staleTime: Infinity
    }
  );
};
