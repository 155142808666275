import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;

  @media (max-width: 767px) {
    min-height: unset;
    gap: 8px;
    flex-direction: column;
    align-items: center;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  overflow: hidden;
  width: 100%;
  margin-top: 24px;
  display: block;
  margin-bottom: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;
