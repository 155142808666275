import {
  LITE_PLAN_MEMBERS_LIMIT,
  PRO_PLAN_MEMBERS_LIMIT,
  TRIAL_MEMBERS_LIMIT
} from "../settings/settingsInvite";
import { PlanType } from "../types";
import useStores from "./useStores";

const useSpacePlanType = ({
  groupCount
}: {
  groupCount: number;
}): {
  totalMemberInviteLimit: number;
  memberInviteLimitPerGroup: number;
  isPlanProOrEnterprise: boolean;
} => {
  const {
    SpaceStore: { activeSpace, isClaimable }
  } = useStores();

  const { spaceSettings, isInTrial } = activeSpace || {};

  const isPlanProOrEnterprise =
    spaceSettings?.planType === PlanType.PRO ||
    spaceSettings?.planType === PlanType.ENTERPRISE;

  if (isClaimable || isInTrial) {
    return {
      totalMemberInviteLimit: TRIAL_MEMBERS_LIMIT,
      memberInviteLimitPerGroup: Math.floor(TRIAL_MEMBERS_LIMIT / groupCount),
      isPlanProOrEnterprise
    };
  }

  const totalMemberInviteLimit = isPlanProOrEnterprise
    ? PRO_PLAN_MEMBERS_LIMIT
    : LITE_PLAN_MEMBERS_LIMIT;

  const memberInviteLimitPerGroup = Math.floor(
    totalMemberInviteLimit / groupCount
  );

  return {
    totalMemberInviteLimit,
    memberInviteLimitPerGroup,
    isPlanProOrEnterprise
  };
};

export default useSpacePlanType;
