import {
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
  css
} from "styled-components";

import colors from "./colors";
import { VariantType } from "./types";

/**
 * Scrollbar
 */

export const SCROLLBAR_SCROLLBAR_COLOR = {
  [VariantType.White]: colors.blueGrey[900],
  [VariantType.Grey]: colors.blueGrey[600]
};

export const scrollbarMixin = (variant: VariantType) => {
  const color = SCROLLBAR_SCROLLBAR_COLOR[variant];
  return css`
    // rules for firefox scrollbar
    @supports (-moz-appearance: none) {
      overflow-y: auto;
      scrollbar-color: ${color} ${color};
      scrollbar-width: thin;
    }
    ::-webkit-scrollbar {
      width: 0.6em;
      height: 0;
    }
    ::-webkit-scrollbar-thumb {
      border: 0.15em solid transparent;
      background-clip: padding-box;
      border-radius: 1em;
      background-color: ${color};
      &:hover {
        background: ${color};
        background-clip: padding-box;
      }
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  `;
};

/**
 * Skeleton
 */

export const skeletonAnimationMixin = (
  baseColor: string,
  shineColor: string
) => {
  return css`
    background: repeating-linear-gradient(
      90deg,
      ${baseColor} 0%,
      ${shineColor} 40%,
      ${shineColor} 50%,
      ${baseColor} 100%
    );
    background-size: 1000%;
    animation: slide 2s infinite;
    @keyframes slide {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 100%;
      }
    }
  `;
};

export type CreateMixinFn = (
  ...args: [
    first: TemplateStringsArray | CSSObject,
    ...interpolations: SimpleInterpolation[]
  ]
) => FlattenSimpleInterpolation;

export const Breakpoint = {
  sm: 768,
  md: 980,
  lg: 1200,
  xl: 1400
} as const;

const mobile: CreateMixinFn = (...args) => css`
  @media (max-width: ${`${Breakpoint.sm - 1}px`}) {
    ${css(...args)};
  }
`;

const lgBreakpointMinusOne = Breakpoint.lg - 1;

const tablet: CreateMixinFn = (...args) => css`
  @media (min-width: ${Breakpoint.sm}px) and (max-width: ${lgBreakpointMinusOne}px) {
    ${css(...args)};
  }
`;

export const desktop: CreateMixinFn = (...args) => css`
  @media (min-width: ${Breakpoint.lg}px) {
    ${css(...args)};
  }
`;

const mobileAndTablet: CreateMixinFn = (...args) => css`
  @media (max-width: ${lgBreakpointMinusOne}px) {
    ${css(...args)};
  }
`;

const desktopAndTablet: CreateMixinFn = (...args) => css`
  @media (min-width: ${Breakpoint.sm}px) {
    ${css(...args)};
  }
`;

export const Mixin = {
  mobile,
  tablet,
  desktop,
  mobileAndTablet,
  desktopAndTablet
} as const satisfies Record<string, CreateMixinFn>;
