import { LinkButton } from "@ds_universal/data_entry";
import React from "react";
import { useHistory } from "react-router";
import { ClaimModal } from "src/js/components/layout/ClaimModal";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import styled from "styled-components";
import GroupInviteMemberModal from "../GroupInviteMemberModal";

const StyledTooltipLinkButton = styled(LinkButton)`
  display: inline;
  color: ${({ theme }) => theme.colors.grey[500]};
  padding: 0;
`;

const StyledLinkButton = styled(LinkButton)`
  display: unset;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.grey[50]} !important;
  padding: 0;
`;

const useInviteTransComponents = () => {
  const history = useHistory();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpaceId },
    ModalStore: { openModal, closeModal }
  } = useStores();

  const openClaimModal = () => {
    closeModal();
    const openGroupCreationModal = () =>
      openModal(() => <GroupInviteMemberModal />);
    openModal(() => (
      <ClaimModal
        close={closeModal}
        header=""
        text={translate("template_claim_modal_text")}
        onGoBack={() => {
          closeModal();
          openGroupCreationModal();
        }}
      />
    ));
  };

  const transComponents = {
    ClaimSpace: <StyledLinkButton onClick={openClaimModal} />,
    ProPlan: (
      <StyledLinkButton
        onClick={() => {
          closeModal();
          history.push({
            pathname: createUrl("dashboard_settings", {
              space_id: activeSpaceId,
              option: "plan"
            })
          });
        }}
      />
    ),
    StaffPage: (
      <StyledTooltipLinkButton
        onClick={() => {
          closeModal();
          history.push({
            pathname: createUrl("dashboard_staff", {
              space_id: activeSpaceId
            })
          });
        }}
      />
    )
  };

  return {
    transComponents
  };
};

export default useInviteTransComponents;
