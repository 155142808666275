import { ObjectValues } from "./Common";

export type EmbeddableResponse = { embeddable: boolean };

export const RESOURCE_DEPRECATED_TYPE = {
  MAPS: "GOOGLE_MAPS",
  DOCUMENT: "DOCUMENT",
  SLIDESHOW: "SLIDESHOW",
  ONEDRIVE: "ONE_DRIVE",
  SPREADSHEET: "SPREADSHEET",
  GOOGLE_DRIVE: "GOOGLE_DRIVE",
  DRAWING: "DRAWING",
  IMAGEGALLERY: "IMAGEGALLERY",
  ASSIGNMENT: "ASSIGNMENT",
  BOARD: "BOARD"
} as const;

export const RESOURCE_TYPE = {
  TEXT: "TEXT",
  EXERCISE: "EXERCISE",
  EXAMINATION: "EXAMINATION",
  SCORM: "SCORM",
  FILE: "FILE",
  GOOGLE_DRIVE: "GDRIVE",
  SPREADSHEET: "SPREADSHEET",
  DOCUMENT: "DOCUMENT",
  LINK: "LINK",
  DROPBOX: "DROPBOX",
  SLIDESHOW: "SLIDESHOW",
  YOUTUBE: "YOUTUBE",
  VIMEO: "VIMEO",
  IMAGE_GALLERY: "IMAGE_GALLERY",
  BADGE: "BADGE_FILE"
} as const;

export const RESOURCE_POC_TYPE = {
  LTI: "LTI"
} as const;

export type ResourceType =
  | ObjectValues<typeof RESOURCE_TYPE>
  | ObjectValues<typeof RESOURCE_DEPRECATED_TYPE>
  | ObjectValues<typeof RESOURCE_POC_TYPE>;

export type NewResourceModel = {
  name: string;
  type: string;
  spaceSlug: string;
  content?: string;
  publicUrls?: string[] | { [propKey: string]: string };
  previewPublicUrl?: string;
  textContent?: string;
  fileUuid?: string;
  size?: number;
  fileMimeType?: string;
  applicationToken?: string;
  preview?: string;
  originalUrl?: string;
  originalName?: string;
  aiTransactionId?: string; // For resources created through AI
};

export type ResourcePreview = {
  originalUrl: string;
  large: string;
  medium: string;
  small: string;
};

export type OldResource = {
  id: number;
  user: { name: string; id: string };
  is_preview_generated: boolean;
  type: ResourceType;
  name: string;
  public_url: string;
  mime_type?: string;
  attributes: unknown;
  content: string;
  drawing_data?: unknown[];
  // This attribute only if type === "EXERCISE"
  exercise?: {
    id: string;
    success_threshold: string;
    title: string;
  };
};

export type Resource = {
  id: number;
  uuid: string;
  type: ResourceType;
  name: string;
  publicUrls?: string[];
  aiGenerated: boolean;
  preview: ResourcePreview;
  size: number;
  textContent: string;
  fileExtension: string;
  fileMimeType: string;
  exerciseId: string;
};

export type TempResource = Resource & {
  tempId?: string;
  file?: File;
};
