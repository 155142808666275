import {
  Box,
  Button,
  ContextualAlert,
  Icon,
  IconName,
  Input
} from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { generateTempResource } from "src/js/modules/fileFunction";
import { saveResourceNew } from "src/js/repository/resourceRepository";
import { Trans, TranslationKeys, useTranslation } from "src/js/translation";
import { FormInputs, RESOURCE_TYPE } from "src/js/types";
import { useTheme } from "styled-components";
import { Animations } from "src/js/settings/animations";
import { useModal } from "src/js/context/ModalProvider";
import { AnimatePresence } from "framer-motion";
import { VideoPlayer } from "src/js/components/VideoPlayer";

import {
  __ADD_LINK_RESOURCE_SETTINGS__,
  getFaviconFromUrl,
  getTranslationFromLinkType,
  linkTypeToS3Video,
  sanitizeIfVimeoUrl
} from "./AddLinkResource.utils";
import * as S from "./AddLinkResource.styles";
import { AddLinkFields, Props } from "./AddLinkResource.types";
import { RESOURCE_PICKER_ITEMS } from "../../ResourcePicker.const";

const AddLinkResource = ({
  linkType,
  title,
  onSubmit,
  onError,
  onSuccess,
  setUploadingProgress,
  onClose,
  elementIndex,
  prepareWSResource
}: Props) => {
  const { whiteTheme, greyTheme } = useTheme();
  const [originalUrl, setOriginalUrl] = useState("");
  const [informationVideoOpen, setInformationVideoOpen] = useState(false);
  const { translate } = useTranslation();
  const { handleResourceLinkUpload } = useResourceUpload(setUploadingProgress);
  const [favIcon, setFavIcon] = useState<IconName>(null);
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceSlug: spaceSlug }
  } = useStores();

  const { changeTitle, changeModalConfig } = useModal();

  const { register, handleSubmit, setValue, watch, errors } = useForm<
    FormInputs<AddLinkFields>
  >({
    defaultValues: { linkInput: "" }
  });

  const link = watch("linkInput");

  useEffect(() => {
    if (!link) {
      setFavIcon(null);
      return;
    }

    setFavIcon(getFaviconFromUrl({ url: link.toLowerCase().trim() }));
  }, [link]);

  const handleSubmission = handleSubmit(() => {
    setValue([{ linkInput: "" }]);

    const sanitizedUrl = sanitizeIfVimeoUrl({ url: originalUrl });

    const tempResource = generateTempResource({
      type: RESOURCE_TYPE.LINK,
      name: sanitizedUrl
    });
    const resourceUploadHandler = () =>
      handleResourceLinkUpload(sanitizedUrl, elementIndex);

    if (onClose) onClose();

    if (prepareWSResource) {
      prepareWSResource(tempResource, resourceUploadHandler);
      return;
    }

    if (onSubmit) onSubmit(tempResource);
    resourceUploadHandler()
      .then(response => {
        onSuccess(response);
      })
      .catch(error => {
        if (error && error.status === 403) {
          saveResourceNew({
            name: translate("private_resource_title"),
            type: "LINK",
            spaceSlug,
            publicUrls: [sanitizedUrl]
          }).then(response => {
            onSuccess(response);
          });
        } else {
          onError(error);
        }
      });
  });

  return (
    <AnimatePresence exitBeforeEnter>
      {informationVideoOpen ? (
        <S.InfoVideoWrapper key="infoVideo" {...Animations.Fade}>
          <S.VideoWrapper>
            <VideoPlayer video={linkTypeToS3Video(linkType)} />
          </S.VideoWrapper>
          <S.VideoDescription type="captionAlert">
            {translate(getTranslationFromLinkType(linkType))}
          </S.VideoDescription>
        </S.InfoVideoWrapper>
      ) : (
        <S.FormWrapper
          key="addLinkForm"
          {...Animations.Fade}
          onSubmit={handleSubmission}
          id="add-new-link-form"
        >
          <S.InputWrapper>
            <Input
              theme={isLayoutModeMobile ? greyTheme : whiteTheme}
              type="field"
              name="linkInput"
              label="linkInput"
              ref={register(__ADD_LINK_RESOURCE_SETTINGS__.linkInput)}
              error={{
                message: errors?.linkInput
                  ? translate(errors.linkInput.message as TranslationKeys)
                  : null
              }}
              value={link}
              onChange={event => {
                const url = event.target.value;
                const transformedLink = url.toLowerCase().trim();
                setValue([{ linkInput: transformedLink }]);
                setOriginalUrl(url);
              }}
              rounded
              hideLabel
              placeholder={translate("add_link_resource_input_placeholder")}
              rightSection={
                favIcon ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon
                      icon={favIcon}
                      initialViewbox
                      height={20}
                      width={20}
                    />
                  </Box>
                ) : null
              }
            />
            <S.AlertWrapper>
              <ContextualAlert
                theme={whiteTheme}
                text={
                  <Trans
                    i18nKey={
                      linkType === RESOURCE_PICKER_ITEMS.LINK
                        ? "add_link_modal_contextual_warning"
                        : "add_link_modal_contextual_warning_provider"
                    }
                    components={{
                      LinkToVideo: (
                        <S.VideoLinkButton
                          type="button"
                          onClick={() => {
                            setInformationVideoOpen(true);
                            changeTitle(
                              translate("add_link_modal_privacy_title")
                            );
                            changeModalConfig({
                              mobileProps: {
                                onClose: () => {
                                  setInformationVideoOpen(false);
                                  changeTitle(translate(title));
                                  changeModalConfig(undefined);
                                },
                                title: translate(
                                  "add_link_modal_privacy_title"
                                ),
                                closeLabel: translate("back")
                              },
                              desktopProps: {
                                onBack: () => {
                                  changeModalConfig(undefined);
                                  changeTitle(translate(title));
                                  setInformationVideoOpen(false);
                                }
                              }
                            });
                          }}
                        />
                      )
                    }}
                  />
                }
              />
            </S.AlertWrapper>
          </S.InputWrapper>
          <S.ButtonWrapper>
            {onClose ? (
              <Button
                theme={whiteTheme}
                variant="secondary"
                type="button"
                onClick={onClose}
              >
                {translate("add_link_resource_cancel_cta")}
              </Button>
            ) : null}
            <Button theme={whiteTheme} variant="primary" type="submit">
              {translate("add_link_resource_submit_cta")}
            </Button>
          </S.ButtonWrapper>
        </S.FormWrapper>
      )}
    </AnimatePresence>
  );
};

export default AddLinkResource;
