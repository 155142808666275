/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route, Switch } from "react-router";
import { SettingsLayout } from "src/js/layout/SettingsLayout";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { NotificationPage } from "./NotificationPage";
import { AccountPage } from "./AccountPage";
import { SettingsPage } from "./SettingsPage";
import { TipsPage } from "./TipsPage";
import { ProfilePage } from "./ProfilePage";
import { ProfileEditPage } from "./ProfileEditPage";

export const SettingsRouter = () => {
  return (
    <SettingsLayout>
      <Switch>
        <Route
          path={WeSchoolRoutes.App.Settings.initialRouteName}
          exact
          component={SettingsPage}
        />
        <Route
          path={WeSchoolRoutes.App.Settings.settingsAccount}
          component={AccountPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Settings.settingsProfile}
          component={ProfilePage}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Settings.settingsProfileEdit}
          component={ProfileEditPage}
        />
        <Route
          path={WeSchoolRoutes.App.Settings.settingsNotifications}
          component={NotificationPage}
        />
        <Route
          path={WeSchoolRoutes.App.Settings.settingsTips}
          component={TipsPage}
        />
        <Route
          render={props => (
            <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
          )}
        />
      </Switch>
    </SettingsLayout>
  );
};
