/* eslint-disable react/no-unstable-nested-components */
import { observer } from "mobx-react";
import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import { LoadingCard } from "src/js/components/LoadingCard";
import { useStores } from "src/js/hooks";
import { SpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import Boards from "src/js/pages/boards/Boards";
import { Calendar } from "src/js/pages/calendar";
import { EventActivity } from "src/js/pages/event_activity";
import { EventDeadline } from "src/js/pages/event_dedline";
import { EventLive } from "src/js/pages/event_live";
import GroupSettings from "src/js/pages/group/settings/GroupSettings";
import { GroupOverquotaBlocked } from "src/js/pages/group_overquota_blocked";
import Whiteboard from "src/js/pages/whiteboard/Whiteboard";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { GroupSectionsEnum, GroupSectionsRouteMatchEnum } from "src/js/types";
import GroupController from "src/legacy/controller/GroupController";
import LoggedController from "src/legacy/controller/LoggedController";
import styled from "styled-components";
import { GroupCustomFieldsBlockedModal } from "src/js/components/GroupCustomFieldsBlockedModal";
import { useSetupSpaceGroup } from "./hooks";

const StyledLoadingCard = styled(LoadingCard)`
  margin: 80px auto auto;
  max-width: 680px;
  @media (max-width: 767px) {
    margin: 80px auto 0;
  }
`;

export const SpacesGroupRouter = observer(() => {
  const { pathname } = useLocation();

  const {
    GroupStore: {
      groupId,
      isGroupSectionVisible,
      settings,
      isWhiteboardEnabled,
      activeGroup,
      activeGroupFull
    },
    ModalStore: { openModal }
  } = useStores();

  useSetupSpaceGroup();

  const sectionRouteMatch = Object.values(GroupSectionsEnum).find(section =>
    useRouteMatch(GroupSectionsRouteMatchEnum[section])
  );

  if (sectionRouteMatch && settings) {
    const isExerciseRun =
      sectionRouteMatch === GroupSectionsEnum.Exercises &&
      pathname.includes("run");
    const shouldShowSection =
      isGroupSectionVisible(sectionRouteMatch) || isExerciseRun;
    const isWhiteboardDisabled =
      sectionRouteMatch === GroupSectionsEnum.Whiteboard &&
      !isWhiteboardEnabled;

    if (!shouldShowSection || isWhiteboardDisabled) {
      const sectionRedirect = Object.values(GroupSectionsEnum).find(
        section =>
          isGroupSectionVisible(section) &&
          section !== GroupSectionsEnum.Whiteboard
      );
      return (
        <Route
          render={props => (
            <RedirectToLandingUrlOrDefault
              {...props}
              to={createUrl(sectionRedirect, {
                group_id: groupId
              })}
            />
          )}
        />
      );
    }
  }

  if (activeGroup?.cloningCompleted === false) {
    return (
      <SpaceGroupsLayout>
        <StyledLoadingCard
          title="group_loading_card_title"
          subtitle="group_loading_card_subtitle"
        />
      </SpaceGroupsLayout>
    );
  }

  return (
    <SpaceGroupsLayout>
      {activeGroupFull.missingCustomFields &&
        openModal(() => <GroupCustomFieldsBlockedModal />)}
      <Switch>
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.settings}
          exact
          component={GroupSettings}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.groupOverquotaBlocked}
          exact
          component={GroupOverquotaBlocked}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.exerciseRun}
          exact
          render={props => <GroupController {...props} />}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.exerciseInstantLive}
          exact
          render={props => <LoggedController {...props} />}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.exerciseInstant}
          exact
          render={props => <GroupController {...props} />}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.whiteboard}
          exact
          component={Whiteboard}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.calendar}
          exact
          key="calendarSpaceGroup"
          component={Calendar}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.calendarAgenda}
          exact
          component={Calendar}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.calendarActivity}
          key="eventActivity"
          component={EventActivity}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.calendarLive}
          key="eventLive"
          component={EventLive}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.modules}
          exact
          component={Boards}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.calendarDeadline}
          key="eventDeadline"
          component={EventDeadline}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.initialRouteName}
          render={props => <LoggedController {...props} />}
        />
        <Route
          render={props => (
            <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
          )}
        />
      </Switch>
    </SpaceGroupsLayout>
  );
});
