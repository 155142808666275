import { Avatar, Icon, Text, ToolbarSpaceItem } from "@arcadia/design-system";
import React from "react";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

import { observer } from "mobx-react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useStores } from "src/js/hooks";
import {
  __MAINNET_CHAIN_ICONS__,
  __MAINNET_CHAIN_IDS__
} from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import { UserAwardedBadgeType } from "src/js/types";
import * as S from "./AwardedBadgeCard.styles";

const AwardedBadgeCard = ({
  badge,
  hideAwardedBadge,
  hasBorder
}: {
  badge: UserAwardedBadgeType;
  hideAwardedBadge: () => void;
  hasBorder?: boolean;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpace }
  } = useStores();

  const handleBadgeImage = (awardedNFT: boolean, chainId: string) => {
    if (awardedNFT) {
      const tooltipString = `${translate(
        "emit_nft_to_students_selected_chain_in_footer",
        { chainId: __MAINNET_CHAIN_IDS__[chainId]?.toLowerCase() }
      )}`;
      if (__MAINNET_CHAIN_ICONS__[chainId]) {
        return (
          <TranslatedTooltip
            tooltipString={tooltipString as TranslationKeys}
            placement="top"
          >
            <S.NFTChainIconContainer>
              <Icon
                icon={__MAINNET_CHAIN_ICONS__[chainId]}
                width={12}
                height={12}
                initialViewbox
              />
            </S.NFTChainIconContainer>
          </TranslatedTooltip>
        );
      }
      return (
        <S.NFTLabelContainer>
          <Text type="label">NFT</Text>
        </S.NFTLabelContainer>
      );
    }
    return null;
  };

  return (
    <S.CardWrapper hasBorder={hasBorder}>
      <S.GroupCoverWrapper>
        {handleBadgeImage(!!badge.nftReference, badge.chainId)}
        <Avatar
          theme={whiteTheme}
          url={badge.image}
          alt="badge-logo"
          size="l"
          rounded={false}
        />
        <S.AvatarWrapper>
          <ToolbarSpaceItem
            id="space-badge"
            theme={whiteTheme}
            badge={activeSpace?.space?.badge?.small as string}
            backgroundColorCode={activeSpace?.space?.defaultBadgeColor}
            spaceName={activeSpace?.space?.name || ""}
            isSelected={false}
            size="auto"
          />
        </S.AvatarWrapper>
      </S.GroupCoverWrapper>
      <S.BodySectionWrapper>
        <S.TextSectionWrapper hasBorder={hasBorder}>
          <Text type="formField">{badge.name}</Text>
          <S.GreyText type="formDescription">{badge.description}</S.GreyText>
        </S.TextSectionWrapper>
        <S.ButtonsWrapper>
          <S.StyledLinkButton
            onClick={() => {
              window.open(badge.openBadgePublicUrl, "_blank");
              hideAwardedBadge();
            }}
          >
            {translate("user_awarded_badge_navigate_cta")}
          </S.StyledLinkButton>
          <S.StyledLinkButton isReject onClick={() => hideAwardedBadge()}>
            {translate("close")}
          </S.StyledLinkButton>
        </S.ButtonsWrapper>
      </S.BodySectionWrapper>
    </S.CardWrapper>
  );
};

export default observer(AwardedBadgeCard);
