import { Box, Icon, ToolbarMenuItem } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";
import { useSpaceByIdCached } from "src/js/query";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { getAppRoutingContext } from "src/js/routing/appRoutingContext";
import { useTheme } from "styled-components";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import * as S from "./Toolbar.styles";
import { ChatButtonItem } from "./ChatButtonItem";
import { ToolbarActiveSpace } from "../ToolbarActiveSpace";
import { WeschoolMonogram } from "../WeschoolMonogram";

const LogoRedirectUrl = "https://www.weschool.com/resources";

const Toolbar = () => {
  const {
    SpaceStore: { activeSpace, activeSpaceSlug },
    DemoSpaceStore: { isDemoSpaceTopBannerVisible }
  } = useStores();
  const { whiteTheme } = useTheme();

  const location = useLocation();
  const context = getAppRoutingContext(location);

  const openNewTab = () => {
    window.open(LogoRedirectUrl, "_blank");
  };

  const { data } = useSpaceByIdCached(activeSpaceSlug, {
    enabled: !!activeSpaceSlug
  });

  return (
    <S.ToolbarContainer
      isDemoSpaceTopBannerVisible={isDemoSpaceTopBannerVisible}
      id={UsetifulId.Toolbar}
    >
      <Box id={UsetifulId.ToolbarActiveSpaceAndActions}>
        <ToolbarActiveSpace />
        <S.ToolbarMenu>
          <ToolbarMenuItem
            icon={<Icon icon="home" height={24} width={24} />}
            onClick={() => {
              if (activeSpace) {
                navigateTo(
                  createUrl("space_groups", {
                    space_slug: activeSpace.space.slug
                  })
                );
                return;
              }
              navigateTo(createUrl("home"));
            }}
            theme={whiteTheme}
            id={UsetifulId.ToolbarHomeLink}
            isDisabled={context === "home"}
            isSelected={context === "groups"}
          />
          {!data?.spaceSettings.disableChat && <ChatButtonItem />}
          {/* Hidden until it is functional */}
          {/* <ToolbarMenuItem
          icon={<Icon icon="calendar" height={22} width={22} />}
          onClick={() => {}}
          theme={whiteTheme}
          id="toolbarCalendarLink"
        /> */}
        </S.ToolbarMenu>
      </Box>

      <S.ToolbarFooter>
        <S.ToolbarLogo>
          <WeschoolMonogram onClick={openNewTab} />
        </S.ToolbarLogo>
      </S.ToolbarFooter>
    </S.ToolbarContainer>
  );
};

export default observer(Toolbar);
