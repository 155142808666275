import {
  Box,
  Button,
  Heading,
  Icon,
  Text,
  Toggle
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { useGroupFeatures, useSpacePlanType, useStores } from "src/js/hooks";
import getUserClient from "src/js/modules/connection";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  api_groups_settings,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  GroupAccess,
  GroupAccessType,
  GroupFeatures,
  GroupSectionsEnum,
  GroupSectionsType,
  GroupSettingsEvent,
  GroupSettingsVisibilityEnum,
  GroupVisibilityEnum,
  SpaceUserRoleEnum
} from "src/js/types";
import { useTheme } from "styled-components";
import { useRouteMatch } from "react-router";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { useSpaceByIdCached } from "src/js/query";
import { editGroupInfo } from "src/js/repository/groupRepository";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { InfoTooltip } from "src/js/components/InfoTooltip";
import { DisabledSectionWrapper } from "src/js/components/DisabledSectionWrapper";
import {
  GROUP_SETTINGS_ACCESS_SECTIONS,
  GROUP_SETTINGS_FEATURES,
  GROUP_SETTINGS_SECTIONS
} from "./GroupSettingsSections.const";
import * as S from "./GroupSettingsSections.styles";

const GroupSettingsSections = () => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: {
      groupId,
      settings,
      setActiveGroupFull,
      setActiveGroupSettings,
      isGroupSectionVisible,
      isWhiteboardEnabled,
      activeGroup
    },
    SpaceStore: { isClaimable, isClaimed }
  } = useStores();
  const { onChangeFeature, loading, ...groupFeaturesVisibility } =
    useGroupFeatures();
  const { isPlanProOrEnterprise } = useSpacePlanType({
    groupCount: 1
  });
  const { whiteTheme: theme } = useTheme();
  const { translate } = useTranslation();
  const [isSectionRequestPending, setIsSectionRequestPending] = useState(false);
  const { params } = useRouteMatch<{ space_slug?: string }>({
    path: WeSchoolRoutes.App.Spaces.SpaceGroup.groupId
  });

  const { data: space } = useSpaceByIdCached(params?.space_slug, {
    enabled: !!params?.space_slug
  });

  const isSpaceAdmin = useMemo(
    () =>
      space?.role === SpaceUserRoleEnum.Owner ||
      space?.role === SpaceUserRoleEnum.Admin,
    [space?.role]
  );

  const groupSectionsVisibility = Object.values(GroupSectionsEnum).reduce(
    (acc, key) => {
      acc[key] = isGroupSectionVisible(key);
      return acc;
    },
    {} as Record<GroupSectionsType, boolean>
  );

  const handleSectionAccess = (id: GroupAccessType) => {
    if ((!isPlanProOrEnterprise || isClaimable) && !isClaimed) {
      return;
    }
    if (id === GroupAccess.SelfJoinable) {
      editGroupInfo({
        id: groupId,
        selfJoinable: !activeGroup.selfJoinable
      })
        .then(data => {
          setActiveGroupFull(data);
          showToastSuccess({
            str: translate("toast_group_settings_self_joinable_success")
          });
          _trackEvent(
            EventDomain.GroupSettingsUsage,
            activeGroup.selfJoinable
              ? GroupSettingsEvent.UnSetSelfJoinableGroupSettings
              : GroupSettingsEvent.SetSelfJoinableGroupSettings
          );
        })
        .catch(error => {
          showToastError({ str: error.message });
        });
    }
  };

  const handleSectionVisibility = (section: GroupSectionsType) => {
    if (isSectionRequestPending) {
      return;
    }
    const countSectionsVisible = Object.values(groupSectionsVisibility).filter(
      value => value
    ).length;
    if (
      countSectionsVisible === 2 &&
      groupSectionsVisibility[section] &&
      groupSectionsVisibility[GroupSectionsEnum.Whiteboard] &&
      section !== GroupSectionsEnum.Whiteboard
    ) {
      showToastError({
        str: translate("group_settings_section_message_whiteboard_error")
      });
      return;
    }
    if (countSectionsVisible === 1 && groupSectionsVisibility[section]) {
      showToastError({
        str: translate("group_settings_section_message_hidden_error")
      });
      return;
    }

    const visibilityKey = GroupSettingsVisibilityEnum[section];
    setIsSectionRequestPending(true);
    getUserClient()
      .patch(getVersionedApiUrl(api_groups_settings({ groupId }), "v3"), {
        [visibilityKey]: groupSectionsVisibility[section]
          ? GroupVisibilityEnum.Hidden
          : GroupVisibilityEnum.Visible
      })
      .finally(() => setIsSectionRequestPending(false))
      .then(({ data }) => {
        setActiveGroupSettings({ ...settings, ...data });
        showToastSuccess({
          str: translate("group_settings_section_message_hidden_success")
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(error, translate("general_error_retry"))
        });
      });
  };

  const groupSettingSections = isWhiteboardEnabled
    ? GROUP_SETTINGS_SECTIONS
    : GROUP_SETTINGS_SECTIONS.filter(
        ({ id }) => id !== GroupSectionsEnum.Whiteboard
      );

  return (
    <S.OuterWrapper>
      <S.SectionWrapper>
        <S.HeadingWrapper>
          <Heading level="5">
            {translate("group_settings_access_title")}
          </Heading>
        </S.HeadingWrapper>
        <Text type={isLayoutModeMobile ? "formTitle" : "formField"}>
          {translate("group_settings_access_subtitle")}
        </Text>
        <S.ListWrapper>
          {settings
            ? GROUP_SETTINGS_ACCESS_SECTIONS.map(section => (
                <DisabledSectionWrapper
                  key={`section-${section.id}`}
                  disabled={
                    (!isPlanProOrEnterprise || isClaimable) && !isClaimed
                  }
                  tooltipContent={
                    <Text type="formDescription">
                      {translate(
                        "tooltip_group_self_joinable_require_pro_plan"
                      )}
                    </Text>
                  }
                >
                  <S.SectionCard>
                    <S.SectionCardContent>
                      <S.IconBox width={40} height={40}>
                        <Icon
                          initialViewbox
                          icon={section.icon}
                          width={14}
                          height={14}
                        />
                      </S.IconBox>
                      <S.SectionCardContentText>
                        <Box display="flex" gap="4px">
                          <S.StyledSectionTitle type="subHeaderSubtitle">
                            {translate(section.title)}
                          </S.StyledSectionTitle>
                          {section.tooltip ? (
                            <InfoTooltip
                              side={isLayoutModeMobile ? undefined : "right"}
                              customTooltipProps={{
                                title: translate(section.tooltip.title),
                                description: translate(
                                  section.tooltip.description
                                )
                              }}
                            />
                          ) : null}
                        </Box>
                        <S.StyledSectionDescription>
                          <Text
                            type={
                              isLayoutModeMobile
                                ? "formSmallCaption"
                                : "formDescription"
                            }
                          >
                            {translate(section.description)}
                          </Text>
                        </S.StyledSectionDescription>
                      </S.SectionCardContentText>
                    </S.SectionCardContent>
                    <S.ToggleWrapper>
                      <Toggle
                        data-testid={`toggle-section-${section.id}`}
                        theme={theme}
                        title={`Disable ${section.id}`}
                        onChange={() => handleSectionAccess(section.id)}
                        checked={activeGroup.selfJoinable}
                        size="small"
                      />
                    </S.ToggleWrapper>
                  </S.SectionCard>
                </DisabledSectionWrapper>
              ))
            : null}
        </S.ListWrapper>
      </S.SectionWrapper>
      <S.SectionWrapper>
        <S.HeadingWrapper>
          <Heading level="5">
            {translate("group_settings_sections_title")}
          </Heading>
        </S.HeadingWrapper>
        <Text type={isLayoutModeMobile ? "formTitle" : "formField"}>
          {translate("group_settings_sections_subtitle")}
        </Text>
        <S.ListWrapper>
          {settings
            ? groupSettingSections.map(section => (
                <S.SectionCard key={`section-${section.id}`}>
                  <S.SectionCardContent>
                    <S.IconBox>
                      <Icon
                        initialViewbox
                        icon={section.icon}
                        width={20}
                        height={20}
                      />
                    </S.IconBox>
                    <S.SectionCardContentText>
                      <S.StyledSectionTitle type="subHeaderSubtitle">
                        {translate(section.title)}
                      </S.StyledSectionTitle>
                      <S.StyledSectionDescription>
                        <Text
                          type={
                            isLayoutModeMobile
                              ? "formSmallCaption"
                              : "formDescription"
                          }
                        >
                          {translate(section.description)}
                        </Text>
                      </S.StyledSectionDescription>
                    </S.SectionCardContentText>
                  </S.SectionCardContent>
                  <S.ToggleWrapper>
                    <Toggle
                      data-testid={`toggle-section-${section.id}`}
                      theme={theme}
                      title={`Disable ${section.id}`}
                      onChange={() => handleSectionVisibility(section.id)}
                      checked={groupSectionsVisibility[section.id]}
                      size="small"
                    />
                  </S.ToggleWrapper>
                </S.SectionCard>
              ))
            : null}
        </S.ListWrapper>
      </S.SectionWrapper>
      <S.SectionWrapper>
        <S.HeadingWrapper>
          <Heading level="5">
            {translate("group_settings_features_title")}
          </Heading>
        </S.HeadingWrapper>
        <Text type={isLayoutModeMobile ? "formTitle" : "formField"}>
          {translate("group_settings_features_subtitle")}
        </Text>
        <S.ListWrapper>
          {settings &&
            GROUP_SETTINGS_FEATURES.map(section => (
              <S.SectionCard key={`section-${section.id}`}>
                <S.SectionCardContent>
                  <S.IconBox>
                    <Icon
                      initialViewbox
                      icon={section.icon}
                      width={20}
                      height={20}
                    />
                  </S.IconBox>
                  <S.SectionCardContentText>
                    <S.StyledSectionTitle type="subHeaderSubtitle">
                      {translate(section.title)}
                    </S.StyledSectionTitle>
                    <S.StyledSectionDescription>
                      <Text
                        type={
                          isLayoutModeMobile
                            ? "formSmallCaption"
                            : "formDescription"
                        }
                      >
                        {translate(section.description)}
                      </Text>
                    </S.StyledSectionDescription>
                    <S.StyledSectionMessage>
                      {section.id === GroupFeatures.DisableChat && (
                        <Text type="formSmallCaption">
                          {isSpaceAdmin
                            ? translate("group_settings_dm_message_owner")
                            : translate("group_settings_dm_message_locked")}
                        </Text>
                      )}
                    </S.StyledSectionMessage>
                  </S.SectionCardContentText>
                </S.SectionCardContent>
                {section.id === GroupFeatures.DisableChat && isSpaceAdmin ? (
                  <Button
                    theme={theme}
                    variant="primary"
                    onClick={() => {
                      if (!space?.space.id) return;
                      navigateTo(
                        createUrl("dashboard_settings", {
                          space_id: space.space.id,
                          option: "customization"
                        })
                      );
                    }}
                  >
                    {translate("group_settings_dm_manage_cta")}
                  </Button>
                ) : (
                  <S.ToggleWrapper>
                    <Toggle
                      theme={theme}
                      title={`Disable ${section.id}`}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onChangeFeature(section.id, event.target.checked);
                      }}
                      disabled={
                        loading === section.id ||
                        section.id === GroupFeatures.DisableChat
                      }
                      checked={!groupFeaturesVisibility[section.id]}
                      size="small"
                    />
                  </S.ToggleWrapper>
                )}
              </S.SectionCard>
            ))}
        </S.ListWrapper>
      </S.SectionWrapper>
    </S.OuterWrapper>
  );
};

export default observer(GroupSettingsSections);
