import {
  Box,
  colors,
  Heading,
  Input,
  Text,
  Toggle
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { InfoTooltip } from "src/js/components/InfoTooltip";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores, useToastMessage } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { copyToClipboard } from "src/js/modules/commonFunction";
import createUrl from "src/js/modules/routing";
import { __LINK_URL_BASE__ } from "src/js/settings/settingsUrl";
import { useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  EventDomain,
  ToastMessage,
  ToastVariant
} from "src/js/types";
import styled, { DefaultTheme } from "styled-components";
import {
  demoNoInvitationWarningBanner,
  temporaryNoInvitationWarningBanner
} from "../GroupSettingsMembers/translations";
import FakeInput from "./components/FakeInput";
import InputEditGroupAlias from "./components/InputEditGroupAlias";
import { useLinkInvite } from "./hooks";

export const StyledInput = styled(Input)<{ error?: boolean }>`
  & > div > input {
    border: ${({ error }) =>
      error ? `1px solid ${colors.error[400]}` : undefined};
  }
`;

const SectionInviteLink = ({
  theme,
  isMultiGroupMode
}: {
  theme: DefaultTheme;
  isMultiGroupMode?: boolean;
}) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { alias: groupAlias, publicInvitation },
    UIStore: { isLayoutModeMobile },
    SpaceStore: { isDemoSpace, activeSpace }
  } = useStores();
  const { togglePublicInvite } = useLinkInvite();

  const [isLoading, setIsLoading] = useState(false);
  const isPublicInvitationLoading = publicInvitation === undefined;

  const isInvitationAllowed =
    (!isDemoSpace && !activeSpace?.isTemporary) || isMultiGroupMode;
  const isInvitationLinkDisabled = !isInvitationAllowed || !publicInvitation;
  const isVisualDisabled = isMultiGroupMode;
  const showSkeleton = !isVisualDisabled && isPublicInvitationLoading;

  const urlToCopy = `${__LINK_URL_BASE__}#${createUrl("join", {
    alias_id: groupAlias
  })}`;

  const initialMessages = useMemo(() => {
    const messages: ToastMessage[] = [];
    if (isMultiGroupMode) {
      messages.push({
        variant: ToastVariant.Alert,
        children: translate("invite_modal_link_invite_toast_only_single_group")
      });
      return messages;
    }
    if (isDemoSpace) {
      messages.push({
        variant: ToastVariant.Warning,
        props: {
          text: demoNoInvitationWarningBanner.text,
          cta: demoNoInvitationWarningBanner.cta
        }
      });
      return messages;
    }
    if (activeSpace?.isTemporary) {
      messages.push({
        variant: ToastVariant.Warning,
        props: {
          text: temporaryNoInvitationWarningBanner.text,
          cta: temporaryNoInvitationWarningBanner.cta
        }
      });
      return messages;
    }

    return messages;
  }, [isDemoSpace]);

  const {
    toastMessages,
    toastMessageNodes,
    addToastMessage,
    removeToastMessage
  } = useToastMessage(initialMessages);

  useEffect(() => {
    _trackEvent(
      EventDomain.AddMember,
      AddMemberEvent.LinkInviteSectionDisplayed
    );
  }, []);

  useEffect(() => {
    if (
      isInvitationAllowed &&
      !isPublicInvitationLoading &&
      isInvitationLinkDisabled
    ) {
      addToastMessage({
        id: "invite-link-disabled",
        variant: ToastVariant.Alert,
        children: translate("invite_modal_link_invite_toast_disabled")
      });
    } else {
      removeToastMessage("invite-link-disabled");
    }
  }, [
    isInvitationAllowed,
    isInvitationLinkDisabled,
    isPublicInvitationLoading
  ]);

  const handleTextCopy = (text: string, toast?: ToastMessage) => {
    copyToClipboard(text, false);
    if (toast) {
      addToastMessage(toast);
    }
  };

  const handleTogglePublicInvite = async () => {
    setIsLoading(true);
    try {
      await togglePublicInvite();
    } finally {
      setIsLoading(false);
    }
  };

  const toggleText = !isInvitationLinkDisabled
    ? translate("invite_modal_link_invite_disable")
    : translate("invite_modal_link_invite_enable");

  return (
    <Box display="flex" flexDirection="column" gap="4px" paddingBottom="20px">
      <Heading level="5">{translate("invite_modal_link_invite_title")}</Heading>
      <Text type="formSubtitle">
        {translate("invite_modal_link_invite_description")}
      </Text>
      <Box display="flex" alignItems="center" marginTop="16px">
        <Box marginRight="12px">
          <Skeleton loading={showSkeleton} borderRadius={20}>
            <Toggle
              disabled={!isInvitationAllowed || isLoading || isVisualDisabled}
              theme={theme}
              title={toggleText}
              onChange={handleTogglePublicInvite}
              checked={!isInvitationLinkDisabled}
              size="small"
            />
          </Skeleton>
        </Box>
        <Box display="flex" alignItems="center" gap="4px">
          <Skeleton loading={showSkeleton} borderRadius={20}>
            <Text type="subHeaderSubtitle">{toggleText}</Text>
          </Skeleton>
          <Skeleton loading={showSkeleton} borderRadius={100}>
            <InfoTooltip
              side={isLayoutModeMobile ? "bottom" : "right"}
              customTooltipProps={{
                title: translate(
                  "invite_modal_link_invite_tooltip_toggle_title"
                ),
                description: translate(
                  "invite_modal_link_invite_tooltip_toggle_description"
                )
              }}
            />
          </Skeleton>
        </Box>
      </Box>
      {toastMessages?.length > 0 ? (
        <Box marginTop="8px" display="flex" flexDirection="column" gap="8px">
          {toastMessageNodes()}
        </Box>
      ) : null}
      <Box display="flex" flexDirection="column" gap="8px" marginTop="16px">
        <Heading level="6">
          {translate("invite_modal_link_invite_group_code")}
        </Heading>
        <InputEditGroupAlias
          theme={theme}
          isDisabled={isInvitationLinkDisabled}
          handleTextCopy={handleTextCopy}
          isVisualDisabled={isVisualDisabled}
        />
        <Box display="flex" alignItems="center" gap="4px">
          <Text type="formSubtitle">
            {translate("invite_modal_link_invite_group_code_description")}
          </Text>
          <InfoTooltip
            side={isLayoutModeMobile ? "bottom" : "right"}
            customTooltipProps={{
              title: translate(
                "invite_modal_link_invite_tooltip_group_code_title"
              ),
              description: translate(
                "invite_modal_link_invite_tooltip_toggle_description"
              )
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="8px" marginTop="32px">
        <Heading level="6">
          {translate("invite_modal_link_invite_invitation_link")}
        </Heading>
        <FakeInput
          disabled={isInvitationLinkDisabled}
          onCopy={() => {
            _trackEvent(
              EventDomain.AddMember,
              AddMemberEvent.InvitationLinkCopied
            );
            handleTextCopy(urlToCopy, {
              variant: ToastVariant.Success,
              id: "invite-link-toast",
              duration: 2000,
              children: translate("invite_modal_link_invite_toast_copied")
            });
          }}
        >
          {!isVisualDisabled
            ? urlToCopy
            : translate("invite_modal_link_invite_invitation_link_disabled")}
        </FakeInput>
        <Text type="formSubtitle">
          {translate("invite_modal_link_invite_invitation_link_description")}
        </Text>
      </Box>
    </Box>
  );
};

export default observer(SectionInviteLink);
