import React, { useCallback, useEffect, useRef, useState } from "react";

import { UploadError } from "src/js/components/UploadError";
import { observer } from "mobx-react";
import {
  LMSCommitHandler,
  LMSFinishHandler,
  readySCORMHandler
} from "src/js/modules/SCORMFunction";
import { __SCORM_PLAYER_URL__ } from "src/js/settings/settingsUrl";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import {
  SCORM2004Methods,
  SCORMErrorType,
  SCORMEventData,
  SCORMMethods,
  SCORMPlayerStatus
} from "src/js/types/models/Scorm";
import { OldResource, Resource } from "src/js/types";
import { useMeQueryCached } from "src/js/query";

const ErrorMessage: Record<SCORMErrorType & "default", TranslationKeys> = {
  [SCORMErrorType.ManifestNotFound]: "scorm_broken_manifest_not_found",
  [SCORMErrorType.ExtensionNotValid]: "scorm_broken_extension_not_valid",
  [SCORMErrorType.VersionNotCompatible]: "scorm_broken_version_not_valid",
  default: "scorm_broken_generic_error"
};

const handleError = (type: SCORMErrorType) =>
  ErrorMessage[type] || ErrorMessage["default"];

const Scorm = ({
  boardElementId,
  resource,
  onClose
}: {
  boardElementId: number;
  resource: (OldResource & { url: string }) | Resource;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TranslationKeys>(null);
  const { greyTheme } = useTheme();

  const scormIframe = useRef<HTMLIFrameElement>(null);

  const { data: activeUser } = useMeQueryCached();
  const { translate } = useTranslation();

  let publicUrl = "url" in resource ? resource.url : "";
  publicUrl = "public_url" in resource ? resource.public_url : publicUrl;
  publicUrl = "publicUrls" in resource ? resource.publicUrls[0] : publicUrl;

  const handleEvent = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== __SCORM_PLAYER_URL__) return;

      const req: SCORMEventData = JSON.parse(event.data);

      if (req.method === SCORMPlayerStatus.Ready) {
        readySCORMHandler({
          boardElementId: `${boardElementId}`,
          userId: activeUser.id,
          username: `${activeUser.name} ${activeUser.surname}`,
          iframe: scormIframe,
          version: req.data.version
        });
      }
    },
    [boardElementId, activeUser]
  );

  useEffect(() => {
    if (!scormIframe.current) return;

    const eventListeners = (event: MessageEvent) => {
      if (event.origin !== __SCORM_PLAYER_URL__) return;
      const req: SCORMEventData = JSON.parse(event.data);
      switch (req.method) {
        case SCORMPlayerStatus.Error:
          setError(handleError(req.data.type));
          break;
        case SCORM2004Methods.Terminate:
        case SCORMMethods.LMSFinish:
          LMSFinishHandler({
            boardElementId: `${boardElementId}`,
            data: req.data.CMIData
          });
          break;
        case SCORM2004Methods.Commit:
        case SCORMMethods.LMSCommit:
          LMSCommitHandler({
            boardElementId: `${boardElementId}`,
            data: req.data.CMIData
          });
          break;
        default:
      }
    };
    window.addEventListener("message", handleEvent);
    window.addEventListener("message", eventListeners, false);

    return () => {
      setError(null);
      window.removeEventListener("message", handleEvent);
      window.removeEventListener("message", eventListeners, false);

      if (!scormIframe?.current) return;
      const closeJson = { method: "LMSExit" };
      scormIframe.current.contentWindow.postMessage(
        JSON.stringify(closeJson),
        "*"
      );
    };
  }, [handleEvent, publicUrl]);

  // TODO since we changed the way the player get the loading/error status, update this code and the player

  if (!publicUrl) {
    return (
      <div className="text-center">{translate("general_error_message")}</div>
    );
  }

  return (
    <>
      {loading && <DoodleLoader theme={greyTheme} />}
      {error && (
        <UploadError
          title="upload_error_page_title"
          text={error}
          elementId={boardElementId}
          onClose={onClose}
        />
      )}

      <div className="full-height">
        <iframe
          key={publicUrl}
          title={`${resource.id}`}
          id="link-player"
          allow="autoplay"
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          ref={scormIframe}
          src={publicUrl}
        />
      </div>
    </>
  );
};

export default observer(Scorm);
