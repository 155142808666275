import { useCallback, useEffect } from "react";
import {
  WebSocketDomain,
  WebSocketEvent,
  WebSocketMessage,
  WebSocketMessageData
} from "src/js/types/models/WebSocket";

export const extractWebSocketMessage = <T extends WebSocketEvent>(
  msg: MessageEvent<string>
) => {
  const newMessage = JSON.parse(msg.data) as WebSocketMessage<
    WebSocketMessageData<T>
  >;
  if (newMessage?.data?.success) return null;

  const socketEventType = `${newMessage.domain}${newMessage.event}`;

  return { socketEventType, newMessage };
};

const useSocket = <T extends WebSocketEvent>({
  socket,
  domain,
  event,
  handler
}: {
  socket: WebSocket;
  domain: WebSocketDomain;
  event: T;
  handler: (message: WebSocketMessage<WebSocketMessageData<T>>) => void;
}) => {
  const eventType = `${domain}${event}`;
  const onMessage = useCallback(
    (msg: MessageEvent<string>) => {
      const extractedData = extractWebSocketMessage<T>(msg);
      if (!extractedData) return;

      const { socketEventType, newMessage } = extractedData;

      if (eventType === socketEventType) {
        handler(newMessage);
      }
    },
    [handler]
  );

  useEffect(() => {
    socket?.addEventListener("message", onMessage);
    return () => {
      socket?.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
};

export default useSocket;
