import {
  ActionButton,
  Box,
  Button,
  ConfirmationAlert,
  ContextualAlert,
  Heading,
  Label,
  Text
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import "jquery-ui";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import maxBy from "lodash/maxBy";
import range from "lodash/range";
import { observer } from "mobx-react";
import React from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useStores } from "src/js/hooks";
import { withConfirmDialogStore, withSpaceStore } from "src/js/hooks/useStores";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import ThemeProvider from "src/js/theme/ThemeProvider";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { css, withTheme } from "styled-components";
import withBackbone from "with-backbone";
import CircleProgress from "../../components/circleProgress";
import ExerciseModel from "../../models/exerciseModel";
import { modalMessage } from "../../models/modalMessageModel";
import { getActiveGroup, getActiveGroupId } from "../../modules/activeGroup";
import { getActiveUser } from "../../modules/activeUser";
import { InternalDispatcher } from "../../modules/dispatcher";
import { navigateTo } from "../../modules/history";
import { __ } from "../../modules/localization";
import {
  convertSecondsToFormattedString,
  createURL
} from "../../modules/utility";
import { __EXERCISE_MAX_RATE__ } from "../../settings/settings";
import EserciziReportsUsersItem from "./report/exerciseReportUsersDetail";

const StyledHistogramBar = styled.div`
  .exercise-stats-histogram-bar {
    background: ${({ theme }) => theme.primaryColor[500]};
  }
  display: flex;
  justify-content: space-between;
`;

const StyledHistogramLabel = styled(Text)`
  @media screen and (max-width: 992px) {
    color: ${({ theme }) => theme.colors.grey[300]};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledFooterSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;

  ${({ isMobileColumn }) =>
    isMobileColumn &&
    css`
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    `};
`;

const StyledFullWidthAlert = styled.div`
  & > div {
    max-width: unset;
  }
`;

const ExerciseListContainer = observer(({ children }) => {
  const { UIStore } = useStores();

  return (
    <div
      className={`exercise-list-item__body ${UIStore.isSideBarOpen ? "exercise-list-item__body--sidebar-open" : ""}`}
    >
      {children}
    </div>
  );
});

const ExercisesListItem = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: new ExerciseModel({}),
        doInstantFunc() {},
        numberOfStudents: 0 // the number of students to show in the last stats box
      };
    },
    requestConfirm() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={__("edit_constrainted_exercise")}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            const exerciseEditUrl = `${createUrl("exercises_edit", {
              group_id: getActiveGroupId(),
              exercise_id: self.props.exercise.get("id")
            })}`;
            navigateTo(exerciseEditUrl, { trigger: true });
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    requestConfirmDelete() {
      const self = this;
      let alertText = __("published_exercise_delete_alert");
      let messageText = __("Esercizio eliminato con successo");

      if (self.props.exercise.get("type") == "EXAMINATION") {
        alertText = __("published_examination_delete_alert");
        messageText = __("examination_delete_success");
      }

      if (this.props.exercise.hasConstraint()) {
        alertText = __("constrainted_exercise_delete_alert");
        if (self.props.exercise.get("type") == "EXAMINATION") {
          alertText = __("constrainted_examination_delete_alert");
        }
      }

      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={alertText}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            ConfirmDialogStore.closeConfirmDialog();
            self.props.exercise.destroy({
              wait: true,
              success() {
                showToastSuccess({ str: messageText });
                InternalDispatcher.trigger(
                  "exercise_delete",
                  self.props.exercise
                );
              },
              error(model, data) {
                const errorMsg = extractErrorMessage(
                  data.responseJSON,
                  __("Attenzione, si è verificato un errore")
                );
                showToastError({ str: errorMsg });
              }
            });
          }}
        />
      );
    },
    showUserSession() {
      const user = getActiveUser();
      const self = this;
      const exerciseModel = this.props.exercise;
      $.ajax({
        type: "GET",
        dataType: "json",
        url: createURL("api_exercises_reports_users_sessions", {
          exercise_id: exerciseModel.get("id"),
          user_id: user.get("id")
        }),
        success(ret) {
          let name = "questo";
          let surname = "utente";
          const body = (
            <div>
              {map(ret, execution => (
                <EserciziReportsUsersItem
                  exercise={exerciseModel}
                  singleUser
                  execution={execution}
                  showArchive={false}
                />
              ))}
            </div>
          );
          if (ret.length > 0) {
            name = ret[0].name;
            surname = ret[0].surname;
          }
          modalMessage.reset();
          modalMessage.set({
            header: {
              content: `<Text type="subHeaderTitle">${__("sessioni utente", {
                name,
                surname
              })}</Text>`,
              type: "HTML"
            },
            body: { content: body, type: "REACT" },
            footer: { content: "", type: "HTML" },
            addCloseButton: true
          });
          self.setState({ loadingBtn: "" });
          modalMessage.show();
        },
        error(data) {
          self.setState({ loadingBtn: "" });
          const errorMsg = extractErrorMessage(
            data,
            __("impossibile recuperare i dati richiesti")
          );
          messagesManager.addError(errorMsg);
        }
      });
    },
    shouldShowReview() {
      return (
        this.props.exercise.attributes.show_results ||
        getActiveGroup().isTeacher()
      );
    },
    render() {
      const group = getActiveGroup();
      const self = this;
      const exercise = this.props.exercise;
      const { SpaceStore } = self.props;
      const { hasDecimalGrading } = SpaceStore;
      let itemHeader = null;
      let itemBody = null;
      let itemFooter = null;
      let noResultInfo = null;
      let executionDiv = null;
      let exerciseReviewExecutionBox = null;
      let exerciseStatsBox = null;
      let editBox = null;
      let deleteBox = null;
      let instantBox = null;

      const total_group_users = this.props.numberOfStudents;
      let execButton = "";

      const report = exercise.get("report_stats");

      const groupId = getActiveGroupId();
      const exercisesRunURL = createURL("exercises_run", {
        group_id: groupId,
        exercise_id: exercise.get("id")
      });

      const avgVote = exercise.getAverageVote();
      const quizCount =
        typeof exercise.get("quizzes_count") === "undefined"
          ? 0
          : exercise.get("quizzes_count");

      let btnExecCopy = "esegui esercizio";
      let btnNotExecCopy = null;
      if (exercise.isVerification()) {
        btnExecCopy = "esegui verifica";
      }

      let userExecUrl = "";
      let userExecTest = __("visualizza esecuzioni");
      const exerciseEditUrl = createUrl("exercises_edit", {
        group_id: getActiveGroupId(),
        exercise_id: exercise.get("id")
      });

      userExecUrl = createURL("exercises_reports_users", {
        group_id: getActiveGroupId(),
        exercise_id: exercise.get("id")
      });

      if (
        group.isTeacher() &&
        report.users_failed_count + report.users_passed_count > 0
      ) {
        exerciseReviewExecutionBox = (
          <Button
            onClick={() => {
              navigateTo(userExecUrl);
            }}
            variant={group.isTeacher() ? "primary" : "secondary"}
            theme={this.props.theme.whiteTheme}
            fullWidth={getBreakpoint() === "smartphone"}
          >
            <Translate text={userExecTest} />
          </Button>
        );
      }

      if (group.isStudent()) {
        if (exercise.has("user_last_execution_report")) {
          userExecUrl = createURL("exercises_reports_sessions", {
            group_id: getActiveGroupId(),
            exercise_id: exercise.get("id"),
            session_id: exercise.get("user_last_execution_report").session_id
          });
          userExecTest = __("visualizza esecuzione");
          exerciseReviewExecutionBox = (
            <Button
              onClick={self.showUserSession.bind(exercise.get("id"))}
              variant={group.isTeacher() ? "primary" : "secondary"}
              theme={this.props.theme.whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
            >
              <Translate text={userExecTest} />
            </Button>
          );
        }
        if (
          !this.shouldShowReview() &&
          report.users_failed_count + report.users_passed_count > 0 &&
          exercise.isDone()
        ) {
          noResultInfo = (
            <StyledFullWidthAlert>
              <ContextualAlert
                theme={this.props.theme.whiteTheme}
                text={__("exercise_result_wait_teacher_correction")}
              />
            </StyledFullWidthAlert>
          );
          exerciseReviewExecutionBox = (
            <Button
              disabled
              variant={group.isTeacher() ? "primary" : "secondary"}
              theme={this.props.theme.whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
            >
              <Translate text={userExecTest} />
            </Button>
          );
        }
      }

      let userPartial = 0;
      let ratePartial = "0";
      let timePartial = 0;
      let gradeCaption = __("voto medio").toUpperCase();
      let timeCaption = __("tempo medio").toUpperCase();

      if (exercise.has("report_stats")) {
        if (typeof report.users_passed_count !== "undefined") {
          userPartial += report.users_passed_count;
        }
        if (typeof report.users_failed_count !== "undefined") {
          userPartial += report.users_failed_count;
        }
        if (typeof report.vote_avg !== "undefined") {
          ratePartial = hasDecimalGrading ? avgVote : avgVote * 10;
        }
        if (typeof report.time_avg !== "undefined") {
          timePartial = convertSecondsToFormattedString(report.time_avg);
        }
      }

      const maxStudentsPerVote = maxBy(
        report.vote_aggregations,
        v => v.users_count
      ).users_count;
      const slowest_time = isUndefined(report.highest_time_execution)
        ? 0
        : report.highest_time_execution;
      const fastest_time = isUndefined(report.lower_time_execution)
        ? 0
        : report.lower_time_execution;

      if (group.isStudent()) {
        gradeCaption = __("il tuo voto").toUpperCase();
        timeCaption = __("il tuo tempo").toUpperCase();
        if (exercise.isExecutedByUser()) {
          let realDuration = exercise.get(
            "user_last_execution_report"
          ).session_time;
          const timeThreshold = exercise.getTimeThreshold();
          /** If duration is longer than threshold default to threshold */
          if (timeThreshold > 0 && realDuration > timeThreshold) {
            realDuration = timeThreshold;
          }

          timePartial = convertSecondsToFormattedString(realDuration);
          ratePartial =
            Math.round(exercise.get("user_last_execution_report").vote * 100) /
            (hasDecimalGrading ? 10 : 1);
        } else {
          timePartial = "—";
          ratePartial = "—";
        }
      }

      const total = hasDecimalGrading
        ? __EXERCISE_MAX_RATE__
        : __EXERCISE_MAX_RATE__ * 10;
      const ratePartialText = hasDecimalGrading
        ? ratePartial
        : `${ratePartial}%`;

      exerciseStatsBox = (
        <div className="row">
          <div className="col-sm-6 exercise-stats-box-wrapper">
            <div className="exercise-stats-box exercise-stats-box-vote">
              <div className="col-xs-3 text-center">
                <CircleProgress
                  defaultText={this.shouldShowReview() ? ratePartialText : 0}
                  partial={this.shouldShowReview() ? ratePartial : 0}
                  total={this.shouldShowReview() ? total : 0}
                  type="decimal"
                  caption={gradeCaption}
                  colorBegin={this.props.theme.primaryColor[500]}
                  colorEnd={this.props.theme.primaryColor[500]}
                  background="#FFFFFF"
                />
              </div>
              <div
                className="col-xs-9 relative text-center"
                style={{ height: "100%" }}
              >
                <div className="exercise-stats-histogram">
                  <StyledHistogramBar>
                    {map(range(11), num => {
                      const maxHeight = 70;
                      let currentVote = find(
                        report.vote_aggregations,
                        aggr => num === aggr.vote_range
                      );
                      let percentageOfMax =
                        currentVote.users_count / maxStudentsPerVote;
                      if (!this.shouldShowReview()) {
                        percentageOfMax = 0;
                      }

                      const barHeight = maxHeight * percentageOfMax;
                      const label = hasDecimalGrading ? num : `${num * 10}%`;

                      return (
                        <div
                          className="exercise-stats-histogram-line"
                          key={num}
                        >
                          <div
                            className="exercise-stats-histogram-bar"
                            style={{ height: `${barHeight}px` }}
                          />
                          {hasDecimalGrading || [0, 5, 10].includes(num) ? (
                            <StyledHistogramLabel type="formSubtitle">
                              {label}
                            </StyledHistogramLabel>
                          ) : null}
                        </div>
                      );
                    })}
                  </StyledHistogramBar>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-ms-6 exercise-stats-box-wrapper">
            <div className="exercise-stats-box exercise-stats-box-time">
              <div className="col-sm-6 col-xs-3 text-center">
                <CircleProgress
                  defaultText={timePartial}
                  partial={null}
                  total={1}
                  type="decimal"
                  caption={timeCaption}
                  colorBegin="#8B5CFF"
                  colorEnd="#8B5CFF"
                  background="#FFFFFF"
                />
              </div>
              {this.shouldShowReview() ? (
                <div className="col-sm-6 col-xs-9 text-center flex-centered-row full-height">
                  <div className="exercise-stats-box-times-wrapper">
                    <Text type="formSubtitle">
                      <Translate text="esecuzione_piu_rapida" />
                    </Text>
                    <div className="margin-bottom-4">
                      <Text type="subHeaderTitle">
                        {convertSecondsToFormattedString(fastest_time)}
                      </Text>
                    </div>
                    <Text type="formSubtitle">
                      <Translate text="esecuzione_piu_lenta" />
                    </Text>
                    <Text type="subHeaderTitle">
                      {convertSecondsToFormattedString(slowest_time)}
                    </Text>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-2 col-ms-6 exercise-stats-box-wrapper">
            <div className="exercise-stats-box exercise-stats-box-students text-center">
              <div className="exercise-stats-box-students-numbers">
                <Heading level="1">{userPartial}</Heading>
                <span className="grey800">
                  <Heading level="5">&nbsp;/ {total_group_users}</Heading>
                </span>
              </div>
              <Text type="formSubtitle">
                <Translate text="students" />
              </Text>
            </div>
          </div>
        </div>
      );

      if (report.users_failed_count + report.users_passed_count === 0) {
        let placeholderLabel = exercise.isVerification()
          ? __("nessuno ha svolto questa verifica")
          : __("nessuno ha svolto questo esercizio");
        if (group.isTeacher()) {
          placeholderLabel = exercise.isVerification()
            ? __("exercises_list_test_not_executed")
            : __("exercises_list_exercise_not_executed");
        }

        exerciseStatsBox = (
          <div className="relative">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="0 8px"
              textAlign="center"
              height="120px"
            >
              <Heading level="4">{placeholderLabel}</Heading>
            </Box>
          </div>
        );
      }
      if (group.isTeacher() && userPartial < 1) {
        if (getBreakpoint() === "smartphone") {
          editBox = (
            <ActionButton
              onClick={
                this.props.exercise.hasConstraint()
                  ? this.requestConfirm
                  : () => {
                      navigateTo(exerciseEditUrl);
                    }
              }
              icon="pencil"
              theme={this.props.theme.whiteTheme}
              variant="secondary"
            />
          );
        } else {
          editBox = (
            <Button
              onClick={
                this.props.exercise.hasConstraint()
                  ? this.requestConfirm
                  : () => {
                      navigateTo(exerciseEditUrl);
                    }
              }
              variant="secondary"
              theme={this.props.theme.whiteTheme}
            >
              <Translate text="modifica" />
            </Button>
          );
        }
      }

      if (group.isTeacher()) {
        deleteBox = (
          <ActionButton
            onClick={this.requestConfirmDelete}
            icon="trash"
            theme={this.props.theme.whiteTheme}
            variant="secondary"
          />
        );
        instantBox = (
          <TranslatedTooltip tooltipString="lancia instant" placement="bottom">
            <Box minWidth="40px">
              <ActionButton
                onClick={this.props.doInstantFunc}
                icon="thunderbolt"
                theme={this.props.theme.whiteTheme}
                variant="secondary"
              />
            </Box>
          </TranslatedTooltip>
        );
      }

      executionDiv = (
        <div className="row">
          <div className="col-xs-12">{exerciseStatsBox}</div>
        </div>
      );

      let quizCountLabel = __("quiz_count_label");
      if (quizCount == 1) {
        quizCountLabel = __("quiz_count_singular_label");
      }

      let orangeLabelCopy = null;
      if (group.isTeacher()) {
        if (exercise.get("pending_correction")) {
          orangeLabelCopy = "exercise_pending_correction_label";
        }
      } else if (exercise.get("pending_reply")) {
        orangeLabelCopy = "exercise_pending_reply_label";
      }

      const violetLabelCopy = exercise.isVerification()
        ? "exercise_list_item_label_examination"
        : "exercise_list_item_label_assignment";

      itemHeader = (
        <div className="exercise-list-item__header">
          <div className="exercise-list-item__header__title">
            <Heading level="4">{exercise.get("title")} </Heading>
            <Box
              display="flex"
              flexWrap="wrap"
              gap="4px"
              className="margin-top-xs-10"
            >
              <Label
                theme={this.props.theme.whiteTheme}
                color="primary"
                outline={false}
              >
                {quizCount} {quizCountLabel}
              </Label>
              <Label
                theme={this.props.theme.whiteTheme}
                color="lightPrimary"
                outline={false}
              >
                <Translate text={violetLabelCopy} />
              </Label>
              {orangeLabelCopy && (
                <div className="mergin-left-5">
                  <Label
                    theme={this.props.theme.whiteTheme}
                    color="yellow"
                    outline={false}
                  >
                    <Translate text={orangeLabelCopy} />
                  </Label>
                </div>
              )}
            </Box>
          </div>
          <div className="exercise-list-item__header__action">
            {editBox}
            {deleteBox}
          </div>
        </div>
      );

      if (group.shouldDisableExerciseExecution() == false) {
        if (exercise.isDoable()) {
          execButton = (
            <Button
              onClick={() => {
                navigateTo(exercisesRunURL);
              }}
              variant={group.isTeacher() ? "secondary" : "primary"}
              theme={this.props.theme.whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
            >
              <Translate text={btnExecCopy} />
            </Button>
          );
        } else {
          execButton = <div>{__(btnNotExecCopy)}</div>;
        }
      } else {
        execButton = "";
        instantBox = "";
      }

      itemBody = <ExerciseListContainer>{executionDiv}</ExerciseListContainer>;

      itemFooter = (
        <StyledFooterContainer>
          <StyledFooterSection isMobileColumn={noResultInfo !== null}>
            {instantBox}
            {exerciseReviewExecutionBox}
            {noResultInfo}
          </StyledFooterSection>
          {execButton}
        </StyledFooterContainer>
      );

      return (
        <div className="app__box exercise-list-item-wrapper">
          <ThemeProvider>
            {itemHeader}
            {itemBody}
            {itemFooter}
          </ThemeProvider>
        </div>
      );
    }
  })
);

export default withSpaceStore(
  withConfirmDialogStore(withTheme(ExercisesListItem))
);
