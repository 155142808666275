import { Box, Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { TooltipContent } from "src/js/components/Tooltip";
import Tooltip from "src/js/components/Tooltip/Tooltip";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { TileButton } from "src/js/components/button/TileButton";
import { ClaimModal } from "src/js/components/layout/ClaimModal";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useTemplates } from "src/js/query/spaces";
import { S3Video } from "src/js/settings/settingsVideo";
import { Trans, useTranslation } from "src/js/translation";
import { TemplateSelection, TemplateSelectionType } from "src/js/types";
import GroupCreationModal from "../../GroupCreationModal";
import * as S from "./TemplateIntro.styles";

const TemplateIntroActions = ({
  onSelection
}: {
  onSelection: (selection: TemplateSelectionType) => void;
}) => {
  const history = useHistory();
  const {
    SpaceStore: {
      activeSpaceId,
      activeSpaceName,
      isClaimable,
      isAdmin,
      isDemoSpace,
      activeSpaceSettings: { dashboardGroupsEnabled }
    },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { trackVirtualView } = useViewTracking();

  useEffect(() => {
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Groups.GroupCreateSelection
    );
  }, []);

  const { data: templatesData, isFetching: templatesLoading } = useTemplates({
    spaceId: activeSpaceId
  });

  const customTemplateTooltip = useMemo(() => {
    if (isDemoSpace) {
      return "template_intro_demo_space_tooltip";
    }
    if (isClaimable) {
      return "template_intro_claim_space_tooltip";
    }
    const hasCustomTemplates = templatesData?.customTemplates?.length;
    if (hasCustomTemplates) {
      return null;
    }
    if (isAdmin) {
      return dashboardGroupsEnabled
        ? "template_intro_no_templates_tooltip"
        : "template_intro_go_to_plans_tooltip";
    }
    return "template_intro_no_permission_tooltip";
  }, [isClaimable, templatesData]);

  const openClaimModal = () => {
    closeModal();
    const openGroupCreationModal = () =>
      openModal(() => <GroupCreationModal />);
    openModal(() => (
      <ClaimModal
        close={closeModal}
        header=""
        text={translate("template_claim_modal_text")}
        onGoBack={() => {
          closeModal();
          openGroupCreationModal();
        }}
      />
    ));
  };

  const tooltip = (
    <TooltipContent>
      <Trans
        i18nKey={customTemplateTooltip}
        components={{
          GroupsList: (
            <S.TooltipLinkBtn
              onClick={() => {
                closeModal();
                history.push({
                  pathname: createUrl("dashboard_groups", {
                    space_id: activeSpaceId
                  }),
                  state: {
                    showTooltipFirstTemplate: true
                  }
                });
              }}
            />
          ),
          ClaimSpace: <S.TooltipLinkBtn onClick={openClaimModal} />,
          Plans: (
            <S.TooltipLinkBtn
              onClick={() => {
                closeModal();
                history.push({
                  pathname: createUrl("dashboard_settings", {
                    space_id: activeSpaceId,
                    option: "plan"
                  })
                });
              }}
            />
          )
        }}
      />
    </TooltipContent>
  );

  return (
    <>
      <TileButton
        id="template-blank"
        loading={templatesLoading}
        icon="contentPaste"
        title={translate("template_intro_blank_btn_title")}
        description={translate("template_intro_blank_btn_desc")}
        onClick={() => {
          onSelection(TemplateSelection.Blank);
        }}
      />
      <TileButton
        id="template-weschool"
        loading={templatesLoading}
        icon="wsLogo"
        title={translate("template_intro_we_btn_title")}
        description={translate("template_intro_we_btn_desc")}
        onClick={() => {
          onSelection(TemplateSelection.Weschool);
        }}
      />
      <Tooltip
        open={customTemplateTooltip ? undefined : false}
        tooltipContent={tooltip}
      >
        <TileButton
          id="template-custom"
          loading={templatesLoading}
          icon="bookmarkStar"
          title={translate("template_intro_custom_btn_title", {
            space:
              activeSpaceName?.length > 50
                ? `${activeSpaceName?.slice(0, 47)}...`
                : activeSpaceName
          })}
          description={translate("template_intro_custom_btn_desc")}
          onClick={() => {
            if (customTemplateTooltip) {
              return;
            }
            onSelection(TemplateSelection.Custom);
          }}
          disabledStyle={!!customTemplateTooltip}
        />
      </Tooltip>
    </>
  );
};

const TemplateIntro = ({
  onSelection
}: {
  onSelection: (selection: TemplateSelectionType) => void;
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal },
    ThemeStore: { whiteTheme }
  } = useStores();
  const { translate } = useTranslation();

  if (isLayoutModeMobile) {
    return (
      <ModalLayout
        closeModal={closeModal}
        mobileProps={{ withAdaptiveHeight: true, withHeaderLabels: false }}
        labels={{ title: "With adaptive" }}
      >
        <Box textAlign="center" padding="12px 20px 0px 20px">
          <Heading level="3">
            {translate("template_intro_title_mobile")}
          </Heading>
        </Box>
        <Box padding={20} display="flex" flexDirection="column" gap={8}>
          <TemplateIntroActions onSelection={onSelection} />
        </Box>
      </ModalLayout>
    );
  }

  return (
    <S.Modal>
      <S.CloseActionButton
        theme={whiteTheme}
        icon="close"
        variant="tertiary"
        onClick={closeModal}
      />
      <S.CardWrap>
        <S.VideoContainer>
          <VideoPlayer video={S3Video.TemplateIntro} />
        </S.VideoContainer>
        <S.ActionContainer>
          <Box marginRight={12}>
            <Heading level="3">{translate("template_intro_title")}</Heading>
          </Box>
          <S.DescriptionWrapper>
            <Text type="formFieldEmpty">
              {translate("template_intro_desc")}
            </Text>
          </S.DescriptionWrapper>
          <Box display="flex" flexDirection="column" gap={12}>
            <TemplateIntroActions onSelection={onSelection} />
          </Box>
        </S.ActionContainer>
      </S.CardWrap>
    </S.Modal>
  );
};

export default observer(TemplateIntro);
