import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { TextEditorInput } from "src/js/components/TextEditorInput";
import { TextEditorSubmitFunction } from "src/js/components/TextEditorInput/TextEditorInput.types";
import { Thread } from "src/js/components/Thread";
import { NoMessages } from "src/js/components/Thread/components/NoMessages";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { createUrl } from "src/js/modules/routing";
import { useMeQueryCached } from "src/js/query";
import {
  checkThreadExists,
  createThread
} from "src/js/repository/threadsRepository";
import { useTranslation } from "src/js/translation";
import { SpaceChatsParams, ThreadUser } from "src/js/types";
import {
  WebSocketConnectionState,
  WebSocketSendError
} from "src/js/types/models/WebSocket";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useFetchUserNewThread } from "../../hooks";
import useFoundThread from "../hooks/useFoundThread";
import * as S from "./NewThreadPage.styles";
import { SearchUsersToolbar } from "./components/SearchUsersToolbar";

const NewThreadPage: FC = () => {
  const { greyTheme } = useTheme();
  const { pageMinHeight } = useAppLayout();
  const {
    SpaceStore: { activeSpaceId, activeSpaceSlug },
    ChatStore: { threadsList, addNewThreadToThreadList },
    SpaceStore: { setActiveSpaceBySlug },
    WebSocketStore: { connectionState }
  } = useStores();
  const { data: activeUser } = useMeQueryCached();
  const { translate } = useTranslation();
  const { space_slug } = useParams<SpaceChatsParams>();

  const [users, setUsers] = useState<ThreadUser[]>([]);
  const [activeThreadId, setActiveThreadId] = useState<string>();
  const [isPending, setIsPending] = useState<boolean>(false);

  const { foundThread } = useFoundThread({
    threadsList,
    fetchedThreadId: activeThreadId
  });

  useEffect(() => {
    if (space_slug) setActiveSpaceBySlug(space_slug);
  }, [space_slug]);

  useFetchUserNewThread({
    handleThreadUser: threadUser => setUsers([threadUser])
  });

  useEffect(() => {
    if ((!activeSpaceId || !users || users.length === 0) && !activeThreadId)
      return;
    const participants = users.map(user => user.uuid);
    checkThreadExists({
      spaceId: activeSpaceId,
      participants
    })
      .then(({ threadId }) => {
        setActiveThreadId(threadId);
      })
      .catch(() => {
        setActiveThreadId(null);
      });
  }, [activeSpaceId, users]);

  const navigateToThread = (threadId: string) =>
    navigateTo(
      createUrl("chat_thread", {
        space_slug: activeSpaceSlug,
        thread_id: threadId
      })
    );

  const handleTexteditorClick = () => {
    if (!activeSpaceId || !users || users.length === 0) return;
    if (foundThread) {
      navigateToThread(foundThread?.id);
    }
  };

  const handleSubmit = async ({
    message,
    resources
  }: TextEditorSubmitFunction) => {
    if (isPending) return;
    if (connectionState !== WebSocketConnectionState.CONNECTED) {
      throw WebSocketSendError.SocketNotReady;
    }
    setIsPending(true);
    const userIds = users.map(user => user.uuid);
    try {
      const thread = await createThread({
        spaceId: activeSpaceId,
        userIds
      });
      addNewThreadToThreadList({
        ...thread,
        lastMessage: {
          id: "unsent",
          userId: activeUser?.uuid,
          createdAt: new Date().toISOString(),
          message,
          resources,
          isRead: false,
          isSent: false
        }
      });
      navigateToThread(thread?.id);
    } catch (error) {
      if (error === undefined) {
        throw WebSocketSendError.SocketNotReady;
      }
    } finally {
      setIsPending(false);
    }
  };

  const newChat = isPending ? (
    <S.StyledDoodleLoader theme={greyTheme} />
  ) : (
    <NoMessages />
  );

  return (
    <S.NewThreadPageWrapper
      pageMinHeight={foundThread ? "100%" : pageMinHeight}
    >
      <SearchUsersToolbar
        users={users}
        setUsers={setUsers}
        threadExists={!!foundThread}
      />
      <S.NewThreadPageContent>
        {foundThread ? (
          <Thread activeThread={foundThread} isReadOnly />
        ) : (
          newChat
        )}
        <TextEditorInput
          showSubmitLoader={isPending}
          onClick={handleTexteditorClick}
          submitFunction={handleSubmit}
          submitDisabled={
            !!activeThreadId || !users || users.length === 0 || isPending
          }
          placeholder={translate("chat_new_input_placeholder")}
        />
      </S.NewThreadPageContent>
    </S.NewThreadPageWrapper>
  );
};

export default observer(NewThreadPage);
