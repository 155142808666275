import { TranslationKeys } from "src/js/translation";
import { ObjectValues } from "src/js/types";

export const CustomFieldsEnum = {
  BirthLocation: "birth_location",
  Residence: "residence",
  VAT: "VAT",
  ID: "ID",
  EducationLevel: "education_level"
} as const;

export type CustomField = {
  name: TranslationKeys;
  checked?: boolean;
};

export type CustomFieldsStateId = ObjectValues<typeof CustomFieldsEnum>;

export type CustomFieldsState = Record<CustomFieldsStateId, CustomField>;

export const TargetAudience = {
  Instructors: "instructors",
  Learners: "learners",
  All: "all"
} as const;

export type TargetAudience = ObjectValues<typeof TargetAudience>;

export const DTOCustomFieldMapped = {
  [CustomFieldsEnum.BirthLocation]: [1, 2],
  [CustomFieldsEnum.Residence]: [3, 4],
  [CustomFieldsEnum.VAT]: [5],
  [CustomFieldsEnum.ID]: [6],
  [CustomFieldsEnum.EducationLevel]: [7]
};
