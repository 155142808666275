import { useCallback, useEffect, useMemo, useState } from "react";
import { DashboardSettings } from "src/js/types";
import {
  AssessmentsGradingMethod,
  AssessmentsGradingMethodType
} from "src/js/types/models/Customization";

const useAssessmentGrading = ({
  assessmentsGradingMethod = AssessmentsGradingMethod.Decimal,
  assessmentsCompletionThreshold
}: Pick<
  DashboardSettings,
  "assessmentsGradingMethod" | "assessmentsCompletionThreshold"
>) => {
  const [gradingMethod, setGradingMethod] =
    useState<AssessmentsGradingMethodType>(assessmentsGradingMethod);
  const [gradingThreshold, setGradingThreshold] = useState(
    assessmentsCompletionThreshold
  );

  useEffect(() => {
    setGradingMethod(assessmentsGradingMethod);
    setGradingThreshold(assessmentsCompletionThreshold);
  }, [assessmentsGradingMethod, assessmentsCompletionThreshold]);

  const setNormalizedThreshold = useCallback(
    (value: number) => {
      if (gradingMethod === AssessmentsGradingMethod.Decimal) {
        setGradingThreshold(value * 10);
      } else {
        setGradingThreshold(value);
      }
    },
    [gradingMethod]
  );

  const normalizedGradingMethod = useMemo(() => {
    return gradingMethod === AssessmentsGradingMethod.Decimal
      ? {
          value: gradingThreshold / 10,
          min: 0,
          max: 10,
          step: 1,
          label: null
        }
      : {
          value: gradingThreshold,
          min: 0,
          max: 100,
          step: 10,
          label: "%"
        };
  }, [gradingMethod, gradingThreshold]);

  return {
    gradingMethod,
    setGradingMethod,
    gradingThreshold,
    setNormalizedThreshold,
    normalizedGradingMethod
  };
};

export default useAssessmentGrading;
