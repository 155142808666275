import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { CustomTooltip } from "src/js/components/CustomTooltip";
import { CustomTooltipProps } from "src/js/components/CustomTooltip/CustomTooltip";
import { InfoButton } from "../button/InfoButton";
import * as S from "./InfoTooltip.styles";

const InfoTooltip = ({
  size,
  disabled,
  side,
  customTooltipProps
}: {
  size?: number;
  disabled?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  customTooltipProps: CustomTooltipProps;
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
        <Tooltip.Trigger asChild onClick={() => setIsTooltipOpen(true)}>
          <InfoButton type="button" size={size} disabled={disabled} />
        </Tooltip.Trigger>
        <Tooltip.Portal forceMount>
          <AnimatePresence>
            {isTooltipOpen ? (
              <Tooltip.Content forceMount asChild side={side} sideOffset={16}>
                <S.MotionTooltipContent
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, y: -10 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{
                    duration: 0.2
                  }}
                >
                  <CustomTooltip maxWidth="224px" {...customTooltipProps} />
                </S.MotionTooltipContent>
              </Tooltip.Content>
            ) : null}
          </AnimatePresence>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default InfoTooltip;
