import { Box, Text } from "@arcadia/design-system";
import React from "react";
import { ToastId } from "react-toastify";
import {
  dismissToast,
  showToastSuccessPermanent
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import {
  StringVariables,
  Trans,
  TranslateOptions,
  TranslationKeys
} from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { DefaultTheme } from "styled-components";

import * as S from "./ConfirmToast.styles";

export const showConfirmToast = ({
  groupId,
  groupName,
  whiteTheme,
  translate
}: {
  groupId: number;
  groupName: string;
  whiteTheme: DefaultTheme;
  translate: (
    keyOrObj: TranslationKeys | TranslateOptions,
    stringVariables?: StringVariables
  ) => string;
}) => {
  let toastId: ToastId | null = null;

  toastId = showToastSuccessPermanent({
    content: (
      <Box display="flex" width="764px">
        <Trans
          i18nKey="group_invitation_confirm_toast_text"
          values={{ groupName }}
          components={{ b: <strong /> }}
        />
        <Box marginLeft="auto" gap="16px" display="flex">
          <S.ConfirmRedirectButton
            type="button"
            onClick={() => {
              navigateTo(createUrl("wall", { group_id: groupId }));
              dismissToast(toastId);
            }}
          >
            <Text type="ctaTertiary">
              {translate("group_invitation_confirm_toast_accept_cta")}
            </Text>
          </S.ConfirmRedirectButton>
          <S.CancelRedirectButton
            type="button"
            onClick={() => dismissToast(toastId)}
          >
            <Text type="ctaTertiary">
              {translate("group_invitation_confirm_toast_decline_cta")}
            </Text>
          </S.CancelRedirectButton>
        </Box>
      </Box>
    ),
    theme: whiteTheme
  });
};
