import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const EmptyModuleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  min-height: 460px;
`;

export const EmptyModuleWrapperMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const StyledText = styled(Text)`
  max-width: 240px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-top: 8px;
`;
