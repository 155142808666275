import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import BadgeGroupWithIcon from "src/js/components/GroupPanel/components/BadgeGroupWithIcon";
import { GroupInSpaceModel } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./ItemCardGroupImportModal.styles";

const ItemCardGroupImportModal = ({
  isActive,
  onClick,
  el
}: {
  isActive: boolean;
  el: GroupInSpaceModel;
  onClick: () => void;
}) => {
  const { whiteTheme, primaryColor } = useTheme();

  return (
    <S.ItemCardGroupImportModalWrapper onClick={onClick} isActive={isActive}>
      <S.ImageContainer>
        <BadgeGroupWithIcon group={el?.group}>
          <S.StyledAvatarWithInitials
            size="extraSmall"
            name={el?.space?.name}
            avatarColor={el?.space?.defaultBadgeColor}
            url={el?.space?.badge?.small as string}
            theme={whiteTheme}
            alt={el?.space?.name}
          />
        </BadgeGroupWithIcon>
      </S.ImageContainer>
      <S.ItemCardGroupImportModalContent>
        <Text type="body">
          <Icon icon="user" width={12} height={12} color={primaryColor[500]} />{" "}
          {el?.usersCount}
        </Text>
        <Text type="formField">{el?.group?.name}</Text>
      </S.ItemCardGroupImportModalContent>
      <S.CheckIndicator isSelected={isActive}>
        <Icon icon="circleCheck" width={24} height={24} initialViewbox />
      </S.CheckIndicator>
    </S.ItemCardGroupImportModalWrapper>
  );
};

export default ItemCardGroupImportModal;
