import { Mixin } from "@ds_themes/mixin";
import { Text } from "@ds_universal/Text";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const Subtitle = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 0;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  gap: 16px;
  margin: 16px 0;
  ${Mixin.tablet`
    grid-template-columns: repeat(2, 1fr);
    `}
  ${Mixin.mobile`
    grid-template-columns: repeat(1, 1fr);  
    `}
`;

export const RightAligner = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
