import React from "react";
import { Box, Button } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

type EditSaveButtonProps = {
  editMode: boolean;
  onClick: () => void;
};

const EditSaveButton = ({ editMode, onClick }: EditSaveButtonProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <Box>
      <Button
        theme={whiteTheme}
        variant="secondary"
        type="button"
        onClick={onClick}
      >
        {translate({
          text: editMode
            ? "space_settings_general_action_save"
            : "space_settings_general_action_edit"
        })}
      </Button>
    </Box>
  );
};

export default EditSaveButton;
