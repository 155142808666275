import styled from "styled-components";
import { motion } from "framer-motion";
import { Text } from "@arcadia/design-system";

export const FormWrapper = styled(motion.form)`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 52px;

  > div > div {
    margin-top: 0px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const AlertWrapper = styled.div`
  > div {
    max-width: unset;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const VideoLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
`;

export const InfoVideoWrapper = styled(motion.div)`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background: linear-gradient(
    45deg,
    ${({ theme }) => theme.primaryColor[500]} 0%,
    ${({ theme }) => theme.primaryColor[800]} 100%
  );
`;

export const VideoDescription = styled(Text)`
  max-width: 404px;
  text-align: center;
  margin: auto;
`;
