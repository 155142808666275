import { Avatar, Text, ToolbarSpaceItem } from "@arcadia/design-system";
import React from "react";
import { groupGetCover } from "src/js/GroupFunction";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { GroupInvitation } from "../../types";

import * as S from "./GroupInvitationCard.styles";

const GroupInvitationCard = ({
  invitation,
  showConfirmation,
  rejectInvite,
  hasBorder
}: {
  invitation: GroupInvitation;
  showConfirmation: ({ invitation }: { invitation: GroupInvitation }) => void;
  rejectInvite: ({ invitation }: { invitation: GroupInvitation }) => void;
  hasBorder?: boolean;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.CardWrapper key={invitation.inviteCode} hasBorder={hasBorder}>
      <S.GroupCoverWrapper>
        <Avatar
          theme={whiteTheme}
          url={groupGetCover({
            cover: invitation.groupData.group.cover?.small
          })}
          alt="Group-Cover"
          size="l"
          rounded={false}
        />
        <S.AvatarWrapper>
          <ToolbarSpaceItem
            id="space-badge"
            theme={whiteTheme}
            badge={invitation.groupData.space.badge?.small}
            backgroundColorCode={invitation.groupData.space.defaultBadgeColor}
            spaceName={invitation.groupData.space.name}
            isSelected={false}
            size="auto"
          />
        </S.AvatarWrapper>
      </S.GroupCoverWrapper>
      <S.BodySectionWrapper>
        <S.TextSectionWrapper hasBorder={hasBorder}>
          <S.GreyText type="formDescription">
            {invitation.groupData.space.name}
          </S.GreyText>
          <Text type="formField">{invitation.groupData.group.name}</Text>
        </S.TextSectionWrapper>
        <S.ButtonsWrapper>
          <S.StyledLinkButton
            onClick={() =>
              showConfirmation({
                invitation
              })
            }
          >
            {translate("group_invitation_accept_buttton")}
          </S.StyledLinkButton>
          <S.StyledLinkButton
            isReject
            onClick={() => rejectInvite({ invitation })}
          >
            {translate("group_invitation_decline_buttton")}
          </S.StyledLinkButton>
        </S.ButtonsWrapper>
      </S.BodySectionWrapper>
    </S.CardWrapper>
  );
};

export default GroupInvitationCard;
