import { useTranslation } from "src/js/translation";

export const S3Video = {
  DashboardUsers: "DASHBOARD_USERS",
  DashboardGroups: "DASHBOARD_GROUPS",
  DashboardAnalytics: "DASHBOARD_ANALYTICS",
  DashboardCustomizations: "DASHBOARD_CUSTOMIZATIONS",
  SpaceBlocked: "SPACE_BLOCKED",
  MigrateGroupsModalIntro: "MIGRATE_GROUPS_MODAL_INTRO",
  ClaimExternalFlowModal: "CLAIM_EXTERNAL_FLOW_MODAL",
  BoardsNoContent: "BOARDS_NO_CONTENT",
  FullyCustomisedDigitalWorkspace: "FULLY-CUSTOMISED_DIGITAL_WORKSPACE",
  InformedModalVideo: "INFORMED_MODAL_VIDEO",
  ExplainDemoSpaceModal: "EXPLAIN_DEMO_SPACE_MODAL",
  AnalyticsInfoModal: "ANALYTICS_INFO_MODAL",
  TemplateIntro: "TEMPLATE_INTRO",
  StaffPermission: "STAFF_PERMISSION",
  CsvUploadSuccess: "CSV_UPLOAD_SUCCESS",
  EmbedPrivacyGeneral: "EMBED_PRIVACY_GENERAL",
  EmbedPrivacyGDocs: "EMBED_PRIVACY_GDOCS",
  EmbedPrivacyMicrosoft: "EMBED_PRIVACY_MICROSOFT",
  EmbedPrivacyVimeo: "EMBED_PRIVACY_VIMEO",
  EmbedPrivacyYoutube: "EMBED_PRIVACY_YOUTUBE"
};
export type S3Video = (typeof S3Video)[keyof typeof S3Video];

export type S3VideoType = {
  url: string;
  ratio: string;
};

export const useS3Video = (video: S3Video): S3VideoType => {
  const { translate } = useTranslation();
  const videoList: { [key: S3Video]: S3VideoType } = {
    [S3Video.DashboardUsers]: {
      url: "https://static.weschool.com/video/dashboard_users.mp4",
      ratio: "16 / 9"
    },
    [S3Video.DashboardGroups]: {
      url: "https://static.weschool.com/video/dashboard_groups.mp4",
      ratio: "16 / 9"
    },
    [S3Video.DashboardAnalytics]: {
      url: translate("video_url_analytics_blocked_modal"),
      ratio: "unset"
    },
    [S3Video.DashboardCustomizations]: {
      url: "https://static.weschool.com/video/dashboard_customization_placeholder.mp4",
      ratio: "16 / 9"
    },
    [S3Video.SpaceBlocked]: {
      url: "https://static.weschool.com/video/space_blocked.mp4",
      ratio: "16 / 9"
    },
    [S3Video.MigrateGroupsModalIntro]: {
      url: translate("video_url_migrate_groups"),
      ratio: "16 / 9"
    },
    [S3Video.ClaimExternalFlowModal]: {
      url: translate("video_url_claim_spaces"),
      ratio: "16 / 9"
    },
    [S3Video.BoardsNoContent]: {
      url: translate("video_url_boards_no_content"),
      ratio: "unset"
    },
    [S3Video.FullyCustomisedDigitalWorkspace]: {
      url: translate("video_url_fully-customised_digital_workspace"),
      ratio: "unset"
    },
    [S3Video.InformedModalVideo]: {
      url: translate("video_url_informed_modal_video"),
      ratio: "unset"
    },
    [S3Video.ExplainDemoSpaceModal]: {
      url: translate("video_url_explain_demo_space_modal_video"),
      ratio: "unset"
    },
    [S3Video.AnalyticsInfoModal]: {
      url: translate("video_url_analytics_info_modal"),
      ratio: "unset"
    },
    [S3Video.TemplateIntro]: {
      url: translate("video_url_template_intro"),
      ratio: "unset"
    },
    [S3Video.StaffPermission]: {
      url: translate("video_url_staff_permission"),
      ratio: "242 / 131"
    },
    [S3Video.CsvUploadSuccess]: {
      url: "https://static.weschool.com/video/csv_upload_success.mp4",
      ratio: "unset"
    },
    [S3Video.EmbedPrivacyGeneral]: {
      url: "https://static.weschool.com/video/embed/privacy/general.mp4",
      ratio: "16/9"
    },
    [S3Video.EmbedPrivacyGDocs]: {
      url: "https://static.weschool.com/video/embed/privacy/google.mp4",
      ratio: "16/9"
    },
    [S3Video.EmbedPrivacyMicrosoft]: {
      url: "https://static.weschool.com/video/embed/privacy/microsoft.mp4",
      ratio: "16/9"
    },
    [S3Video.EmbedPrivacyVimeo]: {
      url: "https://static.weschool.com/video/embed/privacy/vimeo.mp4",
      ratio: "16/9"
    },
    [S3Video.EmbedPrivacyYoutube]: {
      url: "https://static.weschool.com/video/embed/privacy/youtube.mp4",
      ratio: "16/9"
    }
  };
  return videoList[video];
};
