import styled from "styled-components";

export const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
`;

export const EditLayer = styled.div<{ isDefaultAvatar: boolean }>`
  position: absolute;
  top: ${({ isDefaultAvatar }) => (isDefaultAvatar ? "1px" : "0px")};
  left: ${({ isDefaultAvatar }) => (isDefaultAvatar ? "1px" : "0px")};
  display: flex;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.violet[50]}99;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.grey[1000]};
  }

  input {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const MobileUploadInfo = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopUploadInfo = styled.div`
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  @media (max-width: 767px) {
    display: none;
  }
`;
