import { useReducer } from "react";
import {
  UseMutationOptions,
  UseMutationState,
  UseMutationReducerAction
} from "./types";

const reducer = (
  state: UseMutationState,
  { type, payload }: UseMutationReducerAction
): UseMutationState => {
  switch (type) {
    case "fetch":
      return {
        ...state,
        isLoading: true
      };

    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case "error":
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};

const initialState: UseMutationState = {
  error: undefined,
  isLoading: false
};

/**
 * @deprecated use useMutation from [src/js/query/hooks](../query/hooks/useMutation.ts) instead
 */
const useMutation = <TVariables = unknown>(
  cb: (vars?: TVariables) => Promise<void>,
  options?: UseMutationOptions<TVariables>
) => {
  const [{ error, isLoading }, dispatch] = useReducer(reducer, initialState);
  const { onError, onSuccess, onStart } = options || {};

  const mutate = async (
    vars?: TVariables,
    mutateOptions?: UseMutationOptions<TVariables>
  ) => {
    const {
      onError: onInnerError,
      onStart: onInnerStart,
      onSuccess: onInnerSuccess
    } = mutateOptions || {};

    try {
      if (onInnerStart) {
        onInnerStart(vars);
      } else if (onStart) {
        onStart(vars);
      }

      dispatch({ type: "fetch" });
      await cb(vars);
      dispatch({ type: "success" });

      if (onInnerSuccess) {
        onInnerSuccess(vars);
      } else if (onSuccess) {
        onSuccess(vars);
      }
    } catch (err) {
      dispatch({ type: "error", payload: { error: err } });

      if (onInnerError) {
        onInnerError(err, vars);
      } else if (onError) {
        onError(err, vars);
      }
    }
  };

  return {
    mutate,
    error,
    isLoading
  };
};

export default useMutation;
