import { Avatar, Box, Heading, Icon } from "@arcadia/design-system";
import { Location } from "history";
import { observer } from "mobx-react";
import React, { FC, useCallback, useState } from "react";
import { useLocation } from "react-router";
import { DrawerEditBoard } from "src/js/components/DrawerEditBoard";
import { createUrl } from "src/js/modules/routing";
import { translateString } from "src/js/translation/TranslationProvider";
import { useMeQueryCached } from "src/js/query";
import { doRouteBack, navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { GlobalStyleHideGenericHeader } from "../TopHeaderMobileWrapper/TopHeaderMobileWrapper.styles";
import * as S from "./TopHeaderMobile.styles";
import TopHeaderMobileBoardActions from "./TopHeaderMobileBoardActions";
import { TopHeaderMobileProps } from "./types";
import { getHeaderTitle, getPath } from "./utils";

const TopHeaderMobile: FC<TopHeaderMobileProps> = ({
  customHeaderTitle,
  isTeacher,
  withBoardActionButtons,
  withEditSaveActionButtons,
  boardId,
  handleEdit,
  handleBack,
  hideGenericHeader = false
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { whiteTheme } = useTheme();
  const location = useLocation<Location>();
  const headerTitle = getHeaderTitle(location);
  const isPurple = getPath(location) === "settings";

  const { data: activeUser } = useMeQueryCached();

  const onClickBack = useCallback(() => {
    if (handleBack) {
      return handleBack();
    }
    return doRouteBack();
  }, [handleBack]);

  const onNavigateProfile = useCallback(() => {
    navigateTo(createUrl("user_profile_settings"));
  }, []);

  return (
    <S.HeaderMobileWhiteContainer isPurple={isPurple}>
      <S.InnerContainer>
        {isPurple ? (
          <Box display="flex" alignItems="center" onClick={onNavigateProfile}>
            <Avatar
              theme={whiteTheme}
              url={activeUser.avatar?.small}
              alt={activeUser.name}
              size="m"
            />
            <S.NameWrapper>
              <Heading level="4">{`${activeUser?.name} ${activeUser?.surname}`}</Heading>
            </S.NameWrapper>
          </Box>
        ) : (
          <>
            <S.TextContainer>
              <S.HeaderTitle type="headerTitleLg">
                {customHeaderTitle || translateString({ text: headerTitle })}
              </S.HeaderTitle>
            </S.TextContainer>
            <S.BoxButtons>
              <Icon
                icon="chevronSmallLeft"
                width={16}
                height={16}
                onClick={onClickBack}
              />
            </S.BoxButtons>
            <S.BoxButtons right="0px">
              {withBoardActionButtons && isTeacher ? (
                <TopHeaderMobileBoardActions setDrawerOpen={setDrawerOpen} />
              ) : null}
              {withEditSaveActionButtons && (
                <Box onClick={handleEdit}>
                  <S.EditAndSave type="headerTitleLg">
                    {withEditSaveActionButtons()}
                  </S.EditAndSave>
                </Box>
              )}
            </S.BoxButtons>
          </>
        )}
      </S.InnerContainer>
      <DrawerEditBoard
        boardId={boardId}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      {hideGenericHeader ? <GlobalStyleHideGenericHeader /> : null}
    </S.HeaderMobileWhiteContainer>
  );
};

export default observer(TopHeaderMobile);
