import {
  Box,
  CardLesson,
  Heading,
  PageNav,
  SearchBar
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { CreateOrJoinGroup } from "src/js/components/layout/CreateOrJoinGroup";
import { groupGetCover } from "src/js/GroupFunction";
import { useDebounce, useStores } from "src/js/hooks";
import handleThumbnails from "src/js/modules/imageFunction";
import { createUrl } from "src/js/modules/routing";
import { groupsQueryKey, useGroupsPaginated } from "src/js/query/groups";
import { GROUPS_HOME_LIST_LIMIT } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import { useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "src/js/components/Skeleton";
import useSocket from "src/js/hooks/websocket/useSocket";
import {
  DOMAIN,
  EVENT,
  TemplatingGroupCloningCompleted
} from "src/js/types/models/WebSocket";
import { GroupsEmptyState } from "../GroupsEmptyState";
import * as S from "./GroupsList.styles";

const GroupsList = () => {
  const { greyTheme } = useTheme();
  const {
    SpaceStore: {
      activeSpace,
      activeSpaceId,
      cleanLastActiveSpaceLocalStorage
    },
    WebSocketStore: { socket }
  } = useStores();
  const { translate } = useTranslation();
  const [queryString, setQueryString] = useState("");
  const [offset, setOffset] = useState(0);
  const queryStringDebounced = useDebounce(queryString, 300);
  const queryClient = useQueryClient();

  useEffect(() => {
    setOffset(0);
  }, [queryStringDebounced]);

  const listQueryParams: {
    spaceId: string;
    offset: number;
    limit: number;
    name?: string;
  } = {
    spaceId: activeSpaceId,
    offset,
    limit: GROUPS_HOME_LIST_LIMIT,
    name: queryStringDebounced
  };

  const { data, isFetching, isError } = useGroupsPaginated(listQueryParams);
  const { results = [], total, unfilteredTotal } = data || {};

  useEffect(() => {
    if (!isError) return;
    cleanLastActiveSpaceLocalStorage();
    navigateTo(createUrl("home"));
  }, [isError]);

  useSocket({
    socket,
    domain: DOMAIN.TEMPLATING_DOMAIN,
    event: EVENT.GROUP_CLONING_COMPLETED,
    handler: ({ data }: TemplatingGroupCloningCompleted) => {
      const el = results.find(el => el?.group?.id === data?.groupId);
      if (el?.group) {
        el.group.cloningCompleted = true;
        queryClient.invalidateQueries(
          groupsQueryKey.groupsPaginated(listQueryParams)
        );
      }
    }
  });

  if (!queryStringDebounced && (!results || results.length === 0))
    return <GroupsEmptyState />;

  return (
    <>
      <S.HeaderContainer id="tileContainer">
        <Box flex={1}>
          <Heading level="5">
            {translate({ text: "space_groups_title" })} ({unfilteredTotal})
          </Heading>
          <S.Subtitle type="formSubtitle">
            {translate({ text: "space_groups_subtitle" })}
          </S.Subtitle>
        </Box>
        {!activeSpace?.isTemporary && <CreateOrJoinGroup />}
      </S.HeaderContainer>

      <SearchBar
        theme={greyTheme}
        label="searchQuery"
        value={queryString}
        onChange={event => {
          setQueryString(event.target.value);
        }}
        placeholder={translate("space_search_group_placeholder")}
      />
      <S.ListContainer>
        {results.map(item => {
          const {
            group,
            space,
            isTeacher,
            usersCount,
            boardsCount,
            completedBoardElementsCount,
            boardElementsCount,
            nextBoardElement
          } = item || {};
          const isLoading = group?.cloningCompleted === false;
          return (
            <Skeleton
              loading={isFetching}
              borderRadius={20}
              key={`${group.id}-${activeSpaceId}`}
            >
              <CardLesson
                isLoading={isLoading}
                theme={greyTheme}
                title={group.name}
                imgUrl={handleThumbnails(groupGetCover(group), "large")}
                hideOptions={!isTeacher}
                onOptionsClick={() =>
                  navigateTo(
                    createUrl("group_settings", {
                      group_id: group.id,
                      option: "information"
                    })
                  )
                }
                onClick={() => {
                  navigateTo(
                    createUrl("wall", {
                      group_id: group.id,
                      space_slug: space.slug
                    })
                  );
                }}
                usersLabel={`${usersCount} ${translate("space_group_card_users")}`}
                completedBoardElementsCount={completedBoardElementsCount}
                boardsCount={boardsCount}
                boardElementsCount={boardElementsCount}
                nextBoardElement={nextBoardElement}
                onNextBoardElementClick={() =>
                  navigateTo(
                    createUrl("module_element", {
                      group_id: group.id,
                      module_id: nextBoardElement.boardId as string,
                      element_id: nextBoardElement.id as string
                    })
                  )
                }
                completedText={translate("space_group_card_completed")}
                emptyText={translate("space_group_card_empty")}
                onCreateModuleClick={() =>
                  navigateTo(
                    createUrl("modules", {
                      group_id: group.id
                    })
                  )
                }
                recordNavigationText={translate(
                  "space_group_card_record_navigation"
                )}
                noModulesText={translate("space_group_card_no_modules")}
              />
            </Skeleton>
          );
        })}
      </S.ListContainer>
      <S.RightAligner>
        <PageNav
          theme={greyTheme}
          totalRecords={total}
          pageLimit={GROUPS_HOME_LIST_LIMIT}
          startingOffset={offset}
          onPageChanged={p => setOffset((p - 1) * GROUPS_HOME_LIST_LIMIT)}
          label={{
            previous: translate({ text: "prev" }),
            next: translate({ text: "next" })
          }}
        />
      </S.RightAligner>
    </>
  );
};

export default observer(GroupsList);
