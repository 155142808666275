import { Box } from "@ds_universal/Box";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";

const FakeInputWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.blueGrey[400]};
  width: 100%;
  min-height: 45px;
  border-radius: 100px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 400;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  @media (max-width: 768px) {
    background: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FakeInput = ({
  children,
  disabled,
  onEdit,
  onCopy
}: {
  children: React.ReactNode;
  disabled?: boolean;
  onEdit?: () => void;
  onCopy?: () => void;
}) => {
  return (
    <FakeInputWrapper disabled={disabled}>
      <StyledText type="formFieldEmpty">{children}</StyledText>
      {onEdit || onCopy ? (
        <Box display="flex" gap="8px" marginLeft="auto">
          {onEdit ? (
            <IconButton disabled={disabled} onClick={disabled ? null : onEdit}>
              <Icon icon="pencil" />
            </IconButton>
          ) : null}
          {onCopy ? (
            <IconButton disabled={disabled} onClick={disabled ? null : onCopy}>
              <Icon icon="copyImport" />
            </IconButton>
          ) : null}
        </Box>
      ) : null}
    </FakeInputWrapper>
  );
};

export default FakeInput;
