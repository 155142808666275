import { WeSchoolRouteMapType } from "src/js/pages/PagesRouter.types";
import { TranslationKeys } from "src/js/translation";

export const WeSchoolPageTitles: WeSchoolRouteMapType<TranslationKeys> = {
  App: {
    externalAuth: "external_auth_meta_title",
    home: "top_header_title_home_context",
    joinCodiceOption: "join_codice_option_meta_title",
    changeMail: "change_mail_meta_title",
    logout: "logout_meta_title",
    search: "search_meta_title",
    share: "share_meta_title",
    underageApprovation: "underage_approvation_meta_title",
    privacyPolicy: "privacy_policy_meta_title",
    Users: {
      initialRouteName: "users_initial_route_meta_title",
      userProfile: "user_profile_meta_title"
    },
    Spaces: {
      initialRouteName: "spaces_initial_route_meta_title",
      spaceGroups: "my_groups",
      spaceGroupsOverquotaBlocked: "space_groups_overquota_blocked_meta_title",
      spaceChats: "chat_meta_title",
      spaceNotifications: "notifications_page_title",
      SpaceGroup: {
        initialRouteName: "space_group_initial_meta_title",
        groupId: "group_id_meta_title",
        blocked: "group_blocked_meta_title",
        calendar: "calendar_tab_title",
        calendarActivity: "event_activity_meta_title",
        calendarAgenda: "calendar_tab_title",
        calendarDeadline: "event_deadline_meta_title",
        calendarLive: "event_live_meta_title",
        exerciseInstant: "exercise_instant_meta_title",
        exerciseInstantLive: "exercise_instant_live_meta_title",
        exerciseRun: "exercise_run_meta_title",
        groupOverquotaBlocked: "group_overquota_blocked_meta_title",
        settings: "group_settings_meta_title",
        whiteboard: "whiteboard_meta_title",
        modules: "boards_meta_title",
        Activity: {
          initialRouteName: "activity_meta_title",
          new: "activity_new_meta_title",
          mode: "activity_meta_title"
        },
        Boards: {
          initialRouteName: "boards_meta_title",
          boardNew: "boards_meta_title",
          boardId: "boards_meta_title",
          boardElementEdit: "boards_meta_title",
          boardElementResource: "boards_meta_title",
          boardElementResourceComments: "boards_meta_title"
        },
        Exercises: {
          initialRouteName: "exercises_meta_title",
          exercisesDrafts: "exercises_meta_title",
          exercisesNew: "exercises_new_meta_title",
          exercise: "exercise_meta_title",
          exerciseRun: "exercises_run_meta_title",
          exerciseRunOption: "exercises_run_meta_title",
          exerciseEdit: "exercise_edit_meta_title",
          exerciseInstant: "exercise_instant_meta_title",
          exerciseInstantLive: "exercise_instant_live_meta_title",
          exerciseInstantRun: "exercises_run_meta_title",
          exerciseReportsSession: "report_meta_title",
          exerciseReportsUsers: "exercises_reports_meta_title"
        },
        Modules: {
          initialRouteName: "boards_meta_title",
          newModule: "boards_new_meta_title",
          module: "module_meta_title",
          moduleElement: "module_element_meta_title",
          moduleElementEdit: "module_element_edit_meta_title",
          moduleElementComments: "module_element_comments_meta_title",
          moduleMode: "module_mode_meta_title"
        },
        Post: {
          edit: "wall_new_post_meta_title",
          post: "post_meta_title",
          postReport: "report_abuse_meta_title",
          postResource: "post_meta_title",
          postResourceEdit: "post_meta_title"
        },
        issueReport: "report_issue_meta_title",
        moderation: "moderation_meta_title",
        rankingPage: "ranking_meta_title",
        resourceReport: "report_abuse_meta_title",
        resourceEdit: "resource_edit_meta_title",
        wall: "wall_meta_title",
        wallNew: "wall_new_post_meta_title"
      },
      SpaceChat: {
        initialRouteName: "chat_meta_title",
        new: "chat_meta_title",
        threadId: "chat_meta_title"
      }
    },
    Dashboard: {
      initialRouteName: "dashboard_initial_meta_title",
      space: "dashboard_space_meta_title",
      analytics: "dashboard_analytics_meta_title",
      groups: "dashboard_groups_meta_title",
      settings: "settings_label",
      users: "dashboard_users_meta_title",
      badges: "badges_header_tab",
      staff: "dashboard_staff_meta_title"
    },
    Dea: {
      initialRouteName: "dea_import_project",
      importProject: "dea_import_project",
      importProjectNode: "dea_import_lesson"
    },
    Settings: {
      initialRouteName: "settings_initial_meta_title",
      settingsProfile: "user_profile_meta_title",
      settingsProfileEdit: "profile_meta_title",
      settingsAccount: "settings_account_option_meta_title",
      settingsNotifications: "user_settings_notice_tab",
      settingsTips: "tips_tricks_title_page"
    }
  },
  Auth: {
    register: "register_page_title",
    sso: "sso_meta_title",
    joinCodiceOption: "join_codice_auth_meta_title",
    loginUniversity: "login_meta_title",
    confirmRegistration: "confirm_registration_meta_title",
    confirmRegistrationWithAlias: "confirm_registration_meta_title",
    requestPasswordReset: "request_reset_password_meta_title",
    resetPassword: "reset_password_meta_title",
    login: "login_meta_title",
    externalAuth: "external_auth_auth_meta_title",
    underageApprovation: "underage_approvation_auth_meta_title",
    userConfirmMail: "confirm_mail_meta_title",
    privacyPolicy: "privacy_policy_auth_meta_title"
  },
  UserVerification: {
    activateAccount: "activate_account_meta_title",
    logout: "activate_account_logout_meta_title"
  },
  Onboarding: {
    initOnboarding: "onboarding_init_onboarding_route_meta_title",
    resumeOnboarding: "onboarding_resume_onboarding_route_meta_title",
    resumeSpace: "onboarding_resume_space_route_meta_title",
    welcome: "onboarding_initial_route_meta_title",
    marketingData: "onboarding_marketing_data_route_meta_title",
    spaceCreation: "onboarding_space_creation_route_meta_title",
    groupCreateSelection: "onboarding_group_create_selection_meta_title",
    groupCreation: "onboarding_group_creation_route_meta_title",
    weschoolTemplateCreation: "onboarding_group_template_creation_meta_title",
    membersInvite: "onboarding_members_invite_route_meta_title",
    schoolSpaceCreation: "onboarding_school_space_creation_route_meta_title",
    schoolSpaceConfirmation:
      "onboarding_school_space_confirmation_route_meta_title",
    join: "onboarding_join_route_meta_title",
    logout: "onboarding_logout_meta_title"
  },
  Underage: {
    underageLock: "underage_lock_meta_title",
    joinCodiceOption: "join_codice_underage_meta_title",
    underageApprovation: "underage_approvation_meta_title",
    logout: "underage_logout_meta_title"
  }
};
