import React, { ReactNode } from "react";
import { Box } from "@ds_universal/Box";
import { Tooltip, TooltipContent } from "../Tooltip";

type DisabledSectionWrapperProps = {
  disabled: boolean;
  tooltipContent: ReactNode;
  children: ReactNode;
  open?: boolean;
  withPortal?: boolean;
};

const DisabledSectionWrapper = ({
  disabled,
  tooltipContent,
  children,
  open,
  withPortal = true
}: DisabledSectionWrapperProps) => {
  const tooltip = (
    <TooltipContent withPortal={withPortal}>{tooltipContent}</TooltipContent>
  );
  if (!disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <Tooltip open={open} tooltipContent={tooltip} withPortal={withPortal}>
      <Box opacity={0.5} cursor="not-allowed">
        {/* pointerEvents needs to be set separately to allow the tooltip to work */}
        <Box pointerEvents="none">{children}</Box>
      </Box>
    </Tooltip>
  );
};

export default DisabledSectionWrapper;
