import { Icon } from "@ds_universal/Icon";
import styled, { css } from "styled-components";

export const MobileContainer = styled.div`
  padding: 16px;
`;

export const DropdownWrapper = styled.div`
  margin: auto 16px 24px;
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(4, 1fr);
  gap: 0;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const MobileRow = styled.div<{ isOdd?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: ${({ isOdd, theme }) =>
    isOdd ? theme.colors.coldGrey[900] : theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  font-weight: 500;
  font-size: 14px;
  height: 42px;
`;

export const TableCell = styled.div<{
  header?: boolean;
  isOdd?: boolean;
  leftAlignment?: boolean;
}>`
  display: flex;
  justify-content: ${({ leftAlignment }) =>
    leftAlignment ? "flex-start" : "center"};
  align-items: center;
  padding: 4px 12px;
  background-color: ${({ isOdd, theme }) =>
    isOdd ? theme.colors.grey[1000] : theme.colors.grey[900]};
  color: ${({ theme }) => theme.colors.grey[50]};
  font-weight: 600;
  font-size: 12px;
  height: 38px;

  ${({ header }) =>
    header &&
    css`
      font-weight: 500;
      font-size: 14px;
      height: unset;
      text-align: center;
    `}
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
