import {
  Box,
  Button,
  Heading,
  ModalSizeType,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { showToastSuccess } from "src/js/modules/messageManager";
import { StaffUserCard } from "src/js/pages/spaces/dashboard/Staff/components/StaffUserCard";
import { ToggleBlock } from "src/js/pages/spaces/dashboard/Staff/components/ToggleBlock";
import { useMutation } from "src/js/query/hooks";
import { staffQueryKey } from "src/js/query/staff";
import {
  revokeSpaceUserRole,
  updateSpaceUserRole
} from "src/js/repository/dashboardRepository";
import { Trans } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SpaceUserRoleEnum, StandardPaginationResponse } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { StaffMember } from "src/js/types/models/Staff";
import { useTheme } from "styled-components";
import * as S from "./EditStaffModal.styles";

const EditStaffModal = ({
  user,
  listQueryParams
}: {
  user: Partial<StaffMember>;
  listQueryParams: {
    spaceId: string;
    offset: number;
    limit: number;
    name?: string;
  };
}) => {
  const {
    SpaceStore: { activeSpaceName, activeSpaceId },
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal },
    UserStore: { activeUser }
  } = useStores();
  const [accessPermission, setAccessPermission] = useState(true);
  const [createGroupPermission, setCreateGroupPermission] = useState(true);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { name, surname, email, avatar, id } = user;
  const disabled = id === activeUser?.id;

  useEffect(() => {
    if (user.role === SpaceUserRoleEnum.PowerUser) {
      setAccessPermission(false);
      setCreateGroupPermission(true);
    } else {
      setAccessPermission(true);
      setCreateGroupPermission(true);
    }
  }, []);

  useEffect(() => {
    if (!createGroupPermission) setAccessPermission(false);
  }, [createGroupPermission]);

  useEffect(() => {
    if (accessPermission) setCreateGroupPermission(true);
  }, [accessPermission]);

  const validatePermission = () => {
    if (accessPermission && createGroupPermission) {
      return SpaceUserRoleEnum.Admin;
    }
    if (accessPermission || createGroupPermission) {
      return SpaceUserRoleEnum.PowerUser;
    }
    return null;
  };

  const { mutate: updateRole } = useMutation(
    (params: { spaceId: string; userId: number; role: SpaceUserRoleEnum }) =>
      updateSpaceUserRole({ ...params }),
    {
      updateQueryKey: () => staffQueryKey.members(listQueryParams),
      onUpdateData: (
        oldData: StandardPaginationResponse<StaffMember> & {
          unfilteredTotal: number;
        },
        params
      ) => {
        const newUsers = oldData.results.map(user => {
          if (user.id === params.userId) {
            return { ...user, role: params.role };
          }
          return user;
        });
        return { ...oldData, results: newUsers };
      },
      onSuccess(_data, variables) {
        showToastSuccess({
          str: (
            <Trans
              i18nKey={
                variables.role === SpaceUserRoleEnum.Admin
                  ? "staff_global_toast_permission_updated_full"
                  : "staff_global_toast_permission_updated_partial"
              }
              values={{ spaceName: activeSpaceName }}
            />
          )
        });
      },
      onStartMutating() {
        closeModal();
      },
      onErrorMessage: "staff_global_toast_permission_update_error"
    }
  );

  const { mutate: removeRole } = useMutation(
    (params: { spaceId: string; userId: number }) =>
      revokeSpaceUserRole({ ...params }),
    {
      updateQueryKey: () => staffQueryKey.members(listQueryParams),
      onUpdateData: (
        oldData: StandardPaginationResponse<StaffMember> & {
          unfilteredTotal: number;
        },
        params
      ) => {
        const newUsers = oldData.results.filter(
          user => user.id !== params.userId
        );
        return {
          ...oldData,
          results: newUsers,
          total: oldData.total - 1,
          unfilteredTotal: oldData.unfilteredTotal - 1
        };
      },
      onStartMutating() {
        closeModal();
      },
      onErrorMessage: "staff_global_toast_permission_update_error"
    }
  );

  const handleSubmit = ({ role, userId }) => {
    if (role) {
      updateRole({ spaceId: activeSpaceId, role, userId });
    } else {
      removeRole({ spaceId: activeSpaceId, userId });
    }
  };

  const modalBody = (
    <S.ModalContentContainer>
      <Box margin="0 0 8px">
        <Heading level="5">
          {translate("staff_modify_permissions_managing")}
        </Heading>
      </Box>
      <StaffUserCard
        firstname={name}
        lastname={surname}
        email={email}
        avatar={avatar?.small}
      />
      <Box margin="24px 0 4px">
        <Heading level="5">
          {translate({
            text: "staff_modify_permissions_update",
            stringVariables: { userName: name }
          })}
        </Heading>
      </Box>
      <Box margin="0 0 24px">
        <Text type="body">
          {translate("staff_modify_permissions_description")}
        </Text>
      </Box>
      <ToggleBlock
        marginBottom={12}
        isSelected={createGroupPermission}
        disabled={disabled}
        onClick={() => setCreateGroupPermission(prevState => !prevState)}
        icon="plusCircle"
        tooltipTitle={translate(
          "staff_modify_permissions_tooltip_create_groups"
        )}
        tooltipDescription={translate(
          "staff_modify_permissions_tooltip_create_groups_description"
        )}
      >
        <Text type="formField">
          {translate({
            text: "spaces_group_creation_permission_edit_modal_bold_label",
            stringVariables: { spaceName: activeSpaceName }
          })}
        </Text>
      </ToggleBlock>
      <ToggleBlock
        isSelected={accessPermission}
        disabled={disabled}
        onClick={() => {
          setAccessPermission(prevState => !prevState);
        }}
        icon="compassEmpty"
        tooltipTitle={translate(
          "staff_modify_permissions_tooltip_access_dashboard"
        )}
        tooltipDescription={translate(
          "staff_modify_permissions_tooltip_access_dashboard_description"
        )}
      >
        <Text type="formField">
          {translate("spaces_access_permission_edit_modal_bold_label")}
        </Text>
      </ToggleBlock>
    </S.ModalContentContainer>
  );

  return (
    <ModalLayout
      labels={{
        title: translate("staff_modify_permissions_title"),
        mobileClose: translate("close")
      }}
      closeModal={closeModal}
      desktopProps={{
        size: ModalSizeType.Big
      }}
    >
      {modalBody}
      <S.ModalFooterContainer>
        <S.ButtonContainer>
          {!isLayoutModeMobile ? (
            <Button onClick={closeModal} variant="secondary" theme={whiteTheme}>
              {translate("staff_modify_permissions_cancel_button")}
            </Button>
          ) : null}
          <Button
            id={UsetifulId.StaffPermissionUpdate}
            onClick={() =>
              handleSubmit({
                role: validatePermission(),
                userId: user.id
              })
            }
            disabled={disabled}
            variant="primary"
            theme={whiteTheme}
            fullWidth={isLayoutModeMobile}
          >
            {translate("staff_modify_permissions_update_button")}
          </Button>
        </S.ButtonContainer>
      </S.ModalFooterContainer>
    </ModalLayout>
  );
};

export default observer(EditStaffModal);
