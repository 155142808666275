import React from "react";
import { Box, Text } from "@arcadia/design-system";
import { OnboardingTestimonialProps } from "src/js/pages/Onboarding/Onboarding.types";
import * as S from "./Testimonial.styles";

const Testimonial = ({
  background,
  image,
  text,
  author,
  brand
}: OnboardingTestimonialProps) => {
  return (
    <Box
      background={`url(${background})`}
      height="100vh"
      minHeight="735px"
      justifyContent="center"
      alignItems="center"
      display="flex"
      backgroundSize="cover"
    >
      <S.Testimonial>
        <S.TestimonialImage src={image} alt="Testimonial Image" />
        <S.TestimonialText>
          <S.TestimonialMessage>{text}</S.TestimonialMessage>
          {author ? <Text type="captionAlert">{author}</Text> : null}
        </S.TestimonialText>
        {brand ? (
          <S.TestimonialBrand src={brand} alt="Testimonial Brand" />
        ) : null}
      </S.Testimonial>
    </Box>
  );
};

export default Testimonial;
