import moment from "moment";
import { __BOARD_ELEMENT_TITLE_MAX_LENGTH__ } from "src/js/settings/settingsCharLenght";
import { userIsUnderage } from "../UserFunction";
import { __GROUP_DESCRIPTION_LIMIT__ } from "./settingsGroups";

export const __FORM_GROUP_INFO__ = {
  name: {
    required: "error_validation_group_name",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([\w*\W*\.\u00C0-\u017F '-]{2,50})$/,
      message: "error_validation_group_name_min_2_to_50"
    }
  },
  groupType: {
    required: "error_choose_group_type"
  }
};

export const __FORM_GROUP_RESEND_MAIL__ = {
  email: {
    required: "Inserire una mail valida",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value:
        /^([\w'-.+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$/i,
      message: "Inserire una mail valida"
    }
  }
};

export const __FORM_REGISTRATION__ = {
  name: {
    required: "Inserire un nome (massimo 50 caratteri)",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "Inserire un nome (massimo 50 caratteri)"
    }
  },
  surname: {
    required: "Inserire un cognome (massimo 50 caratteri)",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "Inserire un cognome (massimo 50 caratteri)"
    }
  },
  email: {
    required: "Inserire una mail valida",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "Inserire una mail valida"
    }
  },
  birthday: {
    required: "Inserire una data di nascita"
  },
  password: {
    required: "registration_password_error",
    pattern: {
      value: /^.{12,50}$/,
      message: "password_validation_error"
    }
  },
  terms: {
    required: "registration_terms_privacy_validation_error"
  }
};

export const __FORM_SHARE__ = {
  title: {
    required: "board_element_title_validation_error",
    maxLength: {
      value: __BOARD_ELEMENT_TITLE_MAX_LENGTH__,
      message: "board_element_title_validation_error"
    }
  }
};

export const __FORM_REQUEST_RESET_PASSWORD__ = {
  email: {
    required: "request_reset_password_email_required_error",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "email_validation_error"
    }
  }
};

export const __FORM_UNDERAGE__ = {
  name: {
    required: "underage_name_required_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "underage_name_validation_error"
    }
  },
  surname: {
    required: "underage_surname_required_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "underage_surname_validation_error"
    }
  },
  email: {
    required: "underage_email_required_error",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "underage_email_validation_error"
    }
  },
  birthday: {
    required: "underage_birthday_required_error",
    validate: value => {
      return (
        userIsUnderage({ birthday: moment(value, "dd-MM-yyyy").format() }) ||
        "underage_birthday_validation_error"
      );
    }
  },
  password: {
    required: "underage_password_required_error",
    pattern: {
      value: /^.{12,50}$/,
      message: "underage_password_validation_error"
    }
  }
};

export const __FORM_TUTOR__ = {
  name: {
    required: "parent_name_required_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "parent_name_validation_error"
    }
  },
  surname: {
    required: "parent_surname_required_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "parent_surname_validation_error"
    }
  },
  email: {
    required: "parent_email_required_error",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "parent_email_validation_error"
    }
  },
  birthday: {
    required: "parent_birthday_required_error",
    validate: value => {
      return (
        !userIsUnderage({ birthday: moment(value, "dd-MM-yyyy").format() }) ||
        "parent_birthday_validation_error"
      );
    }
  },
  terms: {
    required: "parent_terms_validation_error"
  }
};

export const __FORM_CONTRACT__ = {
  terms4: {
    required: "terms4_validation_error"
  }
};

export const __FORM_MANUAL_JOIN__ = {
  groupCode: {
    required: "group_code_validation_error"
  }
};

export const __FORM_SPACES_GENERAL_SETTINGS__ = {
  name: {
    required: "space_settings_general_warning_field",
    maxLength: {
      value: 250,
      message: "space_settings_general_name_validation_error"
    }
  },
  description: {
    required: false
  },
  layoutColor: {
    required: "layout_color_validation_error"
  }
};

export const __FORM_CALENDAR_EVENT__ = {
  eventTitle: {
    required: "calendar_modal_event_title_validation_error"
  },
  fallbackVendor: {
    required: "calendar_modal_event_fallback_url_validation_error",
    pattern: {
      value: /^http(s)?:\/\/(.)*$/,
      message: "calendar_modal_event_fallback_invalid_url"
    }
  }
};

export const __FORM_DELETE_ACCOUNT__ = {
  password: {
    required: "delete_account_password_error"
  },
  email: {
    required: "delete_account_email_validation_error",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "delete_account_email_validation_error"
    }
  }
};

export const __FORM_CREATE_BUSINESS_GROUP__ = {
  name: {
    required: "group_creation_form_name_validation_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([\w*\W*\.\u00C0-\u017F '-]{2,50})$/,
      message: "error_validation_group_name_min_2_to_50"
    }
  },
  description: {
    maxLength: {
      value: __GROUP_DESCRIPTION_LIMIT__,
      message: "error_validation_group_description_max_255"
    }
  },
  spaceId: {
    required: "group_creation_form_space_validation_error"
  }
};

export const __FORM_CREATE_SCHOOL_GROUP__ = {
  name: {
    required: "group_creation_form_name_validation_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([\w*\W*\.\u00C0-\u017F '-]{2,50})$/,
      message: "error_validation_group_name_min_2_to_50"
    }
  },
  description: {
    maxLength: {
      value: __GROUP_DESCRIPTION_LIMIT__,
      message: "error_validation_group_description_max_255"
    }
  },
  spaceId: {
    required: "group_creation_form_space_validation_error"
  },
  subject: {
    required: "group_creation_form_subject_validation_error"
  },
  specifySubject: {
    required: "group_creation_form_specify_subject_validation_error"
  },
  schoolType: {
    required: "group_creation_form_school_type_validation_error"
  },
  schoolSpecialization: {
    required: "group_creation_form_school_specialization_validation_error"
  }
};

export const __FORM_CREATE_GROUP__ = {
  name: {
    required: "error_validation_group_name_min_2_to_50",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([\w*\W*\.\u00C0-\u017F '-]{2,50})$/,
      message: "error_validation_group_name_min_2_to_50"
    }
  },
  description: {
    maxLength: {
      value: 255,
      message: "error_validation_group_description_max_255"
    }
  },
  spaceId: {
    required: "group_creation_form_space_validation_error"
  },
  subject: {
    required: "group_creation_form_subject_validation_error"
  },
  specifySubject: {
    required: "group_creation_form_specify_subject_validation_error",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([\w*\W*\.\u00C0-\u017F '-]{2,50})$/,
      message: "error_validation_group_subj_min_2_to_50"
    }
  },
  schoolType: {
    required: "group_creation_form_school_type_validation_error"
  },
  schoolSpecialization: {
    required: "group_creation_form_school_specialization_validation_error"
  }
};
