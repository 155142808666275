import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 520px;

  & #dropdown-multiple-list {
    max-height: 152px;
  }
`;
export const Buttons = styled.div`
  display: flex;
  gap: 12px;
  align-self: center;
  width: fit-content;
  margin-top: auto;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 16px;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
