import {
  Button,
  FabButton,
  getHighContrastColor,
  LinkButton
} from "@arcadia/design-system";
import styled from "styled-components";

export const PageContainer = styled.div`
  @media (max-width: 768px) {
    padding: 16px 0 120px;
  }
`;

export const DescriptionContainer = styled.div`
  & strong {
    display: block;
  }
`;

export const Column = styled.div<{ width?: string }>`
  width: ${({ width }) => width || "20%"};
  padding-right: 20px;
`;

export const Counter = styled.div`
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  padding: 0 10px;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => getHighContrastColor(theme.primaryColor[500])};
`;

export const StaffTitleContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StaffListHeader = styled.div`
  display: flex;
  padding-left: 26px;
  padding-right: 30px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.grey[500]};
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  > span {
    white-space: nowrap;
  }
`;

export const ActionColumn = styled.div`
  width: 44px;
  direction: rtl;
`;

export const StyledFabButton = styled(FabButton)`
  z-index: 2;
`;

export const StyledLinkButton = styled(LinkButton)`
  padding: 0;
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
`;

export const SearchbarWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

export const RightAligner = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
