import { scrollbarMixin, VariantType } from "@arcadia/design-system";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  text-align: center;
  padding: 8px 8px 16px;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 0 12px;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 16px 8px 0 8px;
  }
`;

export const ScrollableArea = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 0 8px;
  & > div:last-child {
    margin-bottom: 72px;
  }
  ${scrollbarMixin(VariantType.White)}
  @media screen and (max-width: 768px) {
    ${scrollbarMixin(VariantType.Grey)}
  }
`;

export const BlurRow = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.grey[1000]}00 0%,
    ${theme.colors.grey[1000]}80 44.27%,
    ${theme.colors.grey[1000]}FF 100%
  )`};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
