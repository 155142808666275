/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import { queryClient } from "src/js/query/client";
import { userQueryKey } from "src/js/query/user";
import fetchAccessToken from "src/js/repository/authenticationRepository";
import { User } from "src/js/types";
import { getWebSocketAuthToken } from "../repository/webSocketRepository";
import { WSAuthToken, WSAuthTokenResponse } from "../types/models/WebSocket";

/**
 * @name AuthStore
 *
 * @description
 * ...
 */
class AuthStore {
  userLoggedInSuccessfully = false;
  private fetchUser: () => Promise<User>;
  private getIsLogged: () => boolean;
  private $authToken: WSAuthToken;

  constructor({
    fetchUser,
    getIsLogged
  }: {
    fetchUser: () => Promise<User>;
    getIsLogged: () => boolean;
  }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchUser = fetchUser;
    this.getIsLogged = getIsLogged;
    this.$authToken = null;
  }

  async loginUser(username: string, password: string) {
    await fetchAccessToken(username, password);
    const user = await this.fetchUser();
    await this.refreshWebSocketToken(true);
    queryClient.setQueryData(userQueryKey.me, user);
    this.userLoggedInSuccessfully = true;
  }

  setAuthToken = (token: WSAuthToken) => {
    this.$authToken = token;
  };

  tokenIsExpired = () => {
    const authTokenExpiresAt = new Date(this.$authToken?.expiresAt);
    if (Number.isNaN(authTokenExpiresAt.getTime())) {
      return true;
    }
    const now = new Date();
    return now.getTime() >= authTokenExpiresAt.getTime();
  };

  refreshWebSocketToken = async (force?: boolean) => {
    if (!this.tokenIsExpired() && force !== true) return;
    const authJWT = (await getWebSocketAuthToken()) as WSAuthTokenResponse;
    this.setAuthToken({
      value: authJWT.data.jwt,
      expiresAt: authJWT.data.expiresAt
    } as WSAuthToken);
  };

  resetUserLoggedInSuccessfully() {
    this.userLoggedInSuccessfully = false;
  }

  resetUser(callback = () => {}) {
    return this.fetchUser()
      .then(() => {
        callback();
      })
      .catch(error => {
        throw error;
      });
  }

  // computed
  get userIsLogged() {
    return this.getIsLogged();
  }

  get authToken() {
    return this.$authToken;
  }
}

export default AuthStore;
