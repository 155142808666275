import axios, { CancelTokenSource } from "axios";
import { useEffect } from "react";
import slug from "slug";
import { useStores } from "src/js/hooks";
import {
  getResourceLinkPreview,
  getUploadFileActionUrl,
  saveResourceDropbox,
  saveResourceGDrive,
  saveResourceText
} from "src/js/repository/resourceRepository";
import { TranslationKeys } from "src/js/translation";
import { RESOURCE_TYPE, ResourceType } from "../../types";
import { checkSCORMValidityAsync } from "./utils";

slug.defaults.mode = "rfc3986";

export type ResponseFileData = {
  fileUuid: string;
  fileUrl: string;
  file: File;
  name: string;
  type: ResourceType;
};

let source: CancelTokenSource;

const useResourceUpload = (
  setUploadingProgress?: (id: number, progress: number) => void
) => {
  const {
    SpaceStore: { activeSpaceSlug: spaceSlug },
    WebSocketStore: { socket }
  } = useStores();

  useEffect(() => {
    source = axios.CancelToken.source();
  }, []);

  const cancelRemainingUploads = () => {
    return source.cancel("Operation canceled");
  };

  const handleResourceFileUpload = async (
    filePayload: {
      file: File;
      spaceSlug: string;
      uploadingElementId: number;
    },
    isScorm: boolean,
    onSuccess: (data: ResponseFileData) => void,
    onFailure?: (err: TranslationKeys) => void,
    type?: ResourceType
  ) => {
    let responseData: ResponseFileData = null;
    const handleType = () => {
      if (type) {
        return type;
      }
      return isScorm ? RESOURCE_TYPE.SCORM : RESOURCE_TYPE.FILE;
    };

    const urlData = await getUploadFileActionUrl({
      filePayload,
      isScorm,
      fileType: handleType()
    });

    if (urlData.inputs) {
      const inputs = Object.entries(urlData.inputs);
      const formData = new FormData();

      inputs.forEach(input => {
        formData.append(input[0], input[1]);
      });

      const fileNameWithUuid = slug(
        `${urlData.fileUuid}_${filePayload.file.name}`
      );

      formData.append("file", filePayload.file, fileNameWithUuid);

      responseData = {
        fileUuid: urlData.fileUuid,
        fileUrl: `https://${urlData.inputs.bucket}/${urlData.inputs.key}`,
        file: filePayload.file,
        name: filePayload.file.name,
        type: handleType()
      };

      if (setUploadingProgress) {
        setUploadingProgress(filePayload.uploadingElementId, 1);
      }

      axios
        .post(urlData.actionUrl, formData, {
          onUploadProgress: ProgressEvent => {
            if (setUploadingProgress) {
              const percentage =
                (ProgressEvent.loaded / ProgressEvent.total) * 100;
              setUploadingProgress(
                filePayload.uploadingElementId,
                Math.ceil(percentage)
              );
            }
          },
          cancelToken: source.token
        })
        .then(async () => {
          if (isScorm) {
            const { isValid, error } = await checkSCORMValidityAsync({
              socket,
              scormUuid: urlData.fileUuid
            });

            if (isValid) {
              return onSuccess(responseData);
            }
            return onFailure(error);
          }

          return onSuccess(responseData);
        });
    }
  };

  const handleResourceGalleryUpload = async (
    files: File[],
    elementId,
    onSuccess: (res: {
      [propKey: string]: string;
      galleryName: string;
      fileUuid: string;
    }) => void
  ) => {
    const resourceUrls = {};
    let galleryName = "";
    let fileUuid = "";
    const precentageProgress = [];
    setUploadingProgress(elementId, 1);

    const promises = await files.map(async (file, index) => {
      precentageProgress.splice(index, 0, 0);
      let responseData: ResponseFileData = null;
      const singlePayload = {
        file,
        spaceSlug
      };

      const urlData = await getUploadFileActionUrl({
        filePayload: singlePayload
      });

      if (urlData.inputs) {
        const inputs = Object.entries(urlData.inputs);
        const formData = new FormData();

        inputs.forEach(input => {
          formData.append(input[0], input[1]);
        });

        const fileNameWithUuid = slug(
          `${urlData.fileUuid}_${singlePayload.file.name}`
        );

        if (index === 0) {
          galleryName = fileNameWithUuid;
          fileUuid = urlData.fileUuid;
        }

        formData.append("file", singlePayload.file, fileNameWithUuid);

        responseData = {
          fileUuid: urlData.fileUuid,
          fileUrl: `https://${urlData.inputs.bucket}/${urlData.inputs.key}`,
          file: singlePayload.file,
          name: fileNameWithUuid,
          type: RESOURCE_TYPE.IMAGE_GALLERY
        };

        await axios
          .post(urlData.actionUrl, formData, {
            onUploadProgress: ProgressEvent => {
              if (setUploadingProgress) {
                const percentage =
                  (ProgressEvent.loaded / ProgressEvent.total) * 100;
                const globalPercentage = Math.ceil(
                  precentageProgress.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  ) / files.length
                );
                precentageProgress[index] = percentage;
                setUploadingProgress(elementId, globalPercentage);
              }
            },
            cancelToken: source.token
          })
          .then(() => {
            resourceUrls[responseData.fileUuid] = responseData.fileUrl;
          });
      }
    });
    await Promise.all(promises).then(() => {
      onSuccess({ ...resourceUrls, galleryName, fileUuid });
    });
  };

  const handleResourceLinkUpload = async (
    link: string,
    elementId: number,
    altTitle?: string,
    altUrl?: string
  ) => {
    const linkPreviewResponse = await getResourceLinkPreview(
      link,
      spaceSlug,
      elementId,
      setUploadingProgress,
      altTitle,
      altUrl
    );

    return linkPreviewResponse;
  };

  const handleResourceTextUpload = async (
    title: string,
    content: string,
    elementId: number,
    aiTransactionId?: string
  ) => {
    const textResourceResponse = await saveResourceText(
      content,
      title,
      spaceSlug,
      elementId,
      setUploadingProgress,
      aiTransactionId
    );

    return textResourceResponse;
  };

  const handleResourceGDriveUpload = async (
    token: string,
    title: string,
    originalUrl: string,
    elementId: number,
    fileSize: number
  ) => {
    const driveResourceResponse = await saveResourceGDrive(
      token,
      title,
      spaceSlug,
      originalUrl,
      elementId,
      fileSize,
      setUploadingProgress
    );

    return driveResourceResponse;
  };

  const handleResourceDropboxUpload = async (
    file: { name: string; bytes: number; thumbnailLink: string },
    originalUrl: string,
    elementId: number
  ) => {
    const dropboxResourceResponse = await saveResourceDropbox(
      originalUrl,
      file,
      spaceSlug,
      elementId,
      setUploadingProgress
    );

    return dropboxResourceResponse;
  };

  return {
    handleResourceFileUpload,
    handleResourceLinkUpload,
    handleResourceTextUpload,
    handleResourceGDriveUpload,
    handleResourceDropboxUpload,
    handleResourceGalleryUpload,
    cancelRemainingUploads
  };
};

export default useResourceUpload;
