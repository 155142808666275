import { scrollbarMixin } from "@ds_themes/mixin";
import { VariantType } from "@ds_themes/types";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import styled from "styled-components";

export const EmailInviteWrapper = styled.div<{ disabled: boolean }>`
  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.primaryColor[500]};
  background: ${({ theme }) => theme.colors.grey[1000]};
  overflow: hidden;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};
  ${scrollbarMixin(VariantType.Grey)}
  height: 280px;
  overflow: auto;
  @media (max-width: 767px) {
    height: unset;
    overflow: unset;
  }
`;

export const EmailInviteContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  width: 100%;
`;

export const InputQuery = styled.input`
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-width: 200px;
  width: 100%;
  max-width: 240px;
  padding: 8px 0;
`;

// Chip

export const ChipItemText = styled(Text)`
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  max-width: calc(460px);
  @media (max-width: 767px) {
    max-width: calc(100vw - 140px);
  }
`;

export const ChipItemIcon = styled(Icon)`
  margin-left: 4px;
  color: ${({ theme }) => theme.primaryColor[500]};
  --secondary-color: ${({ theme }) => theme.primaryColor[900]};
`;

export const ChipItemDeleteBtn = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ChipItem = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 36px;
  padding: 0 12px 0 4px;
  border-radius: 100px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
`;
