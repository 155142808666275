import { EmbeddableResponse } from "src/js/types";
import { fetchResourceEmbeddable } from "src/js/repository/resourceRepository";
import { useQuery, UseQueryOptions } from "../hooks";
import { embeddableQueryKey } from "./embeddable.query";

export const useEmbeddable = (
  { publicUrl }: { publicUrl: string },
  options?: UseQueryOptions<EmbeddableResponse, Error, EmbeddableResponse>
) => {
  const { data: embeddable, ...otherData } = useQuery(
    embeddableQueryKey.embeddableResource({ publicUrl }),
    () => fetchResourceEmbeddable({ url: publicUrl }),
    options
  );

  return {
    data: embeddable,
    ...otherData
  };
};
