import { CardActivity, HighlightedLink } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC, useEffect } from "react";
import { ToastId } from "react-toastify";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { SpaceCreationModal } from "src/js/components/SpaceCreation";
import { GroupJoinWithCodeModal } from "src/js/components/GroupJoinWithCodeModal";
import JoinDemoSpaceCard from "src/js/components/layout/JoinDemoSpace/JoinDemoSpaceCard";
import { useStores } from "src/js/hooks";
import { dismissToast, showToastInfo } from "src/js/modules/messageManager";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { GroupsInvitations } from "src/js/pages/spaces/groups/GroupsInvitations";
import { FakePost } from "./FakePost";
import * as S from "./HomeEmptyState.styles";

const HomeEmptyState: FC = () => {
  const { greyTheme } = useTheme();
  const { ModalStore, SpaceStore, UIStore } = useStores();
  const {
    spacesList,
    activeSpaceName,
    canCreateGroups,
    canCreateGroupsFromHome,
    setActiveSpace
  } = SpaceStore;
  const isSpacesListEmpty = spacesList.length === 0;

  const { isLayoutModeMobile: isMobile } = UIStore;

  useEffect(() => {
    setActiveSpace(null);
  }, []);

  useEffect(() => {
    let toastId: ToastId;
    if (isSpacesListEmpty) {
      toastId = showToastInfo({
        str: `${translateString({
          text: "no_spaces_celebration_message"
        })}`
      });
    }
    return () => {
      if (toastId) {
        dismissToast(toastId);
      }
    };
  }, []);

  const createNewGroup = () => {
    ModalStore.openModal(() => <GroupCreationModal />);
  };

  const createNewSpace = () => {
    ModalStore.openModal(() => <SpaceCreationModal />);
  };

  useEffect(() => {
    if (!isSpacesListEmpty) {
      createNewGroup();
      return;
    }
    createNewSpace();
  }, []);

  const groupJoinLink = () =>
    ModalStore.openModal(() => (
      <GroupJoinWithCodeModal closeModal={ModalStore.closeModal} />
    ));

  return (
    <S.HomeEmptyLayout isMobile={isMobile}>
      {!isMobile && <S.Background />}

      <S.HomeEmptyGrid>
        <S.Header>
          <GroupsInvitations />
        </S.Header>
        <S.MainColumn>
          <FakePost isMobile={isMobile} />
        </S.MainColumn>
        <S.SidebarColumn>
          <JoinDemoSpaceCard isMobile={isMobile} />
          {isSpacesListEmpty || canCreateGroups || canCreateGroupsFromHome ? (
            <CardActivity
              theme={greyTheme}
              onClick={() =>
                isSpacesListEmpty ? createNewSpace() : createNewGroup()
              }
              size={isMobile ? "small" : "standard"}
              title={translateString({
                text: isSpacesListEmpty
                  ? "no_spaces_creation_title"
                  : "space_empty_new_group_title"
              })}
              body={`${translateString({
                text: isSpacesListEmpty
                  ? "no_spaces_creation_subtitle"
                  : "space_empty_new_group_subtitle",
                stringVariables: { spaceName: activeSpaceName || "" }
              })}`}
              type="create"
              rightIcon="plus"
            />
          ) : null}
          <HighlightedLink
            theme={greyTheme}
            description={translateString({ text: "space_empty_join_message" })}
            onClick={groupJoinLink}
          >
            {translateString({ text: "space_empty_join_link" })}
          </HighlightedLink>
        </S.SidebarColumn>
      </S.HomeEmptyGrid>
    </S.HomeEmptyLayout>
  );
};

export default observer(HomeEmptyState);

// TODO: Move logic into fn creationNewSpace
// showToastInfo({
//   str: `${translateString({
//     text: "space_empty_celebration_message",
//     stringVariables: { spaceName }
//   })}`
// });
