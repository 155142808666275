import { Box, Button, Heading } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { AwardedBadgeCard } from "./components/AwardedBadgeCard";
import { ModalAwardedBadges } from "./components/ModalAwardedBadges";
import useAwardedBadges from "./hooks/useAwardedBadges";
import * as S from "./styles";

const AwardedBadges = ({
  requestForSpecificSpace
}: {
  requestForSpecificSpace?: boolean;
}) => {
  const { getAwardedBadges, hideAwardedBadge } = useAwardedBadges();
  const {
    SpaceBadgesStore: { userAwardedBadges, updateUserAwardedBadges }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal }
  } = useStores();

  useEffect(() => {
    getAwardedBadges(requestForSpecificSpace);

    return () => updateUserAwardedBadges([]);
  }, []);

  const showBadgesModal = () => {
    openModal(() => (
      <ModalAwardedBadges
        userAwardedBadges={userAwardedBadges}
        hideAwardedBadge={hideAwardedBadge}
        requestForSpecificSpace={requestForSpecificSpace}
      />
    ));
  };

  return Array.isArray(userAwardedBadges) && userAwardedBadges.length ? (
    <Box position="relative">
      <S.HeaderContainer>
        <Box flex={1}>
          <Heading level="5">
            {translate({ text: "user_awarded_badge_title" })} (
            {userAwardedBadges.length})
          </Heading>
          <S.Subtitle type="formSubtitle">
            {translate({ text: "user_awarded_badge_subtitle" })}
          </S.Subtitle>
        </Box>
        <S.ButtonWrapper>
          <Button
            onClick={showBadgesModal}
            theme={greyTheme}
            variant="secondary"
            size="small"
          >
            {translate("user_awarded_badge_cta")}
          </Button>
        </S.ButtonWrapper>
      </S.HeaderContainer>
      <S.AwardedBadgesContainer id="awarded-badges-container">
        {userAwardedBadges.map(badge => (
          <AwardedBadgeCard
            key={badge.id}
            badge={badge}
            hideAwardedBadge={() =>
              hideAwardedBadge(badge.id, requestForSpecificSpace)
            }
          />
        ))}
      </S.AwardedBadgesContainer>
      <S.BlurRow shouldShow={userAwardedBadges.length > 2} />
    </Box>
  ) : null;
};

export default observer(AwardedBadges);
