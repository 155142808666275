import { SearchBar } from "@ds_universal/data_entry";
import React from "react";
import { useTranslation } from "src/js/translation";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import styled, { useTheme } from "styled-components";

export const StyledSearchBar = styled(SearchBar)`
  max-width: 380px;
  & input {
    height: 40px;
  }
  @media (max-width: 767px) {
    max-width: unset;
  }
`;

const GroupSettingsSearchBar = ({
  nameOrEmail,
  setNameOrEmail
}: {
  nameOrEmail: string;
  setNameOrEmail: (value: string) => void;
}) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <StyledSearchBar
      id={UsetifulId.GroupMembersSearchbar}
      value={nameOrEmail}
      theme={greyTheme}
      onChange={event => setNameOrEmail(event.target.value)}
      placeholder={translate("group_members_search_placeholder")}
    />
  );
};

export default GroupSettingsSearchBar;
