import {
  Box,
  Button,
  ContextualAlert,
  FabButton
} from "@arcadia/design-system";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import CreateEventCarouselButton from "src/js/components/CreateEventCarouselButton/CreateEventCarouselButton";
import useStores from "src/js/hooks/useStores";
import { useGroupFeatures } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { createLocalData, readLocalData } from "src/js/modules/storageFunction";
import ButtonsCarousel from "src/js/pages/wall/ButtonsCarousel/ButtonsCarousel";
import GroupWallCover from "src/js/pages/wall/GroupWallCover/GroupWallCover";
import { WallEventListPreview } from "src/js/pages/wall/WallEventListPreview";
import { WhiteboardCarouselButton } from "src/js/pages/wall/WhiteboardCarouselButton";
import { Post } from "src/js/pages/wall/post";
import { __LOCAL_GROUP_COLLAPSED_DESCRIPTION__ } from "src/js/settings/settingsLocalStorage";
import { __URL_BASE__ } from "src/js/settings/settingsUrl";
import WSTheme from "src/js/theme/theme";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { GroupSectionsEnum } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { getActiveGroup } from "../../modules/activeGroup";
import { getActiveUser } from "../../modules/activeUser";
import { basePageModel } from "../../modules/pageStatus";
import { createURL } from "../../modules/utility";
import RankMembersBox from "../ranking/rankMembersBoxView";
import NewQuickPost from "./post/NewQuickPost";
import { WallBadgeBox } from "src/js/pages/wall/WallBadgeBox";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";
import { DescriptionBox } from "src/js/pages/wall/DescriptionBox";
import { Trans } from "react-i18next";

const ContextualAlertWrapper = styled.div`
  > div {
    max-width: unset;
  }
  margin-bottom: 16px;
`;

const StyledLink = styled.a`
  all: unset;
  text-decoration: underline;
  cursor: pointer;
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.grey[50]};
    text-decoration: underline;
  }
`;

const isDescriptionCollapsed = ({ group_id }) => {
  const arrayOfGroupsWithCollapse =
    JSON.parse(readLocalData(__LOCAL_GROUP_COLLAPSED_DESCRIPTION__)) || [];
  if (arrayOfGroupsWithCollapse && arrayOfGroupsWithCollapse.includes(group_id))
    return true;
  const newArray = [...arrayOfGroupsWithCollapse, group_id];
  createLocalData(
    __LOCAL_GROUP_COLLAPSED_DESCRIPTION__,
    JSON.stringify(newArray)
  );
  return false;
};

const Wall = ({
  postsList,
  onDeletePost,
  hasNext,
  fetchNextPage,
  pinnedPost,
  onSetPinnedPost,
  appendNewPost,
  members,
  membersLoaded,
  neighborMembers,
  neighborMembersLoaded
}) => {
  const { greyTheme, whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    GroupStore: {
      isGroupSectionVisible,
      activeGroup,
      settings,
      userIsTeacher: isTeacher
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const { freezeComments } = useGroupFeatures();

  const descriptionIsCollapsed = isDescriptionCollapsed({
    group_id: activeGroup.id
  });

  useEffect(() => {
    basePageModel.set("selectedTab", "bacheca");
  }, []);

  const user = getActiveUser();
  let pinnedPostComponent = null;
  let showMoreButton = null;
  let postsMarkup = null;
  let rankingBox = null;
  let quickPostBox = null;
  let footerBox = null;
  const pointProgressBar = null;

  if (hasNext) {
    showMoreButton = (
      <div
        className="flex-centered-column
      margin-vertical-20"
      >
        <Button
          type="button"
          theme={greyTheme}
          variant="secondary"
          onClick={fetchNextPage}
        >
          {translate("load_older_posts")}
        </Button>
      </div>
    );
  }

  if (!isEmpty(pinnedPost)) {
    pinnedPostComponent = (
      <Post
        postFull={pinnedPost}
        onDeletePost={onDeletePost}
        pinned
        setPinnedFunc={() => {
          onSetPinnedPost(pinnedPost);
        }}
      />
    );
  }

  const shouldHidePostCreation =
    !isTeacher && (settings.wallPost === "disabled" || freezeComments);

  if (postsList.length > 0) {
    postsMarkup = (
      <Box display="flex" flexDirection="column" gap="24px">
        {pinnedPostComponent !== null ? pinnedPostComponent : ""}
        {postsList.map(postFull => {
          if (isEmpty(pinnedPost) || postFull.post.id !== pinnedPost.post.id) {
            return (
              <Post
                postFull={postFull}
                pinned={false}
                onDeletePost={onDeletePost}
                setPinnedFunc={() => {
                  onSetPinnedPost(postFull);
                }}
                key={`post-component-${postFull.post.id}`}
              />
            );
          }
          return null;
        })}
        {showMoreButton}
      </Box>
    );
  } else if (!shouldHidePostCreation && isLayoutModeMobile) {
    postsMarkup = (
      <div className="margin-top-20">
        <Button
          type="button"
          theme={greyTheme}
          variant="secondary"
          fullWidth={isLayoutModeMobile}
          onClick={() =>
            navigateTo(createURL("wall_new_post", { group_id: activeGroup.id }))
          }
        >
          {translate("create_first_post")}
        </Button>
      </div>
    );
  }

  if (!isLayoutModeMobile) {
    quickPostBox = <NewQuickPost appendNewPost={appendNewPost} />;
  }

  if (shouldHidePostCreation) {
    quickPostBox = null;
  }
  if (getActiveGroup().shouldShowRanking()) {
    rankingBox = (
      <RankMembersBox
        group={getActiveGroup()}
        members={members}
        membersLoaded={membersLoaded}
        neighborMembers={neighborMembers}
        neighborMembersLoaded={neighborMembersLoaded}
      />
    );
  }

  if (!isLayoutModeMobile) {
    return (
      <div className="margin-top-15">
        <Row>
          <Col sm={7} md={8} lg={7}>
            {quickPostBox}
            <Box marginTop={shouldHidePostCreation ? 0 : 24} paddingBottom={24}>
              {postsMarkup}
            </Box>
          </Col>
          <Col sm={5} md={4} lg={5}>
            {freezeComments && isTeacher && (
              <ContextualAlertWrapper>
                <ContextualAlert
                  theme={whiteTheme}
                  text={
                    <Trans
                      i18nKey="social_disabled_wall_alert_manage_settings_link"
                      components={{
                        ManageSettingsLink: (
                          <StyledLink
                            href={`#${createUrl("group_settings", {
                              group_id: activeGroup.id,
                              option: "sections"
                            })}`}
                          />
                        )
                      }}
                    />
                  }
                />
              </ContextualAlertWrapper>
            )}
            <GroupWallCover
              image={
                activeGroup && activeGroup.cover && activeGroup.cover.large
              }
              groupName={activeGroup && activeGroup.name}
            />
            <DescriptionBox
              groupDescription={activeGroup && activeGroup.description}
              isCollapsed={descriptionIsCollapsed}
            />
            <WallBadgeBox />
            {pointProgressBar}
            {rankingBox}
            {isGroupSectionVisible(GroupSectionsEnum.Calendar) ? (
              <WallEventListPreview />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }

  if (!shouldHidePostCreation) {
    footerBox = (
      <FabButton
        theme={whiteTheme}
        icon="plus"
        onClick={() =>
          navigateTo(createURL("wall_new_post", { group_id: activeGroup.id }))
        }
        variant="primary"
      />
    );
    if (!isTeacher && settings.wallPost === "disabled") {
      footerBox = "";
    }
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <GroupWallCover
            image={activeGroup && activeGroup.cover && activeGroup.cover.large}
            groupName={activeGroup && activeGroup.name}
          />
        </Col>
        <Col xs={12}>
          <div className="margin-bottom-80 margin-top-xs-195">
            {freezeComments && isTeacher && (
              <ContextualAlertWrapper>
                <ContextualAlert
                  theme={whiteTheme}
                  text={
                    <Trans
                      i18nKey="social_disabled_wall_alert_manage_settings_link"
                      components={{
                        ManageSettingsLink: (
                          <StyledLink
                            href={`#${createUrl("group_settings", {
                              group_id: activeGroup.id,
                              option: "sections"
                            })}`}
                          />
                        )
                      }}
                    />
                  }
                />
              </ContextualAlertWrapper>
            )}
            <DescriptionBox
              groupDescription={activeGroup && activeGroup.description}
              isCollapsed={descriptionIsCollapsed}
            />
            <WallBadgeBox />
            <div className="wall-button-slider-container">
              {isTeacher ? (
                <ButtonsCarousel>
                  {isGroupSectionVisible(GroupSectionsEnum.Calendar) ? (
                    <>
                      <Button
                        variant="secondary"
                        theme={greyTheme}
                        onClick={() =>
                          navigateTo(
                            createUrl("calendar", {
                              group_id: activeGroup.id
                            })
                          )
                        }
                      >
                        {translate("calendar_button_title")}
                      </Button>
                      <CreateEventCarouselButton
                        eventType={EventCategory.Live}
                      />
                      <CreateEventCarouselButton
                        eventType={EventCategory.Activity}
                      />
                      <CreateEventCarouselButton
                        eventType={EventCategory.Deadline}
                      />
                    </>
                  ) : null}
                  {isGroupSectionVisible(GroupSectionsEnum.Whiteboard) ? (
                    <WhiteboardCarouselButton group_id={activeGroup.id} />
                  ) : null}
                  <Button
                    variant="secondary"
                    theme={greyTheme}
                    onClick={() =>
                      navigateTo(
                        createUrl("group_settings", {
                          group_id: activeGroup.id,
                          option: "members"
                        })
                      )
                    }
                    withIcon={{
                      icon: "plus",
                      positionIcon: "left",
                      heightIcon: "16",
                      widthIcon: "16",
                      viewbox: "0 0 24 24"
                    }}
                  >
                    {translate("invita")}
                  </Button>
                </ButtonsCarousel>
              ) : (
                <>
                  <ButtonsCarousel>
                    {isGroupSectionVisible(GroupSectionsEnum.Calendar) ? (
                      <Button
                        variant="secondary"
                        theme={greyTheme}
                        onClick={() =>
                          navigateTo(
                            createUrl("calendar", {
                              group_id: activeGroup.id
                            })
                          )
                        }
                      >
                        {translate("calendar_button_title")}
                      </Button>
                    ) : null}
                    {isGroupSectionVisible(GroupSectionsEnum.Whiteboard) ? (
                      <WhiteboardCarouselButton group_id={activeGroup.id} />
                    ) : null}
                  </ButtonsCarousel>
                </>
              )}
            </div>
            {quickPostBox}
            {postsMarkup}
            {rankingBox}
          </div>
        </Col>
        <Box zIndex={WSTheme.zIndex.forDrawerOverlay}>{footerBox}</Box>
      </Row>
    </>
  );
};

export default Wall;
