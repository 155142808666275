import { Box, Heading, Text, Toggle } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

import * as S from "../../../SpacesSettingsCustomization.styles";

const DirectMessage = ({
  editMode,
  disableChat,
  setDisableChat
}: {
  editMode: boolean;
  disableChat: boolean;
  setDisableChat: (checked?: boolean) => void;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <>
      <Heading level="5">
        {translate("spaces_settings_customization_dm_heading")}
      </Heading>
      <Box marginTop="12px">
        <Text type="formSubtitle">
          {translate("spaces_settings_customization_dm_description")}
        </Text>
      </Box>
      <S.ToggleWrapper>
        <Toggle
          theme={whiteTheme}
          disabled={!editMode}
          title={translate("spaces_settings_customization_dm_description")}
          size="small"
          checked={!disableChat}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDisableChat(!event.target.checked);
          }}
        />
      </S.ToggleWrapper>
    </>
  );
};

export default DirectMessage;
