import {
  Avatar,
  Box,
  Button,
  Heading,
  Icon,
  Text
} from "@arcadia/design-system";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { HTMLParser } from "src/js/components/HTMLParser";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { BadgeEarnedList } from "src/js/pages/user/ProfilePage/components/BadgeEarnedList";
import { useMeQueryCached, usePublicProfileSettingsCached } from "src/js/query";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { wrapAnchorme } from "src/legacy/modules/utility";
import { useTheme } from "styled-components";

import * as S from "./ProfilePage.styles";

const ProfilePage = () => {
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { data: profile } = usePublicProfileSettingsCached();
  const { data: activeUser } = useMeQueryCached();
  const { translate } = useTranslation();

  const goToEditProfile = useCallback(() => {
    navigateTo(createUrl("user_profile_settings_edit"));
  }, []);

  return (
    <S.PageWrapper>
      {isLayoutModeMobile && (
        <Box marginBottom={8}>
          <Avatar
            theme={whiteTheme}
            url={activeUser?.avatar?.medium}
            alt={activeUser?.name}
            size="xl"
          />
        </Box>
      )}
      <S.HeaderWrapper>
        <Heading level={isLayoutModeMobile ? "3" : "4"}>
          {isLayoutModeMobile
            ? `${activeUser?.name} ${activeUser?.surname}`
            : translate("profile_page_personal_information")}
        </Heading>
        <Button
          theme={whiteTheme}
          variant="secondary"
          onClick={goToEditProfile}
        >
          {translate("edit_profile")}
        </Button>
      </S.HeaderWrapper>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <S.InfoWrapper>
          {profile?.birthday && profile?.showBirthday ? (
            <S.InfoContainer>
              <Icon width={16} height={16} icon="birthday" initialViewbox />
              <Text type="body">
                {format(new Date(profile?.birthday), "dd-MM-yyyy")}
              </Text>
            </S.InfoContainer>
          ) : undefined}
          {profile?.instagram && profile?.showInstagram ? (
            <S.InfoContainer>
              <Icon width={16} height={16} icon="instagram" initialViewbox />
              <Text type="body">{profile?.instagram}</Text>
            </S.InfoContainer>
          ) : undefined}
          {profile?.facebook && profile?.showFacebook ? (
            <S.InfoContainer>
              <Icon width={16} height={16} icon="facebook" initialViewbox />
              <Text type="body">{profile?.facebook}</Text>
            </S.InfoContainer>
          ) : undefined}
        </S.InfoWrapper>
        {profile?.status && profile?.showStatus ? (
          <S.InfoWrapper>
            <Box padding={12}>
              <Text type="formTitle">
                {translate({ text: "public_profile_bio_label" })}
              </Text>
              <div>
                <HTMLParser htmlString={wrapAnchorme(profile?.status)} />
              </div>
            </Box>
          </S.InfoWrapper>
        ) : undefined}
        {profile?.showBadges && profile.showBadges ? (
          <S.InfoWrapper>
            <BadgeEarnedList
              userUuid={activeUser?.uuid}
              userFullName={`${activeUser?.name} ${activeUser?.surname}`}
            />
          </S.InfoWrapper>
        ) : null}
      </Box>
    </S.PageWrapper>
  );
};

export default ProfilePage;
