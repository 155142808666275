import { TargetAudienceIdDTO } from "src/js/types";
import { CustomFieldsState, DTOCustomFieldMapped } from "./types";

export const prepareCustomFieldsFormData = ({
  customFields,
  targetAudience
}: {
  customFields: CustomFieldsState;
  targetAudience: TargetAudienceIdDTO;
}) => {
  const formData = new FormData();
  const dataToUpdate = Object.values(customFields).filter(
    field => field.checked === true
  );

  if (dataToUpdate.length === 0 || !targetAudience) {
    formData.append("removeCustomFields", "true");
    return formData;
  }

  let index = 0;
  dataToUpdate.forEach(field => {
    const fieldKey = Object.keys(customFields).find(
      key => customFields[key] === field
    );
    if (fieldKey) {
      const fieldIds =
        DTOCustomFieldMapped[fieldKey as keyof typeof DTOCustomFieldMapped];

      fieldIds.forEach(id => {
        formData.append(`requiredCustomFields[${index}][id]`, `${id}`);
        index += 1;
      });
    }
  });

  formData.append("targetAudience", `${targetAudience}`);

  return formData;
};
