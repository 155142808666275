import { Box, Icon, Text } from "@arcadia/design-system";
import React, { useCallback } from "react";
import { GroupPanelUsersList } from "src/js/components/GroupPanelUsersList";
import { Panel } from "src/js/components/Panel";
import { Tooltip, TooltipContent } from "src/js/components/Tooltip";
import { UserStatusBadge } from "src/js/components/UserStatusBadge";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { createStaticUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { EventDomain, TemplatingEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { DotsMenu } from "../DotsMenu";
import { ListItemProps } from "../DotsMenu/ListItem";
import * as S from "./GroupPanel.styles";
import { GroupPanelProps } from "./GroupPanel.types";
import { useGroupSelfJoinableStatus, useGroupTemplateStatus } from "./hooks";

const GroupPanel = ({
  showTooltipFirstTemplate,
  onClickDotsMenu,
  groupObject,
  isSelectable = false,
  toggleActivation,
  cloneGroup,
  showTeachers,
  ...props
}: GroupPanelProps) => {
  const { group, usersCount, teachers, isActive } = groupObject || {};
  const { name, id } = group || {};
  const { colors } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { isDemoSpace }
  } = useStores();

  const { isTemplate, setTemplate, unsetTemplate } =
    useGroupTemplateStatus(groupObject);
  const { selfJoinable, setSelfJoinable, unsetSelfJoinable } =
    useGroupSelfJoinableStatus(groupObject);

  // create dots menu items
  const menuItems: ListItemProps[] = [
    {
      id: `clone-group-${id}`,
      label: translate(isActive ? "deactivate_group" : "activate_group"),
      icon: isActive ? "block" : "group",
      onClick: () => toggleActivation({ ...group, isActive })
    }
  ];

  if (isActive) {
    menuItems.push({
      id: "clone-group",
      label: translate("space_group_list_clone_group"),
      icon: "copyImport",
      onClick: () => cloneGroup(id)
    });
  }

  if (!isDemoSpace) {
    menuItems.push(
      isTemplate
        ? {
            id: "delete-template",
            label: translate("space_group_list_delete_template"),
            icon: "cancelOutline",
            onClick: () => {
              _trackEvent(
                EventDomain.Templating,
                TemplatingEvent.UnsetCustomTemplate
              );
              unsetTemplate(id);
            }
          }
        : {
            id: "create-template",
            label: translate("space_group_list_create_template"),
            icon: "bookmarkStar",
            onClick: () => {
              _trackEvent(
                EventDomain.Templating,
                TemplatingEvent.SetCustomTemplate
              );
              setTemplate(id);
            }
          }
    );
    menuItems.push(
      selfJoinable
        ? {
            id: "cancel-self-joinable",
            label: translate("dashboard_group_card_cancel_self_joinable_cta"),
            icon: "cancelOutline",
            onClick: () => {
              unsetSelfJoinable(id);
            }
          }
        : {
            id: "set-self-joinable",
            label: translate("dashboard_group_card_set_self_joinable_cta"),
            icon: "stickyNote",
            onClick: () => {
              setSelfJoinable(id);
            }
          }
    );
  }

  const goToGroup = useCallback(() => {
    if (!id || !isActive) return;
    const url = `#${createStaticUrl("wall", {
      group_id: id
    })}`;
    window.open(url, "_blank");
  }, [id, isActive]);

  const tooltipContent = (
    <TooltipContent withPortal={false}>
      {translate("template_group_list_setup_tooltip")}
    </TooltipContent>
  );

  const dotsMenuWithToast = (isMobile: boolean) => (
    <Tooltip
      withPortal={false}
      open={showTooltipFirstTemplate}
      tooltipContent={
        isMobile ? (
          <S.ShowOnXS>{tooltipContent}</S.ShowOnXS>
        ) : (
          <S.HideOnXS>{tooltipContent}</S.HideOnXS>
        )
      }
    >
      <div>
        <DotsMenu
          onClickCustom={onClickDotsMenu}
          id={`${id}DotsMenu${isLayoutModeMobile ? "Mobile" : "Desktop"}`}
          items={menuItems}
          alignRight
        />
      </div>
    </Tooltip>
  );

  const groupProperties = (
    <S.PropertiesContainer>
      <S.PropertyLabel>
        <Icon
          icon={isTemplate ? "successCircle" : "errorCircle"}
          width="16px"
          height="16px"
          color={isTemplate ? colors.success[500] : colors.error[500]}
        />
        <Text
          type={isLayoutModeMobile ? "formDescription" : "formSmallCaption"}
        >
          {translate("dashboard_group_card_template_property")}
        </Text>
      </S.PropertyLabel>
      <S.PropertyLabel>
        <Icon
          icon={selfJoinable ? "successCircle" : "errorCircle"}
          width="16px"
          height="16px"
          color={selfJoinable ? colors.success[500] : colors.error[500]}
        />
        <Text
          type={isLayoutModeMobile ? "formDescription" : "formSmallCaption"}
        >
          {translate("dashboard_group_card_self_joinable_property")}
        </Text>
      </S.PropertyLabel>
    </S.PropertiesContainer>
  );

  return (
    <Panel {...props} isSelectable={isSelectable} height={80}>
      <S.TriggerRowArea>
        <S.InfoContainer>
          <S.TopMobile>
            <S.TopMobileTitle>
              <S.GroupNameWrap>
                <S.GroupName isActive={isActive} onClick={goToGroup}>
                  {name}
                </S.GroupName>
              </S.GroupNameWrap>
              <UserStatusBadge isActive={isActive} />
            </S.TopMobileTitle>
            <S.TopMobileActionContainer>
              {dotsMenuWithToast(true)}
            </S.TopMobileActionContainer>
          </S.TopMobile>

          <S.BottomMobile>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <S.BottomMobileTitle>
                  {translate("dashboard_groups_header_properties")}
                </S.BottomMobileTitle>
                {groupProperties}
              </Box>
              <Box display="flex" flexDirection="column">
                <S.BottomMobileTitle>{translate("users")}</S.BottomMobileTitle>
                <S.UsersCounter>{usersCount}</S.UsersCounter>
              </Box>
            </Box>
          </S.BottomMobile>

          <S.TabletAndUp>
            <S.Column large>
              <S.GroupNameWrap>
                <S.GroupName isActive={isActive} onClick={goToGroup}>
                  {name}
                </S.GroupName>
              </S.GroupNameWrap>
            </S.Column>
            <S.Column>
              <GroupPanelUsersList
                users={teachers}
                showAll={() => showTeachers(teachers, name)}
              />
            </S.Column>
            <S.Column small>
              <UserStatusBadge isActive={isActive} />
            </S.Column>
            <S.Column small>
              <S.UsersCounter>{usersCount}</S.UsersCounter>
            </S.Column>
            <S.Column>{groupProperties}</S.Column>
          </S.TabletAndUp>
        </S.InfoContainer>
      </S.TriggerRowArea>
      <S.HideOnXS>{dotsMenuWithToast(false)}</S.HideOnXS>
    </Panel>
  );
};

export default observer(GroupPanel);
