/* eslint-disable no-restricted-imports */
/* eslint-disable no-restricted-globals */
import {
  Box,
  Button,
  DoodleLoader,
  Heading,
  Input,
  Text
} from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router";
import { useMutation, useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { savePublicProfileSettings } from "src/js/repository/publicProfileRepository";
import WSTheme from "src/js/theme/theme";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation/translated.types";
import { navigateTo } from "src/legacy/modules/history";
import {
  publicProfileQueryKey,
  queryClient,
  usePublicProfileSettingsCached
} from "src/js/query";
import { useTheme } from "styled-components";
import { EditAvatar } from "src/js/components/EditAvatar";
import { EditBirthday } from "./components/EditBirthday";
import { VisibilityToggle } from "./components/VisibilityToggle";
import {
  EditProfileFormData,
  UserInformation,
  UserInfoVisibility
} from "./ProfileEditPage.types";
import {
  __EDIT_PROFILE_SCHEMA__,
  getPlaceholder
} from "./ProfileEditPage.utils";

import * as S from "./ProfileEditPage.styles";

const ProfileEdit = () => {
  const [userVisibility, setUserVisibility] = useState<UserInfoVisibility>();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [isDirty, setIsDirty] = useState(false);
  const [userBirthday, setUserBirthday] = useState<string>();
  const { translate } = useTranslation();

  const { register, errors, handleSubmit, formState, watch, setValue, reset } =
    useForm<EditProfileFormData>({
      mode: "onChange"
    });

  const watchAllFields = watch();

  const { greyTheme } = useTheme();
  const { data: publicProfileSettings, isLoading } =
    usePublicProfileSettingsCached();

  useEffect(() => {
    if (!publicProfileSettings) return;
    setUserVisibility({
      showBirthday: publicProfileSettings.showBirthday,
      showFacebook: publicProfileSettings.showFacebook,
      showInstagram: publicProfileSettings.showInstagram,
      showStatus: publicProfileSettings.showStatus,
      showBadges: publicProfileSettings.showBadges
    });
    setUserBirthday(publicProfileSettings.birthday);

    setValue([
      { facebook: publicProfileSettings.facebook },
      {
        instagram: publicProfileSettings.instagram
      },
      { status: publicProfileSettings.status },
      {
        birthday: format(new Date(publicProfileSettings.birthday), "dd-MM-yyyy")
      }
    ]);
    reset({
      birthday: format(new Date(publicProfileSettings.birthday), "dd-MM-yyyy"),
      facebook: publicProfileSettings.facebook,
      instagram: publicProfileSettings.instagram,
      status: publicProfileSettings.status
    });
  }, [publicProfileSettings]);

  const { mutate: saveProfileSettings } = useMutation(
    async (formData: EditProfileFormData) => {
      const body: UserInfoVisibility & UserInformation = {
        ...formData,
        ...userVisibility
      };
      body.birthday = format(new Date(userBirthday), "dd-MM-yyyy");
      savePublicProfileSettings(body);
    },
    {
      onSuccess: async () => {
        reset();
        setIsDirty(false);
        navigateTo(createUrl("user_profile_settings"));
        // TODO: remove this timeout when the backend will be ready
        showToastSuccess({ str: translate("profile_update_success") });
        await new Promise(resolve => {
          setTimeout(resolve, 1200);
        });
        queryClient.invalidateQueries(publicProfileQueryKey.settings);
      },
      onError() {
        showToastError({ str: translate("general_error") });
      }
    }
  );

  const changeUserVisibility = (
    field: keyof UserInfoVisibility,
    value: boolean
  ) => {
    const newState = { ...userVisibility };
    newState[field] = value;

    setIsDirty(true);
    setUserVisibility(newState);
  };

  const submitChanges = (formData: EditProfileFormData) => {
    saveProfileSettings(formData);
  };

  return isLoading || !userVisibility ? (
    <DoodleLoader theme={greyTheme} />
  ) : (
    <form id="edit-profile-form" style={{ width: "100%" }}>
      {isLayoutModeMobile && (
        <Box marginBottom={8}>
          <EditAvatar />
        </Box>
      )}
      <S.HeaderWrapper>
        {!isLayoutModeMobile && (
          <Heading level="4">
            {translate("profile_page_personal_information")}
          </Heading>
        )}
        <Button
          theme={greyTheme}
          variant="secondary"
          onClick={handleSubmit(submitChanges)}
        >
          {translate("save_edits")}
        </Button>
      </S.HeaderWrapper>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <S.MainWrapper>
          <Prompt
            when={formState.dirty || isDirty}
            message={() =>
              translate({
                text: "profile_edit_leaving_without_saving_prompt_message"
              })
            }
          />

          <S.SectionWrapper zIndex={WSTheme.zIndex.comboBody - 1}>
            <EditBirthday
              birthday={new Date(userBirthday)}
              placeholder={getPlaceholder("birthday")}
              onChangeBirthday={date => {
                setUserBirthday(date.toDateString());
                setValue("birthday", format(new Date(date), "dd-MM-yyyy"));
                setIsDirty(true);
              }}
              showBirthday={userVisibility.showBirthday}
              onChangeShow={value =>
                changeUserVisibility("showBirthday", value)
              }
              ref={register(__EDIT_PROFILE_SCHEMA__.birthday)}
            />
          </S.SectionWrapper>
          <S.SectionWrapper>
            <Input
              label={translate({
                text: "public_profile_facebook_label"
              })}
              theme={greyTheme}
              type="field"
              name="facebook"
              placeholder={translate({
                text: getPlaceholder("facebook")
              })}
              rounded
              icon="facebookEmpty"
              ref={register(__EDIT_PROFILE_SCHEMA__.facebook)}
              error={{
                message: translate({
                  text: errors?.facebook?.message as TranslationKeys
                })
              }}
              rightSection={
                watchAllFields.facebook ? (
                  <VisibilityToggle
                    visible={userVisibility.showFacebook}
                    onChange={value =>
                      changeUserVisibility("showFacebook", value)
                    }
                  />
                ) : null
              }
            />
          </S.SectionWrapper>
          <S.SectionWrapper>
            <Input
              label={translate({
                text: "public_profile_instagram_label"
              })}
              theme={greyTheme}
              type="field"
              placeholder={translate({
                text: getPlaceholder("instagram")
              })}
              rounded
              icon="instagram"
              name="instagram"
              ref={register(__EDIT_PROFILE_SCHEMA__.instagram)}
              error={{
                message: translate({
                  text: errors?.instagram?.message as TranslationKeys
                })
              }}
              rightSection={
                watchAllFields.instagram ? (
                  <VisibilityToggle
                    visible={userVisibility.showInstagram}
                    onChange={value => {
                      changeUserVisibility("showInstagram", value);
                    }}
                  />
                ) : null
              }
            />
          </S.SectionWrapper>
          <S.SectionWrapper>
            <Input
              label={translate({
                text: "public_profile_bio_label"
              })}
              theme={greyTheme}
              type="textarea"
              name="status"
              placeholder={translate({
                text: getPlaceholder("status")
              })}
              rows={6}
              ref={register(__EDIT_PROFILE_SCHEMA__.status)}
              rightSection={
                watchAllFields.status ? (
                  <VisibilityToggle
                    visible={userVisibility.showStatus}
                    onChange={value =>
                      changeUserVisibility("showStatus", value)
                    }
                  />
                ) : null
              }
            />
          </S.SectionWrapper>
          <S.SectionWrapper>
            <Text type="formSubtitle">
              {translate("public_profile_badge_label")}
            </Text>
            <S.SectionPanel>
              <Text type="formFieldEmpty">
                {translate("public_profile_badge_title")}
              </Text>
              <Box position="absolute" right={16}>
                <VisibilityToggle
                  visible={userVisibility.showBadges}
                  onChange={value => changeUserVisibility("showBadges", value)}
                />
              </Box>
            </S.SectionPanel>
            <Box marginTop={6} padding="0px 6px">
              <S.BadgeSubtitle type="formSubtitle">
                {translate("public_profile_badge_text")}
              </S.BadgeSubtitle>
            </Box>
          </S.SectionWrapper>
        </S.MainWrapper>
      </Box>
    </form>
  );
};

export default observer(ProfileEdit);
