import { useQuery } from "src/js/query/hooks";
import { fetchGroupStats } from "src/js/repository/groupRepository";
import { groupQueryKey } from "./group.query";

export const useGroupStats = ({ groupId }: { groupId: number }) => {
  return useQuery(
    groupQueryKey.groupStats({ groupId }),
    () => fetchGroupStats(groupId),
    { refetchOnWindowFocus: false }
  );
};
