import { isBefore } from "date-fns";
import { useLocation } from "react-router";
import { navigateTo } from "src/legacy/modules/history";
import { WeSchoolRoutes } from "../pages/Routes.const";
import { fetchMarketingData } from "../repository/marketingDataRepository";
import { fetchSpacesList } from "../repository/spaceListRepository";
import {
  fetchSpaceDetails,
  fetchSpaceGroups
} from "../repository/spacesRepository";
import { __ONBOARDING_RELEASE_DATE__ } from "../settings/settingsOnboarding";
import { SpaceDetailsModel, SpacePurpose, SpaceUserRoleEnum } from "../types";
import {
  MarketingDataFormFields,
  USER_TYPE,
  UserType
} from "../types/models/MarketingData";
import useStores from "./useStores";

const useOnboarding = () => {
  const {
    SpaceStore: { setActiveSpace },
    UserStore: { activeUser, setUserOnboarding, userOnboarding }
  } = useStores();

  const { pathname } = useLocation();

  const onboardingRoutesArray = Object.values(WeSchoolRoutes.Onboarding);

  const routeGuard = () => {
    if (userOnboarding.step !== pathname) {
      navigateTo(userOnboarding.step);
    }
  };

  const isOnboardingFlow =
    onboardingRoutesArray.filter(el => el === pathname).length > 0;

  const initUserOnboarding = async () => {
    fetchSpacesList({ limit: 10, offset: 0 }).then(async spaces => {
      if (spaces.results.length > 1) {
        setUserOnboarding({
          ...userOnboarding,
          isCompleted: true
        });
        return;
      }
      if (spaces.results.length === 1) {
        const spaceId = spaces.results[0].space.id;
        const spaceDetails = await fetchSpaceDetails(spaceId);

        // overquota space
        if (spaceDetails.spaceSettings.blocked) {
          setUserOnboarding({
            ...userOnboarding,
            isCompleted: true
          });
          return;
        }
        const groupList = await fetchSpaceGroups({
          spaceId,
          limit: 10,
          offset: 0
        });
        if (groupList.results.length > 0) {
          setUserOnboarding({
            ...userOnboarding,
            isCompleted: true
          });
          return;
        }
        if (spaceDetails.space.purpose !== SpacePurpose.Company) {
          setUserOnboarding({
            ...userOnboarding,
            isCompleted: true
          });
          return;
        }
        if (
          spaceDetails.space.purpose === SpacePurpose.Company &&
          spaceDetails.role === SpaceUserRoleEnum.Owner
        ) {
          setActiveSpace(spaceDetails as SpaceDetailsModel);
          setUserOnboarding({
            userType: USER_TYPE.BUSINESS,
            isCompleted: false,
            step: WeSchoolRoutes.Onboarding.resumeSpace
          });
          navigateTo(WeSchoolRoutes.Onboarding.resumeSpace);
        } else {
          setUserOnboarding({
            userType: USER_TYPE.BUSINESS,
            isCompleted: false,
            step: WeSchoolRoutes.Onboarding.welcome
          });
          navigateTo(WeSchoolRoutes.Onboarding.welcome);
        }
      } else {
        let step: string;
        let marketingData: MarketingDataFormFields;
        try {
          const marketingDataFetched = await fetchMarketingData();
          const {
            userType,
            companyName,
            companyRole,
            companySize,
            weschoolUseCase,
            phoneNumber,
            onboardingCompleted
          } = marketingDataFetched;
          if (onboardingCompleted === true) {
            setUserOnboarding({
              ...userOnboarding,
              isCompleted: true
            });
            navigateTo("home");
            return;
          }
          if (
            userType &&
            userType === USER_TYPE.BUSINESS &&
            companyName &&
            companyRole &&
            companySize &&
            weschoolUseCase &&
            phoneNumber
          ) {
            marketingData = {
              companyName: marketingDataFetched.companyName,
              companyRole: marketingDataFetched.companyRole,
              companySize: marketingDataFetched.companySize,
              weschoolUseCase: marketingDataFetched.weschoolUseCase,
              phoneNumber: marketingDataFetched.phoneNumber
            };
            step = WeSchoolRoutes.Onboarding.resumeOnboarding;
          } else {
            step = WeSchoolRoutes.Onboarding.welcome;
          }
          setUserOnboarding({
            userType,
            isCompleted: false,
            step,
            marketingData
          });
          navigateTo(step);
        } catch (error) {
          // when the user have no space, no groups and no marketing data will be redirected to the welcome onboarding page
          setUserOnboarding({
            userType: null,
            isCompleted: false,
            step: WeSchoolRoutes.Onboarding.welcome
          });
          navigateTo(WeSchoolRoutes.Onboarding.welcome);
        }
      }
    });
  };

  const userRegisteredPreOnboarding = isBefore(
    new Date(activeUser?.created),
    new Date(__ONBOARDING_RELEASE_DATE__)
  );

  const flowSwitcher = (
    userType: UserType,
    onboardingWeSchoolTemplates?: boolean
  ) => {
    switch (userType) {
      case USER_TYPE.BUSINESS:
        switch (userOnboarding.step) {
          case WeSchoolRoutes.Onboarding.welcome:
            setUserOnboarding({
              userType: USER_TYPE.BUSINESS,
              isCompleted: false,
              step: WeSchoolRoutes.Onboarding.marketingData
            });
            navigateTo(WeSchoolRoutes.Onboarding.marketingData);
            break;
          case WeSchoolRoutes.Onboarding.resumeOnboarding:
          case WeSchoolRoutes.Onboarding.marketingData:
            navigateTo(WeSchoolRoutes.Onboarding.spaceCreation);
            break;
          case WeSchoolRoutes.Onboarding.groupCreateSelection:
          case WeSchoolRoutes.Onboarding.resumeSpace:
          case WeSchoolRoutes.Onboarding.spaceCreation:
            setUserOnboarding({
              userType: USER_TYPE.BUSINESS,
              isCompleted: false,
              step: onboardingWeSchoolTemplates
                ? WeSchoolRoutes.Onboarding.weschoolTemplateCreation
                : WeSchoolRoutes.Onboarding.groupCreation
            });
            navigateTo(WeSchoolRoutes.Onboarding.groupCreation);
            break;
          case WeSchoolRoutes.Onboarding.weschoolTemplateCreation:
          case WeSchoolRoutes.Onboarding.groupCreation:
            setUserOnboarding({
              userType: USER_TYPE.BUSINESS,
              isCompleted: false,
              step: WeSchoolRoutes.Onboarding.membersInvite
            });
            navigateTo(WeSchoolRoutes.Onboarding.membersInvite);
            break;

          default:
            break;
        }
        break;
      case USER_TYPE.K12:
        if (userOnboarding.step === WeSchoolRoutes.Onboarding.welcome) {
          setUserOnboarding({
            userType: USER_TYPE.K12,
            isCompleted: false,
            step: WeSchoolRoutes.Onboarding.schoolSpaceCreation
          });
          navigateTo(WeSchoolRoutes.Onboarding.schoolSpaceCreation);
        }
        break;

      case USER_TYPE.MEMBER:
        if (userOnboarding.step === WeSchoolRoutes.Onboarding.welcome) {
          setUserOnboarding({
            userType: USER_TYPE.MEMBER,
            isCompleted: false,
            step: WeSchoolRoutes.Onboarding.join
          });
          navigateTo(WeSchoolRoutes.Onboarding.join);
        }
        break;
      default:
        break;
    }
  };

  return {
    userRegisteredPreOnboarding,
    isOnboardingFlow,
    flowSwitcher,
    initUserOnboarding,
    routeGuard
  };
};

export default useOnboarding;
