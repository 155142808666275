import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { CustomListElementProps } from "src/js/components/global/ListSelector";
import {
  SelectableList,
  SelectableListEmptyState,
  SelectableListEmptyStatusType
} from "src/js/components/layout/SelectableList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { fetchSpacesGroups } from "src/js/repository/dashboardRepository";
import { MAX_GROUPS_SELECTED } from "src/js/settings/settingsInvite";
import { useTranslation } from "src/js/translation";
import { GroupInSpaceModel } from "src/js/types";
import GroupInviteMemberModal from "../GroupInviteMemberModal";
import { ItemCardGroupsInviteModal } from "./ItemCardGroupsInviteModal";

const SelectionGroupsInviteModal = ({
  initialGroupIds
}: {
  initialGroupIds: number[];
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal },
    SpaceStore: { activeSpaceId, activeSpaceName }
  } = useStores();

  const [selectedGroups, setSelectedGroups] = useState<string[] | number[]>(
    initialGroupIds || []
  );

  const fetchItems = useCallback(
    ({ name = "", limit, offset }) => {
      return fetchSpacesGroups({
        spaceId: activeSpaceId,
        name_filter: name,
        offset,
        limit
      }).then(data => {
        const results: GroupInSpaceModel[] = data.results.map(
          (item: GroupInSpaceModel) => ({
            ...item,
            id: item.group.id
          })
        );
        return { results, hasNext: null };
      });
    },
    [activeSpaceId]
  );

  const getCustomItemCard = (
    props: CustomListElementProps<GroupInSpaceModel>
  ) => <ItemCardGroupsInviteModal {...props} />;

  const getEmptyStateFromStatus = (status: SelectableListEmptyStatusType) => (
    <SelectableListEmptyState
      status={status}
      emptySearchLabel="select_groups_empty_search"
      emptyListLabel="select_groups_empty_list"
    />
  );

  const openSelectionGroupsInviteModal = () => {
    openModal(() => (
      <SelectionGroupsInviteModal
        initialGroupIds={selectedGroups as number[]}
      />
    ));
  };

  const handleOnConfirm = () => {
    openModal(() => (
      <GroupInviteMemberModal
        initialGroupIds={selectedGroups as number[]}
        onClose={() => {
          closeModal();
          openSelectionGroupsInviteModal();
        }}
      />
    ));
  };

  return (
    <ModalLayout
      labels={{
        title: translate("select_groups_title"),
        mobileClose: translate("close")
      }}
      mobileProps={{
        withDrag: false
      }}
      closeModal={closeModal}
      desktopProps={{ hasAdaptiveHeight: false }}
    >
      <SelectableList
        maxSelectedItems={MAX_GROUPS_SELECTED}
        multipleSelect
        withCancelButton
        onCancel={closeModal}
        onConfirm={handleOnConfirm}
        fetchItems={fetchItems}
        selectedItemsIds={selectedGroups}
        onChangeSelectedItemsIds={setSelectedGroups}
        customComponents={{
          getCustomItemCard,
          getEmptyStateFromStatus
        }}
        labels={{
          translatedConfirm: translate("select_groups_proceed"),
          translatedCancel: translate("select_groups_cancel"),
          translatedTitle: translate("select_groups_instruction", {
            groups: MAX_GROUPS_SELECTED,
            spaceName:
              activeSpaceName.length > 35
                ? `${activeSpaceName.slice(0, 35)}...`
                : activeSpaceName
          }),
          searchPlaceholder: translate(
            "assessment_detail_modal_search_placeholder"
          )
        }}
      />
    </ModalLayout>
  );
};

export default observer(SelectionGroupsInviteModal);
