import React from "react";
import { Trans as TransI18n } from "react-i18next";
import { HTMLParserComponents } from "src/js/settings/settingsHTMLParser";

const HTMLParser = ({
  htmlString,
  components
}: {
  htmlString: string;
  components?: { readonly [tagName: string]: React.ReactElement };
}) => {
  return (
    <TransI18n
      i18nKey={htmlString}
      components={{ ...HTMLParserComponents, ...components }}
    />
  );
};

export default HTMLParser;
