import { Box } from "@arcadia/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { AwardedBadges } from "src/js/components/AwardedBadges";
import { SpacesWelcome } from "src/js/components/SpacesWelcome";
import { HeaderWhiteHome } from "src/js/components/layout/HeaderWhiteHome";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { groupsQueryKey } from "src/js/query/groups";
import { SpaceGroupsParams } from "src/js/types";
import { useTheme } from "styled-components";
import { GroupsInvitations } from "./GroupsInvitations";
import { GroupsList } from "./GroupsList";
import { SelfJoinableGroupsList } from "./SelfJoinableGroupsList";
import { AnalyticsPreview } from "./AnalyticsPreview";

const Groups = observer(() => {
  const {
    SpaceStore: { activeSpace, isDemoSpace, setActiveSpaceBySlug, isAdmin },
    UserStore: { activeUser },
    SpaceMoveGroupsStore: { hasDestinationsSpaces, hasTemporaryGroups },
    UIStore: { openSideBar, isLayoutModeMobile }
  } = useStores();
  const { space_slug } = useParams<SpaceGroupsParams>();
  const { pageMinHeight } = useAppLayout();
  const { trackVirtualView } = useViewTracking();
  const queryClient = useQueryClient();

  const { colors } = useTheme();

  useEffect(() => {
    openSideBar();
  }, []);

  useEffect(() => {
    if (isDemoSpace) {
      trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.DemoSpaceGroups);
    }
  }, [isDemoSpace]);

  useEffect(() => {
    setActiveSpaceBySlug(space_slug);
  }, [space_slug]);

  const isTemporarySpace = activeSpace?.isTemporary;

  return (
    <Box
      width="100%"
      backgroundColor={colors.coldGrey[900]}
      minHeight={pageMinHeight}
    >
      <>
        <HeaderWhiteHome />
        <MainContainer paddingTop="0" paddingTopMobile="0">
          {isTemporarySpace ? (
            <SpacesWelcome
              name={activeUser?.name}
              createCompleted={hasDestinationsSpaces || !hasTemporaryGroups}
              moveCompleted={!hasTemporaryGroups}
            />
          ) : null}
          {isAdmin && activeSpace?.enableAnalytics && !isLayoutModeMobile ? (
            <AnalyticsPreview />
          ) : null}
          <GroupsInvitations
            appendToGroupList={() => {
              queryClient.invalidateQueries(groupsQueryKey.all);
            }}
          />
          <AwardedBadges requestForSpecificSpace />
          <GroupsList />
          <SelfJoinableGroupsList />
        </MainContainer>
      </>
    </Box>
  );
});

export default Groups;
