import { fetchSpacesUsers } from "src/js/repository/dashboardRepository";
import { FetchSpacesUsersParams } from "src/js/types";
import { useQuery } from "../hooks";

const __DASHBOARD_KEY_ALL__ = "dashboard";

export const dashboardQueryKey = {
  all: [__DASHBOARD_KEY_ALL__],
  v1: [__DASHBOARD_KEY_ALL__, "v1"],
  v3: [__DASHBOARD_KEY_ALL__, "v3"],
  settingsV3: ({
    spaceId,
    checkSubscription
  }: {
    spaceId: string;
    checkSubscription: boolean;
  }) => [...dashboardQueryKey.v3, { spaceId, checkSubscription }]
} as const;

// Query keys

const users = ({
  spaceId,
  limit,
  offset,
  name,
  groups,
  roles,
  order_by,
  sort_order,
  include_disabled
}: FetchSpacesUsersParams) => [
  __DASHBOARD_KEY_ALL__,
  "users",
  spaceId,
  limit,
  offset,
  name,
  groups,
  roles,
  order_by,
  sort_order,
  include_disabled
];

export const staffQueryKey = {
  users
} as const;

// Hooks

export const useDashboardUsers = (params: FetchSpacesUsersParams) => {
  return useQuery(
    staffQueryKey.users({ ...params }),
    () => fetchSpacesUsers(params),
    { refetchOnWindowFocus: false }
  );
};
