import styled, { css } from "styled-components";

type BoxModeType = { collapsed: boolean };

export const DescriptionBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 16px;
`;

export const CollapsableContainer = styled.div<BoxModeType>`
  display: ${({ collapsed }) => (collapsed ? "flex" : "block")};
`;

export const CollapsableContent = styled.div<BoxModeType>`
  display: ${({ collapsed }) => (collapsed ? "block" : "inline")};
  white-space: ${({ collapsed }) => (collapsed ? "nowrap" : "pre-wrap")};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  width: 100%;
  ${({ collapsed }) =>
    collapsed &&
    css`
      max-height: 20px;
    `}
`;

export const CollapseButton = styled.button`
  background: none;
  border: none;
  font-weight: 700;
  padding: 0;
`;

export const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
`;

export const PriceInfoRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

export const PriceTag = styled.div`
  font-size: 22px;
  font-weight: 600;
  white-space: nowrap;
`;

export const PriceTagSubtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
`;

export const ManageLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor[500]};
  text-decoration: underline;
  cursor: pointer;
`;

export const SupportRow = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

export const SupportMailLink = styled.a`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryColor[500]};
  text-decoration: underline;
  cursor: pointer;
`;
