import { Box, Dropdown, Heading, Text } from "@arcadia/design-system";
import React, { useEffect, useMemo, useState } from "react";
import { TileButton } from "src/js/components/button/TileButton";
import { useSpacePlanType, useStores, useToastMessage } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { PRO_PLAN_MEMBERS_LIMIT } from "src/js/settings/settingsInvite";
import { Trans, useTranslation } from "src/js/translation";
import {
  AddMemberEvent,
  EventDomain,
  GroupUserRoleEnum,
  ToastVariant
} from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import styled, { DefaultTheme } from "styled-components";
import {
  demoNoInvitationWarningBanner,
  temporaryNoInvitationWarningBanner
} from "../GroupSettingsMembers/translations";
import { FileImportModal } from "./FileImportModal";
import { SelectionGroupImportModal } from "./SelectionGroupImportModal";
import { useInviteTransComponents } from "./hooks";

const StyledDropdown = styled(Dropdown)`
  width: fit-content;
  min-width: 160px;
  @media (max-width: 767px) {
    margin-left: auto;
  }
`;

const StyledTileButton = styled(TileButton)`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

const TileButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionInviteImport = ({
  theme,
  initialInviteMemberRole = GroupUserRoleEnum.Student,
  initialGroupIds
}: {
  theme: DefaultTheme;
  initialInviteMemberRole?: GroupUserRoleEnum;
  initialGroupIds?: number[];
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile },
    SpaceStore: { isDemoSpace, activeSpace },
    GroupStore: { groupId }
  } = useStores();

  const { transComponents } = useInviteTransComponents();
  const groupIds = initialGroupIds ?? [groupId];
  const { totalMemberInviteLimit, isPlanProOrEnterprise } = useSpacePlanType({
    groupCount: groupIds.length
  });
  const isInvitationAllowed = !isDemoSpace && isPlanProOrEnterprise;

  useEffect(() => {
    _trackEvent(EventDomain.AddMember, AddMemberEvent.ImportSectionDisplayed);
  }, []);

  const initialMessages = useMemo(() => {
    if (isDemoSpace) {
      return [
        {
          variant: ToastVariant.Warning,
          props: {
            text: demoNoInvitationWarningBanner.text,
            cta: demoNoInvitationWarningBanner.cta
          }
        }
      ];
    }
    if (activeSpace?.isTemporary) {
      return [
        {
          variant: ToastVariant.Warning,
          props: {
            text: temporaryNoInvitationWarningBanner.text,
            cta: temporaryNoInvitationWarningBanner.cta
          }
        }
      ];
    }

    if (!isPlanProOrEnterprise) {
      return [
        {
          id: "pro-plan-only",
          variant: ToastVariant.Alert,
          children: (
            <Trans
              i18nKey="invite_modal_import_toast_pro_only"
              values={{
                membersLimit: totalMemberInviteLimit,
                newMembersLimit: PRO_PLAN_MEMBERS_LIMIT
              }}
              components={transComponents}
            />
          )
        }
      ];
    }
    return [];
  }, [isDemoSpace, isPlanProOrEnterprise, activeSpace]);

  const { toastMessages, toastMessageNodes } = useToastMessage(initialMessages);

  const [selectedMemberType, setSelectedMemberType] =
    useState<GroupUserRoleEnum>(initialInviteMemberRole);

  const openGroupImportModal = () => {
    openModal(() => (
      <SelectionGroupImportModal
        groupIds={groupIds}
        role={selectedMemberType}
      />
    ));
  };

  const openFileImportModal = () => {
    openModal(() => (
      <FileImportModal
        groupIds={groupIds}
        selectedMemberType={selectedMemberType}
      />
    ));
  };

  return (
    <>
      <Box
        display="flex"
        gap="8px"
        flexDirection={isLayoutModeMobile ? "column" : "row"}
        marginBottom="20px"
      >
        <Box display="flex" flexDirection="column" gap="4px" flex={1}>
          <Heading level="5">{translate("invite_modal_import_title")}</Heading>
          <Text type="formSubtitle">
            {translate("invite_modal_import_description")}
          </Text>
        </Box>
        <StyledDropdown
          id="invite-members-type-dropdown"
          theme={theme}
          optionsList={[
            {
              id: GroupUserRoleEnum.Student,
              label: translate("select_member_type_students")
            },
            {
              id: GroupUserRoleEnum.Teacher,
              label: translate("select_member_type_instructors")
            }
          ]}
          selectedOptionId={selectedMemberType}
          setSelectedOptionId={id =>
            setSelectedMemberType(id as GroupUserRoleEnum)
          }
          disabled={!isInvitationAllowed}
        />
      </Box>
      {toastMessages?.length > 0 ? (
        <Box
          marginBottom="20px"
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          {toastMessageNodes()}
        </Box>
      ) : null}
      <TileButtons>
        <StyledTileButton
          id={UsetifulId.ImportFromGroupsSelection}
          disabled={!isInvitationAllowed}
          icon="gridView"
          title={translate("invite_modal_import_from_groups")}
          description={translate("invite_modal_import_from_groups_instruction")}
          onClick={openGroupImportModal}
        />
        <StyledTileButton
          id={UsetifulId.ImportFromCsvSelection}
          disabled={!isInvitationAllowed}
          icon="contentPaste"
          title={translate("invite_modal_import_from_csv")}
          description={translate("invite_modal_import_from_csv_instruction")}
          onClick={openFileImportModal}
        />
      </TileButtons>
    </>
  );
};

export default SectionInviteImport;
