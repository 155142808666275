import styled from "styled-components";

interface TintableItem {
  isMobile: boolean;
  tinted?: boolean;
}

export const SettingsWrapper = styled.div<TintableItem>`
  width: 100%;
  background-color: ${({ theme, tinted = true }) =>
    tinted ? theme.colors.coldGrey[900] : theme.colors.grey[1000]};
`;

export const UserNameWrapper = styled.div<{ headerHeight?: number }>`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.sidebar - 1};
  top: ${({ headerHeight }) => headerHeight ?? 0}px;
`;

export const ContentWrapper = styled.div`
  max-width: 756px;
  padding: 32px 16px;
  margin: auto;
  width: 100%;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;
