import { ActionButton, Avatar, Divider } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useMemo, useRef } from "react";
import { ListItem, ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { GroupJoinWithCodeModal } from "src/js/components/GroupJoinWithCodeModal";
import { LogoutModal } from "src/js/components/modal/LogoutModal";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { Animations } from "src/js/settings/animations";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { PopupUserMenuProps } from "./types";

import * as S from "./PopupUserMenu.styles";

const PopupUserMenu = ({ isOpen, closePopover }: PopupUserMenuProps) => {
  const {
    UserStore: { activeUserFullname, activeUser },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const popoverRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isOpen && popoverRef.current) {
      popoverRef.current.focus();
    }
  }, [isOpen]);

  const showHelp = () => {
    if (typeof zE !== "undefined") {
      zE.activate({ hideOnClose: true });
    }
  };

  const menuItems: ListItemProps[] = useMemo(
    () => [
      {
        icon: "userEmpty",
        id: "publicProfileLink",
        label: translate("toolbar_profile_menu_profile_option_label"),
        onClick: () => {
          navigateTo(createUrl("user_profile_settings"));
          closePopover();
        }
      },
      {
        icon: "gear",
        id: "accountSettingsLink",
        label: translate("toolbar_profile_menu_manage_account_option_label"),
        onClick: () => {
          navigateTo(createUrl("user_manage_account_settings"));
          closePopover();
        }
      },
      {
        icon: "bellEmpty",
        id: "noticeSettingsLink",
        label: translate(
          "toolbar_profile_menu_notification_settings_option_label"
        ),
        onClick: () => {
          navigateTo(createUrl("user_notification_settings"));
          closePopover();
        }
      },
      {
        icon: "frameSource",
        id: "JoinGroupWithCodeLink",
        label: translate(
          "toolbar_profile_menu_join_group_with_code_option_label"
        ),
        onClick: () => {
          openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
          closePopover();
        }
      },
      {
        icon: "help",
        id: "contactUsLink",
        label: translate("toolbar_profile_menu_contact_us_option_label"),
        onClick: () => {
          showHelp();
          closePopover();
        }
      },

      {
        icon: "logout",
        id: "logoutLink",
        label: translate("toolbar_profile_menu_logout_option_label"),
        redShade: true,
        onClick: () => {
          openModal(() => <LogoutModal />);
          closePopover();
        }
      }
    ],
    [closePopover, translate, navigateTo, createUrl]
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <S.MenuContainer
          {...Animations.Dropdown}
          tabIndex={0}
          ref={popoverRef}
          aria-label="Profile menu popover"
        >
          <S.EditProfileWrapper>
            <ActionButton
              theme={whiteTheme}
              variant="secondaryColored"
              icon="pencil"
              details="profile edit"
              onClick={() => {
                navigateTo(createUrl("user_profile_settings_edit"));
              }}
            />
          </S.EditProfileWrapper>
          <S.AvatarContainer
            aria-label={`${activeUserFullname} avatar and username`}
          >
            <Avatar
              theme={whiteTheme}
              url={activeUser.avatar?.small}
              alt={activeUserFullname}
              size="l"
              rounded
            />
            <S.Username tabIndex={-1}>{activeUserFullname}</S.Username>
          </S.AvatarContainer>
          <S.DividerWrapper>
            <Divider theme={whiteTheme} color="grey" />
          </S.DividerWrapper>
          <S.OptionList tabIndex={0}>
            {menuItems.map(props => (
              <ListItem key={props.id} {...props} />
            ))}
          </S.OptionList>
        </S.MenuContainer>
      )}
    </AnimatePresence>
  );
};

export default observer(PopupUserMenu);
