import { AvatarWithInitials } from "@arcadia/design-system";
import { ListSelectorItem } from "src/js/components/global/ListSelector";
import styled, { css } from "styled-components";

export const CheckIndicator = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor[500]};
  ${({ isSelected }) =>
    !isSelected &&
    css`
      path,
      circle {
        display: none;
      }
    `}
`;

export const ItemCardGroupImportModalContent = styled.div`
  width: 100%;
  padding: 0 12px;
  overflow: hidden;
  span {
    display: block;
  }
`;

export const ItemCardGroupImportModalWrapper = styled(ListSelectorItem)`
  padding: 12px;
  margin-bottom: 12px;
`;

export const ImageContainer = styled.div`
  flex: none;
`;

export const StyledAvatarWithInitials = styled(AvatarWithInitials)`
  position: absolute;
  bottom: -4px;
  right: -4px;
  height: fit-content;
`;
